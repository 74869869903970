<template>
    <div class="page-container red-background">
        <div class="page-content">
            <PurchaseStatusDetails />

            <!-- <div class="mt-5" v-if="purchase" style="border: 2px solid red">
                {{ purchase }}
            </div> -->
        </div>
    </div>
</template>
<script>
import PurchaseStatusDetails from '@/components/Shop/PurchaseStatusDetails.vue';
export default {
    metaInfo: { title: 'Status' },
    data() {
        return {
            message: 'hi',
            purchase: {},
        };
    },
    components: { PurchaseStatusDetails },
    methods: {
        getPurchase(slug) {
            this.axios
                .get(process.env.VUE_APP_API + `/Order/purchase-status/${slug}`)
                .then(response => {
                    this.purchase = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
    },
    // created() {
    //     this.$store.dispatch('toggleTopNav');
    // },
    mounted() {
        this.getPurchase(this.$route.params.slug);
    },
};
</script>
<style>
.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.page-content {
    background-color: white;
    border-radius: 10px;
}
.red-background {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat fixed 0 -16rem;
}
</style>

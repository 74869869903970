span
<template>
    <div>
        <div v-if="loading" class="p-3">
            <v-skeleton-loader type="heading" class="mb-3"> </v-skeleton-loader>
            <v-skeleton-loader type="image" class="mb-3"> </v-skeleton-loader>
            <v-skeleton-loader type="image" class="mb-3"> </v-skeleton-loader>
        </div>
        <div v-else class="analytics-chart">
            <div class="chart">
                <v-card-title>
                    <h5 v-if="mounted" class="chart-title weekly">{{ intervalTitle }}</h5>
                    <h5 class="chart-title-weekly" v-else>Updating Chart..</h5>
                </v-card-title>

                <div class="chart-container">
                    <line-chart :chart-data="datacollection" :options="options"></line-chart>
                    <div>
                        <div class="mt-4 text-center">
                            <h6 class="d-inline-block mr-5" style="border-bottom: 1px solid rgba(255, 83, 13, 0.7)">
                                All Views <span>{{ formatNumber(sumViewsArray(allViews.data)) }}</span>
                            </h6>
                            <h6 class="d-inline-block mr-5" style="border-bottom: 1px solid rgba(25, 83, 13, 0.7)">
                                Recording Views <span>{{ formatNumber(sumViewsArray(delayedViews.data)) }}</span>
                            </h6>
                            <h6 class="d-inline-block mr-5" style="border-bottom: 1px solid rgba(25, 200, 13, 0.7)">
                                Live Views <span>{{ formatNumber(sumViewsArray(liveViews.data)) }}</span>
                            </h6>
                            <h6
                                v-if="sumViewsArray(storyViews.data) > 0"
                                class="d-inline-block mr-5"
                                style="border-bottom: 1px solid rgb(0, 150, 136)"
                            >
                                Story Views <span>{{ formatNumber(sumViewsArray(storyViews.data)) }}</span>
                            </h6>
                            <h6
                                v-if="sumViewsArray(tributeViews.data) > 0"
                                class="d-inline-block"
                                style="border-bottom: 1px solid rgb(103, 58, 183)"
                            >
                                Tribute Views <span>{{ formatNumber(sumViewsArray(tributeViews.data)) }}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from './LineChart';

export default {
    props: {
        funeralHomeId: {
            type: Number,
            default: 0,
        },
        serviceId: {
            type: Number,
            default: 0,
        },
        interval: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            lineChartData: {},
            mounted: false,
            loading: true,
            data: {},
            labels: [],
            datacollection: null,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return Number.isInteger(value) ? value : null; // Only display integers
                                },
                                min: 0,
                                suggestedMax: 10,
                            },
                        },
                    ],
                },
            },
            allViews: {
                label: 'All',
                // borderColor: 'rgba(255,83,13,.7)',
                backgroundColor: 'rgba(200,200,200,0)',
                borderColor: '#ff7837',
                backgroundColor: 'rgba(200,200,200,0)',
                data: [],
            },
            delayedViews: {
                label: 'Recording',
                // borderColor: 'rgba(25,83,13,.7)',
                borderColor: '#176bf1',
                backgroundColor: 'rgba(200,200,200,0)',
                data: [],
            },
            liveViews: {
                label: 'Live',
                // borderColor: 'rgba(25,200,13,.7)',
                borderColor: 'rgb(0,150,136)',
                backgroundColor: 'rgba(200,200,200,0)',
                data: [],
            },
            storyViews: {
                label: 'Story',
                borderColor: '#0d3d60',
                backgroundColor: 'rgba(200,200,200,0)',
                data: [],
            },
            tributeViews: {
                label: 'Tribute',
                borderColor: 'rgb(103,58,183)',
                backgroundColor: 'rgba(200,200,200,0)',
                data: [],
            },
        };
    },
    methods: {
        formatNumber(number) {
            return number.toLocaleString();
        },
        mountChartData(data) {
            this.data = data;

            this.labels = [];
            this.allViews.data = [];
            this.delayedViews.data = [];
            this.liveViews.data = [];
            this.storyViews.data = [];
            this.tributeViews.data = [];

            let arr = this.data;

            if (this.data) {
                arr.forEach(item => {
                    if (item.viewDate) {
                        if (this.interval == 'all') {
                            if (this.serviceId > 0) {
                                this.labels.push(this.$moment.utc(item.viewDate).format('l'));
                            } else {
                                this.labels.push(this.$moment.utc(item.viewDate).format('YYYY'));
                            }
                        } else if (this.interval == 'year') {
                            this.labels.push(this.$moment.utc(item.viewDate).format('MMM YYYY'));
                        } else {
                            this.labels.push(this.$moment.utc(item.viewDate).format('l'));
                        }
                    }
                    this.allViews.data.push(item.allViews ? item.allViews : 0);
                    this.delayedViews.data.push(item.delayedViews ? item.delayedViews : 0);
                    this.liveViews.data.push(item.liveViews ? item.liveViews : 0);
                    this.storyViews.data.push(item.storyViews ? item.storyViews : 0);
                    this.tributeViews.data.push(item.tributeViews ? item.tributeViews : 0);
                });

                this.datacollection = {
                    labels: this.labels,
                    datasets: [this.allViews, this.delayedViews, this.liveViews],
                };

                if (this.sumViewsArray(this.storyViews.data) > 0) {
                    this.datacollection.datasets.push(this.storyViews);
                }

                if (this.sumViewsArray(this.tributeViews.data) > 0) {
                    this.datacollection.datasets.push(this.tributeViews);
                }
            }
            this.mounted = true;
        },
        sumViewsArray(views) {
            return views.reduce((prevValue, currentValue) => prevValue + currentValue, 0);
        },

        fetchChartData(intervalType) {
            const startTime = new Date();

            const params = {
                serviceId: this.serviceId || 0,
                funeralHomeId: this.funeralHomeId || 0,
                startDate: this.startDate,
                endDate: this.endDate,
            };

            this.loading = true;
            return this.axiosInstance
                .get(`/dogs/get/views/line-chart`, { params })
                .then(response => {
                    this.lineChartData[intervalType] = response.data.views;
                    this.mountChartData(response.data.views);
                    this.loading = false;
                    return response.data.views;
                })
                .catch(error => {
                    console.log('error');
                });
        },

        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.fetchChartData(this.interval);
    },
    components: {
        LineChart,
    },
    computed: {
        intervalTitle() {
            if (this.interval == 'year') {
                return 'Yearly Views';
            } else if (this.interval == 'month') {
                return 'Monthly Views';
            } else if (this.interval == 'week') {
                return 'This Week Views';
            } else {
                return 'Views';
            }
        },
    },
    watch: {
        interval(newVal, oldVal) {
            let interval = newVal;

            if (this.lineChartData[interval]) {
                this.mountChartData(this.lineChartData[interval]);
            } else {
                this.fetchChartData(interval);
            }
        },
        startDate(newVal) {
            if (this.interval != 'custom' && this.lineChartData[this.interval]) {
                this.mountChartData(this.lineChartData[this.interval]);
            } else {
                this.fetchChartData(this.interval);
            }
        },
        endDate() {
            if (this.interval != 'custom' && this.lineChartData[this.interval]) {
                this.mountChartData(this.lineChartData[this.interval]);
            } else {
                this.fetchChartData(this.interval);
            }
        },
    },
};
</script>

<style lang="scss">
.analytics-chart {
    .chart-container {
        #line-chart {
            max-height: 350px;
        }
    }
}
</style>

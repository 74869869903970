<template>
    <div class="event-action-container">
        <div v-if="renderComponent" class="loader-content">
            <h2>{{ event.title }} is currently processing.</h2>
            <p>This usually takes 15-45 minutes</p>
            <div class="loading"><i></i><i></i><i></i><i></i></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            renderComponent: true,
        };
    },
    methods: {
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
    },
    watch: {
        event: function () {
            this.forceRerender();
        },
    },
};
</script>

<style lang="scss" scoped>
.event-action-container {
    background: #f8f8f8;
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: center;

    .loader-content {
        display: flex;
        flex-direction: column;
        gap: 4;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        text-align: center;
    }
}

.loading {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    animation: loading-ani1 4s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    margin-bottom: 30px;
    margin-top: 30px;
}
.loading i {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 0.3rem;
    position: absolute;
}
.loading i:nth-child(1) {
    background: #ff530c;
    transform: translate(0, 0);
    animation: loading-ani2 4s ease-in-out infinite;
}
.loading i:nth-child(2) {
    background: #0b3c60;
    transform: rotate(90deg) translate(0, 0);
    animation: loading-ani3 6s ease-in-out infinite;
}
.loading i:nth-child(3) {
    background: #ab1b58;
    transform: rotate(180deg) translate(0, 0);
    animation: loading-ani4 8s ease-in-out infinite;
}
.loading i:nth-child(4) {
    background: #df2000;
    transform: rotate(270deg) translate(0, 0);
    animation: loading-ani5 10s ease-in-out infinite;
}
@keyframes loading-ani1 {
    25% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(225deg);
    }
    75% {
        transform: rotate(315deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
@keyframes loading-ani2 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: translate(0, 40px);
    }
}
@keyframes loading-ani3 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(90deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(90deg) translate(0, 40px);
    }
}
@keyframes loading-ani4 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(180deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(180deg) translate(0, 40px);
    }
}
@keyframes loading-ani5 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(270deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(270deg) translate(0, 40px);
    }
}
body {
    margin: 0;
    background: #fafafa;
}
</style>

<template>
    <div>
        <div @click="handleAppRedirect()" id="download-banner" class="download-banner p-2 elevation-4">
            <div class="d-flex">
                <img
                    style="max-width: 50px; border-radius: 5px"
                    :src="os == 'ios' ? msScannerIconSrc : googleScannerIconSrc"
                />
                <div class="d-flex flex-column ml-2">
                    <strong>Scanner App</strong>
                    <span style="font-size: 0.9rem; color: #999999">Download the App</span>
                </div>
            </div>
            <v-btn @click="handleAppRedirect()" rounded :color="isIOS ? '#ab0658' : '#ff530d'" dark depressed
                >Open</v-btn
            >
        </div>

        <!-- <v-dialog v-model="displaySplash" fullscreen transition="dialog-bottom-transition">
            <v-card class="d-flex flex-column justify-center">
                <div v-if="isIOS" style="gap: 20px" class="d-flex flex-column p-3">
                    <div class="text-center">
                        <img style="max-width: 100px; border-radius: 5px" :src="msScannerIconSrc" />
                    </div>
                    <p class="text-center">
                        For an enhanced, feature-rich experience, we strongly recommend using our
                        <strong>Scanner App</strong>. However, you're welcome to continue in your browser.
                    </p>
                    <v-btn @click="handleAppRedirect()" color="#ab0658" dark x-large>Open App</v-btn>
                    <v-btn @click="displaySplash = false" x-large>Continue in browser</v-btn>
                </div>
                <div v-else style="gap: 20px" class="d-flex flex-column p-3">
                    <div class="text-center">
                        <img style="max-width: 100px; border-radius: 5px" :src="googleScannerIconSrc" />
                    </div>
                    <p class="text-center">
                        For an enhanced, feature-rich experience, we strongly recommend using a Scanner App. However,
                        you're welcome to continue in your browser.
                    </p>
                    <v-btn @click="handleAppRedirect()" dark x-large>Open App</v-btn>
                    <v-btn @click="displaySplash = false" x-large>Continue in browser</v-btn>
                </div>
            </v-card>
        </v-dialog> -->
    </div>
</template>
<script>
import googleScannerIconSrc from '@/assets/images/GoogleScannerIcon.png';
import msScannerIconSrc from '@/assets/images/msScannerIcon.png';
export default {
    data() {
        return {
            googleScannerIconSrc,
            msScannerIconSrc,
            displaySplash: false,
        };
    },
    props: {
        appId: {
            type: String,
            required: true,
        },
        os: {
            type: String,
            required: true,
        },
    },
    computed: {
        isIOS() {
            if (!this.os) return false;

            return this.os.toLowerCase() == 'ios';
        },
        isAndroid() {
            if (!this.os) return false;

            return this.os.toLowerCase() == 'android';
        },
    },
    methods: {
        handleAppRedirect() {
            if (this.isIOS) {
                // const appId = '1665247224';
                const appStoreUrl = `https://apps.apple.com/app/id${this.appId}`;

                window.open(appStoreUrl, '_blank');
                //window.open('https://testflight.apple.com/join/BYbAjbOd', '_blank');
            } else {
                window.open('https://play.google.com/store/search?q=google+photo+scan&c=apps&hl=en_US&gl=US', '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.download-banner {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: white;
    // color: white;
    display: none;
    justify-content: space-between;
    align-items: center;
}
</style>

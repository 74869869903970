<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="tableItems"
            :loading="tableLoading"
            :single-expand="true"
            :server-items-length="tableTotalCount"
            :expanded.sync="expanded"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
                showFirstLastPage: true,
            }"
            show-expand
        >
            <template v-slot:item.dateTimeUTC="{ item }">
                <span>{{ $moment(item.dateTimeUTC).format('MM/DD/YYYY - h:mm a') }}</span>
            </template>
            <template v-slot:expanded-item="{ item }">
                <div class="half-expanded">
                    <section>
                        <h3>Old Values</h3>
                        <div>{{ item.oldValuse || 'N/A' }}</div>
                    </section>

                    <section>
                        <h3>New Values</h3>
                        <div>{{ item.newValues || 'N/A' }}</div>
                    </section>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            expanded: [],
            token: '',
            tableLoading: true,
            headers: [],
            tableItems: [],
            tableTotalCount: 0,
            options: {
                itemsPerPage: 25,
            },
        };
    },
    watch: {
        options: function () {
            if (!this.options || !this.token) return;
            this.fetchData();
        },
    },
    methods: {
        fetchData: function () {
            this.tableLoading = true;
            this.tableItems = [];
            const query = `${process.env.VUE_APP_API}${this.url}`;

            const params = {
                pageSize: this.options?.itemsPerPage || 25,
                pageNumber: this.options?.page - 1 || 0,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(query, { params })
                .then(response => {
                    this.tableLoading = false;
                    this.tableItems = response.data.audits;
                    this.tableTotalCount = response.data.total;
                })
                .catch(error => {
                    console.log(error);
                    this.tableLoading = false;
                    // this.$swal.fire({
                    //     icon: 'error',
                    //     title: 'Error!',
                    //     text: error.response.data.Message,
                    // });
                });
        },
    },
    mounted: function () {
        this.$auth.getIdTokenClaims().then(result => {
            // Token
            this.token = result.__raw;

            // Set Headers
            this.headers = [
                { text: 'User Email', value: 'userEmail' },
                { text: 'Type', value: 'type' },
                { text: 'Table Name', value: 'tableName' },
                { text: 'Date', value: 'dateTimeUTC' },
                // { text: 'Old Values', value: 'oldValues' },
                // { text: 'New Values', value: 'newValues' },
            ];

            // Fetch Data
            this.fetchData();
        });
    },
};
</script>

<style>
.half-expanded {
    width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
}

.half-expanded section {
    word-break: break-all;
}
</style>

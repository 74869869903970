<template>
    <div class="subscription-container">
        <!-- START: Standard Price Data -->
        <v-row>
            <v-col>
                <v-row v-if="!subscriptionData.promo">
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-col style="padding: 0">
                            <h4 class="elevation-2" id="currentPlanBadge">Current Plan</h4>
                        </v-col>
                    </v-row>
                </v-row>
                <div class="info-card">
                    <div :class="subscriptionData.promo ? 'secondary-price' : 'primary-price'">
                        <div class="p-3" v-if="subscriptionData.promo">
                            <v-chip outlined label>
                                Standard pricing starts {{ subscriptionData.promoEndDate | nextDay() }}
                            </v-chip>
                        </div>

                        <v-row class="text-center">
                            <v-col
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="0"
                            >
                                <h4 class="pb-3"><u>Services</u></h4>
                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="serviceDialog = true"
                                    bordered
                                    :color="subscriptionData.promo ? 'gray' : 'info'"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>{{ subscriptionData.streamsPerMonth }}</h3>
                                </v-badge>
                                <h3 class="mb-6 pt-1" v-if="subscriptionData.promo">
                                    {{ subscriptionData.streamsPerMonth }}
                                </h3>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="3"
                            >
                                <h4 class="pb-3"><u>Stream Length</u></h4>
                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="maxStreamsDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>{{ subscriptionData.maxStreamLength.toLocaleString('en-US') }} min.</h3>
                                </v-badge>
                                <h3 class="mb-6 pt-1" v-if="subscriptionData.promo">
                                    {{ subscriptionData.maxStreamLength.toLocaleString('en-US') }} min.
                                </h3>
                            </v-col>
                            <v-col
                                v-if="subscriptionData.tributeVideosEnabled"
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="1"
                            >
                                <h4 class="pb-3"><u>Tribute Videos</u></h4>
                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="tributeVideosPerMonthDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3 v-if="subscriptionData.tributeVideosPerMonth >= 0">
                                        {{ subscriptionData.tributeVideosPerMonth.toLocaleString('en-US') }}
                                    </h3>
                                    <h3 v-else>Unlimited</h3>
                                </v-badge>
                            </v-col>
                            <v-col
                                v-if="subscriptionData.tributeVideosEnabled"
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="4"
                            >
                                <h4 class="pb-3"><u>Tribute Video Cost</u></h4>
                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="tributeCostDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3 v-if="subscriptionData.costPerTributeVideo >= 0">
                                        ${{ (subscriptionData.costPerTributeVideo / 100).toLocaleString('en-US') }}
                                    </h3>
                                </v-badge>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="2"
                            >
                                <h4 class="pb-3"><u>Views</u></h4>
                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="viewsDialog = true"
                                    bordered
                                    :color="subscriptionData.promo ? 'gray' : 'info'"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>
                                        {{ subscriptionData.maxViewers }}
                                    </h3>
                                </v-badge>
                                <h3 class="mb-6 pt-1" v-if="subscriptionData.promo">
                                    {{ subscriptionData.maxViewers }}
                                </h3>
                            </v-col>

                            <v-col
                                cols="12"
                                lg="6"
                                :xl="subscriptionData.tributeVideosEnabled ? '4' : '6'"
                                order-xl="5"
                            >
                                <h4 class="pb-3"><u>Subscription Cost</u></h4>

                                <v-badge
                                    v-if="!subscriptionData.promo"
                                    class="pb-3 btn"
                                    @click.native="subscriptionDialog = true"
                                    bordered
                                    :color="subscriptionData.promo ? 'gray' : 'info'"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>${{ (subscriptionData.subscriptionCost / 100).toLocaleString('en-US') }}</h3>
                                </v-badge>
                                <h3 class="mb-6 pt-1" v-if="subscriptionData.promo">
                                    ${{ (subscriptionData.subscriptionCost / 100).toLocaleString('en-US') }}
                                </h3>
                            </v-col>
                        </v-row>
                    </div>
                    <v-spacer style="min-height: 40px" v-if="subscriptionData.promo"></v-spacer>
                    <div v-if="subscriptionData.promo" class="promo elevation-4">
                        <div style="display: flex; justify-content: space-between" class="p-3">
                            <v-chip color="primary" outlined label>
                                Promo ending {{ subscriptionData.promoEndDate | moment('l') }}
                            </v-chip>
                            <v-chip color="#00C853" outlined label>
                                <font-awesome-icon class="mr-2" icon="fa-regular fa-circle-check" />
                                Current Plan
                            </v-chip>
                        </div>

                        <v-row class="text-center">
                            <v-col>
                                <h4 class="pb-3"><u>Services</u></h4>
                                <v-badge
                                    class="pb-3 btn"
                                    @click.native="serviceDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3 v-if="subscriptionData.promoStreamsPerMonth == 199">Unlimited</h3>
                                    <h3 v-else>{{ subscriptionData.promoStreamsPerMonth }}</h3>
                                </v-badge>
                            </v-col>
                            <v-col>
                                <h4 class="pb-3"><u>Views</u></h4>
                                <v-badge
                                    class="pb-3 btn"
                                    @click.native="viewsDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>
                                        {{ subscriptionData.maxViewers }}
                                    </h3>
                                </v-badge>
                            </v-col>
                        </v-row>
                        <v-row class="text-center">
                            <v-col>
                                <h4 class="pb-3"><u>Stream Length</u></h4>
                                <v-badge
                                    class="pb-3 btn"
                                    @click.native="maxStreamsDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>{{ subscriptionData.maxStreamLength.toLocaleString('en-US') }} min.</h3>
                                </v-badge>
                            </v-col>
                            <v-col>
                                <h4 class="pb-3"><u>Subscription Cost</u></h4>

                                <v-badge
                                    class="pb-3 btn"
                                    @click.native="subscriptionDialog = true"
                                    bordered
                                    color="info"
                                    offset-x="-10"
                                    icon="mdi-help"
                                    overlap
                                >
                                    <h3>${{ (subscriptionData.promoPrice / 100).toLocaleString('en-US') }}</h3>
                                </v-badge>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>

        <!-- START: Dialogs -->
        <!-- START: Service -->
        <v-dialog v-model="serviceDialog" @click:outside="serviceDialog = false" width="500">
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What happens if I go over?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>
                        You can stream as many services as you need. Any service after your
                        <span v-if="subscriptionData.promo">
                            {{
                                subscriptionData.promoStreamsPerMonth == 199
                                    ? 'unlimited'
                                    : subscriptionData.promoStreamsPerMonth.toLocaleString('en-US')
                            }}
                        </span>
                        <span v-else>
                            {{ subscriptionData.streamsPerMonth.toLocaleString('en-US') }}
                        </span>
                        services will be billed at a rate of ${{
                            (subscriptionData.costPerAdditionalStream / 100).toLocaleString('en-US')
                        }}
                        per service. If you are frequently going over this amount, upgrading your subscription may be
                        more cost effective. Contact us to learn more.
                    </h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Service -->

        <!-- START: Views -->
        <v-dialog v-model="viewsDialog" @click:outside="viewsDialog = false" width="500">
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What is this?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>
                        Each service is allowed a maximum of
                        {{ subscriptionData.maxViewers.toLocaleString('en-US') }} viewers. After this many viewers we
                        will add an additional ${{ (subscriptionData.costPer100Viewers / 100).toLocaleString('en-US') }}
                        onto your account. If you have a service coming up, and you are expecting a lot of viewers,
                        please reach out and we may be able to provide a one time waiver to this charge.
                    </h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Views -->

        <!-- START: Stream Length -->
        <v-dialog v-model="maxStreamsDialog" @click:outside="maxStreamsDialog = false" width="500">
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What is this?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>
                        For each service you are allowed a maximum stream length of
                        {{
                            subscriptionData.promo
                                ? subscriptionData.promoStreamsPerMonth.toLocaleString('en-US')
                                : subscriptionData.streamsPerMonth.toLocaleString('en-US')
                        }}
                        minutes. This limit applies to the sum of each event's total stream length. After you stream
                        more than {{ subscriptionData.maxStreamLength.toLocaleString('en-US') }} minutes you will be
                        billed ${{ (subscriptionData.costPerFifteenMinute / 100).toLocaleString('en-US') }}
                        for each 15 minutes of streaming time. If you find yourself frequently going over this amount,
                        you can contact support about available plan upgrades and pricing.
                    </h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Stream Length -->

        <!-- START: Cost -->
        <v-dialog v-model="subscriptionDialog" @click:outside="subscriptionDialog = false" width="500">
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What is this?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>
                        This is the {{ subscriptionData.billedAnnually ? 'annual' : 'monthly' }}
                        fee you pay for access to the MemoryShare platform.
                    </h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Cost -->

        <!-- START: Tribute Cost -->
        <v-dialog v-model="tributeCostDialog" @click:outside="tributeCostDialog = false" width="500">
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What is this?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>This is the fee you pay for each tribute video created with the MemoryShare platform.</h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Tribute Cost -->

        <!-- START: Tributes per month -->
        <v-dialog
            v-model="tributeVideosPerMonthDialog"
            @click:outside="tributeVideosPerMonthDialog = false"
            width="500"
        >
            <v-card class="text-center">
                <v-card-title class="justify-center">
                    <h3>What is this?</h3>
                </v-card-title>

                <v-card-text>
                    <h5>This is the number of tribute videos included with your MemoryShare subscription.</h5>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- END: Tributes per month -->

        <!-- END: Dialogs -->
    </div>
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            message: 'hi',
            token: '',
            serviceDialog: false,
            viewsDialog: false,
            maxStreamsDialog: false,
            subscriptionDialog: false,
            tributeCostDialog: false,
            tributeVideosPerMonthDialog: false,
        };
    },
    props: {
        subscriptionData: {
            type: Object,
            required: true,
        },
    },
    methods: {},
    filters: {
        nextDay(val) {
            let next = moment(val).add(1, 'd').format('l');
            return next;
        },
    },
};
</script>
<style lang="scss">
.subscription-container {
    container-type: inline-size;
}

#currentPlanBadge {
    background-color: white;
    border: 2px solid cornflowerblue;
    width: 170px;
    border-radius: 5px;
    padding: 5px;
    color: black;
    text-align: center;
    margin-bottom: 5px;
}
.info-card {
    // border: 2px solid cornflowerblue;
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    h4 {
        color: cornflowerblue;
    }
}

.subscriptionInfoBadge:hover {
    cursor: pointer;
}

.promo {
    border: 2px solid cornflowerblue;
    border-radius: 5px;
    flex-grow: 1;
}

.primary-price {
    flex-grow: 1;
    padding-top: 24px;
    border: 2px solid cornflowerblue;
    border-radius: 5px;
}
.secondary-price {
    border: 2px solid gray;
    border-radius: 5px;
    background-color: #f1f4f7;
    flex-grow: 1;
    h4,
    h3,
    .btn,
    .v-badge {
        color: gray;
    }
}

@container (max-width: 800px) {
    .info-card {
        flex-direction: column-reverse;
    }
}
</style>

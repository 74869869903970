<template>
    <div>
        <v-alert
            v-model="displayPromoBanner"
            class="text-center elevation-4"
            color="#005069"
            dark
            transition="slide-y-transition"
        >
            <!-- 199 represents unlimited promo streams -->
            <span v-if="promoStreamsPerMonth == 199">
                We hope you are enjoying your unlimited plan! This offer expires
                {{ promoEndDate | moment('l') }}.
            </span>
            <span v-else-if="promoEndDate">
                You are currently on promotional pricing. This plan expires
                {{ promoEndDate | moment('l') }}.
            </span>
            <a href="/settings?tab=subscription"
                ><u><strong>Click here for details</strong></u></a
            >

            <template v-slot:prepend>
                <font-awesome-icon style="font-size: 1.6rem" icon="fa-solid fa-badge-dollar" />
            </template>
            <template v-slot:append>
                <font-awesome-icon
                    v-if="!$auth.impersonating"
                    @click="dismissPromoBanner"
                    style="font-size: 1.6rem; cursor: pointer"
                    icon="fa-solid fa-circle-xmark"
                />
            </template>
        </v-alert>
    </div>
</template>
<script>
import { getWithExpiry, setWithExpiry } from '@/utilities/general.js';
import moment from 'moment';
export default {
    data() {
        return {
            promoEndDate: null,
            promoStreamsPerMonth: 0,
        };
    },
    computed: {
        displayPromoBanner() {
            if (
                !this.$auth.role.includes('SuperAdmin') &&
                this.$auth.session &&
                this.$auth.session.home &&
                this.$auth.session.home.subscriptionStatus === 10 &&
                this.$store.state.displayPromoBanner
            ) {
                return true;
            }
            return false;
        },
    },
    methods: {
        dismissPromoBanner() {
            this.$store.dispatch('updateDisplayPromoBanner', false);
            // 2592000000 - 30 Days
            setWithExpiry(
                'bannerCookie',
                {
                    fhId: this.$auth.session.home.id,
                    dismissed: true,
                },
                2592000000,
            );
        },
        async checkBannerCookie() {
            const bannerCookie = await getWithExpiry('bannerCookie');
            this.$store.dispatch('updateDisplayPromoBanner', true);
            if (bannerCookie && bannerCookie?.bannerCookie['fhId'] == this.$auth.session?.home?.id) {
                this.$store.dispatch('updateDisplayPromoBanner', false);
            } else {
                this.$store.dispatch('updateDisplayPromoBanner', true);
            }
        },
    },
    mounted: async function () {
        if (this.$auth.session?.home?.settings?.promoEndDate) {
            this.promoStreamsPerMonth = this.$auth.session.home.settings.promoStreamsPerMonth;
            this.promoEndDate = this.$auth.session.home.settings.promoEndDate;
        }
        await this.checkBannerCookie();
    },
};
</script>
<style lang=""></style>

<template>
    <div>
        <v-data-table
            :loading="loading"
            loading-text="Getting Songs..."
            :headers="headers"
            :items="songs"
            class="services-table"
            :style="{ paddingTop: '8px' }"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
                showFirstLastPage: true,
            }"
            :server-items-length="totalServices"
            :options.sync="options"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                    class="search-bar"
                    v-model="search"
                    label="Search"
                    append-icon="mdi-magnify"
                    single-line
                    hide
                    details
                ></v-text-field>
            </template>

            <template v-slot:[`item.eventTitle`]="{ item }">
                <a :href="'/services/' + item.serviceSlug">{{ item.eventTitle }}</a>
            </template>

            <template v-slot:[`item.song`]="{ item }">
                <!-- {{item.song}} -->

                <div id="licenseSongTable">
                    <p style="font-size: 15px; padding-right: 20px">
                        {{ item.song }}
                        <v-row
                            ><small style="padding-top: 10px; font-size: 9px; padding-left: 15px; font-weight: bold"
                                ><font color="green">{{ item.artist }}</font></small
                            ></v-row
                        >
                    </p>
                </div>
            </template>

            <template v-if="$auth.role.includes('SuperAdmin')" v-slot:[`item.actions`]="{ item }">
                <div id="musicIconGroup">
                    <div style="width: 46px; text-align: center">
                        <img v-if="item.actions.artworkUrl" width="40px" height="40px" :src="item.actions.artworkUrl" />
                    </div>

                    <div style="width: 30px; text-align: center">
                        <a v-if="item.actions.spotifyLink" :href="item.actions.spotifyLink" target="_blank">
                            <span style="font-size: 30px; color: #1db954" class="mdi mdi-spotify"></span>
                        </a>
                    </div>

                    <div style="width: 30px; text-align: center">
                        <music-track-player :item="item"></music-track-player>
                    </div>
                </div>

                <!-- add apple music icon and link it to :href="song.songLink" -->
            </template>
            <template v-else v-slot:[`item.actions`]="{ item }">
                <div id="musicIconGroup">
                    <div style="width: 46px; text-align: center">
                        <img v-if="item.actions.artworkUrl" width="40px" height="40px" :src="item.actions.artworkUrl" />
                    </div>

                    <div style="width: 30px; text-align: center">
                        <a v-if="item.actions.spotifyLink" :href="item.actions.spotifyLink" target="_blank">
                            <span style="font-size: 30px; color: #1db954" class="mdi mdi-spotify"></span>
                        </a>
                    </div>

                    <div style="width: 30px; text-align: center">
                        <music-track-player :item="item"></music-track-player>
                    </div>
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="deleteModal.show" :retain-focus="false" max-width="290">
            <v-card>
                <v-card-title class="headline"> Delete Service? </v-card-title>
                <v-card-text>{{ deleteModal.message }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModal.show = false"> Cancel </v-btn>
                    <v-btn color="red darken-1" text @click="deleteService(deleteModal.serviceId)"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <passare-button @sync-successful="getSongs"></passare-button> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { serviceStates } from '../../constants';
import debounce from '@/utilities/debounce.js';
import { getLocalSettings, setLocalSettings } from '@/utilities/general';
import MusicTrackPlayer from '@/components/Misc/MusicTrackPlayer.vue';

// TODO Make Live status update automatically?

export default {
    name: 'ServicesTable',
    components: {
        MusicTrackPlayer,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            songs: [],
            loading: false,
            token: '',
            search: '',
            headers: [],
            services: [],
            totalServices: null,
            deleteModal: {
                show: false,
                message: '',
                serviceId: null,
            },
            serviceStates,
            options: {},
            tableLoading: false,
            isPlaying: false,
        };
    },
    watch: {
        options: {
            handler() {
                if (!this.tableLoading) {
                    setLocalSettings('songsTable', {
                        search: this.search,
                        page: this.options.page,
                        pageSize: this.options.itemsPerPage,
                    });
                }

                if (this.token && this.axiosInstance) {
                    this.debouncedGetServices();
                }
            },
            deep: true,
        },
        search() {
            this.loading = true;
            this.debouncedGetServices();

            if (!this.tableLoading) {
                setLocalSettings('songsTable', {
                    search: this.search,
                    page: 1,
                    pageSize: this.options.itemsPerPage,
                });
                this.options = {
                    ...this.options,
                    page: 1,
                };
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },

        deleteService(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/Services/${id}`)
                .then(result => {
                    this.deleteModal.show = false;
                    const { name } = this.services.find(service => service.id === id);
                    const message = `Service for ${name} was deleted`;
                    this.showSnackbar({ message });
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                })
                .finally(() => {
                    this.getSongs();
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'name':
                    return 'FirstName';
                case 'id':
                    return 'Id';
                case 'views':
                    return 'TotalViews';
                case 'status':
                    return 'ServiceState';
                case 'homeName':
                    return 'FuneralHomeName';
                case 'created':
                    return 'CreatedDate';
                case 'private':
                    return 'private';
                case 'slug':
                    return 'slug';
                case 'nextevent':
                    return 'NextEventDate';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        handleGoToEndOfTable() {},
        getQueryParams() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy[0] ? this.getSortByValue(sortBy[0]) : null,
                sortAsc: sortBy[0] ? !sortDesc[0] : null,
                search: this.search || null,
            };
            return params;
        },
        async getSongs() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            if (
                this.$auth.role.includes('SuperAdmin') ||
                this.$auth.role.includes('Owner') ||
                this.$auth.role.includes('RegionalManager')
            ) {
                var homeId = this.$route.params.id;
            } else if (this.$auth.role.includes('FuneralHome')) {
                var homeId = this.$auth.funeralHomeId;
            }
            try {
                const response = await this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(
                        process.env.VUE_APP_API +
                            `/CopyrightMusic?funeralHomeId=${homeId}&search=${
                                this.search
                            }&pageSize=${itemsPerPage}&pageNumber=${page - 1}&sortBy=${this.sortBy}&sortAsc=${
                                this.sortAsc
                            }`,
                    );

                this.headers = [
                    { text: 'Service', value: 'eventTitle', width: '35%', sortable: false },
                    { text: 'Song', value: 'song', width: '40%', sortable: false },
                    { text: '', value: 'actions', width: '35%', sortable: true },
                ];

                this.totalServices = response.data.total;

                this.songs = response.data.copyrightMusics.map(song => {
                    return {
                        album: song.album,
                        artist: song.artist,
                        eventId: song.eventId,
                        eventTitle: song.eventTitle,
                        funeralHomeId: song.funeralHomeId,
                        id: song.id,
                        label: song.label,
                        ownerId: song.ownerId,
                        serviceId: song.serviceId,
                        song: song.song,
                        actions: {
                            previewUrl: song.previewUrl,
                            artworkUrl: song.artworkUrl,
                            songLink: song.songLink,
                            spotifyLink: song.spotifyLink,
                            spotifyTrackId: song.spotifyTrackId,
                        },
                        timeStamp: song.timeStamp,
                        videoId: song.videoId,
                        serviceSlug: song.serviceSlug,
                    };
                });
            } catch (error) {
                console.error(error);
                this.showSnackbar({
                    message:
                        'There was an error retrieving your data. Please contact support or try again in a few minutes.',
                });
            }

            this.loading = false;
        },
    },
    mounted: async function () {
        console.log(this.id, 'music table');
        // Get local settings
        const settings = await getLocalSettings();
        this.search = settings.songsTable.search || '';
        this.options = {
            itemsPerPage: settings.songsTable.pageSize || 10,
            page: settings.songsTable.page || 1,
        };

        this.tableLoading = false;
    },
    async created() {
        this.debouncedGetServices = debounce(this.getSongs, 500);
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getSongs();
    },
};
</script>

<style>
.search-bar {
    margin-left: auto;
    width: 30%;
}

.chip-container {
    position: relative;
    z-index: 1;
}

.chip {
    --border-radius: 15px;
    background: var(--background-color);
    border-radius: var(--border-radius);
    color: white;
    padding: 0.25em 0.7em;
    white-space: nowrap;
}

.chip--live {
    position: relative;
}

.chip--live::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: var(--border-radius);
    background-color: rgb(255, 82, 82);
    animation: 2s infinite pulse;
}
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    70% {
        transform: scale(1.4);
        opacity: 0;
    }

    100% {
        transform: scale(0.95);
        opacity: 0;
    }
}

.text-callout {
    background: #2275d7;
    color: #fff;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}

.text-callout-incomplete {
    background: #f6ff0c;
    color: rgb(31, 31, 31);
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}
/* .myMusic {
    border: 2px solid green;
} */
/* #licenseSongTable {
    border: 2px solid red;
} */
#musicIconGroup {
    display: flex;
    justify-content: space-evenly;
}
#musicIconGroup img {
    margin-top: 3px;
}
</style>

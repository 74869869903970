<template>
    <div v-if="autoShutoff && isValidDate">
        <v-alert class="my-6 text-center" :color="alertColor" text>
            <div></div>
            <h5 class="text-h5">Stream Auto Stop</h5>
            <v-divider class="my-4" :class="computedDividerColor" style="opacity: 0.2"></v-divider>
            <v-row align="center" no-gutters>
                <v-col class="grow">
                    <date-time-countdown
                        class="text-h5"
                        v-if="shutoffTime"
                        :dateTime="shutoffTime"
                        outputFormat="timestamp"
                    />
                </v-col>
                <!-- <v-spacer></v-spacer>
                <v-col class="shrink">
                    <v-btn @click="openAutoShutoffOverRideModal" color="info" outlined> Disable Auto Stop </v-btn>
                </v-col> -->
            </v-row>
        </v-alert>

        <!-- <v-dialog v-model="autoShutoffOverrideModal" max-width="400px">
            <v-card>
                <v-card-title>Disable Stream Auto Stop?</v-card-title>
                <v-card-text
                    >Maximum live stream length is 8 hours. If streams are left running longer than 8 hours, data loss
                    may occurr.
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </div>
</template>
<script>
import DateTimeCountdown from '@/components/ui/DateTimeCountdown.vue';
export default {
    data() {
        return {
            shutoffTime: null,
            isValidDate: false,
            token: '',
            autoShutoffOverrideModal: false,
            alertColor: 'info',
            autoShutoff: false,
        };
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    watch: {
        event(newVal) {
            this.refreshCountdown();
        },
    },
    components: { DateTimeCountdown },
    methods: {
        openAutoShutoffOverRideModal() {
            this.autoShutoffOverrideModal = true;
        },
        validateDate(date) {
            const targetTime = this.$moment.utc(date);
            return targetTime.isValid();
        },
        async refreshCountdown() {
            console.log('refreshing countdown');
            if (!this.event.liveStreamId) return;

            const liveStream = await this.getLiveStream(this.event.liveStreamId);

            console.log('ls', liveStream);
            this.autoShutoff = liveStream.autoShutoff;

            if (!liveStream.startedTime) return;

            const isValid = this.validateDate(liveStream.startedTime);

            if (!isValid) return;

            const offset = this.$moment.utc(new Date()).utcOffset();
            const targetTime = this.$moment.utc(liveStream.startedTime).utcOffset(offset).add(8, 'h');

            const now = this.$moment.utc(new Date()).utcOffset(offset);

            this.shutoffTime = targetTime.toISOString();

            const hourDiff = targetTime.diff(now, 'hours');

            this.setAlertColor(hourDiff);

            this.isValidDate = isValid;
        },
        setAlertColor(hourDiff) {
            if (hourDiff < 1) {
                this.alertColor = 'error';
            } else if (hourDiff < 4) {
                this.alertColor = 'warning';
            } else {
                this.alertColor = 'info';
            }
        },
        async getLiveStream(id) {
            const { data, error } = await this.axiosInstance.get(`/live/${id}`);

            if (error) {
                console.log(error, 'error');
                return;
            }

            return data;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.refreshCountdown();
    },
    computed: {
        computedDividerColor() {
            const colorAliases = ['info', 'warning', 'error'];
            if (colorAliases.includes(this.alertColor)) {
                return this.alertColor;
            }
        },
    },
};
</script>
<style lang=""></style>

<template>
    <div class="p-4 border">
        <div
            class="d-flex justify-content-between align-items-center border-bottom border-light py-1"
            v-for="item in this.items"
            v-bind:key="item.id"
        >
            <div class="d-flex align-items-center">
                <div v-if="type == 'genre'" class="mr-2 cover-img-container">
                    <v-img
                        @click="previewCover(item)"
                        v-if="item.image"
                        cover
                        :src="item.image"
                        :lazy-src="item.image"
                        class="fill-height cover-img"
                        max-width="100%"
                    ></v-img>
                    <div
                        v-else
                        @click="imgUploadInit(item)"
                        class="image-placeholder d-flex align-center justify-center"
                    >
                        <font-awesome-icon icon="fa-regular fa-image"></font-awesome-icon>
                    </div>
                </div>

                <span>{{ item.name }}</span>
            </div>

            <div class="d-flex">
                <SingleValueEditModal
                    :title="editTitle"
                    :inputValue="item.name"
                    @save="newValue => edit(item, newValue)"
                />

                <div
                    class="p-2 rounded-circle danger-btn d-flex justify-content-center"
                    style="width: 30px"
                    @click="onDelete(item)"
                >
                    <font-awesome-icon style="font-size: 0.9rem" icon="fa-regular fa-trash-can" />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <v-btn small class="mt-5" @click="$emit('input', false)">Close</v-btn>
        </div>

        <media-uploader
            ref="genreImgUpload"
            :token="token"
            :uploaderType="MEDIA_UPLOADER_TYPES.TRIBUTE_SONG_GENRE"
            :itemId="id"
            @upload-success="item => $emit('item-image-updated', item)"
        />
        <!-- @refresh="handlePhotoLicenseRefresh()" -->

        <v-dialog @click:outside="resetPreview" max-width="600px" v-model="previewModal">
            <v-card class="p-3" v-if="selectedItem">
                <v-img
                    v-if="selectedItem.image"
                    :src="selectedItem.image"
                    :lazy-src="selectedItem.image"
                    style="border-radius: 5px"
                    max-width="100%"
                ></v-img>

                <div class="mt-3 d-flex justify-end">
                    <v-btn @click="imgUploadInit(selectedItem)" fab depressed small class="mr-3">
                        <font-awesome-icon
                            style="font-size: 1rem"
                            icon="fa-regular fa-arrow-up-from-bracket"
                        ></font-awesome-icon>
                    </v-btn>

                    <div
                        @click="deleteGenreImageStep1"
                        class="danger-btn rounded-circle d-flex justify-content-center align-items-center"
                        style="width: 40px; height: 40px"
                    >
                        <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-trash-can"></font-awesome-icon>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="genreImageDeleteModal" max-width="450">
            <v-card v-if="selectedItem">
                <v-card-title>Delete image for {{ selectedItem.name }}?</v-card-title>
                <v-card-text> This cannot be undone, confirm to continue.</v-card-text>
                <div style="display: flex; justify-content: space-between" class="p-3">
                    <v-btn depressed @click="genreImageDeleteModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="deleteGenreImageSubmit(selectedItem.id)">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SingleValueEditModal from './SingleValueEditModal.vue';
import MediaUploader from '@/components/ui/MediaUploader.vue';
import { mapActions } from 'vuex';
import { MEDIA_UPLOADER_TYPES } from '@/constants';
export default {
    data() {
        return {
            MEDIA_UPLOADER_TYPES,
            id: null,
            isEditingItem: false,
            token: '',
            selectedItem: null,
            previewModal: false,
            genreImageDeleteModal: false,
        };
    },
    props: ['type', 'items', 'modelValue'],
    components: {
        SingleValueEditModal,
        MediaUploader,
    },
    computed: {
        editTitle() {
            return `Update ${this.type}`;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        deleteGenreImageStep1() {
            this.genreImageDeleteModal = true;
        },
        async deleteGenreImageSubmit(id) {
            if (!this.token) {
                await this.setAuthToken();
            }

            const fileName = '';
            this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + '/MusicGenre/image/' + id, JSON.stringify(fileName))
                .then(res => {
                    this.$emit('item-image-updated', res.data);
                    this.genreImageDeleteModal = false;
                    this.previewModal = false;
                    this.showSnackbar({ message: 'Image deleted' });
                    this.selectedItem = null;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        resetPreview() {
            this.selectedItem = null;
            this.previewModal = false;
        },
        previewCover(item) {
            this.selectedItem = item;
            this.previewModal = true;
        },
        async imgUploadInit(item) {
            this.id = item.id;
            this.resetPreview();
            if (!this.token) {
                await this.setAuthToken();
            }
            this.$refs.genreImgUpload.handleOpen();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        onDelete(item) {
            this.$emit('delete', item);
        },

        edit(item, newValue) {
            // find item in items array and update it
            const index = this.items.findIndex(i => i.id === item.id);
            this.items[index].name = newValue;
            this.$emit('edit', item, newValue);
        },
    },
};
</script>

<style lang="scss">
.danger-btn {
    background-color: #ffefef;
    color: #e11414;
    transition: 0.2s;
    cursor: pointer;
}

.danger-btn:hover {
    background-color: #e11414;
    color: #fff;
}

.secondary-btn {
    background-color: #f5f5f5;
    color: #000;
    transition: 0.2s;
    cursor: pointer;
}

.secondary-btn:hover {
    background-color: #e0e0e0;
    color: #000;
}

.cover-img-container {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    transition: 0.3s;
}
.cover-img {
    border-radius: 5px;
}
.cover-img:hover {
    filter: brightness(50%);
}

.image-placeholder {
    border: 1px solid #eee;
    height: 100%;
    border-radius: 5px;
}

.image-placeholder:hover {
    background-color: #f5f5f5;
}
</style>

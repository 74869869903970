<template>
    <v-row justify="center">
        <v-dialog v-model="openDialog" max-width="60vw">
            <v-card>
                <v-card-title>
                    <div class="headline">
                        <font-awesome-icon icon="fa-regular fa-user-group" class="mr-2" />
                        <span v-if="editingUser">{{ userId > 0 ? 'Edit' : 'Create' }} User</span>
                        <span v-else-if="adminEditingUser">Admin Edit User</span>
                        <span v-else>Manage Users</span>
                    </div>

                    <v-spacer></v-spacer>
                    <v-btn v-if="!editingUser && !adminEditingUser" @click="initUpdateUserForm()" color="primary"
                        >+ Add New user</v-btn
                    >
                    <span v-if="editingUser && userId > 0 && !emailVerified" style="opacity: 0.5">PENDING </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-dialog v-model="deleteModal" persistent max-width="290">
                            <v-card v-if="selectedUser">
                                <v-card-title class="headline"> Delete Users? </v-card-title>
                                <v-card-text
                                    >Are you sure you want to delete
                                    {{ selectedUser.firstName + ' ' + selectedUser.lastName }}?</v-card-text
                                >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                                    <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <reset-password
                            v-if="passwordResetModal && token"
                            :token="token"
                            :user="selectedUser"
                            @close-password-reset-modal="passwordResetModal = false"
                        ></reset-password>
                        <v-data-table
                            v-if="!editingUser && !adminEditingUser"
                            :headers="headers"
                            :items="users"
                            :loading="loading"
                            :footer-props="{
                                itemsPerPageOptions: [10, 25, 50],
                            }"
                            :options.sync="options"
                            loading-text="Getting Users..."
                        >
                            <template v-slot:item.name="{ item }">
                                <p class="mb-0">{{ item.firstName + ' ' + item.lastName }}</p>
                                <small v-if="!item.emailVerified" style="opacity: 0.7">Pending</small>
                            </template>

                            <!-- <template v-slot:[`item.blocked`]="{ item }">
                                <v-chip v-if="item.blocked" small dark color="red">Blocked</v-chip>
                            </template> -->

                            <template v-slot:[`item.userType`]="{ item }">
                                <div v-if="item.blocked">
                                    <v-chip x-small dark :color="userTypes[item.userType].color">{{
                                        userTypes[item.userType].label | labelFilter()
                                    }}</v-chip>
                                    <v-chip x-small dark class="mt-1" color="red">Blocked</v-chip>
                                </div>
                                <div v-else>
                                    <v-chip small dark :color="userTypes[item.userType].color">{{
                                        userTypes[item.userType].label | labelFilter()
                                    }}</v-chip>
                                </div>
                            </template>

                            <template v-slot:item.email="{ item }">
                                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                            </template>

                            <template v-slot:[`item.lastLogin`]="{ item }">
                                <div v-if="auth0loading">
                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                </div>
                                <div v-else-if="item.lastLogin">
                                    {{ item.lastLogin | dateFormat() }}
                                </div>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <div class="action-list">
                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-pencil"
                                                    @click="initUpdateUserForm(item)"
                                                    style="font-size: 1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Edit</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip v-if="!item.emailVerified" :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-envelope"
                                                    style="font-size: 1rem"
                                                    @click="resendEmailInvite(item)"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Resend Email Invite</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip v-if="item.emailVerified" :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openPasswordResetModal(item)">
                                                <font-awesome-icon
                                                    icon="fa-regular fa-lock"
                                                    style="font-size: 1.1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Password</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openPasswordResetEmailModal(item)">
                                                <font-awesome-icon icon="fa-regular fa-key" style="font-size: 1.1rem" />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Password Reset Email</span>
                                        </template>
                                    </custom-tooltip>

                                    <!-- separator -->
                                    <div>||</div>

                                    <custom-tooltip
                                        v-if="$auth.role.includes('SuperAdmin')"
                                        :tooltipProps="{ bottom: true }"
                                    >
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-pen-to-square"
                                                    style="font-size: 1rem"
                                                    @click="adminUpdateUser(item)"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Super Admin Edit</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openDeleteModal(item)">
                                                <font-awesome-icon
                                                    icon="fa-regular fa-trash-can"
                                                    style="font-size: 1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Delete</span>
                                        </template>
                                    </custom-tooltip>
                                </div>
                            </template>
                        </v-data-table>

                        <!-- Edit Modal -->
                        <div v-else>
                            <v-form v-if="!adminEditingUser" class="form-container" ref="form" v-model="formValidity">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="userForm.firstName"
                                            label="First Name"
                                            :rules="[v => !!v || 'First name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="userForm.lastName"
                                            label="Last Name"
                                            :rules="[v => !!v || 'Last name is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :disabled="updatingUser"
                                            v-model="userForm.email"
                                            type="email"
                                            label="Email"
                                            :rules="emailRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="userForm.userType"
                                            :items="ownerTypes"
                                            item-text="text"
                                            item-value="value"
                                            label="User Type"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-mask="'###.###.####'"
                                            v-model="userForm.phoneNo"
                                            label="Phone"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-alert v-if="errorMessage.length" type="error">
                                    {{ errorMessage }}
                                </v-alert>

                                <div class="mt-4">
                                    <v-btn
                                        v-if="editingUser && userId > 0 && !emailVerified"
                                        @click="manualshow = true"
                                        color="primary"
                                        text
                                    >
                                        Set Password</v-btn
                                    >
                                    <v-btn
                                        v-if="!emailVerified && userId > 0"
                                        color="blue darken-2"
                                        text
                                        @click="geninviteLink(userId)"
                                    >
                                        Copy Invite Link</v-btn
                                    >
                                </div>

                                <div class="mt-4">
                                    <v-btn
                                        :disabled="!formValidity"
                                        @click.prevent="submitUserForm()"
                                        color="primary"
                                        >{{ updatingUser ? 'Update User' : 'Create User' }}</v-btn
                                    >
                                    <!-- <v-btn @click="cancelUserForm()" text class="ml-4">cancel</v-btn> -->
                                </div>
                            </v-form>
                        </div>
                        <!-- End Edit Modal -->

                        <!-- admin edit modal -->
                        <div v-if="adminEditingUser">
                            <v-form class="form-container" ref="SuperAdminform" lazy-validation>
                                <v-row>
                                    <v-col class="text-left">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dark
                                                    :color="userTypes[userForm.userType].color"
                                                    >{{ userTypes[userForm.userType].label }}</v-chip
                                                >
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    @click="newType(index)"
                                                    v-for="(item, index) in userTypes"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="adminemailVerified"
                                                    dark
                                                    color="green"
                                                    >Verified</v-chip
                                                >
                                                <v-chip v-bind="attrs" v-on="on" v-else dark color="#606060"
                                                    >Not Verified</v-chip
                                                >
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    @click="toggleVerified(item.value)"
                                                    v-for="(item, index) in verifiedValues"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-text-field disabled v-model="adminemail" label="Email"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field label="User ID" disabled v-model="userId"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="ownerId" label="Owner ID"></v-text-field>
                                    </v-col>
                                    <!-- <v-col>
                                        <v-text-field v-model="funeralHomeId" label="Funeral Home ID"></v-text-field>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field readonly v-model="auth0Id" label="Auth0Id"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col>
                                        <v-text-field v-model="usertoken" label="Token"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="firstName"
                                            label="First Name"
                                            :rules="[v => !!v || 'First name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="lastName"
                                            label="Last Name"
                                            :rules="[v => !!v || 'Last name is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <calendar label="Created:" v-model="createDate"></calendar>
                                    </v-col>
                                    <v-col>
                                        <calendar label="Invited: " v-model="inviteSentTime"></calendar>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="selectedPhoneType"
                                            label="Phone Type"
                                            :items="phoneTypes"
                                            item-text="label"
                                            item-value="value"
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row> </v-row>
                                <v-row> </v-row>
                                <v-row> </v-row>

                                <v-alert v-if="errorMessage.length" type="error">
                                    {{ errorMessage }}
                                </v-alert>
                                <v-row>
                                    <v-col class="mt-10">
                                        <v-btn @click.prevent="superAdminEditUser()" color="primary">{{
                                            adminEditingUser ? 'Update User' : 'Create User'
                                        }}</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row> </v-row>
                            </v-form>
                        </div>
                        <!-- end admin edit modal -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="!editingUser && !adminEditingUser"
                        text
                        color="blue darken-1"
                        @click="openDialog = false"
                        >Close</v-btn
                    >
                    <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="adminEditingUser ? cancelSAUserForm() : cancelUserForm()"
                        >Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- manual password modal -->
        <v-dialog v-model="manualshow" max-width="550">
            <v-card class="p-3">
                <v-card-title class="headline"> Manually Set Password</v-card-title>

                <v-form ref="manualPassForm" v-model="manualPassFormValid">
                    <div class="pl-6 pr-6">
                        <v-text-field
                            v-if="showManualPasword"
                            :rules="[pwRules.required, pwRules.password]"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                        <v-text-field
                            autofocus
                            v-if="!showManualPasword"
                            :rules="[pwRules.required, pwRules.password]"
                            type="password"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye-off"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                    </div>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" :disabled="!manualPassFormValid" text @click="manualUserCreation">
                        Update User
                    </v-btn>
                    <v-btn color="gdarken-1" text @click="cancelManualPassword"> Exit </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- end manual password modal -->

        <v-dialog @click:outside="cancelPasswordResetEmail" v-model="passwordResetEmailModal" max-width="500">
            <v-card>
                <v-card-title>Send Password Reset Email</v-card-title>
                <v-card-text
                    >Send password reset email to <strong>{{ selectedUser ? selectedUser.email : '' }}</strong
                    >?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="cancelPasswordResetEmail" depressed>Cancel</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="loading"
                        @click="submitPasswordResetEmailRequest"
                        depressed
                        color="primary"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <land-line-alert @updateNumber="number => updateUserPhone(number)" ref="landlineAlert" />

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import ResetPassword from '@/components/Misc/ResetPassword.vue';
import moment from 'moment';
import Calendar from '@/components/ui/Calendar.vue';
import LandLineAlert from '@/components/Misc/LandLineAlert.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data: () => ({
        token: '',
        emailVerified: false,
        manualshow: false,
        showManualPasword: false,
        manualPassword: '',
        users: [],
        snackbar: false,
        message: '',
        openDialog: false,
        loading: true,
        deleteModal: false,
        passwordResetModal: false,
        selectedUser: {},
        search: '',
        editingUser: false,
        adminEditingUser: false,
        updatingUser: false,
        passwordResetEmailModal: false,
        errorMessage: '',
        // quick: true,
        options: {
            itemsPerPage: 10,
        },
        auth0loading: true,
        userId: -1,
        emailRules: [
            v => !!v || 'Email is required.',
            v => v.indexOf('@') !== 0 || 'Email should have a username.',
            v => v.includes('@') || 'Email should include @ symbol.',
        ],
        formValidity: false,
        userForm: {
            firstName: '',
            lastName: '',
            email: '',
            userType: 1,
            phoneNo: '',
        },
        userTypes: [
            { label: 'Funeral Home', color: 'orange' },
            { label: 'Owner', color: 'blue' },
            { label: 'Super Admin', color: 'red' },
            { label: 'Supplier', color: 'purple' },
            { label: 'Regional Manager', color: 'green' },
        ],
        ownerTypes: [
            { text: 'Owner', value: 1 },
            { text: 'Regional Manager', value: 4 },
        ],
        id: -1,
        headers: [
            { text: 'Name', value: 'name', width: '30%' },
            // { text: '', value: 'blocked' },
            { text: '', value: 'userType' },
            { text: 'Email', value: 'email' },
            { text: 'Last Login', value: 'lastLogin' },
            { text: 'Actions', value: 'actions', width: '26%', align: 'center' },
        ],
        verifiedValues: [
            {
                title: 'Verified',
                value: true,
                text: 'Verified',
                color: '#06aa58',
                virtual: true,
                hidden: false,
                locked: false,
            },
            {
                title: 'Not Verified',
                value: false,
                text: 'Not Verified',
                color: '#606060',
                virtual: true,
                hidden: false,
                locked: false,
            },
        ],
        phoneTypes: [
            { label: 'Landline', value: 0 },
            { label: 'Mobile', value: 1 },
            { label: 'Mobile (Override)', value: 2 },
        ],
        selectedPhoneType: null,
        landLine: false,
        landLineOverride: false,
        phoneNo: '',
        manualPassFormValid: false,
        pwRules: {
            required: value => !!value || 'Required.',
            password: value => {
                const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
                return pattern.test(value) || 'Min. 8 characters with at least one capital letter and a number.';
            },
            passwordsMatch: value => {
                return value === this.password || `Passwords don't match`;
            },
        },
    }),
    filters: {
        dateFormat(value) {
            return moment(value).format('l');
        },
        labelFilter(value) {
            if (value === 'Regional Manager') {
                return 'RM';
            } else {
                return value;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        adminUpdateUser(item) {
            this.getUsersForSuperAdmin(item.id);
            this.updatingUser = false;
            this.editingUser = false;
            this.adminEditingUser = false;

            if (item) {
                this.adminEditingUser = true;
                this.userId = item.id;
                this.firstName = item.firstName;
                this.lastName = item.lastName;
                this.adminemailVerified = item.emailVerified;
                this.createDate = item.createDate;
                this.inviteSentTime = item.createDate;
                this.auth0Id = item.auth0Id;
                this.adminemail = item.email;
                this.funeralHome = item.funeralHome;
                this.funeralHomeId = item.funeralHomeId;
                this.admingetuserid = item.id;
                this.ownerId = item.ownerId;
                this.usertoken = item.token;
                this.userForm.userType = item.userType;
                this.landLine = item.landLine;
                this.landLineOverride = item.landLineOverride;
                this.phoneNo = item.phoneNo;
            }
            this.checkPhoneType();
        },
        submitPasswordResetEmailRequest() {
            if (!(this.selectedUser?.id > 0)) {
                this.showSnackbar({ message: 'Invalid user id', color: 'error' });
                return;
            }

            this.loading = true;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/password-reset-email/' + this.selectedUser.id)
                .then(resp => {
                    this.showSnackbar({ message: 'Password reset email sent!', color: 'success' });
                    this.cancelPasswordResetEmail();
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error sending password reset email', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelPasswordResetEmail() {
            this.passwordResetEmailModal = false;
            this.selectedUser = null;
        },
        openPasswordResetEmailModal(item) {
            this.passwordResetEmailModal = true;
            this.selectedUser = item;
        },
        setPhoneBools() {
            //Backend is 2 bools
            if (this.selectedPhoneType == 2) {
                this.landLineOverride = true;
                this.landLine = false;
            }

            if (this.selectedPhoneType == 1) {
                this.landLineOverride = false;
                this.landLine = false;
            }

            if (this.selectedPhoneType == 0) {
                this.landLineOverride = false;
                this.landLine = true;
            }
        },
        checkPhoneType() {
            if (this.landLineOverride == true) {
                this.selectedPhoneType = 2;
            } else {
                if (this.landLine == true) {
                    this.selectedPhoneType = 0;
                } else {
                    this.selectedPhoneType = 1;
                }
            }
        },
        superAdminEditUser() {
            // /api/users/admin-edit
            this.errorMessage = '';

            if (this.$auth.role.includes('SuperAdmin')) {
                this.setPhoneBools();

                let data = {
                    // auth0Id: this.auth0Id,
                    id: this.admingetuserid,
                    email: this.adminemail,
                    auth0Id: this.auth0Id,
                    ownerId: this.ownerId,
                    funeralHomeId: this.funeralHomeId,
                    createDate: this.createDate,
                    emailVerified: this.adminemailVerified,
                    firstName: this.firstName,
                    inviteSentTime: this.inviteSentTime,
                    lastName: this.lastName,
                    userType: this.userForm.userType,
                    landLine: this.landLine,
                    landLineOverride: this.landLineOverride,
                    phoneNo: this.phoneNo,
                };

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + '/users/admin-edit', data)
                    .then(response => {
                        this.message = 'User Updated';
                        this.snackbar = true;
                        var updatedUser = this.users.find(u => u.id == this.userId);
                        updatedUser.emailVerified = this.adminemailVerified;
                        this.cancelSAUserForm();
                        this.cancelUserForm();

                        this.getUsers(this.id, true);
                    })
                    .catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data.Message,
                        });
                        console.log(error, 'Admin here');
                    });
            }
        },
        // cancelUserForm() {
        //     this.editingUser = false;
        //     this.updatingUser = false;

        //     this.userForm.firstName = '';
        //     this.userForm.lastName = '';
        //     this.userForm.email = '';
        //     this.userId = -1;

        // },
        cancelSAUserForm() {
            this.auth0Id = '';
            this.errorMessage = '';
            this.editingUser = false;
            this.updatingUser = false;
            this.adminEditingUser = false;

            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userId = -1;
            this.userForm.userType = 1;
            this.userForm.phoneNo = '';
        },
        toggleVerified(value) {
            this.adminemailVerified = value;
        },
        newType(value) {
            this.userForm.userType = value;
        },
        geninviteLink(id) {
            this.errorMessage = '';
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/users/invite-link/?id=${id}`)
                .then(response => {
                    navigator.clipboard.writeText(response.data.authLink);
                    this.showSnackbar({ message: 'Copied invite link' });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getUsersForSuperAdmin(id) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    // const id = this.id === -1 ? this.$auth.funeralHomeId : this.id;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/users/admin-get/${id}`)
                        .then(response => {
                            if (response.data) {
                                this.inviteSentTime = this.checkForUTC(response.data.inviteSentTime);

                                this.auth0Id = response.data.auth0Id;
                                this.createDate = this.checkForUTC(response.data.createDate);
                                this.adminemail = response.data.email;
                                this.adminemailVerified = response.data.emailVerified;
                                this.firstName = response.data.firstName;
                                this.funeralHome = response.data.funeralHome;
                                this.funeralHomeId = response.data.funeralHomeId;
                                this.admingetuserid = response.data.id;
                                // (this.inviteSentTime = response.data.inviteSentTime),
                                this.lastName = response.data.lastName;
                                this.ownerId = response.data.ownerId;
                                this.usertoken = response.data.token;
                                this.userType = response.data.userType;
                            }
                        })
                        .catch(error => {
                            this.errorLoading = true;
                        })
                        .finally(() => {
                            // this.loading = false;
                        });
                });
            }
        },
        cancelManualPassword() {
            this.manualshow = false;
            this.manualPassword = '';
        },
        manualUserCreation() {
            let data = {
                password: this.manualPassword,
            };

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/users/create-admin-manual/${this.userId}`, data)
                    .then(response => {
                        var updatedUser = this.users.find(u => u.id == this.userId);
                        updatedUser.emailVerified = true;
                        this.manualshow = false;
                        this.message = 'User Created';
                        this.cancelSAUserForm();
                        this.cancelUserForm();
                    })
                    .catch(error => {
                        console.log(error, 'owner table error');
                        //Alert user if error is password error
                        const passwordError = 'PasswordStrengthError: Password is too weak';
                        if (error.response.data.Message == passwordError) {
                            const errorMessage = passwordError.substring(passwordError.indexOf(':') + 1).trim();
                            this.showSnackbar({ message: errorMessage, color: 'error' });
                        } else {
                            this.showSnackbar({ message: 'Unable to create user', color: 'error' });
                        }
                    });
            });
        },
        toggleShowPassword() {
            this.showManualPassword = !this.showManualPassword;
        },
        getUsers(id, quick) {
            if (quick) {
                this.auth0loading = true;
            }
            this.loading = true;
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/users/owners/${id}?quick=${quick}`)
                    .then(response => {
                        if (response.data) {
                            this.users = response.data;
                            if (quick) {
                                this.getUsers(id, false);
                            }
                            if (!quick) {
                                this.auth0loading = false;
                            }
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                    })
                    .then(() => {
                        this.loading = false;
                    });
            });
        },
        updateUserPhone(number) {
            let id = this.landlineResponseId;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/user/phoneNumber?id=${id}&phoneNumber=${number}`)
                .then(response => {
                    this.showSnackbar({ message: 'Updated user' });
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    var updatedUser = this.users.find(u => u.id == this.landlineResponseId);

                    updatedUser.phoneNo = number;
                    this.cancelUserForm();
                    this.cancelSAUserForm();
                    this.getUsers(this.id, true);
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating user' });
                })
                .finally(() => {
                    this.snackbar = true;
                    this.creatingUser = false;
                });
        },
        initUpdateUserForm(item = null) {
            this.updatingUser = false;
            this.editingUser = true;

            if (item) {
                this.updatingUser = true;
                this.userId = item.id;
                this.userForm.firstName = item.firstName;
                this.userForm.lastName = item.lastName;
                this.userForm.email = item.email;
                this.userForm.userType = item.userType;
                this.emailVerified = item.emailVerified;
                this.userForm.phoneNo = item.phoneNo;
            }
        },
        submitUserForm() {
            this.errorMessage = '';

            this.updatingUser ? this.updateUser() : this.inviteUser();
        },
        updateUser() {
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                id: this.userId,
                ownerId: this.id,
                userType: this.userForm.userType === 4 ? 4 : 1,
                phoneNo: this.userForm.phoneNo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + '/users/', data)
                .then(response => {
                    this.message = 'User Updated';
                    if (response.data.landLine == true) {
                        this.$refs.landlineAlert.dialog = true;
                        this.landlineResponseId = response.data.id;
                    }
                    this.snackbar = true;
                    this.cancelUserForm();
                    this.getUsers(this.id, true);
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                });
        },
        inviteUser() {
            // new user
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                email: this.userForm.email,
                ownerId: this.id,
                userType: this.userForm.userType === 4 ? 4 : 1,
                phoneNo: this.userForm.phoneNo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/invite', data)
                .then(response => {
                    this.message = 'User created';
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    this.snackbar = true;
                    this.cancelUserForm();
                    this.getUsers(this.id, true);
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                });
        },
        cancelUserForm() {
            this.editingUser = false;
            this.updatingUser = false;
            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userForm.userType = 1;
            this.userId = -1;
            this.userForm.phoneNo = '';
        },
        resendEmailInvite(user) {
            if (user.emailVerified) {
                this.snackbar = true;
                this.message = `${user.email} has already verified their email address`;
                return;
            }

            this.$auth.getIdTokenClaims().then(result => {
                let token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` } })
                    .post(process.env.VUE_APP_API + '/users/resend?id=' + user.id)
                    .then(response => {
                        if (response.data) {
                            this.snackbar = true;
                            this.message = `Invite resent to ${user.email}`;
                        }
                    })
                    .catch(error => {
                        this.snackbar = true;
                        this.message = `Error sending to ${user.email}`;
                    });
            });
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.deleteModal = true;
        },
        openPasswordResetModal(item) {
            this.selectedUser = item;
            this.passwordResetModal = true;
        },
        deleteUser() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/Users/${this.selectedUser.id}`)
                .then(response => {
                    this.message = `${this.selectedUser.firstName} ${this.selectedUser.lastName} deleted!`;
                    this.getUsers(this.id, true);
                })
                .catch(error => {
                    this.message = `Error deleting user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`;
                })
                .then(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
    },
    watch: {
        id: function () {
            if (this.id !== -1) {
                this.getUsers(this.id, true);
                this.auth0loading = true;
            }
        },
        openDialog() {
            this.editingUser = false;
            this.selectedUser = {};
            this.auth0Id = '';
        },
        manualshow(val) {
            if (!val) {
                this.$refs.manualPassForm.reset();
            }
        },
    },
    components: {
        ResetPassword,
        Calendar,
        LandLineAlert,
        CustomTooltip,
    },
};
</script>

<style lang="scss" scoped>
.icon {
    margin: 0 0.5rem;
}

.action-list {
    display: flex;
    justify-content: center;
    gap: 10px;
}
</style>

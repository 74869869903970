<template>
    <div>
        <div class="tab-padding" v-show="billingForm.billCollectionOptions !== 2">
            <div class="d-flex">
                <div class="flex-2">
                    <!-- <v-alert
                        @click="openBillingPortal()"
                        v-if="!billingForm.hasDefaultPaymentMethod && !loading"
                        border="top"
                        color="red lighten-2"
                        dark
                    >
                        * No credit card on file. Please update to edit the billing information.
                    </v-alert> -->
                    <v-btn
                        v-if="billingForm.stripeCustomerId"
                        @click="goToStripeDashboard()"
                        color="primary"
                        class="mr-4 mb-4"
                        >View Stripe Dashboard</v-btn
                    >
                    <v-btn @click="openBillingPortal()" class="mb-4">Update Payment and Billing Address</v-btn>
                </div>
                <div
                    v-if="billingForm.subscriptionStatus || billingForm.subscriptionStatus == 0"
                    class="flex-1 text-right"
                >
                    <p class="mb-0">
                        Subscription Status
                        <v-chip
                            :color="subscriptionStatus[billingForm.subscriptionStatus].color"
                            dark
                            @click="subscriptionStatusDialog = true"
                            v-if="$auth.role.includes('SuperAdmin')"
                        >
                            {{ subscriptionStatus[billingForm.subscriptionStatus].label }}
                        </v-chip>

                        <v-chip v-else :color="subscriptionStatus[billingForm.subscriptionStatus].color" dark>
                            {{ subscriptionStatus[billingForm.subscriptionStatus].label }}
                        </v-chip>
                    </p>
                </div>
            </div>

            <v-form ref="billingForm" class="billing-form">
                <div style="width: 100%; display: flex; flex-direction: row; margin-bottom: 20px; gap: 1rem">
                    <v-select
                        style="width: 100%"
                        class="payment_collection"
                        v-model="billingForm.billCollectionOptions"
                        :items="ownerBillCollectionOptions"
                        item-text="description"
                        item-value="id"
                        label="Bill Collection Options"
                    >
                    </v-select>

                    <v-select
                        style="margin-top: 30px; width: 100%"
                        required
                        v-model="billingForm.billingStrategy"
                        :items="billingStrategyOptions"
                        label="Billing Strategy"
                        item-text="label"
                        item-value="id"
                    >
                        <template v-slot:item="{ item }">
                            <span class="disabled_sel" v-if="item.id == 2 || item.id == 3"
                                >{{ item.label }} - Coming Soon</span
                            >
                            <span v-else>{{ item.label }}</span>
                        </template>

                        <template v-slot:selection="{ item }">
                            <span class="disabled_sel" v-if="item.id == 2 || item.id == 3">{{ item.label }} </span>
                            <span v-else>{{ item.label }}</span>
                        </template>
                    </v-select>
                </div>

                <v-row>
                    <v-col>
                        <!-- <date-picker
                            :class="[billingForm.subscriptionId === null ? '' : 'disabled']"
                            :disabled="!billingForm.subscriptionId === null"
                            v-model="billingForm.subscriptionStartDate"
                            formLabel="Subscription Start Date"
                            required
                            readonly
                            type="date"
                            :rules="[v => !!v || 'Required']"
                        ></date-picker> -->

                        <calendar
                            :output-local="true"
                            v-model="billingForm.subscriptionStart"
                            label="Subscription Start Date"
                            required
                            type="date"
                            :rules="[v => !!v || 'Required']"
                        ></calendar>
                        <small
                            style="position: absolute; margin-top: -0.12rem; opacity: 0.5"
                            v-if="billingForm.subscriptionEndDate"
                            >Subscription End Date: {{ billingForm.currentBillingPeriodEnd | moment('l') }}</small
                        >
                    </v-col>
                </v-row>

                <v-row style="margin-top: 40px">
                    <v-col>
                        <vuetify-money
                            v-model="billingForm.subscriptionCost"
                            label="Subscription Cost"
                            :options="currencyOptions"
                            :rules="[v => !!v || 'Required']"
                        ></vuetify-money>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="billingForm.currency"
                            :items="currencies"
                            item-text="currency"
                            item-value="code"
                            label="Currency"
                            return-object
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="billingForm.billedAnnually"
                            :items="billedAnnuallyOptions"
                            label="Billed Annually"
                            return-object
                            hint="Coming soon"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col> -->
                    <!-- OwnerBillingStrategy -->

                    <!-- </v-col> -->
                </v-row>
            </v-form>

            <v-row>
                <v-col class="text-right">
                    <v-btn
                        v-if="currentSubscriptionStatus === 0"
                        @click="openCancelSubscriptionModal"
                        color="red"
                        dark
                        class="mr-4"
                    >
                        <font-awesome-icon icon="fa-regular fa-triangle-exclamation" class="mr-2" />
                        <span>Cancel</span>
                    </v-btn>
                    <v-btn @click="saveBilling" color="primary" :loading="busy" :disabled="busy">
                        <span v-if="currentSubscriptionStatus === 1">Reactivate Subscription</span>
                        <span v-else-if="currentSubscriptionStatus === 7">Start Subscription</span>
                        <span v-else>Update Subscription</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-model="cancelDialog" max-width="360">
            <v-card>
                <v-card-title class="headline">Cancel Subscription</v-card-title>
                <v-card-text>
                    <v-checkbox
                        v-model="confirmCancel"
                        label="This will cancel the subscription in Stripe and in MemoryShare. After canceling the subscription please login to Stripe and verify the cancelation and final invoice"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancelDialog = false">Back</v-btn>
                    <v-btn color="red" :loading="busy" :disabled="busy" text @click="cancelSubscription">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="subscriptionStatusDialog" max-width="360">
            <v-card>
                <v-card-title class="headline">Change Subscription Status</v-card-title>
                <v-card-text>
                    The subscription status is automatically calculated, and should be correct. If you have any
                    questions contact Shane or Kyle before changing this value.
                </v-card-text>

                <v-card-text>
                    <v-select
                        :items="subscriptionStatusEnums"
                        label="Subscription Status"
                        v-model="selectedSubscriptionStatus"
                    ></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="subscriptionStatusDialog = false">Back</v-btn>
                    <v-btn color="red" :loading="busy" :disabled="busy" text @click="updateSubscriptionStatus"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-show="billingForm.billCollectionOptions === 2" style="padding: 20px">
            <v-row style="display: flex; justify-content: space-evenly">
                <v-col class="text-right">
                    <v-select
                        style="width: 80%"
                        v-model="billingForm.billCollectionOptions"
                        :items="ownerBillCollectionOptions"
                        item-text="description"
                        item-value="id"
                        label="Bill Collection Options"
                    >
                    </v-select>
                </v-col>
                <v-col style="margin-top: 15px; positon: relative; rihgt: 0px">
                    <v-btn @click="saveBilling" color="primary" :loading="busy" :disabled="busy">
                        <span v-if="currentSubscriptionStatus === 1">Reactivate Subscription</span>
                        <span v-else-if="currentSubscriptionStatus === 7">Start Subscription</span>
                        <span v-else>Update Subscription</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { subscriptionStatuses } from '@/constants.js';
import currencies from '@/currencies.js';
import { mapActions } from 'vuex';
import DatePicker from '@/components/ui/DatePicker.vue';
import Calendar from '@/components/ui/Calendar.vue';
import moment from 'moment';
export default {
    props: ['ownerId'],
    components: {
        DatePicker,
        Calendar,
    },
    data() {
        return {
            tempValue: '',
            description: '',
            ownerBillCollectionOptions: [],
            billingStrategyOptions: [],
            OwnerbillingStrategyOptions: {
                description: '',
                id: null,
            },
            OwnerBillCollOptions: {
                description: '',
                id: null,
            },
            currencies,
            val: 0,
            billedAnnuallyOptions: [
                {
                    text: 'Yes',
                    value: true,
                },
                {
                    text: 'No',
                    value: false,
                },
            ],
            subscriptionStatusDialog: false,
            subscriptionStatusEnums: [
                'Active',
                'Canceled',
                'Incomplete',
                'Incomplete Expired',
                'Trial',
                'Past Due',
                'Unpaid',
                'No Card',
            ],
            subscriptionMapping: {
                Active: 0,
                Canceled: 1,
                Incomplete: 2,
                'Incomplete Expired': 3,
                Trial: 4,
                'Past Due': 5,
                Unpaid: 6,
                'No Card': 7,
            },
            selectedSubscriptionStatus: 'No Card',
            currentSubscriptionStatus: 0,
            busy: false,
            loading: false,
            stripeId: '',
            isDevHome: false,
            billingForm: {
                streamsPerMonth: null,
                costPerAdditionalStream: null,
                maxStreamLength: null,
                costPerFifteenMinute: null,
                maxViewers: null,
                costPer100Viewers: null,
                billedAnnually: false,
                subscriptionCost: null,
                subscriptionStartDate: null,
                stripePaymentToken: null,
                currentBillingPeriodEnd: null,
                subscriptionStatus: null,
                hasDefaultPaymentMethod: null,
                currency: null,
            },
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            hasSubStartDate: false,
            date: new Date(),
            menu: false,
            modal: false,
            menu2: false,
            cancelDialog: false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            bandwidthData: {
                label: 'Bandwidth Data',
                borderColor: '#659fc3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [32, 45, 12, 45, 65, 43, 55],
            },
            streamingExpensesData: {
                label: 'Streaming Expenses',
                borderColor: '#65c3b8',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [99, 120, 44, 54, 65, 23, 43],
            },
            totalExpenseseData: {
                label: 'Total Expenses',
                borderColor: '#c38965',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [23, 45, 32, 87, 67, 66, 56],
            },
            profitData: {
                label: 'Profit',
                borderColor: '#6570c3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [76, 87, 67, 77, 88, 55, 66],
            },
            datacollection: null,
            chartReady: false,
            confirmCancel: false,
            subscriptionStatus: [...subscriptionStatuses],
            OwnerIdList: [
                { text: 'Shane', val: 0 },
                { text: 'Vertin', val: 1 },
                { text: 'Dev Home', val: 2 },
                { text: 'Dev Owner', val: 3 },
            ],
            requiredRules: [v => !!v || 'This field is required'],
            subscriptionId: null,
        };
    },

    methods: {
        ...mapActions(['showSnackbar']),
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        updateSubscriptionStatus() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/billing/owner/subscription-status/${Number(this.$route.params.id)}?status=${
                            this.subscriptionMapping[this.selectedSubscriptionStatus]
                        }`,
                )
                .then(() => {
                    this.getBilling();
                    this.loading = false;
                    this.subscriptionStatusDialog = false;
                })
                .catch(error => {
                    this.load = false;
                })
                .then(() => {
                    this.loading = false;
                    this.subscriptionStatusDialog = false;
                });
        },
        openBillingPortal() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/owner/portal/${Number(this.$route.params.id)}`)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error Opening Stripe Portal',
                    });
                });
        },
        getBilling() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/owner/${Number(this.$route.params.id)}`)
                .then(response => {
                    this.billingForm = { ...response.data, subscriptionCost: response.data.subscriptionCost / 100 };

                    if (response.data.subscriptionStart === '0001-01-01T00:00:00') {
                        this.hasSubStartDate = false;
                        this.billingForm.subscriptionStart = this.checkForLocal(moment(Date.now()).toISOString());
                    } else {
                        this.hasSubStartDate = true;
                        this.billingForm.subscriptionStart = this.checkForLocal(response.data.subscriptionStart);
                    }

                    // this.billingForm.costPerAdditionalStream = response.data.costPerAdditionalStream / 100;
                    // this.billingForm.costPer100Viewers = response.data.costPer100Viewers / 100;
                    // this.billingForm.costPerFifteenMinute = response.data.costPerFifteenMinute / 100;
                    // this.billingForm.subscriptionCost = response.data.subscriptionCost / 100;

                    this.stripeId = response.data.stripeCustomerId;
                    this.selectedSubscriptionStatus = this.subscriptionStatusEnums[response.data.subscriptionStatus];
                    this.currentSubscriptionStatus = response.data.subscriptionStatus;
                    this.billingForm.billedAnnually = this.billedAnnuallyOptions.find(
                        o => o.value === response.data.billedAnnually,
                    );
                    this.billingForm.hasDefaultPaymentMethod = response.data.hasDefaultPaymentMethod;
                    const currentCurrency =
                        // response.data.currency && currencies.find(c => c.code.toLowerCase() === response.data.currency);
                        (this.billingForm.currency = currentCurrency || this.currencies[0]);
                    this.subscriptionId = response.data.subscriptionId;

                    this.billingForm.currentBillingPeriodEnd = new Date(
                        response.data.currentBillingPeriodEnd,
                    ).toISOString();

                    this.OwnerBillCollOptions = response.data.billCollectionOptions;
                    this.OwnerbillingStrategyOptions = response.data.billingStrategy;
                    //
                })
                .catch(error => {
                    // this.$swal.fire({
                    //     icon: 'error',
                    //     title: 'Error getting billing data',
                    // });
                })
                .then(() => {
                    this.loading = false;
                });
        },
        getBillingStrategy() {
            return new Promise((resolve, reject) => {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/dropdown/owner-billing-strategy`)
                    .then(response => {
                        if (response.data) {
                            // this.billingStrategyOptions = response.data

                            this.billingStrategyOptions = response.data.map(option => {
                                let labelString = option.description
                                    .replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, function (str) {
                                        return str.toUpperCase();
                                    });

                                return {
                                    label: labelString,
                                    ...option,
                                };
                            });
                        }

                        resolve();
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        getBillCollectionOptions() {
            return new Promise((resolve, reject) => {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/dropdown/owner-bill-collection-options`)
                    .then(response => {
                        this.ownerBillCollectionOptions = response.data;
                        resolve();
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        saveBilling() {
            if (this.billingForm.billingStrategy == 2 || this.billingForm.billingStrategy == 3) {
                this.showSnackbar({ message: 'This billing strategy is not currently available' });
                return;
            }
            let formValid = this.$refs.billingForm.validate();
            if (!formValid) return;
            this.busy = true;

            let data = {
                ...this.billingForm,
                funeralHomeId: +this.$route.params.id,
                streamsPerMonth: +this.billingForm.streamsPerMonth,
                costPerAdditionalStream: parseInt(this.billingForm.costPerAdditionalStream * 100),
                maxStreamLength: +this.billingForm.maxStreamLength,
                costPerFifteenMinute: parseInt(this.billingForm.costPerFifteenMinute * 100),
                maxViewers: +this.billingForm.maxViewers,
                costPer100Viewers: parseInt(this.billingForm.costPer100Viewers * 100),
                billedAnnually: false, // backend not set up to receive value from form yet
                currency: this.billingForm.currency?.code ? this.billingForm.currency?.code : 'USD',

                subscriptionCost: parseInt(this.billingForm.subscriptionCost * 100),

                subscriptionStart: this.billingForm.subscriptionStart,
                currentBillingPeriodEnd: new Date(this.billingForm.currentBillingPeriodEnd).toISOString(),
                billCollectionOptions: this.billingForm.billCollectionOptions,
                billingStrategy: this.billingForm.billingStrategy ? this.billingForm.billingStrategy : 0,
                // stripePaymentToken: this.billingForm.stripePaymentToken
                subscriptionEndDate: '0001-01-01T00:00:00',
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/owner/${Number(this.$route.params.id)}`, data)
                .then(response => {
                    this.showSnackbar({ message: 'Billing Updated' });
                    this.getBilling();
                })
                .catch(error => {
                    if (data.subscriptionCost >= '200.00') {
                    }

                    if (error?.response?.data?.title) {
                        this.showSnackbar({ message: error.response.data.title });
                    } else {
                        this.showSnackbar({
                            message: 'Error! There was a problem saving your data. Please try again.',
                        });
                    }
                })
                .finally(() => {
                    this.busy = false;
                });
        },
        goToStripeDashboard() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/customers/${this.stripeId}`, '_blank');
        },
        openCancelSubscriptionModal() {
            this.confirmCancel = false;
            this.cancelDialog = true;
        },
        cancelSubscription() {
            if (!this.confirmCancel) {
                this.showSnackbar({ message: 'You must confrim subscription is canceled in Stripe to proceed' });
            } else {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/billing/owner/cancel/${this.$route.params.id}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Subscription canceled' });
                        this.cancelDialog = false;
                        this.getBilling();
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error ending subscription' });
                    });
            }
        },
    },
    // computed: {
    //     checkBillCollection: function () {
    //         console.log('owner bill coll', this.OwnerBillCollOptions);
    //         if (this.OwnerBillCollOptions.id === null) {
    //             console.log('collection is null');
    //             this.billCollectionOption = 1;
    //         }
    //     },
    // },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
            this.getBillCollectionOptions().then(() => {
                this.getBillingStrategy().then(() => {
                    this.getBilling();
                });
            });
        });
    },
};
</script>

<style scoped>
.disabled {
    position: relative;
    color: rgb(49, 48, 48);
}
.disabled:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: ' ';
}
.billing-form {
    margin-top: 50px;
}

.payment_collection {
    width: 100%;

    margin-top: 30px;
}
.tab-padding {
    padding: 3rem;
}

.v-messages__message {
    color: black !important;
}

.v-tabs-items,
.tab-padding,
.v-window-item,
.v-window,
.v-item-group {
    overflow: visible !important;
}
.disabled_sel {
    color: lightgray;
}
</style>

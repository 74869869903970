<template>
    <div>
        <div>
            <v-row>
                <v-col class="column-expanded">
                    <textarea
                        @scroll="syncScroll"
                        id="editor-input"
                        spellcheck="false"
                        v-model="htmlWrapper"
                        label="HTML Wrapper"
                    ></textarea>

                    <div class="visible-prism">
                        <prism id="editor-output" language="markup"></prism>
                    </div>
                </v-col>
            </v-row>

            <v-alert v-if="errorMessage.length" type="error">
                {{ errorMessage }}
            </v-alert>
        </div>
        <!-- <div class="pt-4" style="display: flex; justify-content: space-between">
            <v-btn @click="copyToClipboard" dark>
                <font-awesome-icon icon="fa-regular fa-clone" class="mr-2 justify-left" />
                <span>Copy</span>
            </v-btn>
            <v-btn dark color="#0c3c60" @click="handleSubmit">Submit</v-btn>
        </div> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Prism from 'vue-prismjs';
import 'prismjs/themes/prism-okaidia.css';

export default {
    data() {
        return {
            htmlWrapper: '',
            token: '',
            errorMessage: '',
        };
    },
    props: ['codeProp'],
    watch: {
        htmlWrapper() {
            this.updatePrism(this.htmlWrapper);
            this.$emit('updateValue', this.htmlWrapper);
        },
    },

    async mounted() {
        window.Prism = window.Prism || {};
        Prism.manual = true;
        await this.setAuthToken();
    },

    methods: {
        ...mapActions(['showSnackbar']),
        handleSubmit() {
            this.$emit('submit', this.htmlWrapper);
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.htmlWrapper);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        syncScroll() {
            let editorOutput = document.querySelector('#editor-output');
            let editorInput = document.querySelector('#editor-input');

            editorOutput.scrollTop = editorInput.scrollTop;
        },
        updatePrism(text) {
            window.Prism = window.Prism || {};
            Prism.manual = true;
            let x = document.querySelector('#editor-output');

            if (x) {
                x.innerHTML = text.replace(new RegExp('<', 'g'), '&lt').replace(new RegExp('>', 'g'), '&gt');

                window.Prism.highlightElement(x);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    components: {
        Prism,
    },
};
</script>

<style lang="scss" scoped>
.vissible-prism {
    opacity: 1;
}
.column-expanded {
    height: 230px;
}

textarea {
    resize: none;
}

#editor-input,
#editor-output {
    // border: 2px solid orange;
    margin: 15px;
    padding: 15px;
    width: calc(100% - 30px);

    height: 200px;
}

#editor-input,
#editor-output,
#editor-output * {
    /* Also add text styles to highlighting tokens */
    font-size: 15pt;
    font-family: monospace;
    line-height: 20pt;
}

#editor-input,
#editor-output {
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    white-space: normal;
}
#editor-input {
    z-index: 1;
    color: transparent;
    background: transparent;
    caret-color: white;
}
#editor-output {
    z-index: 0;
    resize: vertical;
}

code[class*='language-'],
pre[class*='language-'] {
    white-space: pre-wrap !important;
    word-break: break-word !important;
    overflow-x: auto;
    overflow-y: auto;
}
</style>

<template>
    <div>
        <div class="position-relative" :key="posterRefreshKey">
            <div class="gallery-video-group" v-if="item.mediaType == 1">
                <v-img
                    v-if="showPoster"
                    @error="handlePosterError(item)"
                    :src="handleThumbnail(item.url)"
                    :lazy-src="handleThumbnail(item.url)"
                    class="image-base"
                    cover
                    height="150px"
                    width="200px"
                    eager
                    :id="`photo-${item.id}`"
                ></v-img>

                <div v-else class="gallery-video-wrapper">
                    <video class="gallery-video" :src="item.url"></video>
                </div>
                <v-chip class="px-2 video-tag" x-small dark color="#ab0658">VIDEO</v-chip>
            </div>
            <div v-else class="image-wrapper">
                <div v-if="brokenImgOverlay" class="broken-img-overlay d-flex justify-center align-center flex-column">
                    <font-awesome-icon icon="fa-solid fa-image-slash" style="font-size: 2rem"></font-awesome-icon>
                    <span class="text-center text-caption mt-3 px-8">This image could not be loaded</span>
                    <div style="max-width: 100%" class="px-3">
                        <span style="max-width: 100%" class="text-subtitle-2 mt-1 d-inline-block text-truncate">{{
                            item.name
                        }}</span>
                    </div>
                </div>
                <v-img
                    v-if="!brokenImgOverlay"
                    @error="handleImgLoadError"
                    :src="handleImgSrc(item.url)"
                    :lazy-src="handleImgSrc(item.url)"
                    class="image-base"
                    cover
                    height="150px"
                    width="200px"
                    eager
                    :id="`photo-${item.id}`"
                ></v-img>
            </div>

            <div v-if="item.removeFromRender" class="removed-from-render-warning text-caption">
                <div class="photo-details">
                    <div class="filename-inner">
                        <span class="filename__base text-truncate text-center flex-grow-1">Removed from render</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            brokenImgOverlay: false,
            showPoster: true,
            posterRefreshKey: 0,
        };
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        handleImgSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
            }
        },
        handleThumbnail(url) {
            if (url) {
                let filePath = this.extractFilePath(url);

                return `${process.env.VUE_APP_IMG_KIT_BASE}${filePath}/ik-thumbnail.jpg`;
            }
        },
        extractFilePath(url) {
            let filePath = url;

            if (url.includes('/tribute-photos/')) {
                filePath = url.split('/tribute-photos/')[1];
            } else if (url.includes('/original-tribute-photos/')) {
                filePath = url.split('/original-tribute-photos/')[1];
            }

            return filePath;
        },
        handleImgLoadError() {
            this.brokenImgOverlay = true;
        },
        handlePosterError(item) {
            this.showPoster = false;
            this.posterRefreshKey++;
        },
    },
};
</script>
<style lang="scss" scoped>
.image-wrapper {
    width: 200px;
    height: 150px;
    padding: 2px;
}

.broken-img-overlay {
    background-color: #f1f4f7;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.video-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

.image-base {
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.gallery-video {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    width: 250px;
    height: 200px;
    background-color: #f1f4f7;
}

.gallery-video-group {
    width: 200px;
    height: 150px;
    object-fit: cover;
    position: relative;
    padding: 2px;
}

.gallery-video-wrapper {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
}

.removed-from-render-warning {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ff5252;
    color: white;
    padding: 2px;
    z-index: 2;
    margin: 2px;
    border-radius: 0 0 5px 5px;
}

.photo-details {
    display: block;
    min-width: 0;
    font-size: 0.8rem;
    width: 100%;
    max-width: 100%;
    height: 1.5rem;
    flex-shrink: 0;
    position: relative;
}

.filename-inner {
    position: absolute;
    display: flex;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    padding: 2px;
    max-width: 100%;
}

.filename__base {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filename__extension {
    white-space: nowrap;
    flex-shrink: 0;
}
</style>

<template>
    <div>
        <v-row>
            <v-col align-self="center">
                <div v-if="shipmentLineItem.image">
                    <v-badge :content="shipmentLineItem.shipQuantity" overlap>
                        <img class="product-img" :src="shipmentLineItem.image.split(',')[0]" alt="Product Image" />
                    </v-badge>
                </div>
                <div v-else>
                    <v-badge :content="shipmentLineItem.shipQuantity" overlap>
                        <img
                            class="alt-product-img"
                            :src="require(`@/assets/images/logo.png`)"
                            alt="Alt Product Image"
                        />
                    </v-badge>
                </div>
                <div>
                    <small>{{ shipmentLineItem.productName }}</small>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ['shipmentLineItem'],
};
</script>
<style scoped>
.product-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 10px;
}
.alt-product-img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    border-radius: 10px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"p-3"},[_c('v-card-title',{staticClass:"mt-3"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-regular fa-badge-dollar"}}),_c('h3',{staticClass:"m-0"},[_vm._v("Purchases")])],1),_c('div',{staticClass:"d-flex align-items-center mb-3 ms-3"},[_c('div',{staticClass:"me-5 text-muted"},[_vm._v("Quick Filters:")]),_c('v-btn',{staticClass:"me-2",attrs:{"outlined":_vm.activeCustomerTypeQuickFilter != _vm.CUSTOMER_TYPE_FAMILY.value,"small":"","dark":"","color":_vm.CUSTOMER_TYPE_FAMILY.color},on:{"click":function($event){return _vm.updateQuickFilter(_vm.CUSTOMER_TYPE_FAMILY.value)}}},[_vm._v(_vm._s(_vm.CUSTOMER_TYPE_FAMILY.label))]),_c('v-btn',{attrs:{"outlined":_vm.activeCustomerTypeQuickFilter != _vm.CUSTOMER_TYPE_FUNERAL_HOME.value,"small":"","dark":"","color":_vm.CUSTOMER_TYPE_FUNERAL_HOME.color},on:{"click":function($event){return _vm.updateQuickFilter(_vm.CUSTOMER_TYPE_FUNERAL_HOME.value)}}},[_vm._v(_vm._s(_vm.CUSTOMER_TYPE_FUNERAL_HOME.label))])],1),_c('div',{staticClass:"d-flex px-3 flex-wrap flex-md-nowrap"},[_c('div',{staticClass:"flex-fill flex-md-grow-0 me-4"},[_c('calendar',{attrs:{"label":"From:","output-local":true},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',{staticClass:"flex-fill flex-md-grow-0 me-sm-4"},[_c('calendar',{attrs:{"label":"To:","output-local":true},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('div',{staticClass:"flex-fill flex-md-grow-0 ms-md-auto"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search query"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{attrs:{"items":_vm.purchases,"headers":_vm.headers,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.options,"footer-props":{
                itemsPerPageOptions: [5, 25, 50],
            }},on:{"click:row":_vm.getDetails,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.customerType",fn:function(ref){
            var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":true,"disabled":_vm.loading},on:{"input":_vm.handleCustomerTypeMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
            var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)+" ("+_vm._s(_vm.customerTypesSelected.length)+") "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{staticStyle:{"width":"200px"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"margin-bottom":"0.6rem"},attrs:{"small":"","width":"90%"},on:{"click":_vm.toggleAllCustomers}},[_vm._v(_vm._s(_vm.allCustomersSelected ? 'Deselect' : 'Select')+" All")])],1),_vm._l((_vm.customerTypes),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"height":"20px"}},[_c('v-checkbox',{attrs:{"label":item.label,"value":item.value},model:{value:(_vm.customerTypesSelected),callback:function ($$v) {_vm.customerTypesSelected=$$v},expression:"customerTypesSelected"}})],1)})],2)],1)]}},{key:"item.createdDate",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.$moment.utc(item.createdDate).local().format('l'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$moment.utc(item.createdDate).local().format('LT')))])]}},{key:"item.customerType",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.customerTypes[item.customerType].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.customerTypes[item.customerType].label)+" ")])]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")]}}])}),_c('PurchaseSummary',{attrs:{"purchases":_vm.purchases,"total":this.total,"totalAmount":this.totalAmount,"categoryTotals":_vm.categoryTotals}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
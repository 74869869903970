const axios = require('axios');
const { Buffer } = require('buffer');

export function checkHeicFtyp(input) {
    if (typeof input === 'string' && (input.startsWith('http://') || input.startsWith('https://'))) {
        return checkHeicFtypByUrl(input);
    } else {
        return checkHeicFtypByFile(input);
    }
}

function checkHeicFtypByUrl(url) {
    return axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
        headers: { Range: 'bytes=0-11' },
    })
        .then(response => {
            if (response.status !== 206 && response.status !== 200) {
                throw new Error(`Failed to fetch data: HTTP status ${response.status}`);
            }
            if (response.data.length < 12) {
                throw new Error('Insufficient data received');
            }
            const buffer = Buffer.from(response.data);
            return checkBufferHeicFtyp(buffer);
        })
        .catch(error => {
            console.error('Error fetching or parsing the file:', error.message);
            return false;
        });
}

function checkHeicFtypByFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            const data = reader.result;

            const buffer = Buffer.from(data);

            if (data.length < 12) {
                reject(new Error('Fils too small to contain required data'));
                return;
            }

            resolve(checkBufferHeicFtyp(buffer.slice(0, 12)));
        };

        reader.onerror = function () {
            reject(new Error('Fialed to read file'));
        };

        reader.readAsArrayBuffer(file);
    });
}

function checkBufferHeicFtyp(buffer) {
    const ftyp = buffer.toString('ascii', 4, 8);
    if (ftyp !== 'ftyp') {
        return false;
    }

    const majorBrand = buffer.toString('ascii', 8, 12);
    if (!majorBrand) {
        return false;
    }

    const heicBrands = ['heic', 'heix'];
    return heicBrands.includes(majorBrand);
}

<template>
    <div class="onboarding-container">
        <v-stepper alt-labels flat v-model="stepIndex">
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
                <div v-show="windowIndex > 0" style="flex-grow: 1; display: flex; flex-direction: column">
                    <div style="width: 100%; display: flex; justify-content: center" class="stepper-container">
                        <v-stepper-header class="stepper-content elevation-0">
                            <v-stepper-step
                                class="pointer step"
                                @click="handleStepClick(1)"
                                :complete="stepIndex > 1"
                                step="1"
                            >
                                <span class="step-label"> Deadline </span>
                            </v-stepper-step>
                            <v-divider class="step-divider"></v-divider>
                            <v-stepper-step
                                class="pointer step"
                                @click="handleStepClick(2)"
                                :complete="stepIndex > 2"
                                step="2"
                            >
                                <span class="step-label"> Identify </span>
                            </v-stepper-step>
                            <v-divider class="step-divider"></v-divider>
                            <v-stepper-step
                                class="pointer step"
                                @click="handleStepClick(3)"
                                :complete="stepIndex > 3"
                                step="3"
                            >
                                <span class="step-label"> Contact</span>
                            </v-stepper-step>
                            <!-- <v-divider class="step-divider"></v-divider> -->
                            <!-- <v-stepper-step class="pointer step" @click="handleStepClick(4)" step="4">
                                <span class="step-label"> Upload</span>
                            </v-stepper-step> -->
                        </v-stepper-header>
                    </div>
                </div>
            </div>

            <v-divider style="border-color: #eeeeee" />

            <v-window v-model="windowIndex">
                <v-window-item>
                    <div
                        class="text-center p-1"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 32px !important;
                        "
                    >
                        <h3
                            v-if="service"
                            style="font-weight: 800; font-size: 20px; margin-bottom: 16px"
                            class="text-heading"
                        >
                            Give everyone the space to share their favorite memory
                        </h3>

                        <p
                            style="max-width: 600px; font-size: 16px; line-height: 22px; margin-bottom: 32px"
                            class="text-body-light"
                        >
                            Everyone has a story of John. MemoryShare collects these stories, and bundles them into a
                            keepsake John's family will cherish forever. We deliver this story to the family 5 days
                            after the funeral.
                        </p>

                        <button @click="initOnboard" class="btn-blue">Let's Get Started</button>
                    </div>
                </v-window-item>
                <v-window-item eager>
                    <v-stepper-items>
                        <v-stepper-content :step="1">
                            <div class="text-center d-flex flex-column align-items-center p-3">
                                <h3>Let's set a Deadline</h3>
                                <p>
                                    We will close submissions on the deadline. We recommend setting the deadline
                                    <strong>one day after the funeral</strong>.
                                </p>
                                <div class="calendar-wrapper px-6">
                                    <calendar
                                        class="calender-component"
                                        ref="deadlineCalendar"
                                        inline
                                        disable-past-dates
                                        datetime
                                        v-model="deadline"
                                        label="Deadline"
                                        :output-local="true"
                                        :minute-step="15"
                                        hide-save-buttons
                                        @show-time="val => (calendarShowingTime = val)"
                                    />
                                </div>

                                <div style="width: 320px" class="d-flex flex-row justify-space-between">
                                    <!-- <v-btn
                                        v-if="story.deadline"
                                        @click="clearDeadline"
                                        depressed
                                        class="mr-2"
                                        style="width: 40%"
                                    >
                                        <span> Clear </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-broom-wide"
                                        ></font-awesome-icon>
                                    </v-btn>
                                    <v-btn v-else @click="skipDeadline" depressed class="mr-2" style="width: 40%"
                                        >Skip</v-btn
                                    > -->

                                    <v-btn
                                        v-if="!calendarShowingTime"
                                        @click="setDeadlineDate"
                                        depressed
                                        outlined
                                        class="deadline-btn"
                                    >
                                        <span> Set Deadline </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-calendar-day"
                                        ></font-awesome-icon>
                                    </v-btn>

                                    <v-btn
                                        v-else
                                        @click="handleDeadlineSave"
                                        :disabled="loading"
                                        :loading="loading"
                                        depressed
                                        outlined
                                        class="deadline-btn"
                                    >
                                        <span> Set Time </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-clock"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content :step="2">
                            <div class="text-center p-3">
                                <h3 style="margin-bottom: 40px" class="text-heading">
                                    Let's Identify {{ service.firstName }}
                                </h3>
                                <div v-if="confirmingPhoto">
                                    <v-avatar class="m-3" size="300">
                                        <v-img
                                            v-if="confirmationPhotoUrl"
                                            :src="handleMainImgSrc(confirmationPhotoUrl)"
                                            :lazy-src="handleMainImgSrc(confirmationPhotoUrl)"
                                        ></v-img>
                                    </v-avatar>
                                    <p class="mt-3 text-heading" style="font-size: 16px; font-weight: 500">
                                        Does this look like {{ service.firstName }}?
                                    </p>

                                    <v-progress-linear
                                        indeterminate
                                        v-if="loading"
                                        rounded
                                        class="mt-2"
                                    ></v-progress-linear>
                                    <div style="display: flex; justify-content: center; margin-top: 20px; gap: 8px">
                                        <button class="btn-ghost" :disabled="loading" @click="chooseAnotherMainPhoto">
                                            Choose Another Photo
                                        </button>

                                        <button class="btn-orange" :disabled="loading" @click="handleMainPhotoConfirm">
                                            Yes, Looks Good
                                        </button>
                                    </div>
                                </div>

                                <!-- START: Uploader -->
                                <div
                                    :class="confirmingPhoto ? 'hidden' : ''"
                                    style="max-width: 600px; max-height: 300px; margin: 0 auto"
                                >
                                    <div
                                        @click="handleProfileUploadClick"
                                        class="d-flex flex-column p-3 main-photo-cta"
                                    >
                                        <font-awesome-icon
                                            stroke="#7A8187"
                                            style="font-size: 4rem; margin-bottom: 8px"
                                            icon="fa-regular fa-cloud-arrow-up"
                                        />
                                        <p
                                            class="text-center text-body-light"
                                            style="font-weight: 500; font-size: 14px"
                                        >
                                            <span style="color: #2096f3">Click</span> or Drag and Drop Photo
                                        </p>
                                    </div>
                                    <!-- END: Uploader -->
                                </div>
                                <p
                                    v-if="!confirmingPhoto"
                                    class="text-heading"
                                    style="max-width: 300px; margin: 40px auto; font-weight: 500; font-size: 16px"
                                >
                                    Upload a photo of {{ service.firstName }} as refrence for our AI to work their
                                    magic.
                                </p>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content class="text-center" :step="3">
                            <div class="d-flex flex-column align-center justify-content-center">
                                <div>
                                    <h3 class="text-heading">Family Phone Number</h3>
                                    <p>
                                        We will automatically deliver the story video to this number 5 days after the
                                        deadline.
                                    </p>
                                </div>
                                <div style="max-width: 300px" class="flex-grow-1">
                                    <div class="my-10" style="width: 100%; max-width: 100%">
                                        <v-img
                                            style="max-width: 100%; max-height: 100%"
                                            :src="phoneSvg"
                                            alt="phone svg"
                                        ></v-img>
                                    </div>

                                    <div class="d-flex justify-content-center my-8">
                                        <v-text-field
                                            @keydown.enter="savePhoneNumber"
                                            label="Phone Number"
                                            v-model="phoneNumber"
                                            v-mask="'###.###.####'"
                                        ></v-text-field>
                                    </div>

                                    <div class="d-flex justify-content-center mt-4" style="gap: 10px">
                                        <v-btn class="flex-grow-1" @click="skipPhoneNumber" depressed>Skip</v-btn>
                                        <v-btn class="flex-grow-1" @click="savePhoneNumber" depressed outlined
                                            >Save</v-btn
                                        >
                                    </div>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-window-item>
            </v-window>
        </v-stepper>
    </div>
</template>
<script>
import TributeQR from '@/components/ManageService/Tribute/TributeQR.vue';
import mobileIconPng from '@/assets/images/mobile-phone.png';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';
import QrcodeVue from 'qrcode.vue';
import EmailInvite from '@/components/ui/EmailInvite.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import Calendar from '@/components/ui/Calendar.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import { mapActions } from 'vuex';
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';
import phoneSvg from '@/assets/svg/chat.svg';

export default {
    data() {
        return {
            phoneSvg,
            mobileIconSource: mobileIconPng,
            windowIndex: 0,
            stepIndex: 1,
            token: '',
            steps: [
                { label: 'Overview', value: 1 },
                { label: 'Identify', value: 2 },
                { label: 'Upload Photos', value: 3 },
            ],
            stories: [],
            tributeStory: null,
            deadline: null,
            story: {},
            calendarShowingTime: false,
            confirmingPhoto: false,
            qrDecisionModal: false,
            shareUploadModal: false,
            formValid: false,
            loading: false,
            phoneNumber: '',
        };
    },
    components: {
        PhotoUploaderV2,
        TributeQR,
        QrcodeVue,
        QrPrintout,
        EmailInvite,
        ShareUploadModal,
        Calendar,
        BrandedModal,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            if (this.servce) {
                return `Analytics for ${this.service.firstName + ' ' + this.service.lastName}`;
            }
            return 'Analytics';
        },
        pdfTitle() {
            if (this.service) {
                return `Viewers for ${this.service.firstName + ' ' + this.service.lastName}`;
            }
            return 'Viewers';
        },
    },
    watch: {
        stepIndex() {
            if (this.stepIndex == 1) {
                if (this.$refs.deadlineCalendar && this.$refs.deadlineCalendar.datetimeShowTime) {
                    this.$refs.deadlineCalendar.handleBackButton();
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
        ]),
        setDeadlineDate() {
            if (this.$refs.deadlineCalendar) {
                this.$refs.deadlineCalendar.handleSetNextTimeButton();
            }
        },
        skipPhoneNumber() {
            this.$emit('force-finish-onboarding');
        },
        savePhoneNumber() {
            if (!(this.phoneNumber.length == 0 || this.phoneNumber.length == 12)) {
                this.showSnackbar({ message: 'Invalid phone number', color: 'error' });
                return;
            }

            if (!this.story.eventId) {
                this.showSnackbar({ message: 'Invalid event id', color: 'error' });
                return;
            }

            let data = {
                phone: this.phoneNumber,
            };

            return this.axiosInstance
                .put(`Story/settings/update-phone/${this.story.eventId}`, data)
                .then(resp => {
                    this.story.phone = resp.data.phone;
                    this.$emit('force-finish-onboarding');
                    this.$emit('update-story');
                })
                .catch(error => {
                    console.log(error, 'phone error');
                });
        },
        handleProfileUploadClick() {
            this.$emit('profile-uploader-click');
        },
        handleStoryUploaderOpen() {
            this.$emit('story-uploader-open');
        },
        async clearDeadline() {
            if (this.$refs.deadlineCalendar) {
                this.$refs.deadlineCalendar.handleBackButton();
            }
            this.deadline = null;
            await this.updateStoryDeadline(this.story.eventId);
            this.setDeadlineDefault();

            this.calendarShowingTime = false;
            this.stepIndex = 2;
        },
        handleInitInvite() {
            this.$emit('init-invite');
        },
        handleDeadlineSave() {
            this.updateStoryDeadline(this.story.eventId);

            this.stepIndex = 2;
        },
        updateStoryDeadline(id) {
            let data = {
                deadline: this.deadline,
            };

            return this.axiosInstance
                .put(`Story/settings/update-deadline/${id}`, data)
                .then(resp => {
                    this.story.deadline = resp.data.deadline;
                    this.$emit('update-story');
                })
                .catch(error => {
                    console.log(error, 'deadline error');
                });
        },
        handleStepClick(val) {
            if (val > 1 && !this.story.deadline) {
                this.showSnackbar({ message: 'Please set deadline to continue', color: 'error' });
                return;
            }
            this.stepIndex = val;
        },
        async skipDeadline() {
            this.stepIndex = 2;
        },
        initOnboard() {
            this.windowIndex = 1;
            this.createStory();
        },
        createStory() {
            if (!this.event.id) {
                this.showSnackbar({ message: 'Invalid event id', color: 'error' });
                return;
            }

            let data = {
                deadline: null,
            };

            this.loading = true;
            return this.axiosInstance
                .post(`Story/settings/${this.event.id}`, data)
                .then(resp => {
                    this.getStory();
                    this.$emit('update-story');
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getStory() {
            return this.axiosInstance
                .get(`Story/settings/${this.event.id}`)
                .then(resp => {
                    this.story = resp.data;
                    this.phoneNumber = resp.data.phone;
                })
                .catch(error => {
                    if (error.response && error.response.status == 404) {
                        return null;
                    } else {
                        console.log(error, 'error');
                    }
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        setDeadlineDefault() {
            let tempDate = new Date();
            tempDate.setDate(tempDate.getDate() + 4);
            tempDate.setHours(17, 0, 0, 0);
            this.deadline = tempDate.toISOString();
        },
    },
    async mounted() {
        await this.setAuthToken();

        this.createAxiosInstance();
        await this.getStory();

        this.setDeadlineDefault();

        if (this.story.id) {
            this.windowIndex = 1;

            if (this.story.profilePhotoUrl) {
                this.stepIndex = 3;
            } else if (this.story.deadline) {
                this.stepIndex = 2;
            }
        }
    },
};
</script>
<style lang="scss">
.active-toggle-btn.family-sel {
    color: white !important;
    border: none !important;
    background-color: #ab0658 !important;
}
.active-toggle-btn.general-sel {
    color: white !important;
    border: none !important;
    background-color: #0d3d60 !important;
}
.onboarding-container {
    .deadline-link {
        color: #999999;
    }

    .deadline-link:hover {
        text-decoration: underline;
    }

    .deadline-btn {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
    .main-photo-cta {
        border: 3px dashed #bbb;
        border-radius: 32px;
        cursor: pointer;
    }

    .calendar-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        .ms-date-picker {
            width: fit-content;
        }
        .main-container {
            position: relative;
            top: 0;
            margin-bottom: 18px;
        }
        .v-input {
            display: none !important;
        }
    }
}
</style>

export const generateMenu = authObject => {
    return [
        {
            roles: ['SuperAdmin', 'Owner', 'RegionalManager'],
            to: 'Homes',
            params: {},
            label: 'Funeral Homes',
            icon: 'fa-regular fa-house',
            selectedWhen: ['Homes', 'ManageHomes', 'EditHomes', 'AdminSettings'],
        },
        {
            roles: ['SuperAdmin', 'Owner', 'RegionalManager'],
            to: 'DailyStreamSheet',
            params: {},
            label: 'Daily Streams Sheet',
            icon: 'fa-regular fa-video',
            selectedWhen: ['DailyStreamSheet'],
        },
        {
            roles: ['SuperAdmin'],
            to: 'Renders',
            params: {},
            label: 'Renders',
            icon: 'fa-regular fa-server',
            selectedWhen: ['Renders'],
        },
        {
            roles: ['SuperAdmin', 'FuneralHome', 'Owner', 'RegionalManager'],
            to: 'Services',
            params: {},
            label: 'All Services',
            icon: 'fa-regular fa-box-archive',
            selectedWhen: ['Services', 'EditServices', 'Uploader', 'CreateService'],
        },
        {
            roles: ['FuneralHome' /*'Owner'*/],
            to: 'GoogleReport',
            params: { funeralHomeId: authObject.funeralHomeId || null },
            label: 'Google Report',
            icon: 'fa-solid fa-chart-line-up',
            selectedWhen: ['GoogleReport'],
        },
        {
            roles: ['FuneralHome' /*'Owner'*/],
            to: 'Analytics',
            // params: { funeralHomeId: authObject.funeralHomeId || null },
            label: 'Analytics',
            icon: 'fa-solid fa-chart-simple',
            selectedWhen: ['Analytics'],
        },
        {
            roles: ['SuperAdmin'],
            to: 'Owners',
            params: {},
            label: 'Owners',
            icon: 'fa-regular fa-suitcase',
            selectedWhen: ['Owner', 'EditOwner', 'ManageOwners', 'Owners', 'HomesByOwner'],
        },
        {
            roles: ['SuperAdmin'],
            to: 'AdminBilling',
            params: {},
            label: 'Billing',
            icon: 'fa-regular fa-dollar-sign',
            selectedWhen: ['AdminBilling'],
        },
        {
            roles: ['SuperAdmin'],
            to: 'MiscAdminSettings',
            params: {},
            label: 'Admin Settings',
            icon: 'fa-regular fa-display',
            selectedWhen: ['MiscAdminSettings'],
        },
        {
            roles: ['SuperAdmin'],
            to: 'EcomAdmin',
            params: {},
            label: 'eCommerce',
            icon: 'fa-regular fa-cash-register',
            selectedWhen: [
                'Suppliers',
                'OrderDetails',
                'SupplierSettings',
                'CreateSupplier',
                'EditSupplier',
                'EcomAdmin',
                'ShipmentDetails',
                'CreateProduct',
                'EditProduct',
                'PurchaseDetails',
            ],
        },
        {
            roles: ['SuperAdmin'],
            to: 'TributeAdmin',
            params: {},
            label: 'Tribute',
            icon: 'fa-regular fa-photo-film-music',
            selectedWhen: ['TributeAdmin', 'TributeVideoSongAnalytics'],
        },
        {
            roles: ['SuperAdmin'],
            to: process.env.VUE_APP_POWERBI_URL,
            params: {},
            label: 'PowerBi',
            icon: 'fa-regular fa-chart-simple',
            selectedWhen: [],
        },

        {
            roles: ['Supplier'],
            to: 'SupplierSettings',
            params: { supplier_id: authObject.user['https://memoryshare.com/supplier_id'] },
            label: 'Home',
            icon: 'fa-regular fa-house',
            selectedWhen: ['SupplierSettings'],
        },
        {
            roles: ['Supplier'],
            to: 'EditSupplier',
            params: { id: authObject.user['https://memoryshare.com/supplier_id'] },
            label: 'Settings',
            icon: 'fa-regular fa-gear',
            selectedWhen: ['CreateSupplier', 'EditSupplier'],
        },
        {
            roles: ['FuneralHome'],
            to: 'FuneralHomeEcom',
            params: {},
            label: 'Purchases',
            icon: 'fa-regular fa-truck-fast',
            selectedWhen: ['FuneralHomeEcom', 'OrderDetails'],
        },
        {
            roles: ['FuneralHome'],
            to: 'Settings',
            params: {},
            label: 'Settings',
            icon: 'fa-regular fa-gear',
            selectedWhen: ['Settings'],
        },
        // {
        //     roles: ['FuneralHome'],
        //     to: 'FuneralHomeShop',
        //     params: {},
        //     label: 'Shop',
        //     icon: 'fa-solid fa-shop',
        //     selectedWhen: ['FuneralHomeShop', 'OrderDetails'],
        // },
        {
            roles: ['Owner'],
            to: 'OwnerSettings',
            params: { id: authObject.user['https://memoryshare.com/owner_id'] },
            label: 'Settings',
            icon: 'fa-regular fa-gear',
            selectedWhen: ['OwnerSettings'],
        },
        {
            roles: ['Owner', 'RegionalManager'],
            to: 'OwnerReport',
            params: { id: authObject.user['https://memoryshare.com/owner_id'] },
            label: 'Report',
            icon: 'fa-regular fa-display',
            selectedWhen: ['OwnerReport'],
        },
        // {
        //     roles: ['Supplier'],
        //     to: 'SupplierSettings',
        //     params: { supplier_id: authObject.session.supplier.id || null },
        //     label: 'Home',
        //     icon: 'fa-regular fa-house',
        //     selectedWhen: ['SupplierSettings'],
        // },
        // {
        //     roles: ['Supplier'],
        //     to: 'EditSupplier',
        //     params: { id: authObject.session.supplier.id || null },
        //     label: 'Settings',
        //     icon: 'fa-regular fa-gear',
        //     selectedWhen: ['EditSupplier'],
        // },
        {
            roles: ['FuneralHome', 'Owner', 'RegionalManager'],
            to: 'http://help.memoryshare.com/',
            params: {},
            label: 'Help Center',
            icon: 'fa-regular fa-circle-question',
            selectedWhen: [],
        },
        {
            roles: ['FuneralHome', 'Owner', 'RegionalManager'],
            to: 'TermsAndConditions',
            params: {},
            label: 'Terms and Conditions',
            icon: 'fa-regular fa-arrow-up-right-from-square',
            selectedWhen: [],
        },
        {
            roles: ['FuneralHome', 'Owner', 'RegionalManager', 'Supplier'],
            to: 'Feedback',
            params: {},
            label: 'Give Feedback',
            icon: 'fa-regular fa-thumbs-up',
            selectedWhen: [],
        },
    ];
};

<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="mb-5">
            <v-btn @click="$router.push({ path: `/supplier-settings/${$route.params.supplier_id}` })"
                ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Back</v-btn
            >
            <h3 class="mt-3" style="color: white">{{ editing ? 'Edit' : 'Create' }} Product</h3>
        </div>
        <div class="bg-white p-4 rounded elevation-6">
            <v-form v-model="formIsValid">
                <div style="display: flex; justify-content: space-between">
                    <strong>Product Details</strong>
                    <div v-if="$auth.role.includes('SuperAdmin') && status != null">
                        <v-menu attach offset-y v-if="status >= 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" dark :color="productStatuses[status].color">{{
                                    productStatuses[status].label
                                }}</v-chip>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="updateProductStatus(product.id, index)"
                                    v-for="(item, index) in productStatuses"
                                    :key="index"
                                >
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div v-else>
                        <v-chip v-if="status != null && status >= 0" dark :color="productStatuses[status].color">{{
                            productStatuses[status].label
                        }}</v-chip>
                    </div>
                </div>

                <v-row>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            :rules="[v => !!v || 'This field is required']"
                            v-model="name"
                            label="Name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <v-select
                            label="Category"
                            v-model="category"
                            :items="productCategories"
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div style="display: flex">
                            <v-checkbox v-model="trackingInventory" @change="checkInventory()"></v-checkbox>
                            <v-text-field
                                type="number"
                                min="0"
                                v-model.number="inventory"
                                label="Inventory"
                                :prepend-inner-icon="inventory == null ? 'mdi-infinity' : ''"
                                :disabled="!trackingInventory"
                            >
                            </v-text-field>
                        </div>
                    </v-col>
                </v-row>
                <v-textarea v-model="description" outlined label="Description"></v-textarea>
                <div>
                    <v-row>
                        <v-col cols="6">
                            <v-checkbox label="Contains Service" v-model="containsService"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox label="Only Purchase After Service" v-model="afterServiceOnly"></v-checkbox>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="!editing">
                    <div v-if="productPriceModel == 1">
                        <v-select label="Pricing Model" :items="pricingModels" v-model="productPriceModel"> </v-select>
                        <v-row no-gutters>
                            <v-col class="text-right">
                                <v-btn text small @click="addTier(priceTiers.length - 1)">+ Add Another Tier</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-for="(tier, index) in priceTiers" :key="index">
                            <v-col>
                                <v-text-field
                                    type="number"
                                    v-model.number="tier.fromUnits"
                                    :value="index - 1 >= 0 ? parseInt(priceTiers[index - 1].toUnits) + 1 : 1"
                                    label="From (Units)"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    :min="tier.fromUnits"
                                    type="number"
                                    :disabled="index + 1 == priceTiers.length"
                                    v-model.number="tier.toUnits"
                                    label="To (Units)"
                                    :prepend-inner-icon="index + 1 == priceTiers.length ? 'mdi-infinity' : ''"
                                    @input="updateNextToValue(index)"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <vuetify-money
                                    :rules="[v => !!v || 'This field is required']"
                                    :options="currencyOptions"
                                    label="Per Unit Price"
                                    v-model.number="tier.price"
                                ></vuetify-money>
                            </v-col>
                            <div class="mr-3" style="display: flex; align-items: center">
                                <v-btn text small @click="removeTier(index)">X</v-btn>
                            </div>
                        </v-row>
                    </div>

                    <div v-if="productPriceModel == 0">
                        <v-row>
                            <v-col cols="6">
                                <v-select label="Pricing Model" :items="pricingModels" v-model="productPriceModel">
                                </v-select>
                            </v-col>
                            <v-col cols="6">
                                <div style="max-width: 200px">
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="priceTiers[0].price"
                                    ></vuetify-money>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <i style="color: gray"
                            >MemoryShare offers free shipping on all orders, please price products accordingly.</i
                        >
                    </div>
                    <div>
                        <shipping-estimate :supplierId="$route.params.supplier_id" />
                    </div>
                </div>

                <div v-if="editing" class="mt-4">
                    <div style="display: flex; justify-content: space-between">
                        <strong>Images</strong>
                        <div>
                            <v-btn class="mb-2" @click="openImageModal" outlined small>Edit Images</v-btn>
                        </div>
                    </div>
                    <div @click="openImageModal" class="details-image-container" v-if="productImages.length > 0">
                        <div v-for="(image, index) in productImages" :key="index">
                            <div class="product-img">
                                <img :src="image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6" v-if="editing && product.priceModel >= 0">
                    <div style="display: flex; justify-content: space-between">
                        <span
                            ><strong>Price Details</strong
                            ><font-awesome-icon
                                class="ml-2 pointer"
                                style="font-size: 1.1rem"
                                icon="fa-regular fa-circle-question"
                                @click="pricingInfoModal = true"
                        /></span>
                        <!-- <v-btn @click="openImageModal" outlined small>Edit Images</v-btn> -->
                        <div>
                            <v-btn @click="openPriceUpdateModal" outlined small>Edit Pricing</v-btn>
                        </div>
                    </div>

                    <div v-if="product.priceModel == 1">
                        <!-- <p>Pricing Model: {{ pricingModels[product.priceModel].text }}</p> -->
                        <v-select label="Pricing Model" :items="pricingModels" disabled v-model="productPriceModel">
                        </v-select>
                        <v-data-table :items="product.prices" :headers="priceHeaders" hide-default-footer>
                            <template v-slot:item.price="{ item }">
                                {{ item.price | formatMoney }}
                            </template>
                        </v-data-table>
                    </div>
                    <div v-if="product.priceModel == 0">
                        <!-- <p>Pricing Model: {{ pricingModels[product.priceModel].text }}</p> -->
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    label="Pricing Model"
                                    :items="pricingModels"
                                    disabled
                                    v-model="productPriceModel"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <div style="max-width: 200px">
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="priceTiers[0].price"
                                        disabled
                                    ></vuetify-money>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <div class="text-right mt-2">
                    <!-- <v-btn @click="cancelForm()">Cancel</v-btn> -->

                    <!-- <v-btn :disabled="loading" @click="openImageModal()" color="primary">Images</v-btn> -->
                    <v-btn v-if="!editing" @click="createProduct()" :disabled="loading" color="primary">Create</v-btn>
                    <v-btn v-else @click="updateProduct()" :disabled="loading" color="primary">Update</v-btn>
                </div>

                <v-dialog max-width="600" @click:outside="refreshPriceData()" v-model="priceUpdateModal">
                    <v-card class="p-3">
                        <div v-if="productPriceModel == 1">
                            <v-select label="Pricing Model" :items="pricingModels" v-model="productPriceModel">
                            </v-select>
                            <v-row no-gutters>
                                <v-col class="text-right">
                                    <v-btn text small @click="addTier(priceTiers.length - 1)">+ Add Another Tier</v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-for="(tier, index) in priceTiers" :key="index">
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        v-model.number="tier.fromUnits"
                                        :value="index - 1 >= 0 ? parseInt(priceTiers[index - 1].toUnits) + 1 : 1"
                                        label="From (Units)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :min="tier.fromUnits"
                                        type="number"
                                        :disabled="index + 1 == priceTiers.length"
                                        v-model.number="tier.toUnits"
                                        label="To (Units)"
                                        :prepend-inner-icon="index + 1 == priceTiers.length ? 'mdi-infinity' : ''"
                                        @input="updateNextToValue(index)"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="tier.price"
                                    ></vuetify-money>
                                </v-col>
                                <div class="mr-3" style="display: flex; align-items: center">
                                    <v-btn text small @click="removeTier(index)">X</v-btn>
                                </div>
                            </v-row>
                        </div>
                        <div v-else-if="productPriceModel == 0">
                            <v-col>
                                <vuetify-money
                                    :rules="[v => !!v || 'This field is required']"
                                    :options="currencyOptions"
                                    label="Per Unit Price"
                                    v-model.number="priceTiers[0].price"
                                ></vuetify-money>
                            </v-col>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <v-btn :disabled="loading" @click="closePriceModal()">Cancel</v-btn>
                            <v-btn :disabled="loading" @click="updatePrice()" color="primary">Submit</v-btn>
                        </div>
                    </v-card>
                </v-dialog>
            </v-form>
            <v-dialog v-model="imageModal" max-width="600px">
                <v-card class="p-3">
                    <h4>Product Images</h4>
                    <product-image-upload @img-success="getProduct(product.id)" :productId="product.id" />
                </v-card>
            </v-dialog>

            <v-dialog v-model="pricingInfoModal" max-width="600px">
                <v-card class="p-3">
                    <v-card-title>Price Details</v-card-title>
                    <v-card-text
                        >MemoryShare offers free shipping on all orders, please price products accordingly.</v-card-text
                    >
                    <div class="text-center">
                        <shipping-estimate @refresh="pricingInfoModal = false" :supplierId="supplierId" />
                    </div>
                </v-card>
            </v-dialog>

            <v-dialog v-model="shippingRateModal" max-width="600px">
                <v-card class="p-3">
                    <v-card-title>Shipping Rates</v-card-title>
                    <v-card-text>These are shipping rate estimates!</v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </AppLayout>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import ProductImageUpload from '@/components/Misc/ProductImageUpload.vue';
import { productStatuses, productCategories } from '@/constants';
import ShippingEstimate from '@/components/Shop/ShippingEstimate.vue';

export default {
    metaInfo: {
        title: 'Product Details',
    },
    data() {
        return {
            tempInventory: 0,
            trackingInventory: true,
            pricingInfoModal: false,
            shippingRateModal: false,
            supplierId: 0,
            productStatuses,
            productCategories,
            imageModal: false,
            editing: false,
            titleTemplate: 'Create Product',
            priceUpdateModal: false,
            productToEdit: {},
            product: {},
            token: '',
            formIsValid: false,
            dialog: false,
            productId: '',
            name: '',
            price: '',
            description: '',
            category: 0,
            images: '',
            stripeProductId: '',
            stripePriceId: '',
            status: null,
            inventory: 0,
            selectedFile: null,
            loading: false,
            productImages: [],
            containsService: false,
            afterServiceOnly: false,
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            pricingModels: [
                { text: 'Standard', value: 0 },
                { text: 'Volume', value: 1 },
            ],
            productPriceModel: 0,
            priceTiers: [{ price: 0, fromUnits: 1, toUnits: null }],
            categories: ['Media', 'Keepsake', 'Other'],
            priceHeaders: [
                { text: 'From Units', value: 'fromUnits' },
                { text: 'To Units', value: 'toUnits' },
                { text: 'Price', value: 'price' },
                // { text: 'Actions', value: 'actions' },
            ],
        };
    },
    components: { ProductImageUpload, ShippingEstimate },
    // watch: {
    //     dialog() {
    //         if (this.editing) {
    //             this.productId = this.selectedProduct.id;
    //             this.name = this.selectedProduct.name;
    //             this.price = this.selectedProduct.price / 100;
    //             this.category = this.selectedProduct.category;
    //             this.images = this.selectedProduct.images;
    //             this.stripeProductId = this.selectedProduct.stripeProductId;
    //             this.stripePriceId = this.selectedProduct.stripePriceId;
    //             this.description = this.selectedProduct.description;
    //             this.supplierId = this.selectedProduct.supplierId;
    //             this.status = this.selectedProduct.status;
    //             this.inventory = this.selectedProduct.inventory;
    //         } else {
    //             this.productId = '';
    //             this.name = '';
    //             this.price = 0;
    //             this.category = 0;
    //             this.images = null;
    //             this.stripeProductId = '';
    //             this.stripePriceId = '';
    //             this.description = '';
    //             this.status = null;
    //         }
    //         this.refreshPriceData();
    //     },
    // },
    methods: {
        ...mapActions(['showSnackbar']),
        checkInventory() {
            if (!this.trackingInventory) {
                this.tempInventory = this.inventory;
                this.inventory = null;
            } else {
                this.inventory = this.tempInventory;
            }
        },
        openShippingRateModal() {
            this.pricingInfoModal = false;
            this.shippingRateModal = true;
        },
        closePriceModal() {
            this.priceUpdateModal = false;
            this.refreshPriceData();
        },
        openImageModal(item) {
            this.imageModal = true;
            this.selectedProduct = item;
        },
        refreshPriceData() {
            this.loading = false;
            this.productPriceModel = this.product.priceModel ? this.product.priceModel : 0;

            if (this.editing) {
                this.priceTiers = this.product.prices.map(price => {
                    return {
                        ...price,
                        price: price.price / 100,
                        // fromUnits: price.fromUnits,
                        // toUnits: price.toUnits,
                    };
                });
            }
            if (this.priceTiers.length > 0) {
                this.priceTiers[0].fromUnits = 1;
            }
        },
        openPriceUpdateModal() {
            this.priceUpdateModal = true;
        },
        newStatus(index) {
            this.status = index;
        },
        removeTier(i) {
            if (this.priceTiers.length > 1) {
                this.priceTiers.splice(i, 1);
            } else {
                this.showSnackbar({ message: 'At least one tier is required' });
            }
        },

        priceChanged() {
            const refreshedPrices = this.priceTiers.map(price => {
                return {
                    ...price,
                    price: price.price * 100,
                };
            });
            if (refreshedPrices.length == 1) {
                refreshedPrices[0].fromUnits = null;
            }
            if (refreshedPrices.length != this.product.prices.length) {
                return true;
            }

            if (this.product.priceModel != this.productPriceModel) {
                return true;
            }
            for (let i = 0; i < refreshedPrices.length; i++) {
                if (
                    parseInt(refreshedPrices[i].price) != parseInt(this.product.prices[i].price) ||
                    refreshedPrices[i].fromUnits != this.product.prices[i].fromUnits ||
                    refreshedPrices[i].toUnits != this.product.prices[i].toUnits
                ) {
                    return true;
                }
            }

            return false;
        },
        updateNextToValue(i) {
            if (i != this.priceTiers.length) {
                this.priceTiers[i + 1].fromUnits = this.priceTiers[i].toUnits + 1;
            }
        },
        addTier(i) {
            let lastTier = this.priceTiers[i];
            if (lastTier.toUnits == null) {
                lastTier.toUnits = lastTier.fromUnits + 1;
            }
            const newFrom = parseInt(lastTier.toUnits) + 1;
            this.priceTiers.push({ price: 0, fromUnits: newFrom, toUnits: null });
        },
        sendImage() {
            const fd = new FormData();
            fd.append('image', this.selectedFile, this.selectedFile.name);
            this.axios
                .post(process.env.VUE_APP_API + `/products/image-test`, fd, {
                    onUploadProgress: uploadEvent => {
                        console.log(
                            'Upload Progress:' + Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + '%',
                        );
                    },
                })
                .then(response => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        fileChange(e) {
            this.selectedFile = e.target.files[0];
        },
        updatePrice() {
            if (this.priceTiers.some(tier => parseInt(tier.price) == 0)) {
                this.showSnackbar({ message: 'Price cannot be $0.00' });
                return;
            }
            if (this.priceTiers.length < 2 && this.productPriceModel == 1) {
                this.showSnackbar({ message: 'Volume pricing requires multiple tiers' });
                return;
            }
            const transformedPrices = this.priceTiers.map(tier => {
                return {
                    ...tier,
                    price: tier.price * 100,
                };
            });
            this.loading = true;
            const data = {
                ...this.product,
                priceModel: this.productPriceModel,
                price: transformedPrices[0].price,
                prices: transformedPrices,
            };
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .put(process.env.VUE_APP_API + `/Products/update-pricing/${this.product.id}`, data)
                    .then(response => {
                        this.showSnackbar({ message: `Updated ${this.product.name}'s Price` });
                        this.getProduct(this.product.id);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error updating product' });
                    })
                    .finally(() => {
                        this.dialog = false;
                        this.loading = false;
                        this.priceUpdateModal = false;
                    });
            });
        },
        updateProductStatus(id, status) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + `/Products/update-status/${id}?status=${status}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Status updated' });
                        this.getProduct(this.product.id);
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error updating status' });
                    });
            } else {
                this.showSnackbar({ message: 'Must be admin to update status' });
            }
        },

        updateProduct() {
            if (this.formIsValid) {
                if (!(parseInt(this.inventory) >= 0)) this.inventory = null;
                if (this.inventory == null && !parseInt(this.inventory) >= 0) this.trackingInventory = false;

                let data = {
                    name: this.name,
                    category: this.category,
                    description: this.description,
                    supplierId: this.supplierId,
                    status: this.status,
                    inventory: this.inventory,
                    containsService: this.containsService,
                    purchaseAfterServiceOnly: this.afterServiceOnly,
                };

                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .put(process.env.VUE_APP_API + `/Products/${this.product.id}`, data)
                        .then(response => {
                            this.showSnackbar({ message: `Updated ${data.name}'s Details` });
                            this.dialog = false;
                            this.$emit('refresh');
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error updating product' });
                            this.dialog = false;
                        });
                });
            }
        },
        getProduct(id) {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/products/${id}`)
                    .then(response => {
                        this.containsService = response.data.containsService;
                        this.afterServiceOnly = response.data.purchaseAfterServiceOnly;
                        this.product = response.data;
                        this.productId = response.data.id;
                        this.name = response.data.name;
                        this.price = response.data.price / 100;
                        this.category = response.data.category;
                        this.images = response.data.images;
                        this.stripeProductId = response.data.stripeProductId;
                        this.stripePriceId = response.data.stripePriceId;
                        this.description = response.data.description;
                        this.supplierId = response.data.supplierId;
                        this.status = response.data.status;
                        this.inventory = response.data.inventory;
                        this.productPriceModel = response.data.priceModel;
                        this.productImages = response.data.images.split(',');
                        if (this.inventory != null) {
                            this.trackingInventory = true;
                        } else {
                            this.trackingInventory = false;
                        }
                        this.refreshPriceData();
                    })
                    .catch(error => {});
            });
        },
        createProduct() {
            if (this.formIsValid) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    if (this.priceTiers.some(tier => parseInt(tier.price) == 0)) {
                        this.showSnackbar({ message: 'Price cannot be $0.00' });
                        return;
                    }
                    if (this.priceTiers.length < 2 && this.productPriceModel == 1) {
                        this.showSnackbar({ message: 'Volume pricing requires multiple tiers' });
                        return;
                    }
                    const transformedPrices = this.priceTiers.map(tier => {
                        return {
                            ...tier,
                            price: tier.price * 100,
                        };
                    });

                    if (this.inventory == '') {
                        this.inventory = null;
                    }

                    this.loading = true;
                    let data = {
                        supplierId: parseInt(this.$route.params.supplier_id),
                        name: this.name,
                        price: transformedPrices[0].price,
                        category: this.category,
                        stripeProductId: this.stripeProductId,
                        description: this.description,
                        createDate: moment(Date.now()).toISOString(),
                        inventory: this.inventory,
                        priceModel: this.productPriceModel,
                        prices: transformedPrices,
                        containsService: this.containsService,
                        purchaseAfterServiceOnly: this.afterServiceOnly,
                    };

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .post(process.env.VUE_APP_API + '/Products', data)
                        .then(response => {
                            this.showSnackbar({ message: 'Added product' });

                            this.$emit('refresh');
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error adding product' });
                        })
                        .finally(() => {
                            this.dialog = false;
                            this.loading = false;
                        });
                });
            } else {
                this.showSnackbar({ message: 'Please complete the required fields before submitting' });
            }
        },
        cancelForm() {
            this.productId = '';
            this.name = '';
            this.price = null;
            this.category = 0;
            this.images = '';
            this.stripeProductId = '';
            this.description = '';
            this.dialog = false;
            this.containsService = false;
            this.afterServiceOnly = false;
        },
    },
    mounted() {
        if (this.$route.path.includes('/products/edit/')) {
            this.editing = true;
            this.getProduct(this.$route.params.product_id);
        }
        this.supplierId = this.$route.params.supplier_id;
    },
};
</script>
<style>
.details-image-container {
    cursor: pointer;
    padding: 1rem;
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    border: 1px solid #989898;
    border-radius: 5px;
}
.product-img {
    max-width: 100px;
    margin: 1rem;
    height: 100px;
    margin: 0;
    /* border: 2px solid red; */
}
img {
    max-width: 100%;
    max-height: 100%;
}
</style>

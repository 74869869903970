<template>
    <div style="margin-left: 0" class="dash-container">
        <div style="max-height: 100%; display: flex; justify-content: center; align-items: center">
            <v-card style="width: 500px" class="p-3">
                <h4>HOME SIGN UP</h4>
                <Recaptcha ref="recaptcha" @verify="response => submit(response)" />
                <v-btn @click="executeRecaptcha">go</v-btn>
                <v-btn @click="verify('fake token')">test</v-btn>
            </v-card>
        </div>
    </div>
</template>
<script>
import Recaptcha from '@/components/Misc/Recaptcha.vue';

export default {
    data() {
        return {
            token: '',
        };
    },
    components: { Recaptcha },
    methods: {
        executeRecaptcha() {
            this.$refs.recaptcha.execute();
            // console.log(this.$refs.recaptcha);
        },
        submit(tk) {
            this.token = tk;
            this.verify(tk);
        },
        verify(token) {
            const data = {
                token: token,
            };
            this.axios
                .post(process.env.VUE_APP_API + `/funeralhomes/signup/`, data)
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
    },
};
</script>
<style lang=""></style>

<template>
    <div style="margin-top: 2rem" v-if="comments.length > 0">
        <v-card class="p-3">
            <div class="d-flex justify-space-between">
                <h5>Comments</h5>
                <p v-if="events.length > 0">
                    Comments active unitl
                    <!-- <strong>{{ events[events.length - 1].time }}</strong> -->
                    <strong>{{ lockDate | moment('l') }}</strong>
                </p>
            </div>

            <div class="comments">
                <perfect-scrollbar ref="commenScroll" id="comment-scroll">
                    <div v-for="(comment, index) in comments" :key="index">
                        <div class="comment">
                            <div class="header">
                                <v-avatar class="mr-2" color="#999" size="20">
                                    <img v-if="comment.pictureUrl" />
                                    <span v-else class="text-white">{{ comment.name[0] }}</span>
                                </v-avatar>
                                <span style="color: gray">{{ comment.name }}</span>
                            </div>
                            <span class="content">{{ comment.content }}</span>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            comments: [],
        };
    },
    props: {
        service: {
            type: Object,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
    },
    computed: {
        lockDate() {
            const date = new Date(this.events[this.events.length - 1].time);
            const newDate = date.setDate(date.getDate() + 14);
            return newDate;
        },
    },
    methods: {
        getComments(slug) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .post(process.env.VUE_APP_API + `/comments/getall/${slug}`)
                    .then(response => {
                        this.comments = response.data;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
    },
    mounted() {
        this.getComments(this.$route.params.slug);
        // this.setCommentScrollSpeed();
    },
};
</script>
<style lang="scss" scoped>
#comment-scroll {
    height: 200px;
    overflow: hidden;
    position: relative;
}

.comments {
    border: 2px solid #eee;
    border-radius: 10px;
    height: 200px;
    transition: 0.3s;

    .comment {
        padding: 10px;
        .content {
            // border: 2px solid #eee;
            display: inline-block;
            padding-left: 0;
            margin-top: 5px;
        }

        .header {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
        }
    }
}
</style>

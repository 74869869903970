<template>
    <!-- <v-btn> {{ item.artist }} </v-btn> -->

    <div v-if="item.actions.previewUrl">
        <audio ref="audio" v-on:ended="resetSampleTrack()">
            <source :src="item.actions.previewUrl" />
        </audio>

        <custom-tooltip :tooltipProps="{ top: true }">
            <template v-slot:activator>
                <a v-if="item.actions.previewUrl" @click="playSampleTrack(item)">
                    <span v-if="!isPlaying" style="font-size: 30px" class="mdi mdi-play-circle-outline"></span>
                    <span v-else style="font-size: 30px" class="mdi mdi-pause-circle-outline"></span>
                </a>
            </template>

            <template v-slot:content>
                <span
                    >This is just a 30 second preview, to listen to the <br />
                    complete song please load in iTunes or Spotify</span
                >
            </template>
        </custom-tooltip>
    </div>
</template>

<script>
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
export default {
    props: ['item'],
    data() {
        return {
            isPlaying: false,
        };
    },
    components: {
        CustomTooltip,
    },
    methods: {
        playSampleTrack(item) {
            this.isPlaying = !this.isPlaying;

            var audio = this.$refs.audio;
            if (this.isPlaying) {
                audio.play();
            } else {
                audio.pause();
            }
        },
        resetSampleTrack() {
            this.isPlaying = false;
        },
    },
};
</script>

<style></style>

<template>
    <div>
        <div
            v-if="
                $auth.role.includes('SuperAdmin') ||
                $auth.role.includes('Owner') ||
                $auth.role.includes('RegionalManager')
            "
        >
            <div class="dash" ref="dashboardContainer"></div>

            <v-form class="mt-8" ref="form">
                <div class="">
                    <owner-logo-upload :ownerId="id" ref="uploader"></owner-logo-upload>
                </div>
                <div class="mt-8">
                    <div class="toggles" v-if="!$props.editing">
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="settingsForm.support"
                                    persistent-hint
                                    :items="boolOptions"
                                    label="Support"
                                    item-text="title"
                                    item-value="value"
                                    :disabled="!$auth.role.includes('SuperAdmin')"
                                >
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="settingsForm.whiteLabel"
                                    persistent-hint
                                    :items="boolOptions"
                                    label="White Label"
                                    item-text="title"
                                    item-value="value"
                                    :disabled="!$auth.role.includes('SuperAdmin')"
                                >
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    :disabled="!$auth.role.includes('SuperAdmin')"
                                    v-model="settingsForm.displayTerms"
                                    persistent-hint
                                    :items="boolOptions"
                                    label="Display Terms of Service"
                                    item-text="title"
                                    item-value="value"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <div :class="[{ 'disabled-bg': !settingsForm.displayCTA }, 'ease']"></div> -->
                </div>

                <v-row v-if="$auth.role.includes('SuperAdmin')">
                    <v-col cols="12">
                        <a
                            class="text-decoration-underline"
                            href="https://www.notion.so/memoryshare/How-to-Setup-a-New-Power-Bi-Report-614e4951756e4dda9a6c0718e4f12042"
                            target="_blank"
                            >How to Setup a New Power Bi Report</a
                        >
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="PowerBi Report Id"
                            v-model="powerBiSettings.powerBiReportId"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="PowerBi Workspace Id"
                            v-model="powerBiSettings.powerBiWorkspaceId"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="text-right">
                    <v-btn class="mb-5" v-if="$auth.role.includes('SuperAdmin')" color="success" @click="viewReport"
                        >View Report</v-btn
                    >
                </div>
                <v-row>
                    <v-col>
                        <v-textarea
                            style="font-family: monospace"
                            filled
                            v-model="settingsForm.embededScripts"
                            :label="'HTML'"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-right pb-5">
                            <v-btn v-if="!busy" @click="dialog = false" class="mr-5">cancel</v-btn>
                            <v-btn @click="save()" :loading="busy" :disabled="busy" color="primary">Save</v-btn>
                            <!-- <v-btn
                                v-if="$auth.role.includes('SuperAdmin')"
                                color="success"
                                absolute
                                left
                                @click="viewReport"
                                >View Report</v-btn
                            > -->
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OwnerLogoUpload from '@/components/Misc/OwnerLogoUpload.vue';
import 'vue-toggles/dist/vue-toggles.ssr.css';
import VueToggles from 'vue-toggles';

export default {
    name: 'GeneralSettings',
    props: {
        modal: {},
        admin: {},
        id: {},
        editing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        OwnerLogoUpload,
        VueToggles,
    },
    data() {
        return {
            // token: '',
            boolOptions: [
                {
                    title: 'Yes',
                    value: true,
                },
                {
                    title: 'No',
                    value: false,
                },
            ],
            htmlWrapper: '',
            uploader: null,
            dialog: false,
            home: '',
            busy: false,
            reportId: '',
            workspaceId: '',
            tempSettingsForm: {},
            settingsForm: {
                whiteLabel: false,
                embededScripts: '',
                embeddedAnalytics: '',
                support: false,
                displayTerms: false,
            },
            powerBiSettings: {},
            tempPowerBiSettings: {},
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        save() {
            let requests = [];
            let settingsEqual = this.isEqualObj(this.settingsForm, this.tempSettingsForm);
            let reportSettingsEqual = this.isEqualObj(this.powerBiSettings, this.tempPowerBiSettings);

            if (!settingsEqual) {
                requests.push(this.saveSettings());
            }
            if (!reportSettingsEqual) {
                requests.push(this.saveReportData());
            }

            Promise.allSettled(requests)
                .then(results => results.forEach(result => console.log(result)))
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating settings' });
                })
                .finally(() => {
                    this.showSnackbar({ message: 'Settings Updated' });
                });
        },
        viewReport() {
            this.$router.push({ name: 'OwnerReport', params: { data: this.id } });
        },
        openDialog() {
            this.dialog = true;
            this.getSettings();
            //     setTimeout(() => {
            //         this.initUppy();
            //     });
        },
        isEqualObj(obj1, obj2) {
            return JSON.stringify(obj1) == JSON.stringify(obj2);
        },
        getSettings() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${this.id}`)
                    .then(response => {
                        this.settingsForm = { ...response.data };
                        this.tempSettingsForm = { ...response.data };
                    })
                    .catch(error => {});
            });
        },
        saveReportData() {
            let paramString = `?powerBiReportId=${this.powerBiSettings.powerBiReportId}&powerBiWorkspaceId=${this.powerBiSettings.powerBiWorkspaceId}`;

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                return this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .put(process.env.VUE_APP_API + `/owners/power-bi/${this.id}${paramString}`);
                // .then(response => {
                //     this.showSnackbar({ message: 'Settings Updated' });
                // })
                // .catch(error => {
                //     console.log(error);
                // });
            });
        },
        getReportData() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/owners/power-bi/${this.id}`)
                    .then(response => {
                        this.powerBiSettings = {
                            powerBiReportId:
                                response.data.powerBiReportId !== null ? response.data.powerBiReportId : '',
                            powerBiWorkspaceId:
                                response.data.powerBiWorkspaceId !== null ? response.data.powerBiWorkspaceId : '',
                        };
                        // this.powerBiSettings = { ...response.data };
                        this.tempPowerBiSettings = { ...this.powerBiSettings };
                    })
                    .catch(error => {});
            });
        },
        saveSettings() {
            let data = {
                id: parseInt(this.$route.params.id),
                whiteLabel: this.settingsForm.whiteLabel,
                support: this.settingsForm.support,
                displayTerms: this.settingsForm.displayTerms,
                embededScripts: this.settingsForm.embededScripts,
                // embeddedAnalytics: this.settingsForm.embeddedAnalytics,
                logo: this.$refs.uploader.logoSrc,
            };

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                return this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .put(process.env.VUE_APP_API + `/owners/settings/${this.$route.params.id}`, data);
                // .then(response => {
                //     this.showSnackbar({ message: 'Settings updated' });
                //     this.$emit('message', 'Home updated');
                // })
                // .catch(errors => {
                //     this.showSnackbar({ message: "Error. Settings couldn't be updated." });
                //     this.$emit('message', 'Error updating home');
                // })
                // .then(() => {
                //     this.busy = false;
                // });
            });
        },
    },

    mounted() {
        this.getSettings();
        this.getReportData();
    },
};
</script>

<style lang="scss" scoped>
pre {
    display: block;
    unicode-bidi: embed;
    font-family: monospace;
    white-space: pre;
}
.pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    line-height: 1.5;
    word-break: break-all;
    white-space: pre;
    white-space: pre\9; /* IE7+ */
    display: block;
}
.clear-bottom {
    margin-bottom: -1rem;
}

.disabled-bg {
    padding: 0 1rem;
    border-radius: 5px;
    background: #f8f8f8;
}
.Support-toggle {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
}
.WhiteLabel-toggle {
    width: 95%;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.toggles {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-between;
}

.Support {
    margin-right: 50px;
}

.owner-logo-upload {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

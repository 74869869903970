<template>
    <div>
        <div class="content">
            <v-textarea
                solo
                background-color="#1f2227"
                class="embed-code"
                :value="streamString"
                ref="embedCode"
                no-resize
            ></v-textarea>
            <v-btn @click="copyEmbedCode" block small>
                <font-awesome-icon icon="fa-regular fa-clone" class="mr-2" />
                <span>Copy Embed Code</span>
            </v-btn>
        </div>

        <v-snackbar class="snackbar" v-model="snackbar" :timeout="3000">
            {{ message }}
        </v-snackbar>
    </div>
</template>

<script>
export default {
    props: ['slug'],
    data() {
        return {
            dialog: false,
            snackbar: false,
            message: '',
            streamString: this.setEmbed(),
        };
    },
    methods: {
        setEmbed() {
            return this.slug;
        },
        copyEmbedCode() {
            let textarea = this.$refs.embedCode.$el.querySelector('textarea');
            textarea.select();
            document.execCommand('copy');
            textarea.blur();
            this.message = 'Embed code copied to your clipboard';
            this.snackbar = true;
        },
    },
};
</script>

<style lang="scss">
.embed-code textarea {
    margin-top: 0 !important;
}
.content {
    display: block;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none !important;
}

.content textarea {
    color: burlywood !important;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true" class="analytics-section">
        <!-- Title and back button -->
        <div class="d-flex justify-space-between py-4">
            <h3 class="text-light d-flex" style="align-items: center; gap: 1rem">
                <font-awesome-icon icon="fa-solid fa-chart-simple" />
                <span class="title-text">{{ title }}</span>
            </h3>
            <div style="display: flex; gap: 10px">
                <!-- Back Btn -->
                <custom-tooltip :tooltipProps="{ bottom: true }">
                    <template v-slot:activator>
                        <v-btn @click="$router.back()" fab small>
                            <font-awesome-icon style="font-size: 1rem" icon="fa-solid fa-arrow-left" />
                        </v-btn>
                    </template>

                    <template v-slot:content>
                        <span>Back</span>
                    </template>
                </custom-tooltip>

                <!-- Download PDF Btn -->
                <custom-tooltip :tooltipProps="{ bottom: true }">
                    <template v-slot:activator>
                        <v-btn
                            id="download-pdf-btn"
                            :disabled="disablePdfBtn"
                            :loading="disablePdfBtn"
                            @click="downloadPdf"
                            fab
                            small
                        >
                            <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-file-lines" />
                        </v-btn>
                    </template>

                    <template v-slot:content>
                        <span>Download</span>
                    </template>
                </custom-tooltip>

                <!-- Share PDF Btn -->
                <custom-tooltip :tooltipProps="{ bottom: true }">
                    <template v-slot:activator>
                        <v-btn :disabled="disablePdfBtn" :loading="disablePdfBtn" @click="initSharePdfModal" fab small>
                            <font-awesome-icon style="font-size: 0.9rem" icon="fa-solid fa-share-from-square" />
                        </v-btn>
                    </template>

                    <template v-slot:content>
                        <span>Share</span>
                    </template>
                </custom-tooltip>
            </div>
        </div>

        <v-row>
            <v-col v-if="selectedInterval" cols="12">
                <div style="gap: 12px" class="d-flex flex-wrap justify-space-between align-center analytics-interval">
                    <v-btn-toggle class="btn-group-desk" v-model="selectedInterval" mandatory>
                        <v-btn
                            :color="selectedInterval == interval.value ? '#176bf1' : ''"
                            :class="selectedInterval == interval.value ? 'white--text' : ''"
                            small
                            v-for="(interval, index) in intervals"
                            :value="interval.value"
                            :key="interval.value"
                            @click="selectedInterval = interval.value"
                            >{{ interval.text }}</v-btn
                        >
                    </v-btn-toggle>

                    <div class="btn-group-mobile" style="width: 100%">
                        <div class="d-flex flex-column" style="gap: 4px">
                            <v-btn
                                depressed
                                large
                                :color="selectedInterval == interval.value ? '#176bf1' : ''"
                                :class="selectedInterval == interval.value ? 'white--text' : ''"
                                v-for="(interval, index) in intervals"
                                :value="interval.value"
                                :key="index"
                                @click="selectedInterval = interval.value"
                                >{{ interval.text }}</v-btn
                            >
                        </div>
                    </div>

                    <v-chip @click="openCustomDateRangeModal" dark color="#176bf1" label>
                        {{ computedDateRange }}
                    </v-chip>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="visibleStats">
            <v-col cols="12" sm="6" lg="3" v-if="watchTimes && selectedInterval">
                <v-card class="fill-height p-3 pb-2">
                    <p class="mb-1 text-truncate">Avg. Watch Time</p>

                    <div v-if="watchTimeLoading">
                        <h3>Loading...</h3>
                    </div>
                    <div v-else>
                        <h3 class="mb-0" v-if="watchTimes[this.selectedInterval]">
                            {{ secondsToTimestring(watchTimes[this.selectedInterval]) }}
                        </h3>
                        <h4 v-else class="mb-0">0m</h4>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" v-for="(stat, i) in visibleStats" :key="i">
                <v-card class="fill-height p-3 pb-2">
                    <p class="mb-1">{{ stat.title }}</p>

                    <h3 class="mb-0">{{ stat.value }}</h3>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12" sm="6" lg="3" v-if="watchTimes && selectedInterval">
                <v-card class="fill-height p-3">
                    <div v-if="watchTimeLoading">
                        <v-skeleton-loader type="text" class="mx-auto mb-3"></v-skeleton-loader>
                        <v-skeleton-loader type="text" max-width="100"></v-skeleton-loader>
                    </div>
                    <div v-else>
                        <p class="mb-1">Avg. Watch Time</p>
                        <h4 v-if="watchTimes[this.selectedInterval]">
                            {{ secondsToTimestring(watchTimes[this.selectedInterval]) }}
                        </h4>
                        <h4 v-else>0m</h4>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="3" v-for="i in 3">
                <v-card class="fill-height">
                    <v-card-text>
                        <v-skeleton-loader type="text" class="mx-auto mb-3"></v-skeleton-loader>
                        <v-skeleton-loader type="text" max-width="100"></v-skeleton-loader>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <!-- Map -->
            <v-col cols="12">
                <v-card class="fill-height">
                    <v-card-title class="d-flex justify-space-between">
                        <span>
                            <font-awesome-icon icon="fa-regular fa-map-location" style="margin-right: 1rem" />
                            Map View
                        </span>

                        <div class="d-flex flex-wrap flex-grow-1 justify-end align-center">
                            <custom-tooltip
                                style="cursor: help"
                                class="mr-8"
                                :tooltipProps="{ bottom: true, maxWidth: '250px' }"
                            >
                                <template v-slot:activator>
                                    <span class="text-caption">
                                        <font-awesome-icon
                                            class="mr-1"
                                            icon="fa-regular fa-circle-info"
                                        ></font-awesome-icon>

                                        <span class="text-decoration-underline"
                                            >Why are there so many views in Africa?</span
                                        >
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span
                                        >Some browsers don't report location. When this happens the view is logged with
                                        a 0,0 latitude and longitude.</span
                                    >
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true, nudgeLeft: '50px' }">
                                <template v-slot:activator>
                                    <div style="position: relative">
                                        <v-btn
                                            small
                                            depressed
                                            :color="scrollZoomEnabled ? 'primary' : ''"
                                            @click="toggleScrollZoom"
                                        >
                                            <font-awesome-icon
                                                icon="fa-regular fa-magnifying-glass-plus"
                                                style="font-size: 1rem"
                                            ></font-awesome-icon>
                                        </v-btn>
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <span>Toggle Scroll Zoom</span>
                                </template>
                            </custom-tooltip>
                        </div>
                    </v-card-title>

                    <v-card-text>
                        <AnalyticsMap
                            ref="viewerMap"
                            v-if="(funeralHomeId || $route.params.slug) && selectedInterval"
                            :funeralHomeId="funeralHomeId"
                            :serviceSlug="$route.params.slug"
                            :interval="selectedInterval"
                            :startDate="startDate"
                            :endDate="endDate"
                            @map-image-update="url => updateMapImage(url)"
                            @scroll-zoom-enabled="val => (scrollZoomEnabled = val)"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" xl="8">
                <v-card>
                    <div>
                        <v-card-text>
                            <div>
                                <AnalyticsChart
                                    v-if="(serviceId > 0 || funeralHomeId > 0) && selectedInterval"
                                    :serviceId="serviceId"
                                    :funeralHomeId="funeralHomeId"
                                    :interval="selectedInterval"
                                    :startDate="startDate"
                                    :endDate="endDate"
                                />
                            </div>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="12" xl="4">
                <v-card class="fill-height">
                    <v-card-title>
                        <h5 class="mb-0">In Market Views</h5>
                    </v-card-title>

                    <v-card-text>
                        <div style="background-color: white" class="rounded">
                            <market-percent-chart
                                v-if="(serviceId > 0 || funeralHomeId > 0) && selectedInterval"
                                :serviceId="serviceId"
                                :funeralHomeId="funeralHomeId"
                                :interval="selectedInterval"
                                :startDate="startDate"
                                :endDate="endDate"
                                in-market-color="#176bf1 "
                                out-market-color="#ff7837"
                                class="flex-grow-1"
                            ></market-percent-chart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="funeralHomeId && selectedInterval && !$route.params.slug">
            <v-col cols="12" lg="4">
                <v-card class="fill-height">
                    <v-card-title> Top Viewed Services </v-card-title>
                    <v-card-text class="fill-height">
                        <funeral-home-top-viewed-services
                            :funeral-home-id="parseInt(funeralHomeId)"
                            :interval="selectedInterval"
                            :startDate="startDate"
                            :endDate="endDate"
                            :page-size="10"
                        ></funeral-home-top-viewed-services>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="8">
                <v-card class="fill-height">
                    <v-card-title>Views / Services</v-card-title>
                    <v-card-text>
                        <service-to-views-chart
                            :funeral-home-id="parseInt(funeralHomeId)"
                            :interval="selectedInterval"
                            :startDate="startDate"
                            :endDate="endDate"
                        ></service-to-views-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- PDF Layout -->
        <vue-html2pdf
            v-if="visibleStats"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="previewPDFModal"
            :filename="pdfFileName"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
            id="vue-html-pdf"
        >
            <!-- pdf-content-width="1100px" -->
            <section id="pdf-wrapper" slot="pdf-content">
                <div v-if="service" class="analytics-pdf__serviceV2">
                    <div class="analytics-pdf__data-section__base analytics-pdf__data-section__serviceV2">
                        <img class="analytics-pdf__clipped-cloud-overlay" :src="msNewCloudClipped" alt="" />

                        <div class="analytics-pdf__data-header__serviceV2">
                            <div class="analytics-pdf__header-left">
                                <h1 class="analytics-pdf__title__serviceV2 font-weight-black">{{ pdfTitle }}</h1>
                                <p class="analytics-pdf__sub-header">
                                    {{ pdfSubtitle
                                    }}<span style="font-weight: 600">
                                        {{ ' ' + currentDateFormatted }}
                                    </span>
                                </p>
                            </div>
                            <div class="analytics-pdf__header-right">
                                <img class="analytics-pdf__ms-logo" :src="msLogoNewLight" alt="MemoryShare Logo" />
                            </div>
                        </div>
                        <div class="analytics-pdf__data-content__base analytics-pdf__data-content__serviceV2">
                            <div v-for="(stat, index) in coreStats" :key="index">
                                <div class="analytics-pdf__data-category">
                                    <div>
                                        <span class="analytics-pdf__data-value">{{ stat.value }}</span>
                                    </div>
                                    <div class="analytics-pdf__data-label">
                                        <span>{{ getPdfStatLabelWord(stat.title, 0) }}</span>
                                        <span>{{ getPdfStatLabelWord(stat.title, 1) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analytics-pdf__map-section__serviceV2">
                        <img
                            class="analytics-pdf__map-img__base analytics-pdf__map-img__serviceV2"
                            v-if="mapImage"
                            :key="mapImageRefreshKey"
                            :src="mapImage"
                        />
                    </div>
                </div>

                <div v-if="funeralHome" class="analytics-pdf__fh">
                    <div class="analytics-pdf__data-section__base analytics-pdf__data-section__fh">
                        <div class="analytics-pdf__data-header">
                            <div class="analytics-pdf__header-left">
                                <h1 class="font-weight-black">Summary for {{ funeralHome.name }}</h1>
                                <p class="analytics-pdf__sub-header">
                                    {{ pdfSubtitle
                                    }}<span class="analytics-pdf__highlight">
                                        {{ ' ' + computedDateRange }}
                                    </span>
                                </p>
                            </div>
                            <div class="analytics-pdf__header-right">
                                <img class="analytics-pdf__ms-logo__icon" :src="msLogoNewIcon" alt="MemoryShare Logo" />
                            </div>
                        </div>
                        <div class="analytics-pdf__data-content__base analytics-pdf__data-content__fh">
                            <div v-for="(stat, index) in coreStats" :key="index" class="analytics-pdf__data-category">
                                <div>
                                    <span class="analytics-pdf__data-value analytics-pdf__highlight">{{
                                        stat.value
                                    }}</span>
                                </div>
                                <div>
                                    <div class="analytics-pdf__data-label">
                                        <span>{{ getPdfStatLabelWord(stat.title, 0) }}</span>
                                        <span>{{ getPdfStatLabelWord(stat.title, 1) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analytics-pdf__map-section__fh">
                        <img
                            class="analytics-pdf__map-img__base"
                            v-if="mapImage"
                            :key="mapImageRefreshKey"
                            :src="mapImage"
                        />
                    </div>
                </div>
            </section>
        </vue-html2pdf>

        <!-- Printer Friendly PDF Layout -->
        <vue-html2pdf
            v-if="visibleStats"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="previewPDFModal"
            :filename="pdfFileName"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="100%"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf-printer-friendly"
            id="vue-html-pdf-printer-friendly"
        >
            <!-- pdf-content-width="1100px" -->
            <section id="pdf-wrapper-printer-friendly" slot="pdf-content">
                <div v-if="service" class="analytics-pdf__serviceV2">
                    <div
                        class="analytics-pdf__data-section__base analytics-pdf__data-section__service-printer-friendly"
                    >
                        <img class="analytics-pdf__clipped-cloud-overlay" :src="msNewCloudClipped" alt="" />

                        <div class="analytics-pdf__data-header__serviceV2">
                            <div class="analytics-pdf__header-left">
                                <h1 class="analytics-pdf__title__serviceV2 font-weight-black">{{ pdfTitle }}</h1>
                                <p class="analytics-pdf__sub-header">
                                    {{ pdfSubtitle
                                    }}<span style="font-weight: 600">
                                        {{ ' ' + currentDateFormatted }}
                                    </span>
                                </p>
                            </div>
                            <div class="analytics-pdf__header-right">
                                <img class="analytics-pdf__ms-logo" :src="msLogoNewDark" alt="MemoryShare Logo" />
                            </div>
                        </div>
                        <div class="analytics-pdf__data-content__base analytics-pdf__data-content__serviceV2">
                            <div v-for="(stat, index) in coreStats" :key="index">
                                <div class="analytics-pdf__data-category">
                                    <div>
                                        <span class="analytics-pdf__data-value">{{ stat.value }}</span>
                                    </div>
                                    <div class="analytics-pdf__data-label">
                                        <span>{{ getPdfStatLabelWord(stat.title, 0) }}</span>
                                        <span>{{ getPdfStatLabelWord(stat.title, 1) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analytics-pdf__map-section__serviceV2">
                        <img
                            class="analytics-pdf__map-img__base analytics-pdf__map-img__serviceV2"
                            v-if="mapImage"
                            :key="mapImageRefreshKey"
                            :src="mapImage"
                        />
                    </div>
                </div>

                <div v-if="funeralHome" class="analytics-pdf__fh">
                    <div class="analytics-pdf__data-section__base analytics-pdf__data-section__fh">
                        <div class="analytics-pdf__data-header">
                            <div class="analytics-pdf__header-left">
                                <h1 class="font-weight-black">Summary for {{ funeralHome.name }}</h1>
                                <p class="analytics-pdf__sub-header">
                                    {{ pdfSubtitle
                                    }}<span>
                                        {{ ' ' + computedDateRange }}
                                    </span>
                                </p>
                            </div>
                            <div class="analytics-pdf__header-right">
                                <img class="analytics-pdf__ms-logo__icon" :src="msLogoNewIcon" alt="MemoryShare Logo" />
                            </div>
                        </div>
                        <div class="analytics-pdf__data-content__base analytics-pdf__data-content__fh-friendly">
                            <div v-for="(stat, index) in coreStats" :key="index" class="analytics-pdf__data-category">
                                <div>
                                    <span class="analytics-pdf__data-value">{{ stat.value }}</span>
                                </div>
                                <div>
                                    <div class="analytics-pdf__data-label">
                                        <span>{{ getPdfStatLabelWord(stat.title, 0) }}</span>
                                        <span>{{ getPdfStatLabelWord(stat.title, 1) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analytics-pdf__map-section__fh">
                        <img
                            class="analytics-pdf__map-img__base"
                            v-if="mapImage"
                            :key="mapImageRefreshKey"
                            :src="mapImage"
                        />
                    </div>
                </div>
            </section>
        </vue-html2pdf>

        <!-- Share PDF Modal -->
        <v-dialog @click:outside="$refs.inviteForm.reset()" max-width="600px" v-model="sharePDFModal">
            <v-card>
                <div class="pl-3 pr-3 decorated-title">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Share "Certificate of Viewership"</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>

                <v-form ref="inviteForm" @submit.prevent="generateReport" v-model="formValid">
                    <div class="p-3">
                        <div class="email-group pt-0 p-2">
                            <v-combobox
                                v-model="emailList"
                                hide-selected
                                label="Emails"
                                @blur="manualBlur"
                                @input="handleInput"
                                ref="emailCombo"
                                multiple
                                small-chips
                                :deletable-chips="true"
                                :counter="emailList.length > 0"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        small
                                        :key="JSON.stringify(data.item)"
                                        :color="invalidInvites.includes(data.item) ? 'error' : '#f8f8f8'"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                    >
                                        {{ data.item }}
                                        <font-awesome-icon
                                            @click.stop="removeEmailFromList(data)"
                                            class="ml-2 del-btn"
                                            icon="fa-regular fa-circle-x"
                                        />
                                    </v-chip>
                                </template>
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <div>Press <span class="entr-key">Enter</span> to add email</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:counter="{ props }">
                                    <div v-if="props.value" class="email-counter">{{ props.value }} Recipients</div>
                                </template>
                            </v-combobox>

                            <v-btn
                                :disabled="emailList.length == 0 || loadingPdf || !mapImage"
                                @click="downloadPdf"
                                color="primary"
                                class="btn"
                            >
                                <font-awesome-icon icon="fa-solid fa-paper-plane-top" />
                            </v-btn>
                        </div>
                        <a
                            class="text-caption text-decoration-underline"
                            href="http://help.memoryshare.com/en/articles/9654728-certificate-of-viewership"
                            target="_blank"
                        >
                            <font-awesome-icon class="mr-1" icon="fa-regular fa-circle-info"></font-awesome-icon>
                            What is a "Certificate of Viewership"?</a
                        >
                    </div>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="customDateRangeModal" max-width="500px">
            <v-card>
                <v-card-title>Custom Date Range</v-card-title>
                <v-card-text>
                    <div class="analytics-calendar-container">
                        <calendar v-model="customStartDate" label="Start Date" />
                    </div>

                    <div class="analytics-calendar-container">
                        <calendar v-model="customEndDate" label="Stop Date" />
                    </div>
                    <!-- <calendar label="End" inline v-model="customEndDate"></calendar> -->
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="cancelCustomDateModal">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="saveCustomDates">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Share PDF Modal -->
    </AppLayout>
</template>

<script>
import { mapActions } from 'vuex';
import { logObjectWithTitle } from '@/utilities/debug-helpers.js';
import AnalyticsChart from '@/components/charts/AnalyticsChart';
import MonthChart from '@/components/charts/MonthChart';
import YearChart from '@/components/charts/YearChart';
// import Mapbox from 'mapbox-gl';
// import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox';
import VueHtml2pdf from 'vue-html2pdf';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
// import mapPlaceholder from '@/assets/images/map-placeholder.png';

import html2pdf from 'html2pdf.js';
import MarketPercentChart from '@/components/charts/MarketPercentChart.vue';
import FuneralHomeTopViewedServices from '@/components/Tables/Admin/FuneralHomeTopViewedServices.vue';
import ServiceToViewsChart from '@/components/charts/ServiceToViewsChart.vue';
import AnalyticsMap from '@/components/Analytics/AnalyticsMap.vue';
import Calendar from '@/components/ui/Calendar.vue';

import msLogoNewLight from '/public/pdf-assets/new-memoryshare-logo-white.png';
import msLogoNewDark from '/public/pdf-assets/new-memoryshare-logo-dark.png';
import msLogoNewIcon from '/public/pdf-assets/ms-logo-icon-new.svg';
import msNewCloudClipped from '/public/pdf-assets/new-cloud-clipped.svg';

// updated colors
//tead - #08655e
//orange - #ff7837
//blue - #176bf1
//red - #dc433b

export default {
    name: 'Analytics',
    metaInfo: {
        title: 'Analytics',
    },
    data() {
        return {
            // mapPlaceholder,
            msNewCloudClipped,
            msLogoNewIcon,
            msLogoNewLight,
            msLogoNewDark,
            funeralHomeId: 0,
            token: '',
            mapImage: '',
            loading: false,
            service: undefined,
            funeralHome: null,
            mapData: null,
            loadingPdf: false,
            chartMounted: false,
            scrollZoomEnabled: false,
            intervals: [
                {
                    text: 'This week',
                    value: 'week',
                },
                {
                    text: 'Monthly',
                    value: 'month',
                },
                {
                    text: 'Yearly',
                    value: 'year',
                },
                {
                    text: 'All',
                    value: 'all',
                },
                {
                    text: 'Custom',
                    value: 'custom',
                },
            ],
            selectedInterval: null,
            sharePDFModal: false,
            formValid: false,
            emailList: [],
            homeLogoSrc: null,
            map: null,
            mapLoaded: false,
            mapDataFetched: false,
            pdfFileName: 'Analytics',
            viewTotals: {},
            visibleStats: null,
            maxMapRecordsLimit: 200000,
            watchTimes: {},
            watchTimeLoading: true,
            serviceId: 0,
            mapImageRefreshKey: 0,
            customDateRangeModal: false,
            customStartDate: null,
            customEndDate: null,
            offset: 0,
            pdfTitle: null,
            pdfSubtitle: null,
            currentDateFormatted: null,
            isMonthlySummary: false,
            invalidInvites: [],
        };
    },

    methods: {
        ...mapActions(['showSnackbar']),
        filterContacts(contacts) {
            const validEmails = [];
            const validPhoneNumbers = [];
            const invalid = [];

            contacts.forEach(contact => {
                if (this.isValidEmail(contact)) {
                    validEmails.push(contact);
                } else {
                    invalid.push(contact);
                }
            });

            return {
                validEmails,
                validPhoneNumbers,
                invalid,
            };
        },
        handleInput() {
            this.invalidInvites = [];
            const filteredContacts = this.filterContacts(this.emailList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
                this.showSnackbar({ message: `${filteredContacts.invalid[0]} is not a valid email`, color: 'error' });
                return;
            }
        },
        isValidEmail(string) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(string);
        },
        isValidPhone(string) {
            const phonePattern = /^\+?\d{1,3}?[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
            return phonePattern.test(string);
        },
        saveCustomDates() {
            this.updateCustomIntervalDates(this.customStartDate, this.customEndDate);
            this.selectedInterval = 'custom';
            this.customDateRangeModal = false;

            this.startDate = this.customStartDate;
            this.endDate = this.customEndDate;

            if (this.funeralHome) {
                this.pdfFileName = `${this.selectedIntervalTitle} for ${this.funeralHome.name}`;
            }

            this.refreshViewTotals();
            this.getWatchTimes();
        },
        cancelCustomDateModal() {
            this.customDateRangeModal = false;

            let found = this.intervals.find(x => x.value == 'custom');

            if (found) {
                this.customStartDate = found.startDate;
                this.customEndDate = found.endDate;
            }
        },
        openCustomDateRangeModal() {
            this.customDateRangeModal = true;
        },
        initSharePdfModal() {
            this.sharePDFModal = true;

            if (this.serviceId) {
                this.selectedInterval = 'all';
            }
        },
        toggleScrollZoom() {
            if (this.$refs.viewerMap) {
                this.$refs.viewerMap.toggleScrollZoom();
            }
        },
        updateMapImage(url) {
            this.mapImage = url;
        },
        async getHomeLogo(id) {
            return this.axiosInstance
                .get(`/funeralhomes/logo/${id}`)
                .then(resp => {
                    return resp.data.logo;
                })
                .catch(error => {
                    console.log(error, 'logo error');
                });
        },
        manualBlur() {
            this.$refs.emailCombo.blur();
        },
        removeEmailFromList(data) {
            this.emailList.splice(data.index, 1);
        },
        sendPdf(file) {
            this.loadingPdf = true;

            let data = {
                emailList: this.emailList.join(','),
                file: file,
            };

            let endpoint = '';

            const totalViews = parseInt(this.visibleStats['totalViews'].value.replaceAll(',', ''));

            if (this.serviceId) {
                endpoint = `/services/email-analytic-pdf/${this.serviceId}`;
                data = {
                    ...data,
                    fileName: `${this.service.firstName}'s Service Viewers.pdf`,
                    totalViews: totalViews,
                };
            } else if (this.funeralHomeId) {
                endpoint = `/dogs/fh-email-analytic-pdf/${this.funeralHomeId}`;
                data = { ...data, fileName: 'Analytics Summary.pdf' };
            }

            if (!endpoint) {
                this.showSnackbar({ message: 'Error sending email', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(endpoint, data)
                .then(resp => {
                    this.emailList = [];
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error sending email', color: 'red' });
                })
                .finally(() => {
                    this.sharePDFModal = false;
                    this.loadingPdf = false;
                    this.showSnackbar({ message: 'Email sent!' });
                });
        },
        secondsToTimestring(seconds) {
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor((seconds % (3600 * 24)) / 3600);
            var m = Math.floor((seconds % 3600) / 60);
            var s = seconds % 60;

            if (d > 0) {
                return `${d.toLocaleString()}d ${h.toLocaleString()}h`;
            } else if (h > 0) {
                return `${h.toLocaleString()}h ${m.toLocaleString()}m`;
            } else if (m > 0) {
                return `${m}m`;
            } else {
                return `${s}s`;
            }
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            this.loadingPdf = true;
            if (this.emailList.length > 0) {
                html2pdf()
                    .set(options)
                    .from(pdfContent)
                    .toPdf()
                    .output('datauristring')
                    .then(pdfAsString => {
                        // The PDF has been converted to a Data URI string and passed to this function.
                        // Use pdfAsString however you like (send as email, etc)! For instance:
                        // console.log(pdfAsString);

                        this.sendPdf(pdfAsString.replace('data:application/pdf;base64,', ''));
                        return;
                    });
            }
        },
        hasDownloaded(event) {
            this.loadingPdf = false;
        },
        async generatePdfRaw(ref) {
            var opt = {
                margin: 1,
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 2 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
            };

            return new Promise((resolve, reject) => {
                if (this.$refs[ref] && typeof this.$refs[ref].generatePdf === 'function') {
                    const el = this.$refs[ref].$el;

                    html2pdf()
                        .from(el)
                        .set(opt)
                        .toPdf()
                        .output('datauristring')
                        .then(pdfAsString => {
                            const cleaned = pdfAsString.replace('data:application/pdf;base64,', '');

                            resolve(cleaned);
                        });
                } else {
                    reject(new Error('generatePdf function is not available on the vue-html2pdf component.'));
                }
            });
        },
        async generatePdfRawBase64(id) {
            const opt = {
                margin: 1,
                filename: 'printer-friendly-report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
            };

            await this.$nextTick(); // Ensure the DOM is fully rendered

            const element = document.getElementById(id);

            return html2pdf()
                .set(opt)
                .from(element)
                .toPdf()
                .output('datauristring')
                .then(pdfBase64 => {
                    // const base64String = pdfBase64.split(',')[1]; // Remove the prefix (data:application/pdf;base64,)
                    // const cleaned = pdfBase64.replace('data:application/pdf;base64,', '');
                    // console.log(base64String);
                    return pdfBase64;
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);
                });
        },
        async generatePdfRawBlob(id) {
            const opt = {
                margin: 1,
                filename: 'printer-friendly-report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, width: 1122, height: 793 },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
            };

            await this.$nextTick(); // Ensure the DOM is fully rendered

            const element = document.getElementById(id);

            return html2pdf()
                .set(opt)
                .from(element)
                .toPdf()
                .output('blob')
                .then(blob => {
                    // console.log(base64String);
                    return blob;
                })
                .catch(error => {
                    console.error('Error generating PDF:', error);
                });
        },
        downloadPdf() {
            this.invalidInvites = [];
            const filteredContacts = this.filterContacts(this.emailList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
                this.showSnackbar({ message: `${filteredContacts.invalid[0]} is not a valid email`, color: 'error' });
                return;
            }

            this.$refs.html2Pdf.generatePdf();
        },

        getWatchTimes() {
            this.watchTimeLoading = true;
            const params = {
                serviceId: this.service?.id || null,
                funeralHomeId: this.funeralHomeId || this.funeralHome?.id || this.$auth.funeralHomeId || null,
                startDate: this.startDate,
                endDate: this.endDate,
            };

            return this.axiosInstance
                .get(`/dogs/get/watch-times`, { params })
                .then(response => {
                    if (response.data) {
                        this.watchTimes[this.selectedInterval] = response.data.averageDuration;
                    }

                    return this.watchTimes;
                })
                .catch(error => {
                    console.log('error');
                })
                .finally(() => {
                    this.watchTimeLoading = false;
                });
        },
        isNumber(str) {
            return !isNaN(str) && !isNaN(parseFloat(str));
        },
        async getService(slug) {
            if (!slug || this.isNumber(slug)) {
                this.showSnackbar({ message: 'Invalid service slug', color: 'error' });
                return;
            }

            return this.axiosInstance
                .get(`/Services/slug/${slug}`)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    this.showSnackbar({ message: `Error! Unable to find service with slug ${slug}`, color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getFuneralHome(funeralHomeId) {
            if (!funeralHomeId) {
                this.showSnackbar({ message: 'Invalid Funeral Home Id', color: 'error' });
                return;
            }

            return this.axiosInstance
                .get(`/funeralhomes/${funeralHomeId}`)
                .then(response => {
                    return response.data.funeralHome;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTotalViews() {
            const params = {
                serviceId: this.service?.id || null,
                funeralHomeId: this.funeralHomeId || this.funeralHome?.id || this.$auth.funeralHomeId || null,
                startDate: this.startDate,
                endDate: this.endDate,
            };

            this.visibleStats = null;
            return this.axiosInstance
                .get(`/dogs/get/views`, { params })
                .then(({ data }) => {
                    this.viewTotals[this.selectedInterval] = {
                        totalViews: {
                            title: 'Total Participants',
                            color: '#176bf1',
                            value: data.totalViews.toLocaleString(),
                        },
                        totalLive: {
                            title: 'Joined Live',
                            color: '',
                            value: data.totalLive.toLocaleString(),
                        },
                        totalDelayed: {
                            title: 'Watched After',
                            color: '',
                            value: data.totalDelayed.toLocaleString(),
                        },
                    };

                    if (data.totalStory > 0 || data.totalTribute > 0) {
                        this.viewTotals[this.selectedInterval].totalStory = {
                            title: 'Story Views',
                            color: '',
                            value: data.totalStory.toLocaleString(),
                        };
                        this.viewTotals[this.selectedInterval].totalTribute = {
                            title: 'Tribute Views',
                            color: '',
                            value: data.totalTribute.toLocaleString(),
                        };
                    }

                    this.visibleStats = this.viewTotals[this.selectedInterval];
                })
                .catch(error => {
                    console.error(error);
                });
        },
        updateCustomIntervalDates(start, stop) {
            let found = this.intervals.find(x => x.value === 'custom');

            if (found) {
                found.startDate = start;
                found.endDate = stop;
            }
        },
        refreshViewTotals() {
            if (this.selectedInterval != 'custom' && this.viewTotals[this.selectedInterval]) {
                this.visibleStats = this.viewTotals[this.selectedInterval];
            } else {
                this.getTotalViews();
            }
        },
        refreshWatchTimes() {
            if (this.selectedInterval == 'custom' || !this.watchTimes[this.selectedInterval]) {
                this.getWatchTimes();
            }
        },
        async setAuthToken() {
            const result = await this.$auth.getIdTokenClaims();
            this.token = result.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        capitalizeFirstLetter(string) {
            if (typeof string !== 'string' || string.length === 0) return string;
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        isOneMonthApart(date1, date2) {
            const d1 = this.$moment(date1);
            const d2 = this.$moment(date2);

            // //Check both dates are on the first day of the month
            // if (d1.date() !== 1 || d2.date() !== 1) {
            //     return false;
            // }

            if (d1.add(1, 'month').isSame(d2, 'day')) {
                return true;
            }

            if (d2.add(1, 'month').isSame(d1, 'day')) {
                return true;
            }

            return false;
        },
        async initializeServiceSettings(slug) {
            this.service = await this.getService(slug);
            this.serviceId = this.service.id;

            const capitalizedFirstName = this.capitalizeFirstLetter(this.service.firstName);
            const capitalizedLastName = this.capitalizeFirstLetter(this.service.lastName);

            this.pdfFileName = `Service Analytics for ${capitalizedFirstName} ${capitalizedLastName}`;
            this.pdfTitle = `${capitalizedFirstName}'s Service`;
            this.pdfSubtitle = `Here is a report on ${capitalizedFirstName}'s service as of`;
        },
        initializeCurrentDate() {
            this.currentDateFormatted = this.$moment().format('LL');
        },
        initializeIntervals() {
            this.offset = this.$moment(new Date()).utcOffset();
            // this.customStartDate = this.$moment.utc().utcOffset(this.offset).startOf('month').toISOString();
            // this.customEndDate = this.$moment.utc().utcOffset(this.offset).startOf('day').add(1, 'd').toISOString();
            this.customStartDate = this.$moment().startOf('month').toISOString();
            this.customEndDate = this.$moment().startOf('day').add(1, 'd').toISOString();

            this.intervals = [
                {
                    text: 'Week',
                    value: 'week',
                    startDate: this.$moment.utc().utcOffset(this.offset).startOf('week').toISOString(),
                    endDate: this.$moment.utc().utcOffset(this.offset).startOf('day').add(1, 'd').toISOString(),
                },
                {
                    text: 'Last 30 Days',
                    value: 'last30',
                    startDate: this.$moment
                        .utc()
                        .utcOffset(this.offset)
                        .startOf('day')
                        .subtract(30, 'days')
                        .toISOString(),
                    endDate: this.$moment.utc().utcOffset(this.offset).startOf('day').add(1, 'd').toISOString(),
                },
                {
                    text: 'Month',
                    value: 'month',
                    startDate: this.$moment.utc().utcOffset(this.offset).startOf('month').toISOString(),
                    endDate: this.$moment.utc().utcOffset(this.offset).startOf('day').add(1, 'd').toISOString(),
                },
                {
                    text: 'Year',
                    value: 'year',
                    startDate: this.$moment.utc().utcOffset(this.offset).startOf('year').toISOString(),
                    endDate: this.$moment.utc().utcOffset(this.offset).startOf('day').add(1, 'd').toISOString(),
                },
                {
                    text: 'All',
                    value: 'all',
                    startDate: null,
                    endDate: null,
                },
                {
                    text: 'Custom',
                    value: 'custom',
                    startDate: this.customStartDate,
                    endDate: this.customEndDate,
                },
            ];
        },
        checkRouteForCustomDates() {
            if (!this.$route.query.startDate && !this.$route.query.endDate) return;

            let startDate =
                this.$route.query.startDate === 'null' ? null : decodeURIComponent(this.$route.query.startDate);
            let endDate = this.$route.query.endDate === 'null' ? null : decodeURIComponent(this.$route.query.endDate);

            if (startDate && endDate) {
                this.isMonthlySummary = this.isOneMonthApart(startDate, endDate);

                this.updateCustomIntervalDates(startDate, endDate);
                this.selectedInterval = 'custom';
            }
        },
        async loadData() {
            let found = this.intervals.find(x => x.value == this.selectedInterval);
            if (found) {
                this.startDate = found.startDate;
                this.endDate = found.endDate;
            }

            await this.getTotalViews();
            await this.getWatchTimes();
        },
        async loadFuneralHome(id) {
            if (!id) return;

            this.funeralHome = await this.getFuneralHome(this.funeralHomeId);
            this.pdfFileName = `${this.selectedIntervalTitle} for ${this.funeralHome.name}`;
            this.pdfTitle = this.selectedIntervalTitle;

            this.pdfFileName = `Service Analytics for ${this.funeralHome.name} - ${this.computedDateRange}`;
            this.pdfSubtitle = `Here is the analytics report for`;
        },
        async loadHomeLogo() {
            const homeId = this.funeralHomeId || this.funeralHome?.id || this.service?.funeralHomeId;
            if (homeId) {
                this.homeLogoSrc = await this.getHomeLogo(homeId);
            }
        },
        getPdfStatLabelWord(title, wordIndex) {
            let singleWord = title.split(' ')[wordIndex];

            if (!singleWord) return title;

            return singleWord;
        },
        async initializeComponent() {
            await this.setAuthToken();
            this.createAxiosInstance();

            this.initializeCurrentDate();

            if (this.$route.params.slug) {
                await this.initializeServiceSettings(this.$route.params.slug);
            } else {
                this.funeralHomeId = parseInt(this.$route.query.fh || this.$auth.funeralHomeId);
            }

            this.initializeIntervals();

            this.selectedInterval = this.funeralHomeId > 0 ? 'last30' : 'all';

            this.checkRouteForCustomDates();

            await this.loadData();

            if (this.funeralHomeId) {
                this.loadFuneralHome(this.funeralHomeId);
            }

            await this.loadHomeLogo();

            if (this.$route.query.share && this.$route.query.share === 'true') {
                this.initSharePdfModal();
            }
            this.componentRefreshKey++;
        },
    },

    async mounted() {
        await this.initializeComponent();
    },
    watch: {
        async selectedInterval(newVal, oldVal) {
            let found = this.intervals.find(x => x.value == newVal);

            if (found) {
                this.startDate = found.startDate;
                this.endDate = found.endDate;
            }

            if (this.funeralHome) {
                this.pdfFileName = `${this.selectedIntervalTitle} for ${this.funeralHome.name}`;
            }

            this.refreshViewTotals();
            this.refreshWatchTimes();

            if (this.interval == 'custom') {
                await this.getTotalViews();
                await this.getWatchTimes();
            }
        },
    },
    filters: {
        firstWordTitleFilter(title) {
            const words = title.split(' ');
            if (words[0]) {
                return words[0];
            }

            return '';
        },
    },
    computed: {
        previewPDFModal() {
            if (this.emailList.length > 0) {
                return false;
            }
            return true;
        },
        title() {
            if (this.service) {
                return `Analytics for ${this.service.firstName + ' ' + this.service.lastName}`;
            }
            return 'Analytics';
        },
        // pdfTitle() {
        //     if (this.service) {
        //         return `Viewers for ${this.service.firstName + ' ' + this.service.lastName}`;
        //     } else if (this.funeralHome) {
        //         return this.pdfFileName;
        //     }
        //     return 'Viewers';
        // },
        selectedIntervalTitle() {
            if (this.selectedInterval == 'month' || this.isMonthlySummary) {
                return 'Monthly Summary';
            } else if (this.selectedInterval == 'week') {
                return 'Weekly Summary';
            } else if (this.selectedInterval == 'year') {
                return 'Yearly Summary';
            } else {
                return 'Summary';
            }
        },
        disablePdfBtn() {
            if (!this.mapImage) {
                return true;
            }

            if (!this.visibleStats) {
                return true;
            }

            if (this.$route.params.slug && !this.service) {
                return true;
            }

            if (this.$route.params.funeralHomeId && (!this.funeralHome || !this.chartMounted)) {
                return true;
            }

            return false;
        },
        statCount() {
            if (this.visibleStats) {
                return Object.keys(this.visibleStats).length;
            } else {
                return 0;
            }
        },
        coreStats() {
            if (this.visibleStats) {
                return Object.values(this.visibleStats).slice(0, 3);
            } else {
                return [];
            }
        },
        startDate: {
            set(newVal) {
                let encodedDate = encodeURIComponent(newVal);
                if (encodedDate !== this.$route.query.startDate) {
                    this.$router.replace({ query: { ...this.$route.query, startDate: encodedDate } });
                }
            },
            get() {
                let decodedDate = decodeURIComponent(this.$route.query.startDate);
                if (decodedDate == 'null') {
                    return null;
                }

                return decodedDate || null;
            },
        },
        endDate: {
            set(newVal) {
                let encodedDate = encodeURIComponent(newVal);
                if (encodedDate !== this.$route.query.endDate) {
                    this.$router.replace({ query: { ...this.$route.query, endDate: encodedDate } });
                }
            },
            get() {
                let decodedDate = decodeURIComponent(this.$route.query.endDate);

                if (decodedDate == 'null') {
                    return null;
                }

                return decodedDate || null;
            },
        },

        computedDateRange() {
            const now = this.$moment(new Date()).format('ll');

            switch (this.selectedInterval) {
                case 'all':
                    return `All History - ${now}`;
                default:
                    return `${this.$moment.utc(this.startDate).utcOffset(this.offset).format('ll')} - ${this.$moment
                        .utc(this.endDate)
                        .utcOffset(this.offset)
                        .format('ll')}`;
            }
        },
    },

    components: {
        AnalyticsChart,
        MonthChart,
        YearChart,
        AnalyticsMap,
        // MglMap,
        // MglMarker,
        // MglNavigationControl,
        VueHtml2pdf,
        CustomTooltip,
        MarketPercentChart,
        FuneralHomeTopViewedServices,
        ServiceToViewsChart,
        Calendar,
    },
};
</script>

<style lang="scss">
#pdf-wrapper .analytics-pdf__fh {
    height: 100%;
    width: 100%;
    background-color: white;
    max-width: 100%;
    aspect-ratio: 1.414;
    display: flex;
    flex-direction: column;
}

#pdf-wrapper-printer-friendly .analytics-pdf__fh {
    height: 100%;
    width: 100%;
    background-color: white;
    max-width: 100%;
    aspect-ratio: 1.414;
    display: flex;
    flex-direction: column;
}
#pdf-wrapper .analytics-pdf__serviceV2 {
    height: 100%;
    width: 100%;
    background-color: white;
    max-width: 100%;
    aspect-ratio: 1.414;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
}

#pdf-wrapper-printer-friendly .analytics-pdf__serviceV2 {
    height: 100%;
    width: 100%;
    background-color: white;
    max-width: 100%;
    aspect-ratio: 1.414;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
}

.analytics-pdf__data-section__base {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.analytics-pdf__data-section__fh {
    flex-grow: 1;
    background-color: white;
    color: black;
    padding: 0 52px;
}

.analytics-pdf__data-section__serviceV1 {
    flex-grow: 1;
    background-color: #0c3c60;
    color: white;
    padding: 0 52px;
}

.analytics-pdf__data-section__serviceV2 {
    flex-grow: 1;
    background: linear-gradient(137deg, rgba(210, 232, 249, 1) 0%, rgba(121, 187, 238, 1) 72%);
    color: rgba(12, 48, 96, 0.8);
    border-radius: 16px;
    padding: 20px;
    position: relative;
}

.analytics-pdf__data-section__service-printer-friendly {
    flex-grow: 1;
    color: black;
    border-radius: 16px;
    padding: 20px;
    position: relative;
}

.analytics-pdf__data-header {
    display: flex;
}

.analytics-pdf__data-header__serviceV2 {
    display: flex;
    padding: 0 24px;
}

.analytics-pdf__data-content__base {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.analytics-pdf__data-content__fh {
    background-color: white;
    border: 2px solid #e9e9e9;
    border-radius: 16px;
    height: 128px;
}
.analytics-pdf__data-content__fh-printer-friendly {
    background-color: white;
    border-radius: 16px;
    height: 128px;
}
.analytics-pdf__data-content__serviceV1 {
    background-color: #11578c;
    border-radius: 16px;
    height: 128px;
}
.analytics-pdf__data-content__serviceV2 {
    height: 80px;
}

.analytics-pdf__header-left {
    flex-grow: 1;
}

.analytics-pdf__sub-header {
    font-size: 16px;
    padding: 0 !important;
    margin: 0 !important;
}

.analytics-pdf__highlight {
    color: #ff6c37;
}

.analytics-pdf__ms-logo {
    width: 153px;
}

.analytics-pdf__ms-logo__icon {
    width: 65px;
}

.analytics-pdf__data-category {
    display: flex;
    gap: 12px;
    align-items: center;
}

.analytics-pdf__data-value {
    font-size: 65px;
    margin: 0;
    padding: 0;
}
.analytics-pdf__data-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    font-size: 24px;
    line-height: 87%;
    text-transform: uppercase;
    margin-top: 6px;
}

.analytics-pdf__map-section__serviceV2 {
    object-fit: cover;
    object-position: center;
    height: 500px;
}

.analytics-pdf__map-img__base {
    width: 100%;
    object-fit: cover;
}
.analytics-pdf__map-img__serviceV2 {
    border-radius: 16px;
}

.analytics-pdf__clipped-cloud-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50%;
    height: 100%;
}

.analytics-calendar-container .ms-date-picker {
    transition: 1s;
    .main-container {
        max-width: 330px;
        position: relative;
        top: 0;
        margin-bottom: 12px;
    }
}

.analytics-section {
    .blue-gradient-bg {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(23, 107, 241, 1) 100%);
        // background: linear-gradient(0deg, rgba(23, 107, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .orange-gradient-bg {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 120, 55, 1) 100%);
    }

    .teal-gradient-bg {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(8, 101, 94, 1) 100%);
    }

    .bold-border {
        border: 2px solid black !important;
    }

    .dark-text {
        color: black !important;
    }
    #map {
        position: relative;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .dash-container {
        background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f93c8bac-6f36-4379-a2a3-ef312021d719.svg')
            no-repeat 0 -18rem;
    }

    .title-block {
        text-shadow: 1px 1px #666;
    }

    .title-text {
        vertical-align: middle;
        margin-left: 0.3rem;
    }

    .card-padding {
        padding: 2rem 1rem;
        margin-top: 0;
    }

    .view-callout {
        margin-bottom: 3rem;
    }

    .active {
        background: #272727 !important;
        color: #fff !important;
    }
    .map-container {
        height: 500px;
        border-radius: 5px;
    }

    .statistics {
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-gap: 1rem;
        /* place-items: center; */
        border: 2px solid red;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 1rem;

        &__col {
            text-align: center;

            /* &:not(:last-child) {
            border-right: 1px solid #d2d2d2;
        } */
        }

        &__views {
            margin-top: 0.5em;
            font-size: 3em;
        }
    }

    .map-loading-container {
        position: absolute;
        z-index: 1000;
        background: rgb(255, 255, 255);
        padding: 16px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 2rem;
            font-weight: bold;
        }

        .map-loading-overlay-inner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            background: rgb(255, 255, 255, 0.8);
            padding: 16px;
        }
    }

    .btn-group-mobile {
        display: none;
    }

    @media (max-width: 500px) {
        .btn-group-desk {
            display: none;
        }

        .btn-group-mobile {
            display: block;
        }
    }
}
</style>

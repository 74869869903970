<template>
    <div>
        <v-card>
            <v-card-title class="text-h5"> Super Admin Video Edit </v-card-title>

            <v-card-text v-if="loaded">
                <form class="super-container" @submit.prevent="handleFormSubmission">
                    <v-text-field v-model="formData.id" label="Video ID" />
                    <v-text-field v-model="formData.eventId" label="Event ID" />
                    <v-text-field v-model="formData.funeralHomeId" label="Funeral Home ID" />
                    <v-text-field v-model="formData.ownerId" label="Owner Id" />
                    <v-text-field v-model="formData.serviceId" label="Service ID" />

                    <v-text-field v-model="formData.archiveFilePath" label="Archive File Path" />
                    <v-text-field v-model="formData.rawFilepath" label="Raw File Path" />
                    <v-text-field v-model="formData.convertedFilePath" label="Converted File Path" />
                    <v-text-field v-model="formData.videoThumbnail" label="Video Thumbnail" />
                    <v-text-field v-model="formData.hlsGuid" label="Hls Guid" />
                    <v-select
                        :items="hlsStatuses"
                        v-model="formData.hlsStatus"
                        item-text="label"
                        item-value="value"
                        label="Hls Status"
                        clearable
                    />
                    <v-text-field v-model="formData.hlsUrl" label="Hls Url" />

                    <v-text-field v-model="formData.totalRawFileSize" label="Total Raw File Size" />
                    <v-text-field v-model="formData.convertedFileSize" label="Converted File Size" />

                    <v-text-field v-model="formData.start" label="Start" />
                    <v-text-field v-model="formData.stop" label="Stop" />

                    <v-text-field v-model="formData.duration" label="Duration" />
                    <v-text-field v-model="formData.timeEncoding" label="Time Encoding" />

                    <v-text-field v-model="formData.encodeEndTime" label="Encode End Time" />
                    <v-text-field v-model="formData.encodeStartTime" label="Encode Start Time" />

                    <v-text-field v-model="formData.uploadEndTime" label="Upload End Time" />
                    <v-text-field v-model="formData.uploadStartTime" label="Upload Start Time" />

                    <v-text-field v-model="formData.timeUploading" label="Time Uploading" />

                    <v-select
                        label="Storage Type"
                        v-model="formData.storageType"
                        :items="storageTypes"
                        item-text="label"
                        item-value="value"
                    ></v-select>
                    <v-text-field v-model="formData.order" label="Order" />

                    <v-text-field v-model="formData.editFilePathWithoutSlate" label="Edit File path Without Slate" />
                    <fieldset>
                        <label class="date-label">Create Date</label>
                        <datetime
                            placeholder="-- --"
                            type="date"
                            v-model="formData.createDate"
                            class="theme-orange"
                            zone="local"
                            value-zone="local"
                        ></datetime>
                    </fieldset>

                    <v-checkbox v-model="formData.live" label="Live" />

                    <div></div>
                    <v-btn class="mt-3" block color="#0C3C60" dark large type="submit">Save</v-btn>
                </form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { hlsStatuses, storageTypes } from '@/constants';
import { mapActions } from 'vuex';
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hlsStatuses,
            storageTypes,
            token: null,
            formData: {},
            loaded: false,
            videoID: null,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */
        convertIntObj: function (obj) {
            {
                let res = {};
                Object.keys(obj).forEach(key => {
                    if (key === 'pin') {
                        res[key] = obj[key];
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });
                return res;
            }
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/Videos/admin-edit/${this.videoID}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        getVideo() {
            this.axiosInstance
                .get(`${process.env.VUE_APP_API}/videos/events/${this.event.id}`)
                .then(response => {
                    this.videoID = response.data.id;

                    this.axiosInstance
                        .get(`${process.env.VUE_APP_API}/Videos/admin-edit/${this.videoID}`)
                        .then(response => {
                            this.formData = response.data;

                            this.loaded = true;
                        })
                        .catch(error => {});
                })
                .catch(error => {});
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getVideo();
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
</style>

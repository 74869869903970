<template>
    <div>
        <branded-modal @close="displayModal = false" max-width="900px" v-model="displayModal">
            <template v-slot:title>
                <h2>{{ title }}</h2>
            </template>

            <template v-slot:body>
                <div class="main">
                    <div class="qr-section">
                        <div style="max-width: 350px; display: flex; flex-direction: column; align-items: center">
                            <div v-if="link" class="qr-wrap">
                                <qrcode-vue
                                    class="desktop-qr"
                                    ref="qtDesk"
                                    background="#f8f8f8"
                                    :value="link"
                                    :key="`qr-dt-${refreshKey}`"
                                    size="230"
                                    render-as="svg"
                                />
                                <qrcode-vue
                                    class="mobile-qr"
                                    background="#f8f8f8"
                                    :value="link"
                                    :key="`qr-mb-${refreshKey}`"
                                    size="180"
                                    render-as="svg"
                                />
                            </div>

                            <div
                                style="
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                "
                                v-else
                            >
                                <p>Link not found</p>
                            </div>

                            <div class="text-right mt-4" style="width: 100%" v-if="link">
                                <v-btn text small color="#ff530d" dark depressed @click="downloadQrAsJpeg('qtDesk')">
                                    <font-awesome-icon
                                        style="font-size: 1rem"
                                        icon="fa-regular fa-arrow-down-to-bracket"
                                    ></font-awesome-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-divider class="vertical-divider" vertical></v-divider>
                    <v-divider class="horizontal-divider"></v-divider>

                    <div class="input-section">
                        <div class="input-section-content">
                            <!-- <div class="text-center">
                                <v-alert dense color="#ab0658" text outlined v-if="linkType == 'family'">
                                    <p>
                                        <strong
                                            >Allows uploading photos, deleting photos, choosing music and a
                                            theme</strong
                                        >
                                    </p>
                                    <div>*Recommended for next of kin</div>
                                </v-alert>
                                <v-alert dense color="#0c3c60" text outlined v-if="linkType == 'contribute'">
                                    <p><strong>Only allows uploading photos</strong></p>
                                    <div>*Recommended for extended family and friends</div>
                                </v-alert>

                                <v-alert dense color="#0c3c60" text outlined v-if="linkType == 'story'">
                                    <p><strong>Allows uploading story</strong></p>
                                </v-alert>
                            </div> -->
                            <div class="input-group">
                                <h6 class="ma-0"><strong>Send Email or SMS</strong></h6>

                                <p class="ma-0" style="font-size: 0.8rem">
                                    Send an email or sms with instructions to a list of recipients.
                                </p>
                                <!-- <v-text-field dense outlined></v-text-field> -->
                                <div>
                                    <v-combobox
                                        :disabled="!link"
                                        outlined
                                        dense
                                        @input="handleInput"
                                        style="width: 100%"
                                        v-model="inviteList"
                                        hide-selected
                                        @blur="manualBlur"
                                        ref="emailCombo"
                                        class="email-input"
                                        multiple
                                        small-chips
                                        :deletable-chips="true"
                                        :counter="inviteList.length > 0"
                                    >
                                        <!-- color="#f8f8f8" -->
                                        <template v-slot:selection="data">
                                            <v-chip
                                                label
                                                class="ma-1"
                                                :color="invalidInvites.includes(data.item) ? 'error' : '#f8f8f8'"
                                                small
                                                :key="JSON.stringify(data.item)"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                            >
                                                {{ data.item }}
                                                <font-awesome-icon
                                                    @click.stop="removeInviteFromList(data)"
                                                    class="ml-2 del-btn"
                                                    icon="fa-regular fa-circle-x"
                                                />
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <div style="border: 2px transparent">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <div>
                                                            Press <span class="entr-key">Enter</span> to add email
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </template>
                                        <template v-slot:counter="{ props }">
                                            <div v-if="props.value" class="email-counter">
                                                {{ props.value }} Recipients
                                            </div>
                                        </template>
                                    </v-combobox>
                                </div>
                            </div>

                            <div class="input-group">
                                <h6 class="ma-0"><strong>Copy Link</strong></h6>

                                <p class="ma-0" style="font-size: 0.8rem">
                                    Copy this link and use it to send to anyone to upload photos.
                                </p>
                                <v-text-field
                                    :disabled="!link"
                                    ref="linkInput"
                                    class="custom-text-color"
                                    readonly
                                    :value="link"
                                    dense
                                    outlined
                                >
                                    <template v-slot:append>
                                        <div style="cursor: pointer" @click="copyToClipboard(link)" class="mt-1">
                                            <font-awesome-icon icon="fa-regular fa-clone" />
                                        </div>
                                    </template>
                                </v-text-field>
                            </div>
                        </div>
                        <div class="action-container">
                            <v-btn @click="openTab(link)" :disabled="!link" class="action-btn" depressed
                                >Preview
                                <font-awesome-icon class="ml-2" icon="fa-regular fa-share-from-square" />
                            </v-btn>
                            <v-btn
                                class="action-btn"
                                @click="handleSubmit"
                                depressed
                                color="orange"
                                :dark="inviteList.length != 0"
                                :disabled="inviteList.length == 0 || !link"
                                >Send
                                <font-awesome-icon class="ml-2" icon="fa-solid fa-paper-plane-top" />
                            </v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </branded-modal>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';

export default {
    data() {
        return {
            displayModal: false,
            inviteList: [],
            invalidInvites: [],
            refreshKey: 0,
        };
    },
    components: {
        QrcodeVue,
        BrandedModal,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            required: true,
        },
        linkType: {
            type: String,
            required: false,
        },
        qrFileName: {
            type: String,
            required: true,
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        downloadQrAsJpeg(val) {
            const ref = this.$refs[val];

            if (ref) {
                const svgEl = ref.$el.querySelector('svg');
                const svgData = new XMLSerializer().serializeToString(svgEl);
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob(blob => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        var formatted = this.capitalizeAllWords(this.qrFileName);
                        link.download = `${formatted}.jpg`;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }, 'image/jpeg');
                };

                img.src = 'data:image/svg+xml;base64, ' + btoa(svgData);
            }
        },
        capitalizeAllWords(textString) {
            var split = textString.split(' ');

            for (let i = 0; i < split.length; i++) {
                split[i] = this.capitalize(split[i]);
            }

            var formatted = split.join(' ');
            return formatted;
        },
        capitalize(word) {
            if (!word || typeof word !== 'string') {
                return '';
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        },
        handleInput() {
            if (this.inviteList.length > 10) {
                this.showSnackbar({ message: 'Please limit invites to a maximum of 10 at a time.', color: 'error' });
                while (this.inviteList.length > 10) {
                    this.inviteList.pop();
                }
                return;
            }

            for (let i = 0; i < this.inviteList.length; i++) {
                let num = this.inviteList[i];
                if (this.isValidPhone(num) && !num.includes('.')) {
                    let formatted = this.formatPhoneNumber(num);
                    this.inviteList[i] = formatted;
                }
            }

            const filteredContacts = this.filterContacts(this.inviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
            }
        },
        formatPhoneNumber(phoneNumber) {
            return phoneNumber.slice(0, 3) + '.' + phoneNumber.slice(3, 6) + '.' + phoneNumber.slice(6);
        },
        refresh() {
            this.refreshKey++;
        },
        refreshLink() {
            this.$emit('refresh-link', this.linkType);
        },
        openTab(url) {
            window.open(url, '_blank');
        },
        downloadPdf() {
            this.$refs.qrPrint.$refs.html2Pdf.generatePdf();
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$refs.linkInput.blur();
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        removeInviteFromList(data) {
            this.inviteList.splice(data.index, 1);

            let found = this.invalidInvites.find(x => x == data.item);
            if (found) {
                let index = this.invalidInvites.indexOf(found);
                this.invalidInvites.splice(index, 1);
            }
        },
        manualBlur(event) {
            this.$refs.emailCombo.blur();
        },
        reset() {
            this.displayModal = false;
            this.inviteList = [];
        },
        isValidEmail(string) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(string);
        },
        isValidPhone(string) {
            const phonePattern = /^\+?\d{1,3}?[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
            return phonePattern.test(string);
        },
        filterContacts(contacts) {
            const validEmails = [];
            const validPhoneNumbers = [];
            const invalid = [];

            contacts.forEach(contact => {
                if (this.isValidEmail(contact)) {
                    validEmails.push(contact);
                } else if (this.isValidPhone(contact)) {
                    validPhoneNumbers.push(contact);
                } else {
                    invalid.push(contact);
                }
            });

            return {
                validEmails,
                validPhoneNumbers,
                invalid,
            };
        },
        handleSubmit() {
            const filteredContacts = this.filterContacts(this.inviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
                this.showSnackbar({
                    message: `${filteredContacts.invalid.length} contacts are not a valid email or phone number.`,
                    color: 'error',
                });
                return;
            }

            let data = {
                emailList: filteredContacts.validEmails,
                phoneList: filteredContacts.validPhoneNumbers,
            };

            this.$emit('submit-invites', data);
        },
        isValidEmail(value) {
            // Source: https://emailregex.com/
            const emailRegex = /\S+@\S+\.\S+/;
            return emailRegex.test(value);
        },
    },
};
</script>
<style lang="scss" scoped>
.custom-text-color ::v-deep input {
    color: #ff530d !important; /* Change the color to your desired value */
}
.main {
    display: flex;
    padding: 20px;
    gap: 20px;
}

.active-toggle-btn.family-sel {
    color: white !important;
    border: none !important;
    background-color: #ab0658 !important;
}
.active-toggle-btn.general-sel {
    color: white !important;
    border: none !important;
    background-color: #0d3d60 !important;
}

.mobile-qr {
    display: none;
}
.input-section {
    flex-grow: 1;
    // padding: 20px;
    display: flex;
    flex-direction: column;
}
.input-section-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.input-group {
    flex-direction: column;
}

.email-input ::v-deep .v-select__slot {
    max-height: 100px; /* Change the height to your desired value */
    overflow-y: auto;
    overflow-x: hidden;
}

.qr-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qr-wrap {
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: fit-content;
}

.vertical-divider {
    display: block;
}

.horizontal-divider {
    display: none;
}

.action-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

.action-btn {
    flex-grow: 1;
}

@media (max-width: 630px) {
    .main {
        flex-direction: column;
    }

    .mobile-qr {
        display: block;
    }
    .desktop-qr {
        display: none;
    }

    .vertical-divider {
        display: none;
    }

    .horizontal-divider {
        display: block;
    }
}

@media (max-width: 470px) {
    .action-container {
        width: 100%;
        flex-direction: column;
    }
}
</style>

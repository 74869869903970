<template>
    <v-row>
        <v-card-title class="ml-12">
            <font-awesome-icon icon="fa-regular fa-user-group" class="mr-2" />
            <span class="headline"> Manage Users </span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <div class="mr-12 text-right">
                <v-btn v-if="!editingUser" @click="initUpdateUserForm()" color="primary">+ Add New user</v-btn>
            </div>
            <v-container>
                <v-dialog v-model="deleteModal" persistent max-width="290">
                    <v-card v-if="selectedUser">
                        <v-card-title class="headline"> Delete Users? </v-card-title>
                        <v-card-text
                            >Are you sure you want to delete
                            {{ selectedUser.firstName + ' ' + selectedUser.lastName }}?</v-card-text
                        >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                            <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <reset-password
                    v-if="passwordResetModal && token"
                    :token="token"
                    :user="selectedUser"
                    @close-password-reset-modal="passwordResetModal = false"
                ></reset-password>
                <v-data-table
                    v-if="!editingUser"
                    :headers="headers"
                    :items="users"
                    :loading="loading"
                    :footer-props="{
                        itemsPerPageOptions: [10, 25, 50],
                    }"
                    :options.sync="options"
                    loading-text="Getting Users..."
                >
                    <template v-slot:item.name="{ item }">
                        <p class="mb-0">{{ item.firstName + ' ' + item.lastName }}</p>
                        <small v-if="!item.emailVerified" style="opacity: 0.7">Pending</small>
                    </template>

                    <template v-slot:item.userType="{ item }">
                        <v-chip dark :color="userTypes[item.userType].color">{{
                            userTypes[item.userType].label | labelFilter()
                        }}</v-chip>
                    </template>

                    <template v-slot:item.email="{ item }">
                        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <div class="action-list">
                            <custom-tooltip v-if="!item.emailVerified" :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-regular fa-envelope"
                                            @click="resendEmailInvite(item)"
                                        />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Resend Email Invite</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-regular fa-pencil"
                                            @click="initUpdateUserForm(item)"
                                            style="font-size: 1rem"
                                        />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Edit</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span @click="openPasswordResetModal(item)">
                                        <font-awesome-icon icon="fa-regular fa-lock" style="font-size: 1rem" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Password</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span @click="openPasswordResetEmailModal(item)">
                                        <font-awesome-icon icon="fa-regular fa-key" style="font-size: 1.1rem" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Password Reset Email</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span @click="openDeleteModal(item)">
                                        <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Delete</span>
                                </template>
                            </custom-tooltip>
                        </div>
                    </template>
                </v-data-table>

                <div v-else>
                    <v-form class="form-container" ref="form" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="userForm.firstName"
                                    label="First Name"
                                    :rules="[v => !!v || 'First name is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="userForm.lastName"
                                    label="Last Name"
                                    :rules="[v => !!v || 'Last name is required']"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    :disabled="updatingUser"
                                    v-model="userForm.email"
                                    type="email"
                                    label="Email"
                                    :rules="[v => !!v || 'Email is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="userForm.userType"
                                    :items="ownerTypes"
                                    item-text="text"
                                    item-value="value"
                                    label="User Type"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="userForm.phoneNo"
                                    v-mask="'###.###.####'"
                                    label="Phone Number"
                                ></v-text-field>
                            </v-col>
                            <v-col></v-col>
                        </v-row>

                        <v-alert v-if="errorMessage.length" type="error">
                            {{ errorMessage }}
                        </v-alert>

                        <div class="mt-4">
                            <v-btn @click.prevent="submitUserForm()" color="primary">{{
                                updatingUser ? 'Update User' : 'Create User'
                            }}</v-btn>
                            <v-btn @click="cancelUserForm()" text class="ml-4">cancel</v-btn>
                        </div>
                    </v-form>
                </div>
            </v-container>
        </v-card-text>

        <v-dialog v-model="deleteModal" persistent max-width="290">
            <v-card v-if="selectedUser">
                <v-card-title class="headline"> Delete User? </v-card-title>
                <v-card-text
                    >Are you sure you want to delete<br />
                    <strong>{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</strong
                    >?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                    <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog @click:outside="cancelPasswordResetEmail" v-model="passwordResetEmailModal" max-width="500">
            <v-card>
                <v-card-title>Send Password Reset Email</v-card-title>
                <v-card-text
                    >Send password reset email to <strong>{{ selectedUser ? selectedUser.email : '' }}</strong
                    >?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="cancelPasswordResetEmail" depressed>Cancel</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="loading"
                        @click="submitPasswordResetEmailRequest"
                        depressed
                        color="primary"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </v-row>
</template>

<script>
import ResetPassword from '@/components/Misc/ResetPassword.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { mapActions } from 'vuex';

export default {
    data: () => ({
        users: [],
        snackbar: false,
        message: '',
        openDialog: false,
        loading: true,
        deleteModal: false,
        passwordResetModal: false,
        selectedUser: {},
        search: '',
        editingUser: false,
        updatingUser: false,
        errorMessage: '',
        userId: -1,
        options: {
            itemsPerPage: 10,
        },
        // auth0loading: true,
        userForm: {
            firstName: '',
            lastName: '',
            email: '',
            userType: 1,
            phoneNo: '',
        },
        id: -1,
        passwordResetEmailModal: false,
        headers: [
            { text: 'Name', value: 'name', width: '30%' },
            { text: '', value: 'userType' },
            { text: 'Email', value: 'email' },
            { text: 'Actions', value: 'actions', width: '20%', align: 'center' },
        ],
        userTypes: [
            { label: 'Funeral Home', color: 'orange' },
            { label: 'Owner', color: 'blue' },
            { label: 'Super Admin', color: 'red' },
            { label: 'Supplier', color: 'purple' },
            { label: 'Regional Manager', color: 'green' },
        ],
        ownerTypes: [
            { text: 'Owner', value: 1 },
            { text: 'Regional Manager', value: 4 },
        ],
    }),
    props: ['token'],
    filters: {
        labelFilter(value) {
            if (value === 'Regional Manager') {
                return 'RM';
            } else {
                return value;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        submitPasswordResetEmailRequest() {
            if (!(this.selectedUser?.id > 0)) {
                this.showSnackbar({ message: 'Invalid user id', color: 'error' });
                return;
            }

            this.loading = true;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/password-reset-email/' + this.selectedUser.id)
                .then(resp => {
                    this.showSnackbar({ message: 'Password reset email sent!', color: 'success' });
                    this.cancelPasswordResetEmail();
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error sending password reset email', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelPasswordResetEmail() {
            this.passwordResetEmailModal = false;
            this.selectedUser = null;
        },
        openPasswordResetEmailModal(item) {
            this.passwordResetEmailModal = true;
            this.selectedUser = item;
        },
        getUsers(id, quick) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/users/owners/${id}?quick=${quick}`)
                .then(response => {
                    if (response.data) {
                        this.users = response.data;
                        // if (quick) {
                        //         this.getUsers(id, false);
                        //     }
                        // if (!quick) {
                        //     this.auth0loading = false;
                        // }
                    }
                })
                .catch(error => {})
                .then(() => {
                    this.loading = false;
                });
        },
        initUpdateUserForm(item = null) {
            this.updatingUser = false;
            this.editingUser = true;

            if (item) {
                this.updatingUser = true;
                this.userId = parseInt(item.id);
                this.userForm.firstName = item.firstName;
                this.userForm.lastName = item.lastName;
                this.userForm.email = item.email;
                this.userForm.userType = item.userType;
                this.userForm.phoneNo = item.phoneNo;
            }
        },
        submitUserForm() {
            this.errorMessage = '';

            this.updatingUser ? this.updateUser() : this.inviteUser();
        },
        updateUser() {
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                id: this.userId,
                ownerId: parseInt(this.$route.params.id),
                userType: this.userForm.userType == 4 ? 4 : 1,
                phoneNo: this.userForm.phoneNo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + '/users/', data)
                .then(response => {
                    this.message = 'User Updated';
                    this.snackbar = true;
                    this.cancelUserForm();
                    this.getUsers(this.$route.params.id, true);
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                });
        },
        inviteUser() {
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                email: this.userForm.email,
                ownerId: parseInt(this.$route.params.id),
                userType: this.userForm.userType === 4 ? 4 : 1,
                phoneNo: this.userForm.phoneNo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/invite', data)
                .then(response => {
                    this.message = 'User created';
                    this.snackbar = true;
                    this.cancelUserForm();
                    this.getUsers(this.id, true);
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                });
        },
        cancelUserForm() {
            this.editingUser = false;
            this.updatingUser = false;
            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userId = -1;
            this.userForm.userType = 1;
            this.userForm.phoneNo = '';
        },
        resendEmailInvite(user) {
            if (user.emailVerified) {
                this.snackbar = true;
                this.message = `${user.email} has already verified their email address`;
                return;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${token}` } })
                .post(process.env.VUE_APP_API + '/users/resend?id=' + user.id)
                .then(response => {
                    if (response.data) {
                        this.snackbar = true;
                        this.message = `Invite resent to ${user.email}`;
                    }
                })
                .catch(error => {
                    this.snackbar = true;
                    this.message = `Error sending to ${user.email}`;
                });
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.deleteModal = true;
        },
        openPasswordResetModal(item) {
            this.selectedUser = item;
            this.passwordResetModal = true;
        },
        deleteUser() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/Users/${this.selectedUser.id}`)
                .then(response => {
                    this.message = `${this.selectedUser.firstName} ${this.selectedUser.lastName} deleted!`;
                    this.loading = true;
                    this.getUsers(parseInt(this.$route.params.id), true);
                })
                .catch(error => {
                    this.message = `Error deleting user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`;
                })
                .then(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        // this.options = {
        //     itemsPerPage: 25,
        //     page: settings.serviceTable.page || 1,
        // };
    },
    watch: {
        id: function () {
            if (this.id !== -1) {
                this.getUsers(this.id, true);
                // this.auth0loading = true
            }
        },
    },
    components: {
        ResetPassword,
        CustomTooltip,
    },
};
</script>

<style lang="scss" scoped>
.icon {
    margin: 0 0.5rem;
}
</style>

<template>
    <div>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />
        <div class="Logocontainer">
            <img v-if="logoSrc" :src="logoSrc" class="user-logo" />
            <v-btn width="30%" color="blue-grey" :loading="!Boolean(uppy)" class="white--text" @click="handleOpen">
                <span>Upload Logo</span>
                <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-up-from-bracket" />
            </v-btn>
        </div>
    </div>
</template>

<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';

export default {
    name: 'OwnerLogoUpload',
    props: ['ownerId'],
    data() {
        return {
            id: 'Dashboard',
            isOpen: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                note: '5MB file size limit',
            },
            uppy: null,
            logoSrc: '',
        };
    },
    components: {
        DashboardModal,
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.logoSrc = await this.getLogoSrc();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        getLogoSrc() {
            const ownerId = this.ownerId;
            return this.axiosInstance
                .get(`owners/logo/${ownerId}`)
                .then(response => {
                    this.$store.dispatch('updateCustomLogo', response.data.logo);
                    return response.data.logo;
                })
                .catch(error => console.error(error));
        },
        getUploadUrl(file) {
            const ownerId = this.ownerId;
            return this.axiosInstance
                .get(`owners/logo/sas/${ownerId}/${file.name}`)
                .then(response => {
                    log('==== getUploadUrl() called ====', 'silver');
                    return response.data;
                })
                .catch(error => console.error(error));
        },
        async initUppy() {
            const MAX_FILE_SIZE = 15000000; // 5 MB
            this.uppy = new Uppy({
                id: 'logo-upload',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: ['image/*'],
                },
            }).use(UppyAddUploaderPlugin, {
                uploader: fileIds => {
                    return new Promise(async (resolve, reject) => {
                        const file = this.uppy.getFile(fileIds[0]);
                        const { sas: uploadEndpoint, fileName } = await this.getUploadUrl(file);
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(file.data);
                        reader.addEventListener('error', error => {});
                        reader.addEventListener('load', async () => {
                            try {
                                await this.axios
                                    .create({
                                        headers: {
                                            'Content-Type': file.type,
                                            'x-ms-blob-type': 'BlockBlob',
                                        },
                                    })
                                    .put(uploadEndpoint, reader.result);
                                await this.axiosInstance.post(`/owners/logo/${this.ownerId}/${fileName}`);
                                this.logoSrc = await this.getLogoSrc();
                                this.handleClose();
                                this.showSnackbar({ message: 'Successfully uploaded logo' });
                                resolve('done');
                            } catch (error) {
                                console.error(error);
                                reject(error);
                            }
                        });
                    });
                },
            });
        },

        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.Logocontainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(128, 128, 128, 0.383);
    padding: 30px;
    border-radius: 5px;
}
.user-logo {
    display: block;
    max-width: 300px;
    margin-bottom: 1rem;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div>
            <OrderTable />
        </div>
    </AppLayout>
</template>
<script>
import OrderTable from '@/components/Tables/OrderTable.vue';
export default {
    metaInfo: {
        title: 'Shipments',
    },
    data() {
        return {};
    },
    components: { OrderTable },
};
</script>
<style lang="scss" scoped></style>

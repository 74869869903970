<template>
    <div>
        <div>
            <v-chip class="mb-4" @click="openMarketRadiusModal" :color="inMarketColor" dark>
                <span>Market Radius: {{ marketRadius }} miles</span>
            </v-chip>
        </div>
        <div class="in-market-chart">
            <!-- <vc-donut
            v-if="sections.length"
            :key="marketScoreRefresh"
            auto-adjust-text-size
            has-legend
            :thickness="37"
            :size="100"
            unit="%"
            :sections="sections"
        >
        <span v-if="showPercent && marketScores[this.interval]">
                {{ marketScores[this.interval].inPercent }}%
            </span>
        </vc-donut> -->
            <div style="position: relative; width: 100%">
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="70"
                    color="primary"
                    style="position: absolute; margin: auto; left: 0; right: 0; top: 30px; bottom: 0"
                ></v-progress-circular>
                <DonutChart :key="chartRefreshKey" :chart-data="chartData" :chart-options="chartOptions" />

                <v-overlay v-model="overlay" absolute color="white"></v-overlay>
            </div>
            <div class="mt-6" v-if="marketScores[interval]">
                <div class="d-flex justify-center" style="color: black; gap: 24px" v-if="marketScores[interval]">
                    <h6 :style="inMarketBorder">
                        In Market {{ overlay ? 0 : formatNumber(marketScores[interval].inViews) }}
                    </h6>
                    <h6 :style="outMarketBorder">
                        Out Of Market {{ overlay ? 0 : formatNumber(marketScores[interval].outViews) }}
                    </h6>
                </div>
                <div class="mt-6" v-if="marketScores[interval].totalViews > 200000">Showing last 200,000 records</div>
            </div>
        </div>
        <div class="text-right" style="width: 100%">
            <span>
                <font-awesome-icon class="mr-1" icon="fa-regular fa-circle-info"></font-awesome-icon>
                <a href="http://help.memoryshare.com/en/articles/9503969-in-market-views" target="_blank">
                    What are In Market Views?</a
                >
            </span>
        </div>

        <v-dialog v-model="marketRadiusModal" max-width="500px">
            <v-card>
                <v-card-title>Market Radius In Miles</v-card-title>
                <v-card-text>
                    <v-slider
                        class="mt-10"
                        min="5"
                        max="300"
                        v-model="tempMarketRadius"
                        thumb-label="always"
                    ></v-slider>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelMarketRadiusModal">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="updateMarketRadius(funeralHomeId, tempMarketRadius)"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import DonutChart from '@/components/charts/DonutChart.vue';
import { mapActions } from 'vuex';

export default {
    components: { DonutChart },
    data() {
        return {
            token: '',
            overlay: true,
            loading: false,
            sections: [],
            marketScores: {},
            marketScoreRefresh: 0,
            marketRadius: 0,
            tempMarketRadius: 0,
            chartRefreshKey: 0,
            chartData: {
                labels: ['In Market', 'Out of Market'],
                datasets: [
                    {
                        data: [0, 0],
                        backgroundColor: [this.inMarketColor, this.outMarketColor],
                        hoverBackgroundColor: [this.inMarketColor, this.outMarketColor],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            marketRadiusModal: false,
        };
    },
    props: {
        funeralHomeId: {
            type: Number,
            default: 0,
        },
        serviceId: {
            type: Number,
            default: 0,
        },
        interval: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
        inMarketColor: {
            type: String,
            default: '#0c3c60',
        },
        outMarketColor: {
            type: String,
            default: '#d8d8d8',
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        updateMarketRadius(funeralHomeId, radius) {
            if (!funeralHomeId || funeralHomeId <= 0) {
                this.showSnackbar({ message: 'Invalid funeral home id', color: 'error' });
                return;
            }

            if (radius < 0) {
                this.showSnackbar({ message: 'Invalid market radius' });
                return;
            }

            this.axiosInstance
                .post(`/funeralhomes/update-market-radius/${funeralHomeId}/${radius}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Market radius updated' });

                    this.marketRadius = radius;
                    this.fetchInMarketScore(this.interval);
                    this.marketRadiusModal = false;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating market radius', color: 'error' });
                });
        },
        cancelMarketRadiusModal() {
            this.tempMarketRadius = this.marketRadius;
            this.marketRadiusModal = false;
        },
        openMarketRadiusModal() {
            this.tempMarketRadius = this.marketRadius;
            this.marketRadiusModal = true;
        },
        formatNumber(number) {
            return number.toLocaleString();
        },
        fetchInMarketScore(interval) {
            if (this.serviceId == 0 && this.funeralHomeId == 0) {
                return;
            }

            this.loading = true;

            const params = {
                serviceId: this.serviceId || null,
                funeralHomeId: this.funeralHomeId || null,
                startDate: this.startDate,
                endDate: this.endDate,
            };

            this.axiosInstance
                .get('/dogs/in-market-score', { params })
                .then(({ data }) => {
                    // const inPercent =
                    //     data.totalViews > 0 ? Math.floor((data.inMarketViews / data.totalViews) * 100) : 0;

                    const marketViews = {
                        totalViews: data.totalViews,
                        inViews: data.inMarketViews,
                        outViews: data.outOfMarketViews,
                        // inPercent: inPercent,
                        // outPercent: 100 - inPercent,
                    };

                    this.marketRadius = data.marketRadius;

                    this.marketScores[this.interval] = marketViews;
                    this.updateSections();
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateSections() {
            const marketViews = this.marketScores[this.interval];

            if (marketViews.totalViews == 0) {
                this.chartData = {
                    labels: ['In Market', 'Out Of Market'],
                    datasets: [
                        {
                            data: [1, 0],
                            backgroundColor: [this.inMarketColor, this.outMarketColor],
                            hoverBackgroundColor: [this.inMarketColor, this.outMarketColor],
                        },
                    ],
                };

                this.overlay = true;
            } else {
                this.chartData = {
                    labels: ['In Market', 'Out Of Market'],
                    datasets: [
                        {
                            data: [marketViews.inViews, marketViews.outViews],
                            backgroundColor: [this.inMarketColor, this.outMarketColor],
                            hoverBackgroundColor: [this.inMarketColor, this.outMarketColor],
                        },
                    ],
                };
                this.overlay = false;
            }

            this.chartRefreshKey++;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    watch: {
        interval(newVal, oldVal) {
            if (this.marketScores[this.interval]) {
                this.updateSections();
            } else {
                this.fetchInMarketScore(this.interval);
            }
        },
        startDate() {
            if (this.interval != 'custom' && this.marketScores[this.interval]) {
                this.updateSections();
            } else {
                this.fetchInMarketScore(this.interval);
            }
        },
        endDate() {
            if (this.interval != 'custom' && this.marketScores[this.interval]) {
                this.updateSections();
            } else {
                this.fetchInMarketScore(this.interval);
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.fetchInMarketScore(this.interval);
    },
    computed: {
        inMarketBorder() {
            return {
                borderBottom: `1px solid ${this.inMarketColor}`,
            };
        },
        outMarketBorder() {
            return {
                borderBottom: `1px solid ${this.outMarketColor}`,
            };
        },
    },
};
</script>
<style lang="scss">
.in-market-chart {
    #donut-chart {
        height: 300px;
    }
    max-width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._v("Line Items")]),_c('v-data-table',{attrs:{"items":_vm.lineItems,"headers":_vm.headers,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.supplierId",fn:function(ref){
        var header = ref.header;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{staticClass:"p-3",staticStyle:{"width":"300px"}},[_c('v-select',{attrs:{"items":_vm.suppliers,"return-object":"","item-value":"id","item-text":"name"},on:{"input":_vm.updateSelectedSupplier},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.id))])])]}}],null,true),model:{value:(_vm.supplierId),callback:function ($$v) {_vm.supplierId=$$v},expression:"supplierId"}}),(_vm.supplierId > 0)?_c('v-btn',{attrs:{"small":""},on:{"click":_vm.clearSupplierId}},[_vm._v("Clear")]):_vm._e()],1)],1)]}},{key:"item.unitAmount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.unitAmount))+" ")]}},{key:"item.totalAmount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.totalAmount))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
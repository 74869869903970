<template>
    <div>
        <div class="p-3">
            <!-- <div class="click-tab">here</div> -->
            <div class="text-center mb-3">
                <v-chip color="#0c3c60" dark label style="width: 100%; display: flex; justify-content: center">
                    <font-awesome-icon icon="fa-solid fa-computer-classic" style="position: absolute; left: 12" />
                    <span> VM Master List </span>
                </v-chip>
            </div>

            <div class="list-wrapper d-flex flex-column" style="gap: 10px">
                <div
                    v-for="(vm, index) in vms"
                    :key="vm.id"
                    class="vm-item"
                    :class="handleDropClasses(vm)"
                    draggable
                    @dragstart.self="pickupElem($event, vm, index)"
                    @dragover.prevent="showDropPlace($event, vm, index)"
                    @dragenter.prevent
                    @drop="moveElem($event, vm, index)"
                    @dragend.prevent="dragEndClear()"
                >
                    <div
                        class="list__elem d-flex align-center"
                        :class="{
                            'list__elem--is-dragged': dragedElem && vm.id === dragedElem.id,
                        }"
                    >
                        <v-avatar class="white--text" :color="vm.autoAssignDisabled ? '#9E9E9E' : '#0c3c60'" size="25">
                            {{ vm.order }}
                        </v-avatar>

                        <span>
                            <v-switch
                                class="m-0 ml-4"
                                :input-value="!vm.autoAssignDisabled"
                                @change="newVal => toggleAutoAssign(newVal, vm)"
                                inset
                                dense
                                hide-details=""
                            ></v-switch>
                        </span>

                        <div class="d-flex align-center">
                            <span
                                @click="editVm(vm)"
                                class="ml-2 name"
                                :class="vm.autoAssignDisabled ? 'grey--text' : ''"
                            >
                                {{ vm.name }}
                            </span>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="d-flex align-center justify-center ml-4" style="gap: 12px">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <div
                                        @click="!vm.autoAssignDisabled && toggleVmSupport(vm, 'supportsStandard')"
                                        class="p-1"
                                        :class="!vm.autoAssignDisabled ? 'vm-supported-engine-btn pointer' : ''"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-file-video"
                                            style="font-size: 1.5rem"
                                            :style="getIconStyle(!vm.autoAssignDisabled, vm.supportsStandard)"
                                        />
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <span>Toggle Standard Engine Support</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <div
                                        @click="!vm.autoAssignDisabled && toggleVmSupport(vm, 'supportsAudio')"
                                        class="p-1"
                                        :class="!vm.autoAssignDisabled ? 'vm-supported-engine-btn pointer' : ''"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-file-music"
                                            style="font-size: 1.5rem"
                                            :style="getIconStyle(!vm.autoAssignDisabled, vm.supportsAudio)"
                                        />
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <span>Toggle Audio Engine Support</span>
                                </template>
                            </custom-tooltip>
                        </div>

                        <span
                            ><font-awesome-icon
                                v-if="!dragedElem"
                                icon="fa-regular fa-trash-can"
                                style="font-size: 1rem"
                                @click.stop="openDeleteModal(vm)"
                                class="trash ml-4"
                        /></span>
                    </div>
                </div>
                <v-btn small dark style="width: 100%" class="mt-3" @click="newVm"
                    >Add <font-awesome-icon class="ml-2" icon="fa-solid fa-plus"
                /></v-btn>
            </div>
        </div>
        <v-dialog @click:outside="resetModal" max-width="400px" v-model="vmModal">
            <v-card class="p-3">
                <div style="display: flex">
                    <v-text-field
                        autofocus
                        label="Name"
                        v-model="selectedVm.name"
                        @keydown.enter="handleSubmit"
                    ></v-text-field>

                    <v-text-field
                        style="max-width: 80px"
                        label="Order Position"
                        v-model="selectedVm.order"
                        :min="1"
                        type="number"
                        @keydown.enter="handleSubmit"
                    ></v-text-field>
                </div>

                <div class="responsive-flex btn-group">
                    <v-btn v-if="editing" @click="submitVmEdit(selectedVm.id)" color="primary" :disabled="disableSubmit"
                        >Submit</v-btn
                    >
                    <v-btn v-else @click="addVm" color="primary" :disabled="disableSubmit">Submit</v-btn>
                    <v-btn class="cncl-btn" @click="resetModal">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteModal" max-width="400px" @click:outside="resetModal">
            <v-card class="p-3">
                <h5 class="text-center pt-3 pb-3">
                    Delete <strong>{{ selectedVm.name }}</strong
                    >?
                </h5>
                <div style="display: flex; flex-direction: column">
                    <v-btn @click="deleteVm(selectedVm.id)" color="error">Delete</v-btn>
                    <v-btn @click="resetModal" class="mt-3">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
export default {
    data() {
        return {
            vms: [],
            token: '',
            vmModal: false,
            deleteModal: false,
            editing: false,
            selectedVm: {
                name: '',
                order: 1,
                id: '',
            },
            dragedElem: null,
            overElem: null,
        };
    },
    components: { CustomTooltip },
    computed: {
        handleDropClasses() {
            return item => {
                if (!this.overElem || !this.overElem.id) {
                    return '';
                }
                if (this.overElem.id === item.id && item.order < this.dragedElem.order) {
                    return 'drop-place drop-place--before';
                }
                if (this.overElem.id === item.id && item.order > this.dragedElem.order) {
                    return 'drop-place drop-place--after';
                }
            };
        },
        disableSubmit() {
            if (this.selectedVm.name.length === 0 || !this.selectedVm.order > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getIconStyle(active, engineSupport) {
            if (!active) {
                return { color: '#d8d8d8' };
            }
            return engineSupport ? { color: '#1877f2' } : { color: '#d8d8d8' };
        },
        toggleVmSupport(vm, support) {
            this.selectedVm = vm;
            this.selectedVm[support] = !this.selectedVm[support];
            this.submitVmEdit(this.selectedVm.id);
        },
        toggleAutoAssign(newVal, vm) {
            this.selectedVm = vm;
            this.selectedVm.autoAssignDisabled = !newVal;
            this.submitVmEdit(this.selectedVm.id);
        },
        // Drag and drop logic
        // https://codesandbox.io/s/nnvxromq94?file=/src/components/DragAndDrop.vue:658-888
        dragEndClear() {
            this.dragedElem = null;
            this.overElem = null;
        },
        pickupElem(event, elem, index) {
            event.dataTransfer.dropEffect = 'move';
            this.dragedElem = { ...elem };
            this.selectedVm = { ...elem };
        },
        showDropPlace(event, elem, index) {
            if (elem.id !== this.dragedElem.id) {
                this.overElem = { ...elem };
            } else {
                this.overElem = null;
            }
        },
        moveElem(event, elem, index) {
            this.vms = this.vms.filter(item => item.id !== this.dragedElem.id);
            this.vms.splice(index, 0, { ...this.dragedElem });
            // this.vms.forEach((item, index) => (item.order = index + 1));

            if (this.selectedVm.order != index + 1) {
                this.selectedVm.order = index + 1;
                this.submitVmEdit(this.selectedVm.id);
            }
        },
        handleSubmit() {
            if (this.disableSubmit) {
                return;
            } else {
                if (this.editing) {
                    this.submitVmEdit(this.selectedVm.id);
                } else {
                    this.addVm();
                }
            }
        },
        newVm() {
            this.vmModal = true;
            this.selectedVm.order = this.vms.length + 1;
        },
        deleteVm(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/render/vm/${id}`)
                .then(response => {
                    this.getVms();
                    this.resetModal();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        openDeleteModal(vm) {
            this.selectedVm = vm;
            this.deleteModal = true;
        },
        submitVmEdit(id) {
            if (!this.selectedVm.name) {
                this.showSnackbar({ message: 'Please enter a name to add VM' });
                return;
            } else if (!this.selectedVm.order > 0) {
                this.showSnackbar({ message: 'Please enter a preferred order of 1 or greater for VM' });
                return;
            }

            const data = {
                name: this.selectedVm.name,
                order: parseInt(this.selectedVm.order),
                autoAssignDisabled: this.selectedVm.autoAssignDisabled,
                supportsAudio: this.selectedVm.supportsAudio,
                supportsStandard: this.selectedVm.supportsStandard,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/render/edit-vm/${id}`, data)
                .then(response => {
                    this.getVms();
                    this.resetModal();
                    this.showSnackbar({ message: 'VM list updated' });
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        editVm(vm) {
            this.editing = true;
            this.vmModal = true;
            this.selectedVm = {
                id: vm.id,
                name: vm.name,
                order: vm.order,
                autoAssignDisabled: vm.autoAssignDisabled,
            };
        },
        resetModal() {
            this.vmModal = false;
            this.deleteModal = false;
            this.editing = false;
            this.selectedVm = {
                name: '',
                // order: 1,
            };
        },
        addVm() {
            if (!this.selectedVm.name) {
                this.showSnackbar({ message: 'Please enter a name to add VM' });
                return;
            } else if (!this.selectedVm.order > 0) {
                this.showSnackbar({ message: 'Please enter a preferred order of 1 or greater for VM' });
                return;
            }

            const data = {
                name: this.selectedVm.name,
                order: parseInt(this.selectedVm.order),
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/render/vm', data)
                .then(response => {
                    this.vms = response.data;
                    this.getVms();
                    this.resetModal();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getVms() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(process.env.VUE_APP_API + '/render/vm-list')
                .then(response => {
                    this.vms = response.data;

                    this.$emit('refresh-vm-list', this.vms);
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
    },
    mounted() {
        if (this.$auth.role.includes('SuperAdmin')) {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.getVms();
            });
        }
    },
};
</script>

<style scoped>
.vm-supported-engine-btn {
    border-radius: 4px;
}
.vm-supported-engine-btn:hover {
    background-color: rgb(191, 191, 191);
}
.click-tab {
    border: 2px solid red;
    position: relative;
}
.name:hover {
    cursor: pointer;
}
.btn-group {
    justify-content: space-between;
    flex-direction: row-reverse;
}
.responsive-flex {
    display: flex;
}

.trash {
    cursor: pointer;
    transition: 0.2s;
    color: white;
}
.vm-item:hover .trash:hover {
    color: #ff5252;
    transition: 0.2s;
}
/* .list-wrapper {
    max-width: 300px;
} */
.vm-item {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    transition: 0.3s;
}
.vm-item:hover {
    background-color: #eeeeee;
    transition: 0.3s;
}

.vm-item:hover .trash {
    color: black;
    transition: 0.3s;
}

@media only screen and (max-width: 768px) {
    .responsive-flex {
        flex-direction: column;
    }
    #vm-order {
        max-width: 100%;
    }
    .cncl-btn {
        margin-top: 10px;
    }
}

/* DRAG AND DROP STYLES */

.list__elem {
    cursor: row-resize;
    /* cursor: grab; */
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.3s;
    display: flex;
}
.list__elem--is-dragged {
    opacity: 0.7;
    border: 1px dashed skyblue;
    width: 100%;
}
.drop-place {
    position: relative;
    transition: all 0.3s;
}
.drop-place--before {
    padding-top: 40px;
}
.drop-place--before:hover {
    background-color: white;
}
.drop-place--after {
    padding-bottom: 40px;
}
.drop-place--before:before {
    position: absolute;
    top: 5px;
    left: 0;
    text-align: center;
    content: 'Drop here';
    width: 100%;
    padding: 5px 0;
    opacity: 0.7;
    border: 1px dashed skyblue;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px 2px skyblue;
}
.drop-place--after:after {
    position: absolute;
    bottom: 5px;
    left: 0;
    text-align: center;
    content: 'Drop here';
    width: 100%;
    padding: 5px 0;
    opacity: 0.7;
    border: 1px dashed skyblue;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px 2px skyblue;
}
.btn-drag {
    float: right;
    background: none;
    border: none;
}
</style>

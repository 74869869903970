<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-6">
            <div class="header">
                <h4>Shop Settings</h4>
            </div>
            <v-progress-circular v-if="!token" indeterminate color="primary"></v-progress-circular>
            <div v-else>
                <v-tabs v-model="tab" color="#0d5259">
                    <v-tab href="#orders">Orders</v-tab>
                    <v-tab href="#products">Products</v-tab>
                </v-tabs>

                <v-tabs-items :value="tab">
                    <v-tab-item value="orders">
                        <OrderTable />
                    </v-tab-item>
                    <v-tab-item value="products">
                        <ProductList :funeralHomeId="Number(this.$auth.funeralHomeId)" />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </AppLayout>
</template>
<script>
import OrderTable from '@/components/Tables/OrderTable.vue';
import ProductList from '@/components/Shop/ProductList.vue';

export default {
    metaInfo: {
        title: 'Shop Settings',
    },
    data() {
        return {
            tab: null,
            token: '',
        };
    },
    components: { OrderTable, ProductList },
    methods: {
        getToken() {
            return this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
            });
        },
    },
    async created() {
        await this.getToken();
        this.tab = this.$route.query.tab || 'orders';
    },
};
</script>
<style lang=""></style>

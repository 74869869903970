<template>
    <div :class="['event-action-container', event.hidden ? 'hidden-event-action-container' : '']">
        <div v-if="event.hidden" class="text-left">
            <v-chip label outlined color="#fb8c00" dark>Hidden</v-chip>
        </div>
        <nav class="event-action-tab-container">
            <li class="menu-entry" :class="nonLiveIndex == 0 && 'active'" @click="nonLiveIndex = 0">Recording</li>
            <li class="menu-entry" :class="nonLiveIndex == 1 && 'active'" @click="nonLiveIndex = 1">Health</li>
            <li class="menu-entry" :class="nonLiveIndex == 2 && 'active'" @click="nonLiveIndex = 2">Upload</li>
        </nav>

        <div class="event-action-tab-content">
            <!-- START: Default Tab -->

            <div v-if="$auth.role.includes('SuperAdmin') && video.hlsStatus" class="d-flex justify-end mx-6">
                <div class="d-flex align-end">
                    <div class="mr-2">
                        <v-img :src="bunnyLogo" :lazy-src="bunnyLogo" width="100px"></v-img>
                    </div>
                    <v-btn
                        @click="openBunnyDeepLink"
                        small
                        depressed
                        class="cursor-pointer"
                        dark
                        :color="hlsStatuses[video.hlsStatus].color"
                    >
                        <span>
                            {{ hlsStatuses[video.hlsStatus].label }}
                        </span>
                    </v-btn>
                </div>
            </div>

            <section v-if="nonLiveIndex === 0" class="default-button-container">
                <event-viewer
                    class="p-4"
                    v-if="videoSrc"
                    :src="videoSrc"
                    :poster="computedPoster"
                    :event="event"
                    :key="refreshKey"
                    :archived="video.storageType === 3"
                    @error="handleVideoError"
                />

                <div v-if="video.storageType !== 3" class="action-button-container">
                    <v-btn
                        color="green"
                        dark
                        @click="$router.push({ path: `/services/upload/${event.id}?uploaderType=edit` })"
                    >
                        <font-awesome-icon class="mr-2" icon="fa-solid fa-scissors"></font-awesome-icon>
                        <span>Edit</span>
                    </v-btn>

                    <v-btn v-if="showDownloadButton" color="#0C3C60" dark @click="handleVideoDownload">
                        <font-awesome-icon
                            icon="fa-regular fa-arrow-down-to-bracket"
                            style="font-size: 1rem"
                            class="mr-2"
                        />
                        <span>Download</span>
                    </v-btn>
                    <!-- <v-btn color="#0C3C60" dark @click="handleDVDOrder">Order DVD</v-btn>
                    <v-btn color="#0C3C60" dark @click="handleFamilyDVD">Family DVD</v-btn> -->
                </div>
            </section>
            <!-- END: Default Tab -->

            <!-- START: Health Tab -->
            <section v-if="nonLiveIndex === 1">
                <wowza-event-chart
                    v-if="cloudStreamingProvider == 0"
                    :live="event.eventStatus == 2"
                    ref="wowzaChart"
                    :event="event"
                />
                <div class="text-center" v-if="cloudStreamingProvider != 0">Health Chart Not Available</div>
            </section>
            <!-- END: Health Tab -->

            <section v-if="nonLiveIndex === 2" class="">
                <div class="end-stream-container">
                    <h3>Re-upload the recording.</h3>
                    <v-btn @click="handleButtonClick" class="mb-2" color="secondary"> Add Video Files </v-btn>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import WowzaEventChart from '@/components/charts/WowzaEventChart.vue';
import EventViewer from '@/components/videojs/EventViewer.vue';
import { hlsStatuses } from '@/constants';
import bunnyLogo from '@/assets/images/bunny_logo.png';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            bunnyLogo,
            hlsStatuses,
            nonLiveIndex: 0,
            wowzaChart: '',
            cloudStreamingProvider: null,
            blankPosterUrl: process.env.VUE_APP_AZURE_BASE_DOMAIN + '/front-end-assets/video_poster_placeholder.png',
            video: {},
            refreshKey: 0,
            videoSrc: '',
            showDownloadButton: false,
        };
    },
    computed: {
        computedPoster() {
            if (this.event.videoThumbnail && this.service.displayThumbnail) {
                return this.event.videoThumbnail;
            }
            return this.blankPosterUrl;
        },
        // computedSrc() {
        //     return this.video.hlsUrl && this.video.hlsStatus == 4
        //         ? this.video.hlsUrl
        //         : this.event.convertedVideo || this.event.liveURL;
        // },
    },
    watch: {
        // computedSrc(newVal) {
        //     this.refreshKey++;
        // },
        videoSrc(newVal) {
            this.refreshKey++;
        },
    },
    methods: {
        openBunnyDeepLink() {
            if (!this.video.hlsGuid) {
                this.showSnackbar({ message: 'Invalid hls guid', color: 'error' });
                return;
            }
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized Access', color: 'error' });
                return;
            }

            const deeplink = `https://dash.bunny.net/stream/${process.env.VUE_APP_BUNNY_STREAM_LIB_ID}/library/videos?search=${this.video.hlsGuid}&videoId=${this.video.hlsGuid}`;

            window.open(deeplink, '_blank');
        },
        handleVideoError() {
            if (this.videoSrc == this.video.hlsUrl) {
                this.videoSrc = this.event.convertedVideo || this.event.liveURL;
                this.refreshKey++;
            }
        },
        getVideo(eventId) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/videos/events/${eventId}`)
                    .then(response => {
                        if (response.data) {
                            this.video = response.data;

                            if (this.video.hlsUrl != null && this.video.hlsStatus == 4) {
                                this.videoSrc = this.video.hlsUrl;
                            } else {
                                this.videoSrc = this.event.convertedVideo || this.event.liveURL;
                            }
                        }
                    })
                    .catch(error => {});
            });
        },
        getFhInfo(id) {
            if (id == null) return;

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/funeralHomes/${id}`)
                    .then(response => {
                        this.funeralHome = response.data.funeralHome;
                        const subStatus = this.funeralHome.subscriptionStatus;

                        const invalidDownloadStatuses = [1, 5, 6];

                        if (!invalidDownloadStatuses.includes(subStatus)) {
                            this.showDownloadButton = true;
                        }
                    })
                    .catch(error => {});
            });
        },
        getStreamInfo(id) {
            if (id == null) return;

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/live/${id}`)
                    .then(response => {
                        this.cloudStreamingProvider = response.data.cloudStreamingProvider;
                    })
                    .catch(error => {});
            });
        },
        handleButtonClick: function () {
            this.$router.push({ path: `/services/upload/${this.event.id}` });
        },
        endLiveStream() {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/live/stop/${this.event.liveStreamId}`)
                    .then(response => {
                        this.$emit('refresh');
                    })
                    .catch(error => {});
            });
        },
        handleVideoDownload: function () {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/Events/download/${this.event.id}`, { responseType: 'blob' })
                    .then(response => {
                        const url = response.data.sas;
                        const link = document.createElement('a');
                        link.href = url;

                        // this is necessary as link.click() does not work on the latest firefox
                        link.dispatchEvent(
                            new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window,
                            }),
                        );
                    })
                    .catch(error => {});
            });
        },

        encodeQueryData: function (data) {
            const ret = [];
            for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            return ret.join('&');
        },
        handleDVDOrder: function () {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/FuneralHomes/${this.event.funeralHomeId}`)
                    .then(response => {
                        const baseURL = 'https://funeralwebcasting.typeform.com/to/DgHrPi?';
                        const funeralhome = response.data.funeralHome;

                        const params = {
                            address: funeralhome.address1,
                            funeralhome: funeralhome.name,
                            serviceid: this.event.serviceId,
                            funeralhomeid: this.event.funeralHomeId,
                            deceasedname: this.service.firstName + ' ' + this.service.lastName,
                            servicedate: this.event.time,
                            NewPortal: true,
                        };

                        const finalURL = baseURL + this.encodeQueryData(params);
                        window.open(finalURL, '_blank');
                    })
                    .catch(error => {});
            });
        },
        handleFamilyDVD: function () {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/FuneralHomes/${this.event.funeralHomeId}`)
                    .then(response => {
                        const baseURL = 'https://funeralwebcasting.typeform.com/to/q3mWj2?';
                        const funeralhome = response.data.funeralHome;

                        const params = {
                            address: funeralhome.address1,
                            funeralhome: funeralhome.name,
                            serviceid: this.event.serviceId,
                            funeralhomeid: this.event.funeralHomeId,
                            deceasedname: this.service.firstName + ' ' + this.service.lastName,
                            servicedate: this.event.time,
                            NewPortal: true,
                        };

                        const finalURL = baseURL + this.encodeQueryData(params);
                        this.copyToClipboard(finalURL);
                    })
                    .catch(error => {});
            });
        },
        copyToClipboard(text) {
            const el = document.createElement('textarea');
            el.value = text.replace('{', '').replace('}', '');
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.$swal.fire({
                icon: 'info',
                title: 'Link copied to clipboard. Share this link with the family, and they can purchase a DVD directly from MemoryShare',
            });
        },
    },
    components: {
        WowzaEventChart,
        EventViewer,
    },
    mounted() {
        this.getStreamInfo(this.event.liveStreamId);

        this.getVideo(this.event.id);
        this.getFhInfo(this.event.funeralHomeId);
    },
};
</script>

<style lang="scss" scoped>
.event-action-tab-container {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    overflow-x: auto;

    .menu-entry {
        list-style: none;
        padding: 0.5rem 1rem;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .menu-entry.active {
        border-bottom: 4px solid rgb(78, 184, 226);
    }
}

.default-button-container {
    text-align: center;
}
.end-stream-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

.action-button-container {
    // width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media only screen and (max-width: 768px) {
    .event-action-tab-container {
        flex-direction: column;
    }
    .action-button-container {
        width: 100%;
        flex-direction: column;
        button {
            width: 100%;
        }
    }
}
</style>

<template>
    <div>
        <div class="d-flex justify-space-between my-3 flex-wrap">
            <div class="d-flex align-center">
                <v-btn small class="mr-2" depressed @click="$emit('back')">
                    <font-awesome-icon
                        style="font-size: 1rem"
                        class="pointer mr-2"
                        icon="fa-solid fa-arrow-left"
                    ></font-awesome-icon>
                    <span>Select Music</span>
                </v-btn>
            </div>

            <div :class="playlistDuration == 0 ? 'playlist-warning' : ''" class="text-right subtle-text">
                <custom-tooltip :tooltipProps="{ bottom: true, maxWidth: '240px' }">
                    <template v-slot:activator>
                        <span class="help">
                            <span
                                >{{ Math.round(playlistDuration / 60) }} /
                                {{ Math.round(recommendedDuration / 60) }} minutes selected</span
                            >

                            <font-awesome-icon class="ml-2" icon="fa-solid fa-circle-question"></font-awesome-icon>
                        </span>
                    </template>

                    <template v-slot:content>
                        <span
                            >We recommend {{ Math.round(recommendedDuration / 60) }} minutes of music to match your
                            {{ tributeVideo.totalPhotos + 1 }} photos</span
                        >
                    </template>
                </custom-tooltip>
            </div>
        </div>
        <v-window style="overflow: visible" v-model="musicWindow">
            <v-window-item key="0">
                <v-row>
                    <v-col>
                        <div>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :loading="loading"
                                        background-color="#f8f8f8"
                                        solo
                                        flat
                                        v-model="search"
                                        label="Search Music"
                                    >
                                        <template v-slot:append>
                                            <font-awesome-icon
                                                icon="fa-regular fa-magnifying-glass"
                                            ></font-awesome-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col xl="6" cols="12">
                                    <v-row>
                                        <v-col lg="6" cols="12">
                                            <v-select
                                                ref="genreSelect"
                                                max-width="200px"
                                                background-color="#f8f8f8"
                                                solo
                                                flat
                                                label="Genres"
                                                :items="genres"
                                                item-text="name"
                                                item-value="id"
                                                @blur="handleGenreBlur"
                                                multiple
                                                v-model="selectedGenres"
                                            ></v-select>
                                        </v-col>
                                        <v-col lg="6" cols="12">
                                            <v-select
                                                ref="keywordSelect"
                                                max-width="200px"
                                                background-color="#f8f8f8"
                                                solo
                                                flat
                                                label="Keywords"
                                                :items="keywords"
                                                item-text="name"
                                                item-value="id"
                                                @blur="handleKeywordBlur"
                                                multiple
                                                v-model="selectedKeywords"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <tribute-music-table
                                :funeralHomeId="funeralHomeId"
                                :search="search"
                                :featuredOnly="featuredOnly"
                                :filteredGenres="filteredGenres"
                                :token="token"
                                :multiPlaylist="multiPlaylist"
                                @refresh-sync="$emit('refresh-sync')"
                                ref="musicTable"
                                class="service-music-table"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item key="1">
                <v-row>
                    <v-col cols="12">
                        <v-btn depressed small color="#ff530d" dark @click="musicWindow--">
                            <font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />
                            Select music
                        </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <div style="display: flex; justify-content: space-between">
                            <h5>Selected Playlist</h5>
                            <h5 v-if="selectedSongs.length > 0">{{ playlistDuration | durationTimeStamp }}</h5>
                        </div>
                        <div v-if="selectedSongs.length > 0">
                            <draggable v-model="selectedSongs" @change="handleChange($event)" animation="300">
                                <div v-for="(song, index) in selectedSongs" :key="song.id">
                                    <div class="m-1 p-1 playlist-item">
                                        <div>
                                            <span class="mr-2">{{ index + 1 }}</span>
                                            <span> {{ song.title }}</span>
                                        </div>
                                        <div>
                                            <span style="color: gray" class="mr-4">{{
                                                song.duration | durationTimeStamp
                                            }}</span>
                                            <font-awesome-icon
                                                @click="removeFromPlaylist(index, 'music')"
                                                class="xmark"
                                                style="font-size: 1rem"
                                                icon="fa-regular fa-circle-x"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div style="border: 1px solid #d3d3d3; border-radius: 5px" v-else>
                            <p class="text-center pt-4">No songs selected.</p>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <h5>Selected Templates</h5>
                        <div v-if="selectedTemplates.length > 0">
                            <draggable v-model="selectedTemplates" animation="300">
                                <div v-for="(template, index) in selectedTemplates" :key="template.id">
                                    <div class="m-1 p-1 playlist-item">
                                        <div>
                                            <span class="mr-2">{{ index + 1 }}</span>
                                            <span>{{ template.title }}</span>
                                        </div>
                                        <font-awesome-icon
                                            @click="removeFromPlaylist(index, 'template')"
                                            class="xmark"
                                            style="font-size: 1rem"
                                            icon="fa-regular fa-circle-x"
                                        />
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div style="border: 1px solid #d3d3d3; border-radius: 5px" v-else>
                            <p class="text-center pt-4">No templates selected.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import TributeMusicTable from '@/components/Tables/Admin/TributeMusicTable.vue';
import TributePlaylistExpansion from '@/components/ManageService/Tribute/TributePlaylistExpansion.vue';
import TributeMusicUploader from '@/components/ManageService/Tribute/TributeMusicUploader.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            message: 'hi',
            search: '',
            musicWindow: 0,
            loading: false,
            keywords: [],
            selectedKeywords: [],
            genres: [],
            selectedGenres: [],
            transitionDuration: 1,
            templateDuration: 0,
        };
    },
    components: {
        draggable,
        TributeMusicTable,
        TributePlaylistExpansion,
        TributeMusicUploader,
        CustomTooltip,
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        selectedTemplates: {
            get() {
                return this.$store.state.tributeVideo.selectedTemplates;
            },
            set(value) {
                this.updateTributeVideoSelectedTemplates(value);
            },
        },
        playlistDuration() {
            let duration = 0;
            this.selectedSongs.forEach(song => {
                duration += song.duration;
            });
            return duration;
        },
        endingDuration() {
            let duration = 10;

            if (this.$store.state.tributeVideo.endingType == 2) {
                duration = this.$store.state.tributeVideo.endingDuration;
            }

            return duration;
        },
        recommendedDuration() {
            return this.templateDuration + this.slideshowDuration + this.endingDuration;
        },
        slideshowDuration() {
            // return (
            //     (this.tributeVideo.totalPhotos + 1) * this.tributeVideo.slideDuration - this.tributeVideo.totalPhotos
            // );
            const videoCount = this.$store.state.tributeVideo.videoSlidesCount;

            // current transition time is 1s, account for 1s overlap per slide during duration calc
            const totalSlides = this.$store.state.tributeVideo.totalPhotos - videoCount;

            let fullPhoto = totalSlides * this.$store.state.tributeVideo.slideDuration;
            let adjustedPhoto = fullPhoto - totalSlides * this.transitionDuration;

            let fullVideo = this.$store.state.tributeVideo.videoSlidesDuration;
            let adjustedVideo = fullVideo - videoCount * this.transitionDuration;

            let total = adjustedPhoto + adjustedVideo;
            return parseFloat(total.toFixed(2));
        },
        remainingSongs() {
            const averageSongLength = 210;
            const remainingTime = this.recommendedDuration - this.playlistDuration;
            return Math.round(remainingTime / averageSongLength);
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    props: {
        filteredGenres: {
            type: Array,
            required: false,
        },
        featuredOnly: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            required: true,
        },
        funeralHomeId: {
            type: Number,
            required: false,
        },
        multiPlaylist: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        filteredGenres() {
            this.selectedGenres = [...this.filteredGenres];
        },
    },
    methods: {
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
        ]),
        updateTemplateDuration() {
            let duration = 0;
            if (this.selectedTemplates.length > 0) {
                const temp = this.selectedTemplates[0];
                if (temp.duration > 0) {
                    duration = temp.duration;
                } else if (temp.url) {
                    this.getVideoFileDuration(template.url).then(resp => {
                        duration = resp;
                    });
                }
            }
            this.templateDuration = duration;
        },
        stopAllAudio() {
            if (this.$refs.musicTable) {
                this.$refs.musicTable.stopAllAudio();
            }
        },
        refreshSongs() {
            this.$refs.musicTable.getSongs();
        },
        handleKeywordBlur() {
            this.$refs.keywordSelect.blur();
            this.$refs.musicTable.selectedKeywords = this.selectedKeywords;
            this.$refs.musicTable.getSongs();
        },
        handleGenreBlur() {
            this.$refs.genreSelect.blur();
            this.$refs.musicTable.selectedGenres = this.selectedGenres;
            this.$refs.musicTable.getSongs();
        },
        getKeywords() {
            return this.axiosInstance
                .get('/MusicKeyword?pageSize=100')
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getGenres() {
            return this.axiosInstance
                .get('/MusicGenre?pageSize=100')
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        handleChange(event) {
            const selectedIds = this.selectedSongs.map(obj => obj.id);
            this.updateSelectedSongs(this.tributeVideo.id, selectedIds);
        },
        updateSelectedSongs(id, json) {
            this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, json)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        saveSelectedTemplateJson(id, json) {
            this.axiosInstance
                .post(`TributeVideo/selected-templates/${id}`, json)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        removeFromPlaylist(index, arrType) {
            if (arrType == 'music') {
                var cloneList = [...this.selectedSongs];
                cloneList.splice(index, 1);
                this.updateTributeVideoSelectedSongs(cloneList);
                this.updateSelectedSongs(this.tributeVideo.id, cloneList);
            } else if (arrType == 'template') {
                var cloneList = [...this.selectedTemplates];
                cloneList.splice(index, 1);
                this.updateTributeVideoSelectedTemplates(cloneList);
                this.saveSelectedTemplateJson(this.tributeVideo.id, cloneList);
            }
        },
        setSlideDuration() {
            let slideDuration = 0;
            if (this.tributeVideo.totalPhotos + 1 < 100) {
                slideDuration = 7;
            } else {
                slideDuration = 5;
            }

            this.updateTributeVideo({ slideDuration: slideDuration });
        },
    },
    async mounted() {
        this.createAxiosInstance();
        if (this.tributeVideo.slideDuration == 0) {
            this.setSlideDuration();
        }
        this.getKeywords();
        this.getGenres();
        this.updateTemplateDuration();
    },
};
</script>
<style lang="scss" scoped>
.help:hover {
    cursor: help;
}
video {
    max-height: 100%;
    max-width: 100%;
    display: block;
}
.videoPlayer {
    border-radius: 10px;
}
.video-title {
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid transparent;
    margin-top: 5px;
    transition: 0.3s;
}

.video-title:hover {
    background-color: #f2f2f2;
    transition: 0.3s;
}
.empty-video {
    background-color: black;
    border-radius: 10px;
}
.video-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.playlist-item {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: row-resize;
}
.playlist-item:hover .xmark {
    opacity: 1;
    transition: 0.3s;
}
.xmark {
    opacity: 0;
    transition: 0.3s;
    cursor: pointer;
}
.selected {
    background-color: #ecf3fb !important;
    border: 2px solid #1877f2 !important;
    transition: 0.3s;
}
.hovering {
    filter: brightness(50%);
    transition: 0.3s;
}
.img-wrapper {
    border-radius: 10px;
    margin: 2px;
    cursor: pointer;
    transition: 0.3s;
    .photo {
        border-radius: 10px;
    }
}
.selected-image-column {
    height: 279px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.photo {
    max-width: 100%;
    max-height: 100%;
}
.custom-green {
    color: #00c853 !important;
}
// .grid {
//     display: grid;
//     border: 2px solid green;
//     //grid-template-columns: repeat(auto-fit, minmax(300px, auto));
//     grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//     justify-content: center;
// }

.grid-item {
    transition: 0.5s;
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    video {
        border-radius: 5px 5px 0 0;
    }
}
.grid-card-lower {
    border-radius: 0 0 5px 5px;
    cursor: pointer;
}

.grid-item:hover {
    // border: 2px solid red;
    transform: scale(1.03);
    transition: 0.5s;
}

.no-demo-div {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 169px;
}
.template-selection {
    cursor: pointer;
}
.template-selection:hover {
    // background-color: #eee;
    background-color: green;
}

.subtle-text {
    font-size: 0.8rem;
    color: #999;
}

.playlist-warning {
    color: #ff5252;
}
</style>

<template>
    <div>
        <v-card class="p-3">
            <v-card-title class="mt-3">
                <font-awesome-icon icon="fa-regular fa-pallet-box" style="margin-right: 0.5rem" />
                <h3 class="m-0">Suppliers</h3>
                <v-spacer></v-spacer>

                <v-btn dark @click="createSupplier()"> + Add Supplier</v-btn>
            </v-card-title>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="5">
                    <v-text-field append-icon="mdi-magnify" v-model="search"></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                @click:row="supplierDetails"
                :items="suppliers"
                :headers="headers"
                :server-items-length="total"
                :options.sync="options"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
            >
                <template v-slot:item.createDate="{ item }">
                    {{ $moment.utc(item.createDate).local().format('l') }}
                </template>

                <template v-slot:item.actions="{ item }">
                    <div class="action-list">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        icon="fa-regular fa-user-group"
                                        @click.stop
                                        @click.prevent="getUsers(item)"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Users</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        icon="fa-regular fa-pencil"
                                        @click.stop
                                        @click.prevent="editSupplier(item)"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Edit</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        icon="fa-regular fa-trash-can"
                                        @click.stop
                                        @click.prevent="openDeleteModal(item)"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Delete</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="deleteModal" max-width="600px">
            <v-card class="p-3">
                <h5>
                    Delete <strong>{{ selectedSupplier.name }}</strong
                    >?
                </h5>
                <p>Enter supplier name to confirm</p>
                <v-text-field v-model="confirmationText"></v-text-field>
                <div style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelDelete">Cancel</v-btn>
                    <v-btn
                        :disabled="selectedSupplier.name !== confirmationText"
                        @click="deleteSupplier()"
                        color="error"
                        >Delete</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <users-table :userTableType="'supplier'" ref="usersTable" />
    </div>
</template>

<script>
import CreateEditSupplier from '@/views/Shop/CreateEditSupplier.vue';
import moment from 'moment';
import debounce from '@/utilities/debounce.js';
import { mapActions } from 'vuex';
import UsersTable from '@/components/Tables/UsersTable';
import { debounceV2 } from '@/utilities/debounce.js';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            options: {},
            token: null,
            loading: true,
            search: '',
            total: 0,
            loading: false,
            suppliers: [],
            selectedSupplier: {},
            confirmationText: '',
            deleteModal: false,
            headers: [
                { text: 'Id', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'primaryContactEmail' },
                // { text: 'Website', value: 'website' },
                { text: 'Created', value: 'createDate' },
                { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
            ],
        };
    },
    components: {
        CreateEditSupplier,
        UsersTable,
        CustomTooltip,
    },
    watch: {
        options: debounceV2(function () {
            if (!this.loading) this.getSuppliers();
        }, 500),
        search() {
            this.loading = true;
            this.options.page = 1;
            this.debouncedGetSuppliers();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getUsers(item) {
            this.$refs.usersTable.id = item.id;
            this.$refs.usersTable.openDialog = true;
        },
        supplierDetails(item) {
            this.$router.push({ path: `supplier-settings/${item.id}` });
        },
        editSupplier(item) {
            this.$router.push({ path: `suppliers/edit/${item.id}` });
        },
        createSupplier() {
            this.$router.push({ name: 'CreateSupplier' });
        },
        deleteSupplier() {
            if (this.confirmationText === this.selectedSupplier.name) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + `/Suppliers/${this.selectedSupplier.id}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Supplier successfully deleted' });
                        this.getSuppliers();
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error deleting supplier' });
                    });
                this.deleteModal = false;
                this.confirmationText = '';
            } else {
                this.showSnackbar({ message: 'Confirmation does not match supplier name' });
            }
        },
        cancelDelete() {
            this.deleteModal = false;
            this.selectedSupplier = {};
        },
        openDeleteModal(item) {
            this.selectedSupplier = item;
            this.deleteModal = true;
        },
        autofillForm() {
            this.loading = true;
            this.axios
                .get('https://random-word-api.herokuapp.com/word?number=1')
                .then(res => {
                    this.name = res.data[0] + randomNumber(1, 500).toString();
                    this.address1 = 'Address Line 1';
                    this.address2 = 'Address Line 2';
                    this.city = 'Test City';
                    this.state = 'Test State';
                    this.zipCode = 'Test Zip';
                    this.email = 'testemail@a9djwnaidn2.com';
                    this.primaryContact = 'Jeff';
                    this.primaryContactEmail = 'testemail@a9djwnaidn2.com';
                    this.primaryContactPhoneNumber = '1231231234';
                    this.supportNumber = '1231231234';
                    this.website = 'www.example.com';
                })

                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'id':
                    return 'Id';
                case 'name':
                    return 'Name';
                case 'primaryContanctEmail':
                    return 'PrimaryContactEmail';
                case 'createdDate':
                    return 'CreatedDate';
                default:
                    console.warn('No cases found in switch statement.');
                    return 'Id';
            }
        },
        getSuppliers() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.loading = true;
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/Suppliers?`, {
                            params: {
                                pageNumber: page ? page - 1 : 1,
                                pageSize: itemsPerPage,
                                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                                search: this.search,
                            },
                        })
                        .then(response => {
                            this.suppliers = response.data.suppliers;
                            this.total = response.data.total;
                            this.loading = false;
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.loading = false;
                        });
                });
            }
        },
    },
    created() {
        this.debouncedGetSuppliers = debounce(this.getSuppliers, 500);
    },
    mounted() {
        this.options = {
            itemsPerPage: 25,
            page: 1,
        };

        this.getSuppliers();
    },
};
</script>

<style scoped>
.create-button {
    text-decoration: none;
}
</style>

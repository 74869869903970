<template>
    <div>
        <div
            v-if="$auth.role.includes('SuperAdmin') || $auth.role.includes('Supplier')"
            style="display: flex; flex-direction: column"
            class="pt-2"
        >
            <v-btn color="#005069" dark small @click="initiatePostage()">Get Postage</v-btn>
            <v-btn color="#005069" dark class="mt-2" small @click="changeExtShipModal(1)">Add External Shipment</v-btn>
        </div>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal1" max-width="600px">
            <v-card class="p-3">
                <v-card-title> Create Shipment </v-card-title>
                <span><strong>From</strong></span>
                <v-text-field label="Company" v-model="parcel.fromAddress.company"></v-text-field>
                <v-text-field label="Address 1" v-model="parcel.fromAddress.address1"></v-text-field>
                <v-text-field label="Address 2" v-model="parcel.fromAddress.address2"></v-text-field>
                <v-text-field label="City" v-model="parcel.fromAddress.city"></v-text-field>
                <v-text-field label="ZipCode" v-model="parcel.fromAddress.zipCode"></v-text-field>

                <v-select
                    v-model="parcel.fromAddress.state"
                    :items="usStates"
                    item-text="label"
                    item-value="value"
                    label="State"
                ></v-select>
                <div style="display: flex; justify-content: space-between">
                    <!-- <v-btn dark color="primary" @click="submitFromAddress">Next</v-btn> -->
                    <v-btn @click="changeModal()">Cancel</v-btn>
                    <v-btn dark color="primary" @click="changeModal(2)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal2" max-width="600px">
            <v-card class="p-3">
                <v-card-title> Create Shipment </v-card-title>
                <span><strong>Parcel</strong></span>

                <v-select label="Parcel Type" v-model="parcel.parcelMode" :items="parcelModes"></v-select>
                <v-select
                    label="Carrier"
                    v-model="parcel.carrier"
                    item-text="label"
                    item-value="value"
                    :items="carriers"
                    value="0"
                    disabled
                ></v-select>

                <div v-if="parcel.parcelMode == 'Predefined'">
                    <v-select
                        v-model="parcel.package"
                        :items="uspsPredefineds"
                        item-text="label"
                        item-value="value"
                        label="Package"
                    ></v-select>
                </div>
                <div v-else>
                    <v-text-field type="number" label="Length (in)" v-model.number="parcel.length"></v-text-field>
                    <v-text-field type="number" label="Width (in)" v-model.number="parcel.width"></v-text-field>
                    <v-text-field type="number" label="Height (in)" v-model.number="parcel.height"></v-text-field>
                </div>
                <v-text-field label="Weight (oz)" type="number" v-model.number="parcel.weight"></v-text-field>
                <div v-if="parcel.package == 'Parcel' || parcel.package == 'SoftPack' || parcel.parcelMode == 'Custom'">
                    <v-checkbox
                        v-for="item in specialRates"
                        v-model="selectedSpecialRates"
                        :label="item.label"
                        :value="item.value"
                        @click="setOptions(item)"
                    ></v-checkbox>
                </div>
                <div style="display: flex; justify-content: space-between">
                    <!-- <v-btn dark color="primary" @click="submitParcel">Next</v-btn> -->
                    <v-btn @click="changeModal(1)">Back</v-btn>
                    <v-btn :disabled="parcelDataRequired" color="primary" @click="changeModal(3)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal3" max-width="600px">
            <v-card class="p-3">
                <v-card-title>Select Shipment Items</v-card-title>
                <v-list flat subheader>
                    <v-subheader>Included Items</v-subheader>

                    <v-list-item-group v-model="parcel.lineItems" multiple>
                        <div v-for="(item, index) in order.lineItems" :key="index" style="display: flex">
                            <v-list-item :value="item">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" color="primary"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>{{ item.productName }}</v-list-item-content>
                                </template>
                            </v-list-item>
                            <div v-if="parcel.lineItems.includes(item)" class="d-flex align-items-center">
                                <v-btn depressed @click="toggleSplitShip(item)" small>{{
                                    item.splitShip ? 'Cancel' : 'Split'
                                }}</v-btn>
                                <v-text-field
                                    v-if="item.splitShip"
                                    class="ml-4"
                                    v-model.number="item.shipQuantity"
                                    type="number"
                                    :min="0"
                                    :max="item.quantity - item.totalShipped"
                                ></v-text-field>
                            </div>
                        </div>
                    </v-list-item-group>
                </v-list>
                <div style="display: flex; justify-content: space-between">
                    <v-btn @click="changeModal(2)">Back</v-btn>
                    <v-btn :disabled="disableShipment" color="primary" @click="changeModal(4)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal4" max-width="600px">
            <v-card class="p-3">
                <v-card-title>Shipment Confirmation</v-card-title>
                <v-row>
                    <v-col cols="12" md="6">
                        <div><strong>From</strong></div>
                        <div>{{ parcel.fromAddress.company }}</div>
                        <div>{{ parcel.fromAddress.address1 }}</div>
                        <div>{{ parcel.fromAddress.address2 }}</div>
                        <div>{{ parcel.fromAddress.city }}</div>
                        <div>{{ parcel.fromAddress.state }}</div>
                        <div>{{ parcel.fromAddress.zipCode }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>To</strong></div>
                        <div>{{ order.shippingName }}</div>
                        <div>{{ order.address1 }}</div>
                        <div>{{ order.address2 }}</div>
                        <div>{{ order.city }}</div>
                        <div>{{ order.state }}</div>
                        <div>{{ order.zipCode }}</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <div><strong>Items</strong></div>
                        <div v-for="item in parcel.lineItems">{{ item.productName }} x{{ item.shipQuantity }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>Package</strong></div>
                        <div v-if="parcel.parcelMode == 'Predefined'">
                            <div>Predefined Package: {{ parcel.package }}</div>
                        </div>
                        <div v-else>
                            <div>Height: {{ parcel.height }} in</div>
                            <div>Width: {{ parcel.width }} in</div>
                            <div>Length: {{ parcel.length }} in</div>
                        </div>
                        <div>Weight: {{ parcel.weight }} oz</div>
                        <div>Carrier: {{ parcel.carrier }}</div>
                        <div v-if="selectedSpecialRates.length == 1">
                            Special Rate: {{ specialRates[selectedSpecialRates[0]].label }}
                        </div>
                    </v-col>
                </v-row>
                <div class="mt-3" style="display: flex; justify-content: space-between">
                    <v-btn @click="changeModal(3)">Back</v-btn>
                    <v-btn dark color="primary" @click="getShipmentRates()">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal5" max-width="600px">
            <v-card class="p-3">
                <div v-if="loadingRates">
                    <div class="p-4 text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        <div class="pt-4">Getting Rates</div>
                    </div>
                </div>
                <div v-else>
                    <div>
                        <v-radio-group v-model="selectedRate">
                            <v-radio v-for="(rate, index) in easyPostShipment.rates" :key="index" :value="rate">
                                <template v-slot:label>
                                    <div>
                                        {{ rate.service }} - <strong>${{ rate.rate }}</strong>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </div>
                    <div class="mt-3" style="display: flex; justify-content: space-between">
                        <v-btn @click="changeModal(4)">Back</v-btn>
                        <v-btn color="primary" :disabled="!selectedRate" @click="purchasePostage()">Buy</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            @click:outside="resetParcel()"
            transition="scroll-x-reverse-transition"
            v-model="extShipModal1"
            max-width="600px"
        >
            <v-card class="p-3">
                <v-card-title>Add External Tracking Shipment</v-card-title>
                <v-text-field v-model="trackingNumber"></v-text-field>
                <v-select
                    :items="carriers"
                    item-text="label"
                    item-value="value"
                    label="Carrier"
                    v-model="parcel.carrier"
                ></v-select>
                <div style="display: flex; justify-content: space-between">
                    <v-btn color="primary" @click="cancelShipment()">Cancel</v-btn>
                    <v-btn :disabled="trackingNumber == ''" color="primary" @click="changeExtShipModal(2)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            @click:outside="resetParcel()"
            transition="scroll-x-reverse-transition"
            v-model="extShipModal2"
            max-width="600px"
        >
            <v-card class="p-3">
                <v-card-title>Add External Tracking Shipment</v-card-title>
                <v-list flat subheader>
                    <v-subheader>Included Items</v-subheader>

                    <v-list-item-group v-model="parcel.lineItems" multiple>
                        <div v-for="(item, index) in order.lineItems" :key="index" style="display: flex">
                            <v-list-item :disabled="item.maxShipped" :value="item">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" color="primary"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content
                                        >{{ item.productName }}{{ item.maxShipped ? ' | max shipped' : '' }}
                                    </v-list-item-content>
                                </template>
                            </v-list-item>

                            <div v-if="parcel.lineItems.includes(item)" class="d-flex align-items-center">
                                <v-btn depressed @click="toggleSplitShip(item)" small>{{
                                    item.splitShip ? 'Cancel' : 'Split'
                                }}</v-btn>

                                <v-text-field
                                    class="ml-4"
                                    v-if="item.splitShip"
                                    v-model.number="item.shipQuantity"
                                    type="number"
                                    :min="0"
                                    :max="item.quantity - item.totalShipped"
                                ></v-text-field>
                            </div>
                        </div>
                    </v-list-item-group>
                </v-list>
                <div style="display: flex; justify-content: space-between">
                    <v-btn color="primary" @click="changeExtShipModal(1)">Back</v-btn>
                    <v-btn :disabled="disableShipment" color="primary" @click="submitExtShipment()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { orderStatuses, paymentStatuses, usStates, carriers } from '@/constants';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            usStates,
            carriers,
            token: '',
            message: 'hi',
            trackingNumber: '',
            postageModal1: false,
            postageModal2: false,
            postageModal3: false,
            postageModal4: false,
            postageModal5: false,
            extShipModal1: false,
            extShipModal2: false,
            headers: [],
            selectedProducts: [],
            loadingRates: false,
            selectedRate: '',
            parcel: {
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                package: null,
                serviceLevel: '',
                parcelMode: 'Predefined',
                carrier: 0,
                specialRate: null,
                fromAddress: {
                    company: '',
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    phone: '',
                },
                lineItems: [],
            },
            easyPostShipment: {},
            selectedSpecialRates: [],
            specialRates: [
                { label: 'Library Mail', value: 0 },
                { label: 'Media Mail', value: 1 },
            ],
            parcelModes: ['Predefined', 'Custom'],
            postageOrder: {},
            uspsServiceLevels: [
                { label: 'First', value: 'First' },
                { label: 'Priority', value: 'Priority' },
                { label: 'Express', value: 'Express' },
                { label: 'Parcel Select', value: 'ParcelSelect' },
            ],
            uspsPredefineds: [
                // { label: 'Card', value: 'Card' },
                { label: 'Letter', value: 'Letter' },
                { label: 'Flat', value: 'Flat' },
                { label: 'Flat Rate Envelope', value: 'FlatRateEnvelope' },
                { label: 'Flat Rate Legal Envelope', value: 'FlatRateLegalEnvelope' },
                { label: 'Flat Rate Padded Envelope', value: 'FlatRatePaddedEnvelope' },
                { label: 'Parcel', value: 'Parcel' },
                { label: 'Soft Pack', value: 'SoftPack' },
                { label: 'Small Flat Rate Box', value: 'SmallFlatRateBox' },
                { label: 'Medium Flat Rate Box', value: 'MediumFlatRateBox' },
                { label: 'Large Flat Rate Box', value: 'LargeFlatRateBox' },
                { label: 'Regional Rate Box A', value: 'RegionalRateBoxA' },
                { label: 'Regional Rate Box B', value: 'RegionalRateBoxB' },
            ],
            upsPredefineds: [
                { label: 'UPS Letter', value: 'UPSLetter' },
                { label: 'UPS Express Box', value: 'UPSExpressBox' },
                { label: 'UPS 25kg Box', value: 'UPS25kgBox' },
                { label: 'UPS 10kg Box', value: 'UPS10kgBox' },
                { label: 'Tube', value: 'Tube' },
                { label: 'Pak', value: 'Pak' },
                { label: 'Small Express Box', value: 'SmallExpressBox' },
                { label: 'Medium Express Box', value: 'MediumExpressBox' },
                { label: 'Large Express Box', value: 'LargeExpressBox' },
            ],
            rates: [],
        };
    },
    props: ['order', 'supplierSettings'],
    methods: {
        ...mapActions(['showSnackbar']),
        initiatePostage() {
            this.resetFromAddress();
            this.changeModal(1);
        },
        resetFromAddress() {
            this.parcel.fromAddress = {
                company: this.supplierSettings.name,
                address1: this.supplierSettings.address1,
                address2: this.supplierSettings.address2,
                city: this.supplierSettings.city,
                zipCode: this.supplierSettings.zipCode,
                state: this.supplierSettings.state,
                phone: this.supplierSettings.primaryContactPhoneNumber,
            };
        },
        resetParcel() {
            this.parcel = {
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                package: null,
                serviceLevel: '',
                parcelMode: 'Predefined',
                carrier: 0,
                specialRate: null,
                fromAddress: {
                    company: this.supplierSettings.name,
                    address1: this.supplierSettings.address1,
                    address2: this.supplierSettings.address2,
                    city: this.supplierSettings.city,
                    zipCode: this.supplierSettings.zipCode,
                    state: this.supplierSettings.state,
                    phone: this.supplierSettings.primaryContactPhoneNumber,
                },
                lineItems: [],
            };
            this.trackingNumber = '';
        },
        purchasePostage() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/Shipping/buy-postage/${this.easyPostShipment.id}/${this.selectedRate.id}/${this.$route.params.id}`,
                    this.parcel,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Postage purchased' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating shipment' });
                })
                .finally(() => {
                    this.changeModal();
                    this.resetParcel();
                    this.$emit('refresh');
                });
        },
        cancelShipment() {
            this.extShipModal1 = false;
            this.extShipModal2 = false;
            this.resetParcel();
        },
        changeExtShipModal(value) {
            this.extShipModal1 = false;
            this.extShipModal2 = false;
            if (value == 1) {
                this.extShipModal1 = true;
            }
            if (value == 2) {
                this.extShipModal2 = true;
            }
        },
        toggleSplitShip(item) {
            item.splitShip = !item.splitShip;

            if (!item.splitShip) {
                item.shipQuantity = item.quantity - item.totalShipped;
            }
        },
        changeModal(value) {
            this.postageModal1 = false;
            this.postageModal2 = false;
            this.postageModal3 = false;
            this.postageModal4 = false;
            this.postageModal5 = false;
            if (value == 1) {
                this.postageModal1 = true;
            }
            if (value == 2) {
                this.postageModal2 = true;
            }
            if (value == 3) {
                this.postageModal3 = true;
            }
            if (value == 4) {
                this.postageModal4 = true;
            }
            if (value == 5) {
                this.postageModal5 = true;
            }
        },
        openPostageModal1() {
            this.packageModal = true;
        },
        setOptions(item) {
            if (this.selectedSpecialRates.length > 1) {
                this.selectedSpecialRates = [item.value];
            }
        },
        getShipmentRates() {
            this.loadingRates = true;
            this.changeModal(5);
            this.parcel.specialRate = this.selectedSpecialRates.length > 0 ? this.selectedSpecialRates[0] : null;
            if (this.parcel.parcelMode == 'Custom') {
                this.parcel.package = null;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Shipping/get-rates/${this.$route.params.id}`, this.parcel)
                .then(response => {
                    this.easyPostShipment = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error getting shipment rates' });
                    this.changeModal();
                })
                .finally(() => {
                    this.loadingRates = false;
                });
        },
        submitExtShipment() {
            const cleanTrackingNum = this.trackingNumber.replaceAll(' ', '');

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/Shipping/add-external-shipment/${this.$route.params.id}/${cleanTrackingNum}`,
                    this.parcel,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Shipment created' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating shipment' });
                })
                .finally(() => {
                    this.changeExtShipModal();
                    this.resetParcel();
                    this.$emit('refresh');
                });
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
        });
    },

    computed: {
        parcelDataRequired() {
            if (this.parcel.parcelMode == 'Custom') {
                if (
                    this.parcel.length == 0 ||
                    this.parcel.width == 0 ||
                    this.parcel.height == 0 ||
                    this.parcel.weight == 0
                ) {
                    return true;
                }
            } else {
                if (this.parcel.weight == 0 || !this.parcel.package) {
                    return true;
                }
            }
            return false;
        },
        disableShipment() {
            var zeroItem = this.parcel.lineItems.find(item => !item.shipQuantity > 0);
            if (this.parcel.lineItems.length == 0 || zeroItem) {
                return true;
            }
            return false;
        },
    },
};
</script>
<style></style>

<template>
    <div class="dash-container">
        <div class="tile form">
            <div class="anonymous-stream">
                <h1 class="anonymous-stream__title">{{ event.name }}</h1>
                <v-tabs
                    v-model="currentTab"
                    class="anonymous-stream__tabs stream-tabs"
                    background-color="#fafafa"
                    fixed-tabs
                >
                    <v-tab href="#connections">Connections</v-tab>
                    <v-tab :disabled="currentStreamState < streamStates.LIVE" href="#preview"> Live Preview </v-tab>
                    <v-tab :disabled="currentStreamState < streamStates.LIVE" @click="setWowzaChart" href="#health">
                        Health
                    </v-tab>
                </v-tabs>

                <v-tabs-items :value="currentTab">
                    <v-tab-item class="anonymous-stream__tab-item" value="connections">
                        <v-form class="connections">
                            <v-text-field
                                v-for="setting in Object.keys(streamSettings)"
                                :key="streamSettings[setting].label"
                                :label="streamSettings[setting].label"
                                class="connections__input cursor"
                                @click:append="copyText"
                                :append-icon="'mdi-clipboard'"
                                :value="streamSettings[setting].value"
                                readonly
                            />
                        </v-form>
                    </v-tab-item>
                    <v-tab-item class="anonymous-stream__tab-item" value="preview">
                        <event-viewer :src="currentEvent.liveURL" :isLiveStream="true" />
                        <p class="text-center">Viewers: {{ eventViewers }}</p>
                    </v-tab-item>
                    <v-tab-item class="anonymous-stream__tab-item" value="health">
                        <wowza-event-chart
                            :live="event.eventStatus == 2"
                            :anonymous="true"
                            :accessToken="accessToken"
                            ref="wowzaChart"
                            :event="event"
                            @viewerCountUpdate="count => updateViewCount(count)"
                        />
                    </v-tab-item>
                </v-tabs-items>

                <!-- <p class="anonymous-stream__viewers font-weight-bold">Viewers: {{ viewers }}</p> -->

                <span style="width: 100%; margin-top: -30px">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="mb-7" color="secondary" @click="handleAtemClick">
                                <v-icon size="2x">mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Download Streaming Profile</span>
                    </v-tooltip>
                </span>

                <v-btn
                    class="anonymous-stream__btn"
                    :loading="currentStreamState === streamStates.ACTIVE || currentStreamState === streamStates.ENDING"
                    x-large
                    @click="toggleStream"
                    :color="btnColor"
                >
                    <font-awesome-icon
                        v-if="currentStreamState === streamStates.INACTIVE || currentStreamState === streamStates.ENDED"
                        icon="fa-solid fa-play"
                        style="font-size: 1rem"
                    />
                    <font-awesome-icon
                        v-else-if="currentStreamState === 2"
                        icon="fa-solid fa-ban"
                        style="font-size: 1rem"
                    />

                    <span class="ml-2">{{ btnText }}</span>
                </v-btn>
            </div>
        </div>

        <v-snackbar v-model="showSnackbar" :timeout="3000">
            {{ message }}
        </v-snackbar>
    </div>
</template>

<script>
// TODO
// 1) Make sure page loads and syncs with correct event status on the backend
// 2) Make sure if the buttons ever get out of sync with the backend, that I somehow handle it.

import LiveVideoPreview from '@/components/videojs/LiveVideoPreview.vue';
import WowzaEventChart from '@/components/charts/WowzaEventChart.vue';
import EventViewer from '@/components/videojs/EventViewer.vue';

// {Object} streamStates
// 0 = Not activated
// 1 = Stream activated but Wowza server isn't ready
// 2 = Stream activated, server ready
// 3 = Stream is ending, but server hasn't stopped yet
// 4 = Stream ended and server is stopped

export default {
    name: 'AnonymousStream',
    metaInfo: {
        title: 'Manage Stream',
    },
    components: { LiveVideoPreview, EventViewer, WowzaEventChart },
    data() {
        return {
            accessToken: '',
            currentTab: 'connections',
            showSnackbar: false,
            isVideoActivated: false,
            streamStates: {
                INACTIVE: 0,
                ACTIVE: 1,
                LIVE: 2,
                ENDING: 3,
                ENDED: 4,
            },
            currentStreamState: 0, // starts inactive
            message: '', // message for snackbar
            event: {
                name: '',
                viewers: 0,
                id: '',
            },
            eventViewers: 0,
            streamSettings: {
                primaryServer: {
                    label: 'RTMP',
                    value: '',
                },
                streamName: {
                    label: 'Stream Key (name)',
                    value: '',
                },
            },
            currentEvent: null,
        };
    },
    methods: {
        updateViewCount(count) {
            this.eventViewers = count;
        },
        getServiceBySlug() {
            this.axios
                .get(process.env.VUE_APP_API + `/services/view/${this.$route.params.slug}`)
                .then(({ data }) => {
                    const event = data.events.find(e => e.id === data.currentLiveEventId);
                    if (event) {
                        this.event.name = event.title;
                        this.event.id = event.id;
                    } else {
                        this.event.name = `${data.firstName} ${data.lastName}`;
                    }
                })
                .catch(error => {
                    console.error(error);

                    this.event.name = 'Manage Stream';
                    this.setSnackbar('Error! Could not find service.');
                });
        },
        toggleStream() {
            if (
                this.currentStreamState === this.streamStates.INACTIVE ||
                this.currentStreamState === this.streamStates.ENDED
            ) {
                this.currentStreamState = this.streamStates.ACTIVE;
                this.startLiveStream().then(() => {
                    this.monitorStreamStatus();
                });
            } else {
                this.currentStreamState = this.streamStates.ENDING;
                this.endLiveStream();
            }
        },
        startLiveStream() {
            return this.axios
                .post(process.env.VUE_APP_API + `/live/remote/start/${this.$route.params.token}`)
                .then(({ data }) => {
                    this.streamSettings.primaryServer.value = data.primaryServer;
                    this.streamSettings.streamName.value = data.streamName;
                    this.streamSettings.userName = { label: 'Username', value: data.userName };
                    this.streamSettings.password = { label: 'Pawword', value: data.password };

                    this.setSnackbar('Live stream started successfully.');
                })
                .catch(error => {
                    console.error(error);

                    this.endLiveStream();
                    this.setSnackbar('Error! There was a problem starting your live stream.');
                });
        },
        endLiveStream() {
            this.axios
                .post(process.env.VUE_APP_API + `/live/remote/stop/${this.$route.params.token}`)
                .then(({ data }) => {
                    this.currentStreamState = this.streamStates.ENDED;
                    this.setSnackbar('Live stream ended.');
                })
                .catch(error => {
                    console.error(error);

                    // If server fails to stop live stream, try again in 10 seconds.
                    setTimeout(() => {
                        this.endLiveStream();
                    }, 10000);
                });
        },
        monitorStreamStatus() {
            this.axios
                .get(process.env.VUE_APP_API + `/live/remote/get-health-records/${this.$route.params.token}`)
                .then(({ data }) => {
                    this.liveStreamReady = data.uptimes.some(el => el.running);

                    if (this.liveStreamReady) {
                        this.currentStreamState = this.streamStates.LIVE;
                        this.setSnackbar('Live stream server is running');
                    }
                })
                .catch(error => {
                    this.setSnackbar('Error.');
                });
        },
        activateVideoPreview() {
            this.isVideoActivated = true;
            this.$refs.livePreview.loading = false;
            this.$refs.livePreview.init();
        },
        handleVideoError() {
            this.setSnackbar('There was a problem previewing the stream. Please try again.');
        },
        setWowzaChart() {
            return;
        },
        copyText(e) {
            const value = e.target.closest('.connections__input').querySelector('input').value;
            navigator.clipboard.writeText(value);
            this.setSnackbar('Text copied');
        },
        setSnackbar(message) {
            this.message = message;
            this.showSnackbar = true;
        },
        switchTabs(tabName) {
            this.currentTab = tabName;
        },
        getRTMP() {
            this.axios
                .get(process.env.VUE_APP_API + `/live/remote/get-rtmp/${this.$route.params.token}`)
                .then(({ data }) => {
                    this.streamSettings.primaryServer.value = data.rtmp;
                    this.streamSettings.streamName.value = data.name;

                    if (data.username) {
                        this.streamSettings.userName = { label: 'Username', value: data.username };
                    }
                    if (data.password) {
                        this.streamSettings.username = { label: 'Password', value: data.password };
                    }
                })
                .catch(error => {
                    this.setSnackbar('Error.');
                });
        },
        getEventStatus: function () {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(process.env.VUE_APP_API + `/live/remote/get-event/${this.$route.params.token}`)
                    .then(response => {
                        this.currentEvent = response.data;
                        resolve(this.currentEvent);
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        handleAtemClick() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.accessToken}` } })
                .get(process.env.VUE_APP_API + `/events/getatemxmlfile/${this.currentEvent.id}/${this.accessToken}`)
                .then(response => {
                    let eventName = this.currentEvent.title
                        .toLowerCase()
                        .replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
                        .replace(/\s+/g, '-');

                    let xmltext = response.data;
                    let filename = eventName + '-atem.xml';
                    let pom = document.createElement('a');
                    let bb = new Blob([xmltext], { type: 'text/plain' });

                    pom.setAttribute('href', window.URL.createObjectURL(bb));
                    pom.setAttribute('download', filename);

                    pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                    pom.draggable = true;
                    pom.classList.add('dragout');
                    pom.dispatchEvent(new MouseEvent('click', {}));
                })
                .catch(error => {});
        },
    },
    watch: {
        // currentStreamState() {
        //     if(this.currentStreamState === this.streamStates.LIVE) {
        //         this.activateVideoPreview();
        //     }
        // },
        // currentTab() {
        //     // The first time the user switches to the Live Preview tab while the stream is live, activate video preview
        //     if(this.currentTab === 'preview' && this.currentStreamState === this.streamStates.LIVE && !this.isVideoActivated) {
        //         this.activateVideoPreview();
        //     }
        // }
    },
    computed: {
        btnText() {
            return this.currentStreamState !== this.streamStates.LIVE &&
                this.currentStreamState !== this.streamStates.ENDING
                ? 'Start Live Stream'
                : 'Stop Live Stream';
        },
        btnColor() {
            return this.currentStreamState !== this.streamStates.LIVE &&
                this.currentStreamState !== this.streamStates.ENDING
                ? 'success'
                : 'error';
        },
    },
    mounted() {
        this.accessToken = this.$route.params.token;
        this.$store.dispatch('toggleTopNav');

        this.getEventStatus()
            .then(event => {
                // Check if Event is live
                if (event.eventStatus === 2 || event.eventStatus === 2) {
                    this.currentStreamState = this.streamStates.LIVE;
                }

                // Get RTMP VALUES
                this.getServiceBySlug();
                this.getRTMP();
            })
            .catch(() => {
                this.setSnackbar('Error! There was a problem fetching event data!');
            });
    },
    beforeUnmount() {
        // DISCUSS WITH SHANE
        // If Wowza server has started but hasn't been ended, end it
        if (this.currentStreamState > this.streamStates.ACTIVE) {
            this.endLiveStream();
        }
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    margin-left: 0;
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat;
}

.tile {
    width: 40%;
    padding: 2rem;
    box-shadow: 0 0 1rem #666;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    min-width: 500px;
    min-height: 400px;
    display: flex;
}

.video-wrapper {
    min-width: 300px;
    width: 100%;
}

.v-tabs-items {
    width: 100%;
}

.anonymous-stream {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__tabs {
        margin-bottom: 2rem;
        flex-grow: 0;
    }

    &__tab-item {
        margin-bottom: 2rem;
    }

    &__viewers {
        margin-bottom: 8px;
    }

    &__video {
        background: #343434;
        width: 100%;
        height: 400px;
        margin-bottom: 24px;
    }

    &__btn {
        margin-top: auto;
    }
}
</style>

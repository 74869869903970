<template>
    <div>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />
        <v-btn style="height: 40px" color="green" class="white--text" :loading="!Boolean(uppy)" @click="handleOpen">
            <span>Upload</span>
            <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-up-from-bracket" />
        </v-btn>
        <br />
        <small><font color="grey">*Upload your Music License</font></small>
    </div>
</template>

<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { mapActions } from 'vuex';

export default {
    name: 'LogoUpload',
    data() {
        return {
            token: '',
            isOpen: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                note: '5MB file size limit',
            },
            uppy: null,
            licenseSrc: '',
            funeralHomeId: 0,
        };
    },
    components: {
        DashboardModal,
    },
    async created() {
        await this.setAuthToken();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async initUppy() {
            const MAX_FILE_SIZE = 15000000; // 5 MB
            this.uppy = new Uppy({
                id: 'logo-upload',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: ['image/*', '.pdf'],
                },
            }).use(UppyAddUploaderPlugin, {
                uploader: fileIds => {
                    return new Promise(async (resolve, reject) => {
                        const file = this.uppy.getFile(fileIds[0]);
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(file.data);
                        reader.addEventListener('error', error => {});
                        reader.addEventListener('load', async () => {
                            try {
                                const fd = new FormData();
                                fd.append('File', file.data);
                                fd.append('FuneralHomeId', this.funeralHomeId);

                                await this.axios
                                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                                    .post(process.env.VUE_APP_API + `/copyrightmusic/upload`, fd);
                                this.licenseSrc = await this.getLicense();
                                this.handleClose();
                                this.showSnackbar({ message: 'Successfully uploaded music license' });
                                resolve('done');
                            } catch (error) {
                                console.error(error);
                                reject(error);
                            }
                        });
                    });
                },
            });
        },
        getLicense() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/copyrightmusic/license/${this.funeralHomeId}`)
                .then(response => {
                    this.licenseSrc = response.data.musicLicenseFile;
                })
                .catch(error => console.error(error));
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        addLicenseFile() {
            this.uppy = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/copyrightmusic/upload/${this.funeralHomeId}`)
                .then(response => {})
                .catch(error => console.error(error));
        },
        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;

            // Super Admins dont have a funeral home - but they see the id via the route params
            this.funeralHomeId = this.$auth.funeralHomeId ? this.$auth.funeralHomeId : this.$route.params.id;

            // Fetch Data
            this.getLicense();
        });
    },
};
</script>
<style lang="scss" scoped>
.user-logo {
    display: block;
    max-width: 300px;
    margin-bottom: 1rem;
}
</style>

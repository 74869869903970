<template>
    <div>
        <v-dialog @click:outside="$refs.inviteForm.reset()" max-width="600px" v-model="show">
            <v-card>
                <div class="pl-3 pr-3 decorated-title">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Share Upload Link</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>

                <v-form ref="inviteForm" v-model="formValid">
                    <div class="p-3">
                        <v-row>
                            <v-col cols="12" md="2">
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        height: 100%;
                                        width: 100%;
                                    "
                                    v-if="deeplink"
                                >
                                    <qrcode-vue
                                        v-if="deeplink"
                                        style="width: 70px; height: 70px"
                                        :value="deeplink"
                                        size="70"
                                        render-as="svg"
                                    />
                                </div>
                            </v-col>
                            <v-col cols="12" :md="deeplink ? '10' : '12'">
                                <div class="email-group pt-0 p-2">
                                    <v-combobox
                                        style="width: 100%"
                                        v-model="emailList"
                                        hide-selected
                                        label="Email List"
                                        @blur="manualBlur"
                                        ref="emailCombo"
                                        multiple
                                        small-chips
                                        :deletable-chips="true"
                                        :counter="emailList.length > 0"
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                color="primary"
                                                dark
                                                small
                                                :key="JSON.stringify(data.item)"
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                            >
                                                {{ data.item }}
                                                <font-awesome-icon
                                                    @click.stop="removeEmailFromList(data)"
                                                    class="ml-2 del-btn"
                                                    icon="fa-regular fa-circle-x"
                                                />
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <div>Press <span class="entr-key">Enter</span> to add email</div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:counter="{ props }">
                                            <div v-if="props.value" class="email-counter">
                                                {{ props.value }} Recipients
                                            </div>
                                        </template>
                                    </v-combobox>

                                    <v-btn
                                        :disabled="emailList.length == 0"
                                        @click="handleSubmit"
                                        color="primary"
                                        class="btn"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-paper-plane-top" />
                                    </v-btn>
                                </div>
                                <div
                                    class="pt-0 p-2"
                                    style="display: flex; justify-content: space-between; align-items: flex-end"
                                    v-if="deeplink"
                                >
                                    <a :href="deeplink" target="_blank">{{ deeplink }}</a>
                                    <v-btn @click="copyToClipboard(deeplink)">
                                        <font-awesome-icon icon="fa-regular fa-clone"></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- Share PDF Modal -->
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import QrcodeVue from 'qrcode.vue';
export default {
    data() {
        return {
            show: false,
            formValid: false,
            emailList: [],
        };
    },
    components: {
        QrcodeVue,
    },
    props: {
        deeplink: {
            type: String,
            required: false,
        },
    },
    computed: {
        emailRule() {
            return [
                v => {
                    if (!v) {
                        return true;
                    }
                    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    const emails = v.split(',');
                    return emails.every(email => pattern.test(email.trim()));
                },
            ];
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(text) {
            const clipboard = navigator.clipboard;
            clipboard
                .writeText(text)
                .then(() => {
                    this.showSnackbar({ message: 'Copied to clipboard' });
                })
                .catch(error => {
                    console.error(`Error copying to clipboard: ${error}`);
                });
        },
        handleSubmit() {
            let allEmailsValid = true;

            for (let i = 0; i < this.emailList.length; i++) {
                const email = this.emailList[i];

                if (!this.isValidEmail(email)) {
                    this.$emit('invalidEmail', email);
                    allEmailsValid = false;
                    break;
                }
            }

            if (allEmailsValid) {
                this.$emit('submit', this.emailList);
            }
        },
        isValidEmail(value) {
            // Source: https://emailregex.com/
            const emailRegex = /\S+@\S+\.\S+/;
            return emailRegex.test(value);
        },
        manualBlur(event) {
            this.$refs.emailCombo.blur();
        },
        removeEmailFromList(data) {
            this.emailList.splice(data.index, 1);
        },
    },
};
</script>
<style lang=""></style>

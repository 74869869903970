<template>
    <div>
        <div class="d-flex align-items-center">
            <v-btn
                v-if="!scrollStartReached"
                style="position: absolute; left: 0; margin-left: -50px"
                small
                fab
                text
                @mousedown="handleSideScrollMouseDown($event, 'left')"
                @mouseup="handleSideScrollMouseUp()"
                @mouseout="cancelScroll"
            >
                <font-awesome-icon icon="fa-solid fa-chevron-left"></font-awesome-icon>
            </v-btn>
            <v-btn
                v-if="!scrollEndReached"
                style="position: absolute; right: 0; margin-right: -50px"
                small
                fab
                text
                @mousedown="handleSideScrollMouseDown($event, 'right')"
                @mouseup="handleSideScrollMouseUp()"
                @mouseout="cancelScroll"
            >
                <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
            </v-btn>

            <div :id="this.containerId" class="summary-overflow-container align-items-start">
                <slot name="content"></slot>
                <div
                    style="min-width: 5px; height: 100%; margin-left: -20px"
                    :id="this.containerId + 'scroll-trigger-end'"
                ></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            scrollPosition: 0,
            maxScrollPosition: 0,
            animationFrameId: null,
            resizeObserver: null,
            mutationObserver: null,
            lastScrollDirection: null,
        };
    },
    props: {
        containerId: {
            type: String,
            required: true,
        },
        itemBaseId: {
            type: String,
            required: true,
        },
        maxItemCount: {
            type: Number,
            required: true,
        },
        itemQuerySelector: {
            type: String,
            required: true,
        },
    },
    computed: {
        scrollStartReached() {
            return this.scrollPosition == 0;
        },
        scrollEndReached() {
            return this.scrollPosition == this.maxScrollPosition;
        },
    },
    watch: {
        scrollEndReached() {
            if (this.lastScrollDirection == 'right') {
                cancelAnimationFrame(this.animationFrameId);
            }
            this.$emit('scroll-end-reached', this.scrollEndReached);
        },
        scrollStartReached() {
            if (this.lastScrollDirection == 'left') {
                cancelAnimationFrame(this.animationFrameId);
            }
        },
    },
    methods: {
        cancelScroll() {
            cancelAnimationFrame(this.animationFrameId);
        },
        checkScrollPosition() {
            const container = document.getElementById(this.containerId);
            const scrollPosition = container.scrollLeft;
            return scrollPosition;
        },
        checkMaxScroll() {
            const container = document.getElementById(this.containerId);
            const maxScrollPosition = container.scrollWidth - container.clientWidth;
            return maxScrollPosition;
        },
        canScrollRight() {
            const container = document.getElementById(this.containerId);
            const scrollPosition = container.scrollLeft;
            const maxScrollPosition = container.scrollWidth - container.clientWidth;
            const canScrollRight = scrollPosition < maxScrollPosition;
            return canScrollRight;
        },
        canScrollLeft() {
            const container = document.getElementById(this.containerId);
            const scrollPosition = container.scrollLeft;
            const canScrollLeft = scrollPosition > 0;
            return canScrollLeft;
        },
        handleSideScrollMouseDown(event, direction) {
            cancelAnimationFrame(this.animationFrameId);
            this.lastScrollDirection = direction;
            let container = document.getElementById(this.containerId);

            if (direction == 'right' && !this.canScrollRight()) return;
            if (direction == 'left' && !this.canScrollLeft()) return;

            const scrollAmount = 10;

            const scrollStep = () => {
                if (direction == 'right') {
                    container.scrollLeft += scrollAmount;
                    this.scrollPosition = container.scrollLeft;
                    this.animationFrameId = requestAnimationFrame(scrollStep);
                } else if (direction == 'left') {
                    container.scrollLeft -= scrollAmount;
                    this.scrollPosition = container.scrollLeft;
                    this.animationFrameId = requestAnimationFrame(scrollStep);
                }
            };
            this.animationFrameId = requestAnimationFrame(scrollStep);
        },
        handleSideScrollMouseUp() {
            cancelAnimationFrame(this.animationFrameId);
            if (this.lastScrollDirection == 'right') {
                let nextEl = this.findFirstFullyVisibleItem();
                if (!nextEl) {
                    nextEl = this.findLastCutOffItem();
                }

                this.scrollItemIntoView(nextEl);
            } else if (this.lastScrollDirection == 'left') {
                let prevEl = this.findFirstCutOffItem();
                this.scrollItemIntoView(prevEl);
            }
        },
        scrollItemIntoView(targetEl) {
            if (targetEl) {
                const container = document.getElementById(this.containerId);

                let timeout;

                const endScrollHandler = () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        this.maxScrollPosition = this.checkMaxScroll();
                        this.scrollPosition = this.checkScrollPosition();
                    }, 100);
                };

                container.addEventListener('scroll', endScrollHandler);
                targetEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        },
        findFirstFullyVisibleItem() {
            const container = document.getElementById(this.containerId);
            const items = container.querySelectorAll(this.itemQuerySelector);

            const containerScrollLeft = container.scrollLeft;
            const containerRightEdge = containerScrollLeft + container.offsetWidth;

            for (let item of items) {
                const itemLeftEdge = item.offsetLeft;
                const itemRightEdge = itemLeftEdge + item.offsetWidth;

                if (itemLeftEdge >= containerScrollLeft && itemRightEdge <= containerRightEdge) {
                    return item; // This is the first fully visible item
                }
            }
        },
        findFirstCutOffItem() {
            const container = document.getElementById(this.containerId);
            const items = container.querySelectorAll(this.itemQuerySelector);

            const containerScrollLeft = container.scrollLeft;

            for (let item of items) {
                const itemLeftEdge = item.offsetLeft;
                const itemRightEdge = itemLeftEdge + item.offsetWidth;

                if (itemLeftEdge < containerScrollLeft && itemRightEdge > containerScrollLeft) {
                    return item; // This is the first item that is cut off on the left
                }
            }

            return null; // Return null if no item is cut off on the left
        },
        findLastCutOffItem() {
            const container = document.getElementById(this.containerId);
            const items = container.querySelectorAll(this.itemQuerySelector);

            const containerScrollRight = container.scrollLeft + container.clientWidth;

            // Start from the end and find the last item that is cut off on the right
            for (let i = items.length - 1; i >= 0; i--) {
                const item = items[i];
                const itemLeftEdge = item.offsetLeft;
                const itemRightEdge = itemLeftEdge + item.offsetWidth;

                // Check if the item is partially visible on the right
                if (itemRightEdge > containerScrollRight && itemLeftEdge < containerScrollRight) {
                    return item; // This is the last item that is cut off on the right
                }
            }

            return null; // Return null if no item is cut off on the right
        },

        initResizeObserver() {
            cancelAnimationFrame(this.animationFrameId);
            const targetDiv = document.getElementById(this.containerId);

            // Create a ResizeObserver
            this.resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    this.maxScrollPosition = this.checkMaxScroll();
                    this.scrollPosition = this.checkScrollPosition();
                }
            });

            // Start observing the target element
            this.resizeObserver.observe(targetDiv);
        },
        initMutationObserver() {
            cancelAnimationFrame(this.animationFrameId);
            //Update current / max scroll position on item add /remove
            const targetDiv = document.getElementById(this.containerId);

            this.mutationObserver = new MutationObserver(mutations => {
                for (let mutation of mutations) {
                    if (mutation.type === 'childList') {
                        this.maxScrollPosition = this.checkMaxScroll();
                        this.scrollPosition = this.checkScrollPosition();
                    }
                }
            });

            this.mutationObserver.observe(targetDiv, { childList: true, subtree: true });
        },
    },
    mounted() {
        this.initResizeObserver();
        this.initMutationObserver();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    },
};
</script>
<style lang="scss" scoped>
.summary-overflow-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    gap: 20px;
    //overflow-x: auto;
    overflow-x: hidden;
    padding: 10px 0;
}
</style>

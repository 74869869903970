<template>
    <div>
        <!-- <font-awesome-icon icon="fa-solid fa-cloud-music" @click="playAudio(playableLink)" style="font-size: 1.4rem" /> -->
        <audio @loadedmetadata="emitFileSize" ref="audio" v-on:ended="stopAudio()">
            <source :src="playableLink" />
        </audio>
        <font-awesome-icon
            class="pointer player-icon"
            v-if="!playing"
            icon="fa-regular fa-circle-play"
            @click="playAudio()"
            :style="{ fontSize: fontSize }"
        />
        <font-awesome-icon
            class="pointer player-icon"
            v-else
            icon="fa-regular fa-circle-stop"
            @click="stopAudio()"
            :style="{ fontSize: fontSize }"
        />
    </div>
</template>
<script>
export default {
    data() {
        return {
            message: 'hi',
            playing: false,
        };
    },
    props: {
        playableLink: {
            type: String,
            required: true,
        },
        fontSize: {
            type: String,
            default: '1.4rem',
        },
    },
    methods: {
        playAudio() {
            var audio = this.$refs.audio;
            if (!audio) return;

            this.playing = true;
            audio.play();
            this.$emit('playing');
        },
        stopAudio() {
            var audio = this.$refs.audio;

            if (!audio) return;
            this.playing = false;
            audio.pause();
            audio.currentTime = 0;
        },
        emitFileSize(event) {
            const audioElememnt = event.target;
            const fileSize = audioElememnt.duration;
            this.$emit('fileSize', fileSize);
        },
    },
};
</script>
<style lang=""></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"services-table",style:({ paddingTop: '8px' }),attrs:{"loading":_vm.loading,"loading-text":"Getting Songs...","headers":_vm.headers,"items":_vm.songs,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
            showFirstLastPage: true,
        },"server-items-length":_vm.totalServices,"options":_vm.options,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"search-bar",attrs:{"label":"Search","append-icon":"mdi-magnify","single-line":"","hide":"","details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.eventTitle",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'/services/' + item.serviceSlug}},[_vm._v(_vm._s(item.eventTitle))])]}},{key:"item.song",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"id":"licenseSongTable"}},[_c('p',{staticStyle:{"font-size":"15px","padding-right":"20px"}},[_vm._v(" "+_vm._s(item.song)+" "),_c('v-row',[_c('small',{staticStyle:{"padding-top":"10px","font-size":"9px","padding-left":"15px","font-weight":"bold"}},[_c('font',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.artist))])],1)])],1)])]}},(_vm.$auth.role.includes('SuperAdmin'))?{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"id":"musicIconGroup"}},[_c('div',{staticStyle:{"width":"46px","text-align":"center"}},[(item.actions.artworkUrl)?_c('img',{attrs:{"width":"40px","height":"40px","src":item.actions.artworkUrl}}):_vm._e()]),_c('div',{staticStyle:{"width":"30px","text-align":"center"}},[(item.actions.spotifyLink)?_c('a',{attrs:{"href":item.actions.spotifyLink,"target":"_blank"}},[_c('span',{staticClass:"mdi mdi-spotify",staticStyle:{"font-size":"30px","color":"#1db954"}})]):_vm._e()]),_c('div',{staticStyle:{"width":"30px","text-align":"center"}},[_c('music-track-player',{attrs:{"item":item}})],1)])]}}:{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"id":"musicIconGroup"}},[_c('div',{staticStyle:{"width":"46px","text-align":"center"}},[(item.actions.artworkUrl)?_c('img',{attrs:{"width":"40px","height":"40px","src":item.actions.artworkUrl}}):_vm._e()]),_c('div',{staticStyle:{"width":"30px","text-align":"center"}},[(item.actions.spotifyLink)?_c('a',{attrs:{"href":item.actions.spotifyLink,"target":"_blank"}},[_c('span',{staticClass:"mdi mdi-spotify",staticStyle:{"font-size":"30px","color":"#1db954"}})]):_vm._e()]),_c('div',{staticStyle:{"width":"30px","text-align":"center"}},[_c('music-track-player',{attrs:{"item":item}})],1)])]}}],null,true)}),_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"290"},model:{value:(_vm.deleteModal.show),callback:function ($$v) {_vm.$set(_vm.deleteModal, "show", $$v)},expression:"deleteModal.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete Service? ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.deleteModal.message))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gdarken-1","text":""},on:{"click":function($event){_vm.deleteModal.show = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteService(_vm.deleteModal.serviceId)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
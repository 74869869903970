<template>
    <v-row justify="center">
        <v-dialog v-model="openDialog" max-width="60vw">
            <v-card>
                <v-card-title>
                    <div class="headline">
                        <font-awesome-icon icon="fa-regular fa-user-group" class="mr-2" />
                        <span v-if="editingUser">{{ userId > 0 ? 'Edit' : 'Create' }} User</span>
                        <span v-else-if="adminEditingUser">Admin Edit User</span>
                        <span v-else>Manage Users</span>
                    </div>

                    <v-spacer></v-spacer>

                    <span v-if="!editingUser && !adminEditingUser">
                        <v-btn @click="initUpdateUserForm()" color="primary">+ Add New user</v-btn>
                    </span>

                    <span v-if="editingUser && userId > 0 && !emailVerifiedAdmin" style="opacity: 0.5">PENDING </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-dialog v-model="deleteModal" persistent max-width="290">
                            <v-card v-if="selectedUser">
                                <v-card-title class="headline"> Delete User? </v-card-title>
                                <v-card-text
                                    >Are you sure you want to delete<br />
                                    <strong>{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</strong
                                    >?</v-card-text
                                >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                                    <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <reset-password
                            v-if="passwordResetModal && token"
                            :token="token"
                            :user="selectedUser"
                            @close-password-reset-modal="passwordResetModal = false"
                        ></reset-password>

                        <v-data-table
                            v-if="!editingUser && !adminEditingUser"
                            :headers="headers"
                            :items="users"
                            :loading="loading"
                            :footer-props="{
                                itemsPerPageOptions: [10, 25, 50],
                            }"
                            loading-text="Getting Users..."
                            :options.sync="options"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <div>
                                    <p class="mb-0">{{ item.firstName + ' ' + item.lastName }}</p>
                                    <small v-if="!item.emailVerified" style="opacity: 0.7">Pending</small>
                                </div>
                            </template>

                            <template v-slot:[`item.blocked`]="{ item }">
                                <v-chip v-if="item.blocked" small dark color="red">Blocked</v-chip>
                            </template>

                            <template v-slot:[`item.email`]="{ item }">
                                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                            </template>

                            <template v-slot:[`item.lastLogin`]="{ item }">
                                <div v-if="auth0loading">
                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                </div>
                                <div v-else-if="item.lastLogin">
                                    {{ item.lastLogin | dateFormat() }}
                                </div>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- <div v-if="$route.name === 'EditServices'">
                                    <v-chip
                                        @click="updateStreamOwner(item)"
                                        :dark="streamOwner.email === item.email ? true : false"
                                        :color="streamOwner.email === item.email ? 'green' : ''"
                                        ><font-awesome-icon
                                            v-if="streamOwner.email === item.email"
                                            class="mr-2"
                                            icon="fa-solid fa-user"
                                        />{{ streamOwner.email === item.email ? 'Selected' : 'Select' }}</v-chip
                                    >
                                </div> -->
                                <div class="action-list">
                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-pencil"
                                                    @click="initUpdateUserForm(item)"
                                                    style="font-size: 1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Edit</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip v-if="item.emailVerified" :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openPasswordResetModal(item)">
                                                <font-awesome-icon
                                                    icon="fa-regular fa-lock"
                                                    style="font-size: 1.1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Password</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip v-if="!item.emailVerified" :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-envelope"
                                                    style="font-size: 1rem"
                                                    @click="resendEmailInvite(item)"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Resend Email Invite</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openPasswordResetEmailModal(item)">
                                                <font-awesome-icon icon="fa-regular fa-key" style="font-size: 1.1rem" />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Password Reset Email</span>
                                        </template>
                                    </custom-tooltip>

                                    <!-- separator -->
                                    <div>||</div>

                                    <custom-tooltip
                                        v-if="$auth.role.includes('SuperAdmin')"
                                        :tooltipProps="{ bottom: true }"
                                    >
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-envelopes-bulk"
                                                    style="font-size: 1rem"
                                                    @click="postmarkDeeplink(item.email)"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Postmark Activity</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip
                                        v-if="$auth.role.includes('SuperAdmin')"
                                        :tooltipProps="{ bottom: true }"
                                    >
                                        <template v-slot:activator>
                                            <span>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-pen-to-square"
                                                    style="font-size: 1rem"
                                                    @click="adminUpdateUser(item)"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Super Admin Edit</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip :tooltipProps="{ bottom: true }">
                                        <template v-slot:activator>
                                            <span @click="openDeleteModal(item)">
                                                <font-awesome-icon
                                                    icon="fa-regular fa-trash-can"
                                                    style="font-size: 1rem"
                                                />
                                            </span>
                                        </template>

                                        <template v-slot:content>
                                            <span>Delete</span>
                                        </template>
                                    </custom-tooltip>
                                </div>
                            </template>
                        </v-data-table>

                        <!-- owner editing form -->
                        <div v-else-if="editingUser && !adminEditingUser">
                            <v-form class="form-container" ref="form" v-model="formValidity">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="userForm.firstName"
                                            :disabled="editingUser && userId > 0 && !emailVerifiedAdmin"
                                            label="First Name"
                                            :rules="[v => !!v || 'First name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="editingUser && userId > 0 && !emailVerifiedAdmin"
                                            v-model="userForm.lastName"
                                            label="Last Name"
                                            :rules="[v => !!v || 'Last name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="updatingUser"
                                            v-model="userForm.email"
                                            type="email"
                                            label="Email"
                                            :rules="emailRules"
                                            @input="lowerEmail()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <!-- {{ user }} -->
                                        <v-text-field
                                            :disabled="editingUser && userId > 0 && !emailVerifiedAdmin"
                                            v-model="userForm.phoneNo"
                                            v-mask="'###.###.####'"
                                            label="Phone Number"
                                            :hint="
                                                premiumSupport
                                                    ? 'MemoryShare may text this number if we notice a problem with a live event.'
                                                    : ''
                                            "
                                            :persistent-hint="true"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col v-if="editingUser && userId > 0" cols="6">
                                        <v-text-field
                                            label="Phone Type"
                                            v-model="readonlyUserPhonType"
                                            variant="underlined"
                                            readonly
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-alert v-if="errorMessage.length" type="error">
                                    {{ errorMessage }}
                                </v-alert>

                                <div class="mt-4">
                                    <v-btn
                                        v-if="editingUser && userId > 0 && !emailVerifiedAdmin"
                                        @click="manualshow = true"
                                        color="primary"
                                        text
                                    >
                                        Set Password</v-btn
                                    >
                                    <v-btn
                                        v-if="!emailVerifiedAdmin && userId > 0"
                                        color="blue darken-2"
                                        text
                                        @click="geninviteLink(userId)"
                                    >
                                        Copy Invite Link</v-btn
                                    >
                                    <v-btn
                                        v-if="editingUser && userId > 0 && !emailVerifiedAdmin"
                                        color="blue darken-2"
                                        text
                                        @click="openPhoneModal"
                                        >Update Phone Only</v-btn
                                    >
                                </div>
                                <div class="mt-4">
                                    <v-btn
                                        :disabled="!formValidity"
                                        @click.prevent="submitUserForm()"
                                        color="primary"
                                        >{{ updatingUser ? 'Update User' : 'Create User' }}</v-btn
                                    >

                                    <v-btn
                                        v-if="userBlocked"
                                        class="ml-2"
                                        :disabled="disableUnblock"
                                        :loading="disableUnblock"
                                        dark
                                        @click="unblockUser(userId)"
                                        >Unblock</v-btn
                                    >
                                    <v-btn
                                        v-if="$auth.role.includes('SuperAdmin') && updatingUser"
                                        :disabled="!auth0Id"
                                        @click="openAuth0()"
                                        class="ml-2"
                                        depressed
                                        >Auth0</v-btn
                                    >
                                </div>
                            </v-form>
                            <v-dialog max-width=" 400px" v-model="phoneModal">
                                <v-card class="p-3" ref="phoneForm">
                                    <v-form v-model="formValidity">
                                        <h5>Update Phone Number</h5>

                                        <v-text-field v-model="tempPhone" v-mask="'###.###.####'"></v-text-field>
                                        <v-btn :disabled="!formValidity" color="primary" @click="updatePhone()"
                                            >Update</v-btn
                                        >
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </div>

                        <!-- admin editing form  -->
                        <div v-else-if="adminEditingUser">
                            <v-form class="form-container" ref="SuperAdminform" lazy-validation>
                                <v-row>
                                    <v-col class="text-left">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dark
                                                    :color="userTypes[userType].color"
                                                    >{{ userTypes[userType].label }}</v-chip
                                                >
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    @click="newType(index)"
                                                    v-for="(item, index) in userTypes"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="adminemailVerified"
                                                    dark
                                                    color="green"
                                                    >Verified</v-chip
                                                >
                                                <v-chip v-bind="attrs" v-on="on" v-else dark color="#606060"
                                                    >Not Verified</v-chip
                                                >
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    @click="toggleVerified(item.value)"
                                                    v-for="(item, index) in verifiedValues"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                    <!-- <v-col class="text-right">
                                        <v-chip v-if="adminemailVerified" dark color="green">Verified</v-chip>
                                        <v-chip v-else dark color="#606060">Not Verified</v-chip>
                                    </v-col> -->
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-text-field disabled v-model="adminemail" label="Email"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field label="User ID" disabled v-model="userId"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="ownerId" label="Owner ID"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="funeralHomeId" label="Funeral Home ID"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field readonly v-model="auth0Id" label="Auth0Id"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col>
                                        <v-text-field v-model="usertoken" label="Token"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="firstName"
                                            label="First Name"
                                            :rules="[v => !!v || 'First name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="lastName"
                                            label="Last Name"
                                            :rules="[v => !!v || 'Last name is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <calendar label="Created:" v-model="createDate"></calendar>
                                    </v-col>
                                    <v-col>
                                        <calendar label="Invited: " v-model="inviteSentTime"></calendar>
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
                                    <v-col>
                                        <v-checkbox v-model="landLine" label="Landline"></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="landLineOverride" label="Landline Override"></v-checkbox>
                                    </v-col>
                                </v-row> -->
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="phoneType"
                                            label="Phone Type"
                                            :items="phoneTypes"
                                            item-text="label"
                                            item-value="value"
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row> </v-row>
                                <v-row> </v-row>
                                <v-row> </v-row>

                                <v-alert v-if="errorMessage.length" type="error">
                                    {{ errorMessage }}
                                </v-alert>

                                <v-row>
                                    <v-col class="mt-10">
                                        <v-btn @click.prevent="superAdminEditUser()" color="primary">{{
                                            adminEditingUser ? 'Update User' : 'Create User'
                                        }}</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row> </v-row>
                            </v-form>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!editingUser && !adminEditingUser"
                        text
                        color="blue darken-1"
                        @click="openDialog = false"
                        >Close</v-btn
                    >
                    <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="adminEditingUser ? cancelSAUserForm() : cancelUserForm()"
                        >Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="linkshow" :retain-focus="false" max-width="550">
            <v-card>
                <v-card-title class="headline" style="border-bottom: 1px solid lightgray">
                    Generated Link:
                </v-card-title>
                <div class="connection-entry" style="cursor: pointer" id="genlink" @click="copyText(linkgenerated)">
                    <div class="content" style="padding: 50px">
                        <v-row>
                            <v-text-field
                                style="width: 80%; cursor: pointer"
                                @click="copyText(linkgenerated)"
                                readonly
                                v-model="linkgenerated"
                            ></v-text-field>
                            <v-icon slot="append" class="icon">mdi-clipboard</v-icon>
                        </v-row>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="gdarken-1" text @click="linkshow = false"> Exit </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog @click:outside="cancelPasswordResetEmail" v-model="passwordResetEmailModal" max-width="500">
            <v-card>
                <v-card-title>Send Password Reset Email</v-card-title>
                <v-card-text
                    >Send password reset email to <strong>{{ selectedUser ? selectedUser.email : '' }}</strong
                    >?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="cancelPasswordResetEmail" depressed>Cancel</v-btn>
                    <v-btn
                        :loading="loading"
                        :disabled="loading"
                        @click="submitPasswordResetEmailRequest"
                        depressed
                        color="primary"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="manualshow" max-width="550">
            <v-card class="p-3">
                <v-card-title class="headline"> Manually Set Password</v-card-title>

                <v-form ref="manualPassForm" v-model="manualPassFormValid">
                    <div class="pl-6 pr-6">
                        <v-text-field
                            v-if="showManualPasword"
                            :rules="[pwRules.required, pwRules.password]"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                        <v-text-field
                            autofocus
                            v-if="!showManualPasword"
                            :rules="[pwRules.required, pwRules.password]"
                            type="password"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye-off"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                        <!-- <font-awesome-icon icon="fa-regular fa-eye-slash" />
                            <font-awesome-icon icon="fa-regular fa-eye" /> -->
                    </div>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" :disabled="!manualPassFormValid" text @click="manualUserCreation">
                        Update User
                    </v-btn>
                    <v-btn color="gdarken-1" text @click="cancelManualPassword"> Exit </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <land-line-alert @updateNumber="number => updateUserPhone(number)" ref="landlineAlert" />

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import ResetPassword from '@/components/Misc/ResetPassword.vue';
import LandLineAlert from '@/components/Misc/LandLineAlert.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { phoneTypes } from '@/constants';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    components: {
        ResetPassword,
        DatePicker,
        Calendar,
        LandLineAlert,
        CustomTooltip,
    },
    props: ['streamOwner', 'userTableType'],
    data: () => ({
        phoneTypes,
        showManualPasword: false,
        manualshow: false,
        linkshow: false,
        linkgenerated: '',
        invitelinkgen: false,
        manualPassFormValid: false,
        userTypes: [
            { label: 'Funeral Home', color: 'orange' },
            { label: 'Owner', color: 'blue' },
            { label: 'Super Admin', color: 'red' },
            { label: 'Supplier', color: 'purple' },
            { label: 'Regional Manager', color: 'green' },
        ],
        verifiedValues: [
            {
                title: 'Verified',
                value: true,
                text: 'Verified',
                color: '#06aa58',
                virtual: true,
                hidden: false,
                locked: false,
            },
            {
                title: 'Not Verified',
                value: false,
                text: 'Not Verified',
                color: '#606060',
                virtual: true,
                hidden: false,
                locked: false,
            },
        ],
        options: {
            itemsPerPage: 10,
        },
        userBlocked: false,
        manualPassword: '',
        manualUsername: '',
        auth0Id: null,
        createDate: null,
        adminemail: '',
        emailVerifiedAdmin: false,
        adminemailVerified: false,
        firstName: '',
        funeralHome: null,
        funeralHomeId: null,
        admingetuserid: null,
        inviteSentTime: null,
        lastName: '',
        ownerId: null,
        usertoken: '',
        userType: null,
        landLine: false,
        landLineOverride: false,
        phoneNo: '',
        token: '',
        superAdmin: false,
        users: [],
        snackbar: false,
        message: '',
        openDialog: false,
        loading: true,
        errorLoading: false,
        deleteModal: false,
        passwordResetModal: false,
        passwordResetEmailModal: false,
        selectedUser: {},
        search: '',
        editingUser: false,
        adminEditingUser: false,
        updatingUser: false,
        errorMessage: '',
        userId: -1,
        disableUnblock: false,
        phoneModal: false,
        tempPhone: '',
        quick: true,
        auth0loading: true,
        funeralHomeSettings: null,
        readonlyUserPhonType: '',
        userForm: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
        },
        emailRules: [
            v => !!v || 'Email is required.',
            v => v.indexOf('@') !== 0 || 'Email should have a username.',
            v => v.includes('@') || 'Email should include @ symbol.',
        ],
        formValidity: false,
        id: -1,
        headers: [
            // { text: 'Name', value: 'name', width: '30%' },
            // { text: '', value: 'blocked' },
            // { text: 'Email', value: 'email' },
            // { text: 'Phone Number', value: 'phoneNo' },
            // { text: 'Last Login', value: 'lastLogin' },
            // { text: 'Actions', value: 'actions', width: '26%', align: 'center' },
        ],
        phoneRules: [
            v => v.length === 12 || 'Please follow this format 123.456.7890',
            v => (v[3] === '.' && v[7] === '.') || 'Please follow this format 123.456.7890',
        ],
        // phoneTypes: [
        //     { label: 'Landline', value: 0 },
        //     { label: 'Mobile', value: 1 },
        //     { label: 'Mobile (Override)', value: 2 },
        // ],
        selectedPhoneType: null,
        pwRules: {
            required: value => !!value || 'Required.',
            password: value => {
                const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
                return pattern.test(value) || 'Min. 8 characters with at least one capital letter and a number.';
            },
            passwordsMatch: value => {
                return value === this.password || `Passwords don't match`;
            },
        },
    }),
    mounted() {
        // this.$auth.getIdTokenClaims().then(result => {
        //     this.token = result.__raw;
        //     this.superAdmin = true;
        //     this.getUsers();
        //     this.getUsersForSuperAdmin(this.userId);
        // });
        // this.adminEditingUser = false;

        if (this.$auth.role.includes('SuperAdmin') && this.$auth.impersonating === false) {
            this.superAdmin = true;
        }

        this.headers = [
            { text: 'Name', value: 'name', width: '30%' },
            { text: '', value: 'blocked' },
            { text: 'Phone', value: 'phoneNo' },
            { text: 'Email', value: 'email' },
            { text: 'Last Login', value: 'lastLogin' },
            { text: 'Actions', value: 'actions', width: '26%', align: 'center' },
        ];
    },
    filters: {
        dateFormat(value) {
            return moment(value).format('l');
        },
    },
    computed: {
        premiumSupport() {
            if (this.funeralHomeSettings?.doNotWatch == false) {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        postmarkDeeplink(email) {
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                return;
            }

            window.open(
                `https://account.postmarkapp.com/servers/${
                    process.env.VUE_APP_POSTMARK_SERVER_ID
                }/streams/outbound/events?f%5Brange%5D=all&f%5Bquery%5D=${decodeURI(email)}`,
                '_blank',
            );
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        openAuth0() {
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                return;
            }

            if (this.auth0Id) {
                window.open('https://manage.auth0.com/dashboard/us/midweststreams/users/' + this.auth0Id, '_blank');
            } else {
                this.showSnackbar({ message: 'Auth0 Id not found', color: 'error' });
            }
        },
        submitPasswordResetEmailRequest() {
            if (!(this.selectedUser?.id > 0)) {
                this.showSnackbar({ message: 'Invalid user id', color: 'error' });
                return;
            }

            this.loading = true;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/password-reset-email/' + this.selectedUser.id)
                .then(resp => {
                    this.showSnackbar({ message: 'Password reset email sent!', color: 'success' });
                    this.cancelPasswordResetEmail();
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error sending password reset email', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelPasswordResetEmail() {
            this.passwordResetEmailModal = false;
            this.selectedUser = null;
        },
        openPasswordResetEmailModal(item) {
            this.passwordResetEmailModal = true;
            this.selectedUser = item;
        },
        openPhoneModal() {
            this.phoneModal = true;
            this.tempPhone = this.userForm.phoneNo;
        },
        getSettings(id) {
            let settingsEndpoint = process.env.VUE_APP_API + `/funeralhomes/settings/${id}`;
            if (this.$auth.role.includes('SuperAdmin')) {
                settingsEndpoint = process.env.VUE_APP_API + `/funeralhomes/settings/admin/${id}`;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(settingsEndpoint)
                .then(resp => {
                    this.funeralHomeSettings = resp.data;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        updatePhone() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/user/phoneNumber?id=${this.userId}&phoneNumber=${this.tempPhone}`)
                .then(response => {
                    this.phoneModal = false;
                    this.showSnackbar({ message: 'User phone updated' });
                    this.userForm.phoneNo = this.tempPhone;
                    var updated = this.users.find(u => u.id === this.userId);
                    updated.phoneNo = this.tempPhone;
                })
                .catch(error => {
                    console.log(error);
                    this.phoneModal = false;
                    this.showSnackbar({ message: 'Error updating phone' });
                });
        },
        checkInput(e) {
            var isNumber = /^[0-9]$/i.test(e.key);

            if (this.userForm.phoneNo.length === 3 || this.userForm.phoneNo.length === 7) {
                if (e.key !== '.' && e.code !== 'Backspace') {
                    e.preventDefault();
                }
                return;
            } else {
                if (!isNumber && e.code !== 'Backspace') {
                    e.preventDefault();
                }
                if (this.userForm.phoneNo.length === 12 && e.code !== 'Backspace') {
                    e.preventDefault();
                }
            }
        },
        lowerEmail() {
            this.userForm.email = this.userForm.email.toLowerCase();
        },
        unblockUser(id) {
            this.disableUnblock = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/users/unblock/${id}`)
                .then(response => {
                    this.showSnackbar({ message: 'User unblocked' });
                    this.getUsers(true);
                    this.disableUnblock = false;
                    this.userBlocked = false;
                })
                .catch(error => {
                    console.log('error', error);
                    this.showSnackbar({ message: 'Error unblocking user' });
                    this.disableUnblock = false;
                });
        },
        toggleVerified(value) {
            this.adminemailVerified = value;
        },
        newType(value) {
            this.userType = value;
        },
        cancelManualPassword() {
            this.manualshow = false;
            this.manualPassword = '';
        },
        toggleShowPassword() {
            this.showManualPasword = !this.showManualPasword;
        },
        getUsers(quick) {
            if (quick) {
                this.auth0loading = true;
            }
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                var id = this.id === -1 ? this.$auth.funeralHomeId : this.id;

                if (this.$auth.impersonating) {
                    var id = this.$auth.funeralHomeId;
                }

                if (this.userTableType == 'supplier') {
                    var userEndpoint = `/users/suppliers/${id}?quick=${quick}`;
                } else {
                    var userEndpoint = `/Users?funeralHomeId=${id}&quick=${quick}`;
                }

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + userEndpoint)
                    .then(response => {
                        if (response.data) {
                            this.users = response.data;
                            if (quick) {
                                this.getUsers(false);
                            }
                            if (!quick) {
                                this.auth0loading = false;
                            }
                        }
                        this.getSettings(id);
                    })
                    .catch(error => {
                        console.log('error');
                        this.errorLoading = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        getUsersForSuperAdmin(id) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    // const id = this.id === -1 ? this.$auth.funeralHomeId : this.id;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/users/admin-get/${id}`)
                        .then(response => {
                            if (response.data) {
                                this.inviteSentTime = this.checkForUTC(response.data.inviteSentTime);

                                this.auth0Id = response.data.auth0Id;
                                this.createDate = this.checkForUTC(response.data.createDate);
                                this.adminemail = response.data.email;
                                this.adminemailVerified = response.data.emailVerified;
                                this.firstName = response.data.firstName;
                                this.funeralHome = response.data.funeralHome;
                                this.funeralHomeId = response.data.funeralHomeId;
                                this.admingetuserid = response.data.id;
                                // (this.inviteSentTime = response.data.inviteSentTime),
                                this.lastName = response.data.lastName;
                                this.ownerId = response.data.ownerId;
                                this.usertoken = response.data.token;
                                this.userType = response.data.userType;
                            }
                        })
                        .catch(error => {
                            this.errorLoading = true;
                        })
                        .finally(() => {
                            // this.loading = false;
                        });
                });
            }
        },
        updateUserPhone(number) {
            let id = this.landlineResponseId;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/user/phoneNumber?id=${id}&phoneNumber=${number}`)
                .then(response => {
                    this.showSnackbar({ message: 'Updated user' });
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    var updatedUser = this.users.find(u => u.id == this.landlineResponseId);

                    updatedUser.phoneNo = number;
                    this.cancelUserForm();
                    this.cancelSAUserForm();
                    this.getUsers(true);
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating user' });
                })
                .finally(() => {
                    this.snackbar = true;
                });
        },
        adminUpdateUser(item) {
            this.getUsersForSuperAdmin(item.id);
            this.updatingUser = false;
            this.editingUser = false;
            this.adminEditingUser = false;

            if (item) {
                this.adminEditingUser = true;
                this.userId = item.id;
                this.firstName = item.firstName;
                this.lastName = item.lastName;
                this.adminemailVerified = item.emailVerified;
                this.createDate = item.createDate;
                this.inviteSentTime = item.createDate;
                this.auth0Id = item.auth0Id;
                this.adminemail = item.email;
                this.funeralHome = item.funeralHome;
                this.funeralHomeId = item.funeralHomeId;
                this.admingetuserid = item.id;
                this.ownerId = item.ownerId;
                this.usertoken = item.token;
                this.userType = item.userType;
                this.landLine = item.landLine;
                this.landLineOverride = item.landLineOverride;
                this.phoneNo = item.phoneNo;
                this.phoneType = item.phoneType;
            }

            this.checkPhoneType();
        },
        setPhoneBools() {
            //Backend is 2 bools
            if (this.selectedPhoneType == 2) {
                this.landLineOverride = true;
                this.landLine = false;
            }

            if (this.selectedPhoneType == 1) {
                this.landLineOverride = false;
                this.landLine = false;
            }

            if (this.selectedPhoneType == 0) {
                this.landLineOverride = false;
                this.landLine = true;
            }
        },
        checkPhoneType() {
            if (this.landLineOverride == true) {
                this.selectedPhoneType = 2;
            } else {
                if (this.landLine == true) {
                    this.selectedPhoneType = 0;
                } else {
                    this.selectedPhoneType = 1;
                }
            }
        },
        initUpdateUserForm(item = null) {
            if (item) {
                this.getUsersForSuperAdmin(item.id);
            }

            this.updatingUser = false;
            this.editingUser = true;
            this.readonlyUserPhonType = '';

            if (item) {
                this.updatingUser = true;
                this.userId = item.id;
                this.emailVerifiedAdmin = item.emailVerified;
                this.userBlocked = item.blocked;
                this.auth0Id = item.auth0Id;

                this.userForm.firstName = item.firstName;
                this.userForm.lastName = item.lastName;
                this.userForm.email = item.email;
                this.userForm.phoneNo = item.phoneNo ? item.phoneNo : '';
                this.readonlyUserPhonType = item.landLineOverride || !item.landLine ? 'Mobile' : 'Landline';
            }
        },
        manualUserCreation() {
            let data = {
                password: this.manualPassword,
            };

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/users/create-admin-manual/${this.userId}`, data)
                    .then(response => {
                        var updatedUser = this.users.find(u => u.id == this.userId);
                        updatedUser.emailVerified = true;
                        this.manualshow = false;
                        this.message = 'User Created';
                        this.cancelSAUserForm();
                        this.cancelUserForm();
                    })
                    .catch(error => {
                        //Alert user if error is password error
                        const passwordError = 'PasswordStrengthError: Password is too weak';
                        if (error.response.data.Message == passwordError) {
                            const errorMessage = passwordError.substring(passwordError.indexOf(':') + 1).trim();
                            this.showSnackbar({ message: errorMessage, color: 'error' });
                        } else {
                            this.showSnackbar({ message: 'Unable to create user', color: 'error' });
                        }
                    });
            });
        },
        superAdminEditUser() {
            // /api/users/admin-edit
            this.errorMessage = '';

            var updatedUser = this.users.find(u => u.id === this.userId);
            updatedUser.phoneNo = this.userForm.phoneNo;

            if (updatedUser.emailVerified === false) {
                this.showSnackbar({ message: 'Please verify this account before making changes' });
                return;
            }

            if (this.superAdmin) {
                this.setPhoneBools();

                let data = {
                    // auth0Id: this.auth0Id,
                    id: this.admingetuserid,
                    email: this.adminemail,
                    auth0Id: this.auth0Id,
                    ownerId: this.ownerId,
                    funeralHomeId: this.funeralHomeId,
                    createDate: this.createDate,
                    emailVerified: this.adminemailVerified,
                    firstName: this.firstName,
                    inviteSentTime: this.inviteSentTime,
                    lastName: this.lastName,
                    userType: this.userType,
                    landLine: this.landLine,
                    landLineOverride: this.landLineOverride,
                    phoneNo: this.phoneNo,
                    phoneType: this.phoneType,
                };

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + '/users/admin-edit', data)
                    .then(response => {
                        this.message = 'User Updated';
                        var updatedUser = this.users.find(u => u.id == this.userId);
                        updatedUser.emailVerified = this.adminemailVerified;
                        this.cancelSAUserForm();
                        this.cancelUserForm();
                        this.getUsers(true);
                    })
                    .catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data.Message,
                        });
                    })
                    .finally(() => {
                        this.snackbar = true;
                    });
            }
        },
        copyText(text) {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.showSnackbar({ message: 'Copied to clipboard!' });
        },
        geninviteLink(id) {
            this.errorMessage = '';
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/users/invite-link/?id=${id}`)
                .then(response => {
                    this.linkshow = true;
                    this.linkgenerated = response.data.authLink;
                    this.message = 'Invite link generated!';
                    this.invitelinkgen = true;
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                })
                .finally(() => {
                    this.snackbar = false;
                });
        },
        submitUserForm() {
            this.errorMessage = '';

            this.updatingUser ? this.updateUser() : this.inviteUser();
        },
        updateUser() {
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                id: this.userId,
                phoneNo: this.userForm.phoneNo,
            };

            if (this.userTableType == 'supplier') {
                data.userType = 3;
                data.supplierId = this.id;
                data.funeralHomeId = null;
            }

            var updatedUser = this.users.find(u => u.id === this.userId);
            updatedUser.phoneNo = this.userForm.phoneNo;

            if (updatedUser.emailVerified === false) {
                this.showSnackbar({ message: 'Please verify this account before making changes' });
                return;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + '/users/', data)
                .then(response => {
                    this.message = 'User Updated';
                    if (response.data.landLine == true) {
                        this.$refs.landlineAlert.dialog = true;
                        this.landlineResponseId = response.data.id;
                    }
                    this.cancelUserForm();
                    this.cancelSAUserForm();
                    this.getUsers(true);
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                })
                .finally(() => {
                    this.snackbar = true;
                });
        },
        inviteUser() {
            const id = this.id === -1 ? this.$auth.funeralHomeId : this.id;

            // new user
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                email: this.userForm.email.toLowerCase(),
                funeralHomeId: id,
                phoneNo: this.userForm.phoneNo,
            };

            if (this.userTableType == 'supplier') {
                data.userType = 3;
                data.supplierId = id;
                data.funeralHomeId = null;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/invite', data)
                .then(response => {
                    this.message = 'User created';
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    this.cancelUserForm();
                    this.cancelSAUserForm();
                    this.getUsers(true);
                })
                .catch(error => {
                    if (
                        error.response.data.Message &&
                        error.response.data.Message.toLowerCase() == 'user already exists'
                    ) {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            html: `<u style="color: #2778c4">${data.email}</u> is already associated to a different MemoryShare account.<br><br><a href='http://help.memoryshare.com/en/articles/8853160-user-already-exists' target='_blank' style="color: #2778c4 !important">Read More</a>`,
                        });
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data.Message,
                        });
                    }

                    console.log(error, 'error');
                    // this.showSnackbar({ message: error.response.data.Message, color: 'error' });
                });
        },
        cancelUserForm() {
            this.editingUser = false;
            this.updatingUser = false;

            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userForm.phoneNo = '';
            this.userId = -1;
        },
        cancelSAUserForm() {
            this.auth0Id = '';
            this.errorMessage = '';
            this.editingUser = false;
            this.updatingUser = false;
            this.adminEditingUser = false;

            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userForm.phoneNo = '';
            this.userId = -1;

            // this.userForm.firstName = '';
            // this.userForm.lastName = '';
            // this.userForm.email = '';
            // this.userId = -1;
        },
        resendEmailInvite(user) {
            if (user.emailVerified) {
                this.snackbar = true;
                this.message = `${user.email} has already verified their email address`;
                return;
            }

            this.$auth.getIdTokenClaims().then(result => {
                let token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` } })
                    .post(process.env.VUE_APP_API + '/users/resend?id=' + user.id)
                    .then(response => {
                        if (response.data) {
                            this.snackbar = true;
                            this.message = `Invite resent to ${user.email}`;
                        }
                    })
                    .catch(error => {
                        this.snackbar = true;
                        this.message = `Error sending to ${user.email}`;
                    });
            });
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.deleteModal = true;
        },
        openPasswordResetModal(item) {
            this.selectedUser = item;
            this.passwordResetModal = true;
        },
        deleteUser() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/Users/${this.selectedUser.id}`)
                .then(response => {
                    this.message = `${this.selectedUser.firstName} ${this.selectedUser.lastName} deleted!`;
                    this.loading = true;
                    this.getUsers(true);
                })
                .catch(error => {
                    this.message = `Error deleting user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`;
                })
                .finally(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
        updateStreamOwner(item) {
            this.$emit('newowner', item);
        },
    },
    watch: {
        id: function () {
            if (this.id !== -1 || this.$auth.funeralHomeId > 0) {
                this.loading = true;
                this.getUsers(true);
                this.auth0loading = true;
            }
        },
        openDialog() {
            this.editingUser = false;
            this.adminEditingUser = false;
            this.auth0Id = '';
        },
        manualshow(val) {
            if (!val) {
                this.$refs.manualPassForm.reset();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#genlink :hover {
    background-color: rgba(211, 211, 211, 0);
}
.icon {
    margin: 0 0.5rem;
}

.action-list {
    display: flex;
    justify-content: center;
    gap: 10px;
}
</style>

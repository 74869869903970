var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.events,"server-items-length":_vm.totalEvents,"loading":_vm.loading,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        },"loading-text":"Getting events..."},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openDetails},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$moment.utc(item.createDate).local().format('MM/DD/YYYY')))])]}},{key:"item.funeralHomeName",fn:function(ref){
        var item = ref.item;
return [(item.testService)?_c('v-chip',{attrs:{"label":"","x-small":"","color":"#3782db","dark":""}},[_vm._v("TEST")]):_vm._e(),_c('div',[_vm._v(_vm._s(item.funeralHomeName))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.tributeStatuses[item.status].color}},[_vm._v(_vm._s(_vm.tributeStatuses[item.status].label))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <div class="attached-menu-container">
            <v-menu :ref="componentId" :attach="`#${componentId}`" v-bind="menuProps">
                <template #activator="{ on, attrs }">
                    <span :id="componentId" v-bind="attrs" v-on="on">
                        <slot name="activator"></slot>
                    </span>
                </template>
                <template #default>
                    <slot name="content"></slot>
                </template>
            </v-menu>
        </div>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    data() {
        return {
            componentId: 'menu-' + uuidv4(),
        };
    },
    methods: {
        blur() {
            if (this.$refs[this.componentId]) {
                this.$refs[this.componentId].save();
            }
        },
    },
    computed: {
        menuProps() {
            const { ...restProps } = this.$attrs;
            return restProps;
        },
    },
};
</script>
<style lang="scss" scoped>
.attached-menu-container {
    position: relative;
}
</style>

<template>
    <div>
        <!-- <span v-if="logos && ready" @click="deleteLogo" class="delete-btn">
            <font-awesome-icon icon="fa-regular fa-circle-x" />
        </span> -->
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />

        <!-- <img v-if="logoSrc && ready" :src="logoSrc" class="product-img mt-2" /> -->

        <div class="image-uploader">
            <div class="image-container" v-if="productImages.length > 0">
                <div v-for="image in productImages">
                    <span @click="deleteImage(image)" class="delete-btn">
                        <font-awesome-icon icon="fa-regular fa-circle-x" />
                    </span>
                    <div class="product-img">
                        <img :src="image" />
                    </div>
                </div>
            </div>

            <div class="text-center p-2">
                <v-btn color="blue-grey" :loading="!Boolean(uppy)" class="white--text" @click="handleOpen">
                    <span>{{ productImages.length > 0 ? 'Add' : 'Upload' }} Images</span>
                    <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-up-from-bracket" />
                </v-btn>
                <v-btn v-if="productImages.length > 0" class="m-2" color="error" @click="deleteAllImages"
                    >Delete All</v-btn
                >
            </div>
        </div>

        <!-- <v-dialog v-model="excessImageModal" max-width=600>
            <v-card class="p-2">

            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';

export default {
    name: 'LogoUpload',
    props: ['productId'],
    data() {
        return {
            id: 'Dashboard',
            isOpen: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                note: '1MB file size limit',
            },
            uppy: null,
            // logoSrc: '',
            productImages: [],
            excessImageModal: false,
            // ready: true,
        };
    },
    watch: {
        async productId() {
            this.productImages = await this.getImageSrc();
        },
    },
    components: {
        DashboardModal,
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.productImages = await this.getImageSrc();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        deleteImage(image) {
            this.axiosInstance
                .delete(`products/image/${this.productId}`, {
                    params: { url: String(image) },
                })
                .then(response => {
                    this.showSnackbar({ message: 'Image Successfully Deleted' });
                    this.productImages = response.data.images;
                    this.$emit('img-success');
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error Deleting Image' });
                    console.log(error);
                });
        },
        deleteAllImages() {
            return this.axiosInstance
                .delete(`products/all-images/${this.productId}`)
                .then(response => {
                    this.showSnackbar({ message: 'Images Successfully Deleted' });
                    this.productImages = [];
                    this.uppy.cancelAll();
                    this.$emit('img-success');
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error Deleting Image' });
                    console.log(error);
                });
        },

        getImageSrc() {
            this.$emit('refresh', null);
            return this.axiosInstance
                .get(`products/image/${this.productId}`)
                .then(response => {
                    if (response.data.images) {
                        return response.data.images;
                    } else {
                        return [];
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async getUploadUrl(file) {
            const productId = this.productId;
            return this.axiosInstance
                .get(`products/image/sas/${productId}/${file.name}`)
                .then(response => {
                    log('==== getUploadUrl() called ====', 'silver');
                    'response.data', response.data;
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async initUppy() {
            const MAX_FILE_SIZE = 1000000; // 5 MB
            const _ = this;

            this.uppy = new Uppy({
                id: 'logo-upload',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 8,
                    allowedFileTypes: ['image/*'],
                },
            }).use(UppyAddUploaderPlugin, {
                uploader: fileIds => {
                    var imgList = [];
                    if (fileIds.length + this.productImages.length > 8) {
                        this.showSnackbar({ message: 'Products cannot have more than 8 images total', color: 'error' });
                        return;
                    } else {
                        // await promise for each image upload
                        const promiseLoop = fileIds.map(image => {
                            return new Promise(async (resolve, reject) => {
                                var file = this.uppy.getFile(image);
                                var { sas: uploadEndpoint, fileName } = await this.getUploadUrl(file);
                                imgList.push(fileName);
                                var reader = new FileReader();
                                reader.readAsArrayBuffer(file.data);
                                reader.addEventListener('error', error => {});
                                reader.addEventListener('load', async () => {
                                    try {
                                        await this.axios
                                            .create({
                                                headers: {
                                                    'Content-Type': file.type,
                                                    'x-ms-blob-type': 'BlockBlob',
                                                },
                                            })
                                            .put(uploadEndpoint, reader.result);
                                        resolve('done');
                                    } catch (error) {
                                        console.error(error);
                                        reject(error);
                                    }
                                });
                            });
                        });
                        Promise.allSettled(promiseLoop).then(response => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    await this.axiosInstance.post(`/products/image/${this.productId}`, imgList);
                                    this.productImages = await this.getImageSrc();
                                    this.handleClose();
                                    this.showSnackbar({ message: 'Successfully uploaded image' });
                                    this.$emit('img-success');
                                    this.uppy.cancelAll();
                                    resolve('done');
                                } catch (error) {
                                    reject(error);
                                }
                            });
                        });
                    }
                },
            });
        },

        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.image-uploader {
    border: 1px solid #989898;
    border-radius: 5px;
}
.image-container {
    padding: 1rem;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
.product-img {
    max-width: 100px;
    margin: 1rem;
    height: 100px;
    margin: 0;
}
.delete-btn {
    z-index: 2;
    cursor: pointer;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    background-color: white;
    position: relative;
    top: 30px;
}
img {
    max-width: 100%;
    max-height: 100%;
}
</style>

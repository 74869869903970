import { Line, mixins } from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';
const { reactiveProp } = mixins;

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
        // Add Annotation Plugin
        this.addPlugin([chartjsPluginAnnotation]);
        this.renderChart(this.chartData, this.options);
        // console.log('chartjs', this);
    },
};

<template>
    <div>
        <v-dialog @click:outside="$emit('close')" :eager="eager" :max-width="maxWidth" v-model="internalValue">
            <v-card>
                <div class="pl-3 pr-3 decorated-title">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container title">
                            <slot name="title"></slot>
                        </div>
                        <div class="img-container">
                            <img
                                :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')"
                                alt="MemoryShare Icon"
                            />
                        </div>
                    </v-card-title>
                </div>

                <div class="body p-3">
                    <slot name="body"></slot>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            internalValue: this.value,
        };
    },
    watch: {
        value(newVal) {
            this.internalValue = newVal;
        },
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '600px',
        },
        eager: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
// .title {
//     border: 2px solid lime;
// }

// .body {
//     border: 2px solid blue;
// }

.decorated-title {
    color: white;
    background-color: #0d3d60;

    .text-container {
        width: 80%;
        word-break: break-word;
    }
    .img-container {
        width: 20%;
        max-width: 50px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
</style>

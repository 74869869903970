<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="elevation-3 p-4 bg-white rounded">
            <div class="header">
                <h3 class="title">
                    <font-awesome-icon icon="fa-regular fa-house" style="font-size: 1rem" />
                    {{ title }}
                </h3>
            </div>

            <v-form class="form-container" ref="form" lazy-validation>
                <v-btn
                    class="mb-5"
                    v-if="isDevEnv === 'development' && $auth.role.includes('SuperAdmin')"
                    @click="autofillForm"
                    >Autofill form</v-btn
                >
                <v-text-field
                    v-model="name"
                    :rules="[
                        v => !!v || 'Name is required',
                        v => v.length <= 150 || 'Max 150 characters',
                        v => !this.containsEmoji(v) || 'Name should not contain emojis',
                    ]"
                    label="Funeral Home Name"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="address1"
                    :rules="[v => !!v || 'State is required']"
                    label="Address 1"
                    required
                ></v-text-field>
                <v-text-field v-model="address2" label="Address 2" required></v-text-field>

                <v-row class="mt-0">
                    <v-col>
                        <v-text-field
                            v-model="city"
                            :rules="[v => !!v || 'State is required']"
                            label="City"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col>
                        <v-select
                            v-if="country == 'US'"
                            attach=""
                            v-model="state"
                            :items="usStates"
                            item-text="label"
                            item-value="value"
                            :rules="[v => !!v || 'State is required']"
                            label="State"
                        ></v-select>
                        <v-select
                            v-else-if="country == 'CA'"
                            attach=""
                            v-model="state"
                            :items="candaianProvinces"
                            item-text="name"
                            item-value="abbreviation"
                            :rules="[v => !!v || 'State is required']"
                            label="Province"
                        ></v-select>

                        <v-text-field
                            v-else
                            v-model="state"
                            label="State"
                            :rules="[v => !!v || 'State is required']"
                        ></v-text-field>
                    </v-col>

                    <v-col>
                        <v-text-field
                            :rules="[v => !!v || 'Zipcode is required']"
                            v-model="zipCode"
                            label="Zip"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-autocomplete
                    attach=""
                    label="Country"
                    :items="countries"
                    item-text="name"
                    item-value="abbreviation"
                    v-model="country"
                    :rules="[v => !!v || 'Country is required']"
                ></v-autocomplete>

                <!-- e-mail validation rules reference
                    https://blog.logrocket.com/how-to-implement-form-validation-with-vuetify-in-a-vue-js-app/
                     -->

                <v-text-field
                    v-model="email"
                    :rules="[
                        v => !!v || 'Email is required',
                        v =>
                            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                v,
                            ) || 'E-mail must be valid',
                    ]"
                    label="Billing Email"
                    type="email"
                    required
                ></v-text-field>

                <v-row class="mt-0">
                    <v-col>
                        <v-select
                            :disabled="this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')"
                            attach=""
                            v-model="ownerId"
                            :items="OwnerIdList"
                            item-text="name"
                            item-value="id"
                            label="Owner Id"
                            :rules="[v => !!v || 'Owner is required', v => v > 0 || 'Owner ID cannot be 0']"
                        ></v-select>
                    </v-col>
                    <v-col v-if="!$auth.role.includes('FuneralHome') && editing">
                        <v-select
                            attach=""
                            v-model="rmUserId"
                            :items="rmList"
                            item-text="text"
                            item-value="val"
                            label="Regional Manager Id"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-text-field
                    v-model="primaryContact"
                    :rules="[v => !!v || 'Primary Contact is required']"
                    label="Primary Contact Name"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="primaryContactPhoneNumber"
                    v-mask="'###.###.####'"
                    label="Primary Contact Phone"
                    :rules="[
                        v => !!v || 'Funeral Home Number is required',
                        v => v.length == 12 || 'Please include a valid phone number',
                    ]"
                    type="phone"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="primaryContactEmail"
                    :rules="[
                        v => !!v || 'Email is required',
                        v =>
                            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                v,
                            ) || 'E-mail must be valid',
                    ]"
                    label="Primary Contact Email"
                    type="email"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="funeralHomeNumber"
                    v-mask="'###.###.####'"
                    :rules="[
                        v => !!v || 'Funeral Home Number is required',
                        v => v.length == 12 || 'Please include a valid phone number',
                    ]"
                    type="phone"
                    label="Funeral Home Number"
                    required
                ></v-text-field>

                <custom-tooltip :tooltipProps="{ bottom: true, maxWidth: '350px', nudgeTop: 20 }">
                    <template v-slot:activator>
                        <div style="display: flex; gap: 1rem; align-items: center">
                            <calendar
                                v-if="isDevEnv === 'development' && $auth.role.includes('SuperAdmin')"
                                v-model="testClock"
                                label="Stripe Test Clock"
                                datetime
                                :output-local="true"
                            />
                            <v-btn
                                v-if="isDevEnv === 'development' && $auth.role.includes('SuperAdmin')"
                                type="button"
                                @click.stop="testClock = null"
                                plain
                                small
                                color="primary"
                            >
                                Clear
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:content>
                        <span
                            >To make testing easier Stripe has a concept of Test Clocks. This allows us to "play with
                            time". So instead of creating a bunch of overages, and having to wait 30 days to see how the
                            billing engine performs, we can advance time in Stripe and see this immediately.</span
                        >
                    </template>
                </custom-tooltip>
                <v-text-field
                    v-model="website"
                    type="url"
                    label="Website Link"
                    :rules="[
                        v => !!v || 'Valid link is required',
                        v =>
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
                                v,
                            ) || 'Url must be valid',
                    ]"
                ></v-text-field>

                <v-autocomplete
                    attach=""
                    v-model="timezone"
                    :items="timezoneOptions"
                    item-value="id"
                    item-text="displayName"
                    :menu-props="{ closeOnClick: true }"
                    label="Timezone"
                    :rules="[v => !!v || 'Timezone is required']"
                ></v-autocomplete>

                <v-checkbox
                    v-if="$auth.role.includes('SuperAdmin')"
                    v-model="devHome"
                    label="*Dev Home (Will be ignored from analytics, and billing)"
                    style="width: 450px"
                ></v-checkbox>

                <div class="text-right mt-10 mb-6">
                    <button @click.prevent="saveHome($data)" class="btn btn-primary ml-3 save-btn">Save Home</button>
                </div>
            </v-form>
            <Spinner v-if="loading"></Spinner>
        </div>
    </AppLayout>
</template>

<script>
import Spinner from '@/components/ui/Spinner';
import { mapActions } from 'vuex';
import DatePicker from '@/components/ui/DatePicker.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { usStates, countries, candaianProvinces } from '@/constants';
import emojiRegex from 'emoji-regex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export default {
    metaInfo: {
        title: 'Manage Homes',
    },
    data() {
        return {
            usStates,
            timezoneOptions: [],
            timezone: null,
            loading: false,
            editing: false,
            token: '',
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            email: '',
            ownerId: null,
            primaryContact: '',
            primaryContactPhoneNumber: '',
            primaryContactEmail: '',
            funeralHomeNumber: '',
            website: null,
            devHome: false,
            OwnerIdList: [],
            requiredRules: [v => !!v || 'This field is required'],
            isDevEnv: process.env.VUE_APP_ENV,
            testClock: null,
            rmList: [],
            rmUserId: null,
            countries,
            candaianProvinces,
            country: '',
        };
    },
    mounted() {
        if (this.$route.params.ownerId) {
            this.ownerId = parseInt(this.$route.params.ownerId);
        }

        if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
            this.OwnerIdList = [{ id: this.$auth.session.owner.id, name: this.$auth.session.owner.name }];
            this.ownerId = this.$auth.session.owner.id;
        }
        this.getTimezoneOptions()
            .then(() => {
                if (this.$route.params.id) {
                    this.loading = true;
                    this.editing = true;

                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.getOwners().then(() => {
                            this.getHome(this.$route.params.id);
                        });
                    }
                } else {
                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.getOwners();
                        this.loading = true;
                    }
                }
            })
            .catch(() => {
                this.showSnackbar({ message: 'Error Loading Timezones' });
            });
    },
    watch: {
        ownerId() {
            this.getRMs();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        containsEmoji(string) {
            const regex = emojiRegex();
            return regex.test(string);
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        saveHome(payload) {
            let valid = this.$refs.form.validate();
            if (this.ownerId == 0) {
                this.showSnackbar({ message: 'Owner ID cannot be 0' });
                return;
            }

            if (valid) {
                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    email: this.email,
                    ownerId: this.ownerId,
                    primaryContact: this.primaryContact,
                    primaryContactEmail: this.primaryContactEmail,
                    primaryContactPhoneNumber: this.primaryContactPhoneNumber,
                    funeralHomeNumber: this.funeralHomeNumber,
                    website: this.website,
                    devHome: this.devHome,
                    testClock: this.testClock,
                    timeZone: this.timezone,
                    rmUserId: this.rmUserId ? this.rmUserId : null,
                    country: this.country,
                };

                // temp
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    let query = process.env.VUE_APP_API + '/FuneralHomes';

                    if (this.editing) {
                        query = `${query}/${this.$route.params.id}`;

                        this.axios
                            .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                            .put(query, data)
                            .then(response => {
                                this.showSnackbar({ message: `${this.name} successfully updated` });
                                this.$router.push({ name: 'Homes' });
                            })
                            .catch(error => {
                                this.showSnackbar({ message: 'Error! Please try again' });
                            });
                    } else {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                            .post(query, data)
                            .then(response => {
                                this.showSnackbar({ message: `${this.name} successfully added` });
                                this.$router.push({ path: `/homes/settings/${response.data.id}?tab=settings` });
                            })
                            .catch(error => {
                                this.showSnackbar({ message: 'Error! Please try again' });
                            });
                    }
                });
            } else {
                this.showSnackbar({ message: 'Error! Please fix form errors' });
            }
        },
        getRMs() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                let id = this.ownerId;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/users/get-regional-manager/${id}`)
                    .then(response => {
                        this.rmList = response.data.map(rm => {
                            return {
                                text: rm.firstName + rm.lastName,
                                val: rm.id,
                            };
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        getOwners() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/Owners`)
                        .then(response => {
                            this.OwnerIdList = response.data.owners.reverse();

                            this.loading = false;
                            resolve();
                        })
                        .catch(error => {
                            this.loading = false;
                            reject();
                        });
                });
            });
        },
        getHome(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/FuneralHomes/${id}`)
                    .then(response => {
                        this.loading = false;
                        let result = response.data;

                        (this.name = result.funeralHome.name),
                            (this.address1 = result.funeralHome.address1),
                            (this.address2 = result.funeralHome.address2),
                            (this.city = result.funeralHome.city),
                            (this.state = result.funeralHome.state),
                            (this.zipCode = result.funeralHome.zipCode),
                            (this.email = result.funeralHome.email),
                            (this.ownerId = result.funeralHome.ownerId),
                            (this.primaryContact = result.funeralHome.primaryContact),
                            (this.primaryContactEmail = result.funeralHome.primaryContactEmail),
                            (this.primaryContactPhoneNumber = result.funeralHome.primaryContactPhoneNumber),
                            (this.devHome = result.funeralHome.devHome);
                        this.website = result.funeralHome.website;
                        this.rmUserId = result.funeralHome.rmUserId;
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error! Please try again' });
                    });
            });
        },
        autofillForm() {
            //Random Word API down at this time
            // this.loading = true;
            // this.axios
            //     .get('https://random-word-api.herokuapp.com/word?number=1')
            //     .then(res => {
            //         this.name = res.data[0] + randomNumber(1, 500).toString();
            //         this.address1 = 'Address Line 1';
            //         this.address2 = 'Address Line 2';
            //         this.city = 'Test City';
            //         this.state = 'TX';
            //         this.zipCode = 'Test Zip';
            //         this.email = 'testemail@a9djwnaidn2.com';
            //         this.primaryContact = 'Jeff';
            //         this.primaryContactEmail = 'testemail@a9djwnaidn2.com';
            //         this.primaryContactPhoneNumber = '1231231234';
            //         this.funeralHomeNumber = '1231231234';
            //         this.website = 'www.example.com';
            //         this.ownerId = this.OwnerIdList[0].id ? this.OwnerIdList[0].id : '';
            //         this.timezone = 'Central Standard Time';
            //     })

            //     .catch(error => {
            //         console.error(error);
            //     })
            //     .finally(() => {
            //         this.loading = false;
            //     });

            this.address1 = 'Address Line 1';
            this.address2 = 'Address Line 2';
            this.city = 'Dallas';
            this.state = 'TX';
            this.zipCode = '75001';
            this.email = 'testemail@a9djwnaidn2.com';
            this.primaryContact = 'Jeff';
            this.primaryContactEmail = 'testemail@a9djwnaidn2.com';
            this.primaryContactPhoneNumber = '123.123.1234';
            this.funeralHomeNumber = '123.123.1234';
            this.website = 'www.example.com';
            this.ownerId = this.OwnerIdList[0].id ? this.OwnerIdList[0].id : '';
            this.timezone = 'Central Standard Time';
            this.country = 'US';
        },
        getTimezoneOptions() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/dropdown/timezones`)
                        .then(response => {
                            let result = response.data;
                            this.timezoneOptions = this.sortTimezones(result);

                            resolve(result);
                        })
                        .catch(error => {
                            reject();
                        });
                });
            });
        },
        sortTimezones(data) {
            const first = data.filter(e => e.displayName.includes('US'));
            const next = data.filter(
                e =>
                    e.displayName.includes('Arizona') ||
                    e.displayName.includes('Hawaii') ||
                    e.displayName.includes('Alaska'),
            );
            const rest = data.filter(
                e =>
                    !e.displayName.includes('US') &&
                    !e.displayName.includes('Arizona') &&
                    !e.displayName.includes('Hawaii') &&
                    !e.displayName.includes('Alaska'),
            );
            return first.concat(next, rest);
        },
    },
    components: {
        Spinner,
        DatePicker,
        Calendar,
        CustomTooltip,
    },
    computed: {
        title() {
            return this.editing ? `Editing ${this.name}` : 'Create New Home';
        },
    },
};
</script>

<style lang="scss" scoped>
.row {
    margin-top: 1.5rem;
}

.custom-checkbox {
    margin-top: 1rem;
}

.save-btn {
    width: 40%;
}

.form-group {
    margin-top: 2rem;
}
</style>

<template>
    <v-dialog max-width="400px" v-model="showDialog">
        <template v-slot:activator="{ on, attrs }">
            <div
                class="p-2 rounded-circle secondary-btn d-flex justify-content-center mr-2"
                style="width: 30px"
                v-bind="attrs"
                v-on="on"
            >
                <font-awesome-icon style="font-size: 0.9rem" icon="fa-regular fa-pencil" />
            </div>
        </template>
        <v-card class="p-4">
            <v-card-title class="pl-0 pt-1">{{ title }}</v-card-title>
            <v-text-field label="Name" v-model="newKeywordName"></v-text-field>
            <div class="text-right">
                <v-btn @click="submit" class="bg-primary text-white">Save</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            newKeywordName: this.inputValue,
            showDialog: false,
        };
    },
    props: ['title', 'inputValue'],
    methods: {
        submit() {
            this.$emit('save', this.newKeywordName);
            this.showDialog = false;
        },
    },
    emits: ['save'],
};
</script>

<template>
    <div style="height: 100%">
        <div class="onboard-qr-print-action">
            <div class="text-left action-title">
                <h4>Print Out</h4>
                <span>Upload or Scan Photos from your mobile device using the MemoryShare App.</span>
            </div>

            <div class="action-inner">
                <div class="action-inner-upper">
                    <div class="qr-design-container">
                        <div class="horizontal-bg-bar">
                            <!-- START: Large Qr -->
                            <v-avatar class="qr-circle-outer large-pdf-design" size="155" color="#ab0658">
                                <v-avatar class="qr-circle-middle" size="130" color="#FFFFFF">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <qrcode-vue
                                            v-if="tributeVideo.familyLink"
                                            style="width: 80px; height: 80px"
                                            :value="tributeVideo.familyLink"
                                            size="80"
                                            render-as="svg"
                                        />
                                    </div>
                                </v-avatar>
                            </v-avatar>
                            <!-- END: Large Qr -->

                            <!-- START: Medium QR -->
                            <v-avatar class="qr-circle-outer medium-pdf-design" size="130" color="#ab0658">
                                <v-avatar class="qr-circle-middle" size="110" color="#FFFFFF">
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <qrcode-vue
                                            v-if="tributeVideo.familyLink"
                                            style="width: 70px; height: 70px"
                                            :value="tributeVideo.familyLink"
                                            size="70"
                                            render-as="svg"
                                        />
                                    </div>
                                </v-avatar>
                            </v-avatar>
                            <!-- END: Medium QR -->

                            <!-- START: Small QR -->
                            <v-avatar class="qr-circle-outer small-pdf-design" size="100" color="#ab0658">
                                <v-avatar class="qr-circle-middle" size="80" color="#FFFFFF">
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <qrcode-vue
                                            v-if="tributeVideo.familyLink"
                                            style="width: 50px; height: 50px"
                                            :value="tributeVideo.familyLink"
                                            size="50"
                                            render-as="svg"
                                        />
                                    </div>
                                </v-avatar>
                            </v-avatar>
                            <!-- END: Small QR -->
                        </div>
                    </div>

                    <h5 class="action-cta font-weight-bold">Scan to get started</h5>
                </div>
                <div class="btn-wrap">
                    <v-btn depressed @click="openQrModal">
                        Print QR
                        <font-awesome-icon class="ml-2" icon="fa-regular fa-print" />
                    </v-btn>
                </div>

                <div v-if="false" class="col-container">
                    <div class="pdf-wrap action-wrap">
                        <div class="text-left d-flex flex-column">
                            <h4 class="uploader-title">Print Out</h4>

                            <span class="uploader-body"
                                >Upload or Scan Photos form your mobile device using the MemoryShare App.</span
                            >
                        </div>
                        <div class="action-content p-3">
                            <div class="content-upper">
                                <!-- START: small pdf design -->
                                <div class="qr-design-wrap">
                                    <div class="horizontal-bg-bar">
                                        <!-- START: Large Qr -->
                                        <v-avatar class="qr-circle-outer large-pdf-design" size="155" color="#ab0658">
                                            <v-avatar size="130" color="#FFFFFF">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <qrcode-vue
                                                        v-if="tributeVideo.familyLink"
                                                        style="width: 80px; height: 80px"
                                                        :value="tributeVideo.familyLink"
                                                        size="80"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Large Qr -->

                                        <!-- START: Medium QR -->
                                        <v-avatar class="qr-circle-outer medium-pdf-design" size="130" color="#ab0658">
                                            <v-avatar size="110" color="#FFFFFF">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center"
                                                >
                                                    <qrcode-vue
                                                        v-if="tributeVideo.familyLink"
                                                        style="width: 70px; height: 70px"
                                                        :value="tributeVideo.familyLink"
                                                        size="70"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Medium QR -->

                                        <!-- START: Small QR -->
                                        <v-avatar class="qr-circle-outer small-pdf-design" size="100" color="#ab0658">
                                            <v-avatar size="80" color="#FFFFFF">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center"
                                                >
                                                    <qrcode-vue
                                                        v-if="tributeVideo.familyLink"
                                                        style="width: 50px; height: 50px"
                                                        :value="tributeVideo.familyLink"
                                                        size="50"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Small QR -->
                                    </div>
                                </div>
                                <h5 class="action-cta">Scan to get started</h5>
                            </div>
                            <!-- END: small pdf design -->

                            <div class="btn-wrap">
                                <button class="btn-ghost" @click="openQrModal">
                                    Print QR
                                    <font-awesome-icon class="ml-2" icon="fa-regular fa-print" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QrPrintoutTypeModal
            v-show="qrTypeModalVisible"
            v-model="qrTypeModalVisible"
            :service="service"
            :tribute-video="tributeVideo"
        />
    </div>
</template>
<script>
import QrPrintoutTypeModal from '@/components/ManageService/Tribute/QrPrintoutTypeModal.vue';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';
import QrcodeVue from 'qrcode.vue';

export default {
    data() {
        return {
            qrTypeModalVisible: false,
        };
    },
    props: {
        service: {
            type: Object,
            default: () => {},
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        QrPrintoutTypeModal,
        QrcodeVue,
        QrPrintout,
    },
    methods: {
        openQrModal() {
            this.qrTypeModalVisible = true;
        },
    },
};
</script>
<style lang="scss">
.onboard-qr-print-action {
    container-type: inline-size;
    container-name: action;
    min-height: 550px;
    background-color: #f8f8f8;
    padding: 32px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 250px;
    height: 100%;

    .action-inner {
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        height: 350px;
        padding: 16px;
    }

    .action-inner-upper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .qr-design-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .horizontal-bg-bar {
        border: 5px solid #ab0658;
        background-color: #ab0658;
        position: relative;
        width: 80%;
        max-width: 250px;
        border-radius: 20px;
        height: 100px;
    }

    .qr-circle-outer,
    .qr-circle-middle {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .large-pdf-design,
    .medium-pdf-design,
    .small-pdf-design {
        display: none;
    }

    @container action (max-width: 239px) {
        .horizontal-bg-bar {
            height: 75px;
        }

        .small-pdf-design {
            display: block;
        }
    }

    @container action (min-width: 240px) and (max-width: 280px) {
        .medium-pdf-design {
            display: block;
        }
    }

    @container action (min-width: 281px) {
        .large-pdf-design {
            display: block;
        }
    }
}
</style>

<template>
    <div class="countdown-timer">
        {{ formattedTime }}
    </div>
</template>

<script>
export default {
    name: 'CountdownTimer',
    props: {
        initialTime: {
            type: Number,
            required: true,
        },
        outputFormat: {
            type: String,
            default: 'timestamp',
        },
    },
    data() {
        return {
            remainingTime: this.initialTime,
            timerId: null,
        };
    },
    computed: {
        formattedTime() {
            if (this.outputFormat == 'seconds') {
                return Math.ceil(this.remainingTime);
            } else if (this.outputFormat == 'timestamp') {
                const minutes = Math.floor(Math.ceil(this.remainingTime) / 60);
                const seconds = Math.ceil(this.remainingTime) % 60;
                return `${minutes}:${seconds.toString().padStart(2, '0')}`;
            }
        },
    },
    mounted() {
        this.startCountdown();
    },
    beforeDestroy() {
        this.stopCountdown();
    },
    methods: {
        startCountdown() {
            this.timerId = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                    this.$emit('remaining-time', this.remainingTime);
                } else {
                    this.stopCountdown();
                    this.$emit('complete');
                }
            }, 1000);
        },
        stopCountdown() {
            clearInterval(this.timerId);
            this.timerId = null;
        },
    },
};
</script>

<style scoped>
.countdown-timer {
    margin: 0;
    padding: 0;
}
</style>

<template>
    <div :class="['event-action-container', event.hidden ? 'hidden-event-action-container' : '']">
        <div v-if="event.hidden" class="text-left">
            <v-chip label outlined color="#fb8c00" dark>Hidden</v-chip>
        </div>
        <nav class="event-action-tab-container">
            <li class="menu-entry" :class="nonLiveIndex == 0 && 'active'" @click="nonLiveIndex = 0">
                {{ streamingProvider === 5 ? 'RTSP' : 'RTMP' }}
            </li>
            <li class="menu-entry" :class="nonLiveIndex == 1 && 'active'" @click="nonLiveIndex = 1">Health</li>
            <li class="menu-entry" :class="nonLiveIndex == 2 && 'active'" @click="nonLiveIndex = 2">Share</li>
        </nav>
        <div class="event-action-tab-content">
            <!-- START: Default Tab -->
            <section v-if="nonLiveIndex === 2" class="default-button-container">
                <EmailInstructionsButton :event="event" />

                <v-btn
                    block
                    :color="facebookLiveStreamButtonType"
                    @click="getFacebookSettings"
                    v-if="!facebookSimulcast"
                >
                    <v-icon size="2x" left>mdi-facebook</v-icon>
                    {{ facebookLiveStreamButtonText }}
                </v-btn>

                <!-- <v-btn @click="initRtcStream" dark>
                    <font-awesome-icon class="mr-2" icon="fa-solid fa-camera-web"></font-awesome-icon>
                    Stream From Webcam</v-btn
                > -->

                <div v-if="facebookSimulcast" style="display: flex; flex-direction: column; align-items: center">
                    <!-- <h5 style="text-align: center">This Event will be simulcast to your Facebook.</h5> -->
                    <v-alert prominent text dense class="text-center" width="100%" type="info">
                        <v-row align="center">
                            <v-col class="grow"> This event will be simulcast to your Facebook </v-col>
                            <v-col class="shrink">
                                <v-btn
                                    color="info"
                                    outlined
                                    @click="
                                        window.open(
                                            'http://help.memoryshare.com/en/articles/6303770-facebook-simulcasting',
                                            '_blank',
                                        )
                                    "
                                    >learn more<font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right"
                                /></v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </div>
            </section>
            <!-- END: Default Tab -->

            <!-- START: Health Tab -->
            <section v-if="nonLiveIndex === 1">
                <wowza-event-chart
                    v-if="connection.cloudStreamingProvider == 0"
                    :live="event.eventStatus == 2"
                    :token="token"
                    ref="wowzaChart"
                    :event="event"
                />
                <div class="text-center" v-if="connection.cloudStreamingProvider != 0">Health Chart Not Available</div>
            </section>
            <!-- END: Health Tab -->

            <!-- START: Connections Tab -->
            <section v-if="nonLiveIndex === 0">
                <ConnectionFields :connection="connection" :event="event" :streamingProvider="streamingProvider" />
                <div v-if="this.streamingProvider === 1" style="display: flex; justify-content: center">
                    <qrcode-vue :value="qrValue" size="150" />
                </div>
                <v-btn block color="success" @click="startLiveStream">Start Live Stream</v-btn>
                <v-btn block color="secondary" class="mt-2" @click="handleAtemClick">
                    <v-icon size="2x" left>mdi-download</v-icon>
                    Blackmagic Atem Streaming Profile
                </v-btn>
            </section>
            <!-- END: Connections Tab -->
        </div>

        <v-dialog v-model="facebookDialog" persistent max-width="360">
            <v-card>
                <v-card-title class="headline">Facebook Settings</v-card-title>
                <v-card-text>
                    <div v-if="fetchedSettings">
                        <v-form v-model="formIsValid">
                            <v-text-field
                                v-if="!facebookDialogRTMPS"
                                :rules="rtmpRule"
                                v-model="facebookDialogRTMPS"
                                label="Server URL"
                            />
                            <v-text-field
                                v-model="facebookDialogStreamKey"
                                :rules="[v => v.length > 10 || 'Invalid Stream Key']"
                                label="Stream Key"
                            />
                        </v-form>
                    </div>
                    <div v-else>
                        <p>Loading Settings ...</p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-btn
                        outlined
                        small
                        @click="
                            window.open(
                                'http://help.memoryshare.com/en/articles/6303770-facebook-simulcasting',
                                '_blank',
                            )
                        "
                        >How to stream to Facebook <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right"
                    /></v-btn>
                    <!-- <a target="_blank" href="http://help.memoryshare.com/en/articles/6303770-facebook-simulcasting"
                        >How to stream to Facebook</a
                    > -->
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="facebookDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!formIsValid" text color="primary" @click="startFacebookLiveStream"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog @click:outside="rtcStreamConfirmModal = false" v-model="rtcStreamConfirmModal" max-width="500px">
            <v-card>
                <v-card-title>Stream from Webcam</v-card-title>
                <v-card-text
                    >You are about to go live from a webcam. You will no longer be able to go live from the MemoryShare
                    app, or OBS after proceeding.</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="rtcStreamConfirmModal = false" depressed>Cancel</v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        @click="confirmCreateRtcStream(event.liveStreamId)"
                        depressed
                        color="error"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <!-- <v-dialog @click:outside="rtcPublishModal = false" v-model="rtcPublishModal" max-width="500px">
            <v-card>
                <v-card-title>Stream Publish Page Ready</v-card-title>
                <v-card-text>Webcam stream publish page is ready.</v-card-text>
                <v-card-actions>
                    <v-btn block @click="openRedirectLink(redirectLink)" depressed color="success">Open</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import EmailInstructionsButton from '@/components/ManageService/buttons/EmailInstructions.vue';
import ConnectionFields from '@/components/ManageService/ConnectionFields.vue';
import WowzaEventChart from '@/components/charts/WowzaEventChart.vue';
import QrcodeVue from 'qrcode.vue';
import { mapActions } from 'vuex';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    components: { EmailInstructionsButton, WowzaEventChart, ConnectionFields, QrcodeVue },
    data() {
        return {
            qrValue: '',
            streamingProvider: null,
            nonLiveIndex: 0, // Tabs when event isn't live
            liveIndex: 0, // Tabs when event IS live
            connection: {}, // Info about the connection such as RTMP Name etc.
            facebookLiveStreamButtonText: 'Facebook Live Stream',
            facebookLiveStreamButtonType: 'primary',
            facebookDialog: false,
            facebookDialogRTMPS: null,
            facebookDialogStreamKey: null,
            fetchedSettings: false,
            facebookSimulcast: false,
            formIsValid: false,
            rtmpRule: [v => v.startsWith('rtmps://') || 'URL must start with rtmps://'],
            rtcStreamConfirmModal: false,
            loading: false,
            redirectLink: '',
            reqRefresh: false,
            rtcPublishModal: false,
        };
    },
    watch: {
        rtcPublishModal(newVal) {
            if (!newVal) {
                if (this.reqRefresh) {
                    this.$emit('refresh', this.event);
                    this.reqRefresh = false;
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        openRedirectLink(url) {
            if (!url) {
                this.showSnackbar({ message: 'Redirect link not found', color: 'error' });
                return;
            }

            window.open(url, '_blank');

            this.rtcPublishModal = false;
        },
        initRtcStream() {
            this.rtcStreamConfirmModal = true;
        },
        // confirmCreateRtcStream(streamId) {
        //     if (!streamId) {
        //         return;
        //     }

        //     this.redirectLink = '';
        //     this.reqRefresh = true;
        //     this.loading = true;
        //     this.axios
        //         .create({ headers: { Authorization: `Bearer ${this.token}` } })
        //         .post(process.env.VUE_APP_API + `/live/create-rtc-stream/${streamId}`)
        //         .then(response => {
        //             if (response.data.rtcPublishPage) {
        //                 this.rtcStreamConfirmModal = false;
        //                 this.redirectLink = response.data.rtcPublishPage;
        //                 this.rtcPublishModal = true;
        //             }
        //             this.loading = false;
        //         })
        //         .catch(() => {
        //             this.showSnackbar({ message: 'Error Creating RTC Stream', color: 'error' });
        //         });
        // },
        getFacebookSettings: function () {
            this.facebookDialog = true;

            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                // Make Request
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Integration/Facebook/settings/${this.service.funeralHomeId}`)
                    .then(response => {
                        this.facebookDialogRTMPS = response.data.facebookRtmps;
                        this.facebookDialogStreamKey = response.data.facebookStreamKey;
                        this.fetchedSettings = true;
                    })
                    .catch(() => {
                        this.facebookDialog = false;
                        this.showSnackbar({ message: 'Error Getting Settings' });
                    });
            });
        },
        /**
         * Fetches connection information
         */
        fetchConnections: function () {
            if (this.event.liveStreamId) {
                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    // Make Request
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/live/${this.event.liveStreamId}`)
                        .then(response => {
                            this.connection = response.data;
                            this.facebookSimulcast = response.data.facebookSimulcast;
                            this.assignQrValue();
                        });
                });
            }
        },
        assignQrValue() {
            const username = this.connection.userName;
            const pass = this.connection.password;
            const streamName = this.connection.streamName;
            const newurl = encodeURIComponent(this.connection.primaryServer) + '/' + streamName;
            const QRCode = `larix://set/v1?conn[][url]=${newurl}&conn[][name]=${streamName}&conn[][mode]=av&conn[][user]=${username}&conn[][pass]=${pass}&conn[][target]=rtmp`;
            this.qrValue = QRCode;
        },
        handleAtemClick() {
            this.$auth.getIdTokenClaims().then(async result => {
                const token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` } })
                    .get(process.env.VUE_APP_API + `/events/getatemxmlfile/${this.event.id}`)
                    .then(response => {
                        let eventName = this.event.title
                            .toLowerCase()
                            .replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
                            .replace(/\s+/g, '-');

                        let xmltext = response.data;
                        let filename = eventName + '-atem.xml';
                        let pom = document.createElement('a');
                        let bb = new Blob([xmltext], { type: 'text/plain' });

                        pom.setAttribute('href', window.URL.createObjectURL(bb));
                        pom.setAttribute('download', filename);

                        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                        pom.draggable = true;
                        pom.classList.add('dragout');
                        pom.dispatchEvent(new MouseEvent('click', {}));
                    })
                    .catch(error => {});
            });
        },
        startLiveStream(loading = true) {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                // Make Request
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/live/${this.event.liveStreamId}`)
                    .then(response => {
                        const stream = response.data;
                        const url = encodeURIComponent(stream.primaryServer + '/' + stream.streamName);

                        const hlsPlayerUrl = response.data.hlsPlayerUrl;
                        const QRCode = `larix://set/v1?conn[][url]=${url}&conn[][name]=stream&conn[][mode]=av&conn[][user]=${stream.userName}&conn[][pass]=${stream.password}&conn[][target]=rtmp`;

                        // start wowza
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .post(process.env.VUE_APP_API + `/live/start/${this.event.liveStreamId}`)
                            .then(response => {
                                this.$emit('refresh', this.event);
                            })
                            .catch(error => {});
                    })
                    .catch(error => {});
            });
        },
        startFacebookLiveStream() {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                let data = {
                    facebookRtmps: this.facebookDialogRTMPS,
                    facebookStreamKey: this.facebookDialogStreamKey,
                };

                // Make Request
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(
                        process.env.VUE_APP_API + `/Integration/Facebook/settings/${this.service.funeralHomeId}`,
                        data,
                    )
                    // facebookRtmps: this.facebookDialogRTMPS,
                    // facebookStreamKey: this.facebookDialogStreamKey,
                    // })
                    .then(response => {
                        this.connection = response.data;
                        this.facebookSimulcast = response.data.facebookSimulcast;
                        this.assignQrValue();
                        // this.$emit('refresh', );

                        // Make Call To Start Live Stream
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .post(process.env.VUE_APP_API + `/live/facebook?id=${this.event.liveStreamId}`)
                            .then(response => {
                                this.warningFacebookSettings = false;
                                this.facebookStreamStarted = true;
                                this.facebookLiveStreamButtonText = 'Facebook Live Stream Started';
                                this.facebookLiveStreamButtonType = 'success';
                                // this.facebookSimulcast = true;
                                this.$emit('refresh', this.event);
                                this.facebookDialog = false;
                                this.fetchConnections();
                                this.facebookDialog = false;
                            })
                            .catch(error => {
                                this.showSnackbar({ message: 'Error streaming to facebook' });
                                this.warningFacebookSettings = true;
                                this.warningFacebookSettingsDialogTitle = 'Wrong General Settings';
                                this.wrongSettingsMessage = error.response.data;
                            });
                    });
            });
        },
        getUserSettings() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/admin/${this.service.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                this.$emit('message', error.response.data);
                                reject(error);
                            });
                    } else {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/${this.service.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                });
            });
        },
    },
    mounted() {
        if (this.event) {
            this.getUserSettings().then(response => {
                this.streamingProvider = response.streamingProvider;
                // If streaming provider is MemoryShare
                if (response?.streamingProvider === 2) {
                    this.nonLiveIndex = 2;
                }

                // Fetch Connections
                this.fetchConnections();
            });
        }
    },
};
</script>

<style lang="scss" scoped>
#coming-soon-badge {
    border: 2px solid orange;
    background-color: orange;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: center;
    z-index: 1;
    margin-bottom: -30px;
    margin-right: 10px;
    padding: 3px;
}

.event-action-tab-container {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;

    .menu-entry {
        list-style: none;
        padding: 0.5rem 1rem;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .menu-entry.active {
        border-bottom: 4px solid rgb(78, 184, 226);
    }
}

.default-button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>

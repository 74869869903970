<template>
    <div>
        <AppLayout :has-background="true" :contained="true">
            <v-btn @click="$router.back()">
                <font-awesome-icon icon="fa-regular fa-arrow-left"></font-awesome-icon>
                Back
            </v-btn>
            <div class="elevation-3 p-4 bg-white rounded mt-2">
                <h3 class="mb-4">Song Analytics</h3>

                <div v-if="!song">No song found</div>

                <div v-if="song">
                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <v-img
                                    eager
                                    id="artwork"
                                    v-if="song.artwork != null && song.artwork != ''"
                                    aspect-ratio="1"
                                    style="max-width: 150px; cursor: pointer; border-radius: 5px"
                                    :lazy-src="song.artwork"
                                    :src="song.artwork"
                                ></v-img>
                                <div v-else class="image-placeholder">
                                    <span>No Image</span>
                                </div>

                                <div
                                    class="ml-3 d-flex justify-space-between flex-column"
                                    style="flex-grow: 1; min-width: 200px; max-width: 400px"
                                >
                                    <div>
                                        <h5>{{ song.title }}</h5>
                                        <span>{{ song.artist }}</span>
                                    </div>
                                    <audio
                                        ref="audio-modal"
                                        class="rounded-pill"
                                        style="width: 100%"
                                        controls
                                        :src="song.playableLink"
                                    ></audio>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="p-2" style="border: 1px solid #eee; border-radius: 5px">
                                <span>Genres</span>

                                <div class="d-flex flex-wrap">
                                    <v-chip color="#f15a37" dark small v-for="(g, index) in song.musicGenres">{{
                                        g.name
                                    }}</v-chip>
                                    <span class="subtle-text" v-if="song.musicGenres.length == 0">No Genres</span>
                                </div>
                            </div>

                            <div class="p-2 mt-2" style="border: 1px solid #eee; border-radius: 5px">
                                <span>Keywords</span>
                                <div class="d-flex flex-wrap">
                                    <v-chip color="#f15a37" dark small v-for="(kw, index) in song.musicKeywords">{{
                                        kw.name
                                    }}</v-chip>
                                    <span class="subtle-text" v-if="song.musicKeywords.length == 0">No Keywords</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between align-items-center flex-wrap">
                        <h5>Tribute Videos</h5>
                        <v-text-field
                            class="ml-4"
                            style="max-width: 400px"
                            v-model="search"
                            label="Search"
                        ></v-text-field>
                    </div>

                    <v-data-table
                        v-if="tributeVideos"
                        @click:row="handleRowClick"
                        :items="tributeVideos"
                        :headers="headers"
                        :server-items-length="totalTributeVideos"
                        :loading="loading"
                        :options.sync="options"
                        :footer-props="{
                            itemsPerPageOptions: [5, 25, 50],
                        }"
                    >
                        <template v-slot:item.name="{ item }">
                            <div>
                                {{ item.firstName + ' ' + item.lastName }}
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </AppLayout>
    </div>
</template>
<script>
import { debounceV2 } from '@/utilities/debounce.js';
export default {
    metaInfo: {
        title: 'Song Analytics',
    },
    data() {
        return {
            search: '',
            song: null,
            tributeVideos: [],
            totalTributeVideos: 0,
            headers: [
                // { text: 'Tribute Id', value: 'id' },
                { text: 'Funeral Home', value: 'funeralHomeName' },
                { text: 'Owner', value: 'ownerName' },
                { text: 'Service Id', value: 'serviceId' },
                { text: 'Event Id', value: 'eventId' },
                // { text: 'Funeral Home Id', value: 'funeralHomeId' },
                // { text: 'Owner Id', value: 'ownerId' },
                { text: 'Name', value: 'name' },
            ],
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            loading: true,
        };
    },
    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.getSongAnalytics(this.$route.params.id);
                }
            },
        },
        // selectedSongs() {
        //     this.$emit('refresh-selected-songs', this.selectedSongs);
        // },
        search: debounceV2(function () {
            this.getSongAnalytics(this.$route.params.id);
        }, 500),
    },
    methods: {
        handleRowClick(item) {
            const URL = `/services/${item.serviceSlug}/${item.eventId}`;
            window.open(URL, '_blank');
        },
        getSortByValue(value) {
            switch (value) {
                case 'ownerName':
                    return 'OwnerName';
                // case 'id':
                //     return 'Id';
                case 'funeralHomeName':
                    return 'FuneralHomeName';
                case 'eventId':
                    return 'EventId';
                case 'serviceId':
                    return 'ServiceId';
                case 'name':
                    return 'Name';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getSong(id) {
            return this.axiosInstance
                .get(`TributeVideoSong/${id}`)
                .then(res => {
                    this.song = res.data;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        async getSongAnalytics(id) {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
            };

            return this.axiosInstance
                .get(`TributeVideoSong/analytics/${id}`, { params: params })
                .then(res => {
                    this.tributeVideos = res.data.tributeVideos;
                    this.totalTributeVideos = res.data.totalTributeVideos;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getSong(this.$route.params.id);
        this.getSongAnalytics(this.$route.params.id);
    },
};
</script>
<style lang="scss" scoped>
.image-placeholder {
    max-width: 150px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border: 2px solid #bbb;
    background-color: #eee;
    border-radius: 5px;
}
.subtle-text {
    font-size: 0.8rem;
    color: #999;
}
</style>

<template>
    <div class="onboard-share-link-action">
        <div class="text-left mb-4">
            <h4>Mobile Upload</h4>
            <span>Upload or Scan Photos from your mobile device using the MemoryShare App.</span>
        </div>

        <div class="action-inner">
            <div class="action-inner-upper">
                <div class="ms-phone-wrap">
                    <img class="mobile-icon" :src="mobileIcon" />
                    <img :src="require(`@/assets/images/logo.png`)" alt="MemoryShare Logo" class="cta-logo-img" />
                </div>

                <h5 class="action-cta font-weight-bold">
                    Available on <br />
                    mobile
                </h5>
            </div>
            <div class="btn-wrap">
                <v-btn depressed @click="$emit('init-share-modal')">
                    Share
                    <font-awesome-icon class="ml-1" icon="fa-regular fa-share" />
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import mobileIcon from '@/assets/images/mobile-phone.png';
export default {
    data() {
        return {
            mobileIcon,
        };
    },
};
</script>
<style lang="scss">
.onboard-share-link-action {
    min-height: 550px;
    background-color: #f8f8f8;
    padding: 32px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 250px;
    height: 100%;

    .action-inner {
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        height: 350px;
    }

    .action-inner-upper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .ms-phone-wrap {
        position: relative;
        height: 100px;
        width: 100%;

        .mobile-icon {
            position: absolute;
            width: 100px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
        .cta-logo-img {
            position: absolute;
            height: 30px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('font-awesome-icon',{staticClass:"m-2",attrs:{"icon":"fa-regular fa-bolt"}}),_c('h3',{staticClass:"mt-3"},[_vm._v("Credits")]),_c('v-spacer'),_c('create-credit-type',{on:{"refresh":function($event){return _vm.getCreditTypes()}}})],1),_c('v-data-table',{staticClass:"credits-table",attrs:{"headers":_vm.headers,"items":_vm.creditTypes,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.isEditing)?_c('v-text-field',{model:{value:(_vm.tempName),callback:function ($$v) {_vm.tempName=$$v},expression:"tempName"}},[_vm._v("Editing")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.suggestedAmount",fn:function(ref){
        var item = ref.item;
return [(item.isEditing)?_c('vuetify-money',{attrs:{"options":_vm.currencyOptions},model:{value:(_vm.tempAmount),callback:function ($$v) {_vm.tempAmount=$$v},expression:"tempAmount"}}):_c('span',[_vm._v(_vm._s(_vm._f("moneyFilter")(item.suggestedAmount)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.isEditing)?_c('div',{staticClass:"action-list"},[_c('v-btn',{on:{"click":function($event){return _vm.cancelEdit(item.id)}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"info",on:{"click":function($event){return _vm.saveEdit(item.id)}}},[_vm._v("Save")])],1):_c('div',{staticClass:"action-list",staticStyle:{"display":"flex","justify-content":"center"}},[_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-pencil"},on:{"click":function($event){return _vm.editCreditType(item.id)}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Edit")])]},proxy:true}],null,true)}),_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-trash-can"},on:{"click":function($event){return _vm.openDeleteModal(item)}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Delete")])]},proxy:true}],null,true)})],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',{staticClass:"deleteModalCard"},[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"center","color":"red"}},[_c('h2',[_vm._v("Warning")])]),_c('v-card-text',[_c('h4',[_vm._v("Are you sure you want to delete "+_vm._s(_vm.selectedCredit.name)+"?")])]),_c('span',{staticClass:"deleteModalBtns"},[_c('v-btn',{on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteCredit}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-truck-fast" style="margin-right: 0.5rem" />
            <h3 class="m-0">Shipments</h3>
            <v-spacer></v-spacer>
            <v-text-field append-icon="mdi-magnify" style="max-width: 300px" v-model="search"></v-text-field>
        </v-card-title>
        <v-data-table
            :items="shipments"
            :headers="headers"
            :server-items-length="totalShipments"
            :loading="loading"
            :options.sync="options"
            @click:row="goToDetails"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:header.status="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }}<font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <div class="text-center">
                            <v-btn style="margin-bottom: 0.6rem" @click="toggleAllShipmentStatuses" width="90%" small
                                >{{ allStatusesSelected ? 'Deselect' : 'Select' }} All</v-btn
                            >
                        </div>
                        <v-list-item v-for="(item, index) in shipmentStatuses" :key="index" style="height: 20px">
                            <v-checkbox v-model="selectedStatuses" :label="item.label" :value="item.value"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.status="{ item }">
                <v-select
                    style="height: 2.6rem"
                    class="selectOrderStatus"
                    dense
                    chips
                    small
                    return-object
                    item-text="label"
                    item-value="value"
                    v-model="item.status"
                    :items="shipmentStatuses"
                    @input="updateShipmentStatus(item.id, item.status.value)"
                    @click.stop
                >
                    <template v-slot:selection="{ item }">
                        <div>
                            <v-chip dark :color="item.color">{{ item.label }}</v-chip>
                        </div>
                    </template>
                    <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-row no-gutters align="center">
                                        <v-spacer></v-spacer>
                                        <v-chip text-color="white" :color="item.color">{{ item.label }}</v-chip>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </template>

            <template v-slot:item.createdDate="{ item }">
                {{ $moment.utc(item.createdDate).local().format('l') }}
            </template>

            <template v-slot:item.updatedDate="{ item }">
                {{ $moment.utc(item.updatedDate).local().format('l') }}
            </template>

            <template v-slot:item.carrier="{ item }">
                {{ carriers[item.carrier].label }}
            </template>

            <template v-slot:item.cost="{ item }">
                {{ item.cost | formatMoney() }}
            </template>

            <template v-slot:item.orderId="{ item }">
                <span @click.stop>
                    <a :href="`/order-details/${item.orderId}`">{{ item.orderId }}</a>
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div style="display: flex; gap: 10px; align-items: center; justify-content: center">
                    <custom-tooltip v-if="item.trackingUrl" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click.stop="openTab(item.trackingUrl)">
                                <font-awesome-icon icon="fa-regular fa-map-location-dot" style="font-size: 1.1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Tracker</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="item.labelUrl" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    @click.stop="openTab(item.labelUrl)"
                                    icon="fa-regular fa-file-arrow-down"
                                    style="font-size: 1.1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Label</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { carriers, shipmentStatuses } from '@/constants';
import { mapActions } from 'vuex';
import moment from 'moment';
import { debounceV2 } from '@/utilities/debounce.js';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            carriers,
            shipmentStatuses,
            token: '',
            search: '',
            shipments: [],
            loading: true,
            totalShipments: 0,
            options: {
                itemsPerPage: 25,
            },
            headers: [],
            shipmentStatusString: '',
            selectedStatuses: [0, 1, 2, 3, 4, 5],
        };
    },
    props: ['supplierId'],
    components: { CustomTooltip },
    computed: {
        allStatusesSelected() {
            return this.selectedStatuses.length === this.shipmentStatuses.length;
        },
    },
    watch: {
        search: debounceV2(function () {
            this.getShipments();
        }, 500),
        options: debounceV2(function () {
            if (!this.loading) this.getShipments();
        }, 500),
        selectedStatuses: debounceV2(function () {
            if (this.selectedStatuses.length === 0) {
                this.shipments = [];
            } else {
                let string = '';
                this.selectedStatuses.forEach(status => (string += `&shipmentStatus=${status}`));
                this.shipmentStatusString = string;

                this.getShipments();
            }
        }, 500),
    },
    methods: {
        ...mapActions(['showSnackbar']),
        goToDetails(item) {
            this.$router.push({ path: `/shipment/${item.id}` });
        },
        openTab(url) {
            window.open(url, '_blank');
        },
        toggleAllShipmentStatuses() {
            if (this.allStatusesSelected) {
                this.selectedStatuses = [];
            } else {
                this.shipmentStatuses.forEach(status => {
                    if (status.value >= 0 && !this.selectedStatuses.includes(status.value)) {
                        this.selectedStatuses.push(status.value);
                    }
                });
            }
        },
        getSortByValue(value) {
            switch (value) {
                case 'customerName':
                    return 'CustomerName';
                case 'id':
                    return 'Id';
                case 'orderId':
                    return 'OrderId';
                case 'status':
                    return 'Status';
                case 'createdDate':
                    return 'CreatedDate';
                case 'updatedDate':
                    return 'UpdatedDate';
                case 'cost':
                    return 'Cost';
                case 'trackingNumber':
                    return 'TrackingNumber';

                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        updateShipmentStatus(id, status) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Shipping/update-shipment-status/${id}?status=${status}`)
                .then(response => {
                    this.showSnackbar({ message: 'Status Updated' });
                    this.getShipments();
                    this.$emit('refresh');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating status' });
                });
        },
        getShipments() {
            this.loading = true;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
            };
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(
                        process.env.VUE_APP_API +
                            `/Shipping/supplier-shipments/${this.supplierId}?search=${this.search}${this.shipmentStatusString}`,
                        { params: params },
                    )
                    .then(response => {
                        this.shipments = response.data.shipments;
                        this.totalShipments = response.data.total;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
    },
    mounted() {
        this.headers = [
            { text: 'ID', value: 'id' },
            { text: 'Order', value: 'orderId' },
            { text: 'Tracking Number', value: 'trackingNumber' },
            { text: 'Created Date', value: 'createdDate' },
            { text: 'Updated Date', value: 'updatedDate' },
            { text: 'Carrier', value: 'carrier', width: '10%' },
            { text: 'Cost', value: 'cost' },
            { text: 'Status', value: 'status', width: '10%' },
            { text: 'Actions', value: 'actions', width: '10%', sortable: false },
        ];
        this.getShipments();
    },
};
</script>
<style>
.shipment {
    margin-top: 20px;
}
</style>

<template>
    <div
        style="
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            z-index: 50000;
        "
    >
        <h1>Logging Out</h1>
    </div>
</template>
<script>
export default {
    mounted() {
        this.logout();
    },
    methods: {
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
    },
};
</script>

<template>
    <div>
        <branded-modal @close="displayModal = false" v-model="displayModal">
            <template v-slot:title>Choose Profile Picture</template>
            <template v-slot:body>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex justify-space-between align-end flex-wrap" style="gap: 12px">
                            <h4 class="m-0 p-0">Uploads</h4>
                            <v-btn dark depressed color="#ff530d" @click="$emit('init-profile-upload')"
                                >Upload Photo
                                <font-awesome-icon class="ml-2" icon="fa-solid fa-plus"></font-awesome-icon>
                            </v-btn>
                        </div>

                        <v-divider v-if="tributeVideo.mainPhotoId || profilePhotoOptions.length > 0"></v-divider>
                        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                    </v-col>

                    <v-col sm="6" md="4" lg="3" cols="12" v-for="(item, index) in profilePhotoOptions" :key="index">
                        <v-img
                            @click="submitMainPhotoUpdate(tributeVideo.eventId, item.id)"
                            class="modal-photo"
                            :class="isSelected(item) ? 'selected' : ''"
                            aspect-ratio="1"
                            :src="item.url"
                        ></v-img>
                    </v-col>
                </v-row>

                <div v-if="totalPages > 1" class="text-center">
                    <v-pagination class="pt-2" v-model="pageNumber" :length="totalPages"></v-pagination>
                </div>
            </template>
        </branded-modal>
    </div>
</template>
<script>
import BrandedModal from '@/components/ui/BrandedModal.vue';
import { mapActions } from 'vuex';
import TributePhotoService from '@/services/tributePhoto.service';

export default {
    data() {
        return {
            displayModal: false,
            profilePhotoOptions: [],
            pageSize: 12,
            pageNumber: 1,
            totalPhotos: 0,
            loading: false,
            modalMainAnimated: false,
            apiInstance: {},
        };
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
    watch: {
        pageNumber(newVal) {
            const pageNum = newVal > 0 ? newVal - 1 : 0;
            this.getPhotos(this.eventId, {
                pageNumber: pageNum,
                pageSize: this.pageSize,
                imagesOnly: true,
                reversed: true,
                includeProfile: true,
            });
        },
        displayModal(newVal) {
            const pageNum = newVal > 0 ? newVal - 1 : 0;
            this.getPhotos(this.eventId, {
                pageNumber: pageNum,
                pageSize: this.pageSize,
                imagesOnly: true,
                reversed: true,
                includeProfile: true,
            });
        },
    },
    components: {
        BrandedModal,
    },
    computed: {
        totalPages() {
            if (this.totalPhotos > 0) {
                return Math.ceil(this.totalPhotos / this.pageSize);
            }
            return 0;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        open() {
            this.displayModal = true;
        },
        close() {
            this.displayModal = false;
        },
        isSelected(item) {
            if (this.tributeVideo.mainPhotoId && item.id == this.tributeVideo.mainPhotoId) {
                return true;
            }

            return false;
        },
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async submitMainPhotoUpdate(eventId, photoId) {
            if (this.tributeVideo.mainPhotoId && photoId == this.tributeVideo.mainPhotoId) {
                this.showSnackbar({ message: 'This image is already the profile picture' });
                return;
            }

            this.loading = true;

            try {
                await this.apiInstance.tributePhoto.updateProfilePhoto(eventId, photoId);
                this.close();
                this.showSnackbar({ message: 'Profile picture updated' });
            } catch (error) {
                console.log(err, 'err');
                this.showSnackbar({ message: 'Error updating profile picture', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async getPhotos(eventId, params) {
            this.loading = true;

            try {
                const resp = await this.apiInstance.tributePhoto.getPhotos(eventId, params);

                if (resp.data) {
                    this.profilePhotoOptions = resp.data.photos;
                    this.totalPhotos = resp.data.total;
                }
            } catch (error) {
                console.log(error, 'error');
            } finally {
                this.loading = false;
            }
        },
        handleModalMainPhotoClick() {
            this.showSnackbar({ message: 'This profile photo is already selected' });
            this.modalMainAnimated = true;
            setTimeout(() => {
                this.modalMainAnimated = false;
            }, 1000);
        },
        initApiInstance(token) {
            this.apiInstance.tributePhoto = TributePhotoService(token);
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.initApiInstance(this.token);

        const pageNum = this.pageNumber > 0 ? this.pageNumber - 1 : 0;

        this.getPhotos(this.eventId, {
            pageNumber: pageNum,
            pageSize: this.pageSize,
            imagesOnly: true,
            reversed: true,
            includeProfile: true,
        });
    },
};
</script>
<style lang="scss" scoped>
.selected {
    outline: 3px solid #ff530d !important;
}

.modal-photo {
    border-radius: 10px;
    cursor: pointer;
    outline: 3px solid transparent;
    transition: 0.2s;
}

.modal-photo:hover {
    outline: 3px solid #bababa;
}
</style>

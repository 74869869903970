<template>
    <v-card>
        <v-card-title class="text-h5"> Super Admin Stream Edit</v-card-title>

        <v-card-text v-if="loaded">
            <form class="super-container" @submit.prevent="handleFormSubmission">
                <v-text-field v-model="formData.id" label="ID" />
                <v-text-field v-model="formData.serviceId" label="Service ID" />
                <v-text-field v-model="formData.funeralHomeId" label="Funeral Home ID" />
                <v-text-field v-model="formData.eventId" label="Event ID" />
                <v-text-field v-model="formData.ownerId" label="Owner ID" />
                <v-text-field v-model="formData.streamId" label="Stream ID" />

                <calendar datetime v-model="formData.startedTime" label="Started Time" />
                <calendar datetime v-model="formData.endedTime" label="Ended Time" />

                <v-text-field v-model="formData.startStreamAccessToken" label="Stream ID" />
                <v-text-field v-model="formData.uniqueViewers" label="Unique Viewers" />
                <v-text-field v-model="formData.primaryServer" label="Primary Server" />
                <v-text-field v-model="formData.hostPort" label="Host Port" />
                <v-text-field v-model="formData.streamName" label="Stream Name" />
                <v-text-field v-model="formData.userName" label="User Name" />
                <v-text-field v-model="formData.password" label="Password" />
                <v-text-field v-model="formData.application" label="Application" />

                <!-- <v-text-field v-model="formData.state" label="State" /> -->
                <v-select
                    :items="liveSuperAdminStates"
                    item-text="label"
                    item-value="value"
                    label="State"
                    v-model="formData.state"
                ></v-select>

                <v-text-field v-model="formData.playbackUrls" label="Playback URLS" />
                <v-text-field v-model="formData.hlsPlayerUrl" label="HLS Player URL" />
                <v-text-field v-model="formData.simulcast" label="simulcast" />
                <v-text-field v-model="formData.remoteAccessToken" label="remoteAccessToken" />
                <v-text-field v-model="formData.rtcPublishPage" label="RTC Publish Page" />
                <v-select
                    item-value="value"
                    item-text="label"
                    :items="boolSelect"
                    v-model="formData.streamFromRTC"
                    label="Stream From RTC"
                />
                <v-text-field v-model="formData.sdpUrl" label="SDP URL"></v-text-field>

                <v-select
                    item-value="value"
                    item-text="label"
                    :items="boolSelect"
                    v-model="formData.autoShutoff"
                    label="AutoShutoff"
                />

                <div></div>

                <div class="mt-1">
                    <v-btn
                        @click="switchProvider(0)"
                        :ripple="false"
                        style="width: 50%"
                        class="first-btn elevation-2"
                        :class="currentStreamProvider == 0 ? 'activeProvider' : ''"
                        depressed
                        large
                        tile
                    >
                        <div class="img-container">
                            <img :src="require('@/assets/images/wowza-orange.png')" alt="Wowza Logo" />
                        </div>
                    </v-btn>
                    <v-btn
                        @click="switchProvider(1)"
                        :ripple="false"
                        style="width: 50%"
                        class="last-btn elevation-2"
                        :class="currentStreamProvider == 1 ? 'activeProvider' : ''"
                        depressed
                        large
                        tile
                    >
                        <div class="img-container">
                            <img :src="require('@/assets/images/mux.png')" alt="Mux Logo" />
                        </div>
                    </v-btn>
                </div>

                <v-btn
                    v-if="formData.cloudStreamingProvider == 0"
                    class="mt-1"
                    @click="openWowzaTab(formData.streamId)"
                    block
                    large
                >
                    <div class="img-container">
                        <img :src="require('@/assets/images/wowza-orange.png')" alt="Wowza Logo" />
                    </div>
                    <font-awesome-icon
                        class="ml-2"
                        style="font-size: 0.8rem"
                        icon="fa-regular fa-arrow-up-right-from-square"
                /></v-btn>
                <v-btn
                    v-if="formData.cloudStreamingProvider == 1"
                    class="mt-1"
                    @click="openMuxTab(formData.streamId)"
                    block
                    large
                >
                    <div class="img-container">
                        <img :src="require('@/assets/images/mux.png')" alt="Mux Logo" />
                    </div>
                    <font-awesome-icon
                        class="ml-2"
                        style="font-size: 0.8rem"
                        icon="fa-regular fa-arrow-up-right-from-square"
                    />
                </v-btn>

                <v-btn class="mt-4" @click="initRtcStream" large dark>
                    <!-- <font-awesome-icon class="mr-2" icon="fa-solid fa-camera-web"></font-awesome-icon> -->
                    Convert to RTC</v-btn
                >

                <v-dialog v-model="switchConfirmationModal" max-width="400px">
                    <v-card>
                        <div class="pl-3 pr-3 decorated-title">
                            <v-card-title style="display: flex; justify-content: space-between">
                                <div class="text-container">Switch Provider?</div>
                                <div class="img-container">
                                    <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                                </div>
                            </v-card-title>
                        </div>

                        <div class="p-3">
                            <div class="text-center p-3">
                                <v-row no-gutters>
                                    <v-col>
                                        <div class="img-container text-right">
                                            <img
                                                :src="
                                                    require(`@/assets/images/${cloudStreamingProviders[currentStreamProvider].logo}`)
                                                "
                                                alt=""
                                            />
                                        </div>
                                    </v-col>
                                    <v-col cols="2">
                                        <font-awesome-icon class="mt-3" icon="fa-ragular fa-arrow-right" />
                                    </v-col>
                                    <v-col>
                                        <div class="img-container text-left">
                                            <img
                                                :src="
                                                    require(`@/assets/images/${cloudStreamingProviders[newStreamProvider].logo}`)
                                                "
                                                alt=""
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="pt-3 d-flex flex-column">
                                <v-btn color="#ff530d" dark @click="confirmSwitch(newStreamProvider)">Confirm</v-btn>
                                <v-btn class="mt-3" @click="cancelSwitch()">Cancel</v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>

                <custom-tooltip :tooltipProps="{ right: true }">
                    <template v-slot:activator>
                        <div>
                            <v-btn
                                class="mt-4"
                                @click="downloadStreams(downloadUrls)"
                                :disabled="downloadUrls.length == 0 || loading"
                                :loading="loading"
                                block
                                large
                                >download</v-btn
                            >
                        </div>
                    </template>

                    <template v-slot:content>
                        <span v-if="downloadUrls.length == 0">No Recordings Found</span>
                        <span v-else>Download Stream {{ downloadUrls.length > 1 ? 'Recordings' : 'Recording' }}</span>
                    </template>
                </custom-tooltip>

                <!-- grid spacer -->
                <div></div>
                <div>
                    <v-btn class="mt-4" block color="#0C3C60" dark large :disable="!loaded" type="submit">Save</v-btn>
                </div>
            </form>
        </v-card-text>
        <v-card-text v-else>
            <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>

        <v-dialog @click:outside="rtcStreamConfirmModal = false" v-model="rtcStreamConfirmModal" max-width="500px">
            <v-card>
                <v-card-title>Stream from Webcam</v-card-title>
                <v-card-text
                    >You are about to go live from a webcam. You will no longer be able to go live from the MemoryShare
                    app, or OBS after proceeding.</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="rtcStreamConfirmModal = false" depressed>Cancel</v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        @click="confirmCreateRtcStream(event.liveStreamId)"
                        depressed
                        color="error"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog @click:outside="rtcPublishModal = false" v-model="rtcPublishModal" max-width="500px">
            <v-card>
                <v-card-title>Stream Publish Page Ready</v-card-title>
                <v-card-text>Webcam stream publish page is ready.</v-card-text>
                <v-card-actions>
                    <v-btn block @click="openRedirectLink(redirectLink)" depressed color="success">Open</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { liveSuperAdminStates, cloudStreamingProviders } from '@/constants';
import Calendar from '@/components/ui/Calendar.vue';
import JSZip from 'jszip';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { mapActions } from 'vuex';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            token: null,
            formData: {},
            loaded: false,
            loading: false,
            downloadUrls: [],
            liveSuperAdminStates: [...liveSuperAdminStates],
            currentStreamProvider: 0,
            newStreamProvider: 0,
            switchConfirmationModal: false,
            cloudStreamingProviders,
            boolSelect: [
                { label: 'False', value: false },
                { label: 'True', value: true },
            ],
            rtcStreamConfirmModal: false,
            redirectLink: '',
            reqRefresh: false,
            rtcPublishModal: false,
        };
    },
    components: { Calendar, CustomTooltip },
    methods: {
        ...mapActions(['showSnackbar']),
        initRtcStream() {
            this.rtcStreamConfirmModal = true;
        },
        confirmCreateRtcStream(streamId) {
            if (!streamId) {
                return;
            }

            this.redirectLink = '';
            this.reqRefresh = true;
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/live/create-rtc-stream/${streamId}`)
                .then(response => {
                    this.getStream();
                    if (response.data.rtcPublishPage) {
                        this.rtcStreamConfirmModal = false;
                        this.redirectLink = response.data.rtcPublishPage;
                        this.rtcPublishModal = true;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.showSnackbar({ message: 'Error Creating RTC Stream', color: 'error' });
                });
        },
        openRedirectLink(url) {
            if (!url) {
                this.showSnackbar({ message: 'Redirect link not found', color: 'error' });
                return;
            }

            window.open(url, '_blank');

            this.rtcPublishModal = false;
        },
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */
        convertIntObj: function (obj) {
            {
                let res = {};
                Object.keys(obj).forEach(key => {
                    if (key === 'pin') {
                        res[key] = obj[key];
                    } else if (key === 'userName') {
                        res[key] = obj[key]?.toString();
                    } else if (key === 'password') {
                        res[key] = obj[key]?.toString();
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });

                return res;
            }
        },
        async downloadStreams(urls) {
            try {
                this.loading = true;

                const promises = urls.map(async url => {
                    const blob = await this.getStreamData(url);
                    return { url, blob: blob };
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (urls.length > 1) {
                    const zip = new JSZip();

                    res.forEach(stream => {
                        zip.file(stream.url, stream.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(zipFile, this.event.title);
                    this.showSnackbar({ message: 'Streams downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, this.event.title);
                    this.showSnackbar({ message: 'Stream downloaded' });
                }
                this.selectedItems = [];
                this.loading = false;
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error downloading streams', color: 'error' });
            }
        },
        getStreamData(url) {
            return new Promise((resolve, reject) => {
                fetch(url)
                    .then(resp => resp.blob())
                    .then(blobObject => {
                        resolve(blobObject);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error downloading image', color: 'error' });
                        reject();
                    });
            });
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        cancelSwitch() {
            this.switchConfirmationModal = false;
        },
        confirmSwitch(provider) {
            if (this.loaded) {
                this.loaded = false;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + `/Live/switch-stream/${this.event.liveStreamId}/${provider}`)
                    .then(response => {
                        this.currentStreamProvider = provider;
                        this.showSnackbar({ message: 'Streaming Provider Updated' });
                        this.$emit('close');
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    })
                    .finally(() => {
                        this.loaded = true;
                    });
            }
        },
        switchProvider(provider) {
            if (this.currentStreamProvider == provider) {
                return;
            }
            this.newStreamProvider = provider;
            this.switchConfirmationModal = true;
        },
        openWowzaTab(id) {
            window.open('https://cloud.wowza.com/en/thgvyjtb/manage/live_streams/' + id);
        },
        openMuxTab(id) {
            window.open(
                `https://dashboard.mux.com/organizations/cgip3r/environments/${process.env.VUE_APP_MUX_ENV}/video/live-streams/${id}`,
            );
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/Live/admin-edit/${this.event.liveStreamId}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        checkForUTC(timeString) {
            if (timeString && timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal(timeString) {
            if (timeString && timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        getStream() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(`${process.env.VUE_APP_API}/Live/admin-edit/${this.event.liveStreamId}`)
                .then(response => {
                    this.formData = { ...response.data.liveStream };
                    this.currentStreamProvider = response.data.liveStream.cloudStreamingProvider;
                    this.formData.startedTime = this.checkForUTC(response.data.liveStream.startedTime);
                    this.formData.endedTime = this.checkForUTC(response.data.liveStream.endedTime);
                    this.downloadUrls = response.data.downloadUrls ? response.data.downloadUrls.split(',') : [];
                })
                .catch(error => {
                    console.log('error', error);
                })
                .finally(() => {
                    this.loaded = true;
                });
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;

            this.getStream();
        });
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.first-btn {
    border-radius: 10px 0 0 10px;
}
.last-btn {
    border-radius: 0 10px 10px 0;
}
.activeProvider {
    background-color: rgb(205, 205, 205) !important;
}
.img-container {
    height: 500px;
    max-height: 40px;
    img {
        max-height: 100%;
        width: auto;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',{attrs:{"has-background":true,"contained":true}},[(_vm.$auth.role.includes('SuperAdmin'))?_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-btn',{staticClass:"create-button",attrs:{"to":{ name: 'CreateHome', params: { ownerId: this.ownerId } }}},[_vm._v("+ Create New Home")])],1):_vm._e(),_c('v-card',{staticClass:"p-4"},[_c('v-card-title',[_c('span',{staticStyle:{"display":"flex"}},[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"fa-regular fa-house"}}),_c('h4',{staticClass:"m-0"},[_vm._v("Homes")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getHomes.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.homes,"options":_vm.options,"server-items-length":_vm.totalHomes,"loading":_vm.loading,"footer-props":{
                itemsPerPageOptions: [5, 25, 50],
            },"loading-text":"Getting homes...","search":_vm.search,"must-sort":true},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"header.subscriptionStatus",fn:function(ref){
            var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":true,"disabled":_vm.loading},on:{"input":_vm.handleSubscriptionStatusMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
            var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ top: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)+" ("+_vm._s(_vm.selectedStatuses.length)+") "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{staticStyle:{"width":"200px","overflow":"hidden"}},_vm._l((_vm.subscriptionStatuses),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"height":"20px"}},[_c('v-checkbox',{attrs:{"label":item.label,"value":index},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})],1)}),1)],1)]}},{key:"header.lastEventTime",fn:function(ref){
            var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
            var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ top: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{class:[{ calendarShowing: _vm.calendarVisible }, 'lastServiceMenu'],staticStyle:{"padding":"1rem"}},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"0.8rem","font-weight":"bold"}},[_vm._v(" Select to filter before or after date ")]),_c('v-select',{attrs:{"items":_vm.lastServiceSymbols},model:{value:(_vm.selectedLastServiceSymbol),callback:function ($$v) {_vm.selectedLastServiceSymbol=$$v},expression:"selectedLastServiceSymbol"}}),(_vm.selectedLastServiceSymbol === 1 || _vm.selectedLastServiceSymbol === 2)?_c('div',[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"0.8rem","font-weight":"bold"}},[_vm._v("Select Date")]),_c('calendar',{attrs:{"output-local":true},on:{"input":_vm.showCalendar,"close":_vm.hideCalendar},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"position-relative"},[_vm._v(" "+_vm._s(item.name)+" "),(item.oldFuneralHomeId === -1)?_c('div',{staticStyle:{"line-height":"0.9rem"}},[_c('small',{staticStyle:{"color":"#ffc400"}},[_vm._v(" Reverted Home "+_vm._s(item.oldFuneralHomeId)+" ")]),_c('div',{staticClass:"reverted-home-border"})]):(item.oldFuneralHomeId !== null && item.oldFuneralHomeId !== 0)?_c('div',{staticStyle:{"line-height":"0.9rem"}},[_c('small',{staticStyle:{"color":"#750085"}},[_vm._v(" Migrated Home "+_vm._s(item.oldFuneralHomeId)+" ")]),_c('div',{staticClass:"migrated-home-border"})]):(item.devHome)?_c('div',{staticStyle:{"line-height":"0.9rem"}},[_c('small',{staticStyle:{"color":"#ff530d"}},[_vm._v("Dev Home")]),_c('div',{staticClass:"dev-home-border"})]):_vm._e()])]}},{key:"item.contact",fn:function(ref){
            var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.location",fn:function(ref){
            var item = ref.item;
return [(item.city && item.state)?_c('span',[_vm._v(_vm._s(item.city + ', ' + item.state))]):_c('span',[_vm._v(_vm._s(item.city + '' + item.state))])]}},{key:"item.lastEventTime",fn:function(ref){
            var item = ref.item;
return [(item.lastEventTime)?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("moment")(item.lastEventTime,'M/D/YY')))]):_vm._e(),(item.lastEventTime)?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("moment")(item.lastEventTime,'h:mm a')))]):_vm._e()]}},{key:"item.integrations",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","gap":"10px"}},[_c('div',{staticStyle:{"width":"30px"}},[(item.passareIntegration)?_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-img',{attrs:{"src":_vm.passareLogo}})]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Passare")])]},proxy:true}],null,true)}):_vm._e()],1),_c('div',{staticClass:"p-1",staticStyle:{"width":"30px"}},[(item.tukiosIntegration)?_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-img',{attrs:{"src":_vm.tukiosLogo}})]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Tukios")])]},proxy:true}],null,true)}):_vm._e()],1),_c('div',{staticClass:"p-1",staticStyle:{"width":"30px"}},[(item.facebookIntegration)?_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-img',{attrs:{"src":_vm.fbLogo}})]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Facebook")])]},proxy:true}],null,true)}):_vm._e()],1)])]}},{key:"item.subscriptionStatus",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.subscriptionStatuses[item.subscriptionStatus].color}},[_vm._v(" "+_vm._s(_vm.subscriptionStatuses[item.subscriptionStatus].label)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"action-list"},[_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.goToSettings(item.id)}}},'span',_vm.attrs,false),_vm.on),[_c('font-awesome-icon',{staticStyle:{"font-size":"1rem"},attrs:{"icon":"fa-regular fa-gear"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Settings")])]},proxy:true}],null,true)}),_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',[_c('font-awesome-icon',{staticStyle:{"font-size":"1rem"},attrs:{"icon":"fa-regular fa-user-group"},on:{"click":function($event){$event.stopPropagation();return _vm.getUsers(item)}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Users")])]},proxy:true}],null,true)}),_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.impersonate(item.id)}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"1rem"},attrs:{"icon":"fa-regular fa-arrow-up-right-from-square"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Impersonate")])]},proxy:true}],null,true)})],1)]}}])})],1),_c('users-table',{ref:"usersTable",attrs:{"itemId":_vm.item.id}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.getHomes()}}},'v-btn',attrs,false),[_vm._v(" Retry ")])]}}]),model:{value:(_vm.errorLoading),callback:function ($$v) {_vm.errorLoading=$$v},expression:"errorLoading"}},[_vm._v(" Error getting data ")]),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
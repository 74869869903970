//Img.ly vue setup docs //https://img.ly/docs/pesdk/web/guides/vue-js/

<template>
    <div class="imgly-editor-section">
        <div :key="refreshKey" ref="container" style="max-height: 100vh" id="imgly-container" class="imgly-container" />

        <v-dialog v-model="showProgress" max-width="400px">
            <v-card dark>
                <div class="p-3">
                    <p>Progress: {{ progress }}%</p>
                    <v-progress-linear
                        :color="progress == 100 ? 'success' : 'primary'"
                        rounded
                        v-model="progress"
                    ></v-progress-linear>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width="400px">
            <v-card>
                <v-card-title>Delete Photo?</v-card-title>
                <v-card-text>This connot be undone, confirm to continue.</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="deleteModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="$emit('delete')">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="revertModal" max-width="400px">
            <v-card>
                <v-card-title> Revert to Original? </v-card-title>
                <v-card-text
                    >This will return the photo to the original version and any edits will be lost.
                    <strong>This cannot be undone</strong>, confirm to continue.</v-card-text
                >

                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="revertModal = false">Cancel</v-btn>
                    <v-btn depressed color="error" @click="submitRevert">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="300px" v-model="colorPickerModal">
            <v-card>
                <v-color-picker
                    v-model="selectedColor"
                    dot-size="25"
                    mode="hexa"
                    swatches-max-height="200"
                ></v-color-picker>
                <v-divider></v-divider>
                <div class="pb-3 text-center">
                    <v-btn color="primary" depressed @click="handleBackgroundColorSelect">Select Color</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { ExportFormat, ImageFormat, PhotoEditorSDKUI, Tool, UIEvent } from 'photoeditorsdk';
import React from 'react';
import ReactDom from 'react-dom';

window.React = window.React || React;
window.ReactDom = window.ReactDom || ReactDom;

const supportedUis = ['advanced', 'basic'];
const supportedThemes = ['dark', 'light'];

import { imglyCardBgAssets } from '@/constants';

export default {
    name: 'PhotoEditor',
    props: {
        layout: {
            type: String,
            default: 'advanced',
            validator: value => supportedUis.some(type => type === value),
        },
        theme: {
            type: String,
            default: 'dark',
            validator: value => supportedThemes.some(type => type === value),
        },
        imagePath: {
            type: String,
            required: true,
            default: '',
        },
        assetPath: {
            type: String,
            default: '/imgly-assets',
        },
        options: {
            type: Object,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        blankImage: {
            type: Boolean,
            default: false,
        },
        photo: {
            type: Object,
        },
        token: {
            type: String,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        editor: null,
        image: '',
        showProgress: false,
        progress: 0,
        refreshKey: 0,
        backgroundColor: 'white',
        deleteModal: false,
        revertModal: false,
        cardBackgrounds: imglyCardBgAssets,
        colorPickerModal: false,
        selectedColor: {},
    }),

    watch: {
        layout() {
            if (this.visible) {
                this.renderUi();
            }
        },
        visible() {
            if (this.editor) {
                this.editor.dispose();
                this.editor = null;
            }

            this.refreshKey++;
            if (this.visible) {
                this.initImgly();
            }
        },
        imagePath() {
            if (this.editor) {
                this.editor.setImage(this.imagePath);
            }
        },
        token() {
            if (this.token) {
                this.createAxiosInstance();
            }
        },
    },
    async mounted() {
        // await this.setAuthToken();
        this.createAxiosInstance();
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.dispose();
            this.editor = null;
        }
    },
    methods: {
        ...mapActions(['showSnackbar']),
        resetEditor() {
            this.editor = null;
            this.refreshKey++;
            this.initImgly();
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleBackgroundColorSelect() {
            this.setBackgroundColor(this.selectedColor.hex);
            this.colorPickerModal = false;
        },
        checkRemainingAllowedUploads(eventId) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/remaining-allowed-uploads/${eventId}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');
                    // 'response.data', response.data;
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async handleExport(image) {
            try {
                this.showProgress = true;

                if (this.photo) {
                    let SAS_INFO = await this.getUploadUrl(this.photo.name);

                    await this.uploadToEndpoint(image, SAS_INFO.sas, ev => {
                        this.progress = Math.round(ev.loaded / ev.total) * 100;
                    });

                    const replacement = await this.replacePhoto(this.photo.id, SAS_INFO.fileName, true);
                    this.$emit('export-success', replacement.data);
                    this.showSnackbar({ message: 'Photo updated' });
                } else {
                    var remainingAllowed = await this.checkRemainingAllowedUploads(this.eventId);

                    if (remainingAllowed.remaining == 0) {
                        // this.showSnackbar({
                        //     message: `Cannot exceed remaining allowed uploads: ${remainingAllowed.remaining}`,
                        //     color: 'error',
                        // });

                        throw new Error(`Cannot exceed remaining allowed uploads: ${remainingAllowed.remaining}`);
                    }

                    let SAS_INFO = await this.getUploadUrl('Title Slide.png');

                    await this.uploadToEndpoint(image, SAS_INFO.sas, ev => {
                        this.progress = Math.round(ev.loaded / ev.total) * 100;
                    });

                    let data = [
                        {
                            duration: 0,
                            mediaType: 2,
                            name: 'Title Slide.png',
                            url: SAS_INFO.sas.split('?sv=')[0],
                            uniqueName: SAS_INFO.fileName,
                            uploadSource:
                                this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'
                                    ? 2
                                    : 0,
                            uploadUserName:
                                this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'
                                    ? 'Unknown'
                                    : this.$auth.user.name,
                            uploadUserRelationship:
                                this.$route.name == 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'
                                    ? 'Unknown'
                                    : 'Funeral Staff',
                        },
                    ];

                    const result = await this.axiosInstance.post(`/TributeVideoPhoto/${this.eventId}`, data);

                    this.$emit('export-success');
                    this.showSnackbar({ message: 'Card saved' });
                }
                this.resetEditor();
            } catch (err) {
                if (err.message && err.message.includes('Cannot exceed remaining allowed uploads')) {
                    this.showSnackbar({ message: err.message, color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error updating photo', color: 'error' });
                }
            }
            this.showProgress = false;
            setTimeout(() => {
                this.progress = 0;
            }, 500);
        },
        async getUploadUrl(name) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/sas/${this.eventId}/${name}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');

                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updatePhotoFileName(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeVideoPhoto/${id}`, JSON.stringify(fileName))
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        async uploadToEndpoint(blob, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        this.axios
                            .create({
                                headers: {
                                    'Content-Type': 'image/jpg',
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(response => {
                                resolve('done');
                            });
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        replacePhoto(id, fileName, keepOriginal) {
            let data = {
                fileName: fileName,
            };

            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(
                    process.env.VUE_APP_API + `/TributeVideoPhoto/replace-photo/${id}?keepOriginal=${keepOriginal}`,
                    data,
                )
                .then(res => {
                    return res;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        initImgly() {
            this.image = new Image();
            var url = '';
            if (this.blankImage) {
                // const canvas = document.createElement('canvas');
                // canvas.width = 800; // Set the desired width
                // canvas.height = 600; // Set the desired height
                // const context = canvas.getContext('2d');

                // // Draw your new image on the canvas
                // context.fillStyle = this.backgroundColor; // Set the desired background color
                // context.fillRect(0, 0, canvas.width, canvas.height);
                // // You can add more drawing operations like lines, shapes, text, etc.

                // // Convert the canvas to a data URL
                // const dataURL = canvas.toDataURL('image/png');

                // url = dataURL;

                //Default to this image on card create
                url = 'https://photos.memoryshare.com/imgly-assets/sky1.jpg';
            } else if (this.imagePath) {
                url = this.imagePath;
            }

            this.image.onload = this.renderUi.bind(this);
            this.image.src = url;

            this.image.onerror = () => {
                console.log('Error occurred while loading the image');
                this.$emit('image-error');
            };
        },
        setBackgroundColor(color) {
            this.image = new Image();
            var url = '';
            if (this.blankImage) {
                const canvas = document.createElement('canvas');
                canvas.width = 800; // Set the desired width
                canvas.height = 600; // Set the desired height
                const context = canvas.getContext('2d');

                // Draw your new image on the canvas
                context.fillStyle = color; // Set the desired background color
                context.fillRect(0, 0, canvas.width, canvas.height);
                // You can add more drawing operations like lines, shapes, text, etc.

                // Convert the canvas to a data URL
                const dataURL = canvas.toDataURL('image/png');

                url = dataURL;
            } else if (this.imagePath) {
                url = this.imagePath;
            }

            this.editor.setImage(url);
        },
        setBackgroundImage(imageUrl) {
            this.image = new Image();
            var url = '';
            if (this.blankImage) {
                const canvas = document.createElement('canvas');
                canvas.width = 800; // Set the desired width
                canvas.height = 600; // Set the desired height
                const context = canvas.getContext('2d');

                // Create a new image object
                let img = new Image();

                // Set the crossOrigin attribute
                img.crossOrigin = 'anonymous';

                // Set the source of the image object to the provided URL
                img.src = imageUrl;

                // When the image is loaded, draw it on the canvas
                img.onload = () => {
                    context.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Convert the canvas to a data URL
                    const dataURL = canvas.toDataURL('image/png');

                    url = dataURL;
                    this.editor.setImage(url);
                };
            }
        },
        openColorPicker() {
            this.colorPickerModal = true;
        },
        downloadImage() {
            if (this.editor) {
                this.editor.editor.serialize().then(img => {
                    const imageDataUrl = 'data:image/png;base64,' + img.image.data;

                    // // Create an anchor element to download the image
                    const downloadLink = document.createElement('a');
                    downloadLink.href = imageDataUrl;

                    // // Set the 'download' attribute to specify the file name
                    downloadLink.download = 'image.png';

                    // // Append the anchor element to the DOM (it should be in the document for the download to work)
                    document.body.appendChild(downloadLink);
                    // // Trigger the download
                    downloadLink.click();

                    // // Clean up: remove the anchor element from the DOM after download
                    document.body.removeChild(downloadLink);
                });
            }
        },
        createButton(text, onClickHandler, includeTouchStart) {
            var defaultClasses = 'toolbar-control-button';

            if (text.toLowerCase() === 'download') {
                defaultClasses += ' download-btn';
            }

            if (text.toLowerCase() === 'delete') {
                defaultClasses += ' delete-btn';
            }

            return React.createElement(
                'button',
                {
                    key: text,
                    className: defaultClasses,
                    onClick: onClickHandler,
                    // onTouchStart: onClickHandler,
                    onMouseEnter: event => event.target.classList.add('active'),
                    onMouseLeave: event => event.target.classList.remove('active'),
                },
                text,
            );
        },
        createGrid(buttonData, onClickHandler) {
            var defaultClasses = 'toolbar-control-image';
            var gridElements = [];

            for (let i = 0; i < buttonData.length; i++) {
                let thumb = buttonData[i].thumbnail;
                let link = buttonData[i].link;

                let imageElement = React.createElement('img', {
                    key: thumb + i,
                    src: thumb,
                    alt: 'Background Image ' + i,
                    className: defaultClasses,
                    onClick: () => onClickHandler(link),
                    onMouseEnter: event => event.target.classList.add('active'),
                    onMouseLeave: event => event.target.classList.remove('active'),
                    style: {
                        animation: 'fadeInSlideDown 1s ease-in-out forwards',
                        animationDelay: i * 0.1 + 's',
                    },
                });

                gridElements.push(imageElement);
            }

            // All images have loaded, return the grid
            const grid = React.createElement(
                'div',
                {
                    key: 'image-sel',
                    className: this.layout == 'basic' ? 'button-flex' : 'button-grid',
                },
                gridElements,
            );

            const container = React.createElement(
                'div',
                {
                    key: 'grid-container',
                    className: 'grid-container',
                },
                grid,
            );
            return container;
        },
        handleDelete() {
            this.deleteModal = true;
        },
        initRevert() {
            this.revertModal = true;
        },
        submitRevert() {
            this.$emit('revert-original');
            this.revertModal = false;
        },
        handleMoveStart() {
            this.$emit('move-start');
        },
        handleMoveEnd() {
            this.$emit('move-end');
        },
        generateCustomToolControlBar() {
            const bgColorBtn = this.createButton('Background Color', this.openColorPicker, true);

            const bgImageSel = this.createGrid(this.cardBackgrounds, this.setBackgroundImage);

            const downloadBtn = this.createButton('Download', this.downloadImage);
            const revertOriginalBtn = this.createButton('Revert to Original', this.initRevert);
            const moveFirstBtn = this.createButton('Send To Beginning', this.handleMoveStart);
            const moveEndBtn = this.createButton('Send To End', this.handleMoveEnd);

            var cardBtns = [bgColorBtn, bgImageSel];

            var photoBtns = this.photo?.originalPhoto
                ? [moveFirstBtn, moveEndBtn, revertOriginalBtn, downloadBtn]
                : [moveFirstBtn, moveEndBtn, downloadBtn];

            const buttons = this.blankImage ? cardBtns : photoBtns;

            var controlBar = null;
            const deleteBtn = this.createButton('Delete', this.handleDelete);
            controlBar = this.renderControlBar(buttons, deleteBtn);

            return controlBar;
        },
        renderControlBar(buttons, delBtn) {
            if (this.layout == 'basic') {
                return React.createElement(
                    'div',
                    {
                        className: 'toolbar-control-container-basic',
                    },
                    React.createElement(
                        'div',
                        {
                            className: this.blankImage ? 'control-top-column' : 'control-top-row',
                        },
                        [...buttons, delBtn],
                    ),
                );
            } else {
                return React.createElement(
                    'div',
                    {
                        className: 'toolbar-control-container-advanced',
                    },
                    React.createElement(
                        'div',
                        {
                            className: 'control-top-column',
                        },
                        buttons,
                    ),
                    React.createElement(
                        'div',
                        {
                            className: 'control-bottom',
                        },
                        this.blankImage ? '' : delBtn,
                    ),
                );
            }
        },
        renderCustomToolIcon() {
            if (this.layout == 'advanced') {
                return React.createElement(
                    'div',
                    { className: 'toolbar-icon' },
                    React.createElement('span', {
                        className: this.blankImage ? 'mdi mdi-image-outline' : 'mdi mdi-cog',
                    }),
                );
            }
            return null;
        },
        async renderUi() {
            try {
                if (this.editor) {
                    this.editor.dispose();
                }

                const imglyContainer = document.getElementById('imgly-container');

                if (!imglyContainer) return;

                const license = process.env.VUE_APP_IMGLY_LICENSE;

                const editor = await PhotoEditorSDKUI.init({
                    ...this.options,
                    image: this.image,
                    layout: this.layout,
                    theme: 'light',
                    container: this.$refs.container,
                    license: license,
                    assetBaseUrl: this.assetPath,
                    export: {
                        image: {
                            enableDownload: false,
                            format: ImageFormat.JPEG,
                            quality: 1,
                            exportType: ExportFormat.BLOB,
                        },
                    },
                    defaultTool: this.blankImage ? 'text' : 'filter',
                    tools: [
                        this.blankImage ? [] : [Tool.TRANSFORM, Tool.ADJUSTMENT, Tool.FILTER],
                        Tool.TEXT,
                        Tool.TEXT_DESIGN,
                        Tool.CUSTOM,
                    ],
                    [Tool.CUSTOM]: {
                        // icon: () =>
                        //     React.createElement(
                        //         'div',
                        //         { className: 'toolbar-icon' },
                        //         React.createElement('span', { className: 'mdi mdi-cog' }),
                        //     ),
                        icon: () => this.renderCustomToolIcon(),
                        toolControlBar: () => this.generateCustomToolControlBar(),
                    },
                    displayCloseWarning: true,
                    displayDiscardChangesWarning: true,
                    displayToolControlBarTitle: true,
                    text: {
                        // color is represented as a number array which encodes
                        // as a RGBA tuple of floating point values where
                        // each channel is defined in the range of `[0, 1]
                        defaultColor: this.blankImage ? [0.0, 0.0, 0.0, 1] : [1.0, 1.0, 1.0, 1],
                        defaultBackgroundColor: [0, 0, 0, 0],
                        addDefaultTextOnEnter: false,
                    },
                    textdesign: {
                        defaultColor: this.blankImage ? [0.0, 0.0, 0.0, 1] : [1.0, 1.0, 1.0, 1],
                        addDefaultTextOnEnter: false,
                    },
                    transform: {
                        flattenCategories: true,
                        categories: [
                            {
                                identifier: 'imgly_transforms_common',
                                items: [
                                    { identifier: 'imgly_transform_common_custom' },
                                    { identifier: 'imgly_transform_common_square' },
                                    { identifier: 'imgly_transform_common_4' },
                                    { identifier: 'imgly_transform_common_16' },
                                    { identifier: 'imgly_transform_common_3' },
                                    { identifier: 'imgly_transform_common_9' },
                                ],
                            },
                        ],
                        advancedUIToolControlBarOrder: [
                            'resetTransformButton',
                            'keepResolutionCheckbox',
                            'cropSizeInput',
                            'separator',
                            'items',
                        ],
                    },
                    custom: {
                        languages: {
                            en: {
                                mainCanvasActions: {
                                    buttonExport: 'Save',
                                },
                                customTool: {
                                    title: 'More',
                                },
                                textdesign: {
                                    canvasControls: {
                                        placeholderText: `Every end is a new beginning.`,
                                    },
                                },
                                text: {
                                    canvasControls: {
                                        //placeholderText: `There are no goodbyes for us. Wherever you are, you will always be in my heart. \n -Gandhi`,
                                        placeholderText: `Every end is a new beginning.`,
                                    },
                                },
                            },
                        },
                        measurements: {
                            advancedUIToolControlBar: {
                                width: 280,
                            },
                            advancedUIToolbar: {
                                width: 80,
                                marginLeft: 0,
                            },
                            basicUIToolbar: {
                                openHeight: 60,
                                closeHeight: 60,
                            },
                        },
                        themes: {
                            light: {
                                toolbar: {
                                    activeForeground: '#ff530d',
                                },
                            },
                            dark: {
                                primary: '#ff530d',
                                button: {
                                    /** High Emphasis button - primary variant e.g.: Export button */
                                    containedPrimaryBackground: '#ff530d',
                                    containedPrimaryForeground: 'rgba(255, 255, 255, 0.90)',

                                    /** Medium Emphasis button - primary variant e.g.: New Text, Text Design, Upload on toolControlBar */
                                    outlinedPrimaryBackground: '#242424',
                                    outlinedPrimaryForeground: 'rgba(255, 255, 255, 0.90)',

                                    /** Medium Emphasis button - secondary variant e.g.: Remove Filter, Overlay, Shuffle Text Design */
                                    outlinedSecondaryBackground: 'transparent',
                                    outlinedSecondaryForeground: 'rgba(255, 255, 255, 0.90)',

                                    outlinedInactiveOverlayColor: 'rgba(0, 0, 0, 0.3)',
                                    outlinedBorderColor: '#333333',

                                    /** Low Emphasis button - primary variant e.g.: primary button in modal */
                                    textPrimaryForeground: '#ff530d',
                                    /** Low Emphasis button - secondary variant e.g.: secondary button in modal, undo, redo, Carousel navigators, alignment buttons */
                                    textSecondaryForeground: 'rgba(255, 255, 255, 0.90)',

                                    textInactiveOpacity: '0.5',

                                    activeForeground: '#ff530d',

                                    hoverOverlayColor: 'rgba(255, 255, 255, 0.08)',
                                },
                                toolbar: {
                                    foreground: 'rgba(255, 255, 255, 0.90)',
                                    background: '#242424',
                                    activeBackground: 'transparent',
                                    activeForeground: '#ff530d',
                                    /** border between the toolbar and the toolControlBar **/
                                    borderColor: 'transparent',
                                    /** this will overwrite borderColor and apply a border of your choosing **/
                                    border: 'none',
                                },
                                toolControlBar: {
                                    background: 'rgba(23, 23, 23, 0.9)',
                                    /** Border between toolbar and toolControlBar **/
                                    borderColor: 'transparent',
                                    /** Separators between different sections in toolControlBar **/
                                    separatorColor: '#333333',
                                    titleForeground: 'rgba(255, 255, 255, 0.60)', // Relevant only for AdvancedUI
                                    inputLabelForeground: 'rgba(255, 255, 255, 0.60)',
                                    inputLabelInactiveOpacity: '0.3',
                                },
                                tabTitle: {
                                    foreground: 'rgba(255, 255, 255, 0.60)',
                                    background: 'transparent',
                                    activeBackground: 'transparent',
                                    activeBorderColor: '#ff530d',
                                    activeForeground: 'rgba(255, 255, 255, 0.90)',
                                },
                                card: {
                                    background: '#333333',
                                    foreground: 'rgba(255, 255, 255, 0.90)',
                                    activeBorderColor: '#ff530d',
                                    activeOverlayColor: 'rgba(255, 255, 255, 0.3)',
                                    /** card in PhotoEditor SDK almost always has a label */
                                    labelBackground: 'linear-gradient(transparent, rgba(0, 0, 0, 0.6))',
                                    labelForeground: 'rgba(255, 255, 255, 0.90)',
                                    inactiveOverlayColor: 'rgba(0, 0, 0, 0.3)',
                                    hoverOverlayColor: 'rgba(255, 255, 255, 0.08)',
                                },

                                snapping: {
                                    positionGuideColor: '#ff530d',
                                    rotationGuideColor: '#ff530d',
                                },
                            },
                        },
                    },
                    engine: {
                        crossOrigin: 'anonymous',
                        downscaleOptions: {
                            maxMegaPixels: {
                                desktop: 10,
                                mobile: 5,
                            },
                            maxDimensions: {
                                height: 1920,
                                width: 1080,
                            },
                        },
                    },
                })
                    .then(editor => {
                        editor.on(UIEvent.EXPORT, async image => {
                            // TODO: Set up export once SDK license is ready
                            this.handleExport(image);
                        });
                        editor.on(UIEvent.CLOSE, async () => {
                            // TODO: Set up export once SDK license is ready
                            this.$emit('close');
                        });
                        editor.on(UIEvent.ERROR_WEBGL_CONTEXT_LOST, evt => {
                            console.log('WebGL Context Error caught!', evt);
                        });
                        editor.on(UIEvent.TOOL_ENTER, evt => {
                            // console.log('tool enter', evt);
                            // if (evt == 'text') {
                            //     const input = document.querySelector("[data-test='NummericInput'] input");
                            //     console.log(input, 'input');
                            //
                            // }
                        });
                        this.editor = editor;

                        // Inject Custom Icons
                        const transform = document.querySelector("[data-test='ToolbarItem'][aria-label='Transform']");
                        const adjust = document.querySelector("[data-test='ToolbarItem'][aria-label='Adjust']");
                        const filters = document.querySelector("[data-test='ToolbarItem'][aria-label='Filters']");
                        const text = document.querySelector("[data-test='ToolbarItem'][aria-label='Text']");
                        const design = document.querySelector("[data-test='ToolbarItem'][aria-label='Text Design']");

                        const transformSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448 109.3l54.6-54.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L402.7 64 160 64v64l178.7 0L128 338.7V32c0-17.7-14.3-32-32-32S64 14.3 64 32V64H32C14.3 64 0 78.3 0 96s14.3 32 32 32H64V384c0 35.3 28.7 64 64 64H352V384H173.3L384 173.3 384 480c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H448l0-274.7z"/></svg>`;
                        const adjustSVG = ` <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>`;
                        const filtersSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>`;
                        const textSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z"/></svg>`;
                        const designSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2S517.7-4.4 499.1 9.6L262.4 187.2c-24 18-38.2 46.1-38.4 76.1L339.3 367.1zm-19.6 25.4l-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9 .2 7.8 .6 11.6C98.4 429.1 86.4 448 68.8 448H64c-17.7 0-32 14.3-32 32s14.3 32 32 32H208c61.9 0 112-50.1 112-112c0-2.5-.1-5-.2-7.5z"/></svg>`;

                        let parser = new DOMParser();
                        let newSvgElement = parser.parseFromString(transformSVG, 'image/svg+xml').querySelector('svg');
                        let oldSvg = transform.querySelector('svg');
                        transform.replaceChild(newSvgElement, oldSvg);

                        parser = new DOMParser();
                        newSvgElement = parser.parseFromString(adjustSVG, 'image/svg+xml').querySelector('svg');
                        oldSvg = adjust.querySelector('svg');
                        adjust.replaceChild(newSvgElement, oldSvg);

                        parser = new DOMParser();
                        newSvgElement = parser.parseFromString(filtersSVG, 'image/svg+xml').querySelector('svg');
                        oldSvg = filters.querySelector('svg');
                        filters.replaceChild(newSvgElement, oldSvg);

                        parser = new DOMParser();
                        newSvgElement = parser.parseFromString(textSVG, 'image/svg+xml').querySelector('svg');
                        oldSvg = text.querySelector('svg');
                        text.replaceChild(newSvgElement, oldSvg);

                        parser = new DOMParser();
                        newSvgElement = parser.parseFromString(designSVG, 'image/svg+xml').querySelector('svg');
                        oldSvg = design.querySelector('svg');
                        design.replaceChild(newSvgElement, oldSvg);
                    })
                    .catch(err => {
                        console.log('IMGLY INIT ERROR', err);
                    })
                    .finally(() => {});
            } catch (error) {
                console.log('RENDER IMGLY ERROR', error);
            }
        },
    },
};
</script>
<style lang="scss">
.imgly-container {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
.imgly-editor-section {
    .toolbar-control-button {
        font-weight: 900;
        cursor: pointer;
        background: #242424;
        color: rgba(255, 255, 255, 0.9);
        border: 1px solid #333333;
        // min-width: 32px;
        min-width: fit-content;
        white-space: nowrap;
        text-align: center;
        position: relative;
        padding: 4px 8px;
        min-height: 32px;
        border-radius: 4px;
        font-size: 14px !important;
        -webkit-letter-spacing: 0px;
        -moz-letter-spacing: 0px;
        -ms-letter-spacing: 0px;
        letter-spacing: 0px;
        font-family: Avenir, Helvetica, Arial, sans-serif !important;
    }

    .active {
        background-color: rgb(101, 101, 101) !important;
    }

    .toolbar-control-container-advanced {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        gap: 10px;
        max-height: 100%;
        flex-grow: 1;
        overflow: auto;

        .control-top-column {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            width: 100%;
            gap: 10px;
            overflow: auto;
        }
        .control-bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
        }
    }
    .toolbar-control-container-basic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        gap: 10px;
        max-height: 200px;
        flex-grow: 1;
        overflow: hidden;

        .control-top-row {
            display: flex;
            flex-direction: row;
            padding-bottom: 10px;
            width: 100%;
            gap: 10px;
            overflow: auto;
        }
        .control-top-column {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            width: 100%;
            gap: 10px;
            overflow: auto;
        }
        .control-bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
        }
    }

    .toolbar-icon {
        width: 48px;
        font-size: 1.3rem;
    }

    .download-btn {
        background-color: rgba(25, 118, 210, 0.7);
        color: white;
    }
    .download-btn:hover {
        background-color: rgba(25, 118, 210, 1) !important;
    }

    .delete-btn {
        background-color: rgba(255, 82, 82, 0.7);
        color: white;
    }
    .delete-btn:hover {
        background-color: rgb(255, 82, 82) !important;
    }

    .toolbar-control-image {
        border-radius: 5px;
        object-fit: cover;
        // width: 125px;
        width: 100%;
        height: 100%;
        max-width: 125px;
        max-height: 83px;
        // height: 83px;
        cursor: pointer;
        opacity: 0;
    }

    .toolbar-control-image:hover {
        filter: opacity(70%);
    }

    .background-title {
        font-size: 1rem;
    }
    .button-grid {
        padding: 5px;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
    .button-flex {
        padding: 5px;
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }

    .grid-container {
        overflow-y: auto;
    }

    /* Define the fadeIn animation */
    @keyframes fadeInSlideDown {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

[data-test='MainBarButtonClose'] div,
[data-test='MainBarButtonExport'] div {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    text-transform: capitalize;
    size: 15px;
    padding: 4px 13px;
    border-radius: 6px;
    letter-spacing: 0;
}

[data-test='MainBarButtonExport'] {
    background: #ff530d !important;
    border: #ff530d !important;
}

[data-test='ZoomLevel'] {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    text-transform: capitalize;
    size: 15px;
    padding: 4px 13px;
    border-radius: 6px;
    letter-spacing: 0;
}
[data-test='Canvas'] {
    background: white;
}

[data-test='MainBarButtonUndo'] div,
[data-test='MainBarButtonRedo'] div {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    text-transform: capitalize;
    size: 15px;
    padding: 4px 6px;
    border-radius: 6px;
    letter-spacing: 0;
}

[data-test='NummericInput'] input,
[data-test='CategoryItemsContainer'] label,
[data-test='ToolControlBar'] h1,
[data-test='ToolControlBar'] label,
[data-test='ToolControlBar'] span,
[data-test='ToolControlBar'] div,
[data-test='ToolControlBar'] button {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

[data-test='ToolControlBar'] h2 {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    margin-bottom: 20px;
    size: 15px;
    text-transform: capitalize;
}
[data-test='ToolControlBar'] h1 {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    size: 15px;
    text-transform: capitalize;
}

[data-test='ToolControlBar'] input::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: #ff530d;
    border: none;
    overflow: visible;
    cursor: pointer;
}

[data-test='MainCanvasActionBar'] {
    height: 80px !important;
    background: white !important;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
}

[data-test='CanvasContainer'] {
    height: calc(100vh - 80px) !important;
    background: black !important;
}

[data-test='Toolbar'] li button {
    width: 48px;
    height: 48px;
}
</style>

<template>
    <div>
        <div v-if="allProducts.length > 0">
            <v-list flat subheader>
                <v-subheader>Selected Products</v-subheader>
                <v-list-item-group v-model="selectedProducts" multiple>
                    <v-list-item v-for="(item, index) in allProducts" :key="index" :value="item.id">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-checkbox :input-value="active" color="primary"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>{{ item.name }} </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
        <div class="text-right">
            <v-btn @click="updateProductList" color="primary">Save</v-btn>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            token: '',
            allProducts: [],
            selectedProducts: [],
            total: 0,
        };
    },
    props: ['funeralHomeId'],
    methods: {
        ...mapActions(['showSnackbar']),
        getallProducts() {
            this.axios
                .get(process.env.VUE_APP_API + `/Products?productStatus=1`)
                .then(response => {
                    this.allProducts = response.data.products;
                    this.total = response.data.total;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSelectedProducts() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/funeralhomes/get-products/${this.funeralHomeId}`)
                .then(response => {
                    this.selectedProducts = response.data.products.map(product => product.id);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateProductList() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API + `/funeralhomes/update-product-list/${this.funeralHomeId}`,
                    this.selectedProducts,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Product list updated' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating product list' });
                });
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
        });

        this.getallProducts();
        this.getSelectedProducts();
    },
};
</script>
<style lang=""></style>

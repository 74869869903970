<template>
    <div v-if="shipment">
        <div class="mb-5">
            <v-btn @click="$router.back()"><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Back</v-btn>
        </div>
        <v-card class="p-3">
            <v-card-title>
                <font-awesome-icon icon="fa-regular fa-truck-fast" style="margin-right: 0.5rem" />
                <h3 class="m-0">Shipment Details</h3>
                <v-spacer> </v-spacer>
                <!-- <v-chip dark v-if="shipment.status >= 0" :color="shipmentStatuses[shipment.status].color">{{
                    shipmentStatuses[shipment.status].label
                }}</v-chip> -->
                <div v-if="$auth.role.includes('SuperAdmin') && shipment.status != null">
                    <v-menu offset-y v-if="shipment.status >= 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" dark :color="shipmentStatuses[shipment.status].color">{{
                                shipmentStatuses[shipment.status].label
                            }}</v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in shipmentStatuses"
                                :key="index"
                                @click="updateShipmentStatus(shipment.id, item.value)"
                            >
                                <v-list-item-title>{{ item.label }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div v-else>
                    <v-chip
                        v-if="shipment.status != null && status >= 0"
                        dark
                        :color="shipemtntStatuses[shipment.status].color"
                        >{{ shipmentStatuses[shipment.status].label }}</v-chip
                    >
                </div>
            </v-card-title>
            <div class="p-3">
                <v-row>
                    <v-col cols="12" :md="shipment.toCity ? '6' : '12'">
                        <div class="details elevation-2">
                            <strong>Tracking</strong>
                            <v-divider></v-divider>

                            <div class="tracking-group" :class="shipment.toCity ? '' : 'conditional-flex'">
                                <div>
                                    <div>
                                        <strong>{{ shipment.trackingNumber }}</strong>
                                    </div>
                                    <div v-if="shipment.carrier >= 0">{{ carriers[shipment.carrier].label }}</div>
                                    <div>Cost {{ shipment.cost | formatMoney() }}</div>
                                </div>
                                <div>
                                    <div>Order ID: {{ shipment.orderId }}</div>
                                    <div v-if="$auth.role.includes('SuperAdmin')">
                                        Purchase ID: {{ shipment.purchaseId }}
                                    </div>
                                    <div>
                                        {{ $moment.utc(shipment.createdDate).local().format('lll') }}
                                        <i style="color: gray">Created</i>
                                    </div>
                                    <div>
                                        {{ $moment.utc(shipment.updatedDate).local().format('lll') }}
                                        <i style="color: gray">Updated</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="shipment.toCity" cols="12" md="6">
                        <div class="details elevation-2">
                            <strong>Destination</strong>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col v-if="shipment.fromCity" cols="12" sm="6">
                                    <div>
                                        <div class="gray">From</div>
                                        <div>{{ shipment.fromAddress1 }}</div>
                                        <div>{{ shipment.fromAddress2 }}</div>
                                        <div>
                                            {{ shipment.fromCity }} {{ shipment.fromState }}, {{ shipment.fromZipCode }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col v-if="shipment.toCity" cols="12" sm="6">
                                    <div>
                                        <div class="gray">To</div>
                                        <div>{{ shipment.toAddress1 }}</div>
                                        <div>{{ shipment.toAddress2 }}</div>
                                        <div>
                                            {{ shipment.toCity }} {{ shipment.toState }}, {{ shipment.toZipCode }}
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <div class="details elevation-2">
                            <strong>Actions</strong>
                            <v-divider></v-divider>

                            <div class="btn-group">
                                <v-btn
                                    v-if="shipment.trackingUrl"
                                    class="mb-2"
                                    @click="newTab(shipment.trackingUrl)"
                                    small
                                    color="primary"
                                    >Track</v-btn
                                >

                                <v-btn v-if="shipment.labelUrl" @click="newTab(shipment.labelUrl)" small color="primary"
                                    >Label</v-btn
                                >
                                <v-btn
                                    v-if="shipment.easyPostTrackerId"
                                    small
                                    class="mt-2"
                                    dark
                                    @click="openEditTracker"
                                    >Edit Tracker</v-btn
                                >
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="details elevation-2">
                            <strong>Line Items</strong>
                            <v-divider></v-divider>
                            <div v-for="(item, index) in shipment.shipmentLineItems">
                                <div style="display: flex; align-items: center">
                                    <div v-if="item.image">
                                        <v-badge :content="item.shipQuantity" overlap>
                                            <img
                                                class="product-img"
                                                :src="item.image.split(',')[0]"
                                                alt="Product Image"
                                            />
                                        </v-badge>
                                    </div>
                                    <div v-else>
                                        <v-badge :content="item.shipQuantity" overlap>
                                            <img
                                                class="alt-product-img"
                                                :src="require(`@/assets/images/logo.png`)"
                                                alt="Alt Product Image"
                                            />
                                        </v-badge>
                                    </div>
                                    <span class="ml-4">{{ item.productName }}</span>
                                </div>
                                <v-divider v-if="index != shipment.shipmentLineItems.length - 1"></v-divider>
                            </div>
                            <div v-if="shipment.shipmentLineItems && shipment.shipmentLineItems.length == 0">
                                <div class="not-found">No line items found for this shipment</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <v-dialog v-model="editTrackerModal" max-width="400px">
            <v-card class="p-3">
                <v-card-title> Edit Tracking Information </v-card-title>
                <v-text-field label="Tracking Number" v-model="newTrackingNum"></v-text-field>
                <v-select
                    label="Carrier"
                    v-model="newCarrier"
                    item-text="label"
                    item-value="value"
                    :items="carriers"
                    value="0"
                ></v-select>
                <div style="display: flex; flex-direction: column">
                    <v-btn color="primary" :disabled="newTrackingNum.length == 0" @click="submitTrackerEdit(id)"
                        >Submit</v-btn
                    >
                    <v-btn class="mt-2" @click="editTrackerModal = false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { shipmentStatuses, carriers } from '@/constants';
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            carriers,
            shipmentStatuses,
            shipment: {},
            editTrackerModal: false,
            newTrackingNum: '',
            newCarrier: 0,
        };
    },

    methods: {
        ...mapActions(['showSnackbar']),
        updateShipmentStatus(id, status) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .put(process.env.VUE_APP_API + `/Shipping/update-shipment-status/${id}?status=${status}`)
                        .then(response => {
                            this.showSnackbar({ message: 'Status updated' });
                            this.getShipment(this.shipment.id);
                        })
                        .catch(error => {
                            console.log(error);
                            this.showSnackbar({ message: 'Error updating status' });
                        });
                });
            } else {
                this.showSnackbar({ message: 'Must be admin to update status' });
            }
        },
        submitTrackerEdit(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .post(
                        process.env.VUE_APP_API +
                            `/Shipping/edit-ext-tracker/${id}?carrier=${this.newCarrier}&trackingNum=${this.newTrackingNum}`,
                    )
                    .then(response => {
                        this.editTrackerModal = false;
                        this.getShipment(this.id);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
        openEditTracker() {
            this.editTrackerModal = true;
            this.newTrackerNum = '';
            this.newCarrier = 0;
        },
        newTab(url) {
            window.open(url, '_blank');
        },
        getShipment(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/Shipping/get-shipment/${id}`)
                    .then(response => {
                        this.shipment = response.data;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
    },
    props: ['id'],
    mounted() {
        this.getShipment(this.id);
    },
};
</script>
<style scoped>
.conditional-flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
}
.btn-group {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}
.gray {
    color: gray;
}
.details {
    border: 2px solid #eee;
    border-radius: 10px;
    /* padding: 10px; */
    text-align: center;
}
.product-img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 10px;
}
.alt-product-img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 10px;
}
.not-found {
    border: 2px solid #eee;
    border-radius: 10px;
    padding: 5px;
}
@media only screen and (max-width: 575px) {
    .btn-group {
        max-width: 100%;
    }
    .tracking-group {
        display: flex;
        flex-direction: column;
    }
}
</style>

<template>
    <div>
        <div v-if="$auth.role.includes('SuperAdmin') || $auth.role.includes('Supplier')" class="pt-2">
            <v-btn color="primary" small outlined @click="initiatePostage()">Get Rates</v-btn>
        </div>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal1" max-width="600px">
            <v-card class="p-3">
                <span><strong>From</strong></span>
                <v-text-field label="Company" v-model="parcel.fromAddress.company"></v-text-field>
                <v-text-field label="Address 1" v-model="parcel.fromAddress.address1"></v-text-field>
                <v-text-field label="Address 2" v-model="parcel.fromAddress.address2"></v-text-field>
                <v-text-field label="City" v-model="parcel.fromAddress.city"></v-text-field>
                <v-text-field label="ZipCode" v-model="parcel.fromAddress.zipCode"></v-text-field>

                <v-select
                    v-model="parcel.fromAddress.state"
                    :items="usStates"
                    item-text="label"
                    item-value="value"
                    label="State"
                ></v-select>
                <div style="display: flex; justify-content: space-between">
                    <v-btn @click="changeModal()">Cancel</v-btn>
                    <v-btn dark color="primary" @click="changeModal(2)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal2" max-width="600px">
            <v-card class="p-3">
                <span><strong>Parcel</strong></span>

                <v-select label="Parcel Type" v-model="parcel.parcelMode" :items="parcelModes"></v-select>
                <v-select
                    label="Carrier"
                    v-model="parcel.carrier"
                    item-text="label"
                    item-value="value"
                    :items="carriers"
                    value="0"
                    disabled
                ></v-select>

                <div v-if="parcel.parcelMode == 'Predefined'">
                    <v-select
                        v-model="parcel.package"
                        :items="uspsPredefineds"
                        item-text="label"
                        item-value="value"
                        label="Package"
                    ></v-select>
                </div>
                <div v-else>
                    <v-text-field type="number" label="Length (in)" v-model.number="parcel.length"></v-text-field>
                    <v-text-field type="number" label="Width (in)" v-model.number="parcel.width"></v-text-field>
                    <v-text-field type="number" label="Height (in)" v-model.number="parcel.height"></v-text-field>
                </div>
                <v-text-field label="Weight (oz)" type="number" v-model.number="parcel.weight"></v-text-field>
                <div v-if="parcel.package == 'Parcel' || parcel.package == 'SoftPack' || parcel.parcelMode == 'Custom'">
                    <v-checkbox
                        v-for="item in specialRates"
                        v-model="selectedSpecialRates"
                        :label="item.label"
                        :value="item.value"
                        @click="setOptions(item)"
                    ></v-checkbox>
                </div>
                <div style="display: flex; justify-content: space-between">
                    <v-btn @click="changeModal(1)">Back</v-btn>
                    <v-btn :disabled="parcelDataRequired" color="primary" @click="changeModal(4)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal4" max-width="600px">
            <v-card class="p-3 text-center">
                <v-row class="p-3">
                    <v-col cols="12" md="6">
                        <div><strong>From</strong></div>
                        <div>{{ parcel.fromAddress.company }}</div>
                        <div>{{ parcel.fromAddress.address1 }}</div>
                        <div>{{ parcel.fromAddress.address2 }}</div>
                        <div>
                            {{ parcel.fromAddress.city }}, {{ parcel.fromAddress.state }}
                            {{ parcel.fromAddress.zipCode }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>Package</strong></div>
                        <div v-if="parcel.parcelMode == 'Predefined'">
                            <div>Predefined Package: {{ parcel.package }}</div>
                        </div>
                        <div v-else>
                            <div>Height: {{ parcel.height }} in</div>
                            <div>Width: {{ parcel.width }} in</div>
                            <div>Length: {{ parcel.length }} in</div>
                        </div>
                        <div>Weight: {{ parcel.weight }} oz</div>
                        <div>Carrier: {{ carriers[parcel.carrier].label }}</div>
                        <div v-if="selectedSpecialRates.length == 1">
                            Special Rate: {{ specialRates[selectedSpecialRates[0]].label }}
                        </div>
                    </v-col>
                </v-row>

                <div class="mt-3" style="display: flex; justify-content: space-between">
                    <v-btn @click="changeModal(2)">Back</v-btn>
                    <v-btn dark color="primary" @click="getShipmentRates()">Get Rates</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog transition="scroll-x-reverse-transition" v-model="postageModal5" max-width="600px">
            <v-card class="p-3">
                <div class="text-center">
                    <div v-if="loadingRates">
                        <div class="p-4 text-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            <div class="pt-4">Getting Rates</div>
                        </div>
                    </div>
                    <div v-else>
                        <v-btn @click="changeModal()" x-small color="#005069" dark fab absolute right elevation="0"
                            >X</v-btn
                        >
                        <h3>Rate Estimates</h3>

                        <v-row v-if="rateEstimates.length > 0" class="mt-2">
                            <v-col v-for="estimate in rateEstimates" cols="12" md="6">
                                <span
                                    ><h5>{{ estimate.to_address.city }}</h5></span
                                >
                                <!-- <div v-for="rate in estimate.rates">
                                <span>{{ rate.service }}</span> - ${{ rate.rate }}
                            </div> -->
                                <div style="display: flex; justify-content: center">
                                    <div>
                                        ${{ estimate.low.rate }} <span class="p-1">-</span> ${{ estimate.high.rate }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            @click:outside="resetParcel()"
            transition="scroll-x-reverse-transition"
            v-model="extShipModal1"
            max-width="600px"
        >
            <v-card class="p-3">
                <v-card-title>Add External Tracking Shipment</v-card-title>
                <v-text-field v-model="trackingNumber"></v-text-field>
                <v-select
                    :items="carriers"
                    item-value="value"
                    item-text="label"
                    label="Carrier"
                    v-model="parcel.carrier"
                ></v-select>
                <div style="display: flex; justify-content: space-between">
                    <v-btn color="primary" @click="cancelShipment()">Cancel</v-btn>
                    <v-btn :disabled="trackingNumber == ''" color="primary" @click="changeExtShipModal(2)">Next</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { usStates, carriers } from '@/constants';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            usStates,
            carriers,
            loading: true,
            token: '',
            message: 'hi',
            trackingNumber: '',
            postageModal1: false,
            postageModal2: false,
            postageModal3: false,
            postageModal4: false,
            postageModal5: false,
            extShipModal1: false,
            extShipModal2: false,
            headers: [],
            selectedProducts: [],
            loadingRates: false,
            selectedRate: '',
            rateEstimates: [],
            hubCities: [
                {
                    address1: '1265 West Blvd',
                    address2: '',
                    city: 'Los Angeles',
                    state: 'CA',
                    zipCode: '90019',
                },
                {
                    address1: '32 Waverly Pl',
                    address2: '',
                    city: 'New York',
                    state: 'NY',
                    zipCode: '10003',
                },
                {
                    address1: '1500 Marilla St',
                    address2: '',
                    city: 'Dallas',
                    state: 'TX',
                    zipCode: '75201',
                },
                {
                    address1: '61 NE 41st St',
                    address2: '',
                    city: 'Miami',
                    state: 'FL',
                    zipCode: '33137',
                },
            ],
            selectedCity: 0,
            parcel: {
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                package: null,
                serviceLevel: '',
                parcelMode: 'Predefined',
                carrier: 0,
                specialRate: null,
                fromAddress: {
                    company: '',
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    phone: '',
                },
                lineItems: [],
            },
            easyPostShipment: {},
            selectedSpecialRates: [],
            specialRates: [
                { label: 'Library Mail', value: 0 },
                { label: 'Media Mail', value: 1 },
            ],
            parcelModes: ['Predefined', 'Custom'],
            postageOrder: {},
            uspsServiceLevels: [
                { label: 'First', value: 'First' },
                { label: 'Priority', value: 'Priority' },
                { label: 'Express', value: 'Express' },
                { label: 'Parcel Select', value: 'ParcelSelect' },
            ],
            uspsPredefineds: [
                // { label: 'Card', value: 'Card' },
                { label: 'Letter', value: 'Letter' },
                { label: 'Flat', value: 'Flat' },
                { label: 'Flat Rate Envelope', value: 'FlatRateEnvelope' },
                { label: 'Flat Rate Legal Envelope', value: 'FlatRateLegalEnvelope' },
                { label: 'Flat Rate Padded Envelope', value: 'FlatRatePaddedEnvelope' },
                { label: 'Parcel', value: 'Parcel' },
                { label: 'Soft Pack', value: 'SoftPack' },
                { label: 'Small Flat Rate Box', value: 'SmallFlatRateBox' },
                { label: 'Medium Flat Rate Box', value: 'MediumFlatRateBox' },
                { label: 'Large Flat Rate Box', value: 'LargeFlatRateBox' },
                { label: 'Regional Rate Box A', value: 'RegionalRateBoxA' },
                { label: 'Regional Rate Box B', value: 'RegionalRateBoxB' },
            ],
            rates: [],
        };
    },
    props: ['supplierId'],

    methods: {
        ...mapActions(['showSnackbar']),
        initiatePostage() {
            this.$emit('refresh');
            this.resetFromAddress();
            this.changeModal(1);
        },
        resetFromAddress() {
            this.parcel.fromAddress = {
                company: this.supplierSettings.name,
                address1: this.supplierSettings.address1,
                address2: this.supplierSettings.address2,
                city: this.supplierSettings.city,
                zipCode: this.supplierSettings.zipCode,
                state: this.supplierSettings.state,
                phone: this.supplierSettings.primaryContactPhoneNumber,
            };
        },
        getSupplierSettings(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Suppliers/${id}`)
                .then(response => {
                    this.parcel.fromAddress.company = response.data.name;
                    this.parcel.fromAddress.address1 = response.data.address1;
                    this.parcel.fromAddress.address2 = response.data.address2;
                    this.parcel.fromAddress.city = response.data.city;
                    this.parcel.fromAddress.state = response.data.state;
                    this.parcel.fromAddress.zipCode = response.data.zipCode;
                    this.supplierSettings = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        resetParcel() {
            this.parcel = {
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                package: null,
                serviceLevel: '',
                parcelMode: 'Predefined',
                carrier: 'USPS',
                specialRate: null,
                fromAddress: {
                    // company: this.supplierSettings.name,
                    address1: this.supplierSettings.address1,
                    address2: this.supplierSettings.address2,
                    city: this.supplierSettings.city,
                    zipCode: this.supplierSettings.zipCode,
                    state: this.supplierSettings.state,
                    phone: this.supplierSettings.primaryContactPhoneNumber,
                },
                toAddress: {
                    company: '',
                    address1: '',
                    address2: '',
                    city: '',
                    zipCode: '',
                    state: '',
                    phone: '',
                },
                lineItems: [],
            };
            this.trackingNumber = '';
        },
        cancelShipment() {
            this.extShipModal1 = false;
            this.extShipModal2 = false;
            this.resetParcel();
        },
        changeExtShipModal(value) {
            this.extShipModal1 = false;
            this.extShipModal2 = false;
            if (value == 1) {
                this.extShipModal1 = true;
            }
            if (value == 2) {
                this.extShipModal2 = true;
            }
        },

        changeModal(value) {
            this.postageModal1 = false;
            this.postageModal2 = false;
            this.postageModal3 = false;
            this.postageModal4 = false;
            this.postageModal5 = false;
            if (value == 1) {
                this.postageModal1 = true;
            }
            if (value == 2) {
                this.postageModal2 = true;
            }
            if (value == 3) {
                this.postageModal3 = true;
            }
            if (value == 4) {
                this.postageModal4 = true;
            }
            if (value == 5) {
                this.postageModal5 = true;
            }
        },
        openPostageModal1() {
            this.packageModal = true;
        },
        setOptions(item) {
            if (this.selectedSpecialRates.length > 1) {
                this.selectedSpecialRates = [item.value];
            }
        },
        compareRate(a, b) {
            if (parseInt(a.rate) < parseInt(b.rate)) {
                return -1;
            }
            if (parseInt(a.rate) < parseInt(b.rate)) {
                return 1;
            }
            return 0;
        },
        lowRate(arr) {
            arr.sort(this.compareRate);
            return arr[0];
        },
        topRate(arr) {
            arr.sort(this.compareRate);
            return arr[arr.length - 1];
        },
        getShipmentRates() {
            this.loadingRates = true;
            this.changeModal(5);
            this.parcel.specialRate = this.selectedSpecialRates.length > 0 ? this.selectedSpecialRates[0] : null;
            if (this.parcel.parcelMode == 'Custom') {
                this.parcel.package = null;
            }
            const data = {
                addressList: this.hubCities,
                parcel: this.parcel,
            };
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Shipping/get-rates-multicity`, data)
                .then(response => {
                    this.rateEstimates = response.data.map(est => ({
                        ...est,
                        low: this.lowRate(est.rates),
                        high: this.topRate(est.rates),
                    }));
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error getting shipment rates' });
                    this.changeModal();
                })
                .finally(() => {
                    this.loadingRates = false;
                });
        },
        submitExtShipment() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/Shipping/add-external-shipment/${this.$route.params.id}/${this.trackingNumber}`,
                    this.parcel,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Shipment created' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating shipment' });
                })
                .finally(() => {
                    this.changeExtShipModal();
                    this.$emit('refresh');
                });
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
            this.getSupplierSettings(this.supplierId);
        });
    },

    computed: {
        parcelDataRequired() {
            if (this.parcel.parcelMode == 'Custom') {
                if (
                    this.parcel.length == 0 ||
                    this.parcel.width == 0 ||
                    this.parcel.height == 0 ||
                    this.parcel.weight == 0
                ) {
                    return true;
                }
            } else {
                if (this.parcel.weight == 0 || !this.parcel.package) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
<style>
/* .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
} */
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <v-btn v-if="this.$auth.role.includes('SuperAdmin')" class="mb-5" @click="goToEcomAdmin()"
            ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Shop Settings</v-btn
        >
        <div class="elevation-3 p-4 bg-white rounded">
            <v-tabs v-model="tab">
                <v-tab href="#settings">Settings</v-tab>
                <!-- <v-tab href="#logo">Logo</v-tab> -->
            </v-tabs>
            <v-tabs-items :value="tab">
                <v-tab-item value="settings" v-if="tab === 'settings'">
                    <v-form class="form-container" v-model="formValid">
                        <div class="mb-5" style="display: flex; justify-content: space-between">
                            <span style="display: flex; align-items: center">
                                <font-awesome-icon
                                    icon="fa-regular fa-box-open"
                                    style="margin-right: 0.5rem; margin-bottom: 0.5rem; font-size: 1.4rem"
                                ></font-awesome-icon>
                                <h4>{{ editing ? 'Edit' : 'Create' }} Supplier</h4>
                            </span>
                            <v-btn small v-if="$auth.role.includes('SuperAdmin')" @click="autofillForm()"
                                >Autofill</v-btn
                            >
                        </div>
                        <v-text-field
                            label="Name"
                            v-model="name"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <v-text-field
                            label="Address 1"
                            v-model="address1"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <v-text-field label="Address 2" v-model="address2"></v-text-field>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="City"
                                    v-model="city"
                                    :rules="[v => !!v || 'This field is required']"
                                ></v-text-field>
                            </v-col>

                            <v-col>
                                <!-- <v-text-field
                                    label="State"
                                    v-model="state"
                                    :rules="[v => !!v || 'This field is required']"
                                ></v-text-field> -->
                                <v-select
                                    v-model="state"
                                    :items="usStates"
                                    item-text="label"
                                    item-value="value"
                                    label="State"
                                ></v-select>
                            </v-col>

                            <v-col>
                                <v-text-field
                                    label="Zipcode"
                                    v-model="zipcode"
                                    :rules="[v => !!v || 'This field is required']"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- <v-text-field label="country" v-model="country"></v-text-field> -->
                        <v-text-field
                            label="Supplier Email"
                            v-model="email"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <!-- <v-text-field label="createDate" v-model=""></v-text-field> -->
                        <!-- <v-text-field
                            label="Primary Contact"
                            v-model="primaryContact"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <v-text-field
                            label="Primary Contact Phone"
                            v-mask="'###.###.####'"
                            v-model="primaryContactPhone"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <v-text-field
                            label="Primary Contact Email"
                            v-model="primaryContactEmail"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field> -->
                        <v-text-field
                            label="Support Number"
                            v-mask="'###.###.####'"
                            v-model="supportNumber"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-text-field>
                        <v-text-field label="Website" v-model="website"></v-text-field>
                        <v-autocomplete
                            v-model="timezone"
                            :items="timezoneOptions"
                            item-value="id"
                            item-text="displayName"
                            :menu-props="{ closeOnClick: true }"
                            label="Timezone"
                            :rules="[v => !!v || 'This field is required']"
                        ></v-autocomplete>

                        <v-btn v-if="editing" @click="updateSupplier" color="primary">Update</v-btn>
                        <v-btn v-else @click="saveSupplier" color="primary">Save</v-btn>

                        <!-- <v-text-field label="logo" v-model="logo"></v-text-field> -->
                    </v-form>
                </v-tab-item>

                <!-- <v-tab-item value="logo">
                    <div>
                        <p>Feature coming soon!</p>
                    </div>
                </v-tab-item> -->
            </v-tabs-items>
            <Spinner v-if="loading"></Spinner>
        </div>
    </AppLayout>
</template>

<script>
import Spinner from '@/components/ui/Spinner';
import { mapActions } from 'vuex';
import moment from 'moment';
import { usStates } from '@/constants';
// import LogoUpload from '@/components/Misc/LogoUpload.vue';

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export default {
    metaInfo: {
        title: 'Manage Suppliers',
    },
    components: { Spinner },
    data() {
        return {
            usStates,
            tab: 'settings',
            editing: false,
            loading: true,
            formValid: false,
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            email: '',
            primaryContact: '',
            primaryContactPhone: '',
            primaryContactEmail: '',
            supportNumber: '',
            website: '',
            timezone: '',
            timezoneOptions: [],
            supplierId: null,
            images: null,
        };
    },
    mounted() {
        if (this.$route.params.id) {
            this.editing = true;
            this.getSupplier(this.$route.params.id);
        }
        this.getTimezoneOptions();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        goToEcomAdmin() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$router.push({ path: `/ecom?tab=suppliers` });
            }
        },
        getSupplier(id) {
            this.loading = true;
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/Suppliers/${id}`)
                    .then(response => {
                        this.loading = false;
                        this.supplierId = response.data.id;
                        this.name = response.data.name;
                        this.address1 = response.data.address1;
                        this.address2 = response.data.address2;
                        this.city = response.data.city;
                        this.state = response.data.state;
                        this.zipcode = response.data.zipCode;
                        this.email = response.data.email;
                        this.primaryContact = response.data.primaryContact;
                        this.primaryContactEmail = response.data.primaryContactEmail;
                        this.primaryContactPhone = response.data.primaryContactPhoneNumber;
                        this.supportNumber = response.data.supportNumber;
                        this.website = response.data.website;

                        this.timezone = response.data.timeZone;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.loading = false;
                    });
            });
        },
        autofillForm() {
            this.loading = true;
            this.axios
                .get('https://random-word-api.herokuapp.com/word?number=1')
                .then(res => {
                    this.name = res.data[0] + randomNumber(1, 500).toString();
                    this.address1 = 'Address Line 1';
                    this.address2 = 'Address Line 2';
                    this.city = 'Test City';
                    this.state = 'Test State';
                    this.email = 'testemail@a9djwnaidn2.com';
                    this.primaryContact = 'Supply Guy';
                    this.primaryContactEmail = 'testemail@a9djwnaidn2.com';
                    this.primaryContactPhone = '1231231234';
                    this.supportNumber = '1231231234';
                    this.website = 'www.example.com';
                    this.zipcode = '12345';
                    this.timezone = 'Central Standard Time';
                })

                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getTimezoneOptions() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/dropdown/timezones`)
                        .then(response => {
                            let result = response.data;
                            this.timezoneOptions = result;
                            resolve(result);
                            this.loading = false;
                        })
                        .catch(error => {
                            reject();
                            this.loading = false;
                        });
                });
            });
        },
        updateSupplier() {
            if (this.formValid && this.supplierId) {
                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipcode: this.zipcode,
                    email: this.email,
                    primaryContact: this.primaryContact,
                    primaryContactPhoneNumber: this.primaryContactPhone,
                    primaryContactEmail: this.primaryContactEmail,
                    supportNumber: this.supportNumber,
                    website: this.website,
                    timezone: this.timezone,
                };
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .put(process.env.VUE_APP_API + `/Suppliers/${this.supplierId}`, data)
                        .then(response => {
                            this.showSnackbar({ message: 'Updated supplier' });
                            this.loading = false;
                            this.getSupplier(this.supplierId);
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error updating supplier' });
                            this.laoding = false;
                        });
                });
            }
        },
        saveSupplier() {
            if (this.formValid) {
                // this.loading = true;
                const newDate = moment(Date.now()).toISOString();

                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipcode: this.zipcode,
                    email: this.email,
                    createDate: newDate,
                    primaryContact: this.primaryContact,
                    primaryContactPhoneNumber: this.primaryContactPhone,
                    primaryContactEmail: this.primaryContactEmail,
                    supportNumber: this.supportNumber,
                    website: this.website,
                    timezone: this.timezone,
                };
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .post(process.env.VUE_APP_API + `/Suppliers`, data)
                        .then(response => {
                            this.showSnackbar({ message: 'Created supplier' });
                            this.loading = false;
                            this.$router.push({ name: 'EcomAdmin' });
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error creating supplier' });
                            this.laoding = false;
                        });
                });
            } else {
                this.showSnackbar({ message: 'Please fill out form completely before submitting' });
            }
        },
    },
};
</script>

<style></style>

<template>
    <div class="pb-8 pt-2 m-4">
        <v-card id="summary-card">
            <v-row>
                <v-col>
                    <h4 class="ml-12 p-2 elevation-2" id="summary-badge">Summary</h4>
                </v-col>
            </v-row>
            <v-card-text class="text-center"><slot /> </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style>
#summary-badge {
    width: 170px;
    margin-top: -25px;
    background-color: white;
    border: 2px solid cornflowerblue;
    border-radius: 15px;
    text-align: center;
}

#summary-card {
    border: 2px solid cornflowerblue;
}
</style>

<template>
    <div>
        <v-hover v-slot="{ hover }">
            <div>
                <v-btn
                    v-if="!hideButton"
                    :outlined="hover ? false : true"
                    :elevation="hover ? 8 : 0"
                    dark
                    small
                    color="#ff530d"
                    @click="handleOpen"
                    >Upload +</v-btn
                >
            </div>
        </v-hover>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import getBlobDuration from 'get-blob-duration';
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            uppy: null,
            isOpen: false,
            tempFiles: [],
            machineId: null,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                // note: '1MB file size limit',
                note: 'Tribute Story Uploader',
            },
        };
    },
    components: {
        DashboardModal,
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            required: true,
        },
        uploadUserFirstName: {
            type: String,
            required: false,
        },
        uploadUserLastName: {
            type: String,
            required: false,
        },
        uploadUserEmail: {
            type: String,
            required: false,
        },
        uploadUserRelationship: {
            type: String,
            required: false,
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        getMachineId() {
            let machineId = localStorage.getItem('ms-machineId');

            if (!machineId) {
                machineId = uuidv4();

                localStorage.setItem('ms-machineId', machineId);
            }
            return machineId;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
        addFile(file) {
            if (this.uppy) {
                this.uppy.addFile(file);
            }
        },
        addAndUploadFile(file) {
            if (this.uppy) {
                this.addFile(file);
                this.uploadFiles();
            }
        },
        async getUploadUrl(id, fileName) {
            return this.axiosInstance.get(`TributeStory/sas/${id}/${fileName}`);
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file.data);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        await this.axios
                            .create({
                                headers: {
                                    'Content-Type': file.type,
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(res => {});
                        resolve('done');
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        async createStory(eventId, data) {
            return this.axiosInstance.post(`/TributeStory/${eventId}`, data);
        },
        updatePlayableLink(id, fileName) {
            return this.axios
                .create({
                    headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                })
                .put(process.env.VUE_APP_API + `/TributeStory/${id}`, JSON.stringify(fileName))
                .then(res => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        cleanFileName(file) {
            // const lastDot = file.name.lastIndexOf('.');
            // let cleanedName = file.name.substring(0, lastDot);
            file = cleanedName.replaceAll('_', ' ');
            return cleanedName;
        },
        getVideoDuration(file) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = async function () {
                    let duration = video.duration;
                    if (video.src) {
                        if (video.src.includes('blob:')) {
                            duration = await getBlobDuration(video.src);
                        }
                        window.URL.revokeObjectURL(video.src);
                    }

                    resolve(duration);
                };

                video.onerror = function () {
                    reject(new Error('Error loading video'));
                };

                video.src = URL.createObjectURL(file.data);
            });
        },

        uploadSuccessful() {
            this.tempFiles = [];
            this.uppy.cancelAll();
        },
        async uploadFiles() {
            return new Promise(async (resolve, reject) => {
                // Tell dashboard the the upload has started
                this.uppy.emit('upload-start', this.tempFiles);

                for (let i = 0; i < this.tempFiles.length; i++) {
                    let SELECTED_FILE = this.tempFiles[i];

                    try {
                        const duration = await this.getVideoDuration(SELECTED_FILE);

                        const response = await this.getUploadUrl(this.eventId, SELECTED_FILE.data.name);
                        const { sas, fileName } = response.data;

                        // // Upload to azure storage
                        await this.uploadToEndpoint(SELECTED_FILE, sas, ev => {
                            // Emit progress update so Uppy Dashboard shows progress bar
                            this.uppy.emit('upload-progress', SELECTED_FILE, {
                                uploader: this,
                                bytesUploaded: ev.loaded,
                                bytesTotal: ev.total,
                            });
                        });

                        let storyDto = {
                            name: SELECTED_FILE.data.name,
                            uniqueName: fileName,
                            duration: duration,
                            uploadUserFirstName: this.uploadUserFirstName,
                            uploadUserLastName: this.uploadUserLastName,
                            uploadUserEmail: this.uploadUserEmail,
                            uploadUserRelationship: this.uploadUserRelationship,
                            uploadDeviceId: this.machineId,
                        };

                        var result = await this.createStory(this.eventId, storyDto);

                        const uploadResp = {
                            status: 200,
                        };

                        this.uppy.emit('upload-success', SELECTED_FILE, uploadResp);
                        this.$emit('upload-success', result.data);
                    } catch (error) {
                        var status = -1;

                        if (error.response) {
                            status = error.response.status;
                        }

                        const response = {
                            status: status,
                        };

                        this.uppy.emit('upload-error', SELECTED_FILE, error, response);
                        console.log(error, 'upload error');
                        reject(error);
                    }
                }
                this.uppy.cancelAll();
                resolve('done');
            });
        },
        initUppy() {
            this.uppy = new Uppy({
                id: 'tribute-music-uploader',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 10,
                    allowedFileTypes: ['.mp4', '.mov', '.webm', 'video/webm', 'video/mp4'],
                },
            })
                .use(UppyAddUploaderPlugin, {
                    uploader: fileIds => {
                        this.uploadFiles()
                            .then(res => {
                                this.handleClose();
                                this.$emit('refresh');
                                this.showSnackbar({ message: 'Upload Complete' });

                                this.uploadSuccessful();
                            })
                            .catch(error => {
                                this.showSnackbar({ color: 'error', message: 'An error occurred during the upload' });
                            });
                    },
                })
                .on('file-added', file => {
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                })
                .on('progress', progress => {
                    // progress: integer (total progress percentage)

                    this.$emit('progress', progress);
                });
        },
    },

    async created() {
        this.createAxiosInstance();
        this.initUppy();
    },
    mounted() {
        this.machineId = this.getMachineId();
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>
<style lang=""></style>

<template>
    <div v-if="isValidDate" class="countdown-timer">
        {{ formattedTime }}
    </div>
</template>

<script>
export default {
    name: 'CountdownTimer',
    props: {
        dateTime: {
            type: String,
            required: true,
        },
        outputFormat: {
            type: String,
            default: 'timestamp',
        },
    },
    data() {
        return {
            remainingTime: null,
            timerId: null,
            isValidDate: false,
        };
    },
    watch: {
        dateTime(newVal) {
            this.setRemainingTime(newVal);
        },
    },
    methods: {
        startCountdown() {
            this.timerId = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                    this.$emit('remaining-time', this.remainingTime);
                } else {
                    this.stopCountdown();
                    this.$emit('complete');
                }
            }, 1000);
        },
        stopCountdown() {
            clearInterval(this.timerId);
            this.timerId = null;
        },
        validateDate(date) {
            const targetTime = this.$moment(date);

            return targetTime.isValid();
        },
        checkEndReached(diff) {
            if (diff < 0) {
                this.endReached = true; // Flag that the end has been reached
                this.remainingTime = 0; // Set remaining time to 0
                this.stopCountdown(); // Stop the countdown if it's running
            } else {
                this.remainingTime = diff;
                this.endReached = false; // Reset the end reached flag
                if (!this.timerId) {
                    this.startCountdown(); // Start countdown only if it's not already running
                }
            }
        },
        setRemainingTime(date) {
            const isValidDate = this.validateDate(date);

            if (isValidDate) {
                const offset = this.$moment(new Date()).utcOffset();

                const now = this.$moment.utc().utcOffset(offset);
                const targetTime = this.$moment.utc(date).utcOffset(offset);

                const diff = targetTime.diff(now, 'seconds');
                this.checkEndReached(diff);
            }

            this.isValidDate = isValidDate;
        },
    },
    computed: {
        formattedTime() {
            const totalSeconds = Math.ceil(this.remainingTime);
            const days = Math.floor(totalSeconds / (24 * 60 * 60));
            const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
            const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
            const seconds = totalSeconds % 60;

            if (this.outputFormat == 'seconds') {
                return totalSeconds;
            } else if (this.outputFormat == 'timestamp') {
                return `${hours}h ${minutes}m ${seconds}s`;
            }
        },
    },
    mounted() {
        this.setRemainingTime(this.dateTime);
        this.startCountdown();
    },
    beforeDestroy() {
        this.stopCountdown();
    },
};
</script>

<style scoped>
.countdown-timer {
    margin: 0;
    padding: 0;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"ms-main-header"},[_c('font-awesome-icon',{staticClass:"menu-toggle-button",attrs:{"icon":"fa-solid fa-bars","size":"lg"},on:{"click":function($event){return _vm.$emit('toggleMenu')}}}),(
            _vm.$auth.impersonating &&
            (_vm.$auth['user']['https://memoryshare.com/roles'].includes('SuperAdmin') ||
                _vm.$auth['user']['https://memoryshare.com/roles'].includes('Owner'))
        )?_c('v-chip',{staticClass:"ml-4",attrs:{"color":"primary","outlined":""}},[_c('font-awesome-icon',{staticClass:"p-1",attrs:{"icon":"fa-solid fa-user"}}),_vm._v(" "+_vm._s(_vm.$store.state.impersonatingName))],1):_vm._e(),_c('span',{staticStyle:{"flex":"1"}}),(_vm.shouldDisplayToken)?_c('v-btn',{staticClass:"token-button",attrs:{"plain":""},on:{"click":function($event){return _vm.copyTokenToClipboard()}}},[_vm._v("TOKEN")]):_vm._e(),(this.$store.state.streaming)?_c('div',[_c('router-link',{staticClass:"live-container",attrs:{"to":{ name: 'LiveDashboard' }}},[_c('div',{staticClass:"blob"}),_c('span',{staticClass:"mr-3"},[_vm._v("LIVE")])])],1):_vm._e(),(_vm.$auth.impersonating)?_c('div',{staticClass:"d-inline-block mr-8",on:{"click":_vm.returnToAdmin}},[_c('p',{staticClass:"pointer mb-0",staticStyle:{"font-size":"0.9rem"}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-arrow-left"}}),_c('span',[_vm._v("Back to "+_vm._s(_vm.$auth.user.name))])],1),(_vm.$auth['user']['https://memoryshare.com/roles'].includes('SuperAdmin'))?_c('small',{staticStyle:{"position":"absolute","margin-left":"1.9rem"}},[_vm._v("Funeral Home Id: "+_vm._s(_vm.$auth.funeralHomeId))]):_vm._e()]):_c('span',{staticClass:"header-username"},[_vm._v("Hello, "+_vm._s(_vm.$store.state.loggedInUser))]),[_c('div',{staticClass:"d-inline-block ml-4",staticStyle:{"z-index":"1001"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-circle-user","size":"2xl"}})],1)]}}])},[_c('v-list',{staticClass:"dropdown"},[(!(_vm.$auth.role.includes('SuperAdmin') || _vm.$auth.role.includes('RegionalManager')))?_c('v-list-item',{on:{"click":_vm.goToSettings}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":"fa-regular fa-gear"}}),_c('span',[_vm._v("Settings")])],1):_vm._e(),(_vm.$auth.role.includes('FuneralHome') || _vm.$auth.role.includes('Supplier'))?_c('v-list-item',{on:{"click":_vm.getUsers}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":"fa-regular fa-circle-user"}}),_c('span',[_vm._v("Manage Users")])],1):_vm._e(),(
                            !_vm.$auth.role.includes('SuperAdmin') &&
                            !_vm.$auth.role.includes('Supplier') &&
                            !this.$auth.impersonating
                        )?_c('v-list-item',{on:{"click":_vm.getEditInfo}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":"fa-regular fa-circle-user"}}),_c('span',[_vm._v("Edit Profile")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logout}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":"fa-solid fa-right-from-bracket"}}),_c('span',[_vm._v("Logout")])],1)],1)],1)],1)],_c('div',{staticStyle:{"position":"absolute"}},[_c('UsersTable',{ref:"usersTable"}),_c('EditProfileTable',{ref:"EditProfileTable"})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
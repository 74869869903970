import { getInstance } from './index';
import store from '../store/index';

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const userIsAuthorizedForPage = () => {
        if (to.meta.god && authService.role.includes('God')) {
            return true;
        }

        if (to.meta.admin && authService.role.includes('SuperAdmin')) {
            return true;
        }

        if (to.meta.owner && (authService.role.includes('Owner') || authService.role.includes('RegionalManager'))) {
            return true;
        }

        if (Object.keys(to.meta).length === 0) {
            return true;
        }

        return false;
    };

    const getHomeRedirectPath = () => {
        if (authService.role.includes('SuperAdmin')) {
            return '/homes';
        }

        if (authService.role.includes('Supplier')) {
            return `/supplier-settings/${authService.user['https://memoryshare.com/supplier_id']}?tab=products`;
        }

        return '/services';
    };

    const badApiClaim = () => {
        return (
            authService.impersonating !== true &&
            authService.user?.['https://memoryshare.com/api'] !== process.env.VUE_APP_API &&
            process.env.NODE_ENV === 'production'
        );
    };

    const guardAction = () => {
        if (!authService.isAuthenticated) {
            // If the user IS NOT authenticated, log in

            if (to.name === 'View' || to.name === 'CreateUser') {
                authService.isAuthenticated = true;
                return next();
            } else {
                authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
            }
            return;
        } else {
            //If the user IS authenticated, continue with the route

            // Set top nav visibility
            if (to.name === 'View' || to.name === 'CreateUser' || to.name === 'MigrationNotification') {
                store.dispatch('toggleTopNav', false);
            } else if (!store.state.topNavShowing) {
                store.dispatch('toggleTopNav', true);
            }

            //Handle home route dynamic redirect
            if (to.fullPath === '/') {
                const homeRedirectPath = getHomeRedirectPath();
                return next({ path: homeRedirectPath });
            }

            // If user is a funeral home but the API claim isn't right, redirect them to error page
            if (authService.role.includes('FuneralHome') && badApiClaim()) {
                return next({ name: 'MigrationNotification' });
            }

            //Check user role / page meta authorization
            let allowThrough = userIsAuthorizedForPage();

            if (allowThrough) {
                return next();
            } else {
                return next({ name: 'PathNotFound' });
            }
        }
    };

    // If loading has already finished, check our auth state using `guardAction()`
    if (!authService.loading) {
        return guardAction();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return guardAction();
        }
    });
};

<template>
    <div>
        <v-dialog max-width="750px" v-model="modalVisible">
            <v-card class="p-3" :loading="loading">
                <v-card-title>Tribute Video Super</v-card-title>
                <div class="p-3" v-if="formData != null">
                    <v-row>
                        <!-- <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Name"
                                :value="tributeVideo.firstName + ' ' + tributeVideo.lastName"
                            />
                        </v-col> -->
                        <v-col cols="6">
                            <v-text-field disabled label="Id" v-model="formData.id" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="External Id" v-model="formData.externalId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Event Id" v-model="formData.eventId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Service Id" v-model="formData.serviceId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Service Slug" v-model="formData.serviceSlug" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Funeral Home Id" v-model="formData.funeralHomeId" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field disabled label="Owner Id" v-model="formData.ownerId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Deadline" v-model="formData.deadline" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Face Uuid" v-model="formData.faceUuid" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Main Photo Id" v-model="formData.mainPhotoId" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Main Photo Url" v-model="formData.mainPhotoUrl" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Contributor Link" v-model="formData.deeplink" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Age" v-model="formData.age" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Family Link" v-model="formData.familyLink" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Ending Type" v-model="formData.endingType" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Ending Duration" v-model="formData.endingDuration" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                disabled
                                label="Audio Render Required"
                                v-model="formData.audioRenderRequired"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Max Items" v-model="formData.maxItems" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field disabled label="Video Slides Count" v-model="formData.videoSlidesCount" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Separate Player" v-model="formData.separatePlayer" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                disabled
                                label="Video Slides Duration"
                                v-model="formData.videoSlidesDuration"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-btn block dark depressed color="#0d3d60" @click="adminUpdateTribute">Save</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                depressed
                                block
                                :disabled="loading || !tributeId"
                                :loading="loading"
                                @click="refreshTributeTokenAndLinks(tributeId)"
                                >Refresh Tribute Token / Links</v-btn
                            >
                        </v-col>
                        <v-col cols="6">
                            <v-btn depressed dark block :disabled="!tributeId" @click="copyTributeToken(tributeId)"
                                >Copy Tribute Token</v-btn
                            >
                        </v-col>

                        <!--
                        <v-col cols="6">
                            <div class="p-3 tribute-admin-event-div">
                                <h5>Tribute Event</h5>
                                <v-text-field readonly label="Event Id" v-model="tributeVideo.eventId" />
                                <v-text-field readonly label="Status" :value="eventStates[event.eventStatus].label" />
                                <v-text-field readonly label="Hidden" :value="event.hidden" />
                                <v-text-field
                                    readonly
                                    label="Video Source"
                                    persistent-hint
                                    hint="Update url in admin render modal"
                                    v-model="event.convertedVideo"
                                />
                            </div>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <div class="p-3 tribute-admin-event-div">
                                <h5>Story Event</h5>
                                <v-text-field readonly label="Event Id" v-model="tributeVideo.storyEventId" />
                                <v-text-field
                                    readonly
                                    label="Status"
                                    :value="
                                        tributeVideo.storyEvent != null
                                            ? eventStates[tributeVideo.storyEvent.eventStatus].label
                                            : 'N/A'
                                    "
                                />
                                <v-text-field
                                    readonly
                                    label="Video Source"
                                    :value="
                                        tributeVideo.storyEvent != null ? tributeVideo.storyEvent.convertedVideo : ''
                                    "
                                />
                            </div>
                        </v-col> -->
                    </v-row>
                </div>
                <div class="p-3" v-else>
                    <p>No tribute video object found for this event</p>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import TributeVideoService from '@/services/tributeVideo.service';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            formData: null,
            apiInstance: {},
            loading: false,
            tributeId: null,
        };
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        modalVisible: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        modalVisible(newVal) {
            if (newVal) {
                this.getTributeVideo(this.eventId);
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideo']),
        async copyTributeToken(tributeId) {
            try {
                var resp = await this.apiInstance.tributeVideo.getCurrentServiceToken(tributeId);

                if (resp.data.token) {
                    navigator.clipboard.writeText('Bearer ' + resp.data.token);
                    this.showSnackbar({ message: 'Token copied' });
                } else {
                    throw new error('Error retreiving token');
                }
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error retrieving token', color: 'error' });
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        intitApiInstance(token) {
            this.apiInstance.tributeVideo = TributeVideoService(token);
        },
        async refreshTributeTokenAndLinks(tributeId) {
            try {
                this.loading = true;
                var resp = await this.apiInstance.tributeVideo.refreshTokenAndAllLinks(tributeId);
                this.showSnackbar({ message: 'Token and share links updated' });
                this.modalVisible = false;
                if (resp.data) {
                    this.updateTributeVideo({ deeplink: resp.data.deeplink, familyLink: resp.data.familyLink });
                }
                this.$emit('refresh-share-links');
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error refreshing token and share links', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async getTributeVideo(eventId) {
            try {
                this.loading = true;
                const resp = await this.apiInstance.tributeVideo.getTributeVideoByEvent(eventId);

                if (resp.data) {
                    this.formData = { ...resp.data };
                    this.tributeId = this.formData.id;
                }
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },
        async adminUpdateTribute() {
            this.loading = true;
            const tributeId = this.formData.id;
            try {
                if (tributeId <= 0) {
                    throw new Error('Invalid tribute id');
                }

                var resp = await this.apiInstance.tributeVideo.adminUpdateTribute(tributeId, this.formData);
                this.modalVisible = false;
                this.showSnackbar({ message: 'Updated tribute' });

                if (resp.data) {
                    this.updateTributeVideo({ ...resp.data });
                }
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error updating tribute', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.intitApiInstance(this.token);

        this.getTributeVideo(this.eventId);
    },
};
</script>
<style lang=""></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"fa-regular fa-magnifying-glass"}}),_c('h3',{staticClass:"m-0"},[_vm._v("Live Stream Search")]),_c('v-spacer'),_c('div',{staticStyle:{"display":"flex"}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{staticStyle:{"width":"300px"},attrs:{"items":_vm.searchKeys},model:{value:(_vm.searchParam),callback:function ($$v) {_vm.searchParam=$$v},expression:"searchParam"}})],1)],1),_c('v-data-table',{staticClass:"w-95 centered",attrs:{"headers":_vm.headers,"items":_vm.streams,"server-items-length":_vm.totalStreams,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        }},on:{"click:row":_vm.goToService,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.startedTime",fn:function(ref){
        var item = ref.item;
return [(item.startedTime)?_c('span',[(item.startedTime)?_c('strong',[_vm._v(_vm._s(_vm._f("timeFilter")(item.startedTime)))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.startedTime))+" ")]):_vm._e()]}},{key:"item.endedTime",fn:function(ref){
        var item = ref.item;
return [(item.endedTime)?_c('span',[(item.endedTime)?_c('strong',[_vm._v(_vm._s(_vm._f("timeFilter")(item.startedTime)))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.endedTime))+" ")]):_vm._e()]}},{key:"item.serviceSlug",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceSlug)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export function getLighterShade(hexColor, blendFactor) {
    if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(hexColor)) {
        throw new Error('Invalid hex color');
    }

    if (blendFactor < 0 || blendFactor > 1) {
        throw new Error('Blend factor must be between 0 and 1');
    }

    // If it's a 3-character hex code, expand it to 6 characters
    if (hexColor.length === 4) {
        hexColor = '#' + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
    }

    const red = parseInt(hexColor.substring(1, 3), 16);
    const green = parseInt(hexColor.substring(3, 5), 16);
    const blue = parseInt(hexColor.substring(5, 7), 16);

    // Blend the color values with white
    const blendedRed = Math.round(red + (255 - red) * blendFactor)
        .toString(16)
        .padStart(2, '0');
    const blendedGreen = Math.round(green + (255 - green) * blendFactor)
        .toString(16)
        .padStart(2, '0');
    const blendedBlue = Math.round(blue + (255 - blue) * blendFactor)
        .toString(16)
        .padStart(2, '0');

    return `#${blendedRed}${blendedGreen}${blendedBlue}`;
}

export function getDarkerShade(hexColor, blendFactor) {
    if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(hexColor)) {
        throw new Error('Invalid hex color');
    }

    if (blendFactor < 0 || blendFactor > 1) {
        throw new Error('Blend factor must be between 0 and 1');
    }

    // If it's a 3-character hex code, expand it to 6 characters
    if (hexColor.length === 4) {
        hexColor = '#' + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
    }

    const red = parseInt(hexColor.substring(1, 3), 16);
    const green = parseInt(hexColor.substring(3, 5), 16);
    const blue = parseInt(hexColor.substring(5, 7), 16);

    // Blend the color values with black
    const blendedRed = Math.round(red * (1 - blendFactor))
        .toString(16)
        .padStart(2, '0');
    const blendedGreen = Math.round(green * (1 - blendFactor))
        .toString(16)
        .padStart(2, '0');
    const blendedBlue = Math.round(blue * (1 - blendFactor))
        .toString(16)
        .padStart(2, '0');

    return `#${blendedRed}${blendedGreen}${blendedBlue}`;
}

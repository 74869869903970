<template>
    <div>
        <v-card class="p-3">
            <v-card-title class="mt-3">
                <font-awesome-icon icon="fa-regular fa-badge-dollar" class="mr-2"></font-awesome-icon>
                <h3 class="m-0">Purchases</h3>
            </v-card-title>

            <div class="d-flex align-items-center mb-3 ms-3">
                <div class="me-5 text-muted">Quick Filters:</div>

                <v-btn
                    @click="updateQuickFilter(CUSTOMER_TYPE_FAMILY.value)"
                    :outlined="activeCustomerTypeQuickFilter != CUSTOMER_TYPE_FAMILY.value"
                    class="me-2"
                    small
                    dark
                    :color="CUSTOMER_TYPE_FAMILY.color"
                    >{{ CUSTOMER_TYPE_FAMILY.label }}</v-btn
                >
                <v-btn
                    @click="updateQuickFilter(CUSTOMER_TYPE_FUNERAL_HOME.value)"
                    :outlined="activeCustomerTypeQuickFilter != CUSTOMER_TYPE_FUNERAL_HOME.value"
                    small
                    dark
                    :color="CUSTOMER_TYPE_FUNERAL_HOME.color"
                    >{{ CUSTOMER_TYPE_FUNERAL_HOME.label }}</v-btn
                >
            </div>

            <div class="d-flex px-3 flex-wrap flex-md-nowrap">
                <div class="flex-fill flex-md-grow-0 me-4">
                    <calendar v-model="startDate" label="From:" :output-local="true" />
                </div>

                <div class="flex-fill flex-md-grow-0 me-sm-4">
                    <calendar v-model="endDate" label="To:" :output-local="true" />
                </div>

                <div class="flex-fill flex-md-grow-0 ms-md-auto">
                    <v-text-field append-icon="mdi-magnify" v-model="search" label="Search query" />
                </div>
            </div>

            <v-data-table
                @click:row="getDetails"
                :items="purchases"
                :headers="headers"
                :server-items-length="total"
                :loading="loading"
                :options.sync="options"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
            >
                <template v-slot:header.customerType="{ header }">
                    <v-menu
                        :close-on-content-click="false"
                        :offset-y="true"
                        :disabled="loading"
                        @input="handleCustomerTypeMenuToggle"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ customerTypesSelected.length }})
                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 200px">
                            <div class="text-center">
                                <v-btn style="margin-bottom: 0.6rem" small width="90%" @click="toggleAllCustomers"
                                    >{{ allCustomersSelected ? 'Deselect' : 'Select' }} All</v-btn
                                >
                            </div>
                            <v-list-item v-for="(item, index) in customerTypes" :key="index" style="height: 20px">
                                <v-checkbox
                                    v-model="customerTypesSelected"
                                    :label="item.label"
                                    :value="item.value"
                                ></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:item.createdDate="{ item }">
                    <strong>{{ $moment.utc(item.createdDate).local().format('l') }} </strong>
                    <div>{{ $moment.utc(item.createdDate).local().format('LT') }}</div>
                </template>

                <template v-slot:item.customerType="{ item }">
                    <v-chip :color="customerTypes[item.customerType].color" dark>
                        {{ customerTypes[item.customerType].label }}
                    </v-chip>
                </template>

                <template v-slot:item.amount="{ item }">
                    {{ item.amount | formatMoney() }}
                </template>
            </v-data-table>

            <PurchaseSummary
                :purchases="purchases"
                :total="this.total"
                :totalAmount="this.totalAmount"
                :categoryTotals="categoryTotals"
            />
        </v-card>
    </div>
</template>
<script>
import moment from 'moment';
import { debounceV2 } from '@/utilities/debounce.js';
import { customerTypes, CUSTOMER_TYPE_FAMILY, CUSTOMER_TYPE_FUNERAL_HOME } from '@/constants.js';
import PurchaseSummary from '../../Ecommerce/PurchasesSummary.vue';
import Calendar from '@/components/ui/Calendar.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    created() {
        this.customerTypes = customerTypes;
        this.CUSTOMER_TYPE_FAMILY = CUSTOMER_TYPE_FAMILY;
        this.CUSTOMER_TYPE_FUNERAL_HOME = CUSTOMER_TYPE_FUNERAL_HOME;
    },
    data() {
        return {
            purchases: [],
            token: '',
            search: '',
            total: 0,
            totalAmount: 0,
            loading: false,
            filterMenuOpen: false,
            customerTypesSelected: [0, 1],
            headers: [
                { text: 'Created', value: 'createdDate' },
                { text: 'Type', value: 'customerType' },
                { text: 'Home', value: 'funeralHomeName' },
                { text: 'Customer', value: 'customerName' },
                { text: 'Email', value: 'customerEmail' },
                { text: 'Amount', value: 'amount' },
            ],
            options: {
                itemsPerPage: 25,
            },
            startDate: null,
            endDate: null,
            activeCustomerTypeQuickFilter: null,
            categoryTotals: [],
        };
    },
    watch: {
        search: debounceV2(function () {
            this.getPurchases();
        }, 500),
        startDate() {
            if (!this.loading) {
                this.getPurchases();
            }
        },
        endDate() {
            if (!this.loading) {
                this.getPurchases();
            }
        },
        options: {
            handler() {
                if (!this.loading) {
                    this.getPurchases();
                }
            },
        },
    },
    computed: {
        allCustomersSelected() {
            return this.customerTypesSelected.length === this.customerTypes.length;
        },
    },
    methods: {
        toggleAllCustomers() {
            if (this.allCustomersSelected) {
                this.customerTypesSelected = [];
            } else {
                this.customerTypes.forEach(cus => {
                    if (cus.value >= 0 && !this.customerTypesSelected.includes(cus.value)) {
                        this.customerTypesSelected.push(cus.value);
                    }
                });
            }
        },
        handleCustomerTypeMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.customerTypesSelected.length === 0) {
                    this.purchases = [];
                    return;
                }
                this.getPurchases();
                this.filterMenuOpen = false;
            }
        },
        getCustomerTypeQueryParameters(customerTypes) {
            let string = '';
            customerTypes.forEach(cus => {
                string += `&customerType=${cus}`;
            });
            return string;
        },
        getDetails(item) {
            this.$router.push({ path: `/purchase-details/${item.id}` });
        },
        getSortByValue(value) {
            switch (value) {
                case 'customerName':
                    return 'CustomerName';
                case 'customerType':
                    return 'CustomerType';
                case 'createdDate':
                    return 'CreatedDate';
                case 'amount':
                    return 'Amount';
                case 'funeralHomeName':
                    return 'FuneralHomeName';
                case 'customerEmail':
                    return 'CustomerEmail';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        updateQuickFilter(value) {
            if (value === this.activeCustomerTypeQuickFilter) {
                this.activeCustomerTypeQuickFilter = null;
            } else {
                this.activeCustomerTypeQuickFilter = value;
            }

            this.getPurchases();
        },
        createCategoryTotalsList(obj) {
            const excludedProperties = ['purchases', 'total', 'totalAmount', 'usbCount', 'dvdCount'];

            const newObjList = [];
            for (const prop in obj) {
                if (!excludedProperties.includes(prop)) {
                    let newObj = { label: prop, total: obj[prop] };
                    switch (true) {
                        case prop == 'dvdAmount':
                            newObj.color = 'indigo';
                            newObj.icon = 'fa-solid fa-compact-disc';
                            newObj.label = 'DVD';

                            if (obj['dvdCount'] != null) {
                                newObj.unitCount = obj['dvdCount'];
                            }
                            break;
                        case prop == 'usbAmount':
                            newObj.color = '#0653aa';
                            newObj.icon = 'fa-solid fa-usb-drive';
                            newObj.label = 'USB';
                            if (obj['usbCount'] != null) {
                                newObj.unitCount = obj['usbCount'];
                            }
                            break;
                        case prop == 'familyAmount':
                            newObj.color = 'teal';
                            newObj.icon = 'fa-solid fa-family';
                            newObj.label = 'Family';
                            break;
                        case prop == 'funeralHomeAmount':
                            newObj.color = 'purple';
                            newObj.icon = 'fa-solid fa-house';
                            newObj.label = 'Funeral Home';
                            break;
                    }
                    newObjList.push(newObj);
                }
            }

            return newObjList;
        },
        getPurchases() {
            this.loading = true;
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                var params = {
                    pageSize: itemsPerPage,
                    pageNumber: page ? page - 1 : 1,
                    sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                    sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                    // User's timezone is used since the Created column is also displayed in the user's timezone.
                    startDate: this.startDate,
                    endDate: this.endDate,
                    //hardcoding offset to central time
                    offset: 5,
                };

                if (this.search !== '') {
                    params.search = this.search;
                }

                var customerTypeQuery = null;

                // Quick filters take precedence over the multi-select filter from the table.
                if (this.activeCustomerTypeQuickFilter !== null) {
                    customerTypeQuery = this.getCustomerTypeQueryParameters([this.activeCustomerTypeQuickFilter]);
                } else {
                    customerTypeQuery = this.getCustomerTypeQueryParameters(this.customerTypesSelected);
                }

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Order/purchases-list?${customerTypeQuery}`, {
                        params: params,
                    })
                    .then(response => {
                        this.purchases = response.data.purchases;

                        this.total = response.data.total;
                        this.totalAmount = response.data.totalAmount;

                        this.categoryTotals = this.createCategoryTotalsList(response.data);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
    },
    mounted() {
        // 'toISOString' has to be called with local: 'true' to get the same date as we would from the Calendar component
        this.startDate = moment().startOf('month').toISOString(true);
        this.endDate = moment().toISOString(true);

        this.getPurchases();
    },
    components: {
        PurchaseSummary,
        Calendar,
        CustomTooltip,
    },
};
</script>
<style></style>

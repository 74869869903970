<template>
    <div>
        <btn-x-scroll-container
            containerId="top-picks-scroll"
            itemBaseId="top-pick-container"
            :itemQuerySelector="'.top-pick-item'"
            :maxItemCount="20"
        >
            <template v-slot:content>
                <div class="top-pick-item" v-for="song in songs">
                    <featured-audio-player
                        :ref="'audio-' + song.id"
                        :song="song"
                        :id="'top-pick' + song.id"
                        :token="token"
                        @refresh-sync="$emit('refresh-sync')"
                        @playing="song => stopPlayingAudio(song)"
                    ></featured-audio-player>
                </div>
                <div @click="$emit('show-all-featured')" class="top-pick-item more-top-picks-grid-item p-3">
                    <font-awesome-icon
                        style="font-size: 1.5rem"
                        class="mb-1"
                        icon="fa-regular fa-circle-arrow-right"
                    ></font-awesome-icon>
                    <div class="more-top-picks-inner">
                        <strong class="title">More Featured</strong>
                    </div>
                </div>
            </template>
        </btn-x-scroll-container>
    </div>
</template>

<script>
import FeaturedAudioPlayer from '@/components/ui/FeaturedAudioPlayer.vue';
import BtnXScrollContainer from '@/components/ui/BtnXScrollContainer.vue';

export default {
    data() {
        return {
            songs: [],
            totalSongs: 0,
            loading: true,
        };
    },
    props: {
        token: {
            type: String,
            requried: true,
        },
    },
    components: {
        FeaturedAudioPlayer,
        BtnXScrollContainer,
    },

    methods: {
        stopPlayingAudio(song = null) {
            const matchingRefs = Object.keys(this.$refs).filter(key => key.startsWith('audio-'));

            matchingRefs.forEach(ref => {
                const refId = ref.split('-')[1];
                const audioPlayer = this.$refs[ref][0];

                if (!song || (song && song.id.toString() !== refId)) {
                    audioPlayer.pauseAudio();
                }
            });
        },
        async getSongs(favoritesOnly = true) {
            this.loading = true;

            var params = {
                pageSize: 8,
                favoritesOnly: favoritesOnly,
            };

            return this.axiosInstance
                .get(`TributeVideoSong`, {
                    params: params,
                })
                .then(res => {
                    this.songs = res.data.tributeSongs;
                    this.totalSongs = res.data.total;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        this.createAxiosInstance();
        this.getSongs();
    },
};
</script>
<style lang="scss" scoped>
.top-pick-item {
    border-radius: 8px;
    position: relative;
    min-width: 200px;
    transition: 0.2s;
}

.more-top-picks-grid-item {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    height: 110px;
    margin: 8px 0;
}

.more-top-picks-grid-item:hover {
    background-color: #eee;
}
</style>

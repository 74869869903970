<template>
    <div class="text-center">
        <h3 class="text-heading">Upload More Photos/Videos</h3>

        <div v-if="processingUploads" class="my-1">
            <p>Proccessing uploads...</p>
            <v-progress-linear
                dark
                v-model="uploadProgress"
                :color="uploadProgress === 100 ? 'success' : 'primary'"
                height="25"
                >{{ uploadProgress }}%</v-progress-linear
            >
        </div>
        <p
            v-if="!processingUploads"
            style="max-width: 500px; margin: 0 auto; margin-bottom: 56px; font-weight: 500"
            class="text-heading"
        >
            Upload here or send a QR for someone else to upload
        </p>

        <v-row>
            <v-col>
                <OnboardShareLinkAction @init-share-modal="$emit('init-share-modal')" />
            </v-col>

            <!-- START: Qr pdf content -->
            <v-col>
                <OnboardQrPrintAction :service="service" :tributeVideo="tributeVideo" />
            </v-col>
            <!-- END: Qr pdf content -->

            <v-col>
                <OnboardUploadAction
                    @current-files="files => $emit('current-files', files)"
                    @upload-start="files => handleUploadStart(files)"
                    @upload-success="remaining => this.$emit('upload-success', remaining)"
                    @upload-progress="progress => handleProgressUpdate(progress)"
                    @all-uploads-complete="handleUploadsComplete()"
                    :event="event"
                    :service="service"
                />
            </v-col>
            <!-- </div> -->

            <v-col v-if="displayOnboardOverride" cols="12" class="text-right">
                <v-btn @click="$emit('onboard-override')" depressed
                    >Next

                    <font-awesome-icon class="ml-2" icon="fa-regular fa-chevron-right"></font-awesome-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import OnboardUploadAction from '@/components/ManageService/Tribute/actions/OnboardUploadAction.vue';
import OnboardShareLinkAction from '@/components/ManageService/Tribute/actions/OnboardShareLinkAction.vue';
import OnboardQrPrintAction from '@/components/ManageService/Tribute/actions/OnboardQrPrintAction.vue';

export default {
    data() {
        return {
            processingUploads: false,
            uploadProgress: 0,
        };
    },
    components: {
        OnboardUploadAction,
        OnboardShareLinkAction,
        OnboardQrPrintAction,
    },
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        displayOnboardOverride: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: () => {},
        },
        service: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        processingUploads(newVal) {
            this.uploadProgress = 0;
        },
    },
    methods: {
        handleUppyOpen(ref) {
            const uploader = this.$refs[ref];
            if (uploader) {
                uploader.openFileSelection();
            }
        },
        handleUploadCancelled(ref) {
            const uploader = this.$refs[ref];
            if (uploader) {
                uploader.reset();
            }
        },
        openQrModal() {
            this.qrTypeModalVisible = true;
        },
        handleUploadStart(files) {
            this.processingUploads = true;

            this.$emit('upload-start', files);
        },
        handleUploadsComplete() {
            this.processingUploads = false;

            this.$emit('all-uploads-complete');
        },
        handleProgressUpdate(progress) {
            if (progress > this.uploadProgress || progress === 0) {
                this.uploadProgress = progress;
                this.$emit('upload-progress', progress);
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>

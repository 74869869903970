import { render, staticRenderFns } from "./HomeSubscriptionInfo.vue?vue&type=template&id=5b4e4225&"
import script from "./HomeSubscriptionInfo.vue?vue&type=script&lang=js&"
export * from "./HomeSubscriptionInfo.vue?vue&type=script&lang=js&"
import style0 from "./HomeSubscriptionInfo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCard,VCardText,VCardTitle,VChip,VCol,VDialog,VRow,VSpacer})

<template>
    <div>
        <div class="lineItem-wrap">
            <v-row>
                <v-col align-self="center">
                    <div v-if="lineItem.image">
                        <v-badge :content="lineItem.quantity" overlap>
                            <img class="product-img" :src="lineItem.image.split(',')[0]" alt="Product Image" />
                        </v-badge>
                    </div>
                    <div v-else>
                        <v-badge :content="lineItem.quantity" overlap>
                            <img
                                class="alt-product-img"
                                :src="require(`@/assets/images/logo.png`)"
                                alt="Alt Product Image"
                            />
                        </v-badge>
                    </div>
                </v-col>

                <v-col class="align-center">
                    <div>{{ lineItem.productName }}</div>
                </v-col>

                <v-col class="align-center">
                    <!-- <div>{{ lineItem.quantity }}x</div> -->
                    <!-- <div>
                        <strong>{{ lineItem.totalAmount | formatMoney() }}</strong>
                    </div> -->
                    <div>{{ lineItem.unitAmount | formatMoney() }} ea.</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ['lineItem'],
};
</script>
<style scoped>
.align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.product-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 10px;
}
.alt-product-img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    border-radius: 10px;
}
</style>

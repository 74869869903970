span
<template>
    <div>
        <div v-if="loading" class="p-3">
            <v-skeleton-loader type="heading" class="mb-3"> </v-skeleton-loader>
            <v-skeleton-loader type="image" class="mb-3"> </v-skeleton-loader>
            <v-skeleton-loader type="image" class="mb-3"> </v-skeleton-loader>
        </div>
        <div v-else class="analytics-chart">
            <div class="chart">
                <div class="chart-container">
                    <line-chart :chart-data="datacollection" :options="options"></line-chart>
                </div>
                <div class="d-flex mt-3 justify-center" style="color: black; gap: 24px">
                    <h6 style="border-bottom: 1px solid #ff7837">Total Views {{ formatNumber(totalViews) }}</h6>
                    <h6 style="border-bottom: 1px solid #176bf1">Total Services {{ formatNumber(totalServices) }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from './LineChart';

export default {
    props: {
        funeralHomeId: {
            type: Number,
            required: true,
        },
        interval: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
    },
    components: { LineChart },
    data() {
        return {
            token: '',
            loading: false,
            chartData: {},
            datacollection: {
                labels: [],
                datasets: [
                    // {
                    //     type: 'line',
                    //     label: 'Line Dataset',
                    //     backgroundColor: '#f87979',
                    //     data: [40, 39, 10, 40, 39, 80, 40],
                    //     fill: false,
                    //     borderColor: '#f87979',
                    // },
                    // {
                    //     type: 'bar',
                    //     label: 'Bar Dataset',
                    //     backgroundColor: '#42A5F5',
                    //     data: [60, 55, 32, 10, 2, 12, 53],
                    // },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return Number.isInteger(value) ? value : null; // Only display integers
                                },
                                min: 0,
                                suggestedMax: 10,
                            },
                            position: 'left',
                            gridLines: {
                                color: 'rgba(255, 120, 55, .4)', // color of the grid lines
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Views',
                                fontSize: 20,
                            },
                        },
                        {
                            id: 'y-axis-2',
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return Number.isInteger(value) ? value : null; // Only display integers
                                },
                                min: 0,
                                suggestedMax: 10,
                            },
                            position: 'right',
                            gridLines: {
                                color: 'rgba(23, 107, 241, .4)', // color of the grid lines
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Services',
                                fontSize: 20,
                            },
                        },
                    ],
                },
            },
        };
    },
    methods: {
        formatNumber(number) {
            return number.toLocaleString();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        fetchChartData(funeralHomeId, interval) {
            this.axiosInstance
                .get(`/dogs/get/views-to-services-chart/${funeralHomeId}`, {
                    params: {
                        startDate: this.startDate,
                        endDate: this.endDate,
                    },
                })
                .then(response => {
                    this.chartData[interval] = response.data;
                    this.mountChartData(response.data);
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        refreshChartData() {
            if (this.chartData[this.interval]) {
                this.mountChartData(this.chartData[this.interval]);
            } else {
                this.fetchChartData(this.funeralHomeId, this.interval);
            }
        },
        mountChartData(data) {
            const labels = [];
            const views = [];
            const services = [];

            data.forEach(obj => {
                if (this.interval == 'all') {
                    labels.unshift(this.$moment.utc(obj.viewDate).format('YYYY'));
                } else if (this.interval == 'year') {
                    labels.unshift(this.$moment.utc(obj.viewDate).format('MMM YYYY'));
                } else {
                    labels.unshift(this.$moment.utc(obj.viewDate).format('l'));
                }
                views.unshift(obj.totalViews);
                services.unshift(obj.totalServices);
            });

            this.datacollection = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Views',
                        backgroundColor: '#ff7837',
                        data: views,
                        fill: false,
                        borderColor: '#ff7837',
                        yAxisID: 'y-axis-1',
                    },
                    {
                        type: 'bar',
                        label: 'Services',
                        backgroundColor: '#176bf1',
                        data: services,
                        yAxisID: 'y-axis-2',
                    },
                ],
            };
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.fetchChartData(this.funeralHomeId, this.interval);
    },
    watch: {
        interval(newVal, oldVal) {
            this.refreshChartData();
        },
        startDate() {
            this.refreshChartData();
        },
        endDate() {
            this.refreshChartData();
        },
    },
    computed: {
        totalServices() {
            // return this.datacollection.datasets;
            let sum = 0;
            const serviceDataSet = this.datacollection.datasets.find(d => d.label == 'Services');
            if (serviceDataSet?.data) {
                serviceDataSet.data.forEach(total => {
                    sum += total;
                });
            }
            return sum;
        },
        totalViews() {
            let sum = 0;
            const viewsDataSet = this.datacollection.datasets.find(d => d.label == 'Views');
            if (viewsDataSet?.data) {
                viewsDataSet.data.forEach(total => {
                    sum += total;
                });
            }
            return sum;
        },
    },
};
</script>

<style lang="scss"></style>

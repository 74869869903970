<template>
    <div>
        <v-card class="p-3">
            <v-card-title>
                <font-awesome-icon
                    v-if="!$auth.role.includes('FuneralHome')"
                    icon="fa-regular fa-cash-register"
                    style="margin-right: 0.5rem"
                ></font-awesome-icon>
                <h3 v-if="$auth.role.includes('FuneralHome')" class="m-0">Purchases</h3>
                <h3 v-else class="m-0">{{ supplierId > 0 ? 'Orders' : 'All Orders' }}</h3>
                <v-spacer></v-spacer>
                <v-text-field append-icon="mdi-magnify" style="max-width: 300px" v-model="search"></v-text-field>
            </v-card-title>

            <v-data-table
                :items="orders"
                item-key="subId"
                :headers="headers"
                @click:row="getDetails"
                :server-items-length="total"
                :loading="loading"
                :options.sync="options"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
            >
                <template v-slot:header.orderStatus="{ header }">
                    <v-menu :close-on-content-click="false" :offset-y="true" @input="handleStatusMenuToggle">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ selectedStatuses.length }})<font-awesome-icon
                                            class="ml-2"
                                            icon="fa-solid fa-chevron-down"
                                        />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 200px">
                            <div class="text-center">
                                <v-btn style="margin-bottom: 0.6rem" @click="toggleAllOrderStatuses" width="90%" small
                                    >{{ allStatusesSelected ? 'Deselect' : 'Select' }} All</v-btn
                                >
                            </div>
                            <v-list-item v-for="(item, index) in orderStatuses" :key="item.value" style="height: 20px">
                                <v-checkbox
                                    v-model="selectedStatuses"
                                    :label="item.label"
                                    :value="item.value"
                                ></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:item.funeralHomeId="{ item }">
                    <div v-if="item.funeralHomeId > 0">
                        {{ item.funeralHomeId }}
                    </div>
                </template>

                <template v-slot:item.createdDate="{ item }">
                    {{ $moment.utc(item.createdDate).local().format('l') }}
                </template>
                <template v-slot:item.orderDate="{ item }">
                    {{ $moment.utc(item.orderDate).local().format('l') }}
                </template>

                <template v-slot:item.amount="{ item }">
                    {{ item.amount | formatMoney() }}
                </template>
                <template v-slot:item.serviceName="{ item }">
                    <div>
                        {{ item.serviceName }}

                        <v-chip
                            class="ml-1"
                            x-small
                            dark
                            v-if="item.customerType == 0"
                            :color="customerTypes[item.customerType].color"
                            >{{ customerTypes[item.customerType].label }}</v-chip
                        >
                    </div>
                </template>

                <template v-slot:item.fhStatus="{ item }">
                    <div v-if="item.shipmentStatus == null">
                        <v-chip dark :color="orderStatuses[item.orderStatus].color">{{
                            orderStatuses[item.orderStatus].label
                        }}</v-chip>
                    </div>

                    <div v-else>
                        <v-chip
                            v-if="item.orderStatus == 2 || item.orderStatus == 3"
                            dark
                            :color="orderStatuses[item.orderStatus].color"
                            >{{ orderStatuses[item.orderStatus].label }}</v-chip
                        >
                        <v-chip dark v-else :color="shipmentStatuses[item.shipmentStatus].color">{{
                            shipmentStatuses[item.shipmentStatus].label
                        }}</v-chip>
                    </div>
                </template>

                <template v-slot:item.trackingNumber="{ item }">
                    <div
                        v-if="$auth.role.includes('SuperAdmin')"
                        style="min-width: 115px"
                        @click.stop
                        @click.prevent="openTrackingNumDialog(item)"
                    >
                        <span v-if="item.trackingNumber">{{ item.trackingNumber }}</span>
                        <span v-else><v-btn small outlined>Tracking +</v-btn></span>
                    </div>
                    <div v-else>
                        <a @click.stop :href="item.trackingUrl" target="_blank" v-if="item.trackingUrl">{{
                            item.trackingNumber
                        }}</a>
                        <span v-else-if="item.trackingNumber">{{ item.trackingNumber }}</span>
                    </div>
                </template>

                <template v-slot:item.orderStatus="{ item }">
                    <v-select
                        v-if="$auth.role.includes('SuperAdmin')"
                        style="height: 2.6rem"
                        class="selectOrderStatus"
                        dense
                        chips
                        small
                        return-object
                        item-text="label"
                        item-value="value"
                        v-model="item.orderStatus"
                        :items="orderStatuses"
                        @input="updateOrderStatus(item.id, item.orderStatus.value)"
                        @click.stop
                    >
                        <template v-slot:selection="{ item }">
                            <div>
                                <v-chip dark :color="item.color">{{ item.label }}</v-chip>
                            </div>
                        </template>
                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <!-- <span>{{ item.descr }}</span> -->
                                            <v-spacer></v-spacer>
                                            <v-chip text-color="white" :color="item.color">{{ item.label }}</v-chip>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                    <v-chip v-else dark :color="orderStatuses[item.orderStatus].color">{{
                        orderStatuses[item.orderStatus].label
                    }}</v-chip>
                </template>

                <template v-slot:item.carrier="{ item }">
                    <span v-if="item.carrier != null">{{ carriers[item.carrier].label }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div style="display: flex; gap: 0.45rem; align-items: center; justify-content: center">
                        <custom-tooltip v-if="item.trackingUrl" :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="openTab(item.trackingUrl)">
                                    <font-awesome-icon
                                        icon="fa-regular fa-map-location-dot"
                                        style="font-size: 1.1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Tracker</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>

                <template v-slot:item.paymentStatus="{ item }">
                    <v-chip :color="paymentStatuses[item.paymentStatus].color" dark>{{
                        paymentStatuses[item.paymentStatus].label
                    }}</v-chip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="editDialog" max-width="600px">
            <v-card class="p-3">
                <div v-if="itemLoading">
                    <div class="text-center">Loading..</div>
                    <v-progress-linear indeterminate></v-progress-linear>
                </div>
                <form v-if="!itemLoading" class="details-container">
                    <div class="mb-3">
                        <v-chip
                            v-if="selectedOrder.orderStatus >= 0"
                            :color="orderStatuses[selectedOrder.orderStatus].color"
                            dark
                            >{{ orderStatuses[selectedOrder.orderStatus].label }}</v-chip
                        >
                    </div>
                    <div class="mb-3 text-right">
                        <v-chip
                            v-if="selectedOrder.paymentStatus >= 0"
                            :color="paymentStatuses[selectedOrder.paymentStatus].color"
                            dark
                            >{{ paymentStatuses[selectedOrder.paymentStatus].label }}</v-chip
                        >
                    </div>
                    <v-text-field label="ID" v-model="selectedOrder.id" />
                    <v-text-field label="Created" v-model="selectedOrder.createdDate" />
                    <v-text-field label="Shipped" v-model="selectedOrder.shippedDate" />
                    <v-text-field label="Tracking Number" v-model="selectedOrder.trackingNumber" />
                    <v-text-field label="Amount" v-model="selectedOrder.amount" />
                    <v-text-field label="Amount Paid" v-model="selectedOrder.amountPaid" />
                    <v-text-field label="Stripe Payment ID" v-model="selectedOrder.stripePaymentIntentId" />
                    <v-text-field label="Stripe Customer ID" v-model="selectedOrder.stripeCustomerId" />
                    <v-text-field label="Name" v-model="selectedOrder.customerName" />
                    <v-text-field label="Email" v-model="selectedOrder.customerEmail" />
                    <v-text-field label="Address 1" v-model="selectedOrder.address1" />
                    <v-text-field label="Address 2" v-model="selectedOrder.address2" />
                    <v-text-field label="City" v-model="selectedOrder.city" />
                    <v-text-field label="State" v-model="selectedOrder.state" />
                    <v-text-field label="Zip Code" v-model="selectedOrder.zipCode" />
                    <v-text-field label="Country" v-model="selectedOrder.country" />

                    <!-- <line-item-table v-if="selectedOrder.lineItems" :lineItems="selectedOrder.lineItems" /> -->
                    <!-- <v-btn @click="toggleLineItems">Line Items</v-btn> -->
                </form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="trackingNumDialog" max-width="600px">
            <v-card class="p-3">
                <v-card-title>Update Tracking</v-card-title>
                <v-card-text>
                    <!-- {{ selectedOrder }} -->
                    <v-row>
                        <v-col>
                            Shipping Address <br />
                            <span
                                >{{
                                    selectedOrder.shippingName
                                        ? selectedOrder.shippingName
                                        : selectedOrder.customerName
                                }}<br
                            /></span>
                            {{ selectedOrder.address1 }} <br />
                            <span v-if="selectedOrder.address2"><br /></span>
                            {{ selectedOrder.city }}, {{ selectedOrder.state }}
                            {{ selectedOrder.zipCode }}
                        </v-col>
                        <v-col>
                            Order ID: {{ selectedOrder.id }} <br />
                            Customer Name: {{ selectedOrder.customerName }}
                        </v-col>
                    </v-row>

                    <!-- <div>
                        Address <br />
                        {{ selectedOrder.address1 }}
                        {{ selectedOrder.address2 }}
                        {{ selectedOrder.city }}, {{ selectedOrder.city }} {{ selectedOrder.state }}
                        {{ selectedOrder.zipCode }}
                    </div> -->
                </v-card-text>
                <v-text-field v-model="selectedOrder.trackingNumber"></v-text-field>
                <div class="text-right">
                    <v-btn @cick="updateTracking(selectedOrder.id, selectedOrder.trackingNumber)" color="primary"
                        >Update</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width="600px">
            <v-card class="p-3">
                <v-card-title>Delete Order: {{ selectedOrder.id }}?</v-card-title>

                <v-card-text
                    >Please be sure before proceeding, once delted this cannot be undone and payment data may be
                    lost.</v-card-text
                >
                <v-card-text style="display: flex; flex-direction: column">
                    <span v-if="selectedOrder.paymentStatus >= 0"
                        >Payment Status: {{ paymentStatuses[selectedOrder.paymentStatus].label }}
                    </span>
                    <span v-if="selectedOrder.orderStatus >= 0"
                        >Order Status: {{ orderStatuses[selectedOrder.orderStatus].label }}</span
                    >
                    <span>Order ID: {{ selectedOrder.id }}</span>
                    <span>Purchase ID: {{ selectedOrder.purchaseId }}</span>
                    <span>Funeral Home ID: {{ selectedOrder.funeralHomeId }}</span>
                    <span>Amount: {{ selectedOrder.amount | formatMoney() }}</span>
                    <span>Amount Paid: {{ selectedOrder.amountPaid | formatMoney() }}</span>
                    <span>Customer Name: {{ selectedOrder.customerName }}</span>
                    <span>Customer Email: {{ selectedOrder.customerEmail }}</span>
                </v-card-text>
                <v-card-text class="text-center" v-if="!$auth.role.includes('God')">
                    <strong>You do not have access to delete this order</strong>
                </v-card-text>
                <div class="text-right">
                    <v-btn color="error" :disabled="!$auth.role.includes('God')" @click="deleteOrder(selectedOrder.id)"
                        >Delete</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { paymentStatuses, orderStatuses, shipmentStatuses, carriers, customerTypes } from '@/constants';
import LineItemTable from '@/components/Tables/Admin/LineItemTable.vue';
import { debounceV2 } from '@/utilities/debounce.js';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    components: { LineItemTable, CustomTooltip },
    data() {
        return {
            paymentStatuses,
            orderStatuses,
            customerTypes,
            carriers,
            shipmentStatuses,
            selectedStatuses: [0],
            orderStatusString: '',
            viewingLineItems: false,
            message: 'hi',
            token: '',
            orders: [],
            search: '',
            loading: true,
            itemLoading: false,
            total: 0,
            editDialog: false,
            deleteModal: false,
            selectedOrder: {},
            trackingNumDialog: false,
            tempTrackingNum: '',
            selectedOrder: {},
            options: {
                itemsPerPage: 25,
            },
            headers: [],
            filterMenuOpen: false,
        };
    },
    props: ['supplierId'],
    computed: {
        allStatusesSelected() {
            return this.selectedStatuses.length === this.orderStatuses.length;
        },
    },
    watch: {
        search: debounceV2(function () {
            this.getOrders();
        }, 500),
        options: debounceV2(function () {
            if (!this.loading) this.getOrders();
        }, 500),
        // selectedStatuses: debounceV2(function () {
        //     if (this.selectedStatuses.length === 0) {
        //         this.orders = [];
        //     } else {
        //         let string = '';
        //         this.selectedStatuses.forEach(status => (string += `&orderStatus=${status}`));
        //         this.orderStatusString = string;

        //         this.getOrders();
        //     }
        // }, 500),
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleStatusMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                var string = '';
                this.selectedStatuses.forEach(status => (string += `&orderStatus=${status}`));
                this.orderStatusString = string;
                this.getOrders();
                this.filterMenuOpen = false;
            }
        },
        openDeleteModal(item) {
            this.selectedOrder = item;
            this.deleteModal = true;
        },
        openTab(url) {
            window.open(url, '_blank');
        },
        toggleAllOrderStatuses() {
            if (this.allStatusesSelected) {
                this.selectedStatuses = [];
            } else {
                this.orderStatuses.forEach(status => {
                    if (status.value >= 0 && !this.selectedStatuses.includes(status.value)) {
                        this.selectedStatuses.push(status.value);
                    }
                });
            }
        },
        updateTracking(id, number) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Order/update-tracking/${id}?trackingNumber=${number}`)
                .then(response => {
                    this.trackingNumDialog = false;
                    this.showSnackbar({ message: 'Status Updated' });
                    this.getOrders();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        openTrackingNumDialog(item) {
            this.selectedOrder = item;
            this.trackingNumDialog = true;
        },
        updateOrderStatus(id, status) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Order/update-order-status/${id}?status=${status}`)
                .then(response => {
                    // this.$refs.menu.save();
                    this.showSnackbar({ message: 'Status Updated' });
                    this.getOrders();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getDetails(item) {
            this.$router.push({ path: `/order-details/${item.id}` });
        },
        editOrder(item) {
            this.selectedOrder = item;
            this.editDialog = true;
        },
        toggleLineItems() {
            this.viewingLineItems = !this.viewingLineItems;
        },
        getSortByValue(value) {
            switch (value) {
                case 'customerName':
                    return 'CustomerName';
                case 'id':
                    return 'Id';
                case 'orderStatus':
                    return 'OrderStatus';
                case 'paymentStatus':
                    return 'PaymentStatus';
                case 'createdDate':
                    return 'CreatedDate';
                case 'amount':
                    return 'Amount';
                case 'paymentStatus':
                    return 'PaymentAmount';
                case 'funeralHomeName':
                    return 'FuneralHomeName';
                case 'supplierName':
                    return 'SupplierName';
                case 'orderDate':
                    return 'OrderDate';
                case 'serviceName':
                    return 'ServiceName';
                case 'customerEmail':
                    return 'CustomerEmail';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        deleteOrder(id) {
            if (this.$auth.role.includes('God')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + `/Order/${id}`)
                    .then(response => {
                        this.deleteModal = false;
                        this.getOrders();
                        this.showSnackbar({ message: 'Order deleted' });
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.deleteModal = false;
                        this.showSnackbar({ message: 'There was an error deleting this order' });
                    });
            }
        },
        getOrders() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
            };

            if (this.$auth.role.includes('SuperAdmin')) {
                var endpoint =
                    process.env.VUE_APP_API + `/Order/admin-order-list?search=${this.search}${this.orderStatusString}`;
                params.supplierId = this.supplierId ? this.supplierId : 0;
            } else if (this.$auth.role.includes('FuneralHome')) {
                var endpoint =
                    process.env.VUE_APP_API +
                    `/Order/fh-orders/${this.$auth.funeralHomeId}?search=${this.search}${this.orderStatusString}`;

                // { text: 'Payment Status', value: 'paymentStatus' },
            } else {
                var endpoint =
                    process.env.VUE_APP_API +
                    `/Order/supplier-orders/${this.supplierId}?search=${this.search}${this.orderStatusString}`;
            }

            this.loading = true;

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(endpoint, { params: params })
                    .then(response => {
                        this.orders = response.data.orders.map((order, index) => ({
                            ...order,
                            subId: index + 1,
                        }));
                        this.total = response.data.total;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
    },

    mounted() {
        if (this.$auth.role.includes('SuperAdmin')) {
            if (this.supplierId > 0) {
                this.headers = [
                    { text: 'Order', value: 'id' },
                    { text: 'Purchase', value: 'purchaseId' },
                    // { text: 'Supplier', value: 'supplierId' },
                    { text: 'Home', value: 'funeralHomeName' },
                    { text: 'Customer', value: 'customerName' },
                    { text: 'Email', value: 'customerEmail' },
                    { text: 'Created', value: 'createdDate' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Order Status', value: 'orderStatus', width: '10%' },
                    { text: 'Payment Status', value: 'paymentStatus' },
                    // { text: 'Actions', value: 'actions', sortable: false },
                ];
            } else {
                this.headers = [
                    { text: 'Order', value: 'id' },
                    { text: 'Purchase', value: 'purchaseId' },
                    { text: 'Supplier', value: 'supplierName' },
                    { text: 'Home', value: 'funeralHomeName' },
                    { text: 'Customer', value: 'customerName' },
                    { text: 'Email', value: 'customerEmail' },
                    { text: 'Created', value: 'createdDate' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Order Status', value: 'orderStatus', width: '10%' },
                    { text: 'Payment Status', value: 'paymentStatus' },
                    // { text: 'Actions', value: 'actions', sortable: false },
                ];
            }
        } else if (this.$auth.role.includes('FuneralHome')) {
            this.headers = [
                // { text: 'Id', value: 'id' },
                { text: 'Date', value: 'orderDate' },
                { text: 'Service', value: 'serviceName' },
                { text: 'Tracking', value: 'trackingNumber' },
                { text: 'Amount', value: 'amount' },
                { text: 'Carrier', value: 'carrier' },
                { text: 'Status', value: 'fhStatus' },

                // { text: 'Actions', value: 'actions', width: '10%', align: 'center' },
            ];
        } else {
            this.headers = [
                { text: 'ID', value: 'id' },
                { text: 'Customer', value: 'customerName' },
                { text: 'Created', value: 'createdDate' },
                { text: 'Amount', value: 'amount' },
                { text: 'Order Status', value: 'orderStatus', width: '10%' },
                // { text: 'Payment Status', value: 'paymentStatus' },
            ];
        }
        this.getOrders();
    },
};
</script>
<style>
.details-container {
    /* border: 2px solid red; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.selectOrderStatus.v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
    opacity: 0;
}
.selectOrderStatus.v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
    border: 2px solid red;
    opacity: 0;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"fa-regular fa-magnifying-glass"}}),_c('h3',{staticClass:"m-0"},[_vm._v("User Search")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getUsers()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userType",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.userTypes[item.userType].color}},[_vm._v(_vm._s(_vm.userTypes[item.userType].label))])]}},{key:"item.org",fn:function(ref){
        var item = ref.item;
return [(item.userType == 0)?_c('v-chip',{attrs:{"color":_vm.userTypes[item.userType].color,"outlined":""}},[_vm._v(_vm._s(item.funeralHomeId)+" "+_vm._s(item.funeralHomeString))]):_vm._e(),(item.userType == 1 || item.userType == 4)?_c('v-chip',{attrs:{"color":_vm.userTypes[item.userType].color,"outlined":""}},[_vm._v(_vm._s(item.ownerId)+" "+_vm._s(item.ownerString))]):_vm._e()]}},{key:"item.firstName",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.auth0",fn:function(ref){
        var item = ref.item;
return [(item.auth0Id)?_c('div',{staticStyle:{"max-width":"20px"},on:{"click":function($event){return _vm.goToAuth0(item)}}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require('@/assets/images/auth0_logo.png'),"alt":"Logo"}})]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
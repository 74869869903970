<template>
    <div>
        <h6 v-if="lifeEvents.length > 0">Life Events</h6>
        <v-row>
            <v-col
                sm="6"
                md="6"
                lg="4"
                xl="3"
                cols="12"
                class="d-flex align-center justify-center"
                v-for="(event, index) in lifeEvents"
            >
                <div @click="openLifeEvent(event)" class="img-container">
                    <!-- {{ index }} -->
                    <!-- v-if="event.coverPhotoUrl" -->
                    <v-img
                        class="life-event-img"
                        :src="handleEventImgSrc(event)"
                        :lazy-src="handleEventImgSrc(event)"
                    ></v-img>
                    <!-- <v-img v-if="!event.coverPhotoUrl" :src="logoSrc" :lazy-src="logoSrc"> </v-img> -->
                    <div class="overlay text-center">
                        <v-btn @click.stop="initLifeEventDelete(event)" class="del-btn" fab x-small>
                            <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                        </v-btn>
                        <h5>{{ event.name }}</h5>
                        <h6>{{ formatDate(event.date) }}</h6>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-dialog @click:outside="closeEventModal" v-model="eventModal" max-width="600px">
            <v-card class="life-event-modal">
                <v-card-title>Event modal</v-card-title>
                <div class="p-3">
                    <v-form ref="form" @submit.prevent="submitLifeEvent" v-model="formValid">
                        <v-text-field
                            :rules="requiredRule"
                            v-model="selectedLifeEvent.name"
                            label="Name"
                        ></v-text-field>
                        <!-- <v-text-field label="Date"></v-text-field> -->

                        <div :class="['calendar-wrapper', { 'calendar-wrapper--active': modalCalendarActive }]">
                            <calendar
                                @focus="focus => handleCalendarFocus(focus)"
                                v-model="selectedLifeEvent.date"
                                label="Date"
                            />
                        </div>

                        <v-textarea
                            :rules="requiredRule"
                            label="Description"
                            v-model="selectedLifeEvent.description"
                        ></v-textarea>
                        <div class="d-flex justify-space-between">
                            <button type="button" @click="closeEventModal" class="btn-ghost">Close</button>
                            <button :disabled="submitDisabled" type="submit" class="btn-orange">Save</button>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width="400px">
            <v-card>
                <v-card-title>Delete Event?</v-card-title>
                <v-card-text
                    >Are you sure you want to delete <strong>{{ selectedLifeEvent.name }}</strong
                    >? This cannot be undone.</v-card-text
                >

                <div class="pb-3 text-center">
                    <button @click="confirmLifeEventDelete" class="btn-danger">Delete</button>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import moment from 'moment';
import Calendar from '@/components/ui/Calendar.vue';
import logoSrc from '@/assets/images/logo.png';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            message: 'test',
            token: '',
            lifeEvents: [],
            logoSrc,
            total: 0,
            placeholderImg:
                'https://photos.memoryshare.com/tr:w-250,h-250/FH217/S12751/photos/tribute_id324_event_id24235_photo_id4090_25544047-5fa7-4fd7-a4cc-dfb29f90f781_043_2082.jpg',
            eventModal: false,
            modalCalendarActive: false,
            lifeEventDate: '',
            formValid: false,

            selectedLifeEvent: {
                name: '',
                date: '',
                description: '',
                tributeId: null,
            },
            requiredRule: [v => !!v || 'This field is required'],
            deleteModal: false,
        };
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        submitDisabled() {
            if (!this.selectedLifeEvent.name || !this.selectedLifeEvent.date || !this.selectedLifeEvent.description) {
                return true;
            }
            return false;
        },
    },
    components: {
        Calendar,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        closeEventModal() {
            this.resetSelectedLifeEvent();
            this.eventModal = false;
        },
        handleEventImgSrc(event) {
            // process.env.VUE_APP_IMG_KIT_BASE

            let src = '';
            if (event.coverPhotoUrl) {
                src = event.coverPhotoUrl;
            } else if (this.tributeVideo.mainPhotoUrl) {
                src = this.tributeVideo.mainPhotoUrl;
            }

            if (src) {
                const imgPath = src.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-400,h-400/' + imgPath;
            }
        },
        openLifeEvent(event) {
            this.selectedLifeEvent = { ...event };
            this.eventModal = true;
        },
        resetSelectedLifeEvent() {
            this.selectedLifeEvent = {
                name: '',
                date: '',
                description: '',
                tributeId: null,
            };
            this.$refs.form.resetValidation();
        },
        confirmLifeEventDelete() {
            this.axiosInstance
                .delete(`TributeLifeEvent/${this.selectedLifeEvent.id}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Event deleted' });
                    this.getLifeEvents(this.tributeVideo.id);
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error deleting event', color: 'error' });
                })
                .finally(() => {
                    this.deleteModal = false;
                });
        },
        initLifeEventDelete(event) {
            this.selectedLifeEvent = { ...event };
            this.deleteModal = true;
        },
        getLifeEvents(id) {
            this.axiosInstance
                .get(`TributeLifeEvent/get-all/${id}`)
                .then(resp => {
                    this.lifeEvents = resp.data.lifeEvents;
                    this.total = resp.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        formatDate(dateTime) {
            return moment(dateTime).format('MM/DD/YY');
        },
        submitLifeEvent() {
            if (!this.tributeVideo.id) {
                this.showSnackbar({ message: 'An error occured, could not find tribute video id', color: 'error' });
                return;
            }

            this.selectedLifeEvent.tributeId = this.tributeVideo.id;
            if (!this.selectedLifeEvent.date) {
                this.showSnackbar({ message: 'Please enter a date to continue', color: 'error' });
                return;
            }
            if (!this.formValid) {
                this.showSnackbar({ message: 'Please fill out the required fields to continue', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/TributeLifeEvent/${this.selectedLifeEvent.tributeId}`, this.selectedLifeEvent)
                .then(resp => {
                    this.getLifeEvents(this.tributeVideo.id);
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.eventModal = false;
                    this.resetSelectedLifeEvent();
                    this.$refs.form.resetValidation();
                });
        },
        handleCalendarFocus(focus) {
            if (this.modalCalendarActive != focus) {
                this.modalCalendarActive = focus;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        if (this.tributeVideo.id) {
            this.getLifeEvents(this.tributeVideo.id);
        }
    },
};
</script>
<style lang="scss" scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.7) 100%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    border-radius: 5px;
}

.img-container {
    cursor: pointer;
    aspect-ratio: 0.7;
    max-height: 250px;
    max-width: 100%;

    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .life-event-img {
        height: 100%;
        border-radius: 5px;
    }
}
.img-container:hover .del-btn {
    opacity: 0.4;
    // border: 2px solid red;
}

.del-btn {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 5px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.del-btn:hover {
    opacity: 1 !important;
}
.life-event-modal {
    .calendar-wrapper {
        height: 73px;
        transition: height 0.3s ease;
    }
    .calendar-wrapper--active {
        height: 520px !important;
    }
}
</style>

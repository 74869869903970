<template>
    <div>
        <v-card>
            <v-card-title class="text-h5"> Super Admin Invoice Edit </v-card-title>

            <v-card-text v-if="loaded">
                <form class="super-container" @submit.prevent="handleFormSubmission">
                    <v-text-field v-model="formData.id" label="Invoice ID" />
                    <v-text-field v-model="formData.funeralHomeId" label="Funderal Home ID" />
                    <v-text-field v-model="formData.ownerId" label="Owner ID" />
                    <calendar label="Create Date" v-model="formData.createdDate" />
                    <calendar label="Finalized Date" v-model="formData.finalizedDate" />
                    <v-text-field v-model="formData.amountDue" label="Amount Due" type="number" />
                    <v-text-field v-model="formData.amountPaid" label="Amount Paid" type="number" />
                    <v-text-field v-model="formData.pdfLink" label="PDF Link" />
                    <v-text-field v-model="formData.stripeLink" label="Stripe Link" />
                    <v-text-field v-model="formData.description" label="Description" />

                    <calendar label="Period Start" v-model="formData.periodStart" />
                    <calendar label="Period End" v-model="formData.periodEnd" />

                    <v-text-field v-model="formData.stripeId" label="Stripe ID" />
                    <v-text-field v-model="formData.email" label="Email" />
                    <v-text-field v-model="formData.customerId" label="Customer ID" />
                    <v-text-field v-model="formData.subscriptionId" label="Subscription ID" />

                    <v-checkbox v-model="formData.ownerInvoice" label="Owner Invoice" />
                    <v-text-field v-model="formData.stripeStatus" label="Stripe Status" />
                    <v-text-field v-model="formData.invoiceType" label="Invoice Type" />

                    <v-text-field v-model="formData.total" label="Total" type="number" />
                    <v-text-field v-model="formData.billingReason" label="Billing Reason" />

                    <calendar label="Due Date" v-model="formData.dueDate" />
                    <v-text-field v-model="formData.stripePublicId" label="Stripe Public ID" />
                    <v-checkbox v-model="formData.paid" label="Paid" />
                    <v-text-field v-model="formData.statementDescriptor" label="Statement Descriptor" />

                    <div>
                        <v-btn
                            v-if="formData.stripeStatus != 'void' && formData.stripeStatus != 'refunded'"
                            class="mt-1"
                            block
                            dark
                            large
                            @click="voidOrRefundInvoiceModal = true"
                            >{{ formData.paid ? 'Refund Invoice' : 'Void Invoice' }}</v-btn
                        >
                        <v-btn
                            :class="
                                formData.stripeStatus != 'void' && formData.stripeStatus != 'refunded' ? 'mt-4' : 'mt-1'
                            "
                            block
                            color="#0C3C60"
                            dark
                            large
                            type="submit"
                            >Save</v-btn
                        >
                    </div>
                </form>
            </v-card-text>
        </v-card>
        <v-dialog v-model="voidOrRefundInvoiceModal" max-width="400px">
            <v-card>
                <v-card-title>{{ formData.paid ? 'Refund' : 'Void' }} Invoice?</v-card-title>
                <v-card-text>
                    <p>Invoice Number: {{ formData.id }}</p>
                    <p>Amount: {{ formData.amountDue | formatMoney }}</p>
                </v-card-text>

                <div class="text-center p-3">
                    <v-btn
                        dark
                        class="mr-3"
                        color="red"
                        @click="formData.paid ? refundInvoice(formData.id) : voidInvoice(formData.id)"
                        >{{ formData.paid ? 'Refund' : 'Void' }} Invoice</v-btn
                    >
                    <v-btn @click="voidOrRefundInvoiceModal = false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { eventStates } from '@/constants';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    components: {
        Calendar,
    },
    data() {
        return {
            token: null,
            formData: {},
            loaded: false,
            voidOrRefundInvoiceModal: false,
        };
    },
    methods: {
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */

        voidInvoice(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(`${process.env.VUE_APP_API}/billing/invoices/void/${id})`)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        refundInvoice(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(`${process.env.VUE_APP_API}/billing/invoices/refund/${id})`)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        convertIntObj: function (obj) {
            {
                let res = {};
                Object.keys(obj).forEach(key => {
                    if (key === 'pin') {
                        res[key] = obj[key];
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });
                return res;
            }
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/billing/invoices/admin-edit/${this.$props.invoice.id}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(`${process.env.VUE_APP_API}/billing/invoices/admin-edit/${this.$props.invoice.id}`)
                .then(response => {
                    this.formData = response.data;
                    this.loaded = true;
                })
                .catch(error => {});
        });
    },
    watch: {
        invoice: function () {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(`${process.env.VUE_APP_API}/billing/invoices/admin-edit/${this.$props.invoice.id}`)
                    .then(response => {
                        this.formData = response.data;

                        this.formData.createdDate = this.checkForUTC(response.data.createdDate);
                        this.formData.finalizedDate = this.checkForUTC(response.data.finalizedDate);
                        this.formData.periodStart = this.checkForUTC(response.data.periodStart);
                        this.formData.periodEnd = this.checkForUTC(response.data.periodEnd);
                        this.formData.dueDate = this.checkForUTC(response.data.dueDate);

                        this.loaded = true;
                    })
                    .catch(error => {});
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
</style>

<template>
    <div>
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-bolt" class="m-2" />
            <h3 class="mt-3">Credits</h3>
            <v-spacer></v-spacer>
            <create-credit-type @refresh="getCreditTypes()" />
            <!-- <create-credit-type></create-credit-type> -->
        </v-card-title>

        <v-data-table
            class="credits-table"
            :headers="headers"
            :items="creditTypes"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:item.name="{ item }">
                <v-text-field v-if="item.isEditing" v-model="tempName">Editing</v-text-field>
                <span v-else>{{ item.name }}</span>
            </template>

            <template v-slot:item.suggestedAmount="{ item }">
                <!-- <v-text-field v-if="item.isEditing" v-model="tempAmount"></v-text-field> -->
                <vuetify-money :options="currencyOptions" v-if="item.isEditing" v-model="tempAmount"></vuetify-money>
                <span v-else>{{ item.suggestedAmount | moneyFilter() }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div v-if="item.isEditing" class="action-list">
                    <v-btn @click="cancelEdit(item.id)">Cancel</v-btn>
                    <v-btn @click="saveEdit(item.id)" class="info">Save</v-btn>
                </div>

                <div v-else class="action-list" style="display: flex; justify-content: center">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon icon="fa-regular fa-pencil" @click="editCreditType(item.id)" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon icon="fa-regular fa-trash-can" @click="openDeleteModal(item)" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="deleteModal" max-width="400px">
            <v-card class="deleteModalCard">
                <v-card-title style="display: flex; justify-content: center; color: red">
                    <h2>Warning</h2>
                </v-card-title>
                <v-card-text>
                    <h4>Are you sure you want to delete {{ selectedCredit.name }}?</h4>
                </v-card-text>
                <span class="deleteModalBtns">
                    <v-btn @click="cancelDelete">Cancel</v-btn>

                    <v-btn color="error" @click="deleteCredit">Delete</v-btn>
                </span>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateCreditType from '@/components/Actions/Admin/CreateCreditType.vue';
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Credits',
    },
    components: {
        CreateCreditType,
        CustomTooltip,
    },
    data() {
        return {
            newCreditType: '',
            token: '',
            errorMessage: '',
            creditTypes: [],
            errorMessage: '',
            deleteModal: false,
            tempCarryOver: false,
            tempName: '',
            tempAmount: '',
            options: {
                itemsPerPage: 25,
            },
            selectedCredit: {},
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Suggested Amount', value: 'suggestedAmount' },
                { text: 'Actions', value: 'actions', align: 'center' },
            ],
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
        };
    },
    filters: {
        moneyFilter(value) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            return formatter.format(value);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        deleteCredit() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axiosInstance
                    .delete(`/Credit/type/${this.selectedCredit.id}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Credit deleted' });
                        this.getCreditTypes();
                        this.deleteModal = false;
                        this.selectedCredit = {};
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error deleting credit' });
                    });
            }
        },
        cancelDelete() {
            this.deleteModal = false;
        },
        openDeleteModal(item) {
            this.selectedCredit = item;
            this.deleteModal = true;
        },
        saveEdit(id) {
            const eventToUpdate = this.creditTypes.find(event => event.id === id);
            eventToUpdate.name = this.tempName;
            eventToUpdate.suggestedAmount = parseInt(this.tempAmount * 100);
            eventToUpdate.carryOver = this.tempCarryOver;

            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` }, baseURL: process.env.VUE_APP_API })
                    .put(`Credit/type/${id}`, eventToUpdate)
                    .then(response => {
                        this.showSnackbar({ message: 'Credit updated successfully' });
                        this.getCreditTypes();
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error updating credit' });
                    });
            });
        },
        cancelEdit(id) {
            const creditTypes = this.creditTypes.map(event => {
                if (event.id === id) {
                    this.tempName = event.name;
                    this.tempAmount = event.suggestedAmount;
                    return { ...event, isEditing: false };
                } else return event;
            });
            this.creditTypes = creditTypes;
        },
        editCreditType(id) {
            const creditTypes = this.creditTypes.map(event => {
                if (event.id === id) {
                    this.tempName = event.name;
                    this.tempAmount = event.suggestedAmount;
                    this.tempCarryOver = event.carryOver;
                    return { ...event, isEditing: true };
                } else return event;
            });
            this.creditTypes = creditTypes;
        },
        test() {},
        getCreditTypes() {
            this.axiosInstance.get(`/Credit/type`).then(response => {
                this.creditTypes = response.data.creditTypes.map(obj => ({
                    ...obj,
                    suggestedAmount: obj.suggestedAmount / 100,
                }));
            });
        },
        createCreditType() {
            let data = {
                name: this.newCreditType,
                carryOver: false,
                suggestedAmount: 18,
            };

            this.axiosInstance
                .post(`/Credit/type`, data)
                .then(response => {})
                .catch(error => {
                    this.errorMessage = error.response.data;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getCreditTypes();
    },
};
</script>

<style lang="scss">
.deleteModalBtns {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;
}
.deleteModalCard {
    text-align: center;
}
</style>

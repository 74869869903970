<template>
    <div style="margin-left: 0; padding-top: 15px" class="dash-container">
        <div v-if="searchText && searching" style="display: flex; justify-content: center">
            <div class="search-banner elevation-4">
                <strong>Search:</strong>

                <v-chip
                    label
                    outlined
                    color="primary"
                    class="ml-4"
                    v-if="searchText"
                    close
                    @click:close="clearSearch"
                    >{{ searchText }}</v-chip
                >
            </div>
        </div>
        <!-- <v-chip v-if="searchText" close @click:close="clearSearch">{{ searchText }}</v-chip> -->

        <div class="search-tab elevation-4" @click="openSearchModal">
            <font-awesome-icon id="search-icon" icon="fa-regular fa-magnifying-glass" />
        </div>
        <img v-if="homeData.logo" class="elevation-4 p-2 homeLogo" :src="homeData.logo" alt="Home Logo" />
        <v-row>
            <v-col>
                <v-card class="ml-15 mr-15">
                    <v-card-title class="justify-center p-3">
                        <h1>{{ homeSlug }}</h1>
                    </v-card-title>
                    <v-card-text class="text-center" v-if="homeData.city">
                        {{ homeData.address1 }}
                        {{ homeData.address2 }}<br />
                        {{ homeData.city }}, {{ homeData.state }} {{ homeData.zip }}
                    </v-card-text>
                </v-card>

                <v-card class="ml-15 mr-15" style="display: flex; flex-direction: column" v-if="currentlyLive">
                    <v-row>
                        <v-col class="mt-8" align="center">
                            <div style="margin-bottom: -80px" class="text-right mr-5">
                                <v-chip
                                    large
                                    class="mt-2"
                                    dark
                                    :color="serviceStates[featuredLiveStream.serviceState].color"
                                    >{{ serviceStates[featuredLiveStream.serviceState].label
                                    }}{{
                                        featuredLiveStream.currentLiveEventTitle
                                            ? ' - ' + featuredLiveStream.currentLiveEventTitle
                                            : ''
                                    }}</v-chip
                                >
                            </div>
                            <div>
                                <a :href="`/services/view/${featuredLiveStream.slug}`">
                                    <img
                                        v-if="
                                            featuredLiveStream.pictureURL && featuredLiveStream.pictureURL !== 'string'
                                        "
                                        class="mb-4"
                                        :src="featuredLiveStream.pictureURL"
                                        alt="placeholder thumbnail"
                                    />
                                    <img
                                        v-else
                                        class="mb-4"
                                        src="@/assets/images/icon_dark.png"
                                        alt="placeholder thumbnail"
                                    />
                                </a>

                                <h3>{{ featuredLiveStream.firstName }} {{ featuredLiveStream.lastName }}</h3>
                                <h5>
                                    {{ featuredLiveStream.birthDate | dateFormat() }} -
                                    {{ featuredLiveStream.deathDate | dateFormat() }}
                                </h5>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card v-if="homeData" class="ml-15 mr-15">
                    <div class="p-3" v-for="(item, index) in homeData.services.data" :key="index">
                        <div v-if="item.slug !== featuredLiveStream.slug">
                            <v-row>
                                <v-col class="text-center">
                                    <a class="thumbnail" :href="`/services/view/${item.slug}`">
                                        <img
                                            v-if="item.pictureURL && item.pictureURL !== 'string'"
                                            style="max-height: 200px"
                                            :src="item.pictureURL"
                                            alt="placeholder thumbnail"
                                        />
                                        <img
                                            v-else
                                            style="max-height: 200px"
                                            src="@/assets/images/icon_dark.png"
                                            alt="placeholder thumbnail"
                                        />
                                    </a>
                                </v-col>
                                <v-col cols="7" style="display: flex; flex-direction: column; justify-content: center">
                                    <div>
                                        <h3>{{ item.firstName }} {{ item.lastName }}</h3>

                                        <h5>
                                            {{ item.birthDate | dateFormat() }} - {{ item.deathDate | dateFormat() }}
                                        </h5>

                                        <v-chip class="mt-2" dark :color="serviceStates[item.serviceState].color"
                                            >{{ serviceStates[item.serviceState].label
                                            }}{{
                                                item.currentLiveEventTitle ? ' - ' + item.currentLiveEventTitle : ''
                                            }}</v-chip
                                        >
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider v-if="index !== homeData.services.data.length - 1"></v-divider>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog @click:outside="search" v-model="searchModal" width="400px">
            <v-card class="p-3">
                <v-card-title>Search</v-card-title>

                <div style="display: flex">
                    <v-text-field autofocus @keydown.enter="search" v-model="searchText"></v-text-field>
                    <v-btn @click="search" class="mt-3 ml-3" dark color="primary">
                        <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { serviceStates } from '@/constants';

export default {
    metaInfo: { title: 'Landing Page' },
    data() {
        return {
            homeSlug: '',
            homeData: '',
            serviceStates,
            currentlyLive: false,
            featuredLiveStream: {},
            page: 0,
            searchModal: false,
            searchText: '',
            searching: false,
            endOfResults: false,
        };
    },

    filters: {
        dateFormat(value) {
            return moment(value).format('l');
        },
    },
    methods: {
        clearSearch() {
            this.endOfResults = false;
            this.page = 0;
            this.searchText = '';
            this.searching = false;
            this.getHomeInfo();
            window.scrollTo(0, 0);
        },
        openSearchModal() {
            this.searchText = '';
            this.searchModal = true;
            this.searching = false;
        },
        search() {
            this.endOfResults = false;
            this.page = 0;
            this.searching = true;
            this.getHomeInfo();
            window.scrollTo(0, 0);
        },
        getHomeInfo() {
            this.endOfResults = false;
            this.homeSlug = this.$route.params.id;
            this.axios
                .get(process.env.VUE_APP_API + `/funeralhomes/landing-page/${this.homeSlug}?search=${this.searchText}`)
                .then(response => {
                    this.homeData = response.data;
                    this.searchModal = false;

                    this.checkLive();
                });
        },
        checkLive() {
            for (let i = 0; i < this.homeData.services.data.length; i++) {
                if (this.homeData.services.data[i].serviceState === 2) {
                    this.currentlyLive = true;
                    this.featuredLiveStream = this.homeData.services.data[i];
                }
            }
        },
        handleScroll() {
            let bottomOfWindow =
                document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            if (bottomOfWindow && !this.endOfResults) {
                this.page++;
                this.axios
                    .get(
                        process.env.VUE_APP_API +
                            `/funeralhomes/landing-page/${this.homeSlug}?search=${this.searchText}&pageSize=10&pageNumber=${this.page}`,
                    )
                    .then(response => {
                        let additionalStreams = response.data.services.data;
                        this.homeData.services.data = this.homeData.services.data.concat(additionalStreams);

                        if (response.data.services.data.length == 0) {
                            this.endOfResults = true;
                        }
                    });
            }
            this.checkLive();
        },
    },
    watch: {
        searchText() {
            if (this.searchText == null) {
                this.searching = false;
            }
        },
    },
    created() {
        this.$store.dispatch('toggleTopNav');
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.getHomeInfo();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>

<style>
.homeLogo {
    max-height: 100px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    border: 2px solid black;
    border-radius: 10px;
    opacity: 0.9;
}
.thumbnail:hover {
    opacity: 0.8;
}
.search-tab {
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;
    border-radius: 10px 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    transition: 0.3s;
    cursor: pointer;
}
.search-tab:hover {
    height: 70px;
    width: 70px;
    transition: 0.3s;
}

.search-tab:hover > #search-icon {
    transform: scale(1.3);
    transition: 0.3s;
}

#search-icon {
    transition: 0.3s;
}

.search-banner {
    background-color: white;
    min-height: 40px;
    min-width: 170px;
    position: absolute;
    top: 0;
    position: fixed;
    z-index: 2;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
}
</style>

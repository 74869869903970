<template>
    <div class="p-3">
        <div class="pb-3 text-right">
            <v-btn dark @click="initNewCode">Add <font-awesome-icon class="ml-2" icon="fa-solid fa-plus" /></v-btn>
        </div>
        <div v-if="loading">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>

        <div v-if="!loading">
            <div class="text-center" v-if="trackingCodes.length == 0 && !loading">
                <h5>No marketing data available</h5>
                <p>
                    Get started today for access to metrics like these
                    <font-awesome-icon class="ml-1" icon="fa-solid fa-arrow-down" style="font-size: 0.8rem" />
                </p>
            </div>

            <div class="elevation-4 window">
                <v-window continuous v-if="trackingCodes.length > 0 && !loading" v-model="window">
                    <v-window-item v-for="(item, index) in trackingCodes" :key="`card-${index}`">
                        <div>
                            <div class="stat-wrap">
                                <div class="stats-div p-3">
                                    <div class="img-container-displayed">
                                        <img
                                            v-if="item.trackingCodeType >= 0"
                                            :src="
                                                require('@/assets/images/' +
                                                    trackingCodeTypes[item.trackingCodeType].logo)
                                            "
                                            alt="Logo"
                                        />
                                    </div>

                                    <v-divider></v-divider>
                                    <div
                                        style="display: flex; justify-content: space-between"
                                        @mouseover="showDescription(0)"
                                        @mouseleave="hideDescription"
                                        v-if="item.locationScoped == true"
                                    >
                                        <span class="d-flex">
                                            <div style="width: 30px">
                                                <font-awesome-icon icon="fa-solid fa-location-dot" />
                                            </div>
                                            Miles
                                        </span>
                                        <span>
                                            {{ item.radius }}
                                        </span>
                                    </div>
                                    <div
                                        style="display: flex; justify-content: space-between"
                                        v-if="item.ageScoped"
                                        @mouseover="showDescription(1)"
                                        @mouseleave="hideDescription"
                                    >
                                        <span class="d-flex">
                                            <div style="width: 30px">
                                                <font-awesome-icon
                                                    style="font-size: 1.2rem"
                                                    icon="fa-solid fa-person"
                                                />
                                            </div>
                                            Age
                                        </span>
                                        <span>
                                            {{ item.minAge }} -
                                            <span v-if="item.maxAge == -1">
                                                <font-awesome-icon icon="fa-regular fa-arrow-up"
                                            /></span>
                                            <span v-else> {{ item.maxAge }}</span>
                                        </span>
                                    </div>

                                    <div
                                        v-if="item.serviceDateScoped"
                                        style="display: flex; justify-content: space-between"
                                        @mouseover="showDescription(2)"
                                        @mouseleave="hideDescription"
                                    >
                                        <span class="d-flex">
                                            <div style="width: 30px">
                                                <font-awesome-icon icon="fa-solid fa-calendar-range" />
                                            </div>
                                            Days
                                        </span>
                                        <span>
                                            {{ item.minServiceDate }} -
                                            <span v-if="item.maxServiceDate == -1">
                                                <font-awesome-icon icon="fa-regular fa-arrow-up"
                                            /></span>
                                            <span v-else> {{ item.maxServiceDate }}</span>
                                        </span>
                                    </div>
                                    <div
                                        @mouseover="showDescription(3)"
                                        @mouseleave="hideDescription"
                                        style="display: flex; justify-content: space-between"
                                    >
                                        <span class="d-flex">
                                            <div style="width: 30px">
                                                <font-awesome-icon icon="fa-solid fa-user" />
                                            </div>
                                            IP Addresses Tracked
                                        </span>

                                        <span v-if="item.loadingImpact">
                                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                        </span>
                                        <span v-else>
                                            {{ item.estimated30DayHits }}
                                        </span>
                                    </div>
                                </div>
                                <div id="description" class="p-3" style="flex-grow: 1"></div>
                                <div class="p-3" style="display: flex; flex-direction: column">
                                    <v-btn style="opacity: 1" @click="initEdit(item)" dark>Edit</v-btn>
                                    <v-btn @click="initDeleteModal(item)" color="error" class="mt-2">Delete</v-btn>
                                </div>
                            </div>

                            <div>
                                <marketing-map
                                    :location="{ lat: item.latitude, long: item.longitude, rad: item.radius }"
                                    :lockArea="true"
                                    :interactive="true"
                                    :mapId="item.id.toString()"
                                    :ref="'map' + item.id"
                                    :locationScoped="item.locationScoped"
                                    :showAnalytics="true"
                                />
                            </div>
                        </div>
                    </v-window-item>
                </v-window>

                <v-window v-if="trackingCodes.length == 0 && !loading">
                    <v-window-item>
                        <!-- Dummy Data if no codes exist -->
                        <div class="dummyData" v-if="trackingCodes.length == 0 && !loading">
                            <div>
                                <div class="stat-wrap">
                                    <div class="stats-div p-3">
                                        <div class="img-container-displayed">
                                            <img :src="require('@/assets/images/google_analytics.png')" alt="Logo" />
                                        </div>
                                        <v-divider></v-divider>
                                        <!-- <div>Type: {{ trackingCodeTypes[item.trackingCodeType].label }}</div> -->

                                        <div style="display: flex; justify-content: space-between">
                                            <span class="d-flex">
                                                <div style="width: 30px">
                                                    <font-awesome-icon icon="fa-solid fa-location-dot" />
                                                </div>
                                                Miles
                                            </span>
                                            <span> 25 </span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between">
                                            <span class="d-flex">
                                                <div style="width: 30px">
                                                    <font-awesome-icon
                                                        style="font-size: 1.2rem"
                                                        icon="fa-solid fa-person"
                                                    />
                                                </div>
                                                Age
                                            </span>
                                            <span> 30 - 75 </span>
                                        </div>

                                        <div style="display: flex; justify-content: space-between">
                                            <span class="d-flex">
                                                <div style="width: 30px">
                                                    <font-awesome-icon icon="fa-solid fa-calendar-range" />
                                                </div>
                                                Days
                                            </span>
                                            <span> 0 - 45 </span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between">
                                            <span class="d-flex">
                                                <div style="width: 30px">
                                                    <font-awesome-icon icon="fa-solid fa-calendar-circle-user" />
                                                </div>
                                                Est Hits
                                            </span>
                                            <span> 60 </span>
                                        </div>
                                    </div>
                                    <div class="p-3" style="display: flex; flex-direction: column">
                                        <v-btn disabled>Edit</v-btn>
                                        <v-btn disabled color="error" class="mt-2">Delete</v-btn>
                                    </div>
                                </div>
                            </div>
                            <v-btn @click="initNewCode" dark x-large class="cta-btn"
                                >Get Started <font-awesome-icon icon="fa-solid fa-hand-pointer" class="ml-2"
                            /></v-btn>
                            <div>
                                <marketing-map
                                    class="dummyMap"
                                    :location="{ lat: 40.73061, long: -73.935242, rad: 25 }"
                                    :lockArea="true"
                                    :interactive="false"
                                    mapId="dummyMap"
                                    ref="dummyMap"
                                    :locationScoped="true"
                                    :showAnalytics="false"
                                />
                            </div>
                        </div>
                    </v-window-item>
                </v-window>

                <v-card-actions class="justify-space-between">
                    <v-btn :disabled="trackingCodes.length == 0" text @click="prev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-item-group v-model="window" class="text-center" mandatory>
                        <v-item
                            v-for="(item, index) in trackingCodes"
                            :key="`btn-${index}`"
                            v-slot="{ active, toggle }"
                        >
                            <v-btn :input-value="active" icon @click="toggle">
                                <v-icon>mdi-record</v-icon>
                            </v-btn>
                        </v-item>
                    </v-item-group>
                    <v-btn :disabled="trackingCodes.length == 0" text @click="next">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </div>
        </div>

        <create-edit-tracking-code
            :token="token"
            @refresh="getTrackingCodes"
            @cancel="cancelTrackingCodeModal"
            ref="codeComp"
        />

        <!-- START: Delete Modal -->
        <v-dialog v-model="deleteModal" max-width="600px">
            <v-card class="p-3">
                <v-card-title> Delete Ad Campaign? </v-card-title>
                <v-card-text v-if="selectedTrackingCode.trackingCodeType"
                    >Type: {{ trackingCodeTypes[selectedTrackingCode.trackingCodeType].label }}</v-card-text
                >
                <v-card-text>Est 30 Day: {{ selectedTrackingCode.estimated30DayHits }}</v-card-text>
                <div class="p-3" style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelDelete">Cancel</v-btn>
                    <v-btn color="error" @click="confirmDelete">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Delete Modal -->
    </div>
</template>
<script>
import MarketingMap from '@/components/Marketing/MarketingMap.vue';
import EmbedCodeHighlight from '@/components/Misc/EmbedCodeHighlight.vue';
import CreateEditTrackingCode from '@/components/Marketing/CreateEditTrackingCode.vue';
import { trackingCodeTypes, trackingCodeTriggers } from '@/constants';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            loading: true,
            trackingCodeTypes,
            trackingCodeTriggers,
            token: '',
            window: 0,
            trackingCodeModal: false,
            deleteModal: false,
            selectedTrackingCode: {},
            trackingCodes: [],
            fhCoordinates: {
                lat: null,
                long: null,
            },
            fhAddress: {},

            stats: [],
        };
    },
    components: { MarketingMap, EmbedCodeHighlight, CreateEditTrackingCode },
    methods: {
        ...mapActions(['showSnackbar']),
        cancelTrackingCodeModal() {
            this.trackingCodeModal = false;
        },
        hideDescription() {
            var description = document.getElementById('description');
            description.style.opacity = 0;
        },
        showDescription(value) {
            var description = document.getElementById('description');
            description.style.opacity = 0;
            if (value == 0) description.innerHTML = '<p><strong>Target area coverage</strong></p>';
            if (value == 1) description.innerHTML = '<p><strong>Viewer age range</strong></p>';
            if (value == 2) description.innerHTML = '<p><strong>Days since service</strong></p>';
            if (value == 3)
                description.innerHTML = '<p><strong>Number of IP addresses tracked per 30 days</strong></p>';
            description.style.opacity = 1;
        },
        getHomeAddress() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/FuneralHomes/${this.$route.params.id}`)
                .then(response => {
                    this.loading = false;

                    this.fhAddress = {
                        address1: response.data.funeralHome.address1,
                        address2: response.data.funeralHome.address2,
                        city: response.data.funeralHome.city,
                        state: response.data.funeralHome.state,
                        zipCode: response.data.funeralHome.zipCode,
                    };

                    //Convert address to coordinates for map starting location
                    this.getFHCoordinates();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getFHCoordinates() {
            // https://api.mapbox.com/geocoding/v5/{endpoint}/{search_text}.json
            const search_text = `${this.fhAddress.address1} ${this.fhAddress.address2} ${this.fhAddress.city} ${this.fhAddress.state} ${this.fhAddress.zipCode}`;

            this.axios
                .get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
                )
                .then(response => {
                    if (response.data.features[0].center) {
                        const center = response.data.features[0].center;
                        this.fhCoordinates = {
                            long: center[0],
                            lat: center[1],
                        };

                        this.$refs.codeComp.trackingCode.latitude = this.fhCoordinates.lat;
                        this.$refs.codeComp.trackingCode.longitude = this.fhCoordinates.long;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        initNewCode() {
            this.$refs.codeComp.dialog = true;
            this.$refs.codeComp.resetTrackingCode();
            this.$refs.codeComp.editing = false;

            // update modal map (eager component)
            this.$refs.codeComp.$refs.modalMap.refreshMap(this.$refs.codeComp.trackingCode);
        },
        initEdit(item) {
            let tempArr = JSON.stringify(item);
            let copiedObj = JSON.parse(tempArr);

            this.$refs.codeComp.dialog = true;
            this.$refs.codeComp.editing = true;
            this.$refs.codeComp.trackingCode = copiedObj;

            // update modal map (eager component)
            this.$refs.codeComp.$refs.modalMap.refreshMap(copiedObj);
        },
        cancelDelete() {
            this.deleteModal = false;
        },
        confirmDelete() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/TrackingCode/${this.selectedTrackingCode.id}`)
                .then(response => {
                    this.showSnackbar({
                        message: 'Tracking Code Deleted',
                    });
                    this.deleteModal = false;

                    this.getTrackingCodes();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        initDeleteModal(item) {
            this.selectedTrackingCode = item;
            this.deleteModal = true;
        },
        next() {
            this.window = this.window + 1 === this.trackingCodes ? 0 : this.window + 1;
        },
        prev() {
            this.window = this.window - 1 < 0 ? this.trackingCodes - 1 : this.window - 1;
        },
        async getImpact(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TrackingCode/estimated-impact/${id}`)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getTrackingCodes() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TrackingCode/fh-codes/${this.$route.params.id}`)
                .then(async response => {
                    this.trackingCodes = response.data.trackingCodes.map(
                        code => (code = { ...code, loadingImpact: true }),
                    );

                    // get impact for each tracking code
                    for (let i = 0; i < this.trackingCodes.length; i++) {
                        var result = await this.getImpact(this.trackingCodes[i].id);
                        this.$set(this.trackingCodes, i, {
                            ...this.trackingCodes[i],
                            estimated30DayHits: result,
                            loadingImpact: false,
                        });
                    }
                    this.window = 0;
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    async created() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
            this.getHomeAddress();
            this.getTrackingCodes();
        });
    },
};
</script>
<style lang="scss" scoped>
.cta-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;

    transform: translate(-50%, -50%);
}
.company-options-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 15px;
}
.stat-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 225px;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 4;

    background-color: rgba(255, 255, 255, 0.902);
}
.window {
    border: 2px solid #eee;
    border-radius: 10px;
    padding: 5px;
}
.selectedType {
    background-color: #cfeafe;
    border: 2px solid #cfeafe !important;
}

.img-container:hover {
    transition: 0.3s;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.383);
}
.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100px;
    width: 100px;

    border-radius: 10px;
    padding: 5px;
    transition: 0.3s;
    img {
        max-height: 100%;
        width: auto;
    }
}
.img-container-displayed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;

    transition: 0.3s;
    img {
        max-height: 100%;
        width: auto;
    }
}
.dummyData {
    color: lightgray;
    img {
        opacity: 0.4;
    }
    .dummyMap {
        opacity: 0.4;
    }
}
.ms-orange {
    background-color: #ff530d;
}
.modal-title {
    color: white;
}

.selection {
    border: 2px solid #eee;
    border-radius: 10px;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}
.selection:hover {
    border: 2px solid rgba(238, 238, 238, 0);
    transition: 0.3s;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.383);
}

#description {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    flex-wrap: wrap;
}

.world-img {
    height: 610px;
    width: auto;

    transition: 0.3s;
    img {
        max-height: 100%;
        width: auto;
    }
}
</style>

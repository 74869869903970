<template>
    <div>
        <v-dialog v-model="dialog" width="600">
            <template v-slot:activator="{ on, attrs }">
                <custom-tooltip :tooltipProps="{ bottom: true }">
                    <template v-slot:activator>
                        <span>
                            <font-awesome-icon
                                icon="fa-regular fa-pencil"
                                style="font-size: 1rem"
                                @click="dialog = true"
                            />
                        </span>
                    </template>

                    <template v-slot:content>
                        <span>Edit</span>
                    </template>
                </custom-tooltip>
            </template>
            <v-card style="padding: 2rem 3rem">
                <h2>Edit Admin</h2>
                <v-form class="" ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="firstName"
                                label="First Name"
                                :rules="[v => !!v || 'First name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="lastName"
                                label="Last Name"
                                :rules="[v => !!v || 'Last name is required']"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="d-flex justify-space-between mt-4">
                        <v-btn @click="dialog = false" text class="mr-4">Cancel</v-btn>
                        <div>
                            <v-btn @click="openAuth0" depressed class="mr-4">Auth0</v-btn>
                            <v-btn @click.prevent="updateUser()" color="primary">Update Admin</v-btn>
                        </div>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Create Admin User',
    },
    props: {
        item: {
            required: true,
        },
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            token: '',
            dialog: false,
        };
    },
    components: { CustomTooltip },
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.firstName = this.item.firstName;
                this.lastName = this.item.lastName;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        openAuth0() {
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                return;
            }

            if (this.item.auth0Id) {
                window.open(
                    'https://manage.auth0.com/dashboard/us/midweststreams/users/' + this.item.auth0Id,
                    '_blank',
                );
            } else {
                this.showSnackbar({ message: 'Auth0 Id not found', color: 'error' });
            }
        },
        updateUser() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.errorMessage = '';

                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    id: this.item.id,
                    userType: 2,
                };

                this.axios
                    .create({
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    })
                    .put(process.env.VUE_APP_API + '/users/', data)
                    .then(response => {
                        this.showSnackbar({ message: 'User Updated' });
                        this.$emit('refresh');
                        this.dialog = false;
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error Updating User' });
                    });
            });
        },
    },
};
</script>

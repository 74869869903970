<template>
    <div :key="refreshKey">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: rgba(255, 255, 255, 0); display: block; z-index: 1; position: relative"
            width="2751"
            height="633"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 2751 633"
        >
            <g transform="">
                <linearGradient id="lg-0.7255204618066105" x1="0" x2="0" y1="0" y2="1">
                    <stop :stop-color="computedStartColor" offset="0"></stop>
                    <stop :stop-color="computedEndColor" offset="1"></stop>
                </linearGradient>
                <path d="" fill="url(#lg-0.7255204618066105)" opacity="0.9">
                    <animate
                        attributeName="d"
                        dur="20s"
                        repeatCount="indefinite"
                        keyTimes="0;0.333;0.667;1"
                        calcmod="spline"
                        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                        begin="0s"
                        values="M0 0L 0 602.2911268398326Q 275.1 584.2492158232048  550.2 551.2249364196053T 1100.4 488.88408482816044T 1650.6 361.58337309805313T 2200.8 380.3542638090927T 2751 323.2478673729139L 2751 0 Z;M0 0L 0 582.1212800646679Q 275.1 556.0128104804107  550.2 523.8510749114386T 1100.4 442.60720569995567T 1650.6 310.1998978924495T 2200.8 301.17132986413344T 2751 194.51859044615287L 2751 0 Z;M0 0L 0 549.2101842956766Q 275.1 576.5077437519454  550.2 542.1698968534514T 1100.4 494.42854687975705T 1650.6 375.3250079948072T 2200.8 274.1119851844932T 2751 315.0805922980043L 2751 0 Z;M0 0L 0 602.2911268398326Q 275.1 584.2492158232048  550.2 551.2249364196053T 1100.4 488.88408482816044T 1650.6 361.58337309805313T 2200.8 380.3542638090927T 2751 323.2478673729139L 2751 0 Z"
                    ></animate>
                </path>
                <path d="" fill="url(#lg-0.7255204618066105)" opacity="0.9">
                    <animate
                        attributeName="d"
                        dur="20s"
                        repeatCount="indefinite"
                        keyTimes="0;0.333;0.667;1"
                        calcmod="spline"
                        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                        begin="-4s"
                        values="M0 0L 0 546.1927690000653Q 275.1 544.2217535541926  550.2 525.0021751928825T 1100.4 467.4452187830902T 1650.6 430.4478876156422T 2200.8 267.38289226879306T 2751 202.32858721739217L 2751 0 Z;M0 0L 0 534.7584865472379Q 275.1 552.9045609540408  550.2 532.8998970478474T 1100.4 428.72006942289863T 1650.6 329.95766828718024T 2200.8 335.1809417547572T 2751 204.4665272998036L 2751 0 Z;M0 0L 0 594.068538667368Q 275.1 580.338222512009  550.2 534.4425334701921T 1100.4 476.9508683824165T 1650.6 391.68613447047414T 2200.8 367.57291749356324T 2751 307.79003945362444L 2751 0 Z;M0 0L 0 546.1927690000653Q 275.1 544.2217535541926  550.2 525.0021751928825T 1100.4 467.4452187830902T 1650.6 430.4478876156422T 2200.8 267.38289226879306T 2751 202.32858721739217L 2751 0 Z"
                    ></animate>
                </path>
                <path d="" fill="url(#lg-0.7255204618066105)" opacity="0.9">
                    <animate
                        attributeName="d"
                        dur="20s"
                        repeatCount="indefinite"
                        keyTimes="0;0.333;0.667;1"
                        calcmod="spline"
                        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                        begin="-8s"
                        values="M0 0L 0 612.5205249499289Q 275.1 484.8520979619068  550.2 456.3622122179877T 1100.4 459.62194098740133T 1650.6 366.84914875797256T 2200.8 318.13514835822247T 2751 201.0140283618934L 2751 0 Z;M0 0L 0 573.3909943465004Q 275.1 515.0357655380864  550.2 484.65612862253136T 1100.4 456.15759420738044T 1650.6 399.889129053978T 2200.8 295.41961129735273T 2751 226.46891660624556L 2751 0 Z;M0 0L 0 518.4759640677485Q 275.1 594.4493923493937  550.2 564.0887359653168T 1100.4 453.7330794969213T 1650.6 429.1091310375537T 2200.8 358.947033382057T 2751 306.4544197288288L 2751 0 Z;M0 0L 0 612.5205249499289Q 275.1 484.8520979619068  550.2 456.3622122179877T 1100.4 459.62194098740133T 1650.6 366.84914875797256T 2200.8 318.13514835822247T 2751 201.0140283618934L 2751 0 Z"
                    ></animate>
                </path>
                <path d="" fill="url(#lg-0.7255204618066105)" opacity="0.9">
                    <animate
                        attributeName="d"
                        dur="20s"
                        repeatCount="indefinite"
                        keyTimes="0;0.333;0.667;1"
                        calcmod="spline"
                        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                        begin="-12s"
                        values="M0 0L 0 516.1937168795507Q 275.1 570.5546331416996  550.2 555.1215937666865T 1100.4 467.60042792307485T 1650.6 419.90814598850704T 2200.8 280.0809772612762T 2751 172.84864697870665L 2751 0 Z;M0 0L 0 515.650373646287Q 275.1 551.8597184064133  550.2 533.9088452384789T 1100.4 403.7652615365115T 1650.6 336.4861637125192T 2200.8 341.6053385646556T 2751 242.90071339626786L 2751 0 Z;M0 0L 0 524.8320047406313Q 275.1 510.09285371471117  550.2 488.9808568831951T 1100.4 451.647359262252T 1650.6 443.4473510687438T 2200.8 393.3574704180569T 2751 239.36009747248852L 2751 0 Z;M0 0L 0 516.1937168795507Q 275.1 570.5546331416996  550.2 555.1215937666865T 1100.4 467.60042792307485T 1650.6 419.90814598850704T 2200.8 280.0809772612762T 2751 172.84864697870665L 2751 0 Z"
                    ></animate>
                </path>
                <path d="" fill="url(#lg-0.7255204618066105)" opacity="0.9">
                    <animate
                        attributeName="d"
                        dur="20s"
                        repeatCount="indefinite"
                        keyTimes="0;0.333;0.667;1"
                        calcmod="spline"
                        keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                        begin="-16s"
                        values="M0 0L 0 598.6240963103639Q 275.1 557.6462680791138  550.2 530.6367368013418T 1100.4 461.2846854593855T 1650.6 324.2092772472789T 2200.8 229.9737161246988T 2751 193.6801651808368L 2751 0 Z;M0 0L 0 543.7309276504327Q 275.1 521.4820228730558  550.2 476.44650370568377T 1100.4 429.34900852231505T 1650.6 377.5428151857705T 2200.8 353.4880636703889T 2751 267.84849876300564L 2751 0 Z;M0 0L 0 592.3933881344659Q 275.1 536.8329787853685  550.2 501.79241023463067T 1100.4 391.41423201526254T 1650.6 359.07038145892466T 2200.8 266.09643486133933T 2751 233.54495351811323L 2751 0 Z;M0 0L 0 598.6240963103639Q 275.1 557.6462680791138  550.2 530.6367368013418T 1100.4 461.2846854593855T 1650.6 324.2092772472789T 2200.8 229.9737161246988T 2751 193.6801651808368L 2751 0 Z"
                    ></animate>
                </path>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    data() {
        return {
            refreshKey: 0,
        };
    },
    props: {
        startColor: {
            type: String,
            default: '#1173B9',
        },
        endColor: {
            type: String,
            default: '#0d3d60',
        },
    },
    computed: {
        computedStartColor() {
            return this.startColor === null ? '#1173B9' : this.startColor;
        },
        computedEndColor() {
            return this.endColor === null ? '#0d3d60' : this.endColor;
        },
    },
    watch: {
        startColor() {
            this.refreshKey++;
        },
        endColor() {
            this.refreshKey++;
        },
    },
};
</script>
<style lang=""></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"mt-3"},[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"fa-regular fa-code"}}),_c('h3',{staticClass:"m-0"},[_vm._v("Embed Codes")]),_c('v-spacer'),_c('create-embed-code',{ref:"embedCodeEditor",on:{"refresh":_vm.getEmbedCodes}})],1),_c('v-data-table',{staticClass:"w-95 centered",attrs:{"headers":_vm.headers,"items":_vm.embedCodes,"loading":_vm.loading,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        },"loading-text":"Getting Embed Codes...","search":_vm.search,"options":_vm.options},on:{"click:row":_vm.editEmbedCode,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noUsers ? 'No Embed Codes Found' : 'No Embed Codes Found')+" ")]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.height",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.height)+" ")]}},{key:"item.width",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"action-list",staticStyle:{"justify-content":"end"}},[_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',[_c('font-awesome-icon',{staticStyle:{"font-size":"1rem"},attrs:{"icon":"fa-regular fa-pencil"},on:{"click":function($event){return _vm.editEmbedCode(item)}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Edit")])]},proxy:true}],null,true)}),_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',{on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.openDeleteModal(item)}]}},[_c('font-awesome-icon',{staticStyle:{"font-size":"1rem"},attrs:{"icon":"fa-regular fa-trash-can"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Delete")])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"290"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete Embedcode ? ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete "+_vm._s(_vm.selectedEmbedCode.name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gdarken-1","text":""},on:{"click":_vm.closeDeleteModal}},[_vm._v(" cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteEmebed()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="openDialog" max-width="60vw">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        <font-awesome-icon icon="fa-regular fa-user-group" class="mr-2" />
                        Edit Profile</span
                    >
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-alert v-if="showMsg" type="success" :timeout="2000">
                            {{ message }}
                        </v-alert>
                        <v-alert v-if="showError" type="error" :timeout="2000">
                            {{ message }}
                        </v-alert> -->
                        <Spinner v-if="!updatingUser"></Spinner>
                        <div v-if="updatingUser">
                            <v-form class="form-container" ref="form" lazy-validation>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="userForm.firstName"
                                            label="First Name"
                                            :rules="[v => !!v || 'First name is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="userForm.lastName"
                                            label="Last Name"
                                            :rules="[v => !!v || 'Last name is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            :disabled="updatingUser"
                                            v-model="userForm.email"
                                            type="email"
                                            label="Email"
                                            :rules="[v => !!v || 'Email is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Phone Number"
                                            v-model="userForm.phoneNo"
                                            v-mask="`###.###.####`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-alert v-if="errorMessage.length" type="error">
                                    {{ errorMessage }}
                                </v-alert>

                                <div class="mt-4">
                                    <v-btn :disabled="disableButton" @click.prevent="updateUser()" color="primary"
                                        >Update User</v-btn
                                    >
                                    <v-btn :disabled="disableButton" @click="cancelUserForm()" text class="ml-4"
                                        >cancel</v-btn
                                    >
                                </div>
                            </v-form>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="openDialog = false"> Close </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </v-row>
</template>

<script>
import Spinner from '@/components/ui/Spinner.vue';
export default {
    data: () => ({
        token: '',
        snackbar: false,
        message: '',
        userEditInfo: '',
        showMsg: false,
        showError: false,
        openDialog: false,
        loading: false,
        errorLoading: false,
        editingUser: false,
        updatingUser: false,
        disableButton: false,
        errorMessage: '',
        sucessMessage: '',
        userId: -1,
        userForm: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
        },
        id: -1,
        headers: [
            { text: 'Name', value: 'name', width: '30%' },
            { text: 'Email', value: 'email' },
            { text: 'Actions', value: 'actions', width: '20%', align: 'center' },
        ],
    }),
    methods: {
        getUsers() {
            this.updatingUser = false;

            let id = this.$auth.user['https://memoryshare.com/user_id'];
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/users/${id}`)
                    .then(response => {
                        this.updatingUser = true;

                        this.fetchEditUser(response.data);
                    })
                    .catch(error => {});
            });
        },
        fetchEditUser(data) {
            if (data) {
                this.disableButton = false;
                this.userId = data.id;
                this.userForm.firstName = data.firstName;
                this.userForm.lastName = data.lastName;
                this.userForm.email = data.email;
                this.userForm.phoneNo = data.phoneNo;
            } else {
                this.disableButton = true;
                this.message = 'Logged user data not found.';
                this.showError = true;
            }
        },
        updateUser() {
            this.errorMessage = '';
            this.updatingUser = false;
            let data = {
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                phoneNo: this.userForm.phoneNo,
                id: this.userId,
            };
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + '/users/', data)
                .then(response => {
                    this.message = 'User profile updated sucessfully';
                    this.showMsg = true;
                    this.updatingUser = false;
                    this.cancelUserForm();

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/users/get-session`)
                        .then(response => {
                            const fullName = response.data.firstName + ' ' + response.data.lastName;
                            this.$store.dispatch('updateLoggedInUser', fullName);
                        })
                        .catch(response => {
                            this.session = null;
                        })
                        .finally(() => {
                            this.loading = false;
                        });

                    this.getUsers();
                })
                .catch(error => {
                    this.errorMessage = error.response.data;
                    this.message = 'Error updating user';
                })
                .then(() => {
                    this.snackbar = true;
                });
        },
        cancelUserForm() {
            this.userForm.firstName = '';
            this.userForm.lastName = '';
            this.userForm.email = '';
            this.userForm.phoneNo = '';
            this.userId = -1;
            this.openDialog = false;
        },
    },
    components: {
        Spinner,
    },
};
</script>

<style lang="scss" scoped>
.icon {
    margin: 0 0.5rem;
}
</style>

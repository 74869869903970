<template>
    <div class="top-viewed-services-table">
        <v-data-table
            no-data-text="No services in this time frame"
            class="custom-row-hover"
            hide-default-footer
            :items="services"
            :headers="headers"
        >
            <template #item.name="{ item }">
                <span>{{ item.firstName }} {{ item.lastName }}</span>
            </template>

            <template #item.totalViews="{ item }">
                <v-chip dark color="#176bf1">{{ item.totalViews }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            token: '',
            services: [],
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'Views',
                    value: 'totalViews',
                    width: '20%',
                },
            ],
        };
    },
    props: {
        funeralHomeId: {
            type: Number,
            required: true,
        },
        interval: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        openServiceTab(service) {
            window.open(`/analytics/${service.slug}`, '_blank');
        },
        getTopViewedServices(funeralHomeId) {
            if (!funeralHomeId) {
                this.showSnackbar({ message: 'Funeral home id cannot be null', color: 'error' });
                return;
            }

            const params = { startDate: this.startDate, endDate: this.endDate, pageSize: this.pageSize };

            this.axiosInstance
                .get(`/dogs/top-viewed-services/${funeralHomeId}`, { params })
                .then(resp => {
                    this.services = resp.data.services;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error retreiving top viewed services', color: 'error' });
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getTopViewedServices(this.funeralHomeId, this.interval);
    },
    watch: {
        interval(newVal, oldVal) {
            this.getTopViewedServices(this.funeralHomeId, newVal);
        },
        startDate() {
            this.getTopViewedServices(this.funeralHomeId, this.interval);
        },
        endDate() {
            this.getTopViewedServices(this.funeralHomeId, this.interval);
        },
    },
};
</script>
<style>
/* .top-viewed-services-table {
    .custom-row-hover .v-data-table__wrapper tbody tr:hover {
        background-color: #176af132 !important;
    }
} */
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"fa-regular fa-truck-fast"}}),_c('h3',{staticClass:"m-0"},[_vm._v("Shipments")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.shipments,"headers":_vm.headers,"server-items-length":_vm.totalShipments,"loading":_vm.loading,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToDetails},scopedSlots:_vm._u([{key:"header.status",fn:function(ref){
        var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ top: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{staticStyle:{"width":"200px"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"margin-bottom":"0.6rem"},attrs:{"width":"90%","small":""},on:{"click":_vm.toggleAllShipmentStatuses}},[_vm._v(_vm._s(_vm.allStatusesSelected ? 'Deselect' : 'Select')+" All")])],1),_vm._l((_vm.shipmentStatuses),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"height":"20px"}},[_c('v-checkbox',{attrs:{"label":item.label,"value":item.value},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})],1)})],2)],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"selectOrderStatus",staticStyle:{"height":"2.6rem"},attrs:{"dense":"","chips":"","small":"","return-object":"","item-text":"label","item-value":"value","items":_vm.shipmentStatuses},on:{"input":function($event){return _vm.updateShipmentStatus(item.id, item.status.value)},"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"dark":"","color":item.color}},[_vm._v(_vm._s(item.label))])],1)]}},{key:"item",fn:function(ref){
        var active = ref.active;
        var item = ref.item;
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"white","color":item.color}},[_vm._v(_vm._s(item.label))])],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.createdDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.utc(item.createdDate).local().format('l'))+" ")]}},{key:"item.updatedDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.utc(item.updatedDate).local().format('l'))+" ")]}},{key:"item.carrier",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.carriers[item.carrier].label)+" ")]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.cost))+" ")]}},{key:"item.orderId",fn:function(ref){
        var item = ref.item;
return [_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{attrs:{"href":("/order-details/" + (item.orderId))}},[_vm._v(_vm._s(item.orderId))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","gap":"10px","align-items":"center","justify-content":"center"}},[(item.trackingUrl)?_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.openTab(item.trackingUrl)}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"1.1rem"},attrs:{"icon":"fa-regular fa-map-location-dot"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Tracker")])]},proxy:true}],null,true)}):_vm._e(),(item.labelUrl)?_c('custom-tooltip',{attrs:{"tooltipProps":{ bottom: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',[_c('font-awesome-icon',{staticStyle:{"font-size":"1.1rem"},attrs:{"icon":"fa-regular fa-file-arrow-down"},on:{"click":function($event){$event.stopPropagation();return _vm.openTab(item.labelUrl)}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Label")])]},proxy:true}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <btn-x-scroll-container
            :containerId="'photo-scroll-container'"
            :itemBaseId="'photo-'"
            :itemQuerySelector="'.image-summary-wrapper'"
            :maxItemCount="this.tributeVideo.totalPhotos - 1"
            @scroll-end-reached="handleScrollEndReached"
        >
            <template v-slot:content>
                <div
                    v-if="
                        !tributeVideo.selectedTemplates[0] ||
                        (tributeVideo.selectedTemplates[0] && tributeVideo.selectedTemplates[0].title != 'No Theme')
                    "
                    :id="`photo-0`"
                    class="not-draggable"
                >
                    <div class="image-wrapper">
                        <v-img
                            v-if="tributeVideo.selectedTemplates[0]"
                            :src="handleThumbnail(tributeVideo.selectedTemplates[0].url)"
                            :lazy-src="handleThumbnail(tributeVideo.selectedTemplates[0].url)"
                            class="image-base"
                            eager
                        ></v-img>
                        <v-alert
                            v-else
                            @click="$emit('changeTab', 'intro')"
                            outlined
                            text
                            color="warning"
                            class="d-flex align-items-center justify-content-center text-center"
                            style="min-height: 100%; width: 100%; cursor: pointer"
                        >
                            <div>No theme selected!</div>

                            <div>
                                <font-awesome-icon
                                    class="mt-2"
                                    style="font-size: 1.2rem"
                                    icon="fa-regular fa-circle-plus"
                                ></font-awesome-icon>
                            </div>
                        </v-alert>
                    </div>
                </div>
                <draggable
                    @change="handleChange"
                    :filter="'.not-draggable'"
                    animation="300"
                    v-model="photos"
                    class="d-flex"
                    style="gap: 20px"
                >
                    <div
                        :id="`photo-${tributeVideo.selectedTemplates[0] ? index + 1 : index}`"
                        v-for="(item, index) in photos"
                        class="image-summary-wrapper"
                    >
                        <PhotoSummaryItem class="draggable" :item="item" :key="index" s />
                    </div>
                    <div style="width: 10px; border: 2px solid rgba(64, 64, 64, 0)" id="photo-summary-scroll"></div>
                </draggable>
            </template>
        </btn-x-scroll-container>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import BtnXScrollContainer from '@/components/ui/BtnXScrollContainer.vue';
import PhotoSummaryItem from '@/components/ManageService/Tribute/PhotoSummaryItem.vue';
export default {
    data() {
        return {
            pageNumber: 0,
            pageSize: 24,
            token: '',
            photos: [],
        };
    },
    components: {
        draggable,
        BtnXScrollContainer,
        PhotoSummaryItem,
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var totalSeconds = val % 60;
            var seconds = Math.floor(totalSeconds);
            var milliseconds = Math.round((totalSeconds - seconds) * 100); // Adjust this if you want more precision

            // If milliseconds are 50 or more, round up the seconds
            if (milliseconds >= 50) {
                seconds += 1;
                if (seconds >= 60) {
                    seconds = 0;
                    minutes += 1;
                }
            }

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }

            const finalTime = minutes + ':' + str_pad_left(seconds, '0', 2);
            // + '.' + str_pad_left(milliseconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideoPhotos', 'updateTributeVideo']),
        handleScrollEndReached(val) {
            if (val) {
                if (!this.loading && this.tributeVideo.totalPhotos > this.photos.length) {
                    this.nextPagePhotos();
                }
            }
        },
        async handleChange(e) {
            let targetEl = null;
            var movedEl = e.moved.element;

            const targetOrder = e.moved.newIndex;

            await this.updateOrder(movedEl.id, targetOrder);
        },
        updateOrder(id, index) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/TributeVideoPhoto/update-order/${id}/${index}`)
                .then(resp => {
                    this.$emit('refresh-preview');
                    this.$emit('refresh-sync');
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        handleThumbnail(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //https://ik.imagekit.io/demo/sample-video.mp4/ik-thumbnail.jpg
            //https://ik.imagekit.io/memoryshare/templates/fa6da4d5-b142-40ac-a67f-efdc1acd0523.mp4/ik-thumbnail.jpg?tr=so-5
            if (url) {
                const srcPath = url.split('/tribute-template-renders/')[1];
                const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
                const poster = `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${thumbNailMod}`;
                return poster;
            }
        },
        async nextPagePhotos() {
            this.pageNumber += 1;

            let resp = await this.getPhotos(this.tributeVideo.eventId, {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                reversed: true,
            });

            if (resp.photos) {
                this.photos = this.photos.concat(resp.photos);
            }
        },
        getPhotos(id, params) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/event-photos/${id}`, { params: params })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    [console.log(error, 'error')];
                });
        },
        handleImgSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async refreshSummaryPhotos() {
            if (this.tributeVideo.eventId) {
                this.pageNumber = 0;
                let resp = await this.getPhotos(this.tributeVideo.eventId, {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    reversed: true,
                });
                this.photos = resp.photos;
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.refreshSummaryPhotos();
    },
};
</script>
<style lang="scss" scoped>
.video-tag {
    position: absolute;
    top: 10px;
    left: 10px;
}
.image-wrapper {
    width: 200px;
    height: 150px;
    padding: 2px;
    // outline: 2px solid red;
}

.draggable {
    cursor: grab;
}
.image-base {
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.video-wrapper {
    // min-width: 265px;
    min-width: 200px;
    min-height: 150px;
    display: flex;
    align-items: center;
}
.video-base {
    max-height: 150px;
    min-height: 150px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div style="color: white">
            <h3>eCommerce Admin</h3>
        </div>
        <div class="elevation-3 p-4 bg-white rounded">
            <v-tabs v-model="tab" color="#0d5259" style="overflow: visible">
                <v-tab href="#purchases">Purchases</v-tab>
                <v-tab href="#orders">Orders</v-tab>
                <v-tab href="#shipments">Shipments</v-tab>
                <v-tab href="#products">Products</v-tab>
                <v-tab href="#suppliers">Suppliers</v-tab>
                <!-- <v-tab href="#line-items">Line Items</v-tab> -->
            </v-tabs>

            <v-tabs-items :value="tab">
                <v-tab-item value="purchases">
                    <PurchaseTable />
                </v-tab-item>

                <v-tab-item value="orders">
                    <OrderTable />
                </v-tab-item>

                <v-tab-item value="shipments">
                    <AdminShipmentTable />
                </v-tab-item>

                <v-tab-item value="products">
                    <ProductsTable />
                </v-tab-item>

                <v-tab-item value="suppliers">
                    <SupplierTable />
                </v-tab-item>
                <!-- <v-tab-item value="line-items">
                    <LineItemTable />
                </v-tab-item> -->
            </v-tabs-items>
        </div>
    </AppLayout>
</template>

<script>
import SupplierTable from '@/components/Tables/Admin/SupplierTable.vue';
import OrderTable from '@/components/Tables/OrderTable.vue';
import LineItemTable from '@/components/Tables/Admin/LineItemTable.vue';
import PurchaseTable from '@/components/Tables/Admin/PurchaseTable.vue';
import AdminShipmentTable from '@/components/Tables/Admin/AdminShipmentTable.vue';
import ProductsTable from '@/components/Tables/ProductsTable.vue';

export default {
    metaInfo: {
        title: 'eCommerce',
    },
    data() {
        return {};
    },
    components: { SupplierTable, OrderTable, LineItemTable, PurchaseTable, AdminShipmentTable, ProductsTable },
    computed: {
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
};
</script>

<style scoped></style>

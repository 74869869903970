<template>
    <div>
        <side-nav></side-nav>
        <div v-bind:class="[{ 'menu-showing': this.$store.state.menuShowing }, 'dash-container']">
            <div class="tile form elevation-6">
                <div class="header">
                    <h3 class="title">
                        <font-awesome-icon icon="fa-regular fa-code-simple" style="font-size: 1rem" />
                        {{ editing ? 'Edit Embed Code' : 'Create New Embed Code' }}
                    </h3>
                </div>

                <div class="container">
                    <p>
                        The embed codes are exposed to our customers via a dropdown. Please ensure these names are
                        professional, and spelled correctly. Also refrain from naming embed codes after specific
                        customers.
                    </p>
                </div>

                <v-form class="form-container" ref="form" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="name"
                                label="Name"
                                :rules="[v => !!v.length || 'Name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="embedCodeType"
                                :items="embedCodeData"
                                item-text="description"
                                item-value="id"
                                label="Embed Code Type"
                                :required="!embedCodeType"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea v-model="htmlWrapper" label="HTML Wrapper"></v-textarea>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col class="checkbox-area flex-row">
                            <v-checkbox v-model="responsive" label="Responsive"></v-checkbox>
                        </v-col>
                    </v-row> -->
                    <v-alert v-if="errorMessage.length" type="error">
                        {{ errorMessage }}
                    </v-alert>

                    <div class="text-right mt-4">
                        <v-btn @click="$router.go(-1)" text class="mr-4">cancel</v-btn>
                        <v-btn @click.prevent="createEmbedCode()" color="primary">
                            {{ editing ? 'Update' : 'Create' }}</v-btn
                        >
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Manage Admin Users',
    },
    data() {
        return {
            embedCodeData: [],
            name: '',
            embedCodeType: '',
            htmlWrapper: '',
            responsive: true,
            homes: [],
            funeralHome: '',
            token: '',
            errorMessage: '',
            editing: false,
            rules: {
                select: [v => !!v || 'Embed code type is required'],
            },
        };
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getEmbedCodeType();
        if (this.$route.params.id) {
            this.editing = true;
            this.getEmbedCode();
        }
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        getEmbedCodeType() {
            this.axiosInstance
                .get(`/dropdown/embedcodetype`)
                .then(response => {
                    console.log('response /dropdown/embedcodetype', response);
                    if (response.data) {
                        this.embedCodeData = response.data;
                    }
                })
                .catch(error => {});
        },
        getEmbedCode() {
            this.axiosInstance
                .get(`/EmbedCode/${this.$route.params.id}`)
                .then(response => {
                    this.name = response.data.name;
                    this.embedCodeType = response.data.embedCodeType;
                    this.responsive = response.data.responsive;
                    this.htmlWrapper = response.data.htmlWrapper;
                })
                .catch(error => {});
        },
        createEmbedCode() {
            this.errorMessage = '';

            if (this.editing) {
                let data = {
                    name: this.name,
                    width: 0,
                    height: 0,
                    embedCodeType: this.embedCodeType,
                    responsive: this.responsive,
                    id: +this.$route.params.id,
                    htmlWrapper: this.htmlWrapper,
                };

                this.axiosInstance
                    .put(`/EmbedCode/${this.$route.params.id}`, data)
                    .then(response => {
                        this.message = 'Embed code Updated';
                        this.showSnackbar({ message: 'Embed code Updated' });
                        this.$router.go(-1);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        this.showSnackbar({ message: 'Error updating user' });
                    });
            } else {
                // new embed code
                let data = {
                    name: this.name,
                    width: 0,
                    height: 0,
                    embedCodeType: this.embedCodeType,
                    responsive: this.responsive,
                    htmlWrapper: this.htmlWrapper,
                };

                this.axiosInstance
                    .post('/EmbedCode', data)
                    .then(response => {
                        this.showSnackbar({ message: 'Embed Code created' });
                        this.$router.go(-1);
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        this.showSnackbar({ message: 'Error creating user' });
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 3rem;
}

.row {
    margin-top: 1.5rem;
}

.custom-checkbox {
    margin-top: 1rem;
}

.save-btn {
    width: 40%;
}

.form-group {
    margin-top: 2rem;
}
</style>

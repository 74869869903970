<template>
    <AppLayout :has-background="true" :contained="true">
        <v-card class="p-4">
            <v-card-title class="d-flex">
                <font-awesome-icon icon="fa-regular fa-video" style="font-size: 1.5rem" class="mr-3" />
                <h4 class="my-0">Daily Stream Sheet</h4>
            </v-card-title>

            <v-row class="px-3">
                <v-col cols="12" md="3">
                    <!-- Datetime is UTC NOT local, backend reutrns utc time without 'z' |
                    output local fixes date format issue in this instance -->
                    <calendar :output-local="true" label="From" v-model="date" class="mr-8"></calendar>
                </v-col>
                <v-col cols="12" md="3">
                    <calendar :output-local="true" label="To" v-model="streamsEndDate"></calendar>
                </v-col>
                <v-col cols="0" md="2"></v-col>
                <!-- <v-spacer></v-spacer> -->
                <v-col cols="12" md="4">
                    <v-text-field label="Search" append-icon="mdi-magnify" v-model="mysearch"></v-text-field>
                </v-col>
            </v-row>

            <streams-table
                ref="streamTable"
                :date="date"
                :offset="offset"
                :streamsEndDate="streamsEndDate"
                :customized="customized"
                :mysearch="mysearch"
                :customizedTitle="customizedTitle"
                @advance-day="advanceDay"
                @reverse-day="reverseDay"
            >
            </streams-table>
        </v-card>
    </AppLayout>
</template>

<script>
import StreamsTable from '@/components/Streams/StreamsTable.vue';
import moment from 'moment';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    name: 'DailyStreamSheet',
    metaInfo: {
        title: 'Streams',
    },
    components: {
        StreamsTable,
        Calendar,
    },
    data() {
        return {
            today: true,
            customizedTitle: 'Today',
            // date: moment(Date.now()).toISOString(),
            date: '',
            customized: false,
            yesterday: moment(Date.now()).subtract(1, 'd'),
            tomorrow: moment(Date.now()).add(1, 'd'),
            search: '',
            offset: '',
            mysearch: '',
            // streamsEndDate: moment(Date.now()).toISOString(),
            streamsEndDate: '',
            tempDate: '',
        };
    },
    watch: {
        date() {
            this.getFriendlyDateTitle();
            this.resetScroll();
        },
    },

    methods: {
        resetScroll() {
            var main = document.querySelector('#scroll');
            main.scrollTop = 0;
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        resetEndDate() {
            this.streamsEndDate = this.date;
        },
        getToday() {
            return moment(this.date).utcOffset(this.offset).format('MM/DD/YYYY');
        },
        advanceDay() {
            let tempDate = moment(this.date).add(1, 'd').toISOString();
            this.date = this.checkForLocal(tempDate);

            let tempEndDate = moment(this.date).toISOString();
            this.streamsEndDate = this.checkForLocal(tempEndDate);
            this.getFriendlyDateTitle();
        },
        reverseDay() {
            let tempDate = moment(this.date).subtract(1, 'd').toISOString();
            this.date = this.checkForLocal(tempDate);

            let tempEndDate = moment(this.date).toISOString();
            this.streamsEndDate = this.checkForLocal(tempEndDate);

            this.getFriendlyDateTitle();
        },
        getFriendlyDateTitle() {
            if (moment(Date.now()).isSame(this.date, 'date')) {
                this.customizedTitle = 'Today';
                return;
            }
            if (this.yesterday.isSame(this.date, 'date')) {
                this.customizedTitle = 'Yesterday';
                return;
            }
            if (this.tomorrow.isSame(this.date, 'date')) {
                this.customizedTitle = 'Tomorrow';
                return;
            }
            this.customizedTitle = moment(this.date).format('MM/DD/YYYY');
        },
    },
    created() {
        this.offset = Math.floor(moment(Date.now()).utcOffset() / 60);
    },
    mounted() {
        // account for offset in date set : after 7pm (5 hr offset) date set starts as following day
        var tempDate = new Date();
        tempDate.setHours(tempDate.getHours() + this.offset);
        this.date = this.checkForLocal(moment(tempDate).toISOString());

        this.streamsEndDate = this.checkForLocal(moment(tempDate).toISOString());
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat fixed 0 -18rem;
}

.new-service-btn:hover,
.new-service-btn:focus {
    text-decoration: none;
}

/*
.v-data-table-header th span {
    font-size: 18px;
}

.v-data-table__wrapper tbody tr {
    height: 60px !important;
}

.v-data-table__wrapper tbody td {
    font-size: 18px !important;
}

.v-data-footer {
    font-size: 16px !important;
    height: 80px;
} */

.v-data-table-header th {
    white-space: nowrap;
}
</style>

<template>
    <div class="analytics-container">
        <section class="service-analytics-section">
            <h2 class="header">Billing</h2>

            <div class="analytics-grid">
                <div class="analytic-container">
                    <div v-if="minuteOverageUnitCounter" class="text-center">
                        <h6>All subscription minutes have been consumed</h6>
                        <p>Additional Units Used : {{ minuteOverageUnitCounter }}</p>
                    </div>

                    <vc-donut :sections="minutesSection" has-legend :auto-adjust-text-size="true"
                        >Streaming Minutes</vc-donut
                    >
                </div>
                <div class="analytic-container">
                    <div v-if="viewerOverageUnitCounter" class="text-center">
                        <h6>All subscription views have been consumed</h6>
                        <p>Additional Units Used : {{ viewerOverageUnitCounter }}</p>
                    </div>

                    <vc-donut :sections="viewersSection" has-legend :auto-adjust-text-size="true">Viewers</vc-donut>
                </div>
            </div>
            <div style="display: flex; justify-content: center">
                <v-btn width="300px" color="#eee" @click="goToBillingEvents">
                    <span class="mr-2">Go To Billing</span>
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </v-btn>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
        },
        token: {
            type: String,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            minutesSection: [{ label: 'Minutes Used', value: 0, color: '#0c3c60' }],
            viewersSection: [{ label: 'Total Viewers', value: 0, color: '#0c3c60' }],
            minuteOverageUnitCounter: 0,
            viewerOverageUnitCounter: 0,
            minutesRemaining: '',
        };
    },
    methods: {
        goToBillingEvents() {
            this.$router.push({ path: `/services/${this.$route.params.slug}/billing` });
        },
    },
    mounted() {
        this.axios
            .create({ headers: { Authorization: `Bearer ${this.token}` } })
            .get(process.env.VUE_APP_API + `/services/billing?serviceId=${this.service.id}`)
            .then(response => {
                let usedMinutes = response.data.usedMinutes;
                let allowedMinutes = response.data.allowedMinutes;
                let usedViewers = response.data.usedViewers;
                let allowedViewers = response.data.allowedViewers;
                let minutes = (usedMinutes / allowedMinutes) * 100;
                let views = (usedViewers / allowedViewers) * 100;

                this.minutesSection[0].label = `${usedMinutes}/${allowedMinutes} Minutes Used`;
                this.viewersSection[0].label = `${usedViewers}/${allowedViewers} Views`;

                if (usedMinutes === 0) {
                    this.minutesSection[0].value = 0;
                } else if (minutes <= 100) {
                    this.minutesSection[0].value = minutes;
                } else {
                    let overMinutes = usedMinutes - allowedMinutes;
                    this.minuteOverageUnitCounter = Math.floor(overMinutes / 15) + 1;
                    this.minutesSection[0].value = ((overMinutes % 15) / 15) * 100;
                }

                if (usedViewers === 0) {
                    this.viewersSection[0].value = 0;
                } else if (views <= 100) {
                    this.viewersSection[0].value = views;
                } else {
                    let overViewers = usedViewers - allowedViewers;
                    this.viewerOverageUnitCounter = Math.floor(overViewers / 100) + 1;
                    this.viewersSection[0].value = overViewers % 100;
                }
            })
            .catch(error => {});
    },
};
</script>

<style lang="scss" scoped>
.analytics-container {
    container-type: inline-size;
}
.service-analytics-section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    width: 100%;

    .header {
        margin: 0;
        font-size: 1.2rem;
    }

    .analytics-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin: 1rem 0;

        .analytic-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: white;
            box-shadow: 0 0 1rem #dadada;
            padding: 2rem;
            height: 430px;
        }
    }
}

//Container query to match mobile layout if container @ mobile width
@container (max-width: 768px) {
    .analytics-grid {
        grid-template-columns: 1fr !important;
    }
}

@media only screen and (max-width: 768px) {
    .analytics-grid {
        grid-template-columns: 1fr !important;
    }
}
</style>

<template>
    <div>
        <v-card-title class="mt-3">
            <font-awesome-icon icon="fa-regular fa-code" style="margin-right: 0.5rem" />
            <h3 class="m-0">Embed Codes</h3>
            <v-spacer></v-spacer>
            <create-embed-code @refresh="getEmbedCodes" ref="embedCodeEditor" />
        </v-card-title>

        <v-data-table
            class="w-95 centered"
            :headers="headers"
            :items="embedCodes"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting Embed Codes..."
            :search="search"
            @click:row="editEmbedCode"
            :options.sync="options"
        >
            <template v-slot:no-data>
                {{ noUsers ? 'No Embed Codes Found' : 'No Embed Codes Found' }}
            </template>

            <template v-slot:item.name="{ item }">
                {{ item.name }}
            </template>

            <template v-slot:item.height="{ item }">
                {{ item.height }}
            </template>
            <template v-slot:item.width="{ item }">
                {{ item.width }}
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list" style="justify-content: end">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-pencil"
                                    style="font-size: 1rem"
                                    @click="editEmbedCode(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click.stop @click="openDeleteModal(item)">
                                <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>

                    <v-dialog v-model="deleteModal" :retain-focus="false" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline"> Delete Embedcode ? </v-card-title>
                            <v-card-text>Are you sure you want to delete {{ selectedEmbedCode.name }}?</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="gdarken-1" text @click="closeDeleteModal"> cancel </v-btn>
                                <v-btn color="red darken-1" text @click="deleteEmebed()"> Delete </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import CreateEmbedCode from '@/components/Actions/Admin/CreateEmbedCode.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: ' Embed Codes',
    },
    data() {
        return {
            homes: [],
            snackbar: false,
            search: '',
            message: '',
            deleteModal: false,
            loading: false,
            errorLoading: false,
            selectedIndex: 0,
            funeralHome: {},
            noUsers: false,
            selectedEmbedCode: {},
            token: '',
            options: {
                itemsPerPage: 25,
            },
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    width: '80%',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: '20%',
                    align: 'end',
                },
            ],
            embedCodes: [],
        };
    },
    components: {
        CreateEmbedCode,
        CustomTooltip,
    },
    mounted() {
        this.getEmbedCodes();
    },

    methods: {
        closeDeleteModal() {
            if (this.deleteModal) {
                this.deleteModal = false;
            }
        },
        getEmbedCodes() {
            this.noUsers = false;
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    this.axios
                        .create({
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        })
                        .get(process.env.VUE_APP_API + `/EmbedCode`)
                        .then(response => {
                            if (response.data) {
                                this.embedCodes = response.data;
                                if (!this.embedCodes.length) this.noUsers = true;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            }
        },
        openDeleteModal(item) {
            this.selectedEmbedCode = item;
            this.selectedIndex = this.embedCodes.indexOf(item);
            this.deleteModal = true;
        },
        deleteEmebed() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .delete(process.env.VUE_APP_API + `/EmbedCode/${this.selectedEmbedCode.id}`)
                .then(response => {
                    this.message = `${this.selectedEmbedCode.name} deleted!`;
                    this.getEmbedCodes();
                })
                .catch(error => {
                    this.message = `Error deleting embedcode ${this.selectedEmbedCode.name}`;
                })
                .then(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
        editEmbedCode(item) {
            this.$refs.embedCodeEditor.editing = true;
            this.$refs.embedCodeEditor.dialog = true;
            this.$refs.embedCodeEditor.getEmbedCode(item.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 10%;
}
.centered {
    margin: 0 auto;
}
.w-95 {
    width: 95%;
}

// .table {
//     thead{
//         background: #f6f9fc;
//         color: #8898aa;
//         text-transform: uppercase;
//         font-size: .75rem;
//         border-top: none;
//         letter-spacing: 1px;
//     }
// }
</style>

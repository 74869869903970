<template>
    <div>
        <v-dialog v-model="dialog" content-class="decorated-modal" max-width="450px">
            <v-card>
                <div>
                    <div class="pl-3 pr-3 decorated-title">
                        <v-card-title style="display: flex; justify-content: space-between">
                            <div class="text-container">
                                <font-awesome-icon
                                    icon="fa-regular fa-utility-pole-double"
                                    style="font-size: 1.1rem"
                                    class="mr-1"
                                />

                                Landline Detected
                            </div>
                            <div class="img-container">
                                <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                            </div>
                        </v-card-title>
                    </div>
                    <div class="p-3">
                        <!-- <v-card-title>
                            <font-awesome-icon
                                icon="fa-regular fa-utility-pole-double"
                                style="font-size: 1.1rem"
                                class="mr-3"
                            />
                            Landline Detected
                        </v-card-title> -->

                        <v-card-text v-if="!updatingNumber" class="decorated-text"
                            >The phone number for this user is a landline. MemoryShare will not be able to text this
                            number if there is a problem with a stream. We strongly recommend entering a cell phone
                            number.</v-card-text
                        >

                        <v-text-field
                            v-mask="'###.###.####'"
                            v-if="updatingNumber"
                            v-model="newNumber"
                            label="Phone Number"
                            class="p-3"
                            @keydown.enter="submitNumber(newNumber)"
                        ></v-text-field>
                        <div class="text-center">
                            <v-divider v-if="!updatingNumber"></v-divider>
                            <div>
                                <v-btn
                                    v-if="!updatingNumber"
                                    width="90%"
                                    dark
                                    color="#005069"
                                    @click="updatingNumber = true"
                                    >Change Number</v-btn
                                >
                                <v-btn
                                    v-if="updatingNumber"
                                    :disabled="newNumber.length != 12"
                                    width="90%"
                                    :dark="newNumber.length == 12"
                                    color="#005069"
                                    @click="submitNumber(newNumber)"
                                    >Submit</v-btn
                                >
                                <v-btn class="mt-3" width="90%" @click="dialog = false"
                                    >I understand MemoryShare may not contact me if there is a problem</v-btn
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialog: false,
            updatingNumber: false,
            newNumber: '',
        };
    },
    watch: {
        dialog() {
            this.updatingNumber = false;
            this.newNumber = '';
        },
    },
    methods: {
        submitNumber(num) {
            this.$emit('updateNumber', num);
            this.dialog = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.decorated-modal {
    // background-image: url('../../assets/images/logo.png') !important;
    // background-position: 90% 10%;
    // background-size: 40%;

    .decorated-title {
        color: white !important;
        background-color: #ff530d !important;

        // background-image: url('../../assets/images/SW125_MemoryShare_Final-15.png');
        // background-position: 95% 50%;
        // background-size: 10%;
        .text-container {
            width: 80%;
            word-break: break-word;
        }
        .img-container {
            width: 20%;
            max-width: 50px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
</style>

<template>
    <div>
        <side-nav></side-nav>
        <div v-bind:class="[{ 'menu-showing': this.$store.state.menuShowing }, 'dash-container']">
            <div class="text-right">
                <router-link :to="{ name: 'ManageAdminUsers' }">
                    <v-btn color="#0c3c60" class="text-white">
                        <user-plus-icon class="mr-2" size="1.25x" />
                        <span>Add New Admin</span>
                    </v-btn>
                </router-link>
            </div>

            <reset-password
                v-if="passwordResetModal && token"
                :token="token"
                :user="selectedUser"
                @close-password-reset-modal="passwordResetModal = false"
            ></reset-password>

            <v-card>
                <v-card-title>
                    <font-awesome-icon icon="fa-regular fa-user-group" style="font-size: 1rem" />
                    <h4 v-if="$auth.role.includes('Admin')">Admins Users</h4>
                    <h4 v-if="$auth.role.includes('Owner')">Admin Users</h4>
                    <v-spacer></v-spacer>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="users"
                    :loading="loading"
                    :footer-props="{
                        itemsPerPageOptions: [5, 25, 50],
                    }"
                    :options.sync="options"
                    loading-text="Getting  Admin Users..."
                    :search="search"
                >
                    <template v-slot:no-data>
                        {{ noUsers ? 'No  Admin Users Found' : 'No  Admin Users Found' }}
                    </template>

                    <template v-slot:item.name="{ item }">
                        {{ item.firstName + ' ' + item.lastName }}
                    </template>

                    <template v-slot:item.email="{ item }">
                        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </template>

                    <template v-slot:item.primaryContactPhone="{ item }">
                        <a :href="`tel:${item.primaryContactPhone}`">{{ item.primaryContactPhone }}</a>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <div class="action-list">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-regular fa-pencil"
                                            style="font-size: 1rem"
                                            @click="editUser(item.id)"
                                        />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Edit</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="on" @click="openPasswordResetModal(item)">
                                        <font-awesome-icon icon="fa-regular fa-lock" style="font-size: 1rem" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Password</span>
                                </template>
                            </custom-tooltip>

                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <span @click="openDeleteModal(item)">
                                        <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Delete</span>
                                </template>
                            </custom-tooltip>

                            <v-dialog v-model="deleteModal" persistent max-width="290">
                                <v-card>
                                    <v-card-title class="headline"> Delete Admin ? </v-card-title>
                                    <v-card-text
                                        >Are you sure you want to delete
                                        {{ selectedUser.firstName + ' ' + selectedUser.lastName }}?</v-card-text
                                    >
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </div>
</template>

<script>
import ResetPassword from '@/components/Misc/ResetPassword.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: '  Admin Users',
    },
    data() {
        return {
            options: {
                itemsPerPage: 25,
            },
            homes: [],
            snackbar: false,
            search: '',
            message: '',
            deleteModal: false,
            loading: false,
            errorLoading: false,
            selectedIndex: 0,
            funeralHome: {},
            noUsers: false,
            selectedUser: {},
            token: '',
            passwordResetModal: false,
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Phone',
                    value: 'primaryContactPhone',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: '15%',
                },
            ],
            users: [],
        };
    },
    components: {
        ResetPassword,
        CustomTooltip,
    },
    mounted() {
        this.getAdminUsers();
    },
    methods: {
        getAdminUsers() {
            this.noUsers = false;

            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    this.axios
                        .create({
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        })
                        .get(process.env.VUE_APP_API + `/users/superadmins`)
                        .then(response => {
                            if (response.data) {
                                this.users = response.data;
                                if (!this.users.length) this.noUsers = true;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            } else if (this.$auth.role.includes('Owner')) {
                let owner_id = this.$auth.user['https://memoryshare.com/owner_id'];

                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    this.axios
                        .create({
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        })
                        .get(process.env.VUE_APP_API + `/users/owners/${owner_id}`)
                        .then(response => {
                            if (response.data) {
                                this.users = response.data;

                                if (!this.users.length) this.noUsers = true;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            }
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.selectedIndex = this.users.indexOf(item);
            this.deleteModal = true;
        },
        deleteUser() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .delete(process.env.VUE_APP_API + `/Users/${this.selectedUser.id}`)
                .then(response => {
                    this.message = `${this.selectedUser.firstName} ${this.selectedUser.lastName} deleted!`;
                    this.getAdminUsers();
                })
                .catch(error => {
                    this.message = `Error deleting user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`;
                })
                .then(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
        openPasswordResetModal(item) {
            this.selectedUser = item;
            this.passwordResetModal = true;
        },
        editUser(id) {
            this.$router.push({
                path: `/admins/edit/${id}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 10%;
}
</style>

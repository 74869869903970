<template>
    <div>
        <v-card-title class="mt-3">
            <font-awesome-icon icon="fa-regular fa-tags" style="margin-right: 0.5rem"></font-awesome-icon>
            <h3 class="m-0">Products</h3>
            <v-spacer></v-spacer>
            <v-text-field append-icon="mdi-magnify" style="max-width: 300px" v-model="search"></v-text-field>
        </v-card-title>
        <div class="mb-3" v-if="$route.params.supplier_id">
            <v-btn small dark @click="$router.push({ path: `/products/create/${$route.params.supplier_id}` })"
                >+ Add Product</v-btn
            >
        </div>

        <v-data-table
            :items="products"
            :headers="headers"
            :server-items-length="total"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
            }"
            @click:row="editProduct"
        >
            <template v-slot:header.status="{ header }">
                <v-menu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :disabled="loading"
                    @input="handleStatusMenuToggle"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} ({{ statusesSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <div class="text-center">
                            <v-btn style="margin-bottom: 0.6rem" small width="90%" @click="toggleAllStatuses"
                                >{{ allStatusesSelected ? 'Deselect' : 'Select' }} All</v-btn
                            >
                        </div>
                        <v-list-item v-for="(item, index) in productStatuses" :key="index" style="height: 20px">
                            <v-checkbox v-model="statusesSelected" :label="item.label" :value="item.value"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:header.category="{ header }">
                <v-menu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :disabled="loading"
                    @input="handleCategoryMenuToggle"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} ({{ categoriesSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <div class="text-center">
                            <v-btn style="margin-bottom: 0.6rem" small width="90%" @click="toggleAllCategories"
                                >{{ allCategoriesSelected ? 'Deselect' : 'Select' }} All</v-btn
                            >
                        </div>
                        <v-list-item v-for="(item, index) in productCategories" :key="index" style="height: 20px">
                            <v-checkbox
                                v-model="categoriesSelected"
                                :label="item.label"
                                :value="item.value"
                            ></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.category="{ item }">
                {{ productCategories[item.category].label }}
            </template>

            <template v-slot:item.price="{ item }">
                {{ item.price | formatMoney }}
            </template>

            <template v-slot:item.createDate="{ item }">
                {{ $moment.utc(item.createDate).local().format('l') }}
            </template>

            <!-- <template v-slot:item.status="{ item }">
                <v-chip dark :color="productStatus[item.status].color">{{ productStatus[item.status].text }}</v-chip>
            </template> -->

            <template v-slot:item.status="{ item }">
                <v-select
                    v-if="$auth.role.includes('SuperAdmin')"
                    style="height: 2.6rem"
                    class="selectOrderStatus"
                    dense
                    chips
                    small
                    return-object
                    item-text="label"
                    item-value="value"
                    v-model="item.status"
                    :items="productStatuses"
                    @input="updateProductStatus(item.id, item.status.value)"
                    @click.stop
                >
                    <template v-slot:selection="{ item }">
                        <div>
                            <v-chip class="chip-btn" dark :color="item.color">{{ item.label }}</v-chip>
                        </div>
                    </template>
                    <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-row no-gutters align="center">
                                        <!-- <span>{{ item.descr }}</span> -->
                                        <v-spacer></v-spacer>
                                        <v-chip class="chip-btn" text-color="white" :color="item.color">{{
                                            item.label
                                        }}</v-chip>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>

                <v-chip v-if="!$auth.role.includes('SuperAdmin')" dark :color="productStatuses[item.status].color">{{
                    productStatuses[item.status].label
                }}</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-pencil"
                                    @click="
                                        $router.push({ path: `/products/edit/${$route.params.supplier_id}/${item.id}` })
                                    "
                                    style="font-size: 1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-images"
                                    @click="openImageModal(item)"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Images</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-trash-can"
                                    @click="openDeleteModal(item)"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="imageModal" max-width="600px">
            <v-card class="p-3">
                <h4>Product Images</h4>
                <product-image-upload @img-success="imageModal = false" :productId="selectedProduct.id" />
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width="600px">
            <v-card class="p-3">
                <h4>
                    Delete <strong>{{ selectedProduct.name }}</strong
                    >?
                </h4>
                <p>Enter name to confirm</p>
                <v-text-field v-model="confirmationText"></v-text-field>
                <div style="display: flex; justify-content: space-between">
                    <v-btn @click="cancelDelete()">Cancel</v-btn>
                    <v-btn @click="deleteProduct" :disabled="confirmationText !== selectedProduct.name" color="error"
                        >Delete</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <create-product
            :selectedProduct="selectedProduct"
            @refresh="getProducts()"
            ref="createProduct"
        ></create-product>
    </div>
</template>

<script>
import debounce from '@/utilities/debounce.js';
import CreateProduct from '@/components/Actions/Supplier/CreateProduct.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
import { productStatuses, productCategories } from '@/constants';
import ProductImageUpload from '@/components/Misc/ProductImageUpload.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            statusesSelected: [0, 1],
            categoriesSelected: [0, 1, 2],
            productStatuses,
            productCategories,
            token: '',
            name: '',
            search: '',
            loading: true,
            options: {},
            products: [],
            total: 0,
            selectedProduct: {},
            deleteModal: false,
            imageModal: false,
            confirmationText: '',
            headers: [],
            filterMenuOpen: false,
        };
    },
    components: {
        CreateProduct,
        ProductImageUpload,
        CustomTooltip,
    },

    filters: {
        dateFormat(value) {
            return moment(value).format('l');
        },
    },
    watch: {
        search() {
            this.loading = true;
            this.options.page = 1;
            this.debouncedGetProducts();
        },
        // statusesSelected() {
        //     if (this.statusesSelected.length > 0) {
        //         this.debouncedGetProducts();
        //     } else {
        //         this.products = [];
        //     }
        // },
        options: {
            handler() {
                if (!this.loading) {
                    this.getProducts();
                }
            },
        },
    },
    computed: {
        allStatusesSelected() {
            return this.statusesSelected.length === this.productStatuses.length;
        },
        allCategoriesSelected() {
            return this.categoriesSelected.length === this.productCategories.length;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleStatusMenuToggle: function () {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.statusesSelected.length === 0) {
                    this.products = [];
                    return;
                }
                this.getProducts();
                this.filterMenuOpen = false;
            }
        },
        handleCategoryMenuToggle: function () {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.categoriesSelected.length === 0) {
                    this.products = [];
                    return;
                }
                this.getProducts();
                this.filterMenuOpen = false;
            }
        },
        openImageModal(item) {
            this.imageModal = true;
            this.selectedProduct = item;
        },
        getStatusQuery() {
            let string = '';
            this.statusesSelected.forEach(status => {
                string += `&productStatus=${status}`;
            });
            return string;
        },
        getCatQuery() {
            let string = '';
            this.categoriesSelected.forEach(cat => {
                string += `&productCategory=${cat}`;
            });
            return string;
        },
        toggleAllStatuses() {
            if (this.allStatusesSelected) {
                this.statusesSelected = [];
            } else {
                this.productStatuses.forEach(status => {
                    if (status.value >= 0 && !this.statusesSelected.includes(status.value)) {
                        this.statusesSelected.push(status.value);
                    }
                });
            }
        },
        toggleAllCategories() {
            if (this.allCategoriesSelected) {
                this.categoriesSelected = [];
            } else {
                this.productCategories.forEach(category => {
                    if (category.value >= 0 && !this.categoriesSelected.includes(category.value)) {
                        this.categoriesSelected.push(category.value);
                    }
                });
            }
        },
        getSortBy(value) {
            if (value) {
                switch (value) {
                    case 'id':
                        return 'Id';
                    case 'supplierName':
                        return 'SupplierName';
                    case 'name':
                        return 'Name';
                    case 'price':
                        return 'Price';
                    case 'inventory':
                        return 'Inventory';
                    case 'createDate':
                        return 'CreateDate';
                    case 'status':
                        return 'Status';
                    case 'category':
                        return 'Category';
                    default:
                        return 'Id';
                }
            } else {
                return 'Id';
            }
        },
        updateProductStatus(id, status) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + `/Products/update-status/${id}?status=${status}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Status updated' });
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error updating status' });
                    });
            } else {
                this.showSnackbar({ message: 'Must be admin to update status' });
            }
        },
        cancelDelete() {
            this.selectedProduct = {};
            this.deleteModal = false;
            this.confirmationText = '';
        },
        deleteProduct() {
            if (this.confirmationText === this.selectedProduct.name) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + `/Products/${this.selectedProduct.id}`)
                    .then(response => {
                        this.showSnackbar({ message: `${this.selectedProduct.name} deleted` });
                        this.selectedProduct = {};
                        this.deleteModal = false;
                        this.getProducts();
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error deleting product' });
                    });
            } else {
                this.showSnackbar({ message: 'Confirmation did not match product name' });
            }
        },
        openDeleteModal(item) {
            this.deleteModal = true;
            this.selectedProduct = item;
            this.confirmationText = '';
        },
        editProduct(item) {
            this.$router.push({ path: `/products/edit/${item.supplierId}/${item.id}` });
        },
        addProduct() {
            this.$refs.createProduct.dialog = true;
            this.$refs.createProduct.editing = false;
        },
        // getSupplier() {
        //     this.loading = true;
        //     this.$auth.getIdTokenClaims().then(result => {
        //         this.token = result.__raw;

        //         this.axios
        //             .create({ headers: { Authorization: `Bearer ${this.token}` } })
        //             .get(process.env.VUE_APP_API + `/Suppliers/${this.$route.params.supplier_id}`)
        //             .then(response => {
        //                 this.name = response.data.name;
        //                 this.loading = false;
        //             })
        //             .catch(error => {
        //                 console.log(error, 'error');
        //                 this.loading = false;
        //             });
        //     });
        // },
        getProducts() {
            this.loading = true;
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                const statusQuery = this.getStatusQuery();
                const catQuery = this.getCatQuery();
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;
                const sortString = this.getSortBy(sortBy[0]);

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Products?search=${this.search}${statusQuery}${catQuery}`, {
                        params: {
                            supplierId: this.$route.params.supplier_id ? this.$route.params.supplier_id : null,
                            pageSize: itemsPerPage,
                            pageNumber: page ? page - 1 : 1,
                            sortBy: sortString,
                            sortAsc: sortBy ? !sortDesc[0] : true,
                        },
                    })
                    .then(response => {
                        this.products = response.data.products;
                        this.total = response.data.total;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.loading = false;
                    });
            });
        },
    },
    created() {
        this.debouncedGetProducts = debounce(this.getProducts, 500);
    },
    mounted() {
        if (this.$auth.role.includes('SuperAdmin')) {
            this.headers = [
                { text: 'Id', value: 'id' },
                { text: 'Supplier', value: 'supplierName' },
                { text: 'Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Price', value: 'price' },
                { text: 'Inventory', value: 'inventory', align: 'center' },
                { text: 'Created', value: 'createDate', align: 'center' },
                { text: 'Status', value: 'status', align: 'center', width: '10%' },
            ];
        } else {
            this.headers = [
                { text: 'Id', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Price', value: 'price' },
                { text: 'Inventory', value: 'inventory', align: 'center' },
                { text: 'Created', value: 'createDate', align: 'center' },
                { text: 'Status', value: 'status', align: 'center', width: '10%' },
            ];
        }
        this.options.itemsPerPage = 25;
        // this.getSupplier();
        this.getProducts();
    },
};
</script>

<style>
.chip-btn:hover {
    cursor: pointer;
    color: white !important;
}
</style>

<template>
    <div v-if="$auth.role.includes('SuperAdmin')">
        <!-- <h5 class="text-center mt-8">Note: Click expand to see old and new values</h5>
        <BasicDisplayTable v-if="url" :url="url" />
        <v-divider></v-divider> -->

        <div class="caution-zone mb-3 p-3 mt-8">
            <h6>RESET STRIPE</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <span>Reset Stripe ID for this funeral home.</span>

                <v-btn outlined @click="stripeModal = true">Unlink</v-btn>
            </div>
        </div>

        <div class="caution-zone mb-3 p-3">
            <h6>GENERATE HLS VIDEOS</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <span>Generate HLS renders for all available videos.</span>

                <v-btn outlined @click="generateHlsVideosModal = true">Generate Hls Videos</v-btn>
            </div>
        </div>

        <div v-if="fhSettings.storageType === 3" class="info-zone mb-3 p-3">
            <h6>UNARCHIVE VIDEOS</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <span>Undo archive for all videos for this funeral home.</span>

                <v-btn outlined @click="archiveVideosUndoModal = true">Unarchive Videos</v-btn>
            </div>
        </div>

        <div v-else class="danger-zone mb-3 p-3">
            <h6>ARCHIVE VIDEOS</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <span>Send all videos for this funeral home to archive.</span>

                <v-btn outlined @click="archiveVideosModal = true">Archive Videos</v-btn>
            </div>
        </div>

        <div class="danger-zone p-3">
            <h6>DANGER ZONE</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <div>Once you delete a home there is no going back. Please be certain.</div>
                <v-btn class="ml-4" bottom depressed @click="openDeleteModalOne" dark color="error">Delete Home</v-btn>
            </div>
        </div>

        <v-dialog v-model="stripeModal" max-width="300">
            <v-card>
                <v-card-title class="justify-center">Unlink Stripe?</v-card-title>

                <v-card-text class="text-center mt-1">
                    <strong>{{ fhName }}</strong> : <strong>{{ fhId }}</strong></v-card-text
                >
                <v-card-text class="text-center">
                    <strong>Stripe ID</strong> : <strong>{{ stripeID }}</strong>
                </v-card-text>

                <v-card-text class="text-center">
                    Proceed with caution, this will reset this home's Stripe ID
                </v-card-text>
                <v-card-text v-if="!$auth.role.includes('God')"
                    >NOTICE: <strong>You are not God.</strong> Please Contact Shane or Kyle to Unlink Stripe
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="stripeModal = false">Cancel</v-btn>
                    <v-btn :disabled="!$auth.role.includes('God')" text color="red darken-1" @click="unlinkStripe"
                        >Unlink</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModalOne" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Warning!</v-card-title>

                <v-card-text class="text-center mt-1">
                    Delete Home?<br />
                    <strong>{{ fhName }}</strong> : <strong>{{ fhId }}</strong></v-card-text
                >

                <v-card-text
                    >Deleting a funeral home deletes all Videos, PDFs, and Billing Data. You should never need to delete
                    a funeral home, unless it was never used or just a test account. Canceling the funeral home prevents
                    them from creating services, and blocks all videos.
                </v-card-text>
                <v-card-text v-if="!$auth.role.includes('God')"
                    >NOTICE: <strong>You are not God.</strong> Please Contact Shane or Kyle to Delete
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModalOne = false"> Cancel </v-btn>
                    <v-btn
                        :disabled="!$auth.role.includes('God')"
                        @click="handleDeleteModalProceed"
                        color="red darken-1"
                        text
                    >
                        Proceed
                    </v-btn>
                    <!-- :disabled="!$auth.role.includes('God')" -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModalTwo" :retain-focus="false" persistent max-width="290">
            <v-card>
                <v-card-title class="headline"> Confirmation </v-card-title>

                <v-card-text>
                    Please Enter Funeral Home Name to Delete <strong>{{ fhName }}</strong>
                    <v-text-field v-model="deleteConfirmationText" single-line hide-details></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModalTwo = false"> Cancel </v-btn>
                    <v-btn color="red darken-1" @click="deleteHome" text> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="archiveVideosModal" max-width="400">
            <v-card>
                <v-card-title>Archive All Videos?</v-card-title>
                <v-card-text
                    >This will archive all converted videos and will delete all compressed original files.

                    <div class="mt-3">
                        Total Videos: <strong>{{ totalVideos }}</strong>
                    </div>
                    <div style="border: 1px solid #d8d8d8" class="mt-3 pa-2 rounded">
                        <div class="text-overline">Cost to unarchive:</div>
                        <v-divider class="mt-0 pt-0"></v-divider>
                        <div>
                            <v-row no-gutters>
                                <v-col cols="3"> Instant: </v-col>
                                <v-col>
                                    ~<strong>${{ totalVideos * instantUnarchiveCharge }}</strong>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="3"> Standard: </v-col>
                                <v-col>
                                    ~<strong>${{ totalVideos * standardUnarchiveCharge }}</strong>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="archiveVideosModal = false">Cancel</v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        depressed
                        color="error"
                        @click="submitArchiveAllVideos"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="archiveVideosUndoModal" max-width="400">
            <v-card>
                <v-card-title>Unarchive All Videos?</v-card-title>
                <v-card-text
                    >This will unarchive <strong>all videos</strong> for this funeral home for
                    <strong>~$8 charge per video</strong>. Please be certain before unarchiving full collection.

                    <div class="mt-3">
                        Total Videos: <strong>{{ totalArchivedVideos }}</strong>
                    </div>
                    <div style="border: 1px solid #d8d8d8" class="mt-3 pa-2 rounded">
                        <div class="text-overline">Cost to unarchive:</div>
                        <v-divider class="mt-0 pt-0"></v-divider>
                        <div>
                            <v-row no-gutters>
                                <v-col cols="3"> Instant: </v-col>
                                <v-col>
                                    ~<strong>${{ totalVideos * instantUnarchiveCharge }}</strong>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="3"> Standard: </v-col>
                                <v-col>
                                    ~<strong>${{ totalVideos * standardUnarchiveCharge }}</strong>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-row>
                        <v-col>
                            <v-select
                                class="my-6"
                                label="Unarchive Priority"
                                :items="rehydratePriorities"
                                v-model="selectedUnarchivePriority"
                                item-text="label"
                                item-value="value"
                                hint="Selecting high priority will incur a higher unarchive fee than selecting low priority, only select if necessary"
                                persistent-hint
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-overline">Note:</div>
                            <div>Videos can be unarchived one by one if needed.</div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="archiveVideosUndoModal = false">Cancel</v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        depressed
                        color="error"
                        @click="archiveDoubleConfirmModal = true"
                        >Next</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="archiveDoubleConfirmModal" max-width="380">
            <v-card>
                <v-card-title>Confirm Unarchive</v-card-title>
                <v-card-text
                    >Are you sure that you want to unarchive <strong>{{ totalArchivedVideos }} videos</strong> for the
                    cost of <strong>${{ computedUnarchiveCost }}</strong
                    >?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="archiveDoubleConfirmModal = false">Cancel</v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        depressed
                        color="error"
                        @click="submitArchiveUndoAllVideos"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="generateHlsVideosModal" max-width="500">
            <v-card>
                <v-card-title>Generate HLS renders for all videos?</v-card-title>
                <v-card-text
                    >Are you sure that you want to generate HLS renders for
                    <strong>{{ totalNonHlsVideos }} videos</strong>? An HLS render job will be added to the render que
                    for each video.

                    <v-row class="mt-0">
                        <v-col class="d-flex" style="gap: 12px" cols="12">
                            <v-text-field v-if="customVM" v-model="tempVMName" label="VM Name"></v-text-field>
                            <v-select
                                v-else
                                v-model="selectedVm"
                                :items="vmList"
                                item-text="name"
                                item-value="name"
                                label="VM Name"
                            ></v-select>
                            <v-checkbox v-model="customVM" label="Other"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                type="number"
                                min="0"
                                max="200"
                                v-model="maxRenders"
                                label="Max Renders"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-alert v-if="totalNonHlsVideos === 0" dense outlined type="error">
                        All available videos have already been HLS rendered</v-alert
                    >
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="generateHlsVideosModal = false">Cancel</v-btn>
                    <v-btn
                        :disabled="loading || totalNonHlsVideos === 0"
                        :loading="loading"
                        depressed
                        color="error"
                        @click="confirmGenerateHlsAllVideos"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import BasicDisplayTable from '@/components/Tables/BasicDisplayTable.vue';

export default {
    data: function () {
        return {
            url: null,
            deleteModalOne: false,
            deleteModalTwo: false,
            deleteConfirmationText: '',
            stripeModal: false,
            stripeID: null,
            archiveVideosModal: false,
            archiveVideosUndoModal: false,
            selectedUnarchivePriority: false,
            rehydratePriorities: [
                { label: 'Low (24 hours)', value: false },
                { label: 'High (1 hour)', value: true },
            ],
            loading: false,
            totalVideos: 0,
            totalArchivedVideos: 0,
            totalNonHlsVideos: 0,
            instantUnarchiveCharge: 8,
            standardUnarchiveCharge: 1,
            archiveDoubleConfirmModal: false,
            generateHlsVideosModal: false,
            fhSettings: {},
            tempVMName: '',
            customVM: false,
            vmList: [],
            selectedVm: null,
            maxRenders: 20,
        };
    },
    props: ['fhName', 'token'],
    components: { BasicDisplayTable },
    computed: {
        computedUnarchiveCost() {
            const costPerVid = this.selectedUnarchivePriority
                ? this.instantUnarchiveCharge
                : this.standardUnarchiveCharge;

            return this.totalArchivedVideos * costPerVid;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        confirmGenerateHlsAllVideos() {
            if (this.fhId <= 0) {
                this.showSnackbar({ message: 'Invalid funeral home id', color: 'error' });
                return;
            }

            let vmName = this.customVM ? this.tempVMName : this.selectedVm;

            if (!vmName) {
                this.showSnackbar({ message: 'Select a vm to continue', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/funeralhomes/generate-hls-fh-videos/${this.fhId}?vmName=${vmName}&max=${this.maxRenders}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Hls render creation process started' });
                    this.generateHlsVideosModal = false;
                    this.getSettings();
                })
                .catch(error => {
                    this.loading = false;
                    this.showSnackbar({ message: 'Error processing hsl render creation', color: 'error' });
                });
        },
        getVMList() {
            this.axiosInstance
                .get(process.env.VUE_APP_API + '/render/vm-list')
                .then(response => {
                    this.vmList = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        submitArchiveAllVideos() {
            if (this.fhId <= 0) {
                this.showSnackbar({ message: 'Invalid funeral home id', color: 'error' });
                return;
            }

            this.loading = true;
            this.axiosInstance
                .put(`/funeralhomes/archive-all-videos/${this.fhId}`)
                .then(resp => {
                    this.getSettings();
                    this.showSnackbar({ message: 'All video archive processing' });
                })
                .catch(error => {
                    this.loading = false;
                    this.showSnackbar({ message: 'Error triggering all video archive', color: 'error' });
                });
        },
        submitArchiveUndoAllVideos() {
            if (this.fhId <= 0) {
                this.showSnackbar({ message: 'Invalid funeral home id', color: 'error' });
                return;
            }

            this.loading = true;

            this.axiosInstance
                .put(`/funeralhomes/archive-undo-all-videos/${this.fhId}?priority=${this.selectedUnarchivePriority}`)
                .then(resp => {
                    this.archiveDoubleConfirmModal = false;
                    this.archiveVideosUndoModal = false;
                    this.getSettings();
                    this.showSnackbar({ message: 'All video archive undo processing' });
                })
                .catch(error => {
                    this.loading = false;
                    this.showSnackbar({ message: 'Error triggering all video archive undo', color: 'error' });
                });
        },
        getFhVideoCount() {
            if (this.fhId <= 0) {
                this.showSnackbar({ message: 'Invalid funeral home id', color: 'error' });
                return;
            }

            this.axiosInstance
                .get(`/funeralhomes/video-count/${this.fhId}`)
                .then(resp => {
                    if (resp.data) {
                        this.totalVideos = resp.data.total;
                        this.totalArchivedVideos = resp.data.archived;
                        this.totalNonHlsVideos = resp.data.nonHls;
                    }
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getSettings() {
            if (!this.fhId) {
                this.showSnackbar({ message: 'Invalid fh id', color: 'error' });
                return;
            }

            this.axiosInstance
                .get(`/funeralhomes/settings/admin/${this.fhId}`)
                .then(resp => {
                    this.fhSettings = resp.data;
                })
                .catch(error => {
                    console.log('error', error);
                    this.showSnackbar({ message: 'Error getting settings', color: 'error' });
                });
        },
        openDeleteModalOne() {
            this.deleteModalOne = true;
        },
        handleDeleteModalProceed(item) {
            this.deleteModalOne = false;
            this.deleteModalTwo = true;
        },
        unlinkStripe() {
            this.axiosInstance
                .post(`/billing/unlink-stripe/${this.fhId}`)
                .then(result => {
                    this.showSnackbar({ message: 'Stripe ID Reset' });
                    this.stripeID = false;
                    this.stripeModal = false;
                    this.getStripeId();
                })
                .catch(error => {
                    console.log(error);

                    const status = JSON.stringify(error.response.status);
                    if (status == '400') {
                        var message = JSON.stringify(error.response.data);
                        message = message.replaceAll('"', '');
                        this.showSnackbar({ message: message });
                    } else {
                        this.showSnackbar({ message: 'Error resetting Stripe ID' });
                    }
                });
        },
        getStripeId() {
            this.axiosInstance
                .get(`/billing/funeralhome/${this.$route.params.id}`)
                .then(response => {
                    this.stripeID = response.data.subscriptionId;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteHome() {
            // if (this.$auth.role.includes('God')) {
            if (this.deleteConfirmationText !== this.fhName) {
                this.showSnackbar({ message: 'Text does not match!', color: 'error' });
                return;
            } else {
                this.deleteConfirmationText = '';
                this.axiosInstance
                    .delete(`/FuneralHomes/${this.fhId}`)
                    .then(result => {
                        this.showSnackbar({ message: 'Home Deleted' });
                        this.$router.push({ name: 'Homes' });
                        this.$router.go(0);
                    })
                    .catch(error => {
                        this.showSnackbar({
                            message: `Error Deleting Home. Please try again`,
                        });
                    });
                this.deleteModalTwo = false;
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    created() {
        this.fhId = this.$route.params.id;
    },
    mounted() {
        this.createAxiosInstance();
        this.url = `/logs/funeral-home/${this.$route.params.id}`;
        this.getStripeId();
        this.getFhVideoCount();
        this.getSettings();
        this.getVMList();
    },
    sockets: {
        async NotifyArchiveStatus(data) {
            if (data.funeralHomeId == this.$route.params.id && this.loading) {
                var action = data.storageType === 3 ? 'Archive' : 'Unarchive';
                this.showSnackbar({ message: `${action} complete` });
                this.archiveVideosModal = false;
                this.archiveVideosUndoModal = false;
                this.loading = false;
                this.$emit('refresh');
            }
        },
    },
};
</script>

<style scoped>
.caution-zone {
    /* background-color: #eee; */
    border: 1px solid #fdd835;
    border-radius: 5px;
}

.danger-zone {
    background-color: #f8fafb;
    border: 1px solid red;
    border-radius: 5px;
}

.info-zone {
    background-color: #f8fafb;
    border: 1px solid #1877f2;
    border-radius: 5px;
}
</style>

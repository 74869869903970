<template>
    <div>
        <div>Line Items</div>
        <v-data-table
            :items="lineItems"
            :headers="headers"
            :server-items-length="total"
            :loading="loading"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:header.supplierId="{ header }">
                <v-menu ref="menu" :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }}

                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list class="p-3" style="width: 300px">
                        <v-select
                            :items="suppliers"
                            return-object
                            item-value="id"
                            item-text="name"
                            v-model="supplierId"
                            @input="updateSelectedSupplier"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="d-flex justify-center" style="width: 100%">
                                    {{ item.name }}
                                </span>
                            </template>

                            <template v-slot:item="{ item }">
                                <div style="width: 100%; display: flex; justify-content: space-between">
                                    <span>{{ item.name }}</span>
                                    <span>{{ item.id }}</span>
                                </div>
                            </template>
                        </v-select>
                        <v-btn small @click="clearSupplierId" v-if="supplierId > 0">Clear</v-btn>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.unitAmount="{ item }">
                {{ item.unitAmount | formatMoney() }}
            </template>
            <template v-slot:item.totalAmount="{ item }">
                {{ item.totalAmount | formatMoney() }}
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { debounceV2 } from '@/utilities/debounce.js';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            token: '',
            lineItems: [],
            supplierId: 0,
            loading: true,
            total: 0,
            options: {},
            suppliers: [],
            headers: [
                { text: 'Order Id', value: 'orderId' },
                { text: 'Name', value: 'productName' },
                { text: 'SupplierId', value: 'supplierId' },
                { text: 'Unit Amount', value: 'unitAmount' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Total Amount', value: 'totalAmount' },
            ],
        };
    },
    components: { CustomTooltip },
    watch: {
        options() {
            debounceV2(function () {
                if (!this.loading) this.getLineItems();
            }, 500);
        },
    },
    methods: {
        clearSupplierId() {
            this.supplierId = 0;
            this.$refs.menu.save();
            this.getLineItems();
        },
        updateSelectedSupplier(item) {
            this.supplierId = item.id;
            this.$refs.menu.save();
            this.getLineItems();
        },
        getSuppliers() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Suppliers`)
                    .then(response => {
                        this.suppliers = response.data.suppliers;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
        getLineItems() {
            this.loading = true;

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                // const supplierParam = this.supplierId > 0 ? this.supplierId : '';

                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Order/line-item-list`, {
                        params: {
                            pageSize: itemsPerPage,
                            pageNumber: page ? page - 1 : 1,
                            supplierId: this.supplierId > 0 ? this.supplierId : 0,
                            // sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                            // sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                        },
                    })
                    .then(response => {
                        this.lineItems = response.data.lineItems;
                        this.total = response.data.total;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
    },
    mounted() {
        this.getLineItems();
        this.getSuppliers();
    },
};
</script>
<style></style>

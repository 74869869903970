<template>
    <div class="pa-3">
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-rabbit-running" style="margin-right: 0.5rem" />
            <h3 class="m-0">Bunny Video Metadata</h3>
        </v-card-title>

        <div class="d-flex align-center">
            <v-text-field @keydown.enter="getBunnyVideo(videoGuid)" v-model="videoGuid"></v-text-field>
            <v-btn class="ml-4" color="primary" @click="getBunnyVideo(videoGuid)" :disabled="!videoGuid || loading"
                >Search</v-btn
            >
        </div>
        <pre class="pa-3 rounded-lg" style="background-color: #f1f4f7">
            {{ videoData }}
        </pre>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            videoGuid: '',
            loading: true,
            videoData: '',
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getBunnyVideo(videoId) {
            if (!videoId) {
                this.showSnackbar({ message: 'Invalid VideoId', color: 'error' });
                return;
            }

            this.loading = true;

            this.axiosInstance
                .get(`/admin-tools/get-bunny-video?videoId=${videoId}`)
                .then(resp => {
                    this.videoData = resp.data;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error retreiving video data', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.loading = false;
    },
};
</script>
<style lang=""></style>

<template>
    <div class="p-3">
        <div v-if="link">
            <v-progress-linear v-if="loading && isValidURL(link)" indeterminate rounded></v-progress-linear>
            <iframe
                v-if="isValidURL(link)"
                :src="link"
                @load="loading = false"
                style="
                    width: 100%;
                    height: 1000px;
                    border-radius: 5px;
                    border: none;
                    margin: 0;
                    padding: 0;
                    overflow: auto;
                "
            >
                <!-- z-index: 999999; -->
                Your browser doesn't support iframes
            </iframe>
            <div class="text-center" v-else>
                <v-alert type="warning" outlined text>Unable to retrieve report</v-alert>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: true,
            link: '',
        };
    },
    props: {
        funeralHomeId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        isValidURL(string) {
            const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
            return pattern.test(string);
        },
        getHome() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/funeralhomes/${this.funeralHomeId}`)
                    .then(response => {
                        const home = response.data.funeralHome;
                        this.link = home.keywordReportLink;
                    })
                    .catch(err => {
                        console.log(err, 'err');
                    });
            });
        },
    },
    mounted() {
        this.getHome();
    },
};
</script>
<style lang=""></style>

<template>
    <div class="upload-page-wrapper stories-page">
        <animated-waves-background
            v-if="customStartColor && customEndColor"
            :startColor="customStartColor"
            :endColor="customEndColor"
            class="animated-bg-comp"
        />

        <div class="logo-wrap" v-if="logo">
            <img style="max-width: 100%; max-height: 100%" :src="logo" />
        </div>

        <v-container fluid class="hero">
            <v-layout justify-center align-center>
                <v-flex class="card-wrap">
                    <div class="float-card elevation-12 m-4">
                        <!-- <div class="p-2">
                            <h1 class="text-center" v-if="service.firstName">
                                Contribute to {{ service.firstName }}'s Tribute Story
                            </h1>
                            <h1 class="text-center" v-else>Contribute to Tribute Story</h1>
                        </div> -->

                        <v-row justify="center" v-if="videoLink">
                            <v-col cols="12">
                                <div class="pa-3 d-flex flex-column align-center">
                                    <div style="max-width: 1200px; width: 100%">
                                        <branding-player-preview
                                            :key="reloadCount"
                                            ref="videoPlayer"
                                            :show-title="false"
                                            :source="videoLink"
                                        ></branding-player-preview>
                                    </div>
                                </div>

                                <div class="text-center ma-3">
                                    <v-btn
                                        v-if="videoLink"
                                        color="#0d3d60"
                                        :dark="!loading"
                                        :disabled="loading"
                                        @click="downloadTributeStory(videoLink)"
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-arrow-down-to-bracket"
                                            style="font-size: 0.9rem"
                                            class="mr-2"
                                        />
                                        <span>Download</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="p-4" v-else>
                            <v-col cols="12" class="text-center">
                                <h4>Tribute Story Not Available</h4>
                            </v-col>
                        </v-row>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <!-- START: Render download modal -->
        <v-dialog persistent v-model="downloading" max-width="500px">
            <v-card class="p-3">
                <div class="d-flex justify-content-between">
                    <h5>Downloading</h5>
                    <p>{{ downloadProgress }}%</p>
                </div>
                <v-progress-linear rounded v-model="downloadProgress"></v-progress-linear>
                <v-btn class="mt-3" color="error" @click="cancelDownload">Cancel</v-btn>
            </v-card>
        </v-dialog>
        <!-- END: Render download modal -->
    </div>
</template>
<script>
import { getLighterShade, getDarkerShade } from '@/utilities/color.js';
import AnimatedWavesBackground from '@/components/ui/AnimatedWavesBackground.vue';
import CustomLoader from '@/components/ui/CustomLoader.vue';
import { mapActions } from 'vuex';
import BrandingPlayerPreview from '@/components/videojs/BrandingPlayerPreview.vue';

export default {
    metaInfo: {
        title: 'View Story QR',
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    },
    data() {
        return {
            service: {},
            customStartColor: null,
            customEndColor: null,
            logo: '',
            storyEvent: {},
            render: {},
            loading: true,
            downloading: false,
            downloadProgress: 0,
            reloadCount: 0,
            posterReloadCount: 0,
            downloadCancelToken: null,
            refreshKey: 0,
            videoLink: '',
        };
    },
    components: {
        AnimatedWavesBackground,
        CustomLoader,
        BrandingPlayerPreview,
    },
    methods: {
        ...mapActions(['showSnackbar']),
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadTributeStory(url) {
            let name = `${this.service.firstName} ${this.service.lastName}'s Story`;
            this.showSnackbar({ message: 'Downloading' });

            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
        getStory(slug) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeStory/view/${slug}`)
                .then(resp => {
                    this.service = resp.data.service;

                    if (resp.data.story.qrVideoUrl) {
                        this.videoLink = resp.data.story.qrVideoUrl;
                    }

                    if (resp.data.service.videoPlayerPrimaryColor) {
                        this.setAnimatedBackgroundColors(resp.data.service.videoPlayerPrimaryColor);
                    }

                    if (resp.data.service.logoURL) {
                        this.logo = resp.data.service.logoURL;
                    }

                    if (resp.data.service.events.length > 0) {
                        this.storyEvent = resp.data.service.events[0];
                    }
                    if (resp.data.render) {
                        this.render = resp.data.render;
                    }
                });
        },
        handleRenderError() {
            const videoEl = this.$refs['renderPlayer'];
            const maxAttempts = 5;

            if (this.posterReloadCount < maxAttempts) {
                setTimeout(() => {
                    console.log(`reloading render | ${this.reloadCount}`);
                    videoEl.poster = this.handleThumbnail(this.render.url, 'story-render');
                    this.posterReloadCount++;
                }, 1000);
            }
        },
        setAnimatedBackgroundColors(baseColor) {
            const darkerShade = getDarkerShade(baseColor, 0.2);

            this.customStartColor = baseColor;
            this.customEndColor = darkerShade;
        },
    },
    sockets: {
        NotifyStoryRender(data) {
            if (this.storyEvent.id == data.eventId) {
                this.getStory(this.$route.params.slug);
                this.reloadCount++;
            }
        },
    },
    async mounted() {
        await this.getStory(this.$route.params.slug);

        this.loading = false;
    },
};
</script>
<style lang="scss">
// .blue-background {
//     background: url('../../assets/images/animated-background-blue.svg') no-repeat 0 -16rem;
// }
.upload-page-wrapper.stories-page {
    // border: 2px solid red;

    .grid {
        cursor: pointer;
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        justify-items: start;
        grid-gap: 5px;
        // border: 2px solid lime;
    }

    .grid-item {
        max-width: 200px;
        width: 100%;
        padding: 4px;
    }

    .video-container {
        // border: 1px solid;
        position: relative;
        width: 100%;
        aspect-ratio: 9/16;
        // aspect-ratio: 16/9;
        // height: 0;
        // padding-bottom: 177.78%; /* Set the aspect ratio based on the video's height and width */
        background-color: black;
        border-radius: 5px;
    }

    .video-container video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        //object-fit: cover; /* Adjust the object-fit property as needed */
        object-fit: contain; /* Adjust the object-fit property as needed */
    }

    .error-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 18px;
        text-align: center;
    }

    .del-action-btn {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        opacity: 0;
        height: 20px !important;
        width: 20px !important;
    }

    .video-container:hover .del-action-btn {
        opacity: 0.4;
        // border: 2px solid red;
    }

    .del-action-btn:hover {
        opacity: 1 !important;
    }
}
.disabled {
    color: #e5e5e5 !important;
}
#webcam-player {
    // border: 2px solid red;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background-color: black;
    // object-fit: cover;
    object-fit: contain;
}
.flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mobile-fov {
    aspect-ratio: 9 / 16;
}
.desktop-fov {
    aspect-ratio: 16 / 9;
}

.webcam-overlay {
    // border: 2px solid lime;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    max-height: 100%;

    background-color: transparent;
    z-index: 5;
    object-fit: contain;

    .actions-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // border: 2px solid violet;
        width: 100%;
        padding: 10px;
        z-index: 5;
    }

    .actions-bottom {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        // border: 2px solid cyan;
        width: 100%;
        padding: 10px;
        z-index: 5;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        max-height: 100%;
        background-color: black;
        z-index: 4;
    }
}
.upload-page-wrapper {
    background-color: transparent;
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;
    height: 100vh;

    .action-list-item {
        cursor: pointer;
    }

    .logo-wrap {
        border: none;
        max-width: 100px;
        max-height: 100px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1;
        object-fit: contain;
        padding: 10px;
    }

    .action-list-item:hover {
        background-color: #f8f8f8;
    }

    .dashboard-uploader-wrap {
        background-color: #f8f8f8;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: center;
    }

    .gallery-section {
        width: 100%;
        // max-width: 1200px;
        // padding: 0 44px;
    }

    .gallery-video {
        aspect-ratio: 1;
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
    }

    .download-banner {
        position: sticky;
        top: 0;
        z-index: 20;
        background-color: white;
        // color: white;
        display: none;
        justify-content: space-between;
        align-items: center;
    }
    .hero {
        min-width: 100%;
        // background: linear-gradient(180deg, #0d3d60 40%, #ffffff 40%);
        // border: 1px solid green;
        // background-color: #ffffff;
        padding: 60px 0;

        background-size: cover;
        background-position: center;
        position: relative;
    }

    .card-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 200px;
    }
    .float-card {
        // border-radius: 40px;
        border-radius: 4px;
        padding: 12px;
        background-color: white;
    }
    .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 4px;
    }

    .gallery-content {
        margin-top: 20px;
        max-width: 80%;
    }
    .delete-action-btn-qr {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        opacity: 0;
        height: 20px !important;
        width: 20px !important;
    }

    .family-gallery-photo {
        // border-radius: 10px;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        height: auto;
        max-width: 300px;
        max-height: 300px;
    }

    .family-gallery-photo:hover ~ .delete-action-btn-qr {
        opacity: 0.4;
        // border: 2px solid red;
    }
    .delete-action-btn-qr:hover {
        opacity: 1;
    }

    .photo-wrap {
        display: flex;
        justify-content: center;
        position: relative;
    }
    .family-uploader {
        border-radius: 40px;

        #drop-zone {
            border-radius: 40px;
            height: 150px;
            border: 2px dashed #dbe2e8;
        }
        #drop-zone div {
            border-radius: 40px;
        }
        .uppy-DragDrop--isDragDropSupported {
            border-radius: 40px;
        }
        .uppy-DragDrop-container {
            border-radius: 40px !important;
        }
    }

    .animated-bg-comp {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 1250px) {
    .upload-page-wrapper {
        .card-wrap {
            padding: 0 120px;
        }
    }
}

@media (max-width: 1024px) {
    /* Styles for mobile devices and iPads */
    .upload-page-wrapper {
        .download-banner {
            display: flex;
        }
        .hero {
            padding-bottom: 0;
        }

        .family-gallery-photo {
            max-width: 400px;
            max-height: 400px;
        }

        .card-wrap {
            padding: 0 40px;
        }
    }
}

@media only screen and (max-width: 510px) {
    .upload-page-wrapper {
        .gallery-section {
            padding: 0 16px;
        }

        .card-wrap {
            padding: 0;
        }
    }
}
</style>

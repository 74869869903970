import axios from 'axios';

const ApiService = {
    createInstance(token) {
        const instance = axios.create({
            baseURL: process.env.VUE_APP_API,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return {
            get(resource, config = {}) {
                return instance.get(resource, config);
            },

            post(resource, data, config = {}) {
                return instance.post(resource, data, config);
            },

            put(resource, data, config = {}) {
                return instance.put(resource, data, config);
            },

            delete(resource, config = {}) {
                return instance.delete(resource, config);
            },
        };
    },
};

export default ApiService;

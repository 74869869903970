<template>
    <div>
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-magnifying-glass" style="margin-right: 0.5rem" />
            <h3 class="m-0">User Search</h3>
            <v-spacer></v-spacer>

            <v-text-field @keydown.enter="getUsers()" label="Search" v-model="search"></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :server-items-length="total"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:item.userType="{ item }">
                <v-chip dark :color="userTypes[item.userType].color">{{ userTypes[item.userType].label }}</v-chip>
            </template>
            <template v-slot:item.org="{ item }">
                <v-chip :color="userTypes[item.userType].color" outlined v-if="item.userType == 0"
                    >{{ item.funeralHomeId }} {{ item.funeralHomeString }}</v-chip
                >
                <v-chip :color="userTypes[item.userType].color" outlined v-if="item.userType == 1 || item.userType == 4"
                    >{{ item.ownerId }} {{ item.ownerString }}</v-chip
                >
            </template>
            <template v-slot:item.firstName="{ item }">
                <span>{{ item.firstName }} {{ item.lastName }}</span>
            </template>
            <template v-slot:item.auth0="{ item }">
                <div v-if="item.auth0Id" @click="goToAuth0(item)" style="max-width: 20px">
                    <img style="max-width: 100%" :src="require('@/assets/images/auth0_logo.png')" alt="Logo" />
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { debounceV2 } from '@/utilities/debounce.js';
import { mapActions } from 'vuex';
import { userTypes } from '@/constants';
export default {
    data() {
        return {
            userTypes,
            users: [],
            search: '',
            total: 0,
            options: {},
            loading: false,
            headers: [
                { text: 'ID', value: 'id', width: '5%' },
                { text: 'Organization', value: 'org' },
                { text: 'Type', value: 'userType', width: '20%' },
                { text: 'Email', value: 'email' },
                { text: 'Name', value: 'firstName' },
                { text: 'Auth0', value: 'auth0' },
            ],
        };
    },
    watch: {
        search: debounceV2(function () {
            if (this.search.length > 0) {
                this.options = {
                    ...this.options,
                    page: 1,
                };
                this.getUsers();
            } else {
                this.users = [];
            }
        }, 500),
        options: {
            handler() {
                if (!this.loading && this.search.length > 0) {
                    this.getUsers();
                }
            },
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        goToAuth0(item) {
            window.open('https://manage.auth0.com/dashboard/us/midweststreams/users/' + item.auth0Id);
        },
        getSortByValue(value) {
            switch (value) {
                case 'id':
                    return 'Id';
                case 'funeralHomeId':
                    return 'FuneralHomeId';
                case 'email':
                    return 'Email';
                case 'firstName':
                    return 'FirstName';
                case 'lastName':
                    return 'LastName';
                case 'userType':
                    return 'UserType';

                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getUsers() {
            this.$auth.getIdTokenClaims().then(response => {
                const _TOKEN = response.__raw;

                if (this.$auth.role.includes('SuperAdmin')) {
                    this.loading = true;

                    const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                    var params = {
                        search: this.search,
                        pageSize: itemsPerPage,
                        pageNumber: page ? page - 1 : 1,
                        sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                        sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                    };

                    this.axios
                        .create({
                            headers: { Authorization: `Bearer ${_TOKEN}` },
                        })
                        .get(process.env.VUE_APP_API + `/users/admin-search/`, { params: params })

                        .then(response => {
                            this.users = response.data.users;
                            this.total = response.data.total;
                        })

                        .catch(error => {
                            console.log(error);
                            this.showSnackbar({ message: 'Error retreiving results' });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
};
</script>
<style lang=""></style>

<template lang="">
    <div>
        <div v-if="mapDataFeaturesCount >= maxFeatures" class="text-right">
            <span> Showing last {{ mapDataFeaturesCount.toLocaleString() }} view points. </span>
        </div>
        <div style="position: relative">
            <div class="map-loading-container" v-if="!mapImage">
                <div style="position: relative">
                    <img
                        :src="mapPlaceholder"
                        style="object-fit: cover; height: 500px; max-width: 100%"
                        alt="Map Placeholder"
                    />

                    <div class="map-loading-overlay-inner">
                        <p>Loading...</p>
                        <v-progress-linear
                            class="m-3"
                            rounded
                            style="max-width: 400px"
                            indeterminate
                        ></v-progress-linear>
                    </div>
                </div>
            </div>

            <section class="pdf-item">
                <MglMap
                    id="map"
                    class="map-container"
                    :accessToken="mapboxSettings.accessToken"
                    :mapStyle="mapboxSettings.mapStyle"
                    :center="mapboxSettings.centerCoords"
                    :zoom="mapboxSettings.zoomLevel"
                    :preserveDrawingBuffer="true"
                >
                </MglMap>
            </section>
        </div>
    </div>
</template>
<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox';
import mapPlaceholder from '@/assets/images/map-placeholder.png';
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { debounce } from 'lodash';

export default {
    props: {
        serviceSlug: {
            type: String,
            required: false,
        },
        funeralHomeId: {
            type: Number,
            required: false,
        },
        interval: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            reqired: false,
        },
    },
    watch: {
        interval(newVal) {
            this.mapImages[this.interval] = null;
            this.debouncedRefreshMapData(newVal);
        },
        mapImage(newVal) {
            this.$emit('map-image-update', newVal);
        },
        startDate() {
            if (this.interval == 'custom') {
                this.mapImages[this.interval] = null;
                this.$emit('map-image-update', null);
            }

            this.debouncedRefreshMapData(this.interval);
        },
        endDate() {
            if (this.interval == 'custom') {
                this.mapImages[this.interval] = null;
                this.$emit('map-image-update', null);
            }

            this.debouncedRefreshMapData(this.interval);
        },
    },
    data() {
        return {
            mapPlaceholder,
            map: null,
            mapImages: {},
            mapImage: null,
            mapboxSettings: {
                // zoomLevel: 3.4,
                zoomLevel: 4,
                centerCoords: [-99.203512, 37.462758],
                mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
                // accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                accessToken:
                    'pk.eyJ1IjoiaWFuY2RhdmlzNjQ4MSIsImEiOiJjbHd3NnV0N3UwczV2MmxwejJ5YnRxMGlqIn0.mr4Ef4BTCBhFSETwN1wvTg', // your access token. Needed if you using Mapbox maps
            },
            startTime: null,
            endTime: null,
            sourceId: 'viewers',
            dataLoaded: false,
            scrollZoomEnabled: false,
            mapDataFeaturesCount: 0,
            maxFeatures: 250000,
            mapData: {},
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initMap(mapId) {
            Mapbox.accessToken = process.env.VUE_APP_MAPBOX_KEY;
            const map = new Mapbox.Map({
                container: mapId,
                style: 'mapbox://styles/mapbox/streets-v11',
                // center: [-99.203512, 37.462758],
                center: [-97.703512, 38.562758],
                // zoom: 3.4,
                zoom: 3.5,
                preserveDrawingBuffer: true,
            });

            map.on('load', () => {
                this.onMapLoad(map);
            });

            map.on('click', 'clusters', this.onClustersClick);
            map.on('mouseenter', 'clusters', () => (map.getCanvas().style.cursor = 'pointer'));
            map.on('mouseleave', 'clusters', () => (map.getCanvas().style.cursor = ''));
            map.on('mouseover', 'unclustered-point', this.onPointHover);
            // map.on('data', this.onDataEvent);
            map.on('idle', this.refreshMapImage);

            return map;
        },
        toggleScrollZoom() {
            if (!this.map) return;

            if (this.map.scrollZoom._enabled) {
                this.map.scrollZoom.disable();
                this.scrollZoomEnabled = false;
            } else {
                this.map.scrollZoom.enable();
                this.scrollZoomEnabled = true;
            }
            this.$emit('scroll-zoom-enabled', this.scrollZoomEnabled);
        },
        async onMapLoad(map) {
            this.startTime = performance.now();
            this.map.scrollZoom.disable();
            map.addControl(new Mapbox.NavigationControl());
        },
        onClustersClick() {
            const features = this.map.queryRenderedFeatures(e.point, { layers: ['clusters'] });

            if (!features.length) return;

            const clusterId = features[0].properties.cluster_id;
            this.map.getSource(this.sourceId).getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;
                this.map.easeTo({ center: featers[0].geometry.coordinates, zoom });
            });
        },
        onPointHover(e) {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const city = e.features[0].properties.city;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            new Mapbox.Popup().setLngLat(coordinates).setHTML(`${city}`).addTo(this.map);
        },
        async refreshMapImage() {
            const source = this.map.getSource(this.sourceId);
            if (!source || !source._data || !source._data.features) return;

            const loadedFeaturesCount = source._data.features.length;
            this.mapDataFeaturesCount;

            if (loadedFeaturesCount != this.mapDataFeaturesCount) return;

            if (!this.mapImages[this.interval]) {
                this.mapImage = null;
                this.mapImage = await this.generateMapImage(this.map);
                this.mapImages[this.interval] = this.mapImage;
            } else {
                this.mapImage = this.mapImages[this.interval];
            }
        },
        clearSourceAndLayers(map, sourceId) {
            const layer1 = `${sourceId}-clusters`;
            const layer2 = `${sourceId}-cluster-count`;
            const layer3 = `${sourceId}-unclustered-point`;

            const layers = [layer1, layer2, layer3];

            layers.forEach(layer => {
                if (map.getLayer(layer)) {
                    map.removeLayer(layer);
                }
            });

            if (map.getSource(sourceId)) {
                map.removeSource(sourceId);
            }
        },

        addSourceAndLayers(map, sourceId, geoData) {
            /* const geoData = {
                type: 'FeatureCollection',
                crs: {
                    type: 'name',
                    properties: {
                        name: '',
                    },
                },
                features: dataPart,
            };
            */

            this.clearSourceAndLayers(map, sourceId);

            const layer1 = `${sourceId}-clusters`;
            const layer2 = `${sourceId}-cluster-count`;
            const layer3 = `${sourceId}-unclustered-point`;

            map.addSource(sourceId, {
                type: 'geojson',
                // type: 'vector',
                // url: 'mapbox://iancdavis6481.clwwf8iga0tnh1mo16uku62pb-8bv2a',

                // Point to GeoJSON data. This example visualizes all M1.0+ viewers
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: geoData,
                // data: 'https://memorysharedev.blob.core.windows.net/hls-test/mappedGeoJson.json',
                // data: url,
                // data: 'https://memorysharedev.blob.core.windows.net/hls-test/singlePointGeoJson.json',
                buffer: 0,
                cluster: true,
                // clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterMaxZoom: 22, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            });

            map.addLayer({
                id: layer1,
                type: 'circle',
                source: sourceId,
                // 'source-layer': 'double-point-geo',
                minZoom: '3',
                maxZoom: '9',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
                    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                },
            });

            map.addLayer({
                id: layer2,
                type: 'symbol',
                source: sourceId,
                // 'source-layer': 'double-point-geo',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                },
            });

            map.addLayer({
                id: layer3,
                type: 'circle',
                source: sourceId,
                // 'source-layer': 'double-point-geo',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#ff530d',
                    'circle-radius': 6,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#fff',
                },
            });

            const popup = new Mapbox.Popup({
                closeButton: false,
                closeOnClick: false,
            });

            map.on('mouseover', `${sourceId}-unclustered-point`, e => {
                const coordinates = e.features[0].geometry.coordinates.slice();

                const city = e.features[0].properties.city;

                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                if (city) {
                    popup.setLngLat(coordinates).setHTML(`${city}`).addTo(map);
                }
            });

            map.on('mouseleave', `${sourceId}-unclustered-point`, e => {
                popup.remove();
            });
        },
        async getFhMapData(funeralHomeId, interval = null) {
            if (!funeralHomeId) {
                this.showSnackbar({ message: 'Invalid Funeral Home Id', color: 'error' });
                return;
            }

            this.mapImage = null;
            this.mapDataFeaturesCount = 0;
            return this.axiosInstance
                .get('/dogs/map', {
                    params: { funeralHomeId: funeralHomeId, startDate: this.startDate, endDate: this.endDate },
                })

                .then(response => {
                    const geoData = {
                        type: 'FeatureCollection',
                        features: [],
                    };

                    if (response.data.features) {
                        geoData.features = response.data.features;
                        this.mapDataFeaturesCount = response.data.features.length;
                    }

                    this.mapData[this.interval] = geoData;

                    this.addSourceAndLayers(this.map, this.sourceId, geoData);
                    return response.data;
                });
        },
        async getServiceMapData(slug, interval = null) {
            if (!slug) {
                this.showSnackbar({ message: 'Invalid service', color: 'error' });
                return;
            }

            this.mapImage = null;
            this.mapDataFeaturesCount = 0;
            return this.axiosInstance
                .get(`/dogs/map/service/${slug}`, {
                    params: { startDate: this.startDate, endDate: this.endDate },
                })
                .then(response => {
                    const geoData = {
                        type: 'FeatureCollection',
                        features: [],
                    };

                    if (response.data.features) {
                        geoData.features = response.data.features;
                        this.mapDataFeaturesCount = response.data.features.length;
                    }

                    this.mapData[this.interval] = geoData;

                    this.addSourceAndLayers(this.map, this.sourceId, geoData);
                    return response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },

        refreshMapData(interval) {
            if (interval === 'custom') {
                this.handleCustomInterval();
            } else {
                this.handleRegularInterval(interval);
            }
        },
        handleCustomInterval() {
            this.mapImages['custom'] = null;

            if (this.serviceSlug) {
                this.getServiceMapData(this.serviceSlug, 'custom');
            } else if (this.funeralHomeId) {
                this.getFhMapData(this.funeralHomeId, 'custom');
            }
        },
        handleRegularInterval(interval) {
            if (this.mapData[interval] && this.mapImages[interval]) {
                this.useCachedData(interval);
            } else {
                this.fetchMapData(interval);
            }
        },
        useCachedData(interval) {
            this.mapImage = this.mapImages[interval];
            this.mapDataFeaturesCount = this.mapData[interval].features.length;
            this.addSourceAndLayers(this.map, this.sourceId, this.mapData[interval]);
        },
        fetchMapData(interval) {
            if (this.serviceSlug) {
                this.getServiceMapData(this.serviceSlug, interval);
            } else if (this.funeralHomeId) {
                this.getFhMapData(this.funeralHomeId, interval);
            }
        },
        async generateMapImage(map) {
            if (!map.loaded()) return;

            //Get Original Dimensions
            const originalHeight = this.map.getContainer().offsetHeight;

            //Set Custom Dimensions For Image Generation (used for PDF)
            map.getContainer().style.width = 1060 + 'px';
            map.getContainer().style.height = 500 + 'px';
            map.resize();

            const mapRenderPromise = new Promise((resolve, reject) => {
                // Wait for map to finish rendering after resize
                const handleRender = () => {
                    try {
                        const canvas = this.map.getCanvas();
                        const dataUrl = canvas.toDataURL('image/png');
                        this.mapImage = dataUrl;
                        this.mapImages[this.selectedInterval] = dataUrl;

                        // Restore Original Dimensions
                        map.getContainer().style.width = '100%';
                        map.getContainer().style.height = originalHeight + 'px';
                        map.resize();

                        // Remove the render event listener
                        map.off('render', handleRender);
                        resolve(dataUrl);
                    } catch (error) {
                        reject(error);
                    }
                };

                // Add the render event listener
                map.on('render', handleRender);
            });

            return await mapRenderPromise;
        },
        async setAuthToken() {
            const result = await this.$auth.getIdTokenClaims();
            this.token = result.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    created() {
        this.debouncedRefreshMapData = debounce(this.refreshMapData, 100);
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.map = this.initMap('map');

        if (this.serviceSlug) {
            this.getServiceMapData(this.serviceSlug, this.interval);
        } else if (this.funeralHomeId) {
            this.getFhMapData(this.funeralHomeId, this.interval);
        }
    },
    components: {
        MglMap,
        MglMarker,
        MglNavigationControl,
        CustomTooltip,
    },
};
</script>
<style lang="scss">
.map-loading-container {
    position: absolute;
    z-index: 2 !important;
    background: rgb(255, 255, 255);
    padding: 16px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-size: 2rem;
        font-weight: bold;
    }

    .map-loading-overlay-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: rgb(255, 255, 255, 0.8);
        padding: 16px;
    }
}
</style>

<template>
    <section class="mt-10 invoice-table">
        <div class="p-3" style="display: flex; justify-content: space-between">
            <h5>All Invoices</h5>
            <div v-if="$auth.role.includes('SuperAdmin')" class="text-right">
                <v-btn outlined small @click="changeInvoiceModal(1)">Invoice +</v-btn>
            </div>
        </div>

        <!-- START: DATA TABLE -->
        <v-data-table
            :headers="invoiceHeaders"
            :items="invoiceData"
            :loading="loading"
            :item-class="row_classes"
            loading-text="Getting Invoices ..."
            @click:row="handleRowClick"
            :options.sync="options"
            :server-items-length="totalInvoices"
            style="min-height: 0"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:item.amount="{ item }">
                {{ item.amount | formatMoney }}
            </template>

            <template v-slot:item.weblink="{ item }">
                <a
                    @click="
                        $event.stopPropagation();
                        genHostedURL(item.stripeId);
                    "
                    class="hyperlink"
                >
                    {{ item.stripePublicId }}
                </a>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip
                    v-if="item.stripeStatus == 'void' || item.stripeStatus == 'refunded'"
                    :color="item.stripeStatus == 'refunded' ? 'red' : ''"
                    dark
                    >{{ item.stripeStatus.charAt(0).toUpperCase() + item.stripeStatus.slice(1) }}</v-chip
                >
                <v-chip v-else :color="getStatusColor(item.status)" dark>
                    {{ item.status }}
                </v-chip>
            </template>

            <template v-slot:item.billingPeriod="{ item }">
                <div v-if="item.billingReason === 'subscription_create'">
                    <span v-if="item.amountDue !== 0"><v-chip color="#0dbaff" dark> Subscription Start </v-chip></span>
                    <span v-if="item.amountDue === 0"><v-chip color="purple" dark> Free Trial </v-chip></span>
                </div>

                <div v-else>
                    <span v-if="item.invoiceType === 0">{{ item.billingPeriod }}</span>
                    <span v-if="item.invoiceType === 1">
                        <v-chip color="orange" dark> Off Cycle </v-chip>
                    </span>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <a :href="item.actions.pdf" download @click="$event.stopPropagation()">
                                    <font-awesome-icon
                                        icon="fa-regular fa-arrow-down-to-bracket"
                                        style="font-size: 1rem"
                                    />

                                    <!-- <download-icon
                                        :color="item.actions.pdf === null ? 'grey' : ''"
                                        size="1.25x"
                                    ></download-icon
                                > -->
                                </a>
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Download</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="$auth.role.includes('SuperAdmin')" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <a
                                    :href="getStripeUrl(item.actions.stripeId)"
                                    target="_blank"
                                    @click="$event.stopPropagation()"
                                    style="font-size: 1.2rem"
                                >
                                    <font-awesome-icon icon="fa-brands fa-cc-stripe"
                                /></a>
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>View Invoice on Stripe</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="$auth.role.includes('SuperAdmin')" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    @click="deleteInvoiceConfirmation(item)"
                                    style="font-size: 1rem"
                                    icon="fa-regular fa-trash-can"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete Invoices</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-list"
                                    style="font-size: 1rem"
                                    @click.stop="showEvents($event, item, item.actions.events, item.month)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>View Invoice Events</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="$auth.role.includes('SuperAdmin')" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-pencil"
                                    style="font-size: 1rem"
                                    @click.stop="editSuperAdminInvoice(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit Invoice</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>
        <!-- END: DATA TABLE -->

        <!-- START: Add Invoice Dialog1 -->
        <v-dialog
            transition="scroll-x-reverse-transition"
            v-model="invoiceModal1"
            max-width="500"
            @click:outside="resetSelectedCharge"
        >
            <v-card class="p-3">
                <v-card-title style="word-break: break-word">What type of charge would you like to apply?</v-card-title>
                <div class="ml-3 mr-3 pb-3">
                    <!-- <v-list flat subheader> -->
                    <!-- <v-subheader>Line Items</v-subheader> -->

                    <!-- <v-list-item-group v-model="selectedCharge.lineItems" multiple> -->
                    <v-subheader class="pl-3">Types</v-subheader>
                    <div style="display: flex; justify-content: space-evenly; flex-wrap: wrap; gap: 10px">
                        <!-- <v-list-item :value="item">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active" color="primary"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content
                                            style="display: flex; justify-content: space-between"
                                            :class="!selectedCharge.lineItems.includes(item) ? 'gray' : ''"
                                        >
                                            <span>
                                                {{ item.label }}
                                            </span>

                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <div>
                                    <vuetify-money
                                        :disabled="!selectedCharge.lineItems.includes(item)"
                                        v-model="item.amount"
                                        :options="currencyOptions"
                                    ></vuetify-money>
                                </div> -->
                        <v-chip
                            v-for="(item, index) in chargeTypes"
                            @click="addBillingEvent(item)"
                            :key="index"
                            label
                            :color="eventTypes[item.eventType].color"
                            dark
                            >{{ eventTypes[item.eventType].label }}</v-chip
                        >
                    </div>
                    <div class="pb-3">
                        <v-subheader>Line Items</v-subheader>
                        <div class="text-center" v-if="selectedCharge.lineItems.length == 0">
                            <span style="color: gray">Add line items to continue</span>
                        </div>
                        <div v-else>
                            <div
                                v-for="(item, index) in selectedCharge.lineItems"
                                style="display: flex; justify-content: space-between; align-items: center; height: 50px"
                            >
                                <span class="ml-3"
                                    ><v-chip
                                        label
                                        outlined
                                        v-if="item.eventType >= 0"
                                        :color="eventTypes[item.eventType].color"
                                        dark
                                        >{{ eventTypes[item.eventType].label }}</v-chip
                                    ></span
                                >
                                <div style="display: flex; align-items: center; height: 50px">
                                    <vuetify-money
                                        style="width: 80px"
                                        v-model="item.amount"
                                        :options="currencyOptions"
                                    ></vuetify-money>
                                    <font-awesome-icon
                                        @click="removeBillingEvent(index)"
                                        class="hover-trash ml-2"
                                        icon="fa-regular fa-trash-can"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </v-list-item-group> -->
                    <!-- </v-list> -->
                    <!-- <v-switch class="pl-4" v-model="selectedCharge.chargeAutomatically" label="AutoPay"></v-switch> -->
                    <div class="mt-3 btn-flex-div">
                        <v-btn text @click="changeInvoiceModal()">Cancel</v-btn>
                        <v-btn
                            class="submit-btn"
                            color="#0c3c60"
                            :disabled="disableChargeNext"
                            :dark="!disableChargeNext"
                            @click="changeInvoiceModal(2)"
                            >Next</v-btn
                        >
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Add Invoice Dialog1 -->

        <!-- START: Add Invoice Dialog2  -->
        <v-dialog
            transition="scroll-x-reverse-transition"
            v-model="invoiceModal2"
            max-width="500"
            @click:outside="resetSelectedCharge"
        >
            <v-card class="p-3">
                <div class="p-3 text-center" v-if="submittingInvoice">
                    <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>
                    <div class="mt-5">Submiting Invoice</div>
                </div>
                <div v-else>
                    <div
                        class="mr-3"
                        style="display: flex; justify-content: space-between; align-items: center; height: 60px"
                    >
                        <h5 class="pl-3">Details</h5>
                        <v-switch
                            class="pl-4 v-input--reverse"
                            v-model="selectedCharge.chargeAutomatically"
                            label="AutoPay"
                        ></v-switch>
                    </div>

                    <div>
                        <v-textarea
                            auto-grow
                            height="50"
                            class="mx-3 mt-3"
                            @keydown.enter="changeInvoiceModal(2)"
                            label="Invoice Description"
                            v-model="selectedCharge.description"
                        />

                        <div class="px-3 mb-3">
                            <div style="display: flex; justify-content: space-between">
                                <p>Line Items</p>
                                <v-menu ref="chargeMenu" :close-on-content-click="false" :offset-y="true">
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <span v-bind="attrs" v-on="{ ...menu }">
                                            <v-btn small dark>Add +</v-btn>
                                        </span>
                                    </template>
                                    <v-list class="p-3" style="width: 300px">
                                        <div v-for="item in chargeTypes">
                                            <v-list-item @click="addBillingEvent(item)">
                                                {{ item.label }}
                                            </v-list-item>
                                        </div>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div v-for="(item, index) in selectedCharge.lineItems" :key="index">
                                <div style="display: flex; justify-content: space-between; align-items: center">
                                    <v-chip
                                        label
                                        outlined
                                        v-if="item.eventType >= 0"
                                        :color="eventTypes[item.eventType].color"
                                        dark
                                        >{{ eventTypes[item.eventType].label }}</v-chip
                                    >
                                    <!-- <span>
                                    {{ (item.amount * 100) | formatMoney }}
                                </span> -->

                                    <div class="hover-chip">
                                        <!-- <font-awesome-icon class="mr-2" icon="fa-regular fa-trash-can" /> -->

                                        <v-chip
                                            v-if="item.eventType >= 0 && item.editing == false"
                                            @click="item.editing = true"
                                            label
                                            outlined
                                            :color="eventTypes[item.eventType].color"
                                            dark
                                            >{{ (item.amount * 100) | formatMoney }}</v-chip
                                        >

                                        <vuetify-money
                                            v-if="item.editing"
                                            style="width: 80px"
                                            v-model="item.amount"
                                            :options="currencyOptions"
                                        ></vuetify-money>
                                        <v-btn
                                            color="primary"
                                            class="ml-2"
                                            small
                                            @click="item.editing = false"
                                            v-if="item.editing"
                                            >Save</v-btn
                                        >
                                        <font-awesome-icon
                                            @click="removeBillingEvent(index)"
                                            class="hover-trash"
                                            icon="fa-regular fa-trash-can"
                                        />
                                    </div>
                                </div>

                                <v-text-field
                                    :color="eventTypes[item.eventType].color"
                                    class="mt-4"
                                    dense
                                    label="Description"
                                    v-model="item.description"
                                ></v-text-field>
                            </div>
                        </div>

                        <!-- <vuetify-money v-model="selectedCharge.amount" :options="currencyOptions"></vuetify-money> -->
                        <div class="mr-3 btn-flex-div">
                            <v-btn text @click="changeInvoiceModal(1)">Back</v-btn>
                            <v-btn
                                class="submit-btn"
                                :dark="!disableChargeSubmit"
                                :disabled="disableChargeSubmit"
                                @click="submitNewInvoice"
                                >Submit</v-btn
                            >
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- END: Add Invoice Dialog2  -->

        <!--START: EVENT DIALOG -->
        <v-dialog v-model="eventDialog.show" max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ eventDialog.title }}</span>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="eventDialog.table.headers"
                        :items="eventDialog.table.events"
                        loading-text="Getting invoice"
                    >
                        <template v-slot:item.event="{ item }">
                            <v-chip :color="eventTypes[item.eventType].color" dark>{{
                                eventTypes[item.eventType].label
                            }}</v-chip>
                        </template>

                        <template v-slot:item.amount="{ item }">
                            <span>{{ item.amount | formatMoney }}</span>
                        </template>

                        <template v-slot:item.date="{ item }">
                            <p class="pt-6 pb-6 mb-0">
                                <!-- {{ getLocalDate(item.reportedDate) | moment('l') }} -->

                                {{ $moment($moment.utc(item.reportedDate)).local().format('l') }}
                            </p>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--END: EVENT DIALOG -->

        <!--START: EVENT SUBSCRIPTOR DIALOG -->
        <v-dialog v-model="eventSubscriptorDialog.show" max-width="900px">
            <v-card>
                <v-card-text
                    v-if="eventSubscriptorDialog.content"
                    style="padding: 3rem; font-size: 1.1rem; text-align: center"
                >
                    {{ eventSubscriptorDialog.content }}
                </v-card-text>
                <v-card-text v-else style="padding: 3rem; font-size: 1.5rem; text-align: center">
                    No description available for this invoice
                </v-card-text>

                <div class="p-3 pt-0" v-if="eventSubscriptorDialog.events.length > 0">
                    <!-- {{ eventSubscriptorDialog.events }} -->
                    <v-data-table :items="eventSubscriptorDialog.events" :headers="eventSubscriptorHeaders">
                        <template v-slot:item.eventType="{ item }">
                            <v-chip :color="eventTypes[item.eventType].color" dark>{{
                                eventTypes[item.eventType].label
                            }}</v-chip>
                        </template>

                        <template v-slot:item.amount="{ item }">
                            <span>{{ item.amount | formatMoney }}</span>
                        </template>
                        <template v-slot:item.reportedDate="{ item }">
                            <p class="pt-6 pb-6 mb-0">
                                <!-- {{ getLocalDate(item.reportedDate) | moment('l') }} -->

                                {{ $moment($moment.utc(item.reportedDate)).local().format('l') }}
                            </p>
                        </template>
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
        <!--END: EVENT SUBSCRIPTOR DIALOG -->

        <!--START: INVOICE DELETE DIALOG -->
        <v-dialog v-model="deleteDialoge" max-width="400px">
            <v-card style="padding: 1rem; text-align: center">
                <h1>Warning!</h1>
                <p>Are you sure you want to delete this invoice?</p>
                <p>
                    Deleting an invoice does not delete the invoice in Stripe, and has no effect on the actual invoice,
                    any payment or subscription status. Deleting only removes the invoice from this table.
                </p>

                <div style="display: flex; gap: 2rem; justify-content: center">
                    <v-btn @click="deleteDialoge = false">Cancel</v-btn>
                    <v-btn color="red" dark @click="deleteInvoice">Yes</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!--END: INVOICE DELETE DIALOG -->

        <!-- START: INVOICE SUPER ADMIN -->
        <v-dialog v-model="invoiceSuperAdminDialog" width="800">
            <invoice-super :invoice="selectedInvoice" @close="handleInvoiceEditClose" />
        </v-dialog>
        <!-- END: INVOICE SUPER ADMIN -->
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { eventTypes } from '@/constants';
import InvoiceSuper from '@/components/ManageService/super-admin/InvoiceSuper';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'InvoiceTable',
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            invoiceSuperAdminDialog: false,
            invoiceHeaders: [],
            invoiceData: [],
            options: {
                // sortBy: 'stripePublicId',
            },
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            totalInvoices: 0,
            loading: true,
            eventDialog: {
                show: false,
                title: 'Dialog Title',
                loading: false,
                table: {
                    headers: [
                        { text: 'ID', value: 'id' },
                        { text: 'Date', value: 'date' },
                        { text: 'Event', value: 'event' },
                        { text: 'Amount', value: 'amount', width: '13%' },
                        { text: 'Description', value: 'description' },
                    ],
                    events: [],
                },
            },
            eventSubscriptorDialog: {
                show: false,
                title: 'Dialog Title',
                content: '',
                events: [],
            },
            eventSubscriptorHeaders: [
                { text: 'ID', value: 'id' },
                { text: 'Date', value: 'reportedDate' },
                { text: 'Line Item', value: 'eventType' },
                { text: 'Amount', value: 'amount', width: '13%' },
                { text: 'Description', value: 'description' },
            ],
            deleteDialoge: false,
            selectedInvoice: {
                id: null,
            },
            eventTypes,
            invoiceModal1: false,
            invoiceModal2: false,
            selectedCharge: {
                description: '',
                lineItems: [],
                chargeAutomatically: true,
            },
            chargeHeaders: [
                { text: 'Name', value: 'name', align: 'center' },
                { text: 'Amount', value: 'amount', align: 'center' },
                { text: 'Selected', value: 'selected', align: 'center' },
            ],
            chargeTypes: [
                { label: 'Onboarding Fee', amount: 249, eventType: 4, editing: false, description: '' },
                { label: 'Equipment Charge', amount: 999, eventType: 5, editing: false, description: '' },
                { label: 'Custom Edit', amount: 59, eventType: 6, editing: false, description: '' },
                { label: 'Misc', amount: 99, eventType: 8, editing: false, description: '' },
            ],
            selectedChargeTypes: [],
            submittingInvoice: false,
        };
    },
    components: {
        InvoiceSuper,
        CustomTooltip,
    },
    computed: {
        disableChargeSubmit() {
            if (
                this.selectedCharge.description.length == 0 ||
                this.selectedCharge.lineItems.length == 0 ||
                this.isEditing
            ) {
                return true;
            } else {
                return false;
            }
        },
        disableChargeNext() {
            if (this.selectedCharge.lineItems == 0) {
                return true;
            } else {
                return false;
            }
        },
        isEditing() {
            for (let i = 0; i < this.selectedCharge.lineItems.length; i++) {
                if (this.selectedCharge.lineItems[i].editing == true) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        removeBillingEvent(index) {
            this.selectedCharge.lineItems.splice(index, 1);
        },
        addBillingEvent(item) {
            let temp = JSON.stringify(item);
            let data = JSON.parse(temp);
            this.selectedCharge.lineItems.push(data);
            if (this.$refs.chargeMenu) {
                this.$refs.chargeMenu.save();
            }
        },
        changeInvoiceModal(i) {
            this.invoiceModal1 = false;
            this.invoiceModal2 = false;
            if (i == 1) this.invoiceModal1 = true;
            if (i == 2) this.invoiceModal2 = true;
        },
        submitNewInvoice() {
            let tempArr = JSON.stringify(this.selectedCharge);
            let data = JSON.parse(tempArr);

            data.lineItems.forEach(item => {
                item.amount = parseInt(item.amount) * 100;
                item.funeralHomeId = parseInt(this.$route.params.id);
            });

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;

                // const paramString = `funeralHomeId=${this.$route.params.id}&description=${
                //     this.selectedCharge.description
                // }&amount=${parseInt(this.selectedCharge.amount * 100)}&eventType=${this.selectedCharge.enum}`;
                this.submittingInvoice = true;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .post(
                        process.env.VUE_APP_API +
                            `/billing/events-add?funeralHomeId=${this.$route.params.id}&description=${data.description}&autoPay=${data.chargeAutomatically}`,
                        data.lineItems,
                    )
                    .then(response => {
                        this.showSnackbar({ message: 'Invoice added' });
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    })
                    .finally(() => {
                        this.submittingInvoice = false;
                        this.resetSelectedCharge();
                        this.changeInvoiceModal();
                        this.$emit('refreshInvoice');
                        this.getInvoices();
                    });
            });
        },
        resetSelectedCharge() {
            this.selectedCharge = {
                description: '',
                lineItems: [],
                chargeAutomatically: true,
            };
        },
        offCycleEvent(item) {
            if ((item.value >= 4 && item.value <= 6) || item.value == 8) {
                return true;
            } else {
                return false;
            }
        },
        selectCharge(charge) {
            this.selectedCharge = { ...charge, description: '', amount: charge.amount / 100 };
            this.invoiceModal1 = false;
            this.invoiceModal2 = true;
        },
        genHostedURL(stripeId) {
            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` } })
                    .get(process.env.VUE_APP_API + `/billing/invoices/link/${stripeId}`)
                    .then(response => {
                        window.open(response.data, '_blank');
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
        deleteInvoiceConfirmation: function (item) {
            this.deleteDialoge = true;
            this.selectedInvoice = item;
        },
        handleInvoiceEditClose: function () {
            this.invoiceSuperAdminDialog = false;
            this.getInvoices();
        },
        deleteInvoice: function () {
            this.deleteDialoge = false;

            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({
                        headers: { Authorization: `Bearer ${token}` },
                        baseURL: process.env.VUE_APP_API,
                    })
                    .delete('/billing/invoices/' + this.selectedInvoice.id)
                    .then(() => this.getInvoices())
                    .catch(error => {
                        this.showSnackbar({ message: 'Error deleting invoice', color: 'error' });
                    })
                    .then(() => (this.selectedInvoice = {}));
            });
        },
        editSuperAdminInvoice: function (item) {
            this.invoiceSuperAdminDialog = true;
            this.selectedInvoice = item;
        },
        row_classes(item) {
            if (item.status === 'Overdue' && item.stripeStatus !== 'void' && !this.$auth.role.includes('SuperAdmin')) {
                return 'focused-row';
            }
        },
        getInvoices: function () {
            this.loading = true;

            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;

                const { page, itemsPerPage, sortBy, sortDesc } = this.options;

                this.axios
                    .create({
                        headers: { Authorization: `Bearer ${token}` },
                        baseURL: process.env.VUE_APP_API,
                    })
                    .get(
                        this.$props.apiUrl +
                            `&pageSize=${itemsPerPage}&pageNumber=${page - 1}&sortAsc=${
                                sortBy[0] ? !sortDesc[0] : false
                            }&sortBy=${this.getSortBy()}`,
                    )
                    .then(response => {
                        this.totalInvoices = response.data.total;
                        this.invoiceData = response.data.invoices.map(invoice => {
                            const periodStart = this.$moment(invoice.periodStart).format('MM/DD/YY');
                            const periodEnd = this.$moment(invoice.periodEnd).format('MM/DD/YY');

                            const isPaid = invoice.amountDue === invoice.amountPaid;
                            let status = 'Overdue';
                            if (isPaid) status = 'Paid';
                            if (!isPaid && new Date(invoice.dueDate).getTime() > new Date().getTime()) status = 'Open';

                            return {
                                ...invoice,
                                month: this.$moment(invoice.periodStart).format('MMMM'),
                                amount: invoice.amountDue,
                                status: status,
                                billingPeriod: `${periodStart}–${periodEnd}`,
                                actions: {
                                    pdf: invoice.pdfLink,
                                    stripeLink: invoice.stripeLink,
                                    events: invoice.events.reverse(),
                                    stripeId: invoice.stripeId,
                                },
                            };
                        });
                    })
                    .catch(error => {})
                    .then(() => (this.loading = false));
            });
        },
        getStatusColor(status) {
            if (status === 'Paid') return 'green';
            if (status === 'Overdue') return 'red';
            if (status === 'Open') return 'blue';
        },
        getStripeUrl(stripeId) {
            return `${process.env.VUE_APP_STRIPE_URL}/invoices/${stripeId}`;
        },
        getSortBy() {
            const { sortBy } = this.options;
            let sortString = sortBy[0] ? sortBy[0] : null;

            if (sortString) {
                switch (sortString) {
                    default:
                        return 'StripePublicId';
                }
            } else {
                return 'StripePublicId';
            }
        },
        /* Note! The 'event' param is the JavaScript event object.
         * It has nothing to do with invoice events, but is the first arg passed to
         * an event handler by default, so we have to include it
         */
        showEvents(event, item, invoiceEvents, billingMonth) {
            if (item.invoiceType === 0) {
                this.eventDialog.loading = true;
                this.eventDialog.show = true;
                this.eventDialog.title = `${billingMonth} Invoice Summary`;
                this.eventDialog.table.events = invoiceEvents;
                this.eventDialog.loading = false;
            }

            if (item.invoiceType === 1) {
                this.eventSubscriptorDialog.show = true;
                this.eventSubscriptorDialog.content = item.description;

                if (item.events.length > 0) {
                    this.eventSubscriptorDialog.events = item.events;
                } else {
                    this.eventSubscriptorDialog.events = [];
                }
            }
        },

        handleRowClick(row, e) {
            return;
            this.showEvents(e, row, row.actions.events, row.month);
        },
    },
    mounted() {
        this.invoiceHeaders = [
            { text: 'Invoice #', value: 'weblink' },
            { text: 'Month', value: 'month' },
            { text: 'Amount', value: 'amount' },
            { text: 'Status', value: 'status' },
            { text: 'Billing Period', value: 'billingPeriod' },
            { text: 'Actions', value: 'actions', align: 'center' },
        ];

        this.options = {
            ...this.options,
            page: 0,
            pageSize: 3,
        };
    },
    watch: {
        options: {
            handler() {
                this.getInvoices();
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.hover-chip {
    // border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: 0.5s;
    margin-right: -14px;

    .hover-trash {
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;
    }
}
.hover-chip:hover {
    margin-right: 0;

    transition: 0.5s;

    .hover-trash {
        margin-left: 10px;
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
    }
}
.hover-trash {
    visibility: visible;
    transition: 0.5s;
}
.hover-trash:hover {
    color: #ff5252;
    cursor: pointer;
}
.hidden {
    display: none;
}
.gray {
    color: #bbb;
}
.invoice-table {
    // border: 2px solid #ff530d;
    // border-radius: 10px;

    .hyperlink {
        color: #0dbaff !important;
        text-decoration: underline;
        transition: 0.3s;
    }
    .hyperlink:hover {
        color: #00516e !important;
        transition: 0.3s;
    }
    .action-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .action-list a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .focused-row {
        border: 2px solid #ff6a00;
        box-shadow: 0 0 10px #ff6a00;
    }
}

.btn-flex-div {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 768px) {
    .btn-flex-div {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        .submit-btn {
            margin-bottom: 10px;
        }
    }
}
</style>

<template>
    <AppLayout :has-background="true">
        <div style="padding: 2rem; background: white">
            <calendar date v-model="time" label="Event Time" :output-local="true" />
            <p style="color: black">{{ time }}</p>
            <calendar date v-model="timelocal" label="Event Time Local" :output-local="true" />
            <p style="color: black">{{ timelocal }}</p>
        </div>
    </AppLayout>
</template>
<script>
import Calendar from '@/components/ui/Calendar.vue';

export default {
    data() {
        return {
            time: null,
            timelocal: '2022-06-01T00:00:00.000',
        };
    },
    components: {
        Calendar,
    },
};
</script>

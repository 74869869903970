<template>
    <div>
        <!-- START: Migration Button -->
        <v-btn class="ml-4" @click="migrationModal = true">
            <font-awesome-icon icon="fa-regular fa-user-plus" style="margin-right: 0.5rem" />
            <span>Migrate Home</span>
        </v-btn>
        <!-- END: Migration Button -->

        <!-- START: Migration Modal -->
        <v-dialog v-model="migrationModal" persistent max-width="720">
            <v-card>
                <div style="padding: 2rem">
                    <h3 style="text-align: center; margin-bottom: 2rem">Migrate Home</h3>

                    <!-- START: Step O -->
                    <section v-if="migrationStep === 0">
                        <v-form
                            class="form-container p-0"
                            ref="form"
                            lazy-validation
                            :disabled="showPreviewQueryPercentage"
                        >
                            <v-row class="mt-0">
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        v-model="migrationForm.funeralHomeId"
                                        label="Funeral Home ID"
                                        :rules="[v => !!v || 'Funeral home id is required']"
                                    />
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        hint="For example, sub_xxxxx"
                                        v-model="migrationForm.stripeSubscriptionId"
                                        label="Stripe Subscription Id"
                                    />
                                </v-col>
                                <v-col>
                                    <v-select
                                        return-object
                                        class="payment_collection"
                                        v-model="migrationForm.timeZone"
                                        :items="offsetOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Timezone Offset"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </section>
                    <!-- END: Step O -->

                    <!-- START: Step 1 -->
                    <section v-if="migrationStep === 1">
                        <h5 style="margin-top: 1rem; font-size: 1.1rem">
                            Import Existing Home | {{ migrationPreviewData.funeralHomeName }}
                        </h5>

                        <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Records</th>
                                        <th class="text-left">Items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="migrationPreviewData.noOfAnalyticFound > 0">
                                        <td>Analytics Found</td>
                                        <td>{{ migrationPreviewData.noOfAnalyticFound }}</td>
                                    </tr>

                                    <tr v-if="migrationPreviewData.noOfAnalyticInserted > 0">
                                        <td>Analytics Inserted</td>
                                        <td>{{ migrationPreviewData.noOfAnalyticInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfEventInserted > 0">
                                        <td>Events Inserted</td>
                                        <td>{{ migrationPreviewData.noOfEventInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfLiveStreamFound > 0">
                                        <td>Live Streams Found</td>
                                        <td>{{ migrationPreviewData.noOfLiveStreamFound }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfLiveStreamInserted > 0">
                                        <td>Live Streams Inserted</td>
                                        <td>{{ migrationPreviewData.noOfLiveStreamInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfPdfFound > 0">
                                        <td>PDFs Found</td>
                                        <td>{{ migrationPreviewData.noOfPdfFound }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfPdfInserted > 0">
                                        <td>PDFs Inserted</td>
                                        <td>{{ migrationPreviewData.noOfPdfInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfServiceFound > 0">
                                        <td>Services Found</td>
                                        <td>{{ migrationPreviewData.noOfServiceFound }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfServiceInserted > 0">
                                        <td>Services Inserted</td>
                                        <td>{{ migrationPreviewData.noOfServiceInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfUserFound > 0">
                                        <td>Users Found</td>
                                        <td>{{ migrationPreviewData.noOfUserFound }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfUserInserted > 0">
                                        <td>Users Inserted</td>
                                        <td>{{ migrationPreviewData.noOfUserInserted }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfVideoFound > 0">
                                        <td>Videos Found</td>
                                        <td>{{ migrationPreviewData.noOfVideoFound }}</td>
                                    </tr>
                                    <tr v-if="migrationPreviewData.noOfVideoInserted > 0">
                                        <td>Videos Inserted</td>
                                        <td>{{ migrationPreviewData.noOfVideoInserted }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </section>
                    <!-- END: Step 1 -->

                    <!-- START: Step 2 -->
                    <section
                        v-if="migrationStep === 2 && !migrationSuccess"
                        style="display: flex; justify-content: center; align-items: center; flex-direction: column"
                    >
                        <v-progress-circular
                            :rotate="-90"
                            :value="migrationPercentage"
                            color="#FF530D"
                            :size="100"
                            :width="15"
                        />
                        <h2 style="font-size: 1.3rem; margin-top: 1rem">Migration In Progress</h2>
                        <p>Please do not refresh</p>
                    </section>
                    <section
                        v-if="migrationStep === 2 && migrationSuccess"
                        style="display: flex; justify-content: center; align-items: center; flex-direction: column"
                    >
                        <h2 style="font-size: 1.3rem; margin-top: 1rem">Migration Successful!</h2>
                    </section>
                    <!-- END: Step 2 -->
                </div>

                <v-card-actions>
                    <div v-if="migrationStep === 0" style="display: flex; width: 100%">
                        <span style="flex: 1"></span>
                        <v-btn color="gdarken-1" text @click="closeModal()">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="moveToMigrationPreview()">
                            <v-progress-circular
                                v-if="showPreviewQueryPercentage"
                                :rotate="360"
                                :value="previewQueryPercentage"
                                color="#FF530D"
                            />
                            <span v-if="!showPreviewQueryPercentage">Next</span>
                        </v-btn>
                    </div>

                    <div v-if="migrationStep === 1" style="display: flex; width: 100%">
                        <span style="flex: 1"></span>
                        <v-btn color="gdarken-1" text @click="closeModal()">Cancel</v-btn>
                        <v-btn
                            color="blue darken-1"
                            :disabled="disableStartButton"
                            :loading="disableStartButton"
                            text
                            @click="handleStartMigration()"
                        >
                            Import Funeral Home
                        </v-btn>
                    </div>

                    <div v-if="migrationStep === 2 && migrationSuccess" style="display: flex; width: 100%">
                        <span style="flex: 1"></span>
                        <v-btn color="gdarken-1" text @click="closeModal()">Close</v-btn>
                    </div>

                    <!-- <v-progress-circular v-if="loadder" :rotate="360" :value="value" color="#FF530D"> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END: Migration Modal -->
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            migrationForm: {},
            migrationModal: false,
            migrationPreviewData: {},
            migrationStep: 0,
            migrationSuccess: false,
            migrationPercentage: 0,
            previewQueryPercentage: 0,
            showPreviewQueryPercentage: false,
            token: '',
            disableStartButton: false,
            offsetOptions: [
                { label: 'EDT', value: -4 },
                { label: 'CDT', value: -5 },
                { label: 'MDT', value: -6 },
                { label: 'PDT', value: -7 },
            ],
        };
    },
    methods: {
        closeModal: function () {
            this.migrationStep = 0;
            this.migrationModal = false;
            this.migrationForm = {};
            this.migrationPreviewData = {};
        },
        moveToMigrationPreview: function () {
            const payload = {
                funeralHomeId: parseInt(this.migrationForm.funeralHomeId),
                stripeSubscriptionId: this.migrationForm.stripeSubscriptionId,
                dataInsert: 0,
                timeZone: this.migrationForm.timeZone.value,
            };

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/Migration`, payload)
                    .then(response => {
                        this.showPreviewQueryPercentage = true;
                        this.handleGrabMigrationPreview(payload.funeralHomeId);
                    });
            });
        },
        handleGrabMigrationPreview: function (funeralHomeId) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Migration/status/${funeralHomeId}?preview=true`)
                .then(response => {
                    if (response.data.percentage < 100) {
                        this.previewQueryPercentage = response.data.percentage;
                        setTimeout(() => {
                            this.handleGrabMigrationPreview(funeralHomeId);
                        }, 3500);
                    } else {
                        this.migrationPreviewData = response.data;
                        this.showPreviewQueryPercentage = false;
                        this.migrationStep = 1;
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.handleGrabMigrationPreview(funeralHomeId);
                    }, 3500);
                });
        },
        handleStartMigration: function () {
            this.disableStartButton = true;
            this.showMigrationPercentage = true;
            this.migrationStep = 2;

            const payload = {
                funeralHomeId: parseInt(this.migrationForm.funeralHomeId),
                stripeSubscriptionId: this.migrationForm.stripeSubscriptionId,
                dataInsert: 1,
                timeZone: this.migrationForm.timeZone.value,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Migration`, payload)
                .then(response => {
                    this.monitorMigration(payload.funeralHomeId);
                    this.disableStartButton = false;
                });
        },
        monitorMigration: function (funeralHomeId) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Migration/status/${funeralHomeId}?preview=false`)
                .then(response => {
                    if (response.data.percentage) {
                        this.migrationPercentage = response.data.percentage;
                        setTimeout(() => {
                            this.monitorMigration(funeralHomeId);
                        }, 3500);
                    } else {
                        this.migrationSuccess = true;
                        setTimeout(() => {
                            this.closeModal();
                            this.$emit('successfulMigration');
                        }, 3000);
                    }
                });
        },
    },
};
</script>

<template>
    <div>
        <div v-if="dragging" id="upload-overlay-text">
            <div class="mb-2">
                <font-awesome-icon style="font-size: 4rem" icon="fa-regular fa-cloud-arrow-up"></font-awesome-icon>
            </div>
            <h3>Drop files here to upload</h3>
        </div>
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import DropTarget from '@uppy/drop-target';

import '@uppy/core/dist/style.css';
import '@uppy/drop-target/dist/style.css';

export default {
    data() {
        return {
            uppy: null,
            dragging: false,
            tempFiles: [],
        };
    },
    watch: {
        tempFiles() {
            if (this.tempFiles.length > 0) {
                this.$emit('files-changed', this.tempFiles);
                this.dragging = false;
                this.uppy.cancelAll();
            }
        },
    },
    methods: {
        reset() {
            if (this.uppy) {
                this.uppy.cancelAll();
            }
        },
        initUppy() {
            // const target = document.getElementById('ms-main-content-container');
            this.uppy = new Uppy()
                .use(DropTarget, {
                    target: document.body,
                    // target: target,
                    onDragOver: event => {
                        if (this.dragging == false) {
                            this.dragging = true;
                        }
                    },
                    onDragLeave: event => {
                        if (this.dragging) {
                            this.dragging = false;
                        }
                    },
                    // onDrop: event => {
                    //     this.dragging = false;
                    // },
                })

                .on('file-added', file => {
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                });
        },
        destroyUppy() {
            if (this.uppy) {
                this.uppy.close();
            }
        },
        mountTextOverlay() {
            const overlay = document.getElementById('upload-overlay-text');
            if (overlay) {
                overlay.remove();
                document.body.appendChild(overlay);
            }
        },
        destroyTextOverlay() {
            const overlay = document.getElementById('upload-overlay-text');
            if (overlay) {
                overlay.remove();
            }
        },
    },
    mounted() {
        this.initUppy();
        this.mountTextOverlay();
    },
    beforeDestroy() {
        if (this.uppy) {
            this.uppy.close();
        }
        this.destroyTextOverlay();
    },
};
</script>
<style lang="scss">
.uppy-is-drag-over::after {
    background-color: transparent;
    border: none;
}

#upload-overlay-text {
    position: fixed;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    margin: auto;
    background-color: rgba(50, 183, 255, 0.4);
    border: 3px dashed rgb(50, 183, 255);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: white;
}
</style>

<template>
    <div>
        <v-dialog v-model="dialog" width="600">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="s" dark v-bind="attrs" v-on="on"> + Create Credit Type </v-btn>
            </template>
            <v-card style="padding: 3rem">
                <h2>New Credit Type</h2>

                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="newCreditType" label="Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <!-- <v-text-field v-model="suggestedAmount" label="Suggested Amount"></v-text-field> -->
                            <vuetify-money
                                :options="currencyOptions"
                                v-model="suggestedAmount"
                                label="Suggested Amount"
                            ></vuetify-money>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn color="success" style="width: 100%" @click="createCreditType">Create</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    metaInfo: {
        title: 'Create Credit',
    },
    data() {
        return {
            carryOver: false,
            newCreditType: '',
            suggestedAmount: 0,
            dialog: false,
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),

        createCreditType() {
            let data = {
                name: this.newCreditType,
                carryOver: this.carryOver,
                suggestedAmount: parseInt(this.suggestedAmount * 100),
            };

            this.axiosInstance
                .post(`/Credit/type`, data)
                .then(response => {
                    this.dialog = false;
                    this.showSnackbar({ message: 'Credit Created' });
                    this.$emit('refresh');
                })
                .catch(error => {
                    this.errorMessage = error.response.data;
                    this.showSnackbar({ message: 'Error creating credit' });
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
};
</script>

<style></style>

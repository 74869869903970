<template>
    <div id="g-recaptcha" class="g-recaptcha" data-size="invisible" :data-sitekey="sitekey"></div>
</template>
<script>
export default {
    data() {
        return {
            widgetId: 0,
            sitekey: '',
        };
    },
    mounted() {
        this.injectRecaptchaAPI();
        this.sitekey = process.env.VUE_APP_RECAPTCHA_KEY;
        this.render();
    },
    methods: {
        execute() {
            // console.log('executing');
            window.grecaptcha.execute(this.widgetId);
        },
        reset() {
            window.grecaptcha.reset(this.widgetId);
        },
        render() {
            if (window.grecaptcha) {
                this.widgetId = window.grecaptcha.render('g-recaptcha', {
                    sitekey: this.sitekey,
                    size: 'invisible',
                    // the callback executed when the user solve the recaptcha
                    callback: response => {
                        // emit an event called verify with the response as payload
                        this.$emit('verify', response);
                        // reset the recaptcha widget so you can execute it again
                        this.reset();
                    },
                });
            }
        },
        injectRecaptchaAPI() {
            const existing_script = document.getElementById('gr_script');
            if (!existing_script) {
                var gr_script = document.createElement('script');
                gr_script.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
                gr_script.setAttribute('async', true);
                gr_script.setAttribute('defer', true);
                gr_script.setAttribute('id', 'gr_script');
                document.head.appendChild(gr_script);
            }
        },
    },
};
</script>
<style lang=""></style>

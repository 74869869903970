<template>
    <AppLayout :has-background="true" :contained="true">
        <div style="color: white">
            <h3>Tribute Admin</h3>
        </div>
        <div class="elevation-3 p-4 bg-white rounded">
            <v-tabs v-model="tab" color="#0d5259" style="overflow: visible">
                <v-tab href="#events">Videos</v-tab>
                <v-tab href="#templates">Templates</v-tab>
                <v-tab href="#music">Music</v-tab>
                <v-tab href="#userMusic">User Music</v-tab>
            </v-tabs>

            <v-tabs-items :value="tab">
                <v-tab-item value="templates">
                    <div class="p-3">
                        <!-- <p>No templates currently available..</p> -->
                        <v-card-title>
                            <font-awesome-icon icon="fa-solid fa-camera-movie" style="margin-right: 0.5rem" />
                            <h3 class="m-0">Templates</h3>
                            <v-spacer></v-spacer>
                            <v-text-field
                                append-icon="mdi-magnify"
                                label="Search"
                                style="max-width: 300px"
                                v-model="templateSearch"
                            ></v-text-field>
                        </v-card-title>
                        <tribute-template-table :search="templateSearch" />
                    </div>
                </v-tab-item>

                <v-tab-item value="music">
                    <v-card class="p-3">
                        <v-card-title>
                            <font-awesome-icon
                                icon="fa-regular fa-music"
                                style="margin-right: 0.5rem"
                            ></font-awesome-icon>
                            <h3 class="m-0">Music</h3>
                            <v-spacer></v-spacer>
                            <v-text-field
                                append-icon="mdi-magnify"
                                style="max-width: 300px"
                                v-model="musicSearch"
                            ></v-text-field>
                        </v-card-title>

                        <div class="d-flex justify-content-between pb-2">
                            <tribute-music-uploader :token="token" @refresh="refreshSongs()" />
                            <div class="d-flex align-items-center">
                                <v-btn class="mr-2" @click="initGenreModal" outlined x-small>Genres</v-btn>
                                <v-btn @click="initKeywordModal" outlined x-small>Keywords</v-btn>
                            </div>
                        </div>
                        <tribute-music-table
                            v-if="token"
                            ref="adminMusicTable"
                            :token="token"
                            :globalSongsOnly="true"
                            :search="musicSearch"
                        />
                        <!-- <music-folder-grid></music-folder-grid> -->
                    </v-card>
                </v-tab-item>

                <v-tab-item value="userMusic">
                    <v-card class="p-3">
                        <v-card-title>
                            <font-awesome-icon
                                icon="fa-regular fa-music"
                                style="margin-right: 0.5rem"
                            ></font-awesome-icon>
                            <h3 class="m-0">User Music</h3>
                            <v-spacer></v-spacer>
                            <v-text-field
                                append-icon="mdi-magnify"
                                style="max-width: 300px"
                                v-model="musicSearch"
                            ></v-text-field>
                        </v-card-title>
                        <tribute-music-table
                            v-if="token"
                            ref="userMusicTable"
                            :token="token"
                            :userSongsOnly="true"
                            :search="musicSearch"
                        />
                    </v-card>
                </v-tab-item>

                <v-tab-item value="events">
                    <v-card class="p-3">
                        <v-card-title>
                            <font-awesome-icon
                                icon="fa-regular fa-film"
                                style="margin-right: 0.5rem"
                            ></font-awesome-icon>
                            <h3 class="m-0">Tribute Videos</h3>
                            <v-spacer></v-spacer>
                            <v-text-field
                                append-icon="mdi-magnify"
                                style="max-width: 300px"
                                v-model="eventSearch"
                            ></v-text-field>
                        </v-card-title>

                        <tribute-event-table :search="eventSearch" />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </AppLayout>
</template>
<script>
import TributeMusicTable from '@/components/Tables/Admin/TributeMusicTable.vue';
import TributeTemplateTable from '@/components/Tables/Admin/TributeTemplateTable.vue';
import TributeEventTable from '@/components/Tables/Admin/TributeEventTable.vue';
import MusicFolderGrid from '@/components/Misc/MusicFolderGrid.vue';
import TributeMusicUploader from '@/components/ManageService/Tribute/TributeMusicUploader.vue';

export default {
    metaInfo: {
        title: 'Tribute Admin',
    },
    data() {
        return {
            message: 'hi',
            musicSearch: '',
            templateSearch: '',
            eventSearch: '',
            token: '',
        };
    },
    components: {
        TributeMusicTable,
        TributeTemplateTable,
        TributeEventTable,
        MusicFolderGrid,
        TributeMusicUploader,
    },
    computed: {
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
    methods: {
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        refreshSongs() {
            if (this.$refs.adminMusicTable) {
                this.$refs.adminMusicTable.getSongs();
            }
        },
        initGenreModal() {
            if (this.$refs.adminMusicTable) {
                this.$refs.adminMusicTable.genreManageModal = true;
            }
        },
        initKeywordModal() {
            if (this.$refs.adminMusicTable) {
                this.$refs.adminMusicTable.keywordManageModal = true;
            }
        },
    },
    mounted() {
        this.setAuthToken();
    },
};
</script>
<style lang=""></style>

<template>
    <div>
        <div class="mb-6" v-if="render">
            <div class="text-center" v-if="render.status == 0 || render.status == 1">
                <div class="d-flex justify-center m-5">
                    <custom-loader></custom-loader>
                </div>
                <h5 class="mt-6">Creating Tribute Video. This will take a few minutes.</h5>
            </div>
            <div v-if="render.status == 2">
                <div class="d-flex justify-center">

                    <styled-videojs-player
                    class="tribute-player flex-grow-1 ma-3"
                    :key="refreshKey"
                    :src="render.url"
                    ref="videoPlayer"
                    />
                </div>

                <div class="text-center ma-3">
                    <v-btn
                        color="#0d3d60"
                        :dark="!loading"
                        :disabled="loading"
                        @click="downloadTributeVideo(render.url)"
                    >
                        <font-awesome-icon
                            icon="fa-regular fa-arrow-down-to-bracket"
                            style="font-size: 0.9rem"
                            class="mr-2"
                        />
                        <span>Download</span>
                    </v-btn>
                </div>
            </div>
            <div v-if="render.status == 3">
                <v-alert outlined text prominent transition="scale-transition" type="error" icon="mdi-cloud-alert">
                    Unable to create tribute video due to an error.
                    <v-divider class="error" style="opacity: 0.22"></v-divider>
                </v-alert>
            </div>
        </div>

        <!-- START: Render download modal -->
        <v-dialog persistent v-model="downloading" max-width="500px">
            <v-card class="p-3">
                <div class="d-flex justify-content-between">
                    <h5>Downloading</h5>
                    <p>{{ downloadProgress }}%</p>
                </div>
                <v-progress-linear rounded v-model="downloadProgress"></v-progress-linear>
                <v-btn class="mt-3" color="error" @click="cancelDownload">Cancel</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import CustomLoader from '@/components/ui/CustomLoader.vue';
import StyledVideojsPlayer from '@/components/videojs/StyledVideojsPlayer.vue';
export default {
    data() {
        return {
            loading: false,
            refreshKey: 0,
            downloading: false,
            downloadCancelToken: null,
            downloadProgress: 0,
        };
    },
    props: {
        render: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
            default: '',
        },
    },
    components: { StyledVideojsPlayer },
    methods: {
        ...mapActions(['showSnackbar']),
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadTributeVideo(url) {
            let name = this.name ? `${this.name}'s' Tribute` : 'Tribute Video';
            this.showSnackbar({ message: 'Downloading' });

            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.tribute-player {
    max-width: 900px;
}


<template>
    <div class="ms-main-app-container">
        <SideMenu :collapsedMenu="!$store.state.menuShowing" @toggleMenu="toggleMenuv2()" />

        <section class="ms-main-content-container">
            <HeaderBar @toggleMenu="toggleMenuv2()" />
            <notification-bar
                v-if="showNotificationBar"
                :subscriptionStatus="$auth.session.home.subscriptionStatus"
            ></notification-bar>

            <!-- <perfect-scrollbar
                id="scroll"
                v-bind:class="{ 'red-background': hasBackground }"
                :options="{ wheelPropagation: true }"
            > -->
            <div class="main-wrapper" id="scroll" v-bind:class="{ 'red-background': hasBackground }">
                <main class="ms-main-content" v-bind:class="{ 'contained-content': contained }">
                    <promo-banner />
                    <slot />
                    <div
                        v-if="
                            !$auth.role.includes('SuperAdmin') &&
                            $auth.session &&
                            $auth.session.home &&
                            ($auth.session.home.subscriptionStatus === 5 ||
                                $auth.session.home.subscriptionStatus === 6 ||
                                $auth.session.home.subscriptionStatus === 7)
                        "
                        class="ms-notification-bar-spacer"
                    />
                    <div class="imhere" style="width: 100%; height: 2rem" />
                </main>
            </div>
            <!-- </perfect-scrollbar> -->
        </section>
    </div>
</template>

<script>
import SideMenu from '@/components/layout/SideMenu';
import HeaderBar from '@/components/layout/HeaderBar';
import PromoBanner from '@/components/layout/PromoBanner';
import NotificationBar from '@/components/layout/NotificationBar.vue';

export default {
    name: 'AppLayout',
    components: {
        SideMenu,
        HeaderBar,
        PromoBanner,
        NotificationBar,
    },
    props: {
        hasBackground: {
            type: Boolean,
            default: true,
        },
        contained: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            collapsedMenu: false,
        };
    },
    computed: {
        showNotificationBar() {
            const isSuperAdmin = this.$auth.role.includes('SuperAdmin');

            const hasActiveSession = this.$auth.session && this.$auth.session.home;

            const triggeringStatuses = [1, 5, 6, 7];

            const hasTriggeringStatus =
                hasActiveSession && triggeringStatuses.includes(this.$auth.session.home.subscriptionStatus);

            return !isSuperAdmin && hasActiveSession && hasTriggeringStatus;
        },
    },
    methods: {
        toggleMenu: function () {
            this.collapsedMenu = !this.collapsedMenu;
        },
        toggleMenuv2() {
            this.$store.dispatch('toggleSideNav');
        },

        openBillingPortal() {
            const funeralHomeId = this.$auth.session.home.id;
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/billing/funeralhome/portal/${funeralHomeId}`)
                    .then(response => {
                        window.open(response.data, '_blank');
                    })
                    .catch(error => {});
            });
        },
        checkIntercomBanner() {
            let bannerFrame = document.getElementsByName('intercom-banner-frame')[0];

            if (bannerFrame) {
                var iframeStyles = window.getComputedStyle(bannerFrame);

                let bannerStyle = iframeStyles.maxWidth == '800px' ? 'floating' : 'inline';
                let position = iframeStyles.top == '0px' || iframeStyles.top == '16px' ? 'top' : 'bottom';

                if (bannerStyle == 'inline') {
                    const closeBtn = bannerFrame.contentWindow.document.querySelectorAll('[role="button"]')[0];
                    const bannerHeight = bannerFrame.contentWindow.innerHeight;
                    if (closeBtn) {
                        // Revert page height if banner dismissed
                        closeBtn.addEventListener('click', function () {
                            document.documentElement.style.setProperty('--banner-spacer', '0px');
                        });
                    }
                    //adjusts app container height in event of intercom banner
                    document.documentElement.style.setProperty('--banner-spacer', bannerHeight + 'px');
                }

                clearInterval(this.interval);
            }
        },
    },

    mounted: function () {
        document.documentElement.style.overflow = 'hidden';

        const isMobile = window.innerWidth <= 768;
        if (isMobile) {
            this.collapsedMenu = true;
            if (this.$store.state.menuShowing) this.toggleMenuv2();
        }

        this.interval = setInterval(this.checkIntercomBanner, 2000);

        // clears interval for banner check after 10s
        setTimeout(() => {
            clearInterval(this.interval);
        }, 10000);
    },
};
</script>

<style lang="scss">
:root {
    --banner-spacer: 0;
}

// .intercom-container.intercom-inline-frame {
//     /* CSS styles for inline banner */
//     border: 2px solid red !important;
// }
// .intercom-container.intercom-messenger-frame {
//     /* CSS styles for floating banner */
//     border: 2px solid purple !important;
// }

$main-header-height: 60px;
.contained-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
}

.ms-main-app-container {
    height: calc(100svh - var(--banner-spacer));
    width: 100vw;
    display: flex;
    position: relative;
    // border: 2px solid red;

    .ms-side-navbar {
        background: white;
        width: 300px;
        height: 100svh;
        max-height: 100%;
        box-shadow: 0 0.15rem 1.5rem 0 rgba(31, 45, 65, 0.15);
        transition: all 0.15s;
        display: flex;
        flex-direction: column;
        .ps {
            flex: 1;
            padding: 1rem 0.5rem;
        }
        .logo-container {
            padding: 1rem;
            display: flex;
            justify-content: center;
            .logo {
                width: 100%;
                max-width: 200px;
            }
        }
        &-link-container {
            list-style-type: none;
            padding: 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            .menu-entry {
                transition: 0.3s ease-in-out;
                cursor: pointer;
                font-size: 1rem;
                padding: 0.75rem;
                display: flex;
                align-items: center;
                border-radius: 5px;
            }
            .menu-entry:hover {
                background: #eee;
            }
            .menu-entry.selected {
                background: #ff530d;
                color: white;
            }
        }
        &-footer {
            background: #dfdfdf;
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
        }
    }
    .ms-side-navbar.collapsed-menu {
        width: 4rem;
        .logo-container {
            padding: 0.7rem;
        }
        .ps {
            padding: 1rem 0.5rem;
        }
        &-link-container {
            list-style-type: none;
            padding: 0;
            margin: 3rem 0 0;
            overflow-x: hidden;
            flex: 1;
        }
        .menu-entry {
            display: flex;
            justify-content: center;
            align-items: center;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .ms-notification-bar-spacer {
        height: 4rem;
    }
    .ms-main-content-container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        max-height: 100svh;
        min-height: 100%;

        .main-wrapper {
            overflow: hidden;
            display: flex;
            height: 100%;
        }

        .ms-main-header {
            height: $main-header-height;
            max-height: 60px;
            background: #f1f4f7;
            box-shadow: 0 0.1rem 0.25rem 0 rgba(31, 45, 65, 0.1);
            flex: 1;
            display: flex;
            align-items: center;
            padding: 2rem;

            .menu-toggle-button {
                cursor: pointer;
            }
        }

        .ms-notification-bar {
            height: 80px;
            // position: fixed;
            position: sticky;

            z-index: 1000;
            background: rgb(246, 244, 198);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 0 2rem;
            top: $main-header-height;

            &-content-left {
                display: flex;
                align-items: center;
                flex-grow: 1;
                justify-content: center;
            }

            p {
                max-width: 100%;
                margin: 0;
            }
        }

        .ms-main-content {
            flex-grow: 1;
            max-width: 100%;
            overflow: auto;
            // max-height: calc(100vh - $main-header-height);
        }
        .red-background {
            background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
                no-repeat fixed 0 -16rem;
        }
    }
    .mobile-menu-toggle {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .ms-side-navbar {
        width: 100vw !important;
        position: fixed;
        top: 0;
        // top: var(--banner-spacer);
        left: 0;
        z-index: 10;
        .logo-container {
            max-width: 100px;
            margin: 0 auto;
        }
        .ps {
            height: 100%;
        }
    }
    .ms-side-navbar.collapsed-menu {
        left: -100vw;
    }
    .mobile-menu-toggle {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex !important;
        width: 25px;
        height: 25px;
        background: #f1f4f7;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        opacity: 0.4;
        transition: all 0.15s;
        z-index: 5000;
    }
    .mobile-menu-toggle:hover {
        opacity: 0;
    }

    .ms-notification-bar {
        height: 200px !important;
    }

    .ms-notification-bar-spacer {
        height: 220px !important;
        width: 100%;
    }
}
</style>

import { render, staticRenderFns } from "./TributeOnboarding.vue?vue&type=template&id=0a6b7fec&scoped=true&"
import script from "./TributeOnboarding.vue?vue&type=script&lang=js&"
export * from "./TributeOnboarding.vue?vue&type=script&lang=js&"
import style0 from "./TributeOnboarding.vue?vue&type=style&index=0&id=0a6b7fec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6b7fec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VAvatar,VBtn,VDivider,VImg,VProgressLinear,VStepper,VStepperContent,VStepperItems,VWindow,VWindowItem})

<template>
    <div style="min-height: 550px">
        <div class="d-flex pa-1">
            <v-row>
                <v-col cols="auto" class="pt-8">
                    <div style="width: 100%" class="d-flex justify-space-between">
                        <calendar
                            v-if="customDateRange"
                            style="max-width: 200px"
                            v-model="startDate"
                            label="From:"
                            :output-local="true"
                        ></calendar>

                        <calendar
                            v-if="customDateRange"
                            style="max-width: 200px"
                            v-model="endDate"
                            label="To:"
                            :output-local="true"
                        ></calendar>
                    </div>

                    <div class="d-flex flex-wrap my-3" style="gap: 12px">
                        <v-btn
                            v-for="filter in quickFilters"
                            small
                            dark
                            :color="filter.color"
                            :outlined="currentQuickFilter != filter.value"
                            depressed
                            @click="quickFilter(filter.value)"
                            >{{ filter.label }}</v-btn
                        >
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex justify-end">
                        <v-text-field
                            label="Search"
                            style="max-width: 300px"
                            v-model="search"
                            append-icon="mdi-magnify"
                            @keyup.enter="getLiveEvents"
                            single-line
                            hide-details
                        ></v-text-field>
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-data-table
            :headers="headers"
            :items="liveEvents"
            :options.sync="options"
            :loading="loading"
            :server-items-length="total"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting Live Events..."
            @click:row="openDetailsModal"
        >
            <template v-slot:header.archiveStatus="{ header }">
                <v-menu
                    @input="handleEventMenuToggle"
                    :disabled="loading || currentQuickFilter === 'scheduled'"
                    :close-on-content-click="false"
                    :offset-y="true"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :disable="currentQuickFilter === 'scheduled'" :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span
                                    :class="currentQuickFilter === 'scheduled' ? 'disabled-text' : ''"
                                    v-bind="attrs"
                                    v-on="{ ...menu }"
                                >
                                    {{ header.text }} ({{ selectedStatuses.length }})
                                    <font-awesome-icon
                                        v-if="currentQuickFilter != 'scheduled'"
                                        icon="fa-solid fa-chevron-down"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <v-list-item v-for="(item, index) in liveArchiveStatuses" :key="index" style="height: 20px">
                            <v-checkbox v-model="selectedStatuses" :label="item.label" :value="index"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:header.eventStatus="{ header }">
                <v-menu
                    @input="handleEventStatusMenuToggle"
                    :disabled="loading || currentQuickFilter === 'scheduled'"
                    :close-on-content-click="false"
                    :offset-y="true"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :disable="currentQuickFilter === 'scheduled'" :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span
                                    :class="currentQuickFilter === 'scheduled' ? 'disabled-text' : ''"
                                    v-bind="attrs"
                                    v-on="{ ...menu }"
                                >
                                    {{ header.text }} ({{ selectedEventStatuses.length }})
                                    <font-awesome-icon
                                        v-if="currentQuickFilter != 'scheduled'"
                                        icon="fa-solid fa-chevron-down"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <v-list-item v-for="(item, index) in eventStates" :key="index" style="height: 20px">
                            <v-checkbox v-model="selectedEventStatuses" :label="item.label" :value="index"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:header.videoId="{ header }">
                <v-menu :disabled="loading" :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }}
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <div class="bg-white pa-2">
                        <v-radio-group dense hide-details v-model="selectedVideoFilterState">
                            <v-radio
                                class="ma-1"
                                v-for="item in videoFilterStates"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-menu>
            </template>

            <template v-slot:item.archiveStatus="{ item }">
                <div :id="`status-select-${item.eventId}`">
                    <v-menu :ref="`status-select-${item.eventId}`" :attach="`#status-select-${item.eventId}`" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                dark
                                v-bind="attrs"
                                v-on="on"
                                :color="liveArchiveStatuses[item.archiveStatus].color"
                            >
                                {{ liveArchiveStatuses[item.archiveStatus].label }}</v-chip
                            >
                        </template>
                        <v-list style="max-height: 300px; background-color: white">
                            <v-list-item
                                style="background-color: white"
                                @click.stop="updateArchiveStatus(item, status)"
                                class="status-list-chip"
                                v-for="(status, index) in liveArchiveStatuses"
                                :key="index"
                            >
                                <v-chip dark :color="status.color">
                                    <v-list-item-title>{{ status.label }}</v-list-item-title>
                                </v-chip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

            <template v-slot:item.eventTitle="{ item }">
                <div class="d-flex flex-column">
                    <span class="text-caption grey--text">{{ item.funeralHomeName }}</span>

                    <a :href="`/services/${item.serviceSlug}/${item.eventId}`" target="_blank" @click.stop="">{{
                        item.eventTitle
                    }}</a>
                </div>
            </template>

            <template v-slot:item.eventTime="{ item }">
                <div class="d-flex flex-column">
                    <span>
                        {{ $moment.utc(item.eventTime).local().format('l') }}
                    </span>
                    <span class="text-caption" style="color: gray">
                        {{ $moment.utc(item.eventTime).local().format('LT') }}
                    </span>
                </div>
            </template>

            <template v-slot:item.eventStatus="{ item }">
                <div>
                    <v-chip dark :color="eventStates[item.eventStatus].color">
                        {{ eventStates[item.eventStatus].label }}
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.estRenderDate="{ item }">
                <div class="d-flex flex-column">
                    <span>
                        {{ $moment.utc(item.eventTime).add(7, 'days').local().format('l') }}
                    </span>
                </div>
            </template>

            <template v-slot:item.liveSecondsConsumed="{ item }">
                <div>
                    {{ formatDuration(item.liveSecondsConsumed) }}
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <custom-tooltip :tooltipProps="{ top: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    v-if="!(item.eventStatus == 4 || item.archiveStatus == 3)"
                                    @click.stop="initRenderModal(item)"
                                    style="font-size: 1.2rem"
                                    icon="fa-regular fa-file-video"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Submit Render</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>

            <template v-slot:item.videoId="{ item }">
                <div>
                    <v-checkbox disabled :input-value="item.videoId != null"></v-checkbox>
                </div>
            </template>
        </v-data-table>

        <v-dialog max-width="720" v-model="createRenderModal">
            <v-card>
                <v-card-title>Create Render</v-card-title>
                <v-card-text>
                    <v-row class="mt-0">
                        <v-col cols="12">
                            <div class="d-flex justify-space-between">
                                <span>{{ selectedItem.eventTitle }}</span>
                                <span>Event Id: {{ selectedItem.eventId }}</span>
                            </div>
                            <div class="d-flex" style="gap: 12px">
                                <v-checkbox v-model="autoVMAssign" label="Auto Assign"></v-checkbox>
                                <v-checkbox :disabled="autoVMAssign" v-model="customVM" label="Other VM"></v-checkbox>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :disabled="autoVMAssign"
                                v-if="customVM"
                                v-model="tempVMName"
                                label="VM Name"
                            ></v-text-field>
                            <v-select
                                :disabled="autoVMAssign"
                                v-else
                                v-model="renderForm.vmName"
                                :items="vmList"
                                item-text="name"
                                item-value="name"
                                label="VM Name"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                disabled
                                v-model="renderForm.renderEngineType"
                                :items="renderEngineTypes"
                                item-text="label"
                                item-value="value"
                                label="Speed"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="renderForm.renderSpeed"
                                :items="renderSpeeds"
                                item-text="label"
                                item-value="value"
                                label="Speed"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="renderForm.renderType"
                                :items="renderTypes"
                                item-text="label"
                                item-value="value"
                                label="Video Queue Type"
                                disabled
                            ></v-select>
                        </v-col>
                        <!-- <v-col cols="12" md="6">
                            <v-select
                                v-model="renderForm.queStatus"
                                :items="queStatusEnum"
                                item-text="description"
                                item-value="id"
                                label="Video Queue Status"
                            ></v-select>
                        </v-col> -->
                        <v-col cols="12" md="6">
                            <v-text-field label="Start" v-model="renderForm.start"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Stop" v-model="renderForm.stop"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelCreateRender">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="createRender(selectedItem.eventId)">Create Render</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="adminDetailsModal" max-width="720">
            <v-card>
                <v-card-title>Details</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="selectedItem.eventId" label="EventId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="EventTitle" v-model="selectedItem.eventTitle"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="FuneralHomeId" v-model="selectedItem.funeralHomeId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Funeral Home Name"
                                v-model="selectedItem.funeralHomeName"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="ServiceId" v-model="selectedItem.serviceId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="ServiceSlug" v-model="selectedItem.serviceSlug"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="LiveStreamId" v-model="selectedItem.liveStreamId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="StreamId" v-model="selectedItem.streamId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="VideoId" v-model="selectedItem.videoId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                readonly
                                label="Live Seconds Consumed"
                                :value="formatDuration(selectedItem.liveSecondsConsumed)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Archive Status" v-model="selectedItem.archiveStatus"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                readonly
                                label="Create Date"
                                :value="formatDate(selectedItem.createDate)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                readonly
                                label="Started Time"
                                :value="formatDate(selectedItem.startedTime)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                readonly
                                label="Ended Time"
                                :value="formatDate(selectedItem.endedTime)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Vods" v-model="selectedItem.vods"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div>
                                <v-btn depressed class="mt-3" block @click="openWowzaTab(selectedItem.streamId)"
                                    >Wowza</v-btn
                                >
                            </div>
                        </v-col>
                        <v-col vols="12">
                            <p>Vod Urls</p>
                            <v-divider></v-divider>

                            <div v-if="selectedItem.vods">
                                <div v-for="item in JSON.parse(selectedItem.vods)">
                                    <!-- <span class="text-caption grey--text">{{ item.createDate }}</span> -->
                                    <!-- <span>{{ item.playbackUrl }}</span> -->
                                    <div class="d-flex justify-space-between flex-wrap align-end">
                                        <div class="d-flex flex-column">
                                            <span v-if="item.CreateDate">{{
                                                $moment.utc(item.CreateDate).local().format('lll')
                                            }}</span>
                                            <span>{{ item.PlaybackUrl }}</span>
                                        </div>
                                        <div>
                                            <v-btn @click="copyText(item.PlaybackUrl)" small depressed dark>
                                                <font-awesome-icon icon="fa-regular fa-clone"></font-awesome-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <v-divider></v-divider>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="adminDetailsModal = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Calendar from '@/components/ui/Calendar.vue';
import { mapActions } from 'vuex';
import { debounceV2 } from '@/utilities/debounce.js';
import { liveArchiveStatuses } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { renderEngineTypes, renderSpeeds, renderTypes, queStatusEnum, boolSelectItems, eventStates } from '@/constants';

export default {
    data() {
        return {
            eventStates,
            renderEngineTypes,
            renderSpeeds,
            renderTypes,
            queStatusEnum,
            boolSelectItems,
            liveArchiveStatuses,
            token: '',
            liveEvents: [],
            total: 0,
            loading: true,
            mounted: false,
            search: '',
            startDate: null,
            endDate: null,
            offset: '',
            customDateRange: false,
            renderForm: {
                renderSpeed: 0,
                renderType: 9,
                renderEngineType: 0,
                vmName: '',
                start: 0,
                stop: 0,
            },
            quickFilters: [
                { label: 'Needs Attention', value: 'error', color: 'error' },
                { label: 'tonight', value: 'scheduled', color: '#9C27B0' },
                { label: 'upcoming', value: 'upcoming', color: 'primary' },
                { label: 'All', value: 'custom-date-range', color: 'black' },
            ],
            selectedVideoFilterState: 0,
            videoFilterStates: [
                { label: 'All', value: 0 },
                { label: 'Has Video', value: 1 },
                { label: 'Has No Video', value: 2 },
            ],
            headers: [
                //{
                //    text: 'Event Id',
                //    value: 'eventId',
                //},
                // {
                //     text: 'Live Stream Id',
                //     value: 'liveStreamId',
                // },
                {
                    text: 'Event',
                    value: 'eventTitle',
                },
                {
                    text: 'Event Status',
                    value: 'eventStatus',
                },
                // {
                //     text: 'Create Date',
                //     value: 'createDate',
                // },
                {
                    text: 'Event Time',
                    value: 'eventTime',
                },
                {
                    text: 'Scheduled Render',
                    value: 'estRenderDate',
                },
                // {
                //     text: 'Stream Id',
                //     value: 'streamId',
                //     sortable: false,
                // },
                {
                    text: 'Stream Length',
                    value: 'liveSecondsConsumed',
                },
                {
                    text: 'Video',
                    value: 'videoId',
                },
                {
                    text: 'Status',
                    value: 'archiveStatus',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                },
            ],
            options: {
                itemsPerPage: 25,
            },
            selectedEventStatuses: [0, 1, 2, 3, 4, 5, 6, 7],
            filterMenuOpen: false,
            selectedStatuses: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            autoRenderThresholdDate: this.$moment(new Date()).add(-7, 'days').startOf('day'),
            currentQuickFilter: null,
            createRenderModal: false,
            selectedItem: {},
            tempVMName: '',
            customVM: false,
            autoVMAssign: true,
            vmList: [],
            adminDetailsModal: false,
        };
    },
    components: {
        Calendar,
        CustomTooltip,
    },
    watch: {
        options: {
            handler() {
                if (this.mounted) {
                    this.debounceGetLiveEvents();
                }
            },
            deep: true,
        },
        search() {
            if (this.mounted) {
                this.debounceGetLiveEvents();
            }
        },
        startDate() {
            if (this.mounted) {
                this.debounceGetLiveEvents();
            }
        },
        endDate() {
            if (this.mounted) {
                this.debounceGetLiveEvents();
            }
        },
        autoVMAssign(newVal) {
            if (newVal) {
                this.renderForm.vmName = null;
                this.tempVMName = null;
            }
        },
        adminDetailsModal(newVal) {
            if (!newVal) {
                this.selectedItem = {};
            }
        },
        selectedVideoFilterState(newVal) {
            this.getLiveEvents();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        formatDuration(val) {
            let duration = this.$moment.duration(val, 'seconds');

            let formattedDuration =
                Math.floor(duration.asHours()).toString().padStart(2, '0') +
                this.$moment.utc(duration.asMilliseconds()).format(':mm:ss');
            return formattedDuration;
        },
        formatDate(val) {
            if (!val) return null;

            return this.$moment.utc(val).local().format('lll');
        },
        debounceGetLiveEvents: debounceV2(function () {
            this.getLiveEvents();
        }, 500),
        openWowzaTab(id) {
            window.open('https://cloud.wowza.com/en/thgvyjtb/manage/live_streams/' + id);
        },
        copyText(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        openDetailsModal(item) {
            this.selectedItem = item;
            this.adminDetailsModal = true;
        },
        updateArchiveStatus(live, status) {
            if (live.liveStreamId == null || live.liveStreamId == 0) {
                this.showSnackbar({ message: 'Invalid live stream id', color: 'error' });
                return;
            }

            const menu = this.$refs[`status-select-${live.eventId}`];
            if (menu) {
                menu.save();
            }

            this.axiosInstance
                .put(`/live/admin-update-archive-status/${live.liveStreamId}/${status.value}`)
                .then(response => {
                    live.archiveStatus = status.value;
                    this.showSnackbar({ message: 'Live Archive Status updated' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating status', color: 'error' });
                });
        },
        getVMList() {
            this.axiosInstance
                .get('/render/vm-list')
                .then(response => {
                    this.vmList = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        resetRenderForm() {
            this.renderForm = {
                renderSpeed: 0,
                renderType: 9,
                renderEngineType: 0,
                vmName: '',
                start: 0,
                stop: 0,
            };
        },
        cancelCreateRender() {
            this.createRenderModal = false;
            this.selectedItem = {};
            this.resetRenderForm();
        },
        initRenderModal(item) {
            this.selectedItem = item;
            this.createRenderModal = true;
        },
        createRender(eventId) {
            if (!eventId) {
                this.showSnackbar({ message: 'Event id not found', color: 'error' });
                return;
            }

            if (this.customVM && !this.autoVMAssign) this.renderForm.vmName = this.tempVMName;

            let data = {
                start: this.renderForm.start,
                stop: this.renderForm.stop,
                renderSpeed: this.renderForm.renderSpeed,
                autoAssignVm: this.autoVMAssign,
                vmName: this.renderForm.vmName,
            };

            this.axiosInstance
                .post(`/events/create-render/${eventId}`, data)
                .then(resp => {
                    this.cancelCreateRender();
                    this.getLiveEvents();
                    this.showSnackbar({ message: 'Render created' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating render', color: 'error' });
                });
        },
        quickFilter(val) {
            this.currentQuickFilter = val;

            switch (val) {
                case 'upcoming':
                    this.customDateRange = false;
                    this.startDate = this.autoRenderThresholdDate.toISOString();
                    this.endDate = null;
                    this.selectedStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                    break;
                case 'scheduled':
                    this.customDateRange = false;
                    this.startDate = null;
                    this.endDate = this.autoRenderThresholdDate.toISOString();
                    this.selectedStatuses = [1];
                    break;
                case 'custom-date-range':
                    this.setStartEndDates();
                    this.selectedStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                    this.customDateRange = true;
                    break;
                case 'error':
                    this.customDateRange = false;
                    this.startDate = null;
                    this.endDate = null;
                    this.selectedStatuses = [6, 7, 8];
                    break;
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        handleEventMenuToggle() {
            if (this.filterMenuOpen == false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen == true) {
                if (this.selectedStatuses.length === 0) {
                    this.liveEvents = [];
                    this.total = 0;
                    return;
                }
                this.getLiveEvents();
                this.filterMenuOpen = false;
            }
        },
        handleEventStatusMenuToggle() {
            if (this.filterMenuOpen == false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen == true) {
                if (this.selectedEventStatuses.length === 0) {
                    this.liveEvents = [];
                    this.total = 0;
                    return;
                }
                this.getLiveEvents();
                this.filterMenuOpen = false;
            }
        },

        getLiveEvents() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            const archiveStatusParamString = this.selectedStatuses.map(status => `archiveStatus=${status}`).join('&');

            const eventStatusParamString = this.selectedEventStatuses.map(status => `eventStatus=${status}`).join('&');

            const fullParamString = [archiveStatusParamString, eventStatusParamString].join('&');

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
                startDate: this.startDate,
                endDate: this.endDate,
                offset: this.offset,
                archiveStatus: this.selectedStatuses,
                videoState: this.selectedVideoFilterState,
            };

            this.axiosInstance
                .get(`/admin-tools/live-events?${fullParamString}`, { params })
                .then(resp => {
                    this.total = resp.data.total;
                    this.liveEvents = resp.data.events;
                    this.loading = false;
                    this.mounted = true;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error getting live events' });
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'eventTitle':
                    return 'EventTitle';
                case 'id':
                    return 'Id';
                case 'createDate':
                    return 'CreateDate';
                case 'eventTime':
                    return 'EventTime';
                case 'estRenderDate':
                    return 'EventTime';
                case 'eventId':
                    return 'EventId';
                case 'LiveStreamId':
                    return 'LiveStreamId';
                case 'liveSecondsConsumed':
                    return 'LiveSecondsConsumed';
                case 'videoId':
                    return 'VideoId';
                case 'archiveStatus':
                    return 'ArchiveStatus';
                case 'eventStatus':
                    return 'EventStatus';

                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getOffset() {
            this.offset = Math.abs(this.$moment(new Date()).utcOffset() / 60);
        },
        setStartEndDates() {
            this.startDate = this.$moment(new Date()).startOf('month').toISOString();
            this.endDate = this.$moment(new Date()).add(1, 'days').startOf('day').toISOString();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        this.quickFilter('error');
        this.getOffset();
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getLiveEvents();
        this.getVMList();
    },
};
</script>
<style lang="scss" scoped>
.status-list-chip:hover {
    background-color: #f1f4f7;
}

.status-list-chip {
    transition: 0.2s;
}

.disabled-text {
    color: grey;
}
</style>

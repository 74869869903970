<template>
    <div class="p-3">
        <div class="subscriptionBilling">
            <v-row>
                <v-col
                    v-if="
                        $auth.role.includes('FuneralHome') ||
                        $auth.role.includes('Owner') ||
                        $auth.role.includes('RegionalManager')
                    "
                >
                    <h5 class="mb-4">Update Billing</h5>
                    <v-btn @click="openBillingPortal" color="primary" class="mb-2"
                        >Update Payment and Billing Address</v-btn
                    >
                </v-col>
                <v-col v-if="this.subStart && this.subStart != '0001-01-01T00:00:00'" class="text-right mt-2">
                    <h6>Current Billing Period</h6>

                    {{ this.subStart | moment('l') }} -
                    {{ this.subEnd | moment('l') }}
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="this.subStart != '0001-01-01T00:00:00'"></v-divider>
        <div v-if="!this.subStart != '0001-01-01T00:00:00'" style="height: 12px"></div>

        <!-- Start FH ProminentInfo Section -->
        <div class="my-8" v-if="!this.$auth.role.includes('SuperAdmin')">
            <div v-if="!subscriptionData && this.$auth.role.includes('FuneralHome')">
                <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </div>
            <div v-else>
                <div v-if="subscriptionData != null">
                    <home-subscription-info :subscriptionData="subscriptionData" />
                </div>
            </div>
        </div>

        <!-- <v-row>
            <v-col v-if="$auth.role.includes('FuneralHome')">
                <h5 class="mb-4">Update Billing</h5>
                <v-btn @click="openBillingPortal" color="primary" class="mb-2"
                    >Update Payment and Billing Address</v-btn
                >
            </v-col>
            <v-col v-if="this.subStart" class="text-right mt-2">
                <h6>Current Billing Period</h6>
                {{ this.subStart | moment('l') }} -
                {{ this.subEnd | moment('l') }}
            </v-col>
        </v-row> -->

        <!-- START: Current Invoice Table -->
        <h5 class="pl-3">Current Invoice Charges</h5>
        <div id="tableWrapper">
            <v-data-table
                :headers="currentInvoiceTable.headers"
                :items="currentInvoiceTable.events"
                :loading="loading"
                loading-text="Getting Billing Events..."
                class="subscriptionInvoiceTable"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
            >
                <template v-slot:item.event="{ item }">
                    <span v-if="item.serviceSlug">
                        <a :href="`/services/${item.serviceSlug}`">
                            <v-chip
                                v-if="item.eventType >= 0"
                                style="cursor: pointer"
                                :color="eventTypes[item.eventType].color"
                                dark
                                >{{ eventTypes[item.eventType].label }}</v-chip
                            >
                        </a>
                    </span>

                    <span v-else>
                        <v-chip v-if="item.eventType >= 0" :color="eventTypes[item.eventType].color" dark>{{
                            eventTypes[item.eventType].label
                        }}</v-chip>
                    </span>
                </template>

                <template v-slot:item.amount="{ item }">
                    <span>{{ item.amount | formatMoney }}</span>
                </template>

                <template v-slot:item.date="{ item }">
                    <p class="pt-6 pb-6 mb-0">
                        {{ getLocalDate(item.reportedDate) | moment('l') }}
                    </p>
                </template>
                <template v-slot:item.description="{ item }">
                    <v-textarea
                        class="description-textarea"
                        v-if="item.isEditing"
                        autofocus
                        auto-grow
                        rows="1"
                        v-model="item.description"
                    ></v-textarea>
                    <span v-else>{{ item.description }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div v-if="item.isEditing" class="d-flex flex-column">
                        <v-btn class="mb-2" color="green" small dark @click="saveDescription(item.id)">Save</v-btn>
                        <v-btn @click="cancelEdit(item.id)" small>Cancel</v-btn>
                    </div>
                    <div v-else class="action-list">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click="editDescription(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-pencil" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Edit Description</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click="showDeleteDialog(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Delete Item</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>
            </v-data-table>
            <div v-if="invoiceTotal">
                <span>
                    <p class="displayTotal">Current Invoice Total: {{ invoiceTotal | formatMoney }}</p>
                </span>
            </div>
            <!-- END: Current Invoice Table -->

            <!-- START: Credit Table -->
            <funeral-home-credit-table
                v-if="this.$auth.role.includes('SuperAdmin') || this.$auth.role.includes('FuneralHome')"
                :funeralHomeId="funeralHomeId"
                :token="token"
            />
            <!-- END: Credit Table -->

            <!-- START: Invoice Table -->
            <invoice-table
                class="subscriptionInvoiceTable"
                :apiUrl="'/billing/invoices?funeralHomeId=' + this.funeralHomeId"
                @refreshInvoice="getBillingData"
            />
            <!-- END: Invoice Table -->

            <!-- START: Invoice Delete Dialog -->
            <v-dialog v-model="deleteDialog.show" max-width="290">
                <v-card class="align-stretch">
                    <v-card-title class="headline">Delete Event?</v-card-title>
                    <v-card-text>Are you sure you want to delete this event?</v-card-text>
                    <v-card-actions class="justify-center">
                        <div>
                            <v-btn color="gdarken-1" text @click="deleteDialog.show = false">Cancel</v-btn>
                            <v-btn color="red darken-1" text @click="deleteEvent(deleteDialog.eventId)">Delete</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- END: Invoice Delete Dialog -->
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { eventTypes } from '@/constants';
import InvoiceTable from '@/components/Tables/InvoiceTable.vue';
import FuneralHomeCreditTable from '@/components/Tables/FuneralHomeCreditTable.vue';
import HomeSubscriptionInfo from '../Settings/HomeSubscriptionInfo.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'SubscriptionPanel',
    props: ['token', 'funeralHomeId'],
    components: {
        InvoiceTable,
        FuneralHomeCreditTable,
        HomeSubscriptionInfo,
        CustomTooltip,
    },
    data() {
        return {
            eventDialog: {
                show: false,
                title: 'Dialog Title',
                loading: false,

                table: {
                    headers: [
                        { text: 'Date', value: 'date' },
                        { text: 'Event', value: 'event' },
                        { text: 'Amount', value: 'amount', width: '13%' },
                        { text: 'Description', value: 'description' },
                    ],
                    events: [],
                },
            },
            currentInvoiceTable: {
                events: [],
                headers: [
                    { text: 'Date', value: 'date' },
                    { text: 'Event', value: 'event' },
                    { text: 'Amount', value: 'amount', width: '13%' },
                    { text: 'Description', value: 'description', width: '50%' },
                ],
                loading: false,
            },
            allInvoicesTable: {
                invoices: [],
                headers: [
                    { text: 'Month', value: 'month' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Status', value: 'status' },
                    { text: 'Billing Period', value: 'billingPeriod' },
                    { text: 'Actions', value: 'actions', align: 'center' },
                ],
                loading: false,
            },
            deleteDialog: {
                eventId: '',
                show: false,
            },
            serviceDialog: false,
            viewsDialog: false,
            maxStreamsDialog: false,
            subscriptionDialog: false,
            eventTypes,
            subStart: null,
            subEnd: null,
            loading: false,
            dataTableHeight: '',
            subscriptionData: null,
            subscriptionHeaders: [
                { text: 'Description', value: 'description' },
                { text: 'Price', value: 'price' },
            ],
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            invoiceTotal: null,
        };
    },

    methods: {
        ...mapActions(['showSnackbar']),

        getTotal(array) {
            let total = 0;
            for (let i = 0; i < array.length; i++) {
                total += array[i].amount;
            }
            return total;
        },
        getSubscriptionData() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/funeralhome/${this.funeralHomeId}`)
                .then(response => {
                    this.subscriptionData = response.data;
                })
                .catch(error => {});
        },
        handleClick(row, e, third) {
            this.showEvents(null, row.actions.events, row.month);
        },
        editDescription(id) {
            const eventToEdit = this.currentInvoiceTable.events.find(event => event.id === id);
            eventToEdit.prevDescription = eventToEdit.description;
            eventToEdit.isEditing = true;
        },
        cancelEdit(id) {
            const eventToEdit = this.currentInvoiceTable.events.find(event => event.id === id);
            eventToEdit.description = eventToEdit.prevDescription;
            eventToEdit.isEditing = false;
        },
        saveDescription(id) {
            const eventToUpdate = this.currentInvoiceTable.events.find(event => event.id === id);
            if (eventToUpdate.description === eventToUpdate.prevDescription) {
                eventToUpdate.isEditing = false;
                return;
            }

            const payload = {
                ...eventToUpdate,
            };
            // Delete client side specific properties
            delete payload.isEditing;
            delete payload.prevDescription;
            this.axiosInstance
                .put('billing/events', payload)
                .then(response => {
                    this.showSnackbar({ message: 'Event updated successfully' });
                    this.getBillingData();
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating events' });
                });
        },
        showDeleteDialog(id) {
            this.deleteDialog.eventId = id;
            this.deleteDialog.show = true;
        },
        deleteEvent(id) {
            this.axiosInstance
                .delete(`/billing/events/${id}`)
                .then(response => {
                    this.deleteDialog.eventId = null;
                    this.deleteDialog.show = false;
                    this.showSnackbar({ message: 'Event deleted successfully' });
                    this.getBillingData();
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error deleting events' });
                });
        },
        showEvents(event, invoiceEvents, billingMonth) {
            /* Note! The 'event' param is the JavaScript event object.
             * It has nothing to do with invoice events, but is the first arg passed to
             * an event handler by default, so we have to include it
             */
            this.eventDialog.loading = true;
            this.eventDialog.show = true;
            this.eventDialog.title = `${billingMonth} Invoice Summary`;
            this.eventDialog.table.events = invoiceEvents;
            this.eventDialog.loading = false;
        },
        getStatusColor(status) {
            return status === 'Paid' ? 'green' : 'red';
        },
        openBillingPortal() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/funeralhome/portal/${this.funeralHomeId}`)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => {});
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        getBillingData() {
            this.loading = true;

            const homeReq = this.axiosInstance.get(`/funeralhomes/${this.funeralHomeId}`); // used to get subStart and subEnd
            const currentInvoicesReq = this.axiosInstance.get('/billing/events/current', {
                params: { funeralHomeId: this.funeralHomeId, sortAsc: true },
            });

            Promise.allSettled([homeReq, currentInvoicesReq]).then(([homeRes, currentInvoicesRes, allInvoicesRes]) => {
                this.getSubscriptionData();

                if (homeRes.status === 'fulfilled') {
                    const home = homeRes.value.data.funeralHome;
                    this.subStart = home.currentBillingPeriodStart;
                    this.subEnd = home.currentBillingPeriodEnd;
                } else {
                    this.showSnackbar({ message: `There was an error retrieving some of your settings.` });
                }

                if (currentInvoicesRes.status === 'fulfilled') {
                    this.currentInvoiceTable.events = currentInvoicesRes.value.data.reverse().map(event => {
                        return { ...event, isEditing: false };
                    });
                    this.invoiceTotal = this.getTotal(this.currentInvoiceTable.events);
                } else {
                    this.showSnackbar({ message: 'There was an error retrieving some of your settings.' });
                }

                this.loading = false;
            });
        },
        editBillingCard() {
            this.editCard = true;
        },
        getLocalDate(date) {
            const localDate = this.$moment(this.$moment.utc(date)).local();
            return localDate;
        },
        getStripeUrl(stripeId) {
            return `${process.env.VUE_APP_STRIPE_URL}/invoices/${stripeId}`;
        },
    },
    mounted() {
        this.createAxiosInstance();
        if (this.$auth.role.includes('SuperAdmin')) {
            this.currentInvoiceTable.headers.push({ text: 'Actions', value: 'actions', width: '10%', align: 'center' });
        }
        this.getBillingData();
    },
};
</script>

<style scoped>
a {
    color: inherit !important;
}

.v-textarea textarea {
    font-size: 0.875rem;
    line-height: 1.5 !important;
}
.prominentInfo {
    text-align: center;

    color: cornflowerblue;
    padding-bottom: 30px;
    border: 2px solid cornflowerblue;
    border-radius: 15px;
}
.prominentHeaders {
    margin-bottom: 1em;
}
.prominentNumbers {
    color: black;
}

.lessProminentInfo {
    color: gray;
}
.subscriptionBilling {
    /* border: 2px solid red; */
    margin-bottom: 20px;
}
.subscriptionPlan {
    /* border: 2px solid green; */
    margin-top: 40px;
    padding-bottom: 40px;
}

.subscriptionInfoBadge:hover {
    cursor: pointer;
}
.action-list {
    display: flex;
    gap: 10px;
    justify-content: center;
}
#tableWrapper .subscriptionInvoiceTable {
    min-height: 0;
}

#currentPlanBadge {
    background-color: white;
    border: 2px solid cornflowerblue;
    width: 170px;
    border-radius: 10px;
    padding: 5px;
    color: black;
}

.displayTotal {
    margin-top: -45px;
    border: 2px solid cornflowerblue;
    width: 25%;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-6">
            <div class="header">
                <h4 ref="leaveFeedbackEasterEgg">Leave Feedback</h4>
            </div>

            <div id="typeForm"></div>
        </div>
    </AppLayout>
</template>

<script>
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Feedback',
    },
    mounted() {
        createWidget('Ld7nlTBn', {
            container: document.querySelector('#typeForm'),
            hidden: {
                email: this.$auth.user.email,
            },
        });

        this.$refs.leaveFeedbackEasterEgg.addEventListener(
            'click',
            function (evt) {
                if (evt.detail === 3) {
                    this.copyTokenToClipboard();
                }
            }.bind(this),
        );
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyTokenToClipboard() {
            this.$auth.getIdTokenClaims().then(result => {
                const el = document.createElement('textarea');
                el.value = 'Bearer ' + result.__raw;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showSnackbar({ message: 'Token copied to clipboard' });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 3rem;
}

.tile {
    width: 70%;
    margin: 4rem auto 10%;
    padding: 2rem inherit;
}
#typeForm {
    height: 700px;
}
</style>

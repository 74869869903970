<template>
    <div>
        <v-dialog eager v-model="dialog" @click:outside="resetTrackingCode" max-width="800px">
            <v-card>
                <div class="modal-title ms-orange p-3">
                    <h4>Ad Campaign</h4>
                </div>

                <div
                    v-if="gatheringImpact"
                    style="
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    "
                >
                    <v-progress-circular size="100" indeterminate color="primary"></v-progress-circular>
                    <p class="mt-6">Gathering Estimated Impact</p>
                </div>

                <div v-else style="display: flex">
                    <div style="border: 1px solid #eee; display: flex; flex-direction: column">
                        <v-tabs id="tab-group" color="#ff530d" v-model="tab" vertical style="max-width: 120px">
                            <v-tab>
                                <template v-slot:default>
                                    <span :class="trackingCode.trackingCodeType == null ? '' : 'selectedTab'"
                                        >Provider</span
                                    >
                                </template>
                            </v-tab>
                            <v-tab>
                                <template v-slot:default>
                                    <span :class="trackingCode.locationScoped ? 'selectedTab' : ''">Location</span>
                                </template>
                            </v-tab>
                            <v-tab>
                                <template v-slot:default>
                                    <span class="selectedTab">Age</span>
                                </template>
                            </v-tab>
                            <v-tab>
                                <template v-slot:default>
                                    <span class="selectedTab">Date</span>
                                </template>
                            </v-tab>
                            <v-tab>
                                <template v-slot:default>
                                    <span :class="trackingCode.code == '' ? '' : 'selectedTab'">Code</span>
                                </template>
                            </v-tab>
                            <v-tab>
                                <template v-slot:default>
                                    <span :class="trackingCode.trackingCodeTrigger == null ? '' : 'selectedTab'"
                                        >Trigger</span
                                    >
                                </template>
                            </v-tab>
                            <!-- <v-tab>Location</v-tab>
                            <v-tab>Age</v-tab>
                            <v-tab>Date</v-tab>
                            <v-tab>Code</v-tab>
                            <v-tab>Trigger</v-tab> -->
                        </v-tabs>

                        <div class="p-3">
                            <v-btn v-if="tab == 0" outlined small @click="cancelTrackingCode">Cancel</v-btn>
                            <v-btn v-if="tab != 0" outlined small @click="tab--">Back</v-btn>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%">
                        <v-tabs-items v-model="tab" vertical style="flex-grow: 1">
                            <!-- START: Provider Tab -->

                            <v-tab-item style="min-height: 288px; display: flex; align-items: center">
                                <div class="company-options-wrapper p-5">
                                    <div
                                        id="option-container"
                                        class="company-option"
                                        v-for="(item, index) in trackingCodeTypes"
                                        :key="index"
                                    >
                                        <custom-tooltip :tooltipProps="{ bottom: true }">
                                            <template v-slot:activator>
                                                <div
                                                    v-if="item.value != 7"
                                                    @click="typeSelected(item)"
                                                    class="img-container"
                                                    :class="
                                                        trackingCode.trackingCodeType === item.value
                                                            ? 'selectedType'
                                                            : ''
                                                    "
                                                >
                                                    <img
                                                        :src="require('@/assets/images/' + item.logo)"
                                                        alt="Provider Logo"
                                                    />
                                                </div>

                                                <div
                                                    v-if="item.value == 7"
                                                    @click="typeSelected(item)"
                                                    class="img-container"
                                                    :class="
                                                        trackingCode.trackingCodeType === item.value
                                                            ? 'selectedType'
                                                            : ''
                                                    "
                                                    style="display: flex; flex-direction: column; align-items: center"
                                                >
                                                    <img
                                                        :src="require('@/assets/images/logo.png')"
                                                        alt="Provider Logo"
                                                    />
                                                    <div style="color: gray">Other</div>
                                                </div>
                                            </template>

                                            <template v-slot:content>
                                                <span>{{ item.label }}</span>
                                            </template>
                                        </custom-tooltip>
                                    </div>
                                </div>
                            </v-tab-item>

                            <!-- END: Provider Tab -->

                            <!-- START: Map Tab -->
                            <v-tab-item eager>
                                <div class="p-3 pb-0">
                                    <marketing-map
                                        v-if="trackingCode.latitude && trackingCode.longitude"
                                        @locationSelected="loc => locationSelected(loc)"
                                        @toggleScope="toggleLocationScope"
                                        mapId="modalMap"
                                        ref="modalMap"
                                        :location="{
                                            lat: trackingCode.latitude,
                                            long: trackingCode.longitude,
                                            rad: trackingCode.radius,
                                        }"
                                        :lockArea="false"
                                        :interactive="true"
                                        :locationScoped="trackingCode.locationScoped"
                                    />
                                    <!-- <div>testiing</div> -->

                                    <!-- <v-btn
                                        dark
                                        small
                                        style="position: absolute; width: 275px; bottom: 38px; right: 18px; z-index: 2"
                                        @click="toggleLocationScope"
                                        >{{
                                            trackingCode.locationScoped ? 'Target Everywhere' : 'Target Location'
                                        }}</v-btn
                                    > -->
                                </div>
                            </v-tab-item>
                            <!-- END: Map Tab -->

                            <!-- START: Age Tab -->
                            <v-tab-item
                                style="min-height: 288px; display: flex; align-items: center; justify-content: center"
                            >
                                <div
                                    class="p-3 pr-0"
                                    style="display: flex; flex-direction: column; min-width: 100%; min-height: 288px"
                                >
                                    <div class="pl-5 pr-5">
                                        <div style="display: flex; justify-content: space-between">
                                            <h5 class="pt-3">Who should we target?</h5>
                                        </div>
                                        <v-divider></v-divider>
                                    </div>
                                    <div class="pr-3" style="display: flex; min-height: 150px; align-items: center">
                                        <v-row>
                                            <v-col
                                                class="d-flex justify-content-center"
                                                v-for="(range, index) in ageRanges"
                                                :key="index"
                                            >
                                                <div
                                                    @click="selectAgePreset(range)"
                                                    :class="
                                                        range.min == trackingCode.minAge &&
                                                        range.max == trackingCode.maxAge
                                                            ? 'selectedType'
                                                            : ''
                                                    "
                                                    class="selection"
                                                >
                                                    <div>
                                                        <font-awesome-icon
                                                            style="font-size: 1.5rem"
                                                            :icon="range.icon"
                                                        />
                                                    </div>
                                                    <div v-if="index != 2">
                                                        <span>{{ range.min }} - </span>
                                                        <span v-if="range.max == -1" class="ml-1">
                                                            <font-awesome-icon icon="fa-regular fa-arrow-up"
                                                        /></span>
                                                        <span v-else class="ml-1"> {{ range.max }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span>All</span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="text-right">
                                        <v-btn
                                            @click="toggleCustomAgeRange"
                                            class="mt-4 mr-4"
                                            :class="customAgeRange ? 'custom-selected' : ''"
                                            x-small
                                            outlined
                                            >Custom Range</v-btn
                                        >
                                    </div>
                                    <v-row v-if="showCustomAgeRange" class="p-3">
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="trackingCode.minAge"
                                                type="number"
                                                label="From"
                                                min="0"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-if="trackingCode.maxAge == -1"
                                                disabled
                                                value="No Max"
                                            ></v-text-field>
                                            <v-text-field
                                                v-else
                                                v-model="trackingCode.maxAge"
                                                type="number"
                                                label="To"
                                                min="-1"
                                            ></v-text-field>
                                            <v-btn
                                                v-if="trackingCode.maxAge == -1"
                                                @click="setMaxAge(0)"
                                                dark
                                                color="#0c3c60"
                                                block
                                                >Set Max</v-btn
                                            >
                                            <v-btn v-else @click="setMaxAge(-1)" dark color="#0c3c60" block
                                                >Set No Max</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-tab-item>
                            <!-- START: Age Tab -->

                            <!-- START: Service Date Tab -->
                            <v-tab-item
                                style="min-height: 288px; display: flex; align-items: center; justify-content: center"
                            >
                                <div
                                    class="p-3 pr-0"
                                    style="display: flex; flex-direction: column; min-width: 100%; min-height: 288px"
                                >
                                    <div class="pl-5 pr-5">
                                        <div style="display: flex; justify-content: space-between">
                                            <h5 class="pt-3">What services should we target?</h5>
                                        </div>
                                        <v-divider></v-divider>
                                    </div>
                                    <div class="pr-3" style="display: flex; min-height: 150px; align-items: center">
                                        <v-row>
                                            <v-col
                                                class="d-flex justify-content-center"
                                                v-for="(range, index) in dateRanges"
                                                :key="index"
                                            >
                                                <div
                                                    @click="selectDatePreset(range)"
                                                    class="selection"
                                                    :class="
                                                        trackingCode.minServiceDate === range.min &&
                                                        trackingCode.maxServiceDate === range.max
                                                            ? 'selectedType'
                                                            : ''
                                                    "
                                                >
                                                    <div>
                                                        <font-awesome-icon
                                                            style="font-size: 1.2rem"
                                                            :icon="range.icon"
                                                        />
                                                    </div>
                                                    <span>{{ range.label }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="text-right">
                                        <v-btn
                                            @click="toggleCustomDateRange"
                                            class="mt-4 mr-4"
                                            :class="customDateRange ? 'custom-selected' : ''"
                                            x-small
                                            outlined
                                            >Custom Range</v-btn
                                        >
                                    </div>
                                    <v-row v-if="showCustomDateRange" class="p-3">
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="trackingCode.minServiceDate"
                                                type="number"
                                                label="From"
                                                min="0"
                                            >
                                                <template v-slot:append>
                                                    <span>Days</span>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-if="trackingCode.maxServiceDate == -1"
                                                disabled
                                                value="No Max"
                                            ></v-text-field>
                                            <v-text-field
                                                v-else
                                                v-model="trackingCode.maxServiceDate"
                                                type="number"
                                                label="To"
                                                min="-1"
                                            >
                                                <template v-slot:append>
                                                    <span>Days</span>
                                                </template></v-text-field
                                            >
                                            <v-btn
                                                v-if="trackingCode.maxServiceDate == -1"
                                                @click="setMaxDate(0)"
                                                dark
                                                color="#0c3c60"
                                                block
                                                >Set Max</v-btn
                                            >
                                            <v-btn v-else @click="setMaxDate(-1)" dark color="#0c3c60" block
                                                >No Max</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-tab-item>
                            <!-- END: Service Date Tab -->

                            <!-- START: Embed Code Tab -->
                            <v-tab-item eager>
                                <div class="pl-5 pr-5">
                                    <h5 class="pt-3">Pixel Code</h5>
                                    <v-divider></v-divider>
                                </div>
                                <div class="m-4 mt-0">
                                    <embed-code-highlight ref="codeInput" @updateValue="code => updateCode(code)" />
                                </div>
                            </v-tab-item>
                            <!-- END: Embed Code Tab -->

                            <!-- START: Trigger Tab -->
                            <v-tab-item
                                style="min-height: 288px; display: flex; align-items: center; justify-content: center"
                            >
                                <div
                                    style="display: flex; flex-direction: column; min-width: 100%; min-height: 288px"
                                    class="p-3"
                                >
                                    <div class="pl-5 pr-5">
                                        <h5 class="pt-3">What triggers this?</h5>
                                        <v-divider></v-divider>
                                    </div>
                                    <div style="display: flex; min-height: 150px; align-items: center">
                                        <v-row>
                                            <v-col
                                                class="d-flex justify-content-center"
                                                v-for="(trigger, index) in trackingCodeTriggers"
                                                :key="index"
                                            >
                                                <div
                                                    @click="selectTrigger(trigger.value)"
                                                    class="selection"
                                                    :class="
                                                        trackingCode.trackingCodeTrigger === trigger.value
                                                            ? 'selectedType'
                                                            : ''
                                                    "
                                                >
                                                    <div>
                                                        <font-awesome-icon
                                                            style="font-size: 1.2rem"
                                                            :icon="trigger.icon"
                                                        />
                                                    </div>
                                                    <span>{{ trigger.label }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-tab-item>
                            <!-- END: Trigger Tab -->
                        </v-tabs-items>

                        <div class="p-3 text-right">
                            <v-btn v-if="tab == 5" @click="submitTrackingCode" dark color="#0d3d60">Submit</v-btn>
                            <v-btn v-if="tab != 5" @click="tab++" dark color="#0d3d60">Next</v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { trackingCodeTypes, trackingCodeTriggers } from '@/constants';
import MarketingMap from '@/components/Marketing/MarketingMap.vue';
import EmbedCodeHighlight from '@/components/Misc/EmbedCodeHighlight.vue';
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            tab: 0,
            dialog: false,
            trackingCodeTypes,
            trackingCodeTriggers,
            showCustomAgeRange: false,
            showCustomDateRange: false,
            editing: false,
            ageRanges: [
                // { min: 0, max: 49, icon: 'fa-solid fa-child' },
                { min: 0, max: 49, icon: 'fa-solid fa-person' },
                { min: 50, max: -1, icon: 'fa-solid fa-person-cane' },
                { min: 0, max: -1, icon: 'fa-solid fa-infinity' },
            ],
            dateRanges: [
                { min: 0, max: 30, label: 'Month', icon: 'fa-solid fa-calendar-range' },
                { min: 0, max: 365, label: 'Year', icon: 'fa-solid fa-calendars' },
                { min: 0, max: -1, label: 'All', icon: 'fa-solid fa-infinity' },
            ],
            trackingCode: {
                funeralHomeId: null,
                locationScoped: true,
                latitude: null,
                longitude: null,
                radius: 25,
                ageScoped: false,
                minAge: 0,
                maxAge: -1,
                serviceDateScoped: true,
                minServiceDate: 0,
                maxServiceDate: -1,
                estimated30DayHits: 0,
                trackingCodeType: null,
                trackingCodeTrigger: 1,
                code: '',
            },
            selectedAges: [],
            gatheringImpact: false,
            tempImpact: 5,
        };
    },
    components: { MarketingMap, EmbedCodeHighlight, CustomTooltip },
    watch: {
        tab() {
            if (this.tab == 1) {
                setTimeout(() => {
                    this.$refs.modalMap.resizeMap();
                }, 100);
            }
            if (this.tab == 4) {
                this.$refs.codeInput.htmlWrapper = this.trackingCode.code;
            }
        },
    },
    computed: {
        disableSubmit() {
            if (this.trackingCode.trackingCodeTrigger == null || this.trackingCode.trackingCodeType == null) {
                return true;
            } else {
                return false;
            }
        },
        customAgeRange() {
            if (this.trackingCode.ageScoped) {
                for (let i = 0; i < this.ageRanges.length; i++) {
                    if (
                        this.trackingCode.minAge == this.ageRanges[i].min &&
                        this.trackingCode.maxAge == this.ageRanges[i].max
                    ) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        },
        customDateRange() {
            if (this.trackingCode.serviceDateScoped) {
                for (let i = 0; i < this.ageRanges.length; i++) {
                    if (
                        this.trackingCode.minServiceDate == this.dateRanges[i].min &&
                        this.trackingCode.maxServiceDate == this.dateRanges[i].max
                    ) {
                        return false;
                    }
                }
                return true;
            } else {
                return false;
            }
        },
    },
    props: ['token'],
    methods: {
        ...mapActions(['showSnackbar']),
        toggleLocationScope() {
            this.trackingCode.locationScoped = !this.trackingCode.locationScoped;
            if (!this.trackingCode.locationScoped) {
                this.$refs.modalMap.map.zoomTo(0, { duration: 2000 });
                this.$refs.modalMap.radius = 0;
                this.$refs.modalMap.showAreaSelection = false;
            }
            if (this.trackingCode.locationScoped) {
                this.$refs.modalMap.map.zoomTo(8, {
                    center: [this.trackingCode.longitude, this.trackingCode.latitude],
                    duration: 2000,
                });
                this.$refs.modalMap.radius = 25;
                this.$refs.modalMap.showAreaSelection = true;
            }
        },
        setMaxAge(val) {
            this.trackingCode.maxAge = val;
        },
        setMaxDate(val) {
            this.trackingCode.maxServiceDate = val;
        },
        resetTrackingCode() {
            this.tab = 0;
            this.trackingCode = {
                ...this.trackingCode,
                funeralHomeId: null,
                locationScoped: true,
                radius: 25,
                ageScoped: false,
                minAge: 0,
                maxAge: -1,
                serviceDateScoped: true,
                minServiceDate: 0,
                maxServiceDate: -1,
                estimated30DayHits: null,
                trackingCodeType: null,
                code: '',
            };
        },
        cancelTrackingCode() {
            this.dialog = false;
            this.resetTrackingCode();
        },
        getEstImpact(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TrackingCode/estimated-impact/${id}`)
                .then(response => {
                    this.tempImpact = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        createTrackingCode(data) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/TrackingCode`, data)
                .then(response => {
                    this.showSnackbar({
                        message: 'Tracking Code Created',
                    });
                    this.dialog = false;
                    this.resetTrackingCode();
                    // this.$emit('refresh');
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.$emit('refresh');
                });
        },
        updateTrackingCode(data) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/TrackingCode/${this.trackingCode.id}`, data)
                .then(response => {
                    this.showSnackbar({
                        message: 'Tracking Code Updated',
                    });

                    // this.$emit('refresh');

                    this.editing = false;
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    //Reload Map w/ new location data
                    var mapRef = 'map' + this.trackingCode.id;
                    // var selectedMap = this.$refs[mapRef][0];
                    // selectedMap.loadMap();
                    this.resetTrackingCode();
                    this.$emit('refresh');
                    this.dialog = false;
                });
        },
        async submitTrackingCode() {
            if (this.trackingCode.trackingCodeType == null) {
                this.showSnackbar({ message: 'Provider is required' });
                return;
            }
            if (this.trackingCode.trackingCodeTrigger == null) {
                this.showSnackbar({ message: 'Trigger is required' });
                return;
            }

            if (this.trackingCode.minServiceDate == 0 && this.trackingCode.maxServiceDate == 0) {
                this.trackingCode.serviceDateScoped = false;
            } else {
                this.trackingCode.serviceDateScoped = true;
            }

            var data = {
                ...this.trackingCode,
                funeralHomeId: parseInt(this.$route.params.id),
                latitude: this.trackingCode.latitude,
                longitude: this.trackingCode.longitude,
                radius: parseInt(this.trackingCode.radius) || 0,
                minAge: parseInt(this.trackingCode.minAge) || 0,
                maxAge: parseInt(this.trackingCode.maxAge) || 0,
                minServiceDate: parseInt(this.trackingCode.minServiceDate) || 0,
                maxServiceDate: parseInt(this.trackingCode.maxServiceDate) || 0,
                estimated30DayHits: parseInt(this.tempImpact) || 0,
                trackingCodeType: parseInt(this.trackingCode.trackingCodeType),
            };

            if (this.editing) {
                this.gatheringImpact = true;
                await this.getEstImpact(data.id);
                this.gatheringImpact = false;
                data.estimated30DayHits = this.tempImpact;
                this.updateTrackingCode(data);
            } else {
                const newCode = await this.createTrackingCode(data);

                this.gatheringImpact = true;
                await this.getEstImpact(newCode.id);
                this.gatheringImpact = false;
                data.estimated30DayHits = this.tempImpact;
                this.updateTrackingCode(data);
            }
        },
        selectTrigger(value) {
            this.trackingCode.trackingCodeTrigger = value;
        },
        locationSelected(loc) {
            this.trackingCode.locationScoped = true;
            this.trackingCode.longitude = loc.long;
            this.trackingCode.latitude = loc.lat;
            this.trackingCode.radius = loc.radius;
            this.showSnackbar({ message: 'Location Updated' });
            // this.tab = 2;
        },
        toggleCustomDateRange() {
            this.showCustomDateRange = !this.showCustomDateRange;
        },
        toggleCustomAgeRange() {
            this.showCustomAgeRange = !this.showCustomAgeRange;
        },
        updateCode(value) {
            this.trackingCode.code = value;
        },
        typeSelected(type) {
            this.trackingCode.trackingCodeType = type.value;
            this.tab = 1;
        },
        selectAgePreset(range) {
            this.showCustomAgeRange = false;
            this.trackingCode.minAge = range.min;
            this.trackingCode.maxAge = range.max;

            this.ageSelected();
        },
        ageSelected() {
            if (this.trackingCode.minAge > 0 || this.trackingCode.maxAge > 0) {
                this.trackingCode.ageScoped = true;
            }
            this.tab = 3;
        },
        selectDatePreset(range) {
            this.showCustomDateRange = false;
            this.trackingCode.minServiceDate = range.min;
            this.trackingCode.maxServiceDate = range.max;
            this.trackingCode.serviceDateScoped = true;
            this.tab = 4;
        },
    },
};
</script>
<style lang="scss" scoped>
.company-options-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
    min-height: 288px;
}
.stat-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 225px;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 5;

    background-color: rgba(255, 255, 255, 0.902);
}
.window {
    border: 2px solid #eee;
    border-radius: 10px;
    padding: 5px;
}
.selectedType {
    background-color: #cfeafe;
    border: 2px solid #cfeafe !important;
}

.img-container:hover {
    transition: 0.3s;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.383);
}
.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100px;
    width: 100px;

    border-radius: 10px;
    padding: 5px;
    transition: 0.3s;
    img {
        max-height: 100%;
        width: auto;
    }
}
.img-container-displayed {
    height: 70px;
    width: 70px;

    transition: 0.3s;
    img {
        max-height: 100%;
        width: auto;
    }
}
.dummyData {
    color: lightgray;
    img {
        opacity: 0.4;
    }
    .dummyMap {
        opacity: 0.4;
    }
}
.ms-orange {
    background-color: #ff530d;
}
.modal-title {
    color: white;
    height: 60px;
}

.selection {
    border: 2px solid #eee;
    border-radius: 10px;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}
.selection:hover {
    border: 2px solid rgba(238, 238, 238, 0);
    transition: 0.3s;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.383);
}

#description {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    flex-wrap: wrap;
}
.custom-selected {
    background-color: #cfeafe;
}
.selectedTab {
    color: #0c3c60;
}
</style>

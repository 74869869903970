<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn block color="secondary" v-bind="attrs" v-on="on" class="btn-override">
                Share Streaming Instructions
            </v-btn>
        </template>

        <v-card>
            <v-card-text class="pt-4" v-if="!loading">
                Email Address to send Instructions to
                <v-text-field
                    v-model="emailInstructionsAddress"
                    label="Email Address"
                    :rules="[v => !!v || 'Email Address is Required']"
                ></v-text-field>
            </v-card-text>

            <v-card-text class="pt-4" v-else> Sending... </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="handleEmailSend()"> Send </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'EmailInstructionsButton',
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            emailInstructionsAddress: '',
            loading: false,
            dialog: null,
            snackbar: false,
            message: '',
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleEmailSend() {
            this.loading = true;
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                // URI encode this

                let email = encodeURIComponent(this.emailInstructionsAddress);

                this.axios
                    .create({
                        baseURL: process.env.VUE_APP_BASE_URI,
                        timeout: 1000,
                        headers: { Authorization: `Bearer ${this.token}` },
                    })
                    .get(process.env.VUE_APP_API + `/events/${this.event.id}/send-stream-email?email=${email}`)
                    .then(response => {
                        this.loading = false;
                        this.dialog = false;
                        this.message = 'Success!';
                        this.snackbar = true;
                        this.showSnackbar({ message: 'Success!' });
                        this.emailInstructionsAddress = '';
                    })
                    .catch(error => {
                        this.loading = false;
                        this.loading = false;
                        this.showSnackbar({ message: 'Error!', color: 'error' });
                    });
            });
        },
    },
};
</script>

<template>
    <div style="padding: 1rem">
        <span v-if="logoSrc" @click="deleteLogo" class="delete-btn">
            <font-awesome-icon icon="fa-regular fa-circle-x" />
        </span>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />

        <div class="img-wrapper">
            <img v-if="logoSrc" :src="logoSrc" class="user-logo" />
        </div>

        <v-btn color="blue-grey" :loading="!Boolean(uppy)" class="white--text upload-btn" @click="handleOpen">
            <span>Upload Logo</span>
            <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-up-from-bracket" />
        </v-btn>
        <div v-if="uploadError" class="error-message">Upload Error</div>

        <v-dialog persistent v-model="errorModal" max-width="500px">
            <v-card>
                <div class="pl-3 pr-3 decorated-title">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Image To Large</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>
                <div class="p-3">
                    <v-card-text
                        >File size must be less than <strong>1MB</strong>. Please choose another image or resize this
                        one.

                        <br /><br />
                        <u>To resize image</u><br />
                        1. Click
                        <strong><a href="https://www.wix.com/tools/image-resizer" target="_blank">here</a></strong
                        ><br />
                        2.Select image to resize <br />
                        3. *If using PNG image: select 'Original' for Image Format* <br />
                        4. Click 'Resize Image' <br />
                        5. Download Image
                    </v-card-text>

                    <div class="text-center">
                        <v-divider></v-divider>
                        <v-btn width="50%" dark color="#005069" @click="errorModal = false">Close</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';

export default {
    name: 'LogoUpload',
    props: ['funeralHomeId'],
    data() {
        return {
            id: 'Dashboard',
            isOpen: false,
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                note: '1MB file size limit',
            },
            uppy: null,
            logoSrc: '',
            ready: true,
            valid: true,
            errorModal: false,
            uploadError: false,
        };
    },
    components: {
        DashboardModal,
    },
    async created() {
        const maxFileSize = 1050000; // 1 MB
        await this.setAuthToken();
        this.createAxiosInstance();
        this.logoSrc = await this.getLogoSrc();
        this.initUppy();
        // check image dimensions when added
        this.uppy.on('file-added', async file => {
            const imgDimension = await this.getImgDimension(file);
            if (/*imgDimension.height > 400 || imgDimension.width > 400 ||*/ imgDimension.fileSize > maxFileSize) {
                this.valid = false;
            } else {
                this.valid = true;
            }
        });
    },
    beforeDestroy() {
        this.uppy.close();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async getImgDimension(imgFile) {
            return new Promise(resolve => {
                let url = URL.createObjectURL(imgFile.data);
                let img = new Image();
                img.onload = function () {
                    URL.revokeObjectURL(img.src);
                    resolve({
                        width: img.width,
                        height: img.height,
                        fileSize: imgFile.size,
                    });
                };
                img.src = url;
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        deleteLogo() {
            return this.axiosInstance
                .delete(`funeralhomes/logo/${this.funeralHomeId}`)
                .then(response => {
                    this.logoSrc = '';
                    this.$emit('refresh', null);
                    document.getElementsByClassName('customLogo')[0].remove();

                    this.showSnackbar({ message: 'Logo Successfully Deleted' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error Deleting Logo' });
                    console.log(error);
                });
        },
        // checkForLogo(value) {
        //     this.axios
        //         .get(value)
        //         .then(response => {
        //             this.ready = true;
        //             this.$emit('refresh', null);
        //             this.$emit('logo-is-ready');
        //         })
        //         .catch(error => {
        //             console.log('img not ready, retrying..');
        //             setTimeout(() => {
        //                 this.checkForLogo(value);
        //             }, 5000);
        //         });
        // },
        getLogoSrc() {
            // this.ready = false;
            // this.$emit('logo-not-ready');
            this.$emit('refresh', null);
            return this.axiosInstance
                .get(`funeralhomes/logo/${this.funeralHomeId}`)
                .then(response => {
                    return response.data.logo;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        sanitizeFilename(filename) {
            // Replace spaces with hyphens
            let sanitized = filename.replaceAll(/\s+/g, '-');

            sanitized = sanitized.replaceAll('+', '-');
            // Use encodeURIComponent to escape characters that are not URL-safe

            return sanitized;
        },
        getUploadUrl(file) {
            const funeralHomeId = this.funeralHomeId;

            const sanitizedFileName = this.sanitizeFilename(file.name);

            return this.axiosInstance
                .get(`funeralhomes/logo/sas/${funeralHomeId}/${sanitizedFileName}`)
                .then(response => {
                    log('==== getUploadUrl() called ====', 'silver');
                    'response.data', response.data;

                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async initUppy() {
            // const MAX_FILE_SIZE = 1050000; // 1 MB
            const _ = this;

            this.uppy = new Uppy({
                id: 'logo-upload',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: ['image/*'],
                },
            }).use(UppyAddUploaderPlugin, {
                uploader: fileIds => {
                    if (!this.valid) {
                        this.uppy.cancelAll();
                        this.handleClose();
                        this.errorModal = true;
                        this.uploadError = true;
                    } else {
                        return new Promise(async (resolve, reject) => {
                            this.uploadError = false;
                            const file = this.uppy.getFile(fileIds[0]);
                            const { sas: uploadEndpoint, fileName } = await this.getUploadUrl(file);
                            const reader = new FileReader();
                            reader.readAsArrayBuffer(file.data);

                            reader.addEventListener('error', error => {});

                            reader.addEventListener('load', async () => {
                                try {
                                    await this.axios
                                        .create({
                                            headers: {
                                                'Content-Type': file.type,
                                                'x-ms-blob-type': 'BlockBlob',
                                            },
                                        })
                                        .put(uploadEndpoint, reader.result);
                                    await this.axiosInstance.post(
                                        `/funeralhomes/logo/${this.funeralHomeId}/${fileName}`,
                                    );
                                    this.logoSrc = await this.getLogoSrc();
                                    this.handleClose();
                                    this.showSnackbar({ message: 'Successfully uploaded logo' });

                                    this.uppy.cancelAll();
                                    resolve('done');
                                } catch (error) {
                                    console.error(error);
                                    reject(error);
                                }
                            });
                        });
                    }
                },
            });
        },

        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.img-wrapper {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.error-message {
    border-radius: 5px;
    position: absolute;
    bottom: 5px;
    width: 95%;
    text-align: center;
    background-color: #ff5252;
    color: white;
}
.user-logo {
    display: block;
    max-width: 250px;
    margin-bottom: 1rem;
    max-width: 100%;
    max-height: 100%;
}
.delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-bottom: -30px;
    margin-left: -190px;
    cursor: pointer;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #ffffff;
}
.upload-btn {
    position: absolute;
    bottom: 40px;
}

.decorated-title {
    color: white !important;
    background-color: #ff530d !important;

    // background-image: url('../../assets/images/SW125_MemoryShare_Final-15.png');
    // background-position: 95% 50%;
    // background-size: 10%;
    .text-container {
        width: 80%;
        word-break: break-word;
    }
    .img-container {
        width: 20%;
        max-width: 50px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
</style>

<template>
    <div class="page-container">
        <v-btn class="logout-btn" @click="logout"
            >Logout <font-awesome-icon icon="fa-regular fa-arrow-right-from-bracket" style="font-size: 1rem"
        /></v-btn>
        <div class="migration-notification">
            <img class="migration-notification__logo" src="../../assets/images/logo.png" />
            <v-alert class="mb-5" icon="mdi-shield-lock-outline" prominent text type="info">
                Whoops! This is the new MemoryShare portal, and your account is still linked to the old portal. Please
                contact
                <a class="migration-notification__link" href="mailto:support@memoryshare.com"
                    >support@memoryshare.com</a
                >
                to get setup on the new portal.
            </v-alert>
            <div class="migration-notification__btn-group">
                <v-btn
                    class="migration-notification__continue-btn"
                    x-large
                    href="https://portal.midweststreams.com"
                    color="primary"
                    >Continue to Old Portal</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index';

export default {
    name: 'MigrationNotification',
    metaInfo: {
        title: 'Migration Notification',
    },

    created() {
        if (store.state.topNavShowing) {
            store.dispatch('toggleTopNav', false);
        }
    },
    methods: {
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.logout-btn {
    align-self: flex-end;
}

.migration-notification {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    &__logo {
        width: 250px;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    &__link {
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__continue-btn {
        text-decoration: none;
    }
}
</style>

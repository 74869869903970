import ApiService from './api.service';

const TributeVideoService = token => {
    const api = ApiService.createInstance(token);

    return {
        getTributeVideoByEvent(eventId) {
            return api.get(`/TributeVideo/get-tribute-by-event/${eventId}`);
        },
        submitRender(tributeVideoId) {
            let data = {
                tributeId: tributeVideoId,
            };

            return api.post(`/TributeVideoRender/`, data);
        },
        adminUpdateTribute(id, dto) {
            return api.put(`/TributeVideo/tribute-admin-update/${id}`, dto);
        },
        getSharedUploadInfo(slug) {
            return api.get(`/TributeVideo/shared-upload-info/${slug}`);
        },
        getTributeToken(slug, pin) {
            return api.get(`/TributeVideo/tribute-token/${slug}/${pin}`);
        },
        invite(tributeId, data) {
            return api.post(`/TributeVideoPhoto/tribute-invite/${tributeId}`, data);
        },
        refreshTokenAndAllLinks(tributeId) {
            return api.post(`TributeVideo/refresh-token-and-all-links/${tributeId}`);
        },
        getCurrentServiceToken(tributeId) {
            return api.get(`TributeVideo/current-service-tribute-token/${tributeId}`);
        },
    };
};

export default TributeVideoService;

<template>
    <div>
        <dashboard-modal v-if="uppy" :uppy="uppy" :props="dashboardProps" :open="isOpen" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import UppyAddUploaderPlugin from '@/utilities/uppy-add-uploader-plugin.js';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { log } from '@/utilities/debug-helpers';
import { mapActions } from 'vuex';
import BlobService from '@/services/blob.service';

import { MEDIA_UPLOADER_TYPES, getUploaderEndpoints } from '@/constants';

export default {
    props: {
        // sasEndpoint: {
        //     type: String,
        //     required: true,
        // },
        // updateEndpoint: {
        //     type: String,
        //     required: true,
        // },
        itemId: {
            type: Number,
            required: false,
        },
        token: {
            type: String,
            required: true,
        },
        allowedFileTypes: {
            type: Array,
            required: false,
            default: function () {
                return ['image/*', '.pdf'];
            },
        },
        uploaderType: {
            type: Number,
            required: true,
            validator: value => Object.values(MEDIA_UPLOADER_TYPES).includes(value),
        },
    },
    data() {
        return {
            uppy: null,
            isOpen: false,
            tempFiles: [],
            imgUrl: '',
            // allowedTypes: ['image/*', '.pdf'],
            dashboardProps: {
                theme: 'light',
                onRequestCloseModal: this.handleClose,
                hideUploadButton: false,
                height: 450,
                showProgressDetails: true,
                browserBackButtonClose: true,
                closeModalOnClickOutside: true,
                proudlyDisplayPoweredByUppy: false,
                // note: '1MB file size limit',
            },
        };
    },
    components: {
        DashboardModal,
    },
    watch: {
        token() {
            if (this.token) {
                this.createAxiosInstance();
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleOpen() {
            this.isOpen = true;
        },
        handleClose() {
            this.isOpen = false;
            this.uppy.cancelAll();
        },
        async getUploadUrl(id, file) {
            return this.axiosInstance.get(`${this.endpoints.sas}/${id}/${file.name}`);
        },
        cleanFileName(file) {
            const lastDot = file.name.lastIndexOf('.');
            let cleanedName = file.name.substring(0, lastDot);
            cleanedName = cleanedName.replaceAll('_', ' ');
            return cleanedName;
        },
        getVideoFileDuration(file) {
            return new Promise((resolve, reject) => {
                const src = URL.createObjectURL(file.data);

                const videoElement = document.createElement('video');
                videoElement.src = src;

                videoElement.addEventListener('loadeddata', () => {
                    let duration = Math.floor(videoElement.duration);
                    return resolve(duration);
                    // The duration variable now holds the duration (in seconds) of the audio clip
                });
                videoElement.addEventListener('error', () => {
                    return reject(error);
                });
            });
        },
        async uploadFiles() {
            const startTime = performance.now();
            try {
                this.uppy.emit('upload-start', this.tempFiles);

                for (const file of this.tempFiles) {
                    await this.processFile(file);
                }

                const stopTime = performance.now();
                const uploadTime = stopTime - startTime;
                console.log(`Upload time for full photo batch: ${uploadTime.toFixed(2)} ms`);
                return 'done';
            } catch (error) {
                throw error;
            }
        },

        async processFile(file) {
            const startTime = performance.now();

            const {
                data: { sas, fileName },
            } = await this.getUploadUrl(this.itemId, file);

            await this.uploadToAzureStorage(sas, file);

            const updatedItem = await this.updateDatabaseItem(fileName, file);

            const stopTime = performance.now();
            const uploadTime = stopTime - startTime;
            console.log(`Upload time for ${file.name}: ${uploadTime.toFixed(2)} ms`);
            this.emitUploadSuccess(file, updatedItem);
        },

        async uploadToAzureStorage(sas, file) {
            await BlobService.upload(sas, file, {
                onUploadProgress: this.createProgressHandler(file),
            });
        },

        createProgressHandler(file) {
            return progressEvent => {
                this.uppy.emit('upload-progress', file, {
                    uploader: this,
                    bytesUploaded: progressEvent.loaded,
                    bytesTotal: progressEvent.total,
                });
            };
        },

        async updateDatabaseItem(fileName, file) {
            let data = { fileName };

            if (this.allowedFileTypes.includes('.mp4')) {
                const duration = await this.getVideoFileDuration(file);
                data.duration = duration;
            }

            return this.axiosInstance.put(
                `${this.endpoints.update}/${this.itemId}`,
                this.allowedFileTypes.includes('.mp4') ? data : JSON.stringify(fileName),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        },

        emitUploadSuccess(file, updatedItem) {
            const uploadResp = { status: 200 };
            this.uppy.emit('upload-success', file, uploadResp);
            this.$emit('upload-success', updatedItem.data);
        },
        initUppy() {
            this.uppy = new Uppy({
                id: 'image-uploader',
                debug: true,
                autoProceed: false,
                // logger: Uppy.debugLogger,
                allowMultipleUploadBatches: false,
                restrictions: {
                    // maxFileSize: MAX_FILE_SIZE,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: this.allowedFileTypes,
                },
            })
                .use(UppyAddUploaderPlugin, {
                    uploader: fileIds => {
                        this.uploadFiles()
                            .then(res => {
                                this.handleClose();
                                this.$emit('refresh');
                                this.showSnackbar({ message: 'Upload Complete' });
                            })
                            .catch(error => {
                                this.showSnackbar({ color: 'error', message: 'An error occurred during the upload' });
                            });
                    },
                })
                .on('file-added', file => {
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.uppy.getFiles();
                })
                .on('upload-success', (file, response) => {});
        },
    },
    async created() {
        this.endpoints = getUploaderEndpoints(this.uploaderType);
        if (!this.endpoints) {
            console.error('No endpoints found for the specified uploader type');
            return;
        }

        this.createAxiosInstance();
        this.initUppy();
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>
<style lang=""></style>

<template>
    <div>
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-arrow-up-wide-short" style="margin-right: 0.5rem" />
            <h3 class="m-0">Google Index</h3>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-text-field clearable label="Notify URL Update" v-model="url"></v-text-field>
        <div class="text-right">
            <v-btn depressed color="primary" @click="submitUpdate">Submit</v-btn>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            url: '',
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        submitUpdate() {
            if (!this.url) {
                this.showSnackbar({ message: 'URL cannot be null', color: 'error' });
                return;
            }

            let data = {
                url: this.url,
            };

            this.axiosInstance
                .post('/admin-tools/notify-url-updated', data)
                .then(resp => {
                    this.showSnackbar({ message: 'Notify update success' });
                })
                .catch(err => {
                    console.log(err, 'Error notifying update url');
                    this.showSnackbar({ message: 'Error notifying updated url', color: 'error' });
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
};
</script>
<style lang=""></style>

<template>
    <!-- Setting min height because inline calendars are getting cut off -->
    <div style="min-height: 550px">
        <v-form ref="form" style="x;">
            <v-row
                style="
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #d3d3d3;
                    background-color: rgba(128, 128, 128, 0.05);
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 20px;
                    padding-bottom: 15px;
                "
            >
                <h6><strong>Started On:</strong></h6>
                <v-col>
                    <calendar v-model="startDate" ref="startDate" :output-local="true" />
                </v-col>

                <h6><strong>Ends On:</strong></h6>
                <v-col style="width: 50%">
                    <calendar v-model="endDate" ref="startDate" :output-local="true" />
                </v-col>
                <!-- uploader -->

                <v-col>
                    <music-upload v-model="musicLicenseFile"></music-upload>
                </v-col>
            </v-row>

            <music-table :id="funeralHomeId"></music-table>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import MusicUpload from '@/components/Misc/MusicUpload';
import MusicTable from '@/components/Tables/MusicTable.vue';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    name: 'GeneralSettings',
    props: ['modal', 'admin', 'adminFuneralHomeId', 'id'],
    components: {
        MusicUpload,
        MusicTable,
        Calendar,
    },
    data() {
        return {
            funeralHomeId: 0,
            startDate: null,
            endDate: null,
            songs: [],
            token: '',
            musicLicenseFile: null,
        };
    },
    watch: {
        File() {
            this.getMusicLicenseDate();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        checkFocus() {
            console.log(this.$refs.startDate.focus, 'start');
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        /**
         * Update the date on input
         */
        updateDate() {
            let data = {
                startDate: this.startDate,
                endDate: this.endDate ? this.endDate : this.checkForLocal(new Date().toISOString()),
                musicLicenseFile: this.musicLicenseFile,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/copyrightmusic/license/${this.funeralHomeId}`, data)
                .then(response => {
                    this.getMusicLicenses();
                    this.getMusicLicenseDate();
                })
                .catch(error => console.error(error));
        },
        /**
         * Gets music licenses Date
         */
        getMusicLicenseDate() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/copyrightmusic/license/${this.funeralHomeId}`)
                .then(response => {
                    if (response.data.startDate) {
                        this.startDate = this.checkForLocal(response.data.startDate);
                        this.endDate = this.checkForLocal(response.data.endDate);
                    }
                    this.musicLicenseFile = response.data.musicLicenseFile;
                })
                .catch(error => console.error(error));
        },
        /**
         * Gets music licenses
         */
        getMusicLicenses() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/CopyrightMusic?funeralHomeId=${this.funeralHomeId}`)
                .then(response => {
                    if (response.data) {
                        this.songs = response.data.copyrightMusics;
                    }
                })
                .catch(error => console.error(error));
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;

            // Super Admins dont have a funeral home - but they see the id via the route params
            // this.funeralHomeId = this.$auth.funeralHomeId != null ? this.$auth.funeralHomeId : this.$route.params.id;
            this.funeralHomeId = this.id;

            // Query Initial Data
            this.getMusicLicenses();
            this.getMusicLicenseDate();
        });
    },
};
</script>

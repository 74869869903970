<template>
    <AppLayout :has-background="true" :contained="true">
        <v-btn
            v-if="$auth.role.includes('SuperAdmin')"
            class="mb-5"
            @click="$router.push({ path: `/ecom?tab=purchases` })"
            ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Back</v-btn
        >
        <v-btn v-else class="mb-5" @click="$router.back()"
            ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Back</v-btn
        >

        <h3 style="color: white">Purchase Details</h3>

        <div v-if="$auth.role.includes('SuperAdmin')">
            <div class="elevation-3 p-4 bg-white rounded">
                <div v-if="purchase">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="flex-div">
                                <span>Purchase ID</span>
                                <span>{{ purchase.id }}</span>
                            </div>
                            <div class="flex-div">
                                <span>Funeral Home Id</span>
                                <span>{{ purchase.funeralHomeId }}</span>
                            </div>

                            <div class="flex-div">
                                <span>Name</span>
                                <span>{{ purchase.customerName }}</span>
                            </div>
                            <div class="flex-div">
                                <span>Email</span>
                                <span>{{ purchase.customerEmail }}</span>
                            </div>
                            <div class="flex-div">
                                <span>Created</span>
                                <span>{{ $moment.utc(purchase.createdDate).local().format('ll') }}</span>
                            </div>
                            <div class="flex-div">
                                <span>Total</span>
                                <span v-if="purchase.amount">{{ purchase.amount | formatMoney() }}</span>
                            </div>
                            <div v-if="$auth.role.includes('SuperAdmin')" class="text-right">
                                <v-chip
                                    v-if="purchase.customerType >= 0"
                                    :color="customerTypes[purchase.customerType].color"
                                    dark
                                >
                                    {{ customerTypes[purchase.customerType].label }}
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div style="display: flex; flex-direction: column">
                                <v-btn color="#005069" dark small @click="openStatusPage">Status Page</v-btn>
                                <v-btn
                                    v-if="purchase.serviceSlug"
                                    class="mt-2"
                                    small
                                    dark
                                    color="#ff530d"
                                    @click="$router.push({ path: `/services/${purchase.serviceSlug}` })"
                                    >service</v-btn
                                >
                                <v-btn class="mt-2" color="#625afa" dark small @click="openStripeCustomer()"
                                    >View Customer</v-btn
                                >

                                <v-btn class="mt-2" color="#625afa" dark small @click="openStripePayment()"
                                    >View Payment</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div>
                <h3 class="mt-5">Orders</h3>
                <div class="mt-5 elevation-3 p-4 bg-white rounded" v-for="order in purchase.orders">
                    <OrderStatusDetails :order="order" />
                </div>
            </div>

            <!-- <div class="mt-5 elevation-3 p-4 bg-white rounded">Shipments</div> -->
        </div>
    </AppLayout>
</template>
<script>
import moment from 'moment';
import { orderStatuses, paymentStatuses, customerTypes } from '@/constants';
import OrderStatusDetails from '@/components/Shop/OrderStatusDetails.vue';
export default {
    metaInfo: { title: 'Purchase Details' },
    data() {
        return {
            orderStatuses,
            paymentStatuses,
            customerTypes,
            purchase: {
                orders: [],
            },
            orderHeaders: [
                { text: 'Order', value: 'id' },
                { text: 'Purchase', value: 'purchaseId' },
                { text: 'Supplier', value: 'supplierId' },
                { text: 'Created', value: 'createdDate' },
                // { text: 'Shipped', value: 'shippedDate' },
                // { text: 'Tracking', value: 'trackingNumber' },
                { text: 'Amount', value: 'amount' },
                { text: 'Status', value: 'orderStatus' },
            ],
        };
    },
    components: { OrderStatusDetails },
    methods: {
        openStatusPage() {
            window.open(`/purchase-status/${this.purchase.purchaseSlug}`, '_blank');
        },
        openStripeCustomer() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/customers/${this.purchase.stripeCustomerId}`, '_blank');
        },
        openStripePayment() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/payments/${this.purchase.stripePaymentIntentId}`, '_blank');
        },
        goToOrder(item) {
            this.$router.push({ path: `/order-details/${item.id}` });
        },
        goToPurchases() {
            this.$router.push({ path: '/shop-settings?tab=purchases' });
        },
        getPurchaseDetails() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/Order/get-purchase/${this.$route.params.id}`)
                        .then(response => {
                            this.purchase = response.data;
                            console.log(this.purchase);
                        })
                        .catch(error => {
                            console.log(error, 'error');
                        });
                });
            }
        },
    },
    mounted() {
        this.getPurchaseDetails();
    },
};
</script>
<style>
.flex-div {
    display: flex;
    justify-content: space-between;
}
</style>

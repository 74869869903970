<template>
    <div>
        <div v-if="$auth.role.includes('SuperAdmin')" class="danger-zone p-3">
            <h6>DANGER ZONE</h6>
            <div style="display: flex; justify-content: space-between; align-items: flex-end">
                <div>Once you delete an owner there is no going back. Please be certain.</div>
                <v-btn class="ml-4" @click="openDeleteModalOne" dark color="error">Delete Owner</v-btn>
            </div>
        </div>

        <v-dialog v-model="deleteModalOne" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Warning!</v-card-title>

                <v-card-text class="text-center mt-1">
                    Delete Owner?<br />
                    <strong>{{ ownerName }}</strong> : <strong>{{ ownerId }}</strong></v-card-text
                >

                <v-card-text
                    >Deleting an owner deletes all billing data. You should never need to delete an owner, unless it was
                    never used or just a test account.
                </v-card-text>
                <v-card-text v-if="!$auth.role.includes('God')"
                    >NOTICE: <strong>You are not God.</strong> Please Contact Shane or Kyle to Delete
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModalOne = false"> Cancel </v-btn>
                    <v-btn @click="handleDeleteModalProceed" color="red darken-1" text> Proceed </v-btn>
                    <!-- :disabled="!$auth.role.includes('God')" -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModalTwo" :retain-focus="false" persistent max-width="290">
            <v-card>
                <v-card-title class="headline"> Confirmation </v-card-title>

                <v-card-text>
                    Please Enter Owner Name to Delete <strong>{{ ownerName }}</strong>
                    <v-text-field v-model="deleteConfirmationText" single-line hide-details></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModalTwo = false"> Cancel </v-btn>
                    <v-btn
                        color="red darken-1"
                        :disabled="ownerName != deleteConfirmationText || !$auth.role.includes('God')"
                        @click="deleteOwner"
                        text
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            deleteModalOne: false,
            deleteModalTwo: false,
            deleteConfirmationText: '',
            token: '',
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        openDeleteModalOne() {
            this.deleteModalOne = true;
        },
        handleDeleteModalProceed() {
            this.deleteModalOne = false;
            this.deleteModalTwo = true;
        },
        deleteOwner() {
            // if (this.$auth.role.includes('God')) {
            if (this.deleteConfirmationText !== this.ownerName) {
                this.showSnackbar({ message: 'Text does not match!', color: 'error' });
                return;
            } else {
                this.deleteConfirmationText = '';
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + `/Owners/${this.ownerId}`)
                    .then(result => {
                        this.showSnackbar({ message: `${this.ownerName} Deleted` });
                        this.$router.push({ name: 'Owners' });
                        this.$router.go(0);
                    })
                    .catch(error => {
                        this.showSnackbar({
                            message: `Error Deleting Owner. Please try again`,
                        });
                    });
                this.deleteModalTwo = false;
            }
            // }
        },
    },
    props: ['ownerId', 'ownerName'],
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
        });
    },
};
</script>
<style>
.danger-zone {
    background-color: #eee;
    border: 1px solid red;
    border-radius: 5px;
}
</style>

<template>
    <div
        :id="`video-wrap-${template.id}`"
        class="videoPlayer-wrap p-1"
        @click="$emit('click')"
        @dblclick="$emit('dblclick')"
    >
        <div v-if="!template.url || template.title == 'No Theme'">
            <video class="empty-video"></video>
            <div v-if="template.title != 'No Theme'" class="video-overlay">
                <span style="color: white; font-size: 0.9rem">No demo available</span>
            </div>
        </div>

        <div class="preview-container" v-else>
            <v-chip v-if="showTags && template.staffFavorite" class="staff-label" dark small color="#ff530d">
                Staff Pick
            </v-chip>
            <video
                muted
                :poster="handleThumbnail(template.url)"
                preload="metadata"
                ref="themeVideoList"
                :id="`intro-video-${template.id}-${componentId}`"
                class="videoPlayer"
                v-if="template.url"
                :src="template.url"
                @mouseover="handleMouseOver(template)"
                @mouseout="handleMouseOut(template)"
            >
                Your browser does not support HTML video.
            </video>
        </div>
        <div class="p-2 pb-0">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <strong>
                    {{ template.title }}
                </strong>
                <div class="d-flex">
                    <font-awesome-icon
                        v-if="isSelected"
                        class="ml-2"
                        style="font-size: 1.2rem; color: #2096f3"
                        icon="fa-regular fa-circle-check"
                    />
                    <span class="ml-2">{{ template.duration | formatTimeStamp }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            componentId: uuidv4(),
        };
    },
    props: {
        template: {
            type: Object,
            default: () => {},
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        showTags: {
            type: Boolean,
            default: false,
        },
    },

    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
    methods: {
        handleThumbnail(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //https://ik.imagekit.io/demo/sample-video.mp4/ik-thumbnail.jpg
            //https://ik.imagekit.io/memoryshare/templates/fa6da4d5-b142-40ac-a67f-efdc1acd0523.mp4/ik-thumbnail.jpg?tr=so-5

            const srcPath = url.split('/tribute-template-renders/')[1];
            const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
            const poster = `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${thumbNailMod}`;

            return poster;
        },
        handleMouseOver(item) {
            this.hoveredVideo = { ...item, loading: true };
            this.hoverTimeout = setTimeout(() => {
                const video = document.getElementById(`intro-video-${item.id}-${this.componentId}`);
                if (video) {
                    video.load();
                    this.fetchVideoAndPlay(video, item);
                }
            }, 1000);
        },
        handleMouseOut(item) {
            clearTimeout(this.hoverTimeout);
            const video = document.getElementById(`intro-video-${item.id}-${this.componentId}`);

            if (video) {
                video.pause();
                video.currentTime = 2;
            }
            this.hoveredVideo = null;
        },
        fetchVideoAndPlay(video, item) {
            //Check for good response from url
            this.axios
                .get(item.url, {
                    responseType: 'blob',
                })
                .then(resp => {
                    video.currentTime = 0.2;
                    //play video
                    return video.play();
                })
                .then(_ => {
                    //When play promise resolves, check the mouse out event has not happened before resolve / reject
                    //If so, reset video
                    if (this.hoveredVideo?.id != item.id) {
                        video.pause();
                        video.currentTime = 2;
                    } else {
                        this.hoveredVideo.loading = false;
                    }
                })
                .catch(error => {
                    console.log(error, 'error starting playback');
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.preview-container {
    position: relative;
    max-width: 100%;
}
video {
    max-height: 100%;
    max-width: 100%;
    display: block;
}

.videoPlayer {
    border-radius: 10px;
}

.video-title {
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid transparent;
    margin-top: 5px;
    transition: 0.3s;
}

.videoPlayer-wrap {
    border: 2px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    // padding: 8px;
    border-radius: 10px;
    // border: 2px solid blue;
}
.videoPlayer-wrap:hover {
    background-color: #f2f2f2;
    transition: 0.3s;
}
.empty-video {
    background-color: black;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
}
.video-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.videoPlayer-wrap:hover .preview-btn {
    opacity: 0.4;
    // border: 2px solid red;
}

.selected {
    background-color: #ecf3fb !important;
    // border: 2px solid #1877f2 !important;
    border: 2px solid #2096f3 !important;
    transition: 0.3s;
}

.hovering {
    filter: brightness(50%);
    transition: 0.3s;
}
.img-wrapper {
    border-radius: 10px;
    margin: 2px;
    cursor: pointer;
    transition: 0.3s;
    .photo {
        border-radius: 10px;
    }
}

.selected-image-column {
    height: 279px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.photo {
    max-width: 100%;
    max-height: 100%;
}
.custom-green {
    color: #00c853 !important;
}

.staff-label {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 5;
}
</style>

<template>
    <div>
        <v-text-field class="mb-2" background-color="#f8f8f8" solo flat v-model="search" label="Search Music">
            <template v-slot:append>
                <font-awesome-icon icon="fa-regular fa-magnifying-glass"></font-awesome-icon>
            </template>
        </v-text-field>

        <v-divider style="margin: 0"></v-divider>
        <v-expansion-panels style="cursor: default; padding-top: 0; padding-bottom: 0" readonly flat>
            <v-expansion-panel dense readonly>
                <v-expansion-panel-header dense>
                    <v-row>
                        <v-col cols="6"><span class="ml-7">Title</span></v-col>
                        <v-col cols="3"> Artist </v-col>
                        <!-- <v-col cols="1">Genres</v-col>
                        <v-col cols="1">Keywords</v-col> -->
                        <v-col>Duration</v-col>
                    </v-row>
                    <template v-slot:actions>
                        <div style="width: 24px"></div>
                    </template>
                </v-expansion-panel-header>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider style="margin: 0"></v-divider>
        <v-expansion-panels class="mt-2" v-model="openedPanel" readonly accordion flat>
            <v-expansion-panel
                active-class="active-panel"
                class="music-panel"
                @click="toggleSelected(song)"
                v-for="(song, i) in songs"
                :key="i"
            >
                <v-expansion-panel-header class="panel-header">
                    <v-row>
                        <v-col cols="6">
                            <div class="column-text">
                                <font-awesome-icon
                                    @click.stop="toggleSelected(song)"
                                    class="mr-2"
                                    style="color: #91a6b6"
                                    :icon="checkSelectedSong(song) ? 'fa-solid fa-circle' : 'fa-regular fa-circle'"
                                ></font-awesome-icon>

                                {{ song.title }}
                            </div>
                        </v-col>
                        <v-col class="column-text" cols="3">
                            {{ song.artist }}
                        </v-col>

                        <!-- <v-col cols="1">
                            <v-menu open-on-hover top offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip color="#91a6b6" dark v-bind="attrs" v-on="on" label>
                                        {{ song.musicGenres.length }}</v-chip
                                    >
                                </template>

                                <v-list>
                                    <v-list-item v-for="(item, index) in song.musicGenres" :key="index">
                                        <v-chip color="#ff530d" dark> {{ item.name }}</v-chip>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col> -->
                        <!-- <v-col cols="1">
                            <v-menu open-on-hover top offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip color="#91a6b6" dark v-bind="attrs" v-on="on" label>
                                        {{ song.musicKeywords.length }}</v-chip
                                    >
                                </template>

                                <v-list>
                                    <v-list-item v-for="(item, index) in song.musicKeywords" :key="index">
                                        <v-chip color="#ff530d" dark> {{ item.name }}</v-chip>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col> -->
                        <v-col class="column-text">
                            {{ song.duration | durationTimeStamp }}
                        </v-col>
                    </v-row>

                    <template v-slot:actions>
                        <div style="width: 100%; height: 100%">
                            <v-btn small text @click.stop="handlePanelAction(song, i)">
                                <font-awesome-icon
                                    :icon="i == openedPanel ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
                                ></font-awesome-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="panel-content">
                    <div class="p-3"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="d-flex justify-space-between">
            <div>
                <v-btn v-if="options.page != 1" small text @click="options.page--">
                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                </v-btn>
            </div>
            <div>
                <span style="font-size: 0.9rem">{{ currentResultsStart }} - {{ currentResultsEnd }}</span>
            </div>
            <div>
                <v-btn
                    v-if="currentResultsEnd >= totalSongs == false"
                    :disabled="currentResultsEnd >= totalSongs"
                    small
                    text
                    @click="options.page++"
                >
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { debounceV2 } from '@/utilities/debounce.js';
export default {
    data() {
        return {
            songs: [],
            keywords: [],
            totalSongs: 0,
            genres: [],
            totalGenres: [],
            keywords: [],
            totalKeywords: [],
            expanded: [],
            token: '',
            // search: '',
            loading: true,
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            search: '',
            singleExpand: true,
            openedPanel: null,
            headers: [
                // { text: 'Id', value: 'id' },
                // { text: 'Selected', value: 'selected', sortable: false },
                { text: 'Title', value: 'title' },
                { text: 'Artist', value: 'artist' },
                { text: 'Album', value: 'album' },
                { text: 'Genres', value: 'musicGenres', sortable: false },
                { text: 'Keywords', value: 'musicKeywords', sortable: false },
                // { text: 'Create Date', value: 'createDate' },
                // { text: 'Duration', value: 'duration' },
                // { text: 'Audio', value: 'playableLink', align: 'center', sortable: false },
                // { text: '', value: 'data-table-expand' },
                // { text: 'Actions', value: 'actions', align: 'center' },
            ],
        };
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        // expandedItem() {
        //     // return the component to render in the expandable content for the current expanded item
        //     if (this.expanded.length) {
        //         const item = this.desserts[this.expanded[0]];
        //         return {
        //             component: item.component,
        //             props: {
        //                 item: item,
        //             },
        //         };
        //     }
        //     return null;
        // },
        menuShowing() {
            return this.$store.state.menuShowing;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        currentResultsStart() {
            return this.options.itemsPerPage * this.options.page - this.options.itemsPerPage;
        },
        currentResultsEnd() {
            return this.options.itemsPerPage * this.options.page - this.options.itemsPerPage + this.songs.length;
        },
    },
    watch: {
        search: debounceV2(function () {
            this.options.page = 1;

            this.getSongs();
        }, 500),
        'options.page': debounceV2(function () {
            this.getSongs();
        }, 500),
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedSongs']),
        handlePanelAction(song, i) {
            if (this.openedPanel == i) {
                this.openedPanel = null;
            } else {
                this.openedPanel = i;
            }
        },
        rowClass(item) {
            const found = this.expanded.find(x => x.id == item.id);
            if (found) {
                const rowClass = 'table-item';
                return rowClass;
            }
        },
        checkSelectedSong(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            if (found) {
                return true;
            } else {
                return false;
            }
        },
        updateSelectedSongs(id, json) {
            this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, json)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        toggleSelected(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            var listClone = [...this.selectedSongs];
            if (found) {
                const indexToRemove = listClone.indexOf(found);
                listClone.splice(indexToRemove, 1);
                for (let i = indexToRemove; i < listClone.length; i++) {
                    listClone[i].order = i;
                }
            } else {
                listClone.push({ ...item, order: this.selectedSongs.length });
            }
            this.updateTributeVideoSelectedSongs(listClone);
            this.updateSelectedSongs(this.tributeVideo.id, listClone);
        },
        getSelectedSongs(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    this.updateTributeVideoSelectedSongs(resp.data.songs);
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        getKeywords() {
            return this.axiosInstance
                .get('/MusicKeyword?pageSize=100')
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getGenres() {
            return this.axiosInstance
                .get('/MusicGenre?pageSize=100')
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'title':
                    return 'Title';
                case 'id':
                    return 'Id';
                case 'artist':
                    return 'Artist';
                case 'album':
                    return 'Album';
                case 'createDate':
                    return 'CreateDate';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        async getSongs() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
            };

            return this.axiosInstance
                .get(`TributeVideoSong?${this.genreParamString}${this.keywordParamString}`, { params: params })
                .then(res => {
                    this.songs = res.data.tributeSongs;
                    this.totalSongs = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        await this.getGenres();
        await this.getKeywords();
        this.getSongs();
    },
    mounted() {
        // this.songs.forEach(item => {
        //     this.loadExpandedComponent(item);
        // });
    },
};
</script>
<style lang="scss">
.music-panel:hover {
    background-color: #dbe2e8 !important;
    cursor: pointer;
}

.active-panel {
    .panel-header {
        border-radius: 10px 10px 0 0;
        background-color: #dbe2e8;
    }
    .panel-content {
        border-radius: 0 0 10px 10px;
        background-color: #f8fafb;
    }
}

.expanded-content {
    background-color: #f8fafb;
}

.table-item {
    background-color: #dbe2e8;
    border-radius: 10px;
}

.column-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

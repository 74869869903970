<template>
    <div class="event-action-container">
        <nav class="event-action-tab-container">
            <li class="menu-entry" :class="nonLiveIndex == 0 && 'active'" @click="nonLiveIndex = 0">Preview</li>
            <li class="menu-entry" :class="nonLiveIndex == 1 && 'active'" @click="nonLiveIndex = 1">Health</li>
            <li class="menu-entry" :class="nonLiveIndex == 2 && 'active'" @click="nonLiveIndex = 2">Connection</li>
        </nav>
        <div class="event-action-tab-content">
            <!-- START: Default Tab -->
            <section v-if="nonLiveIndex === 0" class="default-button-container">
                <event-viewer class="p-4" :src="event.liveURL" :isLiveStream="true" :event="event" />
                <v-btn large color="warning" @click="warningLiveStreamEnd = true">Stop Live Stream</v-btn>
            </section>
            <!-- END: Default Tab -->

            <!-- START: Health Tab -->
            <section v-if="nonLiveIndex === 1">
                <wowza-event-chart
                    v-if="connection.cloudStreamingProvider == 0"
                    :live="event.eventStatus == 2"
                    :token="token"
                    ref="wowzaChart"
                    :event="event"
                />
                <div class="text-center" v-if="connection.cloudStreamingProvider != 0">Health Chart Not Available</div>
            </section>
            <!-- END: Health Tab -->

            <!-- START: Connections Tab -->
            <section v-if="nonLiveIndex === 2">
                <ConnectionFields :connection="connection" :event="event" />
                <div v-if="this.streamingProvider === 1" style="display: flex; justify-content: center">
                    <qrcode-vue :value="qrValue" size="150" />
                </div>
            </section>
            <!-- END: Connections Tab -->

            <!-- START: Connections Tab -->
            <section v-if="nonLiveIndex === 3" class="add-video-container">
                <v-btn @click="handleButtonClick" class="mb-2" color="secondary"> Add Video Files </v-btn>
            </section>
            <!-- END: Connections Tab -->

            <!-- START: LIVE STREAM WARNING DIALOG -->
            <v-dialog v-model="warningLiveStreamEnd" persistent max-width="400">
                <v-card>
                    <v-card-title class="headline text-center"> </v-card-title>
                    <v-card-text>
                        <h1 class="text-center">Warning!</h1>
                        <p class="text-center">Are you sure you wish to stop the live stream?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="warningLiveStreamEnd = false"> Cancel </v-btn>
                        <v-btn @click="endLiveStream" color="primary" text> Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- END: LIVE STREAM WARNING DIALOG -->
        </div>
    </div>
</template>

<script>
import EmailInstructionsButton from '@/components/ManageService/buttons/EmailInstructions.vue';
import ConnectionFields from '@/components/ManageService/ConnectionFields.vue';
import WowzaEventChart from '@/components/charts/WowzaEventChart.vue';
import EventViewer from '@/components/videojs/EventViewer.vue';
import { mapActions } from 'vuex';
import QrcodeVue from 'qrcode.vue';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            qrValue: '',
            streamingProvider: '',
            nonLiveIndex: 0, // Tabs when event isn't live
            liveIndex: 0, // Tabs when event IS live
            connection: {}, // Info about the connection such as RTMP Name etc.
            token: '',
            wowzaChart: '',
            warningLiveStreamEnd: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleButtonClick: function () {
            this.$router.push({ path: `/services/upload/${this.event.id}` });
        },
        /**
         * Fetches connection information
         */
        fetchConnections: function () {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                // Make Request
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/live/${this.event.liveStreamId}`)
                    .then(response => {
                        this.connection = response.data;
                        this.assignQrValue();
                    });
            });
        },

        /**
         * END THE STREAM
         */
        endLiveStream() {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;
                const url = process.env.VUE_APP_API + `/live/pause/${this.event.liveStreamId}`;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(url)
                    .then(response => {
                        this.warningLiveStreamEnd = false;
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.warningLiveStreamEnd = false;

                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data.Message,
                        });
                    });
            });
        },
        /**
         * Activate Stream Monitoring
         */
        monitorStreamStatus() {
            // liveStreamStatus 0 - no 1 - live

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/live/get-live-health-chart/${this.event.liveStreamId}`)
                .then(response => {
                    if (response.data) {
                        if (response.data.current.connected.value === 'Yes') {
                            this.liveStreamStatus = 1;
                            if (this.$refs.livePreview) {
                                this.$refs.livePreview.loading = false;
                                this.$refs.livePreview.init();
                            }
                        }
                    }
                })
                .catch(error => {
                    //temp
                    clearInterval(interval);
                    this.liveStreamStatus = 1;
                });
        },
        assignQrValue() {
            const username = this.connection.userName;
            const pass = this.connection.password;
            const streamName = this.connection.streamName;
            const newurl = encodeURIComponent(this.connection.primaryServer) + '/' + streamName;

            const QRCode = `larix://set/v1?conn[][url]=${newurl}&conn[][name]=${streamName}&conn[][mode]=av&conn[][user]=${username}&conn[][pass]=${pass}&conn[][target]=rtmp`;
            this.qrValue = QRCode;
        },
        getUserSettings() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/admin/${this.event.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                this.$emit('message', error.response.data);
                                reject(error);
                            });
                    } else {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/${this.$auth.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                });
            });
        },
    },
    mounted() {
        if (this.event) {
            this.getUserSettings().then(response => {
                this.streamingProvider = response.streamingProvider;
            });
            this.fetchConnections();
        }

        // Start Wowza
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;
        });
    },
    components: { EmailInstructionsButton, WowzaEventChart, EventViewer, ConnectionFields, QrcodeVue },
};
</script>

<style lang="scss" scoped>
.event-action-tab-container {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;

    .menu-entry {
        list-style: none;
        padding: 0.5rem 1rem;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .menu-entry.active {
        border-bottom: 4px solid rgb(78, 184, 226);
    }
}

.default-button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.connection-entry {
    width: 100%;
    position: relative;
    cursor: pointer;
    margin: 1rem 0;

    .entry-title {
        size: 0.75rem;
        color: gray;
    }

    .content {
        background: rgb(231, 231, 231);
        padding: 0.5rem;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 3rem;
    }
    .icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
    }
}

.add-video-container {
    display: flex;
    justify-content: center;
}
</style>

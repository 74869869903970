<template>
    <AppLayout :has-background="true" :contained="true">
        <div style="display: flex; justify-content: center">
            <v-card id="wrapper">
                <section v-if="!error" id="container"></section>
                <div class="text-center" v-if="error">No report is available at this time.</div>
                <v-skeleton-loader v-if="loading" height="900px" type="table"></v-skeleton-loader>
                <div class="text-right mt-3">
                    <v-btn v-if="!error" dark color="green" @click="fullscreen()"
                        >Fullscreen <font-awesome-icon class="ml-2" icon="far fa-expand"></font-awesome-icon
                    ></v-btn>
                </div>
            </v-card>
        </div>
    </AppLayout>
</template>

<script>
import * as pbi from 'powerbi-client';
export default {
    metaInfo: {
        title: 'Report',
    },
    data() {
        return {
            message: '',
            ownerSettings: '',
            owner_id: '',
            loading: true,
            error: false,
            dashboardFrame: null,
        };
    },
    methods: {
        backToSettings() {
            this.$router.go(-1);
        },
        fullscreen() {
            const fullscreen = document.querySelector('#container');
            fullscreen.requestFullscreen();
        },
        getOwnerReport() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.owner_id = this.$route.params.id;
            } else {
                this.owner_id = this.$auth.user[`https://memoryshare.com/owner_id`];
            }

            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/PowerBi/embed-report/${this.owner_id}`)
                    .then(response => {
                        this.ownerSettings = response.data;

                        // Config Embed
                        const permissions = pbi.models.Permissions.All;
                        const config = {
                            type: 'report',
                            tokenType: pbi.models.TokenType.Embed,
                            accessToken: this.ownerSettings.EmbedToken.Token,
                            TokenId: this.ownerSettings.EmbedToken.TokenId,
                            embedUrl: this.ownerSettings.EmbedReport[0].EmbedUrl,
                            pageView: 'fitToWidth',
                            permissions: permissions,
                        };

                        let powerbi = new pbi.service.Service(
                            pbi.factories.hpmFactory,
                            pbi.factories.wpmpFactory,
                            pbi.factories.routerFactory,
                        );
                        const dashboardContainer = document.getElementById('container');
                        const dashboard = powerbi.embed(dashboardContainer, config);
                        this.dashboardFrame = dashboard;

                        dashboard.off('loaded');
                        dashboard.off('rendered');
                        dashboard.on('error', function () {
                            this.dashboard.off('error');
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.error = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dashboardFrame.iframe.style.border = 'none';
                    });
            });
        },
    },
    mounted() {
        this.getOwnerReport();
        // console.log(this.dashboardFrame, 'frame');
    },
};
</script>

<style>
#wrapper {
    padding: 20px;
    margin: 0 50px;
    width: 800px;
}
#container {
    height: 700px;
    width: 100%;
}
</style>

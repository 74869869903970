<template>
    <div>
        <div
            @click="toggleSelected(song)"
            :class="{ selected: isSelected, dark: song.artwork, light: !song.artwork }"
            class="container"
        >
            <v-chip class="staff-label" dark small color="#ff530d"> Staff Pick </v-chip>

            <v-img v-if="song.artwork" cover class="artwork" :src="song.artwork" :lazy-src="song.artwork"></v-img>
            <div v-if="song.artwork" class="overlay"></div>

            <div class="song-info">
                <strong>{{ song.title }}</strong>
                <p class="subtitle">{{ song.artist ? song.artist : 'Unknown Artist' }}</p>
            </div>
            <div :class="{ disabled: !ready }" class="audio-container">
                <font-awesome-icon
                    class="player-icon"
                    v-if="!isPlaying"
                    icon="fa-regular fa-circle-play"
                    :style="{ fontSize: fontSize }"
                    @click.stop="togglePlaying"
                />
                <font-awesome-icon
                    class="player-icon"
                    v-else
                    icon="fa-regular fa-circle-stop"
                    :style="{ fontSize: fontSize }"
                    @click.stop="togglePlaying"
                />
                <div @click.stop class="mx-3 wave-container">
                    <waveformV2
                        v-if="song.playableLink"
                        :ref="`featured-wave-${song.id}`"
                        :containerId="`featured-wave-${song.id}`"
                        :sourceUrl="song.playableLink"
                        @playing="$emit('playing', song)"
                        @is-playing="val => updatePlayingState(val)"
                        @ready="ready = true"
                    ></waveformV2>
                </div>
                <!-- <div>
                    <font-awesome-icon
                        class="player-icon"
                        :class="{ selected: isSelected }"
                        :icon="isSelected ? 'fa-regular fa-circle-minus' : 'fa-regular fa-circle-plus'"
                        :style="{ fontSize: fontSize }"
                        @click="toggleSelected(song)"
                    />
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import WaveformV2 from '@/components/ui/WaveformV2.vue';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            isPlaying: false,
            ready: false,
        };
    },
    props: {
        song: {
            type: Object,
            required: true,
        },
        fontSize: {
            type: String,
            default: '1.4rem',
        },
        token: {
            type: String,
            required: true,
        },
    },
    components: {
        WaveformV2,
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        isSelected() {
            if (this.selectedSongs && this.song) {
                const found = this.selectedSongs.find(x => x.id == this.song.id);
                return found;
            }
            return false;
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideoSelectedSongs']),
        updateSelectedSongs(id, ids, successMessage = '') {
            let data = {
                selectedSongs: ids,
            };
            return this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, data)
                .then(resp => {
                    if (successMessage) {
                        this.showSnackbar({ message: successMessage });
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async toggleSelected(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);
            var listClone = [...this.selectedSongs];

            if (found) {
                const indexToRemove = listClone.indexOf(found);
                listClone.splice(indexToRemove, 1);
                for (let i = indexToRemove; i < listClone.length; i++) {
                    listClone[i].order = i;
                }
            } else {
                listClone.push({ ...item, order: this.selectedSongs.length });
            }

            this.updateTributeVideoSelectedSongs(listClone);
            const selectedIds = listClone.map(obj => obj.id);

            var message = '';

            if (found) {
                message = 'Removed from to playlist';
            } else {
                message = 'Added to playlist';
            }

            await this.updateSelectedSongs(this.tributeVideo.id, selectedIds, message);
            this.$emit('refresh-sync');
        },
        updatePlayingState(val) {
            this.isPlaying = val;
        },
        togglePlaying() {
            const wave = this.$refs[`featured-wave-${this.song.id}`];
            if (wave && this.ready) {
                wave.togglePlaying();
            }
        },
        pauseAudio() {
            const wave = this.$refs[`featured-wave-${this.song.id}`];
            if (wave && this.ready) {
                wave.pauseAudio();
            }
        },
        emitFileSize(event) {
            const audioElememnt = event.target;
            const fileSize = audioElememnt.duration;
            this.$emit('fileSize', fileSize);
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        this.createAxiosInstance();
    },
};
</script>
<style lang="scss" scoped>
.container {
    background-color: #f8f8f8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: 0.1s;
    margin: 8px 0;
}

.dark.container:hover {
    .overlay {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.light.container:hover {
    background-color: #eee;
}

.artwork {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    filter: opacity(70%);
}

.song-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;
}

.subtitle {
    color: #91a6b6;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.audio-container {
    display: flex;
    align-items: center;
    min-height: 22px;
    transition: 0.3s;
    opacity: 1;
    z-index: 2;
}

.staff-label {
    position: absolute;
    top: -12px;
    right: -7px;
    z-index: 2;
}

.player-icon {
    cursor: pointer;
    transition: 0.1s;
}
.wave-container {
    width: 100%;
    height: 20px;
}
.disabled {
    opacity: 0.1;
    cursor: not-allowed !important;
}

.player-icon:hover {
    color: #ff530d;
}

.selected {
    background-color: #1876f232 !important;
}

.overlay {
    position: absolute; /* Absolute positioning */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    color: white; /* Text color */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1; /* Sit on top */
    border-radius: 5px;
    transition: 0.2s;
}

.dark {
    color: white;

    p {
        color: white;
    }
}
</style>

<template>
    <div class="tribute-onboarding-stepper">
        <div class="stepper-container">
            <v-stepper-header class="stepper-content elevation-0">
                <v-stepper-step class="pointer step" @click="$emit('step-click', 1)" :complete="stepIndex > 1" step="1">
                    <span class="step-label"> Deadline </span>
                </v-stepper-step>
                <v-divider class="step-divider"></v-divider>
                <v-stepper-step class="pointer step" @click="$emit('step-click', 2)" :complete="stepIndex > 2" step="2">
                    <span class="step-label"> Identify </span>
                </v-stepper-step>
                <v-divider class="step-divider"></v-divider>
                <v-stepper-step class="pointer step" @click="$emit('step-click', 3)" step="3">
                    <span class="step-label"> Upload</span>
                </v-stepper-step>
            </v-stepper-header>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        stepIndex: {
            type: Number,
            default: 1,
        },
    },
};
</script>
<style lang="scss">
.tribute-onboarding-stepper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .stepper-container {
        width: 100%;
        display: flex;
        justify-content: center;

        container-type: inline-size;
        container-name: stepper;
        margin: 0 auto;

        max-width: 100%;
        min-width: 285px;

        .step-divider {
            margin: 35px -20px !important;
        }
        .step {
            max-width: fit-content;
        }

        .v-stepper__step__step {
            font-size: 18px;
            font-weight: 800;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }

        .step-label {
            color: #000;
        }

        .v-stepper__step--active .v-stepper__step__step,
        .v-stepper__step--complete .v-stepper__step__step {
            background: #2c3e50 !important;
            border: 2px solid #2c3e50 !important;
        }

        .v-stepper__step--inactive .v-stepper__step__step {
            background: none !important;
            color: #2c3e50;
            border: 2px solid #2c3e50 !important;
        }

        .v-divider {
            border-color: #91a6b6 !important;
        }

        .stepper-content {
            max-width: 800px;
            min-width: 150px;
            flex-grow: 2;
        }
    }
}
</style>

<template>
    <div>
        <v-card :loading="loading">
            <v-card-title>Video Data</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Storage Tier"
                            v-model="video.storageType"
                            :items="filteredStorageTypes"
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field label="Codec" v-model="video.codec"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field label="Width" v-model="video.width" type="number"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field label="Height" readonly v-model="video.height" type="number"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Original File Size"
                            readonly
                            :value="formatFileSize(video.totalRawFileSize)"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            readonly
                            label="Converted File Size"
                            :value="formatFileSize(video.convertedFileSize)"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field label="Original Duration" v-model="video.originalDuration"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field label="Rendered Duration" v-model="video.duration"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-btn class="mt-3" large depressed @click="openBunnyDeepLink" v-if="video.hlsGuid" block>
                            <div>
                                <v-img :src="bunnyLogo" width="120px"></v-img>
                            </div>
                            <font-awesome-icon
                                icon="fa-regular fa-arrow-up-right-from-square"
                                style="font-size: 1rem"
                                class="ml-4"
                            ></font-awesome-icon>
                        </v-btn>

                        <v-btn
                            class="mt-3"
                            large
                            depressed
                            :disabled="video.hlsStatus != null"
                            @click="createHlsRender"
                            v-if="!video.hlsGuid"
                            block
                        >
                            {{ video.hlsStatus != null ? 'Proccessing Hls' : 'Create Hls Render' }}
                        </v-btn>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-btn
                            large
                            block
                            class="mt-2"
                            :disabled="loading"
                            :loading="loading"
                            @click="updateVideoData"
                            depressed
                            dark
                            color="#0d3d60"
                            >Save</v-btn
                        >
                    </v-col>

                    <v-col cols="12">
                        <v-divider></v-divider>
                        <div
                            style="border: 1px solid #ff5252; background-color: hsl(0, 100%, 66%, 10%)"
                            class="rounded pa-2"
                        >
                            <p class="error--text">
                                <strong> Danger Zone </strong>
                            </p>

                            <div class="d-flex flex-wrap" style="gap: 12px">
                                <v-btn
                                    @click="archiveConfirmModal = true"
                                    v-if="video.storageType != 3"
                                    color="error"
                                    depressed
                                    >Archive File</v-btn
                                >
                                <v-btn
                                    @click="unarchiveConfirmModal = true"
                                    v-if="video.storageType == 3"
                                    color="error"
                                    depressed
                                    >Unarchive File</v-btn
                                >

                                <v-btn color="error" depressed @click="deleteHlsModal = true" v-if="video.hlsGuid"
                                    >Delete HLS</v-btn
                                >
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-card-actions class="d-flex justify-space-between">
                <v-btn @click="$emit('close')" depressed>Cancel</v-btn>
            </v-card-actions> -->
        </v-card>

        <v-dialog v-model="archiveConfirmModal" max-width="480px">
            <v-card>
                <v-card-title>Archive File?</v-card-title>
                <v-card-text>
                    <v-alert text type="error"
                        >Are you sure you want to archive this file? There will be an <strong>$8 charge</strong> to
                        unarchive this file.</v-alert
                    >
                    <p>Click confirm to continue <strong>archive</strong> this file.</p>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="archiveConfirmModal = false" depressed>Cancel</v-btn>
                    <v-btn @click="confirmArchiveSubmit" depressed color="error">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="unarchiveConfirmModal" max-width="480px">
            <v-card>
                <v-card-title>Unarchive File?</v-card-title>
                <v-card-text>
                    <v-alert text type="error"
                        >Are you sure you want to unarchive this file? There will be an <strong>$8 charge</strong> to
                        unarchive this file.</v-alert
                    >
                    <p>Click confirm to continue <strong>unarchive</strong> this file.</p>
                    <v-select
                        label="Unarchive Priority"
                        :items="rehydratePriorities"
                        v-model="selectedUnarchivePriority"
                        item-text="label"
                        item-value="value"
                        hint="Selecting high priority will incur a higher unarchive fee than selecting low priority, only select if necessary"
                        persistent-hint
                    ></v-select>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="unarchiveConfirmModal = false" depressed>Cancel</v-btn>
                    <v-btn @click="confirmUnarchiveSubmit" depressed color="error">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteHlsModal" max-width="400px">
            <v-card>
                <v-card-title>Delete HLS?</v-card-title>
                <v-card-text> Click confirm to delete HLS copy of this video</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="deleteHlsModal = false" depressed>Cancel</v-btn>
                    <v-btn @click="confirmDeleteHls" color="error" depressed>Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { hlsStatuses, storageTypes } from '@/constants';
import { mapActions } from 'vuex';
import bunnyLogo from '@/assets/images/bunny_logo.png';

export default {
    data() {
        return {
            bunnyLogo,
            storageTypes,
            loading: true,
            token: null,
            videoId: 0,
            video: {},
            archiveConfirmModal: false,
            unarchiveConfirmModal: false,
            selectedUnarchivePriority: false,
            rehydratePriorities: [
                { label: 'Low (24 hours)', value: false },
                { label: 'High (1 hour)', value: true },
            ],
            deleteHlsModal: false,
        };
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    computed: {
        filteredStorageTypes() {
            return this.storageTypes.map(item => ({ ...item, disabled: item.value == 3 }));
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        formatFileSize(fileSizeInBytes) {
            const kilobyte = 1024;
            const megabyte = kilobyte * 1024;
            const gigabyte = megabyte * 1024;

            if (fileSizeInBytes >= gigabyte) {
                return (fileSizeInBytes / gigabyte).toFixed(2) + ' GB';
            } else if (fileSizeInBytes >= megabyte) {
                return (fileSizeInBytes / megabyte).toFixed(2) + ' MB';
            } else if (fileSizeInBytes >= kilobyte) {
                return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB';
            } else {
                return fileSizeInBytes + ' bytes';
            }
        },
        createHlsRender() {
            console.log(this.video, 'video');
            if (this.video.hlsGuid) {
                this.showSnackbar({ message: 'Hls render already exists', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/render/render-hls/${this.video.eventId}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Hls render job created' });
                    this.$emit('close');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating hls render job', color: 'error' });
                });
        },
        openBunnyDeepLink() {
            if (!this.video.hlsGuid) {
                this.showSnackbar({ message: 'Invalid hls guid', color: 'error' });
                return;
            }

            const deeplink = `https://dash.bunny.net/stream/${process.env.VUE_APP_BUNNY_STREAM_LIB_ID}/library/videos?search=${this.video.hlsGuid}&videoId=${this.video.hlsGuid}`;

            window.open(deeplink, '_blank');
        },
        confirmArchiveSubmit() {
            if (this.videoId < 1) {
                this.showSnackbar({ message: 'Invalid video id', color: 'error' });
                return;
            }

            this.axiosInstance
                .put(`/videos/archive/${this.videoId}`)
                .then(resp => {
                    this.showSnackbar({ message: `Video ${this.videoId} archived` });
                    this.archiveConfirmModal = false;
                    this.$emit('close');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error archiving video', color: 'error' });
                });
        },
        confirmUnarchiveSubmit() {
            if (this.videoId < 1) {
                this.showSnackbar({ message: 'Invalid video id', color: 'error' });
                return;
            }

            this.axiosInstance
                .put(`/videos/archive-undo/${this.videoId}?priority=${this.selectedUnarchivePriority}`)
                .then(resp => {
                    this.showSnackbar({ message: `Video ${this.videoId} unarchived` });
                    this.unarchiveConfirmModal = false;
                    this.$emit('close');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error unarchiving video', color: 'error' });
                });
        },
        getVideo() {
            this.axiosInstance
                .get(`/videos/events/${this.event.id}`)
                .then(response => {
                    if (response.data) {
                        this.videoId = response.data.id;
                        this.video = response.data;

                        this.video.duration = this.roundTimestamp(this.video.duration);
                        this.video.originalDuration = this.roundTimestamp(this.video.originalDuration);
                    }
                })
                .catch(error => {})
                .finally(() => {
                    this.loading = false;
                });
        },
        updateVideoData() {
            if (this.videoId < 1) {
                this.showSnackbar({ message: 'Invalid video Id', color: 'error' });
                return;
            }

            if (this.video.storageType == null) {
                this.showSnackbar({ message: 'Invalid storage type', color: 'error' });
                return;
            }

            this.loading = true;

            let data = {
                storageType: this.video.storageType,
                height: this.video.height,
                width: this.video.width,
                codec: this.vieo.codec,
            };

            this.axiosInstance
                .put(`/videos/admin-update-video-data/${this.videoId}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Storage tier updated' });
                    this.$emit('close');
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating storage tier', color: 'error' });
                });
        },
        confirmDeleteHls() {
            if (!this.videoId) {
                this.showSnackbar({ message: 'Invalid video Id', color: 'error' });
                return;
            }

            this.axiosInstance
                .put(`/videos/delete-hls/${this.videoId}`)
                .then(resp => {
                    this.showSnackbar({ message: 'HLS successfully deleted' });
                    this.deleteHlsModal = false;
                    this.$emit('close');
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error deleting HLS', color: 'error' });
                });
        },
        roundTimestamp(timestamp) {
            // Split the timestamp into parts
            const parts = timestamp.split(':');
            let hours = parseInt(parts[0], 10);
            let minutes = parseInt(parts[1], 10);
            let seconds = parseFloat(parts[2]);

            // Round the seconds based on the milliseconds
            seconds = Math.floor(seconds);

            // Handling carry-over scenarios if seconds become 60 after rounding
            if (seconds === 60) {
                seconds = 0;
                minutes += 1;
                if (minutes === 60) {
                    minutes = 0;
                    hours += 1;
                }
            }

            // Formatting the output to always have two digits for hours, minutes, and two digits for seconds
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');

            // Return the rounded timestamp
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getVideo();
    },
};
</script>
<style lang=""></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',{attrs:{"has-background":true,"contained":true}},[_c('div',{staticClass:"tile"},[_c('v-card-title',[_c('h4',[_vm._v("Billing Events")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("back")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.billingEvents,"loading":_vm.loading,"footer-props":{
                itemsPerPageOptions: [5, 25, 50],
            },"options":_vm.options,"loading-text":"Getting Billing Events..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.reportedDate,'l')))])]}},{key:"item.event",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.eventTypes[item.eventType].color,"dark":""}},[_vm._v(_vm._s(_vm.eventTypes[item.eventType].label))])]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s((item.amount / 100).toFixed(2)))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div v-if="purchase.id" class="text-center p-3">
        <!-- <h2>Purchase Status</h2> -->
        <v-row>
            <v-col cols="12">
                <h4>Purchase #{{ purchase.id }}</h4>
                <div class="section">
                    <div style="height: 100%; display: flex; flex-direction: column; justify-content: center">
                        <div v-if="purchase.createdDate">
                            <div>
                                <div>{{ $moment.utc(purchase.createdDate).local().format('ll') }}</div>
                            </div>
                            <div>
                                <div>{{ purchase.address1 }}</div>
                                <div>{{ purchase.address2 }}</div>
                                <div>{{ purchase.city }}, {{ purchase.state }} {{ purchase.zipCode }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <h5>Purchase Details</h5>
                <div class="section">
                    <div v-for="(lineItem, index) in purchase.lineItems" :key="index">
                        <LineItemStatusDetails :lineItem="lineItem" />
                    </div>

                    <v-divider></v-divider>
                    <div class="p-3" style="display: flex; justify-content: space-between" v-if="purchase.amount">
                        <div>Total</div>

                        <h5>
                            <strong>{{ purchase.amount | formatMoney() }}</strong>
                        </h5>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div>
                    <h5>Shipments</h5>
                    <div v-if="purchase.shipments.length == 0" class="section">
                        No shipment data is available for this purchase at this time.
                    </div>
                    <div class="section" v-for="(shipment, index) in purchase.shipments">
                        <div style="display: flex; justify-content: space-between">
                            <div class="text-left">
                                <strong>{{ $moment.utc(shipment.updatedDate).local().format('ll') }}</strong>
                                <div>{{ $moment.utc(shipment.updatedDate).local().format('LT') }}</div>
                            </div>

                            <v-chip small dark :color="shipmentStatuses[shipment.status].color">{{
                                shipmentStatuses[shipment.status].label
                            }}</v-chip>
                        </div>
                        <div class="mt-2" style="display: flex; justify-content: center">
                            <div v-for="(sli, index) in shipment.shipmentLineItems" :key="index">
                                <ShipmentLineItemStatusDetails class="p-2" :shipmentLineItem="sli" />
                            </div>
                        </div>
                        <div>
                            <span class="mr-2">{{ carriers[shipment.carrier].label }}</span>
                            <a :href="shipment.trackingUrl" target="_blank">{{ shipment.trackingNumber }}</a>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { orderStatuses, paymentStatuses, shipmentStatuses, carriers } from '@/constants';
import LineItemStatusDetails from '@/components/Shop/LineItemStatusDetails.vue';
import ShipmentLineItemStatusDetails from '@/components/Shop/ShipmentLineItemStatusDetails.vue';
import moment from 'moment';
export default {
    data() {
        return {
            orderStatuses,
            paymentStatuses,
            shipmentStatuses,
            carriers,
            purchase: {
                shipments: [],
            },
        };
    },
    components: { LineItemStatusDetails, ShipmentLineItemStatusDetails },
    methods: {
        getPurchase(slug) {
            this.axios
                .get(process.env.VUE_APP_API + `/Order/purchase-status/${slug}`)
                .then(response => {
                    this.purchase = response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
    },
    // created() {
    //     this.$store.dispatch('toggleTopNav');
    // },
    mounted() {
        this.getPurchase(this.$route.params.slug);
    },
};
</script>
<style>
.section {
    margin: 20px 0;
    padding: 10px;
    border: 2px solid #eee;
    border-radius: 4px;
    background-color: white;
}
.shipment {
    /* border: 2px solid green; */
    padding: 10px;
}
</style>

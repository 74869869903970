<template>
    <div>
        <!-- <div style="border: 2px solid red">
            <h3>music folder test</h3>
            
        </div> -->

        <div class="grid">
            <div
                @click="selectGenre(genre)"
                :class="selectedItem == genre ? 'selected' : ''"
                class="grid-item"
                v-for="(genre, index) in genres"
                :key="index"
            >
                <div class="d-flex algin-center justify-center flex-column">
                    <font-awesome-icon style="font-size: 2rem" icon="fa-solid fa-folder"></font-awesome-icon>
                    <h6 class="text-center">{{ genre.name }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            genres: [],
            totalGenres: 0,
            keywords: [],
            totalKeywords: 0,
            selectedItem: null,
        };
    },
    methods: {
        selectGenre(genre) {
            this.selectedItem = genre;
        },
        getKeywords() {
            return this.axiosInstance
                .get('/MusicKeyword?pageSize=100')
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getGenres() {
            return this.axiosInstance
                .get('/MusicGenre?pageSize=100')
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        await this.getGenres();
        await this.getKeywords();
    },
};
</script>
<style lang="scss" scoped>
.grid {
    cursor: pointer;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: start;
    grid-gap: 5px;
}
.grid-item {
    max-width: 200px;
    width: 100%;
    // border: 2px solid red;
    padding: 10px;
}
</style>

<template>
    <v-app id="app">
        <router-view :key="$route.name" />
        <Snackbar />
        <GlobalLoading />
    </v-app>
</template>

<script>
import Snackbar from '@/components/ui/Snackbar.vue';
import GlobalLoading from '@/components/layout/GlobalLoading.vue';
import { debounce } from 'lodash';

export default {
    metaInfo: {
        titleTemplate: '%s | MemoryShare',
    },
    components: {
        Snackbar,
        GlobalLoading,
    },
    methods: {
        getOwnerSettings(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${id}`)
                    .then(response => {
                        this.$store.dispatch('updateDisplayTerms', response.data.displayTerms);
                        this.$store.dispatch('updateCustomLogo', response.data.logo);
                        this.$store.dispatch('updateUserSupport', response.data.support);
                        this.$store.dispatch('updateCustomHTML', response.data.embededScripts);
                        this.$store.dispatch('updateWhitelabel', response.data.whiteLabel);
                        if (response.data.embededScripts && response.data.support) {
                            let script = document.createElement('script');
                            script.innerHTML = eval(response.data.embededScripts);
                            document.body.appendChild(script);
                        }
                    });
            });
        },
        getHomeSettings(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/funeralhomes/${id}`)
                    .then(response => {
                        const homeName = response.data.funeralHome.name;
                        this.saveImpersonateName(homeName);

                        if (response.data.funeralHome.keywordReportLink) {
                            this.$store.dispatch('updateDisplayKeywordReportNav', true);
                        }

                        if (this.$auth.session.owner) {
                            // Sets side nav settings from owner settings

                            this.$store.dispatch('updateDisplayTerms', this.$auth.session.owner.displayTerms);
                            this.$store.dispatch('updateCustomLogo', this.$auth.session.owner.logo);
                            this.$store.dispatch('updateUserSupport', this.$auth.session.owner.support);
                            this.$store.dispatch('updateWhitelabel', this.$auth.session.owner.whiteLabel);

                            // enables owner scripts
                            if (this.$auth.session.owner.embededScripts && this.$auth.session.owner.support) {
                                let script = document.createElement('script');
                                script.innerHTML = eval(this.$auth.session.owner.embededScripts);
                                document.body.appendChild(script);
                            }
                        }
                    });
            });
        },
        getUserSettingsv2() {
            if (this.$auth.session != 'hello') {
                if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                    this.getOwnerSettings(this.$auth.user['https://memoryshare.com/owner_id']);
                } else if (this.$auth.role.includes('FuneralHome')) {
                    this.getHomeSettings(this.$auth.funeralHomeId);
                }
            } else {
                setTimeout(() => {
                    this.getUserSettingsv2();
                }, 500);
                return;
            }
        },
        checkIntercom: function (to) {
            if (!process.env.VUE_APP_INTERCOM_APPID) return;

            let intercomObject = document.querySelector('.intercom-launcher');
            if (!intercomObject) {
                setTimeout(() => {
                    this.checkIntercom(to);
                }, 500);

                return;
            }

            intercomObject?.classList.remove('intercom-hidden');
            intercomObject.classList.add('intercom-show');

            if (!this.$auth.session) {
                intercomObject?.classList.add('intercom-hidden');
                intercomObject.classList.remove('intercom-show');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }

            if (this.$auth.role.includes('SuperAdmin')) {
                intercomObject?.classList.remove('intercom-show');
                intercomObject.classList.add('intercom-hidden');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }
            if (this.$auth.role.includes('Supplier')) {
                intercomObject?.classList.remove('intercom-show');
                intercomObject.classList.add('intercom-hidden');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }

            if (!this.$auth.user.email) {
                intercomObject?.classList.remove('intercom-show');
                intercomObject?.classList.add('intercom-hidden');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }

            if (this.$auth.session.owner && !this.$auth.session.owner.support) {
                intercomObject?.classList.add('intercom-hidden');
                intercomObject?.classList.remove('intercom-show');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }

            if (
                this.$route.name == 'TributeUploadPage' ||
                this.$route.name == 'TributeFamilyPage' ||
                this.$route.name == 'TributeStoryUpload'
            ) {
                intercomObject?.classList.add('intercom-hidden');
                intercomObject?.classList.remove('intercom-show');

                // intercom-hidden class overridden when banner active
                this.$intercom.update({ hide_default_launcher: true });
                return;
            }

            // Session Payload

            const payload = {
                owner: this.$auth.session?.owner?.name || '',
                subscription_status: this.$auth.session?.home?.subscriptionStatus || '',
                last_event: this.$auth.session?.home?.lastEventTime || '',
                goal_streams: this.$auth.session?.home?.goalStreams || '',
                phone: this.$auth.session?.home?.primaryContactPhoneNumber || '',
                created_at: this.$auth.session.home?.createDate || '',
                city: this.$auth.session?.home?.city || '',
                state: this.$auth.session?.home?.state || '',
                name: this.$auth.session?.home?.name || '',
                company_id: this.$auth.session?.home?.id || '',
                // company_last_seen: this.$auth.session?.home?.lastEventTime || '',
                // company_created_at: this.$auth.session?.home?.lastEventTime || '',
                monthly_spend: this.$auth.session?.home?.subscriptionCost / 100 || '',
                company_segment: '',
                company_tag: '',
                company_size: '',
                company_industry: '',
                company_website: this.$auth.session?.home?.website || '',
                plan_name: '',
            };

            this.$intercom.update({ email: this.$auth.user.email, name: this.$auth.user.name, company: payload });

            // Exception List for Intercom
            if (to || this.$route) {
                const name = to ? to.name : this.$route.name;

                if (name === 'MigrationNotification' || name === 'EmbedPlayer' || name === 'View') {
                    intercomObject?.classList.remove('intercom-show');
                    intercomObject?.classList.add('intercom-hidden');
                    return;
                }
            }
        },
        checkForName: function () {
            //connect to viewer webhook hub for anonymous users at tribute upload page
            if (
                (this.$route.name === 'TributeUploadPage' ||
                    this.$route.name == 'TributeFamilyPage' ||
                    this.$route.name == 'TributeStoryUpload') &&
                !this.$auth.user
            ) {
                this.$socket.connection = process.env.VUE_APP_SIGNALR_HUBS + '/viewing';

                this.$socket.start({
                    log: false, // Active only in development for debugging.
                });

                // Join the application w/o param as viewer
                this.$socket.invoke('JoinSlug', this.$route.params.slug);
                console.log('socket', this.$socket.connection);
                return;
            }

            if (this.$auth.user.email && this.$auth.session && this.$auth.user.name) {
                this.$store.dispatch('updateLoggedInUser', this.$auth.user.name);

                // Determine connection string
                if (this.$auth.role.includes('SuperAdmin')) {
                    this.$socket.connection = process.env.VUE_APP_SIGNALR_HUBS + '/superadmin';

                    this.$socket.start({
                        log: false, // Active only in development for debugging.
                    });

                    // Join the application w/o param as superadmin
                    this.$socket.invoke('JoinApplication');
                }

                if (this.$auth.role.includes('FuneralHome')) {
                    this.$socket.connection = process.env.VUE_APP_SIGNALR_HUBS + '/funeralhome';

                    // Connect to web sockets
                    this.$socket.start({
                        log: false, // Active only in development for debugging.
                    });

                    // Join the application
                    this.$socket.invoke('JoinApplication', this.$auth.funeralHomeId.toString());
                }

                if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                    this.$socket.connection = process.env.VUE_APP_SIGNALR_HUBS + '/owner';

                    // Connect to web sockets
                    this.$socket.start({
                        log: false, // Active only in development for debugging.
                    });

                    // Join the application
                    this.$socket.invoke(
                        'JoinApplication',
                        this.$auth.user['https://memoryshare.com/owner_id'].toString(),
                    );
                }

                console.log('socket', this.$socket.connection);

                // If there is intercom, load it
                if (process.env.VUE_APP_INTERCOM_APPID) {
                    this.$intercom.boot({
                        name: this.$auth.user.name,
                        email: this.$auth.user.email,
                        created_at: new Date().toUTCString(),
                        user_hash: this.$auth.session.hmac,
                    });
                    this.checkIntercom(null);
                }
            } else {
                console.debug('re-polling session ...');
                setTimeout(() => {
                    this.checkForName();
                }, 500);
            }
        },
        saveImpersonateName(name) {
            if (this.$auth.impersonating) {
                this.$store.dispatch('updateImpersonatingName', name);
            }
        },
        checkLive() {
            this.$auth.getIdTokenClaims().then(result => {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${result.__raw}` } })
                    .get(process.env.VUE_APP_API + '/live/get-live-count')
                    .then(response => {
                        if (response.data > 0) {
                            this.$store.dispatch('toggleStreaming', true);
                        } else {
                            this.$store.dispatch('toggleStreaming', false);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
    mounted() {
        //resets intercom launcher hidden cookie on mount

        this.debouncedCheckLive = debounce(this.checkLive, 200);
        this.$intercom.update({ hide_default_launcher: false });
        this.getUserSettingsv2();
        this.checkForName();
    },
    watch: {
        $route(to, from) {
            this.checkIntercom(to);
        },
    },
    sockets: {
        NotifyLive(data) {
            //this.checkLive();
            this.debouncedCheckLive();
        },
        // Global Registration
        NotifyUpload(data) {},
        NotifyTributeRender(data) {},
        NotifyStoryRender(data) {},
        NotifyTemplateRender(data) {},
        NotifyMainPhotoReplace(data) {},
        NotifyTributeRenderSettings(data) {},
        NotifyTributeThemeUpdate(data) {
            // console.log('heard at app level');
        },
        NotifyTributeSongsUpdate(data) {},
        NotifyRenderStatus(data) {},
        NotifyArchiveStatus(data) {},
        NotifyAnalyticsEmail(data) {
            // console.log(data, 'test')
        },
    },
};
</script>

<style lang="scss">
// https://davidwalsh.name/write-media-queries-sass
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@media print {
    @page {
        size: auto !important;
    }
}

.intercom-launcher {
    display: none;
}

.intercom-hidden {
    display: none !important;
}
.intercom-show {
    display: block !important;
}

.v-btn__content {
    max-width: 100% !important;
    white-space: normal !important;
}

.v-slider__thumb-container {
    cursor: pointer !important;
}

* {
    box-sizing: border-box;
}

h4 {
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    padding: 0;
    margin: 0;
}

.pointer,
.cursor {
    cursor: pointer;
}

.dialog-container {
    padding: 1.5rem 2rem !important;
}

.custom-dialog {
    .header {
        padding: 1.75rem 2rem 0;

        h4 {
            margin-bottom: 0;
        }

        small {
            opacity: 0.7;
        }
    }

    .billing-form {
        margin-top: -1rem;
    }

    .content {
        padding: 1rem 2rem;
    }

    label {
        margin-bottom: 0;
    }
}

.ease {
    transition: 0.3s ease-in-out;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.page-container {
    padding: 1rem;
}

.auth-view {
    background: #f8f8f8;
}

.text-center {
    text-align: center;
}

.auth-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28vw;
    padding: 2rem 1.5rem;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.dash-container {
    margin-left: 4rem;
    padding: 5rem 3rem 1.5rem;
    background-color: rgb(241, 244, 247);
    min-height: 100vh;
    transition: 0.1s linear;

    &.menu-showing {
        margin-left: 18rem !important;
    }

    .header {
        display: flex;
        margin-bottom: 2rem;
    }

    .title {
        display: inline-block;
        margin: 0.8rem 0 0;
    }

    .search-icon {
        margin: 0 1rem 0.2rem 0;
        height: 1.2rem;
    }

    .search-field {
        background: #fff;
        // width: 12rem;
        padding: 0.25rem 1rem;
        border: 1px solid rgba(192, 195, 197, 0.5);
        border-radius: 1.4rem;
        box-shadow: 0 0 4px rgba(192, 195, 197, 0.3);

        input {
            border: none;
            width: 16rem;
            height: 100%;

            &:focus {
                outline: none;
            }
        }
    }

    .tile {
        background: #fff;
        padding: 1.5rem;
        border-radius: 3px;
        box-shadow: 0 2px 8px rgba(192, 195, 197, 0.3);
    }

    .vdatetime-input {
        border: none;
        width: 100%;
    }

    .v-input__slot label {
        margin-bottom: 0;
    }

    .btn.btn-primary {
        color: #fff;
        background: #0c3c60;
        border-color: #0c3c60;

        &:hover {
            opacity: 0.9;
        }
    }

    .btn.btn-secondary {
        color: #fff;
        background: #ff530d;
        border-color: #ff530d;
    }

    .btn.btn-gradient {
        color: #fff;
        background: linear-gradient(90deg, rgba(238, 63, 70, 1) 0%, rgba(244, 125, 35, 1) 100%) !important;
        border: none;
    }

    .btn:hover {
        opacity: 0.9;
    }

    .v-card {
        margin-top: 2rem;
    }

    .v-data-table {
        min-height: 24rem;
        padding: 0 1rem;
        margin-top: 1rem;
    }
}

.action-list {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;

    span {
        display: flex;
    }
}

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #ff530d;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
    color: #ff530d;
}

.theme-orange.validation-error {
    border-bottom: 1px solid #ff5252;
}

.disabled {
    opacity: 0.3;
}

.tile.form {
    width: 85%;
    margin: 2rem auto;
}

.form-container {
    padding: 2rem 4rem;
}

.chart-container {
    // margin: 2rem 0;
}

.chart {
    background: #fff;
    // padding: 2rem;
    padding: 5px;
    border-radius: 5px;
}

.chart-title {
    border-left: 2px solid rgba(224, 0, 0, 0.7);
    border-radius: 1px;
    padding: 0 0.5rem;
    margin: 0;
    display: inline-block;

    &.weekly {
        border-color: rgba(255, 83, 13, 0.7);
    }

    &.yearly {
        border-color: rgba(171, 6, 88, 0.7);
    }
}

.v-radio {
    &:first-of-type {
        margin-bottom: 1rem !important;
    }

    .v-label {
        margin-bottom: 0.1rem;
    }
}

.v-input--checkbox {
    .v-label {
        margin-bottom: 0;
    }
}

table {
    td {
        cursor: default;
    }
}
.v-data-table tr td {
    cursor: pointer;
}

.logo {
    display: block;
    max-width: 300px;
    max-height: 300px;
}

// THEME START
.text-body-light {
    color: #7a8187;
}
.text-heading {
    color: #2c3e50;
}

.btn-blue {
    background: #2096f3 !important;
    color: white !important;
    font-weight: 800 !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 12px 13px;
    gap: 10px;
    transition: all 150ms;
}
.btn-blue:hover {
    background: #1e89da !important;
    transition: all 150ms;
}

.btn-ghost {
    background: #eeeeee !important;
    color: #2c3e50 !important;
    font-weight: 800 !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 12px 13px;
    gap: 10px;
    transition: all 150ms;
}
.btn-ghost:hover {
    background: #d7d6d6 !important;
    transition: all 150ms;
}

.btn-orange {
    background: #fb8c00 !important;
    color: white !important;
    font-weight: 800 !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 12px 13px;
    gap: 10px;
    transition: all 150ms;
}

.btn-orange:disabled {
    opacity: 0.5;
}
.btn-ghost:disabled {
    opacity: 0.5;
}
.btn-orange:hover {
    background: #fb7900 !important;
    transition: all 150ms;
}

.btn-danger {
    background: #ff5252 !important;
    color: white !important;
    font-weight: 800 !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 12px 13px;
    gap: 10px;
    transition: all 150ms;
}

.btn-sm {
    padding: 12px !important;
}

//Scrollbar css
/* For Webkit Browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f100;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(188, 188, 188, 0.63);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(188, 188, 188, 0.912);
}
</style>

<template>
    <div class="event-action-container">
        <v-btn @click="handleButtonClick" class="mb-2" color="secondary"> Add Video Files </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleButtonClick: function () {
            this.$router.push({ path: `/services/upload/${this.event.id}` });
        },
    },
};
</script>

<style lang="scss" scoped>
.event-action-container {
    background: #f8f8f8;
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
}
</style>

// Check if object is empty
export const isObjectEmpty = object => {
    return !Boolean(Object.keys(object).length);
};

export const getLocalSettings = async () => {
    return new Promise(async (resolve, reject) => {
        let settings = JSON.parse(localStorage.getItem('memoryshare-local-settings'));

        if (!settings) {
            settings = await generateSettings();
        }

        resolve(settings);
    });
};

export const setLocalSettings = async (key, object) => {
    let settings = JSON.parse(localStorage.getItem('memoryshare-local-settings'));
    let newSettings = {
        ...settings,
        [key]: object,
    };

    localStorage.setItem('memoryshare-local-settings', JSON.stringify(newSettings));
};

export const setDictInLocalStorage = (key, dict) => {
    if (typeof dict !== 'object' || Array.isArray(dict)) {
        throw new Error('The provided data is not an object');
    }
    localStorage.setItem(key, JSON.stringify(dict));
};

export const getDictFromLocalStorage = key => {
    const storedData = localStorage.getItem(key);
    if (storedData) {
        try {
            return JSON.parse(storedData);
        } catch (e) {
            console.error('Error parsing object from local storage:', e);
            return {};
        }
    }
    return {};
};

// Set local storage item with expiration (default is 6 houros - 21600000)
export const setWithExpiry = async (key, object, ttl = 21600000) => {
    const cookie = JSON.parse(localStorage.getItem(key));
    const now = new Date();

    const item = {
        ...cookie,
        [key]: object,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

// retrieve local storage item with expiration
export const getWithExpiry = async key => {
    const itemStr = localStorage.getItem(key);

    // if item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // if item is expired, return null
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item;
};

const generateSettings = () => {
    return new Promise((resolve, reject) => {
        const settings = {
            serviceTable: {
                search: '',
                page: '',
                pageSize: '',
            },
            funeralHomesTable: {
                search: '',
                page: '',
                pageSize: '',
            },
            rendersTable: {
                search: '',
                page: '',
                pageSize: '',
            },
            appSettings: {
                menuState: 'max',
            },
        };

        localStorage.setItem('memoryshare-local-settings', JSON.stringify(settings));
        resolve(settings);
    });
};

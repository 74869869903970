<template>
    <div>
        <div v-if="splashMessage" class="splash-message">
            {{ splashMessage }}
        </div>

        <div class="styled-videojs">
            <video
                preload="auto"
                x-webkit-airplay="allow"
                controls
                ref="videoPlayer"
                class="video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause player"
                :class="handleForcedAspectRatio()"
                :poster="poster ? poster : null"
            ></video>
            <!-- class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause player vjs-default-skin" -->
        </div>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

const splashMessages = {
    waitingForFeed: 'No video feed is coming through yet.',
    eventEnded: 'No video is available for this event.',
};

export default {
    name: 'VideoPlayer',
    data() {
        return {
            player: null,
            splashMessage: null,
            isHls: false,
            currentTime: 0,
            duration: 0,
            paused: true,
            type: '',
            options: {
                autoplay: false,
                controls: true,
                fluid: true,
                preload: 'auto',
                // height: 300,
                // techOrder: ['chromecast', 'html5'],
                // controlBar: {
                //     children: [
                //         'playToggle',
                //         'currentTimeDisplay',
                //         'progressControl',
                //         'volumePanel',
                //         'fullscreenToggle',
                //     ],
                // },
                controlBar: {
                    children: [
                        'progressControl',
                        'playToggle',
                        // 'volumeMenuButton',
                        'volumePanel',
                        'currentTimeDisplay',
                        'timeDivider',
                        'durationDisplay',
                        // 'remainingTimeDisplay',
                        // 'currentTimeDisplay',
                        // 'spacer',
                        'fullscreenToggle',
                    ],
                },
            },
            shareOptions: {
                socials: [
                    'fb',
                    'tw',
                    'reddit',
                    'gp',
                    'messenger',
                    'linkedin',
                    'telegram',
                    'whatsapp',
                    'viber',
                    'vk',
                    'ok',
                    'mail',
                ],
                url: window.location.href,
                title: 'videojs-share',
                description: 'video.js share plugin',
                image: 'https://dummyimage.com/1200x630',
                // required for Facebook and Messenger
                fbAppId: '12345',
                // optional for Facebook
                redirectUri: window.location.href + '#close',
                // optional for VK
                isVkParse: true,
            },
        };
    },
    watch: {
        src(newVal) {
            if (!this.player) return;

            this.updatePlayerSource(newVal);
        },
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        poster: {
            type: String,
            required: false,
        },
        controlsOnMount: {
            type: Boolean,
            default: false,
        },
        hidePlayBtnOnPlay: {
            type: Boolean,
            default: false,
        },
        aspectRatio: {
            type: String,
            default: null,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        showTimeControls: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        async initVideoJs() {
            let _ = this;

            const controlsOnMount = this.controlsOnMount;

            if (!this.showTimeControls) {
                this.options.controlBar.children = ['playToggle', 'volumePanel', 'fullscreenToggle'];
            }

            let type = this.src.endsWith('.m3u8') ? 'application/x-mpegURL' : 'video/mp4';

            this.type = type;

            this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
                let img = this.controlBar.addChild('Component', {}, 12);
                img.addClass('video-logo');

                if (controlsOnMount) {
                    this.addClass('vjs-has-started');
                }
            });

            this.player.on('loadedmetadata', () => {
                this.duration = this.player.duration();
                this.$emit('loadedmeta', this.duration);
            });
            this.player.on('ended', () => {
                this.paused = true;
                this.$emit('ended');
            });

            this.player.on('timeupdate', () => {
                this.currentTime = this.player.currentTime();
                this.$emit('timeupdate', this.currentTime);
            });

            this.player.on('play', () => {
                this.paused = false;

                if (this.hidePlayBtnOnPlay) {
                    this.player.el_.classList.remove('vjs-show-big-play-button-on-pause');
                }
            });

            this.player.on('pause', () => {
                this.paused = true;
            });

            this.player.on('error', () => {
                this.$emit('error');
            });
        },
        updatePlayerSource(source) {
            this.type = source.endsWith('.m3u8') ? 'application/x-mpegURL' : 'video/mp4';

            if (this.player) {
                this.player.src({ type: this.type, src: source });
            }

            if (this.autoplay) {
                this.player.play();
            }
        },
        handleForcedAspectRatio() {
            if (this.aspectRatio === '16/9') {
                return 'vjs-16-9';
            }
        },
        setCurrentTime(val) {
            if (!this.player) {
                return;
            }
            this.player.currentTime(val);

            if (this.hidePlayBtnOnPlay) {
                this.player.el_.classList.remove('vjs-show-big-play-button-on-pause');
            }
        },
        adjustCurrentTime(val) {
            if (!this.player) return;

            this.player.currentTime(this.player.currentTime() + val);
        },
        jumpToStart() {
            this.setCurrentTime(0);
        },
        jumpToEnd() {
            if (!this.player) return;

            this.setCurrentTime(this.player.duration());
        },
        adjustVolume(val) {
            if (!this.player) return;

            let newVol = this.player.volume() + val;

            if (newVol > 1) newVol = 1;
            if (newVol < 0) newVol = 0;

            this.player.volume(newVol);
        },
        play() {
            if (!this.player) {
                return;
            }
            this.player.play();
        },
        pause() {
            if (!this.player) {
                return;
            }
            this.player.pause();
        },
        togglePlay() {
            if (!this.player) return;

            if (this.paused) {
                this.player.play();
            } else {
                this.player.pause();
            }
        },
        toggleMuted() {
            if (!this.player) return;

            this.player.muted(!this.player.muted());
        },
        toggleFullscreen() {
            if (!this.player) return;

            if (this.player.isFullscreen()) {
                this.player.exitFullscreen();
            } else {
                this.player.requestFullscreen();
            }
        },
        openModal() {
            const modal = this.player.createModal('This is a modal!');
        },
        async isStreamReady() {
            try {
                const response = await this.axios.get(this.src);
                const doesExist = response.status === 200;
                return doesExist;
            } catch (error) {
                return false;
            }
        },
        async startPreview() {
            console.count('run');
            const isStreamReady = await this.isStreamReady();

            if (isStreamReady) {
                // Stream is ready, clear the splash message and set the src
                this.splashMessage = null;
                this.$emit('videoready');
                this.player.src({ type: this.isHls ? 'application/x-mpegURL' : 'video/mp4', src: this.src });
            } else {
                // The live stream has started and the event status is 2 (live), but the URL is returning
                // a 404 error, so display a waiting message and try to load the source again in
                // a little while.
                this.splashMessage = splashMessages.waitingForFeed;
                this.timeoutId = setTimeout(() => {
                    this.startPreview();
                }, 10000);
            }
        },
        keydownListener(e) {
            e.preventDefault();

            switch (e.code) {
                case 'Space':
                    this.togglePlay();
                    return;
                case 'ArrowRight':
                    this.adjustCurrentTime(2);
                    return;
                case 'ArrowLeft':
                    this.adjustCurrentTime(-2);
                    return;
                case 'ArrowUp':
                    this.adjustVolume(0.1);
                    return;
                case 'ArrowDown':
                    this.adjustVolume(-0.1);
                    return;
                case 'KeyM':
                    this.toggleMuted();
                    return;
                case 'KeyF':
                    this.toggleFullscreen();
                    return;
                case 'Home':
                    this.jumpToStart();
                    return;
                case 'End':
                    this.jumpToEnd();
                    return;
                default:
                    return;
            }
        },
    },
    async mounted() {
        this.initVideoJs();

        if (this.src) {
            this.updatePlayerSource(this.src);
        }

        const vp = this.$refs.videoPlayer;
        if (vp) {
            vp.addEventListener('keydown', this.keydownListener);
        }
    },
    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        if (this.player) {
            this.player.dispose();
        }

        const vp = this.$refs.videoPlayer;
        if (vp) {
            vp.removeEventListener('keydown', this.keydownListener);
        }
    },
};
</script>
<style lang="scss">
$pink: #ab0658;
$pinkHover: #d4257d;
$control-bar-height: 3em;
$slider-height: $control-bar-height * 0.8;

.styled-videojs {
    .video-js {
        max-width: 100%;
        width: 100%;

        .vjs-current-time,
        .vjs-time-divider,
        .vjs-play-control,
        .vjs-duration {
            display: block;
        }

        .vjs-time-control {
            width: fit-content;
            min-width: 0;
            flex-shrink: 1 !important;
            padding: 0 0.2rem !important;
            overflow: hidden;
        }

        font-size: 0.9rem !important;

        .vjs-fullscreen-control {
            padding: 0 !important;
            width: 4rem;
            flex-shrink: 0;
            margin-left: auto;
        }

        .vjs-play-progress {
            .vjs-time-tooltip {
                display: none !important;
            }
        }

        // .vjs-volume-panel-horizontal.vjs-hover ~ .vjs-time-control {
        //     // display: none;
        //     // width: 0;
        // }

        &:hover {
            cursor: pointer !important;
        }

        // Big play button
        .vjs-big-play-button {
            background: $pink !important;
            border-color: $pink !important;
            border-radius: 5px !important;
            color: #fff !important;
            opacity: 0.9 !important;
            transition: 0.2s all !important;
        }

        &:hover .vjs-big-play-button {
            background: $pink !important;
            border-color: $pink !important;
            opacity: 1 !important;
        }

        // Control bar
        .vjs-control-bar {
            // background-color: rgba(0, 0, 0, 0.6) !important;
            // rgba(0, 0, 0, 0.1) 30%,
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) !important;

            padding: 0 !important;
            max-width: 100%;
            width: 100%;
            height: $control-bar-height + $slider-height !important;
            padding-top: $slider-height !important;
        }

        /* Make the progress bar full width and at the top */
        .vjs-progress-control {
            min-width: 100%;
            order: -1; /* This ensures it's always at the top */
            position: absolute;
            bottom: $slider-height !important;
            height: $slider-height;
        }

        // All control buttons (play, volume, full screen)
        .vjs-control {
            /* width: 3em !important; */

            .vjs-icon-placeholder::before {
                // font-size: 2em !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
            }
        }

        // Play button
        .vjs-play-control {
            // font-size: 1.3em !important;
            // max-width: 3em !important;
        }

        // Progress bar
        .vjs-progress-control:hover .vjs-progress-holder {
            font-size: 1em !important; // Overrides videojs styles which make the bar get taller on hover
        }

        .vjs-progress-holder {
            // height: $slider-height !important;
        }

        .vjs-play-progress {
            background: $pink !important;
        }

        .vjs-time-tooltip {
            font-size: 1.2em !important;
        }

        // Volume control
        .vjs-volume-control {
            height: 100% !important;
            align-items: center !important;
            // margin-top: 0 !important; // Overrides top margin added when embedded
        }

        // .vjs-mute-control {
        //     margin-top: 0 !important; // Overrides top margin added when embedded
        // }

        .vjs-volume-bar.vjs-slider-horizontal {
            // height: $slider-height !important;
        }

        .vjs-volume-level {
            height: 100% !important;

            &::before {
                display: none !important;
            }
        }
    }
}
</style>

<template>
    <div>
        <div>
            <div style="display: flex; justify-content: space-between">
                <div>
                    <div>
                        <strong>{{ order.supplierName }}</strong>
                    </div>
                    <div>ID: {{ order.id }}</div>
                </div>

                <div class="text-right">
                    <v-chip dark small v-if="order.orderStatus >= 0" :color="orderStatuses[order.orderStatus].color">{{
                        orderStatuses[order.orderStatus].label
                    }}</v-chip
                    ><br />
                    <v-chip
                        class="mt-2"
                        dark
                        small
                        v-if="order.paymentStatus >= 0"
                        :color="paymentStatuses[order.paymentStatus].color"
                        >{{ paymentStatuses[order.paymentStatus].label }}</v-chip
                    >
                </div>
            </div>
        </div>
        <v-row>
            <v-col cols="12" md="6">
                <h5 class="text-center">Line Items</h5>
                <div class="section">
                    <div class="order-line-items p-3">
                        <div v-for="(lineItem, index) in order.lineItems" :key="index">
                            <LineItemStatusDetails :lineItem="lineItem" />
                        </div>
                        <v-divider></v-divider>
                        <div class="text-right">
                            <v-row>
                                <v-col class="text-left"><strong>Total</strong></v-col>
                                <v-col class="align-center"> </v-col>
                                <v-col class="align-center">
                                    <strong>{{ order.amount | formatMoney() }}</strong>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="text-center">
                    <h5>Shipments</h5>
                    <div v-if="order.shipments.length == 0" class="section">
                        No shipment data is available for this purchase at this time.
                    </div>
                    <div class="section" v-for="(shipment, index) in order.shipments">
                        <div style="display: flex; justify-content: space-between">
                            <div>
                                <div>{{ $moment.utc(shipment.updatedDate).local().format('lll') }}</div>
                                <div class="text-left">
                                    <i style="color: gray; font-size: 0.8rem">Updated</i>
                                </div>
                            </div>

                            <v-chip small dark :color="shipmentStatuses[shipment.status].color">{{
                                shipmentStatuses[shipment.status].label
                            }}</v-chip>
                        </div>
                        <div class="mt-2" style="display: flex; justify-content: center">
                            <div v-for="(sli, index) in shipment.shipmentLineItems" :key="index">
                                <ShipmentLineItemStatusDetails class="p-2" :shipmentLineItem="sli" />
                            </div>
                        </div>
                        <div>
                            <span class="mr-2">{{ carriers[shipment.carrier].label }}</span>
                            <a :href="shipment.trackingUrl" target="_blank">{{ shipment.trackingNumber }}</a>
                        </div>
                        <div style="display: flex; flex-direction: column">
                            <v-btn
                                class="mt-2"
                                color="#005069"
                                dark
                                small
                                v-if="shipment.trackingUrl"
                                @click="openTab(shipment.trackingUrl)"
                                >Tracking</v-btn
                            >
                            <v-btn
                                color="#005069"
                                dark
                                small
                                class="mt-2"
                                v-if="shipment.labelUrl"
                                @click="openTab(shipment.labelUrl)"
                                >Label</v-btn
                            >
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { orderStatuses, paymentStatuses, shipmentStatuses, carriers } from '@/constants';
import LineItemStatusDetails from '@/components/Shop/LineItemStatusDetails.vue';
import ShipmentLineItemStatusDetails from '@/components/Shop/ShipmentLineItemStatusDetails.vue';
import moment from 'moment';
export default {
    data() {
        return {
            orderStatuses,
            paymentStatuses,
            shipmentStatuses,
            carriers,
        };
    },
    props: ['order'],
    components: { LineItemStatusDetails, ShipmentLineItemStatusDetails },
    methods: {
        openTab(url) {
            window.open(url, '_blank');
        },
    },
};
</script>
<style scoped>
.order-group {
    border: 2px solid #eee;
    border-radius: 10px;
    margin: 20px 0;
}
.align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .gray-border {
    border: 2px solid #eee;
    border-radius: 10px;
} */
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <v-card-title>
            <h2 class="text-light d-inline-block">
                <font-awesome-icon icon="fa-regular fa-box-archive" style="font-size: 1rem" /> Services -
                {{ funeralHome }}
            </h2>
        </v-card-title>
        <services-table :home_id="home_id" />
    </AppLayout>
</template>

<script>
import ServicesTable from '@/components/Tables/Services/ServiceByFuneralHome.vue';

export default {
    name: 'Services',
    metaInfo: {
        title: 'Services',
    },
    components: {
        ServicesTable,
    },
    data() {
        return {
            home_id: '',
            funeralHome: '',
        };
    },
    mounted: function () {
        const { funeral_home_id } = this.$route.params;
        this.home_id = funeral_home_id;

        this.$auth.getIdTokenClaims().then(result => {
            const token = result.__raw;

            this.axios
                .create({ headers: { Authorization: `Bearer ${token}` } })
                .get(process.env.VUE_APP_API + `/FuneralHomes/${funeral_home_id}`)
                .then(response => {
                    this.funeralHome = response.data.funeralHome.name;
                })
                .catch(error => {});
        });
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat fixed 0 -18rem;
}

.new-service-btn:hover,
.new-service-btn:focus {
    text-decoration: none;
}

.v-data-table-header th {
    white-space: nowrap;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="d-flex justify-end mb-4">
            <v-btn class="create-button" :to="{ name: 'CreateOwner' }">+ Create New Owner</v-btn>
        </div>

        <v-card class="p-4">
            <v-card-title>
                <font-awesome-icon icon="fa-regular fa-suitcase" style="margin-right: 0.5rem" />
                <h4 class="m-0">Owners</h4>

                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    @input="watchSearch"
                    v-on:keyup.enter="getOwners"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                :items="owners"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
                loading-text="Getting Owners..."
                :search="search"
                :headers="headers"
                :options.sync="options"
                :server-items-length="totalOwners"
                @click:row="handleRowClick"
                :must-sort="true"
            >
                <template v-slot:item.actions="{ item }">
                    <div class="action-list">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="goToSettings(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-gear" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Settings</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        @click.stop="getUsers(item)"
                                        icon="fa-regular fa-user-plus"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Users</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="impersonate(item.id)">
                                    <font-awesome-icon
                                        icon="fa-regular fa-arrow-up-right-from-square"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Impersonate </span>
                            </template>
                        </custom-tooltip>
                    </div>
                    <owner-user-table ref="ownerUserTable" :itemId="item.id" />
                </template>
                <template v-slot:item.name="{ item }">
                    <div class="position-relative">
                        {{ item.name }}

                        <!-- <div v-if="item.oldFuneralHomeId === -1" style="line-height: 0.9rem"> -->
                        <!-- <small style="color: #ffc400"> Reverted Home {{ item.oldFuneralHomeId }} </small>-->
                        <!-- <div class="reverted-home-border"></div>  -->
                        <!-- </div> -->
                        <!-- <div
                                v-else-if="item.oldFuneralHomeId !== null && item.oldFuneralHomeId !== 0"
                                style="line-height: 0.9rem"
                            > -->
                        <!-- <small style="color: #750085"> Migrated Home {{ item.oldFuneralHomeId }} </small>
                                <div class="migrated-home-border"></div> -->
                        <!-- </div> -->
                        <!-- <div v-else-if="item.devHome" style="line-height: 0.9rem">
                                <small style="color: #ff530d">Dev Home</small>
                                <div class="dev-home-border"></div>
                            </div> -->
                    </div>
                </template>
                <template v-slot:item.contact="{ item }">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>

                <template v-slot:item.location="{ item }">
                    <span v-if="item.city && item.state">{{ item.city + ', ' + item.state }}</span>
                    <span v-else>{{ item.city + '' + item.state }}</span>
                </template>
                <template v-slot:item.subscriptionStatus="{ item }">
                    <v-chip :color="subscriptionStatuses[item.subscriptionStatus].color" dark>
                        {{ subscriptionStatuses[item.subscriptionStatus].label }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="createOrEditModal" persistent max-width="720">
            <v-card>
                <v-card-title class="headline"> Owner </v-card-title>
                <div>
                    <v-form class="form-container pt-0 pb-0 pl-8 pr-8" ref="form" lazy-validation>
                        <v-text-field
                            v-model="form.name"
                            label="Name"
                            :rules="[v => !!v || 'Name is required']"
                        ></v-text-field>

                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field v-model="form.address1" label="Address 1"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="form.address2" label="Address 2"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field v-model="form.city" label="City"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="form.state" label="State"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="form.zipCode" label="Zip"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field v-model="form.email" label="Email"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="form.primaryContactEmail"
                                    label="Primary Contact Email"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-0">
                            <v-col>
                                <v-text-field v-model="form.primaryContact" label="Primary Contact"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="form.primaryContactPhoneNumber"
                                    label="Primary Contact Phone Number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="createOrEditModal = false"> cancel </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </AppLayout>
</template>

<script>
import ownerUserTable from '@/components/Tables/OwnersTable';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

import { subscriptionStatuses } from '@/constants';
export default {
    metaInfo: {
        title: 'Owners',
    },
    data() {
        return {
            subscriptionStatuses,
            token: '',
            owners: [],
            loading: true,
            search: '',
            selectedOwner: null,
            selectedIndex: -1,
            createOrEditModal: false,
            deleteModal: false,
            totalOwners: 0,
            sortBy: 'id',
            options: {},
            selectedItem: {},
            form: {
                name: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipCode: '',
                email: '',
                primaryContact: '',
                primaryContactPhoneNumber: '',
                primaryContactEmail: '',
            },
            headers: [
                { text: 'Id', value: 'id', sortable: true },
                { text: 'Owner', value: 'name' },
                { text: 'Funeral Homes', value: 'totalHomes' },
                { text: 'Location', value: 'location' },
                { text: 'Contact', value: 'contact' },
                { text: 'Subscription Status', value: 'subscriptionStatus', align: 'center', width: '10%' },
                { text: 'Actions', value: 'actions', width: '20%', align: 'center', sortable: false },
            ],
        };
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
            this.getOwners();
        });

        this.search = '';
        this.options = {
            itemsPerPage: 25,
            page: 1,
            sortBy: ['id'],
        };
    },
    methods: {
        goToSettings(id) {
            this.$router.push({ path: `/owners/settings/${id}` });
        },
        getSubscriptionColor(status, isDevHome) {
            return !isDevHome ? subscriptionStatuses[status].color : 'orange';
        },
        getSubscriptionValue(status, isDevHome) {
            return !isDevHome ? subscriptionStatuses[status].value : 'Dev';
        },
        handleRowClick(item) {
            this.$router.push({ path: `/homes/${item.id}` });
        },
        impersonate(id) {
            this.$swal.fire({
                icon: 'error',
                title: 'This Feature is Coming Soon',
            });

            return;
        },
        getUsers(item) {
            if (this.$refs.ownerUserTable) {
                this.$refs.ownerUserTable.openDialog = true;
                this.$refs.ownerUserTable.id = item.id;
            }
        },

        watchSearch() {
            if (this.search.length === 0) {
                this.getOwners();
            } else {
                this.options = {
                    ...this.options,
                    page: 1,
                };
                this.getOwners();
            }
        },
        getSortBy() {
            const { sortBy } = this.options;
            let sortString = sortBy && sortBy[0] ? sortBy[0] : null || null;

            if (sortString) {
                switch (sortString) {
                    case 'id':
                        return 'Id';
                    case 'name':
                        return 'Name';
                    case 'location':
                        return 'City';
                    case 'contact':
                        return 'PrimaryContact';
                    case 'ownerName':
                        return 'OwnerName';
                    case 'subscriptionStatus':
                        return 'SubscriptionStatus';
                    default:
                        return 'Id';
                }
            } else {
                return 'Id';
            }
        },
        getOwners() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            this.loading = true;
            this.errorLoading = false;
            //history.replaceState(undefined, undefined, `?page=${ page }`)

            const sortByField = this.getSortBy();

            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(
                            process.env.VUE_APP_API +
                                `/Owners?pageSize=${itemsPerPage}&pageNumber=${page ? page - 1 : 1}&sortAsc=${
                                    sortBy && sortBy[0] ? !sortDesc[0] : false || false
                                }&sortBy=${sortByField}&search=${this.search.toString().toLowerCase()}`,
                        )
                        .then(response => {
                            this.owners = response.data.owners;

                            // this.totalOwners = response.data.length;
                            this.totalOwners = response.data.total;
                            this.loading = false;
                            resolve(response.data);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            });
        },
    },
    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.getOwners();
                }
            },
            deep: true,
        },
    },
    components: {
        ownerUserTable,
        CustomTooltip,
    },
};
</script>
<style lang="scss" scoped>
.dev-home-border {
    background: #ff530d;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.action-list {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.migrated-home-border {
    background: #750085;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.reverted-home-border {
    background: #ffc400;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.v-application p {
    margin-bottom: 0px;
    margin-right: 9px;
}

.respCall {
    width: 100%;
    padding: 10px 4px 16px 14px;
}

.textF {
    font-weight: 200;
}
.errorSub {
    color: red;
    font-size: 11px;
}

.textBenifit {
    font-size: 15px;
    margin: 8px 4px 4px 10px;
}

.create-button {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
</style>

import { render, staticRenderFns } from "./ServicesByHome.vue?vue&type=template&id=387a9121&scoped=true&"
import script from "./ServicesByHome.vue?vue&type=script&lang=js&"
export * from "./ServicesByHome.vue?vue&type=script&lang=js&"
import style0 from "./ServicesByHome.vue?vue&type=style&index=0&id=387a9121&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387a9121",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardTitle})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Super Admin Usage Records ")]),(_vm.loaded)?_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.event.title))]),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.liveStreamData},scopedSlots:_vm._u([{key:"header.usageType",fn:function(ref){
var header = ref.header;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":true,"disabled":!_vm.loaded},on:{"input":_vm.handleMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('custom-tooltip',{attrs:{"tooltipProps":{ top: true }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, menu)),[_vm._v(" "+_vm._s(header.text)+" ("+_vm._s(_vm.selectedUsageTypes.length)+") "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v("Select Filters")])]},proxy:true}],null,true)})]}}],null,true)},[_c('v-list',{staticStyle:{"width":"200px","overflow":"hidden"}},_vm._l((_vm.usageRecordTypes),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"height":"20px"}},[_c('v-checkbox',{attrs:{"label":item.label,"value":index},model:{value:(_vm.selectedUsageTypes),callback:function ($$v) {_vm.selectedUsageTypes=$$v},expression:"selectedUsageTypes"}})],1)}),1)],1)]}},{key:"item.startedTime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"date-time-stamp"},[_c('span',[_vm._v(_vm._s(item.startedTime))]),_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(item.startedTimeHours))])])]}},{key:"item.endedTime",fn:function(ref){
var item = ref.item;
return [(item.endedTime != 'Invalid date')?_c('div',{staticClass:"date-time-stamp"},[_c('span',[_vm._v(_vm._s(item.endedTime))]),_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(item.endedTimeHours))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-trash-can"},on:{"click":function($event){return _vm.deleteRecord(item)}}})],1)])]}},{key:"item.usageType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","dark":"","small":"","color":_vm.usageRecordTypes[item.usageType].color}},[_vm._v(" "+_vm._s(_vm.usageRecordTypes[item.usageType].label)+" ")])]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.completed ? '#975DBE' : 'orange',"dark":"","small":"","label":""}},[_vm._v(_vm._s(item.completed ? 'Closed' : 'Open'))])]}}],null,false,3598697803)})],1):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"p-3"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary "}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <AppLayout :has-background="true" :contained="true">
        <div>
            <ShipmentDetailsComponent :id="$route.params.id" />
        </div>
    </AppLayout>
</template>
<script>
import ShipmentDetailsComponent from '@/components/Shop/ShipmentDetailsComponent.vue';
export default {
    metaInfo: { title: 'Shipment Details' },
    data() {
        return {};
    },
    components: { ShipmentDetailsComponent },
};
</script>
<style></style>

<template>
    <div>
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-magnifying-glass" style="margin-right: 0.5rem" />
            <h3 class="m-0">Live Stream Search</h3>
            <v-spacer></v-spacer>

            <div style="display: flex">
                <v-text-field label="Search" v-model="search"></v-text-field>
                <v-spacer></v-spacer>
                <v-select style="width: 300px" v-model="searchParam" :items="searchKeys"></v-select>
            </div>
        </v-card-title>

        <v-data-table
            class="w-95 centered"
            @click:row="goToService"
            :headers="headers"
            :items="streams"
            :server-items-length="totalStreams"
            :options.sync="options"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
        >
            <template v-slot:item.startedTime="{ item }">
                <span v-if="item.startedTime">
                    <strong v-if="item.startedTime">{{ item.startedTime | timeFilter() }}</strong
                    ><br />
                    {{ item.startedTime | dateFilter() }}
                </span>
            </template>

            <template v-slot:item.endedTime="{ item }">
                <span v-if="item.endedTime">
                    <strong v-if="item.endedTime">{{ item.startedTime | timeFilter() }}</strong
                    ><br />
                    {{ item.endedTime | dateFilter() }}
                </span>
            </template>

            <template v-slot:item.serviceSlug="{ item }">
                {{ item.serviceSlug }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import moment from 'moment';
import debounce from '@/utilities/debounce.js';

import { mapActions } from 'vuex';

export default {
    data() {
        return {
            searchParam: 'wowzaStreamId',
            paramString: '',
            search: '',
            streams: [],
            totalStreams: 0,
            options: {
                itemsPerPage: 25,
            },
            searchKeys: [
                { text: 'Stream ID', value: 'wowzaStreamId' },
                { text: 'Stream Name', value: 'streamName' },
            ],
            headers: [
                { text: 'Stream ID', value: 'streamId', sortable: false },
                { text: 'Stream Name', value: 'streamName', sortable: false },
                { text: 'Event', value: 'eventTitle', width: '15%', sortable: false },
                { text: 'Slug', value: 'serviceSlug', sortable: false },
                { text: 'Start Time', value: 'startedTime', width: '10%' },
                { text: 'End Time', value: 'endedTime', width: '10%' },
            ],
        };
    },
    components: {},
    filters: {
        dateFilter(value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('l');
        },
        timeFilter(value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LT');
        },
    },
    watch: {
        search() {
            this.options.page = 1;
            this.debouncedGetResults();
        },
        searchParam() {
            this.options.page = 1;
            this.getResults();
        },
        options() {
            this.getResults();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getToken() {
            this.$auth.getIdTokenClaims().then(response => {
                this.token = response.__raw;
            });
        },
        getQueryParams() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            const params = {
                pageNumber: page ? page - 1 : 1,
                pageSize: itemsPerPage,
            };
            return params;
        },
        getResults() {
            this.$auth.getIdTokenClaims().then(response => {
                const _TOKEN = response.__raw;
                const params = this.getQueryParams();

                if (this.$auth.role.includes('SuperAdmin')) {
                    this.axios
                        .create({
                            headers: { Authorization: `Bearer ${_TOKEN}` },
                        })
                        .get(process.env.VUE_APP_API + `/Live/search?${this.searchParam}=${this.search}`, { params })

                        .then(response => {
                            this.streams = response.data.data;
                            this.totalStreams = response.data.total;
                        })

                        .catch(error => {
                            console.log(error);
                            this.showSnackbar({ message: 'Error retreiving results' });
                        });
                }
            });
        },
        goToService(item) {
            this.$router.push({ path: `/services/${item.serviceSlug}` });
        },
    },
    mounted() {
        this.getToken();
        this.debouncedGetResults();
    },
    created() {
        this.debouncedGetResults = debounce(this.getResults, 500);
    },
};
</script>

<style>
.centered {
    margin: 0 auto;
}
.w-95 {
    width: 95%;
}
</style>

<template>
    <div>
        <div class="text-right d-flex mt-4 mb-2" style="gap: 12px">
            <v-btn depressed @click="initServiceSummaryModal"
                >Service Summary<font-awesome-icon icon="fa-regular fa-plus" class="ml-2"></font-awesome-icon
            ></v-btn>

            <v-btn depressed @click="addFhJobModal = true"
                >Fh Summary<font-awesome-icon icon="fa-regular fa-plus" class="ml-2"></font-awesome-icon
            ></v-btn>

            <v-btn depressed @click="monthlyBatchModal = true"
                >Monthly FH Batch<font-awesome-icon icon="fa-regular fa-plus" class="ml-2"></font-awesome-icon
            ></v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="jobs"
            :server-items-length="total"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting Renders..."
        >
            <template v-slot:item.status="{ item }">
                <v-chip dark :color="emailJobStatuses[item.status].color">{{
                    emailJobStatuses[item.status].label
                }}</v-chip>
            </template>

            <template v-slot:item.emailType="{ item }">
                <v-chip dark :color="emailJobTypes[item.emailType].color">{{
                    emailJobTypes[item.emailType].label
                }}</v-chip>
            </template>

            <template v-slot:item.contacts="{ item }">
                <v-chip @click="openContactsModal(item)" label dark>
                    {{ item.contacts.length }}
                    {{ item.contacts.length == 1 ? 'Contact' : 'Contacts' }}
                </v-chip>
            </template>

            <template v-slot:item.summaryStartDate="{ item }">
                <div v-if="item.summaryStartDate != '0001-01-01T00:00:00'">
                    <div class="text-center">
                        {{ item.summaryStartDate | dateFilter }}
                    </div>
                    <div class="text-center text--secondary">
                        {{ item.summaryStartDate | timeFilter }}
                    </div>
                </div>
            </template>

            <template v-slot:item.summaryStopDate="{ item }">
                <div v-if="item.summaryStopDate != '0001-01-01T00:00:00'">
                    <div class="text-center">
                        {{ item.summaryStopDate | dateFilter }}
                    </div>
                    <div class="text-center text--secondary">
                        {{ item.summaryStopDate | timeFilter }}
                    </div>
                </div>
            </template>
            <template v-slot:item.sendDate="{ item }">
                <div class="text-center">
                    {{ item.sendDate | dateFilter }}
                </div>
                <div class="text-center text--secondary">
                    {{ item.sendDate | timeFilter }}
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openEditModal(item)">
                                <font-awesome-icon icon="fa-regular fa-pencil" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openDeleteModal(item)">
                                <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="addFhJobModal" max-width="400px">
            <v-card>
                <v-card-title>Funeral Home Summary Email Job</v-card-title>
                <v-card-text>
                    <v-text-field v-model="tempFhId" label="Funeral Home Id"></v-text-field>
                    <div class="email-job-calendar-container">
                        <calendar v-model="summaryStartDate" label="Start Date" />
                    </div>
                    <div class="email-job-calendar-container">
                        <calendar v-model="summaryStopDate" label="Stop Date" />
                    </div>
                    <div class="email-job-calendar-container">
                        <calendar v-model="sendDate" datetime label="Send Date" />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelAddJob">Cancel</v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        @click="submitAddFhEmailJob(tempFhId, summaryStartDate, summaryStopDate, sendDate)"
                        >Submit</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addServiceJobModal" max-width="400px">
            <v-card>
                <v-card-title>Service Summary Email Job</v-card-title>
                <v-card-text>
                    <v-text-field v-model="tempServiceId" label="Service Id"></v-text-field>

                    <v-text-field v-model="tempRecipientEmail" label="Email"></v-text-field>

                    <v-text-field v-model="tempRecipientFirstName" label="First Name"></v-text-field>

                    <div class="email-job-calendar-container">
                        <calendar v-model="sendDate" datetime label="Send Date" />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelAddJob">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="submitAddServiceEmailJob(tempServiceId, sendDate)"
                        >Submit</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width="500px">
            <v-card v-if="selectedJob">
                <v-card-title>Delete Job?</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this Job?
                    <v-text-field disabled v-model="selectedJob.id" label="Id"></v-text-field>
                    <v-text-field disabled v-model="selectedJob.funeralHomeId" label="Funeral Home"></v-text-field>
                    <v-text-field
                        disabled
                        :value="selectedJob.summaryStartDate | dateFilter"
                        label="StartDate"
                    ></v-text-field>
                    <div class="email-job-calendar-container">
                        <calendar disabled v-model="selectedJob.summaryStopDateDate" label="Stop Date" />
                    </div>

                    <div class="email-job-calendar-container">
                        <calendar disabled v-model="selectedJob.sendDate" datetime label="Send Date" />
                    </div>

                    <v-text-field disabled v-model="selectedJob.contactName" label="Name"></v-text-field>
                    <v-text-field disabled v-model="selectedJob.contactEmail" label="Email"></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="cancelDelete">Cancel</v-btn>
                    <v-btn depressed color="error" @click="submitDeleteEmailJob(selectedJob.id)">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editModal" max-width="600px">
            <v-card v-if="selectedJob">
                <v-card-title>Edit Job</v-card-title>
                <v-card-text>
                    <v-text-field disabled v-model="selectedJob.id" label="Id"></v-text-field>
                    <v-text-field disabled v-model="selectedJob.funeralHomeId" label="Funeral Home"></v-text-field>
                    <v-text-field
                        v-if="selectedJob.emailType == 1"
                        disabled
                        v-model="selectedJob.serviceSlug"
                        label="Service Slug"
                    ></v-text-field>

                    <v-text-field
                        disabled
                        :value="selectedJob.summaryStartDate | dateFilter"
                        label="Start Date"
                    ></v-text-field>
                    <v-text-field
                        disabled
                        :value="selectedJob.summaryStopDate | dateFilter"
                        label="Stop Date"
                    ></v-text-field>

                    <div class="email-job-calendar-container">
                        <calendar
                            v-model="selectedJob.sendDate"
                            @focus="vis => updateCalendarVis(vis)"
                            datetime
                            label="Send Date"
                        />
                    </div>

                    <v-text-field v-model="selectedJob.contactName" label="Name"></v-text-field>
                    <v-text-field v-model="selectedJob.contactEmail" label="Email"></v-text-field>
                    <v-select
                        v-model="selectedJob.status"
                        :items="emailJobStatuses"
                        item-text="label"
                        item-value="value"
                    >
                        <template #item="{ item }">
                            <v-chip dark :color="item.color">
                                {{ item.label }}
                            </v-chip>
                        </template>

                        <template #selection="{ item }">
                            <v-chip dark :color="item.color">{{ item.label }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>
                <v-card-actions>
                    <div class="d-flex flex-column flex-md-row" style="width: 100%; gap: 12px">
                        <div class="d-flex flex-column flex-md-row" style="width: 100%; gap: 12px">
                            <v-btn depressed :block="$vuetify.breakpoint.smAndDown" @click="cancelEdit">Cancel</v-btn>
                            <v-btn
                                dark
                                depressed
                                :block="$vuetify.breakpoint.smAndDown"
                                @click="openPreview(selectedJob)"
                                >Preview</v-btn
                            >
                        </div>
                        <v-btn
                            depressed
                            color="primary"
                            :block="$vuetify.breakpoint.smAndDown"
                            @click="submitUpdateEmailJob()"
                            >Save</v-btn
                        >
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="monthlyBatchModal" max-width="500px">
            <v-card>
                <v-card-title>Generate Email Job for all homes this month?</v-card-title>
                <v-card-text>
                    This will generate an analytics email job for all homes in <strong>Created</strong> status. Update
                    each job's status to <strong>Scheduled</strong> to send on sendDate.

                    <div class="email-job-calendar-container">
                        <calendar v-model="summaryStartDate" label="Summary Start Date" />
                    </div>
                    <div class="email-job-calendar-container">
                        <calendar v-model="summaryStopDate" label="Summary Stop Date" />
                    </div>

                    <div class="email-job-calendar-container">
                        <calendar v-model="sendDate" datetime label="Send Date" />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="cancelMonthlyBatch" depressed>Cancel</v-btn>
                    <v-btn
                        depressed
                        color="warning"
                        @click="createMonthlyBatch(summaryStartDate, summaryStopDate, sendDate)"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="contactsModal" max-width="500px">
            <v-card>
                <v-card-title>Contacts</v-card-title>
                <v-card-text>
                    <div style="max-height: 600px; overflow: auto">
                        <v-list dense v-if="selectedJob">
                            <div v-for="(item, index) in selectedJob.contacts">
                                <v-list-item>
                                    <div>
                                        <div>
                                            {{ item.name }}
                                        </div>
                                        <div class="text--secondary">
                                            {{ item.email }}
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-divider v-if="index != selectedJob.contacts.length - 1"></v-divider>
                            </div>
                        </v-list>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="contactsModal = false" depressed> Close </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { emailJobStatuses, emailJobTypes } from '@/constants';
import { mapActions } from 'vuex';
import Calendar from '@/components/ui/Calendar.vue';

export default {
    metaInfo: {
        title: 'Email Jobs',
    },
    components: { CustomTooltip, Calendar },
    data() {
        return {
            emailJobStatuses,
            emailJobTypes,
            addFhJobModal: false,
            addServiceJobModal: false,
            currentOffset: null,
            token: '',
            jobs: [],
            total: 0,
            headers: [
                {
                    text: 'Id',
                    value: 'id',
                },
                {
                    text: 'Funeral Home',
                    value: 'funeralHomeId',
                },
                {
                    text: 'Contacts',
                    value: 'contacts',
                },
                {
                    text: 'Type',
                    value: 'emailType',
                },
                // {
                //     text: 'Email',
                //     value: 'contactEmail',
                // },
                {
                    text: 'Summary Start',
                    value: 'summaryStartDate',
                },
                {
                    text: 'Summary End',
                    value: 'summaryStopDate',
                },
                {
                    text: 'Send Date',
                    value: 'sendDate',
                },
                {
                    text: 'Status',
                    value: 'status',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: '20%',
                    align: 'center',
                },
            ],
            options: {
                itemsPerPage: 25,
            },
            loading: true,
            tempFhId: null,
            tempServiceId: null,
            selectedJob: null,
            deleteModal: false,
            sendDate: null,
            calendarShowing: false,
            editModal: false,
            monthlyBatchModal: false,
            summaryStartDate: null,
            summaryStopDate: null,
            contactsModal: false,
            tempRecipientEmail: '',
            tempRecipientFirstName: '',
        };
    },

    filters: {
        truncate: function (text, length, suffix) {
            if (text && text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        dateFilter: function (value) {
            if (value === '0001-01-01T00:00:00') {
                return 'N/A';
            }

            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LL');
        },
        timeFilter: function (value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LT');
        },
    },
    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.getJobs();
                }
            },
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getJobs();

        this.resetBatchTimes();
    },
    sockets: {
        NotifyAnalyticsEmail(data) {
            this.updateEmailJob(data);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        updateEmailJob(data) {
            const found = this.jobs.find(x => x.id == data.id);

            if (!found) return;

            found.status = data.status;
        },
        openPreview(selectedJob) {
            if (selectedJob.emailType == 0) {
                window.open(
                    `/analytics?fh=${selectedJob.funeralHomeId}&startDate=${selectedJob.summaryStartDate}&endDate=${selectedJob.summaryStopDate}`,
                    '_blank',
                );
            } else if (selectedJob.emailType == 1) {
                const startDate =
                    selectedJob.summaryStartDate == '0001-01-01T00:00:00' ? null : selectedJob.summaryStartDate;
                const stopDate =
                    selectedJob.summaryStopDate == '0001-01-01T00:00:00' ? null : selectedJob.summaryStopDate;

                window.open(
                    `/analytics/${selectedJob.serviceSlug}?startDate=${startDate}&endDate=${stopDate}`,
                    '_blank',
                );
            }
        },
        openContactsModal(item) {
            this.selectedJob = { ...item };
            this.contactsModal = true;
        },
        cancelMonthlyBatch() {
            this.monthlyBatchModal = false;
            this.resetBatchTimes();
        },
        initServiceSummaryModal() {
            this.addServiceJobModal = true;
        },
        createMonthlyBatch(summaryStartDate, summaryStopDate, sendDate) {
            if (!summaryStartDate || summaryStartDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid start date', color: 'error' });
                return;
            }

            if (!sendDate || sendDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid send date', color: 'error' });
                return;
            }

            let data = {
                summaryStartDate: summaryStartDate,
                summaryStopDate: summaryStopDate,
                sendDate: sendDate,
            };

            this.axiosInstance
                .post(`/dogs/summary-email/generate-monthly-jobs-batch`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Monthly email job batch created' });
                    this.monthlyBatchModal = false;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error creating monthly batch', color: 'error' });
                    console.log(error, 'error');
                });
        },
        resetBatchTimes() {
            const offset = moment(new Date()).utcOffset();
            this.summaryStartDate = this.$moment.utc().utcOffset(offset).startOf('month').toISOString();
            this.summaryStopDate = this.$moment.utc().utcOffset(offset).startOf('month').add(1, 'month').toISOString();

            this.sendDate = this.$moment.utc().utcOffset(offset).startOf('day').add(2, 'd').hour(11).toISOString();
        },
        updateCalendarVis(visibility) {
            this.calendarShowing = visibility;
        },
        openDeleteModal(item) {
            this.selectedJob = item;
            this.deleteModal = true;
        },
        openEditModal(item) {
            const localTimeString = this.$moment.utc(item.sendDate).local().format('YYYY-MM-DD HH:mm:ss');

            this.selectedJob = { ...item, sendDate: localTimeString };
            this.editModal = true;
        },
        cancelDelete() {
            this.deleteModal = false;
            this.selectedJob = null;
        },
        cancelEdit() {
            this.editModal = false;
            this.selectedJob = null;
        },
        submitDeleteEmailJob(id) {
            this.axiosInstance
                .delete(`/dogs/summary-email/${id}`)
                .then(resp => {
                    this.cancelDelete();
                    this.getJobs();
                    this.showSnackbar({ message: 'Job deleted' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error adding job', color: 'error' });
                });
        },
        submitUpdateEmailJob() {
            this.selectedJob.sendDate = this.$moment(this.selectedJob.sendDate).utc().toISOString();
            this.axiosInstance
                .put(`/dogs/summary-email/${this.selectedJob.id}`, this.selectedJob)
                .then(resp => {
                    this.cancelEdit();
                    this.getJobs();
                    this.showSnackbar({ message: 'Job updated' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error adding job', color: 'error' });
                });
        },
        submitAddFhEmailJob(funeralHomeId, summaryStartDate, summaryStopDate, sendDate) {
            if (!summaryStartDate || summaryStartDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid start date', color: 'error' });
                return;
            }

            if (!summaryStopDate || summaryStopDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid stop date', color: 'error' });
                return;
            }

            if (!sendDate || sendDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid send date', color: 'error' });
                return;
            }

            if (!funeralHomeId || funeralHomeId <= 0) {
                this.showSnackbar({ message: 'Invalid Funeral Home Id' });
                return;
            }

            let data = {
                summaryStartDate: summaryStartDate,
                summaryStopDate: summaryStopDate,
                sendDate: sendDate,
            };

            this.axiosInstance
                .post(`/dogs/summary-email-fh/${funeralHomeId}`, data)
                .then(resp => {
                    this.cancelAddJob();
                    this.getJobs();
                    this.showSnackbar({ message: 'Job added' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error adding job', color: 'error' });
                });
        },
        submitAddServiceEmailJob(serviceId, sendDate) {
            if (!sendDate || sendDate === '0001-01-01T00:00:00') {
                this.showSnackbar({ message: 'Invalid send date', color: 'error' });
                return;
            }

            if (!serviceId || serviceId <= 0) {
                this.showSnackbar({ message: 'Invalid Service Id', color: 'error' });
                return;
            }

            if (this.tempRecipientEmail && !this.tempRecipientFirstName) {
                this.showSnackbar({ message: 'First name cannot be blank when adding custom email', color: 'error' });
                return;
            }

            if (!this.tempRecipientEmail && this.tempRecipientFirstName) {
                this.showSnackbar({ message: 'Email cannot be blank when adding custom name', color: 'error' });
                return;
            }

            let data = {
                summaryStartDate: null,
                summaryStopDate: null,
                sendDate: sendDate,
                email: this.tempRecipientEmail,
                firstName: this.tempRecipientFirstName,
            };

            this.axiosInstance
                .post(`/dogs/summary-email-service/${serviceId}`, data)
                .then(resp => {
                    this.cancelAddJob();
                    this.getJobs();
                    this.showSnackbar({ message: 'Job added' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error adding job', color: 'error' });
                });
        },
        cancelAddJob() {
            this.addFhJobModal = false;
            this.addServiceJobModal = false;
            this.tempFhId = null;
            this.tempServiceId = null;
            this.resetBatchTimes();
        },
        getJobs() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
            };

            this.axiosInstance
                .get(process.env.VUE_APP_API + `/dogs/summary-email/queue`, { params: params })
                .then(response => {
                    if (response.data) {
                        this.jobs = response.data.jobs;
                        this.total = response.data.total;

                        this.jobs = this.jobs.map(job => {
                            const emails = job.contactEmail ? job.contactEmail.split(',') : [];
                            const names = job.contactName ? job.contactName.split(',') : [];

                            const contacts = emails.map((email, index) => {
                                const name = names.length > index ? names[index] : null;
                                return { name, email };
                            });

                            return { ...job, contacts };
                        });
                    }
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .then(() => {
                    this.loading = false;
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'id':
                    return 'Id';
                case 'funeralHomeId':
                    return 'FuneralHomeId';
                case 'contactName':
                    return 'ContactName';
                case 'contatEmail':
                    return 'ContactEmail';
                case 'status':
                    return 'Status';
                case 'summaryStartDate':
                    return 'SummaryStartDate';
                default:
                    console.warn('No cases found in switch statement.');
                    return 'Id';
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
};
</script>
<style lang="scss">
.email-job-calendar-container .ms-date-picker {
    transition: 1s;
    .main-container {
        max-width: 330px;
        position: relative;
        top: 0;
        margin-bottom: 12px;
    }
}
</style>

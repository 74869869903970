var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ms-main-app-container"},[_c('SideMenu',{attrs:{"collapsedMenu":!_vm.$store.state.menuShowing},on:{"toggleMenu":function($event){return _vm.toggleMenuv2()}}}),_c('section',{staticClass:"ms-main-content-container"},[_c('HeaderBar',{on:{"toggleMenu":function($event){return _vm.toggleMenuv2()}}}),(_vm.showNotificationBar)?_c('notification-bar',{attrs:{"subscriptionStatus":_vm.$auth.session.home.subscriptionStatus}}):_vm._e(),_c('div',{staticClass:"main-wrapper",class:{ 'red-background': _vm.hasBackground },attrs:{"id":"scroll"}},[_c('main',{staticClass:"ms-main-content",class:{ 'contained-content': _vm.contained }},[_c('promo-banner'),_vm._t("default"),(
                        !_vm.$auth.role.includes('SuperAdmin') &&
                        _vm.$auth.session &&
                        _vm.$auth.session.home &&
                        (_vm.$auth.session.home.subscriptionStatus === 5 ||
                            _vm.$auth.session.home.subscriptionStatus === 6 ||
                            _vm.$auth.session.home.subscriptionStatus === 7)
                    )?_c('div',{staticClass:"ms-notification-bar-spacer"}):_vm._e(),_c('div',{staticClass:"imhere",staticStyle:{"width":"100%","height":"2rem"}})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div name="snackbars">
        <v-snackbar v-model="show" :color="color" :timeout="timeout">
            {{ message }}
            <template v-if="button" v-slot:action="{ attrs }">
                <v-btn :color="button.color" text v-bind="attrs" @click="button.callback">
                    {{ button.text }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'showSnackbar') {
                this.show = true;
                this.message = state.snackbar.message;
                this.color = state.snackbar.color;
                this.timeout = state.snackbar.timeout;
                this.button = state.snackbar.button;
            }
        });
    },
    data() {
        return {
            show: false,
            color: '',
            message: '',
            timeout: -1,
            button: null,
        };
    },
};
</script>

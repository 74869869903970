<template>
    <div>
        <v-dialog v-model="dialog" max-width="700px">
            <v-card class="p-3">
                <v-form v-model="formIsValid">
                    <div style="display: flex; justify-content: space-between">
                        <strong>Product Details</strong>
                        <v-chip v-if="status !== null" dark :color="productStatus[status].color">{{
                            productStatus[status].text
                        }}</v-chip>
                    </div>

                    <v-text-field
                        :rules="[v => !!v || 'This field is required']"
                        v-model="name"
                        label="Name"
                        required
                    ></v-text-field>
                    <v-select label="Category" v-model="category" :items="categories"></v-select>
                    <v-text-field type="number" min="0" v-model.number="inventory" label="Inventory"></v-text-field>
                    <v-textarea v-model="description" outlined label="Description"></v-textarea>
                    <div v-if="!editing">
                        <v-select label="Pricing Model" :items="pricingModels" v-model="productPriceModel"> </v-select>
                        <div v-if="productPriceModel == 1">
                            <v-row no-gutters>
                                <v-col class="text-right">
                                    <v-btn text small @click="addTier(priceTiers.length - 1)">+ Add Another Tier</v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-for="(tier, index) in priceTiers">
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        v-model.number="tier.fromUnits"
                                        :value="index - 1 >= 0 ? parseInt(priceTiers[index - 1].toUnits) + 1 : 1"
                                        label="From (Units)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :min="tier.fromUnits"
                                        type="number"
                                        :disabled="index + 1 == priceTiers.length"
                                        v-model.number="tier.toUnits"
                                        label="To (Units)"
                                        :prepend-inner-icon="index + 1 == priceTiers.length ? 'mdi-infinity' : ''"
                                        @input="updateNextToValue(index)"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="tier.price"
                                    ></vuetify-money>
                                </v-col>
                                <div class="mr-3" style="display: flex; align-items: center">
                                    <v-btn text small @click="removeTier(index)">X</v-btn>
                                </div>
                            </v-row>
                        </div>

                        <div v-if="productPriceModel == 0">
                            <v-row>
                                <v-col>
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="priceTiers[0].price"
                                    ></vuetify-money>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <div class="mt-3" v-if="editing">
                        <div style="display: flex; justify-content: space-between">
                            <strong>Price Details</strong>
                            <v-btn @click="openPriceUpdateModal" outlined small>Edit Pricing</v-btn>
                        </div>
                        <p>Pricing Model: {{ pricingModels[selectedProduct.priceModel].text }}</p>
                        <div v-if="selectedProduct.priceModel == 1">
                            <v-data-table :items="selectedProduct.prices" :headers="priceHeaders" hide-default-footer>
                                <template v-slot:item.price="{ item }">
                                    {{ item.price | formatMoney }}
                                </template>
                            </v-data-table>
                        </div>
                        <div v-if="selectedProduct.priceModel == 0">
                            <vuetify-money
                                :rules="[v => !!v || 'This field is required']"
                                :options="currencyOptions"
                                label="Per Unit Price"
                                v-model.number="priceTiers[0].price"
                                readonly
                            ></vuetify-money>
                        </div>
                    </div>

                    <div class="mt-2" style="display: flex; justify-content: space-between">
                        <v-btn @click="cancelForm()">Cancel</v-btn>

                        <v-btn v-if="!editing" @click="createProduct()" :disabled="loading" color="primary"
                            >Create</v-btn
                        >
                        <v-btn v-else @click="updateProduct()" :disabled="loading" color="primary">Update</v-btn>
                    </div>

                    <v-dialog max-width="600" v-model="priceUpdateModal">
                        <v-card class="p-3">
                            <v-select label="Pricing Model" :items="pricingModels" v-model="productPriceModel">
                            </v-select>
                            <div v-if="productPriceModel == 1">
                                <v-row no-gutters>
                                    <v-col class="text-right">
                                        <v-btn text small @click="addTier(priceTiers.length - 1)"
                                            >+ Add Another Tier</v-btn
                                        >
                                    </v-col>
                                </v-row>
                                <v-row v-for="(tier, index) in priceTiers">
                                    <v-col>
                                        <v-text-field
                                            type="number"
                                            v-model.number="tier.fromUnits"
                                            :value="index - 1 >= 0 ? parseInt(priceTiers[index - 1].toUnits) + 1 : 1"
                                            label="From (Units)"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            :min="tier.fromUnits"
                                            type="number"
                                            :disabled="index + 1 == priceTiers.length"
                                            v-model.number="tier.toUnits"
                                            label="To (Units)"
                                            :prepend-inner-icon="index + 1 == priceTiers.length ? 'mdi-infinity' : ''"
                                            @input="updateNextToValue(index)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <vuetify-money
                                            :rules="[v => !!v || 'This field is required']"
                                            :options="currencyOptions"
                                            label="Per Unit Price"
                                            v-model.number="tier.price"
                                        ></vuetify-money>
                                    </v-col>
                                    <div class="mr-3" style="display: flex; align-items: center">
                                        <v-btn text small @click="removeTier(index)">X</v-btn>
                                    </div>
                                </v-row>
                            </div>
                            <div v-else-if="productPriceModel == 0">
                                <v-col>
                                    <vuetify-money
                                        :rules="[v => !!v || 'This field is required']"
                                        :options="currencyOptions"
                                        label="Per Unit Price"
                                        v-model.number="priceTiers[0].price"
                                    ></vuetify-money>
                                </v-col>
                            </div>
                            <div class="text-right">
                                <v-btn :disabled="loading" @click="updatePrice()" color="primary">Update</v-btn>
                            </div>
                        </v-card>
                    </v-dialog>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import ProductImageUpload from '@/components/Misc/ProductImageUpload.vue';

export default {
    data() {
        return {
            editing: false,
            priceUpdateModal: false,
            productToEdit: {},
            token: '',
            formIsValid: false,
            dialog: false,
            productId: '',
            name: '',
            price: '',
            description: '',
            category: 0,
            images: '',
            stripeProductId: '',
            stripePriceId: '',
            status: null,
            inventory: 0,
            selectedFile: null,
            loading: false,
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            productStatus: [
                { text: 'In Review', color: 'orange' },
                { text: 'Active', color: 'green' },
                { text: 'Canceled', color: 'red' },
            ],
            pricingModels: [
                { text: 'Standard', value: 0 },
                { text: 'Volume', value: 1 },
            ],
            productPriceModel: 0,
            priceTiers: [{ price: 0, fromUnits: 1, toUnits: null }],
            categories: ['Media', 'Keepsake', 'Other'],
            priceHeaders: [
                { text: 'From Units', value: 'fromUnits' },
                { text: 'ToUnits', value: 'toUnits' },
                { text: 'Price', value: 'price' },
                // { text: 'Actions', value: 'actions' },
            ],
        };
    },
    props: ['selectedProduct'],
    components: { ProductImageUpload },
    watch: {
        dialog() {
            if (this.editing) {
                this.productId = this.selectedProduct.id;
                this.name = this.selectedProduct.name;
                this.price = this.selectedProduct.price / 100;
                this.category = this.selectedProduct.category;
                this.images = this.selectedProduct.images;
                this.stripeProductId = this.selectedProduct.stripeProductId;
                this.stripePriceId = this.selectedProduct.stripePriceId;
                this.description = this.selectedProduct.description;
                this.supplierId = this.selectedProduct.supplierId;
                this.status = this.selectedProduct.status;
                this.inventory = this.selectedProduct.inventory;
            } else {
                this.productId = '';
                this.name = '';
                this.price = 0;
                this.category = 0;
                this.images = null;
                this.stripeProductId = '';
                this.stripePriceId = '';
                this.description = '';
                this.status = null;
            }
            this.refreshPriceData();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        refreshPriceData() {
            this.loading = false;
            this.productPriceModel = this.selectedProduct.priceModel ? this.selectedProduct.priceModel : 0;

            if (this.editing) {
                this.priceTiers = this.selectedProduct.prices.map(price => {
                    return {
                        price: price.price / 100,
                        fromUnits: price.fromUnits,
                        toUnits: price.toUnits,
                    };
                });
            }
            if (this.priceTiers.length > 0) {
                this.priceTiers[0].fromUnits = 1;
            }
        },
        openPriceUpdateModal() {
            this.priceUpdateModal = true;
        },
        newStatus(index) {
            this.status = index;
        },
        removeTier(i) {
            if (this.priceTiers.length > 1) {
                this.priceTiers.splice(i, 1);
            } else {
                this.showSnackbar({ message: 'At least one tier is required' });
            }
        },
        updateNextToValue(i) {
            if (i != this.priceTiers.length) {
                this.priceTiers[i + 1].fromUnits = this.priceTiers[i].toUnits + 1;
            }
        },
        addTier(i) {
            let lastTier = this.priceTiers[i];
            if (lastTier.toUnits == null) {
                lastTier.toUnits = lastTier.fromUnits + 1;
            }
            const newFrom = parseInt(lastTier.toUnits) + 1;
            this.priceTiers.push({ price: 0, fromUnits: newFrom, toUnits: null });
        },
        sendImage() {
            const fd = new FormData();
            fd.append('image', this.selectedFile, this.selectedFile.name);
            this.axios
                .post(process.env.VUE_APP_API + `/products/image-test`, fd, {
                    onUploadProgress: uploadEvent => {
                        console.log(
                            'Upload Progress:' + Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + '%',
                        );
                    },
                })
                .then(response => {})
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        fileChange(e) {
            this.selectedFile = e.target.files[0];
        },
        updatePrice() {
            if (this.priceTiers.some(tier => parseInt(tier.price) == 0)) {
                this.showSnackbar({ message: 'Price cannot be $0.00' });
                return;
            }
            if (this.priceTiers.length < 2 && this.productPriceModel == 1) {
                this.showSnackbar({ message: 'Volume pricing requires multiple tiers' });
                return;
            }
            const transformedPrices = this.priceTiers.map(tier => {
                return {
                    ...tier,
                    price: tier.price * 100,
                };
            });
            this.loading = true;
            const data = {
                ...this.selectedProduct,
                priceModel: this.productPriceModel,
                price: transformedPrices[0].price,
                prices: transformedPrices,
            };
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .put(process.env.VUE_APP_API + `/Products/update-pricing/${this.selectedProduct.id}`, data)
                    .then(response => {
                        this.showSnackbar({ message: `Updated ${this.selectedProduct.name}` });
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error updating product' });
                    })
                    .finally(() => {
                        this.dialog = false;
                        this.loading = false;
                    });
            });
        },
        updateProduct() {
            if (this.formIsValid) {
                let data = {
                    name: this.name,
                    category: this.category,
                    description: this.description,
                    supplierId: this.supplierId,
                    status: this.status,
                    inventory: parseInt(this.inventory),
                };
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .put(process.env.VUE_APP_API + `/Products/${this.selectedProduct.id}`, data)
                        .then(response => {
                            this.showSnackbar({ message: `Updated ${this.selectedProduct.name}` });
                            this.dialog = false;
                            this.$emit('refresh');
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error updating product' });
                            this.dialog = false;
                        });
                });
            }
        },

        createProduct() {
            if (this.formIsValid) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    if (this.priceTiers.some(tier => parseInt(tier.price) == 0)) {
                        this.showSnackbar({ message: 'Price cannot be $0.00' });
                        return;
                    }
                    if (this.priceTiers.length < 2 && this.productPriceModel == 1) {
                        this.showSnackbar({ message: 'Volume pricing requires multiple tiers' });
                        return;
                    }
                    const transformedPrices = this.priceTiers.map(tier => {
                        return {
                            ...tier,
                            price: tier.price * 100,
                        };
                    });

                    this.loading = true;
                    let data = {
                        supplierId: parseInt(this.$route.params.supplier_id),
                        name: this.name,
                        price: transformedPrices[0].price,
                        category: this.category,
                        stripeProductId: this.stripeProductId,
                        description: this.description,
                        createDate: moment(Date.now()).toISOString(),
                        inventory: this.inventory,
                        priceModel: this.productPriceModel,
                        prices: transformedPrices,
                    };

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .post(process.env.VUE_APP_API + '/Products', data)
                        .then(response => {
                            this.showSnackbar({ message: 'Added product' });

                            this.$emit('refresh');
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error adding product' });
                        })
                        .finally(() => {
                            this.dialog = false;
                            this.loading = false;
                        });
                });
            } else {
                this.showSnackbar({ message: 'Please complete the required fields before submitting' });
            }
        },
        cancelForm() {
            this.productId = '';
            this.name = '';
            this.price = null;
            this.category = 0;
            this.images = '';
            this.stripeProductId = '';
            this.description = '';
            this.dialog = false;
        },
    },
};
</script>

<style></style>

<template>
    <div class="elevation-3 p-4 bg-white rounded">
        <v-card-title>
            <font-awesome-icon icon="fa-regular fa-box-archive" style="margin-right: 0.5rem" />
            <h4 class="m-0">Services</h4>

            <v-spacer></v-spacer>
            <v-text-field class="search-bar" v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
        </v-card-title>

        <v-data-table
            :loading="loading"
            loading-text="Getting Services..."
            :headers="headers"
            :items="services"
            class="services-table"
            :style="{ paddingTop: '8px' }"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
                showFirstLastPage: true,
            }"
            :server-items-length="totalServices"
            :options.sync="options"
            @click:row="editService"
            :must-sort="true"
        >
            <!-- <template v-slot:top>
                <v-text-field
                    class="search-bar"
                    v-model="search"
                    label="Search"
                    append-icon="mdi-magnify"
                ></v-text-field>
            </template> -->

            <!--start views filter -->

            <template v-if="!$auth.role.includes('FuneralHome')" v-slot:header.views="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list>
                        <v-select class="p-3" v-model="selectedComparison" :items="amountComparisons"></v-select>
                        <v-text-field
                            v-model="selectedAmount"
                            class="ml-5"
                            style="width: 100px"
                            label="Viewers:"
                        ></v-text-field>
                    </v-list>
                </v-menu>
            </template>

            <!--end views filter -->

            <!--start status filter -->

            <template v-if="!$auth.role.includes('FuneralHome')" v-slot:header.status="{ header }">
                <v-menu :close-on-content-click="false" :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <custom-tooltip :tooltipProps="{ top: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} ({{ selectedStatuses.length }})<font-awesome-icon
                                        icon="fa-solid fa-chevron-down"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <v-list-item v-for="(item, index) in serviceStates" :key="index" style="height: 20px">
                            <v-checkbox v-model="selectedStatuses" :label="item.definition" :value="index"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <!--end status filter -->

            <template v-slot:item.name="{ item }">
                <span>
                    {{ item.name }}
                    <img
                        v-if="item.passareCaseId"
                        src="@/assets/images/passare-2-icon-cropped.png"
                        class="passare-logo-servicetable"
                    />
                </span>
                <span v-if="item.test" class="text-callout ml-3" style="line-height: 1.3rem">TEST</span>
            </template>

            <template v-slot:item.nextevent="{ item }">
                <span v-if="item.nextEventDate">
                    <v-chip
                        dark
                        :color="item.status == 5 ? 'purple' : 'orange'"
                        v-if="item.nextEventDate === '0001-01-01T00:00:00'"
                    >
                        {{ item.status == 5 ? 'Tribute Video' : 'Incomplete' }}
                    </v-chip>
                    <div v-else style="display: flex; flex-direction: column; justify-content: center">
                        <strong
                            ><p class="m-0">
                                {{ $moment.utc(item.nextEventDate).local().format('MM/DD/YYYY') }}
                            </p></strong
                        >
                        <p class="m-0">{{ $moment.utc(item.nextEventDate).local().format('h:mm a') }}</p>
                    </div>
                </span>
            </template>

            <template v-slot:item.status="{ item }">
                <div class="chip-container">
                    <span
                        :style="{ '--background-color': serviceStates[item.status].color }"
                        class="chip"
                        :class="{ 'chip--live': item.status === 2 }"
                    >
                        {{ serviceStates[item.status].definition }}
                    </span>
                </div>
            </template>
            <template v-slot:item.enum="{ item }">
                {{ item.status }}
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="action-list text-center" style="display: flex; gap: 12px; justify-content: center">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-solid fa-play"
                                    style="font-size: 1rem"
                                    @click.stop="playService(item.slug, item.private)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Play video</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-pencil"
                                    style="font-size: 1rem"
                                    @click="editService(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit Service</span>
                        </template>
                    </custom-tooltip>

                    <!-- <v-tooltip bottom v-if="$auth.role.includes('SuperAdmin')">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <font-awesome-icon
                                    icon="fa-solid fa-shop"
                                    style="font-size: 1rem"
                                    @click.stop
                                    @click.prevent="goToEComm(item.slug)"
                                />
                            </span>
                        </template>

                        <span>Keepsakes</span>
                    </v-tooltip> -->

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-solid fa-chart-simple"
                                    style="font-size: 1rem"
                                    :class="item.test ? 'disabled' : ''"
                                    @click.stop="viewAnalytics(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span v-if="item.test">Analytics not available for test service</span>
                            <span v-else>View Analylics</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-trash-can"
                                    style="font-size: 1rem"
                                    @click.stop="openDeleteModal(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>
        <v-dialog v-model="deleteModal.show" :retain-focus="false" max-width="290">
            <v-card>
                <v-card-title class="headline"> Delete Service? </v-card-title>
                <v-card-text>{{ deleteModal.message }}</v-card-text>
                <v-card-text v-if="deleteSuperAdminInfo">
                    <strong><p style="margin: 0">Service Info:</p></strong>
                    <p style="margin: 0">Revenue: {{ deleteSuperAdminInfo.amount }}</p>
                    <p style="margin: 0">Views: {{ deleteSuperAdminInfo.views }}</p>
                    <p style="margin: 0">Billing Events: {{ deleteSuperAdminInfo.billingEvents }}</p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" text @click="deleteModal.show = false"> Cancel </v-btn>
                    <v-btn color="red darken-1" text @click="deleteService(deleteModal.serviceId)"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <passare-button v-if="!loading" @sync-successful="getServices"></passare-button> -->
    </div>
</template>

<script>
import PassareButton from '@/components/Services/PassareButton.vue';
import { mapActions } from 'vuex';
import { serviceStates } from '@/constants.js';
import debounce from '@/utilities/debounce.js';
import { getLocalSettings, setLocalSettings } from '@/utilities/general';
import Calendar from '@/components/ui/Calendar.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

// TODO Make Live status update automatically?

export default {
    name: 'ServicesTable',
    components: {
        Calendar,
        CustomTooltip,
        PassareButton,
    },
    data() {
        return {
            statusParamString: '',
            selectedAmount: null,
            loading: false,
            token: '',
            search: '',
            headers: [],
            services: [],
            totalServices: null,
            deleteModal: {
                show: false,
                message: '',
                serviceId: null,
            },
            serviceStates,
            options: {
                itemsPerPage: 25,
            },
            tableLoading: false,
            deleteSuperAdminInfo: null,
            selectedStatuses: [0, 1, 2, 3, 4, 5, 6, 7],
            amountComparisons: [
                { text: '--', value: '' },
                { text: 'is greater than', value: 1 },
                { text: 'is less than', value: 2 },
            ],
            selectedComparison: '',
        };
    },
    watch: {
        options: {
            handler() {
                if (!this.tableLoading) {
                    setLocalSettings('serviceTable', {
                        search: this.search,
                        page: this.options.page,
                        pageSize: this.options.itemsPerPage,
                    });
                }

                if (this.token && this.axiosInstance) {
                    this.debouncedGetServices();
                }
            },
            deep: true,
        },
        search() {
            this.debouncedGetServices();

            if (!this.tableLoading) {
                setLocalSettings('serviceTable', {
                    search: this.search,
                    page: 1,
                    pageSize: this.options.itemsPerPage,
                });
                this.options = {
                    ...this.options,
                    page: 1,
                };
            }
        },
        selectedStatuses() {
            if (this.selectedStatuses.length === 0) {
                this.services = [];
            } else {
                let string = '';
                this.selectedStatuses.forEach(event => (string += `&state=${event}`));
                this.statusParamString = string;
                this.getServices();
            }
        },
        selectedAmount() {
            this.options = {
                ...this.options,
                page: 1,
            };
            this.getServices();
        },
        selectedComparison() {
            if (!this.selectedComparison) {
                this.selectedAmount = '';
            }

            this.options = {
                ...this.options,
                page: 1,
            };
            this.getServices();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        goToEComm(slug) {
            if (this.$auth.role.includes('SuperAdmin')) {
                window.open(`shop/${slug}`);
            }
        },
        playService(slug, privateService) {
            if (privateService) {
                window.open(`${process.env.VUE_APP_VIDEO_PRIVATE}/memoryshare/video/${slug}`, '_blank');
            } else {
                window.open(`${process.env.VUE_APP_VIDEO}/memoryshare/video/${slug}`, '_blank');
            }
        },
        editService(item) {
            this.$router.push({ path: `/services/${item.slug}` });
        },

        viewAnalytics(item) {
            if (item.test) {
                this.showSnackbar({ message: 'Analytics unavailable' });
                return;
            }

            this.$router.push({ path: `/analytics/${item.slug}` });
        },
        openDeleteModal(item) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axiosInstance.get(`/services/safetodelete/${item.id}`).then(response => {
                    this.deleteSuperAdminInfo = response.data;
                    this.deleteModal.message = `Are you sure you want to delete the service for ${item.name}?`;
                    this.deleteModal.serviceId = item.id;
                    this.deleteModal.show = true;
                });
            } else {
                this.deleteModal.message = `Are you sure you want to delete the service for ${item.name}?`;
                this.deleteModal.serviceId = item.id;
                this.deleteModal.show = true;
            }
        },
        deleteService(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/Services/${id}`)
                .then(result => {
                    this.deleteModal.show = false;
                    const { name } = this.services.find(service => service.id === id);
                    const message = `Service for ${name} was deleted`;
                    this.showSnackbar({ message });
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                })
                .finally(() => {
                    this.getServices();
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'name':
                    return 'FirstName';
                case 'id':
                    return 'Id';
                case 'views':
                    return 'TotalViews';
                case 'status':
                    return 'ServiceState';
                case 'homeName':
                    return 'FuneralHomeName';
                case 'created':
                    return 'CreatedDate';
                case 'private':
                    return 'private';
                case 'slug':
                    return 'slug';
                case 'nextevent':
                    return 'NextEventDate';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        handleGoToEndOfTable() {},
        getQueryParams() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            const params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search || null,
                symbol: this.selectedComparison || null,
                views: this.selectedAmount || null,
            };
            return params;
        },
        async getServices() {
            this.loading = true;

            // Query Switch
            let query = '/services';

            if (this.$auth.role.includes('SuperAdmin')) query = '/services/get-admin?' + this.statusParamString;
            if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager'))
                query = '/services/owner?' + this.statusParamString;

            const params = this.getQueryParams();

            if (params?.sortBy === null) {
                delete params.sortby;
            }

            try {
                const response = await this.axiosInstance.get(query, { params });

                if (this.$auth.role.includes('SuperAdmin')) {
                    this.headers = [
                        { text: 'Home', value: 'homeName' },
                        { text: 'ID', value: 'id' },
                        { text: 'Name', value: 'name' },
                        { text: 'Views', value: 'views', width: '100px' },
                        { text: 'Status', value: 'status', width: '10%' },
                        { text: 'Next Event', value: 'nextevent', sortable: true },
                        { text: 'Actions', value: 'actions', sortable: false, width: '25%', align: 'center' },
                    ];

                    this.totalServices = response.data.total;
                    this.services = response.data.services.map(service => {
                        return {
                            id: service.id,
                            homeName: service.funeralHomeName,
                            created: service.createdDate,
                            createdBy: service.creatorEmail,
                            private: service.private,
                            name: `${service.firstName} ${service.lastName}`,
                            views: service.totalViews,
                            status: service.serviceState,
                            slug: service.slug,
                            test: service.test,
                            home_id: service.funeralHomeId,
                            nextEventDate: service.nextEventDate,
                            passareCaseId: service.passareCaseUuid,
                        };
                    });
                } else if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                    this.headers = [
                        { text: 'Funeral Home', value: 'funeralHomeName' },
                        { text: 'Name', value: 'name' },
                        { text: 'Views', value: 'views' },
                        { text: 'Status', value: 'status' },
                        { text: 'Next Event', value: 'nextevent', sortable: true },
                        { text: 'Actions', value: 'actions', sortable: false, width: '25%', align: 'center' },
                    ];

                    this.totalServices = response.data.total;
                    this.services = response.data.services.map(service => {
                        return {
                            id: service.id,
                            funeralHomeName: service.funeralHomeName,
                            name: `${service.firstName} ${service.lastName}`,
                            views: service.totalViews,
                            status: service.serviceState,
                            slug: service.slug,
                            test: service.test,
                            nextEventDate: service.nextEventDate,
                            passareCaseId: service.passareCaseUuid,
                        };
                    });
                } else {
                    this.headers = [
                        { text: 'Name', value: 'name' },
                        { text: 'Views', value: 'views' },
                        { text: 'Status', value: 'status' },
                        { text: 'Next Event', value: 'nextevent', sortable: true },
                        { text: 'Actions', value: 'actions', sortable: false, width: '25%', align: 'center' },
                    ];

                    this.totalServices = response.data.total;
                    this.services = response.data.services.map(service => {
                        return {
                            id: service.id,
                            name: `${service.firstName} ${service.lastName}`,
                            views: service.totalViews,
                            status: service.serviceState,
                            slug: service.slug,
                            test: service.test,
                            nextEventDate: service.nextEventDate,
                            passareCaseId: service.passareCaseUuid,
                        };
                    });
                }
            } catch (error) {
                console.error(error);
                this.showSnackbar({
                    message:
                        'There was an error retrieving your data. Please contact support or try again in a few minutes.',
                });
            }
            this.loading = false;
        },
    },

    sockets: {
        NotifyLive(data) {
            this.getServices();
        },
    },
    mounted: async function () {
        // Get local settings
        const settings = await getLocalSettings();
        this.search = settings.serviceTable.search || '';
        this.options = {
            itemsPerPage: 25,
            page: settings.serviceTable.page || 1,
        };

        this.tableLoading = false;
    },
    async created() {
        this.debouncedGetServices = debounce(this.getServices, 500);
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getServices();
    },
};
</script>

<style>
.search-bar {
    margin-left: auto;
    width: 30%;
}

.chip-container {
    position: relative;
    z-index: 1;
}

.chip {
    --border-radius: 15px;
    background: var(--background-color);
    border-radius: var(--border-radius);
    color: white;
    padding: 0.25em 0.7em;
    white-space: nowrap;
}

.chip--live {
    position: relative;
}

.chip--live::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: var(--border-radius);
    background-color: rgb(255, 82, 82);
    animation: 2s infinite pulse;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    70% {
        transform: scale(1.4);
        opacity: 0;
    }

    100% {
        transform: scale(0.95);
        opacity: 0;
    }
}

.text-callout {
    background: #2275d7;
    color: #fff;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}

.text-callout-incomplete {
    background: #f6ff0c;
    color: rgb(31, 31, 31);
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}
.passare-logo-servicetable {
    width: 30px;
    margin-left: 15px;
}
</style>

<template>
    <div :class="['event-action-container', event.hidden ? 'hidden-event-action-container' : '']">
        <div v-if="event.hidden" class="text-left">
            <v-chip label outlined color="#fb8c00" dark>Hidden</v-chip>
        </div>
        <nav class="event-action-tab-container">
            <li class="menu-entry" :class="nonLiveIndex == 0 && 'active'" @click="nonLiveIndex = 0">Recording</li>
            <li class="menu-entry" :class="nonLiveIndex == 1 && 'active'" @click="nonLiveIndex = 1">Health</li>
            <li class="menu-entry" :class="nonLiveIndex == 2 && 'active'" @click="nonLiveIndex = 2">Upload</li>
        </nav>

        <div class="event-action-tab-content">
            <!-- START: Default Tab -->
            <section v-if="nonLiveIndex === 0" class="default-button-container">
                <div v-if="vods.length > 0">
                    <v-card>
                        <v-window continuous v-model="model">
                            <v-window-item eager v-for="(item, index) in vods" :key="index">
                                <event-viewer
                                    @ended="nextTrack"
                                    @loadedmeta="getMaxDuration"
                                    ref="myVods"
                                    :src="vods[index].playbackUrl"
                                    :event="event"
                                    :poster="
                                        event.videoThumbnail && service.displayThumbnail
                                            ? event.videoThumbnail
                                            : blankPosterUrl
                                    "
                                ></event-viewer>
                            </v-window-item>
                        </v-window>
                        <v-card-actions v-if="vods.length > 1" class="justify-space-between">
                            <v-btn text @click="model--">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-item-group v-model="model" class="text-center mt-1" mandatory z-index="1">
                                <v-item v-for="(item, index) in vods" :key="index" v-slot="{ active, toggle }">
                                    <v-btn :input-value="active" icon @click="toggle">
                                        <v-icon>mdi-record</v-icon>
                                    </v-btn>
                                </v-item>
                            </v-item-group>

                            <v-btn text @click="model++">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <div v-if="isEditing">
                        <strong
                            ><p style="margin: 1rem 0; text-align: center; font-size: 1.5rem">Editing Video</p></strong
                        >
                    </div>

                    <v-range-slider
                        class="mt-6"
                        thumb-size="20"
                        :thumb-label="vods.length > 1 ? 'always' : false"
                        v-if="isEditing"
                        v-model="range"
                        :max="newTotal"
                        min="0"
                    >
                        <template v-slot:prepend>
                            <div style="display: flex">
                                <v-btn tile x-small @click="moveStart(-1)">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                                </v-btn>
                                <v-btn tile x-small @click="moveStart(1)">
                                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:append>
                            <div style="display: flex">
                                <v-btn tile x-small @click="moveEnd(-1)">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                                </v-btn>
                                <v-btn tile x-small @click="moveEnd(1)">
                                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                                </v-btn>
                            </div>
                        </template>
                        <template v-if="vods.length > 1" v-slot:thumb-label="{ value }">
                            {{ checkPosition(value - 1) }}
                        </template>
                    </v-range-slider>

                    <div v-if="isEditing" style="display: flex; justify-content: space-between">
                        <p style="margin: 0; text-align: left">
                            <strong>Start</strong>
                        </p>

                        <p style="margin: 0; text-align: left">
                            <strong>End</strong>
                        </p>
                    </div>

                    <div v-if="isEditing" style="display: flex; justify-content: space-between">
                        <div>
                            <p v-if="vods.length > 1" style="margin: 0; text-align: left">
                                Clip: {{ checkPosition(range[0]) }}
                            </p>

                            <p>{{ getCurrentClipTime(checkPosition(range[0]), range[0]) }}</p>
                        </div>

                        <div>
                            <p v-if="vods.length > 1" style="margin: 0; text-align: left">
                                Clip: {{ checkPosition(range[1] - 1) }}
                            </p>
                            <p>{{ getCurrentClipTime(checkPosition(range[1]), range[1]) }}</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No video is available for this event.</p>
                </div>

                <v-btn
                    v-if="!isEditing && hasVideo"
                    style="width: 100%; margin: 1rem 0"
                    dark
                    color="green"
                    @click="startEditing"
                    :disabled="disableSaveBtn"
                    :loading="disableSaveBtn"
                >
                    <font-awesome-icon class="mr-2" icon="fa-solid fa-scissors"></font-awesome-icon>
                    Edit</v-btn
                >

                <div v-if="isEditing" style="padding: 24px">
                    <div style="display: flex; justify-content: flex-end"></div>
                    <div style="display: flex; justify-content: space-between; width: 100%">
                        <v-btn color="surface" @click="handleCancelEdit">Cancel</v-btn>

                        <!--  dark + disabled props cause btn to disappear -->
                        <v-btn
                            color="success"
                            :dark="!disableSaveBtn"
                            :loading="disableSaveBtn"
                            :disabled="disableSaveBtn"
                            @click="handleSaveEdit"
                            >Save Video</v-btn
                        >
                    </div>
                </div>
            </section>
            <!-- END: Default Tab -->

            <!-- START: Health Tab -->
            <section v-if="nonLiveIndex === 1">
                <wowza-event-chart
                    v-if="cloudStreamingProvider === 0"
                    ref="wowzaChart"
                    :live="event.eventStatus == 2"
                    :event="event"
                />
                <div class="text-center" v-if="cloudStreamingProvider !== 0">Health Chart Not Available</div>
            </section>
            <!-- END: Health Tab -->

            <section v-if="nonLiveIndex === 2" class="">
                <div class="end-stream-container">
                    <h3 class="text-center">Upload a high quality version of your stream.</h3>
                    <v-btn @click="handleButtonClick" class="mb-2" color="secondary"> Add Video Files </v-btn>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import WowzaEventChart from '@/components/charts/WowzaEventChart.vue';
import EventViewer from '@/components/videojs/EventViewer.vue';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model: 0,
            vods: [],
            nonLiveIndex: 0,
            wowzaChart: '',
            isEditing: false,
            range: [0, 100],
            videoPlayerReference: null,
            videoLength: 0,
            disableSaveBtn: false,
            newTotal: 0,
            loadingEdit: true,
            cloudStreamingProvider: null,
            blankPosterUrl: process.env.VUE_APP_AZURE_BASE_DOMAIN + '/front-end-assets/video_poster_placeholder.png',
        };
    },
    computed: {
        hasVideo() {
            if (this.vods.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        getStreamInfo(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/live/${id}`)
                    .then(response => {
                        this.cloudStreamingProvider = response.data.cloudStreamingProvider;
                        if (this.cloudStreamingProvider == 1) {
                            this.vods = this.event.vod;
                            console.log(this.vods, 'vods');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        if (this.cloudStreamingProvider === 0) this.getVods();
                    });
            });
        },
        pauseAll() {
            for (let i = 0; i < this.$refs.myVods.length; i++) {
                this.$refs.myVods[i].player.pause();
            }
        },
        startEditing() {
            if (!this.event.id) {
                this.showSnackbar({ message: 'Error retreiving event data', color: 'error' });
                return;
            }

            this.$router.push({ path: `/services/upload/${this.event.id}?uploaderType=edit` });
            // console.log('starting inline edit', this.vods);

            // this.isEditing = true;
            // for (let i = 0; i < this.$refs.myVods.length; i++) {
            //     this.$refs.myVods[i].player.bigPlayButton.el().style.display = 'none';
            //     this.$refs.myVods[i].player.play();
            //     this.$refs.myVods[i].player.pause();
            //     this.$refs.myVods[i].player.controls(true);
            // }
            // setTimeout(() => {
            //     this.loadingEdit = false;
            // }, 2000);
        },
        getCurrentClipTime(index, value) {
            let totalDuration = 0;
            let inverse = 0;
            let newTime = 0;
            for (let x = 0; x < index; x++) {
                totalDuration += this.$refs.myVods[x].player.cache_.duration;
                inverse = totalDuration - value;
                newTime = this.$refs.myVods[x].player.cache_.duration - inverse;
            }
            return this.secondsToTimestamp(newTime);
        },
        checkPosition(value) {
            let duration = 0;
            let time = 0;

            if (this.$refs.myVods) {
                // display appropriate video based on selected time and video durations

                for (let i = 0; i < this.$refs.myVods.length; i++) {
                    duration += this.$refs.myVods[i].$refs.videoPlayer.duration;

                    if (duration > value) {
                        // if combined duration is greater than selected time, display video
                        time = duration - this.$refs.myVods[i].$refs.videoPlayer.duration;

                        //set current time for player
                        this.$refs.myVods[i].$refs.videoPlayer.currentTime = value - time;
                        let track = i + 1;
                        return track;
                        break;
                    }
                }
            }
        },
        nextTrack() {
            //display and play next video if available
            if (this.model < this.$refs.myVods.length) {
                this.model++;
                if (this.$refs.myVods[this.model]) {
                    this.$refs.myVods[this.model].player.play();
                }
            }
        },
        checkForIndex(value) {
            let duration = 0;
            let time = 0;

            if (this.$refs.myVods) {
                // display appropriate video based on selected time and video durations

                for (let i = 0; i < this.$refs.myVods.length; i++) {
                    duration += this.$refs.myVods[i].$refs.videoPlayer.duration;

                    if (duration > value) {
                        // if combined duration is greater than selected time, display video
                        this.model = i;
                        time = duration - this.$refs.myVods[i].$refs.videoPlayer.duration;

                        //set current time for player
                        this.$refs.myVods[i].$refs.videoPlayer.currentTime = value - time;
                        break;
                    }
                }
            }
        },
        nextPage(value) {
            this.model = value;
        },
        getMaxDuration() {
            let total = 0;
            for (let i = 0; i < this.$refs.myVods.length; i++) {
                total += this.$refs.myVods[i].$refs.videoPlayer.duration;
            }
            this.range[1] = total;

            this.newTotal = total;
        },
        handleButtonClick: function () {
            this.$router.push({ path: `/services/upload/${this.event.id}` });
        },
        handleSaveEdit() {
            this.disableSaveBtn = true;
            this.isEditing = false;
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                const payload = {
                    start: this.range[0],
                    stop: this.range[1],
                };

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/Events/create-render/${this.event.id}`, payload)
                    .then(response => {
                        this.isEditing = false;
                        this.$emit('refresh');
                        this.disableSaveBtn = false;
                    })
                    .catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data.Message,
                        });
                        this.disableSaveBtn = false;
                    });
            });
        },
        moveStart: function (amount) {
            this.range = [this.range[0] + amount, this.range[1]];
        },
        moveEnd: function (amount) {
            this.range = [this.range[0], this.range[1] + amount];
        },
        getVods() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/live/get-vod/${this.event.id}`)
                    .then(response => {
                        this.vods = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },

        handleCancelEdit() {
            this.isEditing = false;
            for (let i = 0; i < this.$refs.myVods.length; i++) {
                this.$refs.myVods[i].player.bigPlayButton.el().style.display = 'block';
            }
        },
        secondsToTimestamp(seconds) {
            // multiply by 1000 because Date() requires miliseconds
            var date = new Date(seconds * 1000);
            var hh = date.getUTCHours();
            var mm = date.getUTCMinutes();
            var ss = date.getSeconds();
            // If you were building a timestamp instead of a duration, you would uncomment the following line to get 12-hour (not 24) time
            // if (hh > 12) {hh = hh % 12;}
            // These lines ensure you have two-digits

            if (mm < 10) {
                mm = '0' + mm;
            }
            if (ss < 10) {
                ss = '0' + ss;
            }
            // This formats your string to HH:MM:SS
            return hh + ':' + mm + ':' + ss;
        },
    },
    watch: {
        range: function (newValue, oldValue) {
            // If index 0 didn't change then index 1 was what changed
            if (newValue[0] === oldValue[0]) {
                if (this.loadingEdit == false) {
                    this.checkForIndex(newValue[1]);
                }
            } else {
                this.checkForIndex(newValue[0]);
            }
        },
        model() {
            this.pauseAll();
        },
    },
    mounted() {
        this.getStreamInfo(this.event.liveStreamId);
    },
    components: {
        WowzaEventChart,
        EventViewer,
    },
};
</script>

<style lang="scss" scoped>
.event-action-tab-container {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;

    .menu-entry {
        list-style: none;
        padding: 0.5rem 1rem;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .menu-entry.active {
        border-bottom: 4px solid rgb(78, 184, 226);
    }
}

.default-button-container {
    text-align: center;
}
.end-stream-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
}

.text-center {
    text-align: center;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div>
            <!-- <side-nav></side-nav>
        <div v-bind:class="[{ 'menu-showing': this.$store.state.menuShowing }, 'dash-container']"> -->
            <v-card class="pt-5 mb-6">
                <div class="tile form">
                    <div class="header">
                        <h3 class="title">
                            <font-awesome-icon icon="fa-regular gear" style="font-size: 1rem" />
                            Settings
                        </h3>
                    </div>

                    <div>
                        <!-- Tabs -->
                        <v-tabs v-model="tab" color="#0d5259">
                            <v-tab href="#details">Details</v-tab>
                            <v-tab href="#settings">Settings</v-tab>
                            <v-tab href="#subscription">Subscription</v-tab>
                            <v-tab href="#manageUsers">Manage Users</v-tab>
                        </v-tabs>
                        <!-- tab items -->
                        <v-tabs-items :value="tab">
                            <!-- Details Tab -->
                            <v-tab-item value="details">
                                <div class="tab-padding mt-10">
                                    <v-form class="form-container" ref="form" lazy-validation>
                                        <v-text-field
                                            v-model="name"
                                            :rules="[v => !!v || 'Name is required']"
                                            label="Name"
                                            required
                                        ></v-text-field>
                                        <v-text-field v-model="address1" label="Address 1" required></v-text-field>
                                        <v-text-field v-model="address2" label="Address 2" required></v-text-field>

                                        <v-row class="mt-0">
                                            <v-col>
                                                <v-text-field v-model="city" label="City" required></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="state" label="State" required></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="zipCode" label="Zip" required></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-text-field
                                            v-model="email"
                                            :rules="[v => !!v || 'Email is required']"
                                            label="Email"
                                            type="email"
                                            required
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="primaryContactEmail"
                                            :rules="[v => !!v || 'Email is required']"
                                            label="Primary Contact Email"
                                            type="email"
                                            required
                                        ></v-text-field>

                                        <v-text-field
                                            v-model="primaryContact"
                                            :rules="[v => !!v || 'Primary Contact is required']"
                                            label="Primary Contact Name"
                                            required
                                        ></v-text-field>

                                        <v-text-field
                                            v-model="primaryContactPhoneNumber"
                                            v-mask="'###.###.####'"
                                            label="Primary Contact Phone"
                                            type="phone"
                                            required
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="website"
                                            label="Website Link"
                                            :rules="[v => !!v || 'Valid link is required']"
                                        ></v-text-field>

                                        <embed-modal v-if="embedHtml" :slug="embedHtml" />
                                        <div class="text-right mt-10 mb-6">
                                            <button
                                                @click.prevent="saveOwner($data)"
                                                class="btn btn-primary ml-3 save-btn"
                                            >
                                                Save Owner
                                            </button>
                                        </div>
                                    </v-form>
                                </div>
                            </v-tab-item>

                            <!-- settings -->
                            <v-tab-item value="settings">
                                <div class="tab-padding mt-10">
                                    <owner-settings
                                        modal="false"
                                        :id="Number(this.$route.params.id)"
                                        :token="token"
                                        ref="ownersettings"
                                        @message="setSnackBar"
                                        :editing="true"
                                    />
                                </div>
                            </v-tab-item>

                            <!-- Subscription Tab -->
                            <v-tab-item value="subscription">
                                <div class="tab-padding mt-10">
                                    <OwnerSubscriptionPanel :ownerId="Number(this.$route.params.id)" />
                                </div>
                            </v-tab-item>

                            <!-- Manage Users Tab -->
                            <v-tab-item value="manageUsers">
                                <div class="tab-padding mt-10"></div>
                                <owner-user-management-panel :token="token" :id="Number(this.$route.params.id)" />
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                    <Spinner v-if="loading"></Spinner>
                </div>
            </v-card>
        </div>
        <!-- </div> -->
    </AppLayout>
</template>

<script>
import OwnerSettings from '@/components/Settings/OwnerSettings';
import IntegrationSettings from '@/components/Settings/IntegrationSettings';
import Spinner from '@/components/ui/Spinner';
import { mapActions } from 'vuex';
import EmbedModal from '@/components/Modals/EmbedModal';
import LineChart from '@/components/charts/LineChart';
import OwnerSubscriptionPanel from '@/components/Panels/OwnerSubscriptionPanel';
import SuperAdminDetails from '@/components/Misc/SuperAdminDetails.vue';
import OwnerBillingPanel from '@/components/Panels/OwnerBillingPanel.vue';
import OwnerUserManagementPanel from '@/components/Panels/OwnerUserManagementPanel.vue';

export default {
    metaInfo: {
        title: 'Manage Owners',
    },
    components: {
        OwnerSubscriptionPanel,
        SuperAdminDetails,
        OwnerBillingPanel,
        Spinner,

        EmbedModal,
        LineChart,
        OwnerSettings,
        IntegrationSettings,
        OwnerUserManagementPanel,
    },
    data() {
        return {
            embedHtml: null,
            loading: true,
            editing: false,
            token: '',
            name: '',
            address1: '',
            address2: '',
            city: '',
            message: '',
            state: '',
            zipCode: '',
            email: '',
            owners: [],
            primaryContact: '',
            website: null,
            primaryContactPhoneNumber: '',
            primaryContactEmail: '',
            loading: false,
            snackbar: false,
            message: '',
        };
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
        });

        this.getOwner(this.$route.params.id);
    },
    watch: {
        tab: {
            handler() {
                if (this.tab === 'settings') {
                    setTimeout(() => {
                        this.$refs.ownersettings.getSettings();
                    });
                }

                if (this.tab === 'profitability') {
                    this.setChart();
                }
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),

        getSettings() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${this.id}`)
                    .then(response => {
                        let data = response.data;
                        if (response.data) {
                            this.settingsForm = {
                                // azureVMSize: data.azureVMSize,
                                wowzaServerLocation: data.wowzaServerLocation,
                                websiteProvider: this.websiteProviders[data.websiteProvider],
                                streamingProvider: this.streamingProviders[data.streamingProvider],
                                // displayTutorial: data.displayTutorial,
                                // dvdForSale: data.dvdForSale,
                                // displayCTA: data.displayCTA,
                                transcoded: data.transcoded,
                                whiteLabel: data.whiteLabel,
                                support: data.support,
                                callToActionHeading: data.callToActionHeading,
                                callToActionButtonText: data.callToActionButtonText,
                                callToActionButtonLink: data.callToActionButtonLink,
                                // enablePreview: data.enablePreview,
                                embedCodeId: data.embedCodeId,
                            };
                        }
                    })
                    .catch(error => {
                        this.$emit('message', error.response.data);
                    });
            } else {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${this.id}`)
                    .then(response => {
                        let data = response.data;
                        if (response.data) {
                            this.settingsForm = {
                                // azureVMSize: data.azureVMSize,
                                wowzaServerLocation: data.wowzaServerLocation,
                                websiteProvider: this.websiteProviders[data.websiteProvider],
                                streamingProvider: this.streamingProviders[data.streamingProvider],
                                // displayTutorial: data.displayTutorial,
                                // enablePreview: data.enablePreview,
                                // dvdForSale: data.dvdForSale,
                                // displayCTA: data.displayCTA,
                                transcoded: data.transcoded,
                                whiteLabel: data.whiteLabel,
                                support: data.support,
                                callToActionHeading: data.callToActionHeading,
                                callToActionButtonText: data.callToActionButtonText,
                                callToActionButtonLink: data.callToActionButtonLink,
                                embedCodeId: data.embedCodeId,
                            };
                        }
                    })
                    .catch(error => {});
            }
        },
        setSnackBar(message) {
            this.message = message;
            this.snackbar = true;
        },

        saveOwner(payload) {
            let valid = this.$refs.form.validate();

            if (valid) {
                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    email: this.email,
                    primaryContact: this.primaryContact,
                    primaryContactEmail: this.primaryContactEmail,
                    primaryContactPhoneNumber: this.primaryContactPhoneNumber,
                    website: this.website,
                };

                // temp
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    let query = `${process.env.VUE_APP_API}/Owners/${this.$route.params.id}`;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .put(query, data)
                        .then(response => {
                            this.showSnackbar({ message: `${this.name} successfully updated` });
                            this.$router.push({ name: 'OwnerSettings' });
                        })
                        .catch(error => {
                            this.showSnackbar({ message: 'Error! Please try again' });
                        });
                });
            } else {
                this.showSnackbar({ message: 'Error! Please fix form errors' });
            }
        },

        getOwner(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/Owners/${id}`)
                    .then(response => {
                        this.loading = false;
                        let result = response.data;

                        this.name = result.name;
                        this.address1 = result.address1;
                        this.address2 = result.address2;
                        this.city = result.city;
                        this.state = result.state;
                        this.zipCode = result.zipCode;
                        this.email = result.email;
                        this.primaryContact = result.primaryContact;
                        this.primaryContactEmail = result.primaryContactEmail;
                        this.primaryContactPhoneNumber = result.primaryContactPhoneNumber;
                        this.website = result.website;
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error! Please try again' });
                    });
            });
        },
    },
    computed: {
        title() {
            return this.editing ? `Editing ${this.name}` : 'Create New Owner';
        },
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.row {
    margin-top: 1.5rem;
}

.custom-checkbox {
    margin-top: 1rem;
}

.save-btn {
    width: 40%;
}

.form-group {
    margin-top: 2rem;
}
</style>

<template>
    <div>
        <div class="mt-3" style="border-radius: 4px; background-color: #f8f8f8; padding: 10px">
            <div class="my-3" style="display: flex; flex-direction: column">
                <div style="display: flex; justify-content: space-between">
                    <strong style="font-size: 1.2rem">QR Code</strong>

                    <div style="display: flex">
                        <v-btn color="#ffffff" @click="printQR" small depressed
                            >Print QR <font-awesome-icon class="ml-2" icon="fa-regular fa-print"></font-awesome-icon
                        ></v-btn>
                        <v-btn color="#ffffff" depressed class="ml-2" @click="initUploadInvite" small
                            >Share
                            <font-awesome-icon class="ml-2" icon="fa-regular fa-share" />
                        </v-btn>
                    </div>
                </div>
                <span>Send someone a QR so they can upload photos or videos</span>
            </div>
            <div
                class="p-3"
                style="background-color: white; border-radius: 4px; display: flex; justify-content: center"
            >
                <qrcode-vue
                    v-if="tributeVideo.deeplink"
                    style="width: 105px; height: 105px"
                    :value="tributeVideo.deeplink"
                    size="100"
                    render-as="svg"
                />
            </div>
        </div>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            deeplink: null,
        };
    },
    components: {
        QrcodeVue,
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        printQR() {
            // this.showSnackbar({ message: 'This feature is not available yet' });
            this.$emit('print');
        },
        initUploadInvite() {
            this.$emit('initInvite');
        },
    },
    mounted() {
        if (this.tributeVideo) {
            if (this.tributeVideo.deeplink) {
                this.deeplink = this.tributeVideo.deeplink;
            }
        }
    },
};
</script>
<style lang=""></style>

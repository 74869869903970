import branch from 'branch-sdk';

const Branch = {
    initSession: callback => {
        console.log('session checking');
        branch.init(process.env.VUE_APP_BRANCH_KEY, {}, callback);
    },
    // other methods and variables here
};

export default Branch;

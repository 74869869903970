<template>
    <div v-show="Show">
        <v-btn v-if="$auth.role.includes('FuneralHome')" @click="openBillingPortal()"
            >Update Payment and Billing Address</v-btn
        >

        <!-- <h5 class="mt-12">All Invoices</h5> -->
        <v-dialog v-model="eventDialog.show" max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ eventDialog.title }}</span>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="eventDialog.table.headers"
                        :items="eventDialog.table.events"
                        loading-text="Getting invoice"
                    >
                        <template v-slot:item.event="{ item }">
                            <v-chip :color="eventTypes[item.eventType].color" dark>{{
                                eventTypes[item.eventType].label
                            }}</v-chip>
                        </template>

                        <template v-slot:item.amount="{ item }">
                            <span>{{ item.amount | formatMoney }}</span>
                        </template>

                        <template v-slot:item.date="{ item }">
                            <p class="pt-6 pb-6 mb-0">
                                {{ getLocalDate(item.reportedDate) | moment('l') }}
                            </p>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <invoice-table :apiUrl="'/billing/owner/invoices?ownerId=' + this.ownerId" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { eventTypes } from '@/constants';
import InvoiceTable from '@/components/Tables/InvoiceTable.vue';

export default {
    name: 'SubscriptionPanel',
    props: ['ownerId'],
    components: { InvoiceTable },
    data() {
        return {
            // token: null,
            Show: true,
            eventDialog: {
                show: false,
                title: 'Dialog Title',
                loading: false,
                table: {
                    headers: [
                        { text: 'Date', value: 'date' },
                        { text: 'Event', value: 'event' },
                        { text: 'Amount', value: 'amount', width: '13%' },
                        { text: 'Description', value: 'description' },
                    ],
                    events: [],
                },
            },
            currentInvoiceTable: {
                events: [],
                headers: [
                    { text: 'Date', value: 'date' },
                    { text: 'Event', value: 'event' },
                    { text: 'Amount', value: 'amount', width: '13%' },
                    { text: 'Description', value: 'description', width: '50%' },
                ],
                loading: false,
            },
            allInvoicesTable: {
                invoices: [],
                headers: [
                    { text: 'Month', value: 'month' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Status', value: 'status' },
                    { text: 'Billing Period', value: 'billingPeriod' },
                    { text: 'Actions', value: 'actions' },
                ],
                loading: false,
            },
            deleteDialog: {
                eventId: '',
                show: false,
            },
            eventTypes,
            subStart: null,
            subEnd: null,
            loading: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleClick(row, e, third) {
            this.showEvents(null, row.actions.events, row.month);
        },
        editDescription(id) {
            const eventToEdit = this.currentInvoiceTable.events.find(event => event.id === id);
            eventToEdit.prevDescription = eventToEdit.description;
            eventToEdit.isEditing = true;
        },
        cancelEdit(id) {
            const eventToEdit = this.currentInvoiceTable.events.find(event => event.id === id);
            eventToEdit.description = eventToEdit.prevDescription;
            eventToEdit.isEditing = false;
        },
        saveDescription(id) {
            const eventToUpdate = this.currentInvoiceTable.events.find(event => event.id === id);
            if (eventToUpdate.description === eventToUpdate.prevDescription) {
                eventToUpdate.isEditing = false;
                return;
            }

            const payload = {
                ...eventToUpdate,
            };
            // Delete client side specific properties
            delete payload.isEditing;
            delete payload.prevDescription;
            this.axiosInstance
                .put('billing/events', payload)
                .then(response => {
                    this.showSnackbar({ message: 'Event updated successfully' });
                    this.getBillingData();
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating events' });
                });
        },
        showDeleteDialog(id) {
            this.deleteDialog.eventId = id;
            this.deleteDialog.show = true;
        },
        deleteEvent(id) {
            this.axiosInstance
                .delete(`/billing/events/${id}`)
                .then(response => {
                    this.deleteDialog.eventId = null;
                    this.deleteDialog.show = false;
                    this.showSnackbar({ message: 'Event deleted successfully' });
                    this.getBillingData();
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error deleting events' });
                });
        },
        // showEvents(event, invoiceEvents, billingMonth) {
        /* Note! The 'event' param is the JavaScript event object.
         * It has nothing to do with invoice events, but is the first arg passed to
         * an event handler by default, so we have to include it
         */
        // this.eventDialog.loading = true;
        // this.eventDialog.show = true;
        // this.eventDialog.title = `${billingMonth} Invoice Summary`;
        // this.eventDialog.table.events = invoiceEvents;
        // this.eventDialog.loading = false;
        // },
        getStatusColor(status) {
            return status === 'Paid' ? 'green' : 'red';
        },
        getEventColor(event) {
            switch (this.eventTypes[event]) {
                case 'Streaming Overage':
                    return 'blue';
                    break;
                case 'Viewer Overage':
                    return 'teal';
                    break;
                case 'Service Consumption':
                    return 'indigo';
                    break;
                case 'Subscription Charge':
                    return 'purple';
                    break;
                default:
                    return 'blue-grey';
                    break;
            }
        },
        openBillingPortal() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/owner/portal/${this.ownerId}`)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => {});
        },
        createAxiosInstance() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axiosInstance = this.axios.create({
                    headers: { Authorization: `Bearer ${this.token}` },
                    baseURL: process.env.VUE_APP_API,
                });

                if (this.$auth.role.includes('SuperAdmin')) {
                    this.currentInvoiceTable.headers.push({ text: 'Actions', value: 'actions', width: '10%' });
                }
                this.getBillingData();
            });
        },
        getBillingData() {
            this.loading = true;
            const allInvoicesReq = this.axiosInstance.get(process.env.VUE_APP_API + '/billing/owner/invoices', {
                params: { ownerId: this.ownerId },
            });

            Promise.allSettled([allInvoicesReq]).then(([allInvoicesRes]) => {
                if (allInvoicesRes.status === 'fulfilled') {
                    this.allInvoicesTable.invoices = allInvoicesRes.value.data.invoices.reverse().map(invoice => {
                        const periodStart = this.$moment(invoice.periodStart).format('MM/DD/YY');
                        const periodEnd = this.$moment(invoice.periodEnd).format('MM/DD/YY');

                        return {
                            month: this.$moment(invoice.periodStart).format('MMMM'),
                            amount: invoice.amountDue,
                            status: invoice.amountDue === invoice.amountPaid ? 'Paid' : 'Overdue',
                            billingPeriod: `${periodStart}–${periodEnd}`,
                            actions: {
                                pdf: invoice.pdfLink,
                                stripeLink: invoice.stripeLink,
                                events: invoice.events.reverse(),
                                stripeId: invoice.stripeId,
                            },
                        };
                    });
                } else {
                    this.showSnackbar({ message: 'There was an error retrieving some of your settings.' });
                }

                this.loading = false;
            });
        },
        editBillingCard() {
            this.editCard = true;
        },
        getLocalDate(date) {
            const localDate = this.$moment(this.$moment.utc(date)).local();
            return localDate;
        },
        getStripeUrl(stripeId) {
            return `${process.env.VUE_APP_STRIPE_URL}/invoices/${stripeId}`;
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
            this.createAxiosInstance();
        });
    },
};
</script>

<style>
a {
    color: inherit !important;
}

.v-textarea textarea {
    font-size: 0.875rem;
    line-height: 1.5 !important;
}
</style>

import ApiService from './api.service';

const TributeTemplateService = token => {
    const api = ApiService.createInstance(token);

    return {
        getTemplates(params) {
            return api.get(`/TributeVideoTemplate`, { params: params });
        },
        getTemplate(id) {
            return api.get(`TributeVideoTemplate/get-template-with-fields/${id}`);
        },
        getSelected(id) {
            return api.get(`TributeVideo/selected-templates/${id}`);
        },
    };
};

export default TributeTemplateService;

<template>
    <div>
        <v-card-title class="mt-3">
            <font-awesome-icon icon="fa-regular fa-display" style="margin-right: 0.5rem"></font-awesome-icon>
            <h3 class="m-0">Admin Users</h3>
            <v-spacer></v-spacer>
            <create-admin-user @refresh="getAdminUsers(true)" />
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting  Admin Users..."
            :search="search"
            :options.sync="options"
        >
            <template v-slot:no-data>
                {{ noUsers && 'No Admin Users Found' }}
            </template>

            <template v-slot:item.name="{ item }">
                <div style="opacity: 0.5" v-if="!item.emailVerified">Pending</div>
                {{ item.firstName + ' ' + item.lastName }}
            </template>

            <template v-slot:item.email="{ item }">
                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>

            <template v-slot:item.primaryContactPhone="{ item }">
                <a :href="`tel:${item.primaryContactPhone}`">{{ item.primaryContactPhone }}</a>
            </template>

            <template v-slot:item.createDate="{ item }">
                <div>
                    {{ $moment(item.createDate).local().format('l') }}
                </div>
                <!-- <div>
                    {{ $moment(item.createDate).local().format('LTS') }}
                </div> -->
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list" style="display: flex; justify-content: center">
                    <edit-admin-user :item="item" @refresh="getAdminUsers(true)" />

                    <custom-tooltip v-if="item.emailVerified" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openPasswordResetModal(item)">
                                <font-awesome-icon icon="fa-regular fa-lock" style="font-size: 1.1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Password</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="!item.emailVerified" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-envelope"
                                    style="font-size: 1rem"
                                    :class="item.inviteSent ? 'disabled' : ''"
                                    @click="resendEmailInvite(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Resend Email Invite</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip v-if="!item.emailVerified" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-key"
                                    style="font-size: 1rem"
                                    @click="initManualPasswordModal(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Set User Password</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="openDeleteModal(item)">
                                <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>

                    <v-dialog v-model="deleteModal" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline"> Delete Admin ? </v-card-title>
                            <v-card-text
                                >Are you sure you want to delete
                                {{ selectedUser.firstName + ' ' + selectedUser.lastName }}?</v-card-text
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="gdarken-1" text @click="deleteModal = false"> cancel </v-btn>
                                <v-btn color="red darken-1" text @click="deleteUser()"> Delete </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>

        <reset-password
            v-if="passwordResetModal && token"
            :token="token"
            :user="selectedUser"
            @close-password-reset-modal="passwordResetModal = false"
        />

        <v-dialog v-if="selectedUser" v-model="setPasswordModal" max-width="550">
            <v-card class="p-3">
                <v-card-title class="headline">Set User Password</v-card-title>

                <v-card-text>
                    {{ selectedUser.email }}
                </v-card-text>
                <v-form ref="manualPassForm" v-model="manualPassFormValid">
                    <div class="pl-6 pr-6">
                        <v-text-field
                            v-if="showManualPassword"
                            :rules="[rules.required, rules.password]"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                        <v-text-field
                            autofocus
                            v-if="!showManualPassword"
                            :rules="[rules.required, rules.password]"
                            type="password"
                            label="Password"
                            v-model="manualPassword"
                            append-icon="mdi-eye-off"
                            @click:append="toggleShowPassword"
                        ></v-text-field>
                        <!-- <font-awesome-icon icon="fa-regular fa-eye-slash" />
                            <font-awesome-icon icon="fa-regular fa-eye" /> -->
                    </div>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gdarken-1" :disabled="!manualPassFormValid" text @click="manualUserCreation">
                        Update User
                    </v-btn>
                    <v-btn color="gdarken-1" text @click="cancelManualPassword"> Exit </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ResetPassword from '@/components/Misc/ResetPassword.vue';
import CreateAdminUser from '@/components/Actions/Admin/CreateAdminUser.vue';
import EditAdminUser from '@/components/Actions/Admin/EditAdminUser.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Admin Table',
    },
    data() {
        return {
            options: {
                itemsPerPage: 25,
                sortBy: ['createDate'],
                sortDesc: [true],
            },
            homes: [],
            snackbar: false,
            search: '',
            message: '',
            deleteModal: false,
            loading: true,
            errorLoading: false,
            selectedIndex: 0,
            funeralHome: {},
            noUsers: false,
            selectedUser: {},
            token: '',
            passwordResetModal: false,
            setPasswordModal: false,
            manualPassword: '',
            showManualPassword: false,
            manualPassFormValid: false,
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                // {
                //     text: 'Phone',
                //     value: 'primaryContactPhone',
                // },
                {
                    text: 'Created',
                    value: 'createDate',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    // width: '15%',
                    align: 'center',
                },
            ],
            users: [],
            rules: {
                required: value => !!value || 'Required.',
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
                    return pattern.test(value) || 'Min. 8 characters with at least one capital letter and a number.';
                },
                passwordsMatch: value => {
                    return value === this.password || `Passwords don't match`;
                },
            },
        };
    },
    components: {
        ResetPassword,
        CreateAdminUser,
        EditAdminUser,
        CustomTooltip,
    },
    watch: {
        setPasswordModal(val) {
            if (!val) {
                this.$refs.manualPassForm.reset();
            }
        },
    },
    mounted() {
        this.getAdminUsers(true);
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initManualPasswordModal(item) {
            this.selectedUser = item;
            this.setPasswordModal = true;
        },
        toggleShowPassword() {
            this.showManualPassword = !this.showManualPassword;
        },
        cancelManualPassword() {
            this.setPasswordModal = false;
            this.manualPassword = '';
            this.selectedUser = {};
        },
        manualUserCreation() {
            if (!(this.selectedUser?.id > 0)) {
                this.showSnackbar({ message: 'Invalid user id', color: 'error' });
                return;
            }

            let data = {
                password: this.manualPassword,
            };

            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/users/create-admin-manual/${this.selectedUser.id}`, data)
                    .then(response => {
                        this.selectedUser.emailVerified = true;
                        this.showSnackbar({ message: 'User Created' });
                        this.cancelManualPassword();
                    })
                    .catch(error => {
                        //Alert user if error is password error
                        const passwordError = 'PasswordStrengthError: Password is too weak';
                        if (error.response.data.Message == passwordError) {
                            const errorMessage = passwordError.substring(passwordError.indexOf(':') + 1).trim();
                            this.showSnackbar({ message: errorMessage, color: 'error' });
                        } else {
                            this.showSnackbar({ message: 'Unable to create user', color: 'error' });
                        }
                    });
            });
        },
        resendEmailInvite(user) {
            if (user.emailVerified) {
                this.showSnackbar({
                    message: `${user.email} has already verified their email address`,
                    color: 'error',
                });
                return;
            }

            if (user.inviteSent) {
                this.showSnackbar({ message: `Invite already sent to ${user.email}` });
                return;
            }

            user.inviteSent = true;

            this.$auth.getIdTokenClaims().then(result => {
                let token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` } })
                    .post(process.env.VUE_APP_API + '/users/resend?id=' + user.id)
                    .then(response => {
                        if (response.data) {
                            this.showSnackbar({ message: `Invite resent to ${user.email}` });
                        }
                    })
                    .catch(error => {
                        user.inviteSent = false;
                        this.showSnackbar({ message: `Error sending to ${user.email}`, color: 'error' });
                    });
            });
        },
        getAdminUsers(quick) {
            this.noUsers = false;

            if (this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    this.axios
                        .create({
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        })
                        .get(process.env.VUE_APP_API + `/users/superadmins?quick=${quick}`)
                        .then(response => {
                            if (response.data) {
                                this.users = response.data.map(user => {
                                    return { ...user, inviteSent: false };
                                });
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        })
                        .finally(() => {
                            if (!this.users.length) this.noUsers = true;
                            this.loading = false;
                        });
                });
            } else if (this.$auth.role.includes('Owner')) {
                let owner_id = this.$auth.user['https://memoryshare.com/owner_id'];

                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                    this.axios
                        .create({
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                        })
                        .get(process.env.VUE_APP_API + `/users/owners/${owner_id}`)
                        .then(response => {
                            if (response.data) {
                                this.users = response.data;

                                if (!this.users.length) this.noUsers = true;
                                this.loading = false;
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            }
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.selectedIndex = this.users.indexOf(item);
            this.deleteModal = true;
        },
        deleteUser() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .delete(process.env.VUE_APP_API + `/Users/${this.selectedUser.id}`)
                .then(response => {
                    this.message = `${this.selectedUser.firstName} ${this.selectedUser.lastName} deleted!`;
                    this.getAdminUsers(true);
                })
                .catch(error => {
                    this.message = `Error deleting user ${this.selectedUser.firstName} ${this.selectedUser.lastName}`;
                })
                .then(() => {
                    this.deleteModal = false;
                    this.snackbar = true;
                });
        },
        openPasswordResetModal(item) {
            this.selectedUser = item;
            this.passwordResetModal = true;
        },
        editUser(id) {
            this.$router.push({
                path: `/admins/edit/${id}`,
            });
        },
    },
};
</script>

<style>
.deleteModalBtns {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;
}
.deleteModalCard {
    text-align: center;
}

.action-list {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.disabled {
    opacity: 0.5;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-6">
            <div class="header">
                <h4>Live Streams</h4>
            </div>
            <div v-if="loading" class="loading-container">
                <v-progress-circular v-if="loading" color="#FF530D" indeterminate></v-progress-circular>
                <p>Loading Live Streams ...</p>
            </div>
            <div v-else-if="!loading && !totalStreams" class="empty-streams-container">
                <h2>No active streams</h2>
            </div>
            <div v-else-if="streams.length > 0" class="stream-container">
                <v-row>
                    <v-col v-for="(stream, i) in streams" :key="stream.id" cols="6">
                        <v-card>
                            <v-card-title
                                ><router-link :to="{ name: 'EditServices', params: { slug: stream.slug } }"
                                    >{{ stream.title }} - {{ stream.funeralHomeName }}</router-link
                                ></v-card-title
                            >
                            <event-viewer
                                class="p-4"
                                :src="stream.hlsPlayerUrl"
                                :isLiveStream="stream.isLive"
                                :event="stream.event"
                            ></event-viewer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-right mt-4 mb-4">
                            <h6>Total: {{ totalStreams }}</h6>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import EventViewer from '@/components/videojs/EventViewer.vue';
import { streamStates } from '@/constants.js';
import { log } from '@/utilities/debug-helpers';

export default {
    metaInfo: {
        title: 'Live Streams',
    },
    data() {
        return {
            value: 10,
            bufferValue: 20,
            interval: 0,
            streams: [],
            loading: true,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            streamStates,
        };
    },
    async created() {
        this.$socket.start({
            log: true, // Active only in development for debugging.
        });

        await this.setAuthToken();
        this.createAxiosInstance();
        this.getStreams();
    },
    sockets: {
        NotifyLive(data) {
            log('notify live!', 'blue');
            if (this.$route.name === 'LiveDashboard') {
                const event = this.findEvent(this.streams, data.id);
                if (event || data.eventStatus == 2) {
                    this.getStreams();
                }
            }
        },
    },
    methods: {
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        findEvent(arr, id) {
            return arr.find(event => event.id == id);
        },
        getStreams() {
            // this.loading = true;

            this.axiosInstance
                .get(`/live/dashboard`)
                .then(({ data }) => {
                    this.streams = data.streamObject.map(stream => {
                        return {
                            title: stream.event.title,
                            isLive: stream.event.live,
                            hlsPlayerUrl: stream.event.liveURL,
                            id: stream.event.id,
                            serviceId: stream.info.serviceId,
                            slug: stream.service.slug,
                            funeralHomeName: stream.funeralHomeName,
                            event: stream.event,
                        };
                    });
                })
                .catch(error => {})
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        totalStreams() {
            return this.streams.length;
        },
    },
    components: {
        LineChart,
        EventViewer,
    },
};
</script>

<style lang="scss" scoped>
// .dashboard-container {
//     container-type: inline-size;
//     color: red !important;
// }
.loading-container {
    text-align: center;
    margin: 8rem 0;
}

.video-boxes-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.video-box {
    width: 31%;
    margin: 0.5rem 0;
    //flex: 1 1 30%;
    background: #f8f8f8;

    .placeholder {
        height: 16rem;
        overflow: hidden;
        text-align: center;
        background: #f2f2f2;
        line-height: 16rem;
        position: relative;
    }
}

.video-js {
    width: 100%;
    height: 16rem;
}

#line-chart {
    height: 16rem;
}

.empty-streams-container {
    height: 14rem;
    margin: 2rem 0 0 2.5%;
}

.analytics-btn {
    cursor: pointer;
    padding: 0.25rem;
    transition: 0.3s ease-in-out;

    &:hover {
        background: #d8d8d8;
        border-radius: 50%;
    }
}

// @container (max-width: 768px) {
//     .dashboard-container {
//         color: blue !important;
//     }
// }
</style>

<template>
    <div
        :key="event.id"
        class="service-editor-section-entry"
        :class="activeTab === index ? 'active' : ''"
        @click="$emit('click')"
    >
        <!-- <span
            v-if="!event.hidden"
            class="status-dot"
            style="border: 2px solid blue"
            :class="hasTributePhotos ? 'active' : ''"
        >
        </span>
        <span v-else class="status-dot" style="background-color: #fb8c00; border: 2px solid red"></span> -->

        <font-awesome-icon :color="computedIconColor" icon="fa-solid fa-play" />

        <span class="entry-title"
            >{{ cleanTitle(event.title) }}
            <span v-if="$auth.role.includes('SuperAdmin')"></span>
        </span>

        <!-- Live Icon -->
        <div v-if="event.eventStatus === 2" class="live-container">
            <div class="blob" />
            <span>LIVE</span>
        </div>

        <!-- @click="$emit('deleteEvent', event)" -->
        <v-btn
            v-if="$auth.role.includes('SuperAdmin')"
            @click.stop="openDeleteEventConfirm(event)"
            class="delete-btn"
            :class="{ 'delete-btn--light-bg': activeTab === index }"
            color="white"
            small
            fab
            text
            dark
        >
            <font-awesome-icon icon="fa-regular fa-trash-can" />
        </v-btn>

        <!-- START: Confirmation Modal -->
        <v-dialog max-width="400px" v-model="deleteEventModal">
            <v-card>
                <v-card-title>Delete Tribute Event</v-card-title>
                <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text>

                <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                    <v-btn @click="deleteEventModal = false">Cancel</v-btn>
                    <v-btn @click="handleDelete(event)" color="error">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { eventStates } from '@/constants';
import { mapActions } from 'vuex';
export default {
    name: 'TributeEntry',
    props: {
        event: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        activeTab: {
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            eventStates: [...eventStates],
            deleteEventModal: false,
        };
    },
    computed: {
        tribute() {
            return this.$store.state.tributeVideo;
        },
        hasTributePhotos() {
            if (this.tribute?.mainPhotoId) {
                return true;
            } else {
                return false;
            }
        },
        computedIconColor() {
            if (this.event.hidden) {
                return '#ff9800';
            }

            if (this.hasTributePhotos) {
                return '#0c3c60';
            }

            return 'rgb(198, 198, 198)';
        },
    },
    methods: {
        /**
         * Currently, the title of an event comes back from the api as
         * 'firstName lastName's Funeral'. We need to remove the first
         * and last name for visual purposes.
         */
        cleanTitle: function (title) {
            let cleaned = title.replace(`${this.service.firstName} ${this.service.lastName}'s `, '');
            return cleaned;
        },
        ...mapActions('tributeVideo', ['resetTributeVideoState']),
        handleDelete(event) {
            this.$emit('deleteEvent', event);
            this.resetTributeVideoState();
            this.deleteEventModal = false;
        },
        openDeleteEventConfirm() {
            // console.log('HERE');
            this.deleteEventModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.tribute-status {
    height: 0.75rem;
    width: 0.75rem;
    background: rgb(198, 198, 198);
    border-radius: 50%;
}
.delete-btn {
    &:hover,
    &:focus {
        background-color: rgba(233, 232, 232, 0.5) !important;
    }
}
.hidden {
    background: #fb8c00;
}
</style>

<template>
    <div>
        <v-form @submit.prevent="submit" ref="form">
            <v-chip v-if="isDevHome" class="mb-6" color="primary">
                <font-awesome-icon icon="fa-regular fa-house" class="mr-3" />
                <h6 class="mb-0">This Is A Test Home</h6>
            </v-chip>

            <v-text-field v-model="name" label="Name" :rules="rules"></v-text-field>
            <v-text-field v-model="address1" label="Address 1" :rules="rules"></v-text-field>
            <v-text-field v-model="address2" label="Address 2"></v-text-field>
            <v-row class="mt-0">
                <v-col>
                    <v-text-field v-model="city" label="City" :rules="rules"></v-text-field>
                </v-col>

                <v-col>
                    <!-- <v-text-field v-model="state" label="State" :rules="rules"></v-text-field> -->
                    <v-select
                        v-if="selectedCountry == 'US'"
                        attach=""
                        v-model="state"
                        :items="usStates"
                        item-text="label"
                        item-value="value"
                        :rules="[v => !!v || 'State is required']"
                        label="State"
                    ></v-select>
                    <v-select
                        v-else-if="selectedCountry == 'CA'"
                        attach=""
                        v-model="state"
                        :items="candaianProvinces"
                        item-text="name"
                        item-value="abbreviation"
                        :rules="[v => !!v || 'State is required']"
                        label="Province"
                    ></v-select>

                    <v-text-field
                        v-else
                        v-model="state"
                        label="State"
                        :rules="[v => !!v || 'State is required']"
                    ></v-text-field>
                </v-col>

                <v-col>
                    <v-text-field v-model="zipCode" label="Zip" :rules="rules"></v-text-field>
                </v-col>
            </v-row>

            <v-autocomplete
                attach=""
                label="Country"
                :items="countries"
                item-text="name"
                item-value="abbreviation"
                v-model="selectedCountry"
                :rules="[v => !!v || 'Country is required']"
            ></v-autocomplete>

            <v-text-field
                v-model="email"
                label="Billing Email"
                type="email"
                :rules="rules"
                hint="The billing email is where MemoryShare will send all invoices too. This person does not need to have a login to MemoryShare."
                :persistent-hint="true"
                class="mb-5"
            ></v-text-field>
            <v-select
                attach=""
                v-model="defaultUser"
                :items="users"
                :item-text="getFullName"
                item-value="id"
                label="Default User"
                hint="This will be the default contact for live stream support"
                persistent-hint
                no-data-text="No users available"
                class="mb-5"
            >
            </v-select>
            <v-text-field v-model="primaryContact" label="Primary Contact" :rules="rules"></v-text-field>

            <v-text-field
                v-model="primaryContactPhoneNumber"
                label="Primary Contact Phone Number"
                :rules="rules"
            ></v-text-field>
            <v-text-field
                v-model="primaryContactEmail"
                label="Primary Contact Email"
                type="email"
                :rules="rules"
            ></v-text-field>
            <v-text-field
                v-model="funeralHomeNumber"
                v-mask="'###.###.####'"
                type="phone"
                label="Funeral Home Number"
                :rules="rules"
            ></v-text-field>

            <v-text-field :rules="rules" label="Website" v-model="website"></v-text-field>
            <v-text-field label="Landing Page" readonly v-model="landingPage"></v-text-field>
            <a :href="landingPage">Visit Landing Page</a>

            <v-autocomplete
                v-model="timezone"
                :items="timezoneOptions"
                item-value="id"
                item-text="displayName"
                :menu-props="{ closeOnClick: true }"
                label="Timezone"
            ></v-autocomplete>

            <div class="mt-8 text-right">
                <v-btn @click="update">Update</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { usStates, candaianProvinces, countries } from '@/constants';
import emojiRegex from 'emoji-regex';

export default {
    name: 'HomeSettings',
    props: ['token'],
    data() {
        return {
            usStates,
            candaianProvinces,
            countries,
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            email: '',
            primaryContact: '',
            primaryContactPhoneNumber: '',
            primaryContactEmail: '',
            funeralHomeNumber: '',
            isDevHome: null,
            website: '',
            landingPage: '',
            rules: [
                v => v.length <= 150 || 'Max 150 characters',
                v => v.length !== 0 || 'This field is required',
                v => !this.containsEmoji(v) || 'This field should not contain emojis',
            ],
            landingPageLink: '',
            timezoneOptions: [],
            timezone: '',
            defaultUser: null,
            users: [],
            keywordReportLink: '',
            selectedCountry: '',
            loading: true,
        };
    },
    watch: {
        selectedCountry() {
            if (!this.loading) {
                this.state = '';
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        containsEmoji(string) {
            const regex = emojiRegex();
            return regex.test(string);
        },
        getTimezoneOptions() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/dropdown/timezones`)
                        .then(response => {
                            let result = response.data;
                            this.timezoneOptions = result;

                            resolve(result);
                        })
                        .catch(error => {
                            reject();
                        });
                });
            });
        },
        getFullName(item) {
            return item.firstName + ' ' + item.lastName;
        },
        getUsers(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Users?funeralHomeId=${id}&quick=true`)
                .then(res => {
                    if (res.data) this.users = res.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getHome() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/funeralhomes/${this.$auth.funeralHomeId}`)
                .then(response => {
                    const home = response.data.funeralHome;

                    this.name = home.name;
                    this.address1 = home.address1;
                    this.address2 = home.address2;
                    this.city = home.city;
                    this.state = home.state;
                    this.zipCode = home.zipCode;
                    this.email = home.email;
                    this.ownerId = home.ownerId;
                    this.primaryContact = home.primaryContact;
                    this.primaryContactPhoneNumber = home.primaryContactPhoneNumber;
                    this.primaryContactEmail = home.primaryContactEmail;
                    this.funeralHomeNumber = home.funeralHomeNumber;
                    this.isDevHome = home.devHome;
                    this.website = home.website;
                    this.landingPage = window.location.origin + '/landing-page/' + encodeURI(home.name);
                    this.timezone = home.timeZone;
                    this.defaultUser = home.defaultUser;
                    this.keywordReportLink = home.keywordReportLink;

                    this.selectedCountry = home.country;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        update() {
            let valid = this.$refs.form.validate();

            if (valid) {
                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    country: this.selectedCountry,
                    email: this.email,
                    ownerId: this.ownerId,
                    primaryContact: this.primaryContact,
                    primaryContactEmail: this.primaryContactEmail,
                    primaryContactPhoneNumber: this.primaryContactPhoneNumber,
                    funeralHomeNumber: this.funeralHomeNumber,
                    devHome: this.isDevHome,
                    timeZone: this.timezone,
                    website: this.website,
                    defaultUser: this.defaultUser,
                };

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + `/funeralHomes/${this.$auth.funeralHomeId}`, data)
                    .then(response => {
                        this.showSnackbar({ message: 'Settings successfully updated' });
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error! Please try again' });
                    });
            } else {
                this.showSnackbar({ message: 'Please fix errors in form and try again.' });
            }
        },
    },
    created() {
        this.getTimezoneOptions();
        this.getHome();
        this.getUsers(this.$auth.funeralHomeId);
    },
};
</script>

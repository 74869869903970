<template>
    <AppLayout :has-background="true" :contained="true">
        <!-- START: Service Header -->
        <section class="service-header-section">
            <h1 class="header">Create New Service</h1>
        </section>
        <!-- END: Service Header -->

        <!-- START: Service Editor -->
        <section class="service-editor-section">
            <!-- Event Content -->
            <div class="service-editor-section-content service-creator-section-content">
                <div class="content-container">
                    <h3 class="title mb-8">Create New Service</h3>
                    <v-alert v-if="passareId" type="info">
                        Your funeral home is using Passare for Case Management. It's recommended to first create the
                        service in Passare, then import it.
                    </v-alert>

                    <v-row class="field-container-create">
                        <v-col>
                            <v-text-field
                                v-model="firstName"
                                label="First Name"
                                :rules="[v => !!v || 'First name is required']"
                            />
                        </v-col>

                        <v-col>
                            <v-text-field v-model="lastName" label="Last Name" />
                        </v-col>
                    </v-row>
                    <v-row class="field-container-create">
                        <v-col>
                            <calendar v-model="birthDate" label="Birth Date" :output-local="true" />
                        </v-col>

                        <v-col>
                            <calendar v-model="deathDate" label="Death Date" :output-local="true" />
                        </v-col>
                    </v-row>

                    <div class="d-flex">
                        <v-checkbox v-model="testService" label="Test Service" style="width: 120px" class="mr-4" />
                        <v-checkbox v-model="privateServiceCheckbox" label="Private Service" style="width: 140px" />
                    </div>

                    <v-btn
                        @click.prevent="saveService()"
                        :disabled="loading"
                        block
                        color="#0C3C60"
                        dark
                        large
                        class="save-btn mt-8"
                        >Save Service</v-btn
                    >
                </div>
            </div>
        </section>
        <!-- END: Service Editor -->

        <Spinner v-if="loading"></Spinner>
    </AppLayout>
</template>

<script>
import Calendar from '@/components/ui/Calendar.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { mapActions } from 'vuex';
import ServicesService from '@/services/services.service';

export default {
    metaInfo: {
        title: 'Create Service',
    },
    name: 'CreateService',
    data() {
        return {
            firstName: '',
            lastName: '',
            birthDate: null,
            deathDate: null,
            testService: false,
            privateServiceCheckbox: false,
            token: '',
            loading: false,
            passareId: '',
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        getPassareData() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/Integration/Passare/settings/${this.$auth.funeralHomeId}`)
                    .then(({ data }) => {
                        this.passareId = data.passareOrganizationId;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });
        },

        async saveService() {
            if (!this.firstName) {
                this.showSnackbar({ message: 'Please enter a name to create service' });
                return;
            }

            let data = {
                firstName: this.firstName,
                lastName: this.lastName,
                birthDate: this.birthDate,
                deathDate: this.deathDate,
                funeralHomeId: this.$auth.funeralHomeId,
                private: this.privateServiceCheckbox,
                test: this.testService,
                createDefaultEvents: true,
            };

            try {
                this.loading = true;
                var response = await this.servicesApiInstance.create(data);
                console.log('create service response');
                if (response.data.slug) {
                    this.$router.push({
                        name: 'EditServices',
                        params: { slug: response.data.slug },
                    });
                }
            } catch (err) {
                this.loading = false;
                this.showSnackbar({ message: 'Error creating service', color: 'error' });
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    watch: {},
    components: { Calendar, Spinner },
    async mounted() {
        await this.setAuthToken();
        this.servicesApiInstance = ServicesService(this.token);

        let temp = new Date();
        this.birthDate = this.checkForUTC(new Date(temp.setFullYear(temp.getFullYear() - 70)).toISOString());

        let deathTemp = new Date();
        this.deathDate = this.checkForUTC(new Date(deathTemp.setDate(deathTemp.getDate() - 3)).toISOString());

        this.getPassareData();
    },
};
</script>

<style lang="scss">
.service-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
        color: white;
        margin: 0;
    }

    .button-container {
        display: flex;
        gap: 0.5rem;
    }

    width: 100%;
}

.service-editor-section {
    background: white;
    margin-top: 2rem;
    box-shadow: 0 0 1rem rgb(155, 155, 155);
    display: flex;
    width: 100%;
    // min-width: 200px;

    &-content {
        padding: 3rem 4rem;
        flex: 1;
        display: flex;
        flex-direction: column;

        .content-container {
            height: 100%;
            min-width: 200px;
            // max-width: 400px;
        }
    }
}

.service-creator-section-content {
    width: 100%;
    max-width: 100% !important;
}

// BLOB =======================================================================
.blob {
    display: inline-block;
    border-radius: 50%;
    margin: 0 0.5rem;
    height: 15px;
    width: 15px;
    vertical-align: middle;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.dash-container-service-editor {
    background-image: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: 0 -18rem, bottom right;
    background-size: auto, 30em;
    background-attachment: scroll, fixed;
    padding: 5rem 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4rem;
    transition: 0.1s linear;

    &.menu-showing {
        margin-left: 18rem !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .field-container-create {
        display: grid !important;
        grid-template-columns: 1fr !important;

        .col {
            flex: 1;
        }
    }

    .service-editor-section-content {
        padding: 2rem;
        width: 100%;
    }
}
</style>

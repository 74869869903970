<template>
    <div class="story-share-tab">
        <h3 class="text-center">Get the Word Out</h3>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <div class="col-container">
                    <!-- START: Mobile Upload -->
                    <div class="qr-wrap action-wrap">
                        <div class="text-left d-flex flex-column">
                            <h4 class="uploader-title">Share a Link</h4>

                            <span class="uploader-body">A direct link families can share stories at.</span>
                        </div>
                        <div class="action-content p-3">
                            <div class="content-upper">
                                <div class="ms-phone-wrap">
                                    <img class="mobile-icon" :src="mobileIconSource" />
                                    <img
                                        :src="require(`@/assets/images/logo.png`)"
                                        alt="MemoryShare Logo"
                                        class="cta-logo-img"
                                    />
                                </div>
                                <h5 class="action-cta">
                                    Available on <br />
                                    iPhone and Android
                                </h5>
                            </div>

                            <div class="btn-wrap">
                                <button class="btn-ghost" @click="$emit('init-invite')">
                                    Share
                                    <font-awesome-icon class="ml-1" icon="fa-regular fa-share" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END: Mobile Upload -->
            </v-col>

            <!-- START: Qr pdf content -->
            <v-col>
                <div class="col-container">
                    <div class="pdf-wrap action-wrap">
                        <div class="text-left d-flex flex-column">
                            <h4 class="uploader-title">Print Out</h4>

                            <span class="uploader-body"
                                >A print out explaining to the families how to use the stories product.</span
                            >
                        </div>
                        <div class="action-content p-3">
                            <div class="content-upper">
                                <!-- START: small pdf design -->
                                <div class="qr-design-wrap">
                                    <div class="horizontal-bg-bar">
                                        <!-- START: Large Qr -->
                                        <v-avatar class="qr-circle-outer large-pdf-design" size="155" color="#ab0658">
                                            <v-avatar size="130" color="#FFFFFF">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <qrcode-vue
                                                        v-if="contributeLink"
                                                        style="width: 80px; height: 80px"
                                                        :value="contributeLink"
                                                        size="80"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Large Qr -->

                                        <!-- START: Medium QR -->
                                        <v-avatar class="qr-circle-outer medium-pdf-design" size="130" color="#ab0658">
                                            <v-avatar size="110" color="#FFFFFF">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center"
                                                >
                                                    <qrcode-vue
                                                        v-if="contributeLink"
                                                        style="width: 70px; height: 70px"
                                                        :value="contributeLink"
                                                        size="70"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Medium QR -->

                                        <!-- START: Small QR -->
                                        <v-avatar class="qr-circle-outer small-pdf-design" size="100" color="#ab0658">
                                            <v-avatar size="80" color="#FFFFFF">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center"
                                                >
                                                    <qrcode-vue
                                                        v-if="contributeLink"
                                                        style="width: 50px; height: 50px"
                                                        :value="contributeLink"
                                                        size="50"
                                                        render-as="svg"
                                                    />
                                                </div>
                                            </v-avatar>
                                        </v-avatar>
                                        <!-- END: Small QR -->
                                    </div>
                                </div>
                                <h5 class="action-cta">Scan to get started</h5>
                            </div>
                            <!-- END: small pdf design -->

                            <div class="btn-wrap">
                                <button class="btn-ghost" @click="$emit('download-qr-pdf')">
                                    Print QR
                                    <font-awesome-icon class="ml-2" icon="fa-regular fa-print" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>

            <!-- END: Qr pdf content -->

            <v-col>
                <div class="col-container">
                    <div class="uploader-wrap action-wrap">
                        <div class="text-left d-flex flex-column">
                            <h4 class="uploader-title">Display Video</h4>
                            <span class="uploader-body"
                                >Broadcast the QR code on your TVs during the visitation or service.
                            </span>
                        </div>

                        <div>
                            <div class="action-content p-3">
                                <div class="content-upper">
                                    <font-awesome-icon
                                        style="font-size: 5rem"
                                        icon="fa-regular fa-clapperboard-play"
                                    ></font-awesome-icon>
                                    <h5 class="action-cta">Display contribute link with QR video</h5>
                                </div>
                                <div class="btn-wrap">
                                    <button @click="$emit('display-tv-qr')" class="btn-ghost">
                                        Display
                                        <font-awesome-icon icon="fa-solid fa-tv" class="ml-2"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>

            <!-- <v-col class="text-right" cols="12">
                    <v-btn depressed @click="$emit('force-finish-onboarding')"
                        >Next
                        <font-awesome-icon class="ml-2" icon="fa-regular fa-chevron-right"></font-awesome-icon>
                    </v-btn>
                </v-col> -->
        </v-row>
    </div>
</template>
<script>
import mobileIconSource from '@/assets/images/mobile-phone.png';
import QrcodeVue from 'qrcode.vue';

export default {
    data() {
        return {
            mobileIconSource,
        };
    },
    props: {
        contributeLink: {
            type: String,
            default: '',
        },
    },
    components: {
        QrcodeVue,
    },
};
</script>

<style lang="scss" scoped>
.deadline-link {
    color: #999999;
}

.deadline-link:hover {
    text-decoration: underline;
}

.deadline-btn {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.main-photo-cta {
    border: 3px dashed #bbb;
    border-radius: 32px;
    cursor: pointer;
}

.horizontal-bg-bar {
    border: 5px solid #ab0658;
    background-color: #ab0658;
    position: relative;
    width: 100%;
    border-radius: 20px;
    height: 130px;
}

.qr-circle-outer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.qr-design-wrap {
    width: 100%;
    position: relative;
}

.stepper-container {
    container-type: inline-size;
    container-name: stepper;
    margin: 0 auto;

    // display: flex;
    // justify-content: center;
    max-width: 100%;
    min-width: 285px;

    .step-divider {
        margin: 35px -20px !important;
    }
    .step {
        max-width: fit-content;
    }

    .v-stepper__step__step {
        font-size: 18px;
        font-weight: 800;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .step-label {
        color: #000;
    }

    .v-stepper__step--active .v-stepper__step__step,
    .v-stepper__step--complete .v-stepper__step__step {
        background: #2c3e50 !important;
        border: 2px solid #2c3e50 !important;
    }

    .v-stepper__step--inactive .v-stepper__step__step {
        background: none !important;
        color: #2c3e50;
        border: 2px solid #2c3e50 !important;
    }

    .v-divider {
        border-color: #91a6b6 !important;
    }

    .stepper-content {
        max-width: 800px;
        min-width: 150px;
        flex-grow: 2;
        // border: 2px solid red;
    }
}

.col-container {
    container-type: inline-size;
    container-name: action;
    min-width: 170px;

    //border: 1px solid transparent;
}
.action-wrap {
    border-radius: 7px;
    background-color: #f8f8f8;
    padding: 32px;

    height: 100%;
    min-height: 525px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // .text-heading {
    //     font-weight: 800;
    //     font-size: 32px;
    // }
    .action-cta {
        font-weight: 800;
        font-size: 20px;
        text-align: center;
    }
    .btn-wrap {
        padding: 12px;
    }
}

.action-content {
    background-color: white;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;

    .content-upper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        justify-content: space-evenly;
    }
}

.pdf-wrap {
    height: 100%;
}
.qr {
    height: 300px;
}
.fake-btn {
    background-color: #ff530d;
    // background-color: #fb8c00;
    border-radius: 5px;
    padding: 9px 25px;
    color: white;
    font: 14px Avenir, Helvetica, Arial, sans-serif;
}
.cta-logo-img {
    height: 30px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.uploader-title {
    font-weight: 800;
    font-size: 20px;
}

.uploader-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #2c3e50;
}

.ms-phone-wrap {
    position: relative;
    // height: 100px;
    height: 100px;

    .mobile-icon {
        width: 100px;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }

    .calendar-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        .ms-date-picker {
            width: fit-content;
        }
        .main-container {
            position: relative;
            top: 0;
            margin-bottom: 18px;
        }
        .v-input {
            display: none !important;
        }
    }
}

//Container under 200px
@container action (max-width: 240px) {
    .story-share-tab {
        .action-wrap {
            padding: 16px;

            .btn-ghost {
                font-size: 12px;
                padding: 8px 9px;
            }
            .btn-orange {
                font-size: 12px;
                padding: 8px 9px;
            }
            .uploader-title {
                font-size: 18px;
            }
            .uploader-body {
                font-size: 14px;
                line-height: 19px;
            }
            .action-cta {
                font-weight: 600;
                font-size: 15px;
            }
            .btn-wrap {
                padding: 4px;
            }
            // .text-heading {
            //     font-weight: 800;
            //     font-size: 20px;
            // }
        }

        .medium-pdf-design,
        .large-pdf-design {
            display: none !important;
        }

        .horizontal-bg-bar {
            height: 80px;
            min-width: 110px;
        }

        .small-scan-label {
            display: block;
        }
    }
}

//Container between 200 & 300px
@container action  (min-width: 240px) and (max-width: 300px) {
    .story-share-tab {
        .large-pdf-design,
        .small-pdf-design,
        .small-scan-label {
            display: none !important;
        }

        .horizontal-bg-bar {
            height: 100px;
        }
    }
}
//Container over 300px
@container action (min-width: 300px) {
    .story-share-tab {
        .medium-pdf-design,
        .small-pdf-design,
        .small-scan-label {
            display: none !important;
        }
    }
}
</style>

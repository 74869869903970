<template>
    <div class="embed">
        <h5>{{ title }}</h5>

        <code v-if="hasValidEvents" class="embed__code">{{ embedCode.replace('{', '').replace('}', '') }}</code>

        <div v-if="!hasValidEvents">
            <p>
                The Embed Code is not available until at least one event is active. To activate an event, you need to
                set a date, then click "Save".
            </p>
        </div>

        <v-btn @click="copyToClipboard(embedCode)" :disabled="!hasValidEvents">
            <font-awesome-icon icon="fa-regular fa-clone" class="mr-2 justify-left" />

            <span>{{ buttonText }}</span>
        </v-btn>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'EmbedSnippet',
    props: {
        title: {
            type: String,
            required: true,
        },
        embedCode: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Copy Embed Code',
        },
        hasValidEvents: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(text) {
            const el = document.createElement('textarea');
            el.value = text.replace('{', '').replace('}', '');
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.showSnackbar({ message: 'Copied to clipboard' });
        },
    },
};
</script>

<style lang="scss" scoped>
.embed {
    &__code {
        display: block;
        background: #1f2227 !important;
        padding: 0.75rem;
        color: #deb887 !important;
        margin-bottom: 1rem;
        max-height: 200px;
        overflow: auto;
        width: 100%;
    }
}
</style>

<template>
    <div>
        <div>
            <div v-if="healthRecords.length">
                <v-select
                    :items="healthRecords"
                    item-text="started_at"
                    return-object
                    v-model="selectedRecord"
                    label="Select a streaming session"
                    attach=""
                >
                    <!-- @input="getHealthChart" -->
                    <template v-slot:item="{ item }"> {{ item.started_at | moment('lll') }} </template>
                    <template v-slot:selection="{ item }">
                        {{ item.started_at | moment('lll') }}
                    </template>
                </v-select>

                <div v-if="chartReady">
                    <line-chart
                        v-if="advancedMetrics"
                        :chart-data="datacollection"
                        :options="advancedOptions"
                    ></line-chart>
                    <line-chart
                        v-if="!advancedMetrics"
                        :chart-data="simpleDataCollection"
                        :options="simpleOptions"
                    ></line-chart>
                    <!-- ref="simpleLineChart" -->
                    <v-btn dark color="orange" class="mt-3" @click="toggleAdvancedMetrics">{{
                        advancedMetrics ? 'Data' : 'Advanced Metrics'
                    }}</v-btn>
                </div>
            </div>

            <div v-if="loading">
                <div class="text-center">Loading Chart Data</div>
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </div>
            <div class="text-center" v-if="!healthRecords.length">No Records</div>
        </div>
    </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import Spinner from '@/components/ui/Spinner.vue';

const simpleAnnotations = {
    drawTime: 'afterDatasetsDraw',
    events: ['click', 'mouseout', 'over', 'mouseenter'],
    annotations: [
        {
            id: '1',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 0.6,
            borderColor: 'rgba(244, 67, 54, .7)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Minimum',
                fontSize: 10,
            },
            // onMouseenter: function (e) {
            //     console.log(this.$refs.simpleLineChart);
            //     const item = this.$refs.simpleLineChart._data._chart.getElementAtEvent(e);
            //     console.log(item);
            //     console.log('hover');
            // }.bind(this),
            // onMouseout: function (e) {
            //     console.log('out');
            // },
        },
        {
            id: '2',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 0.8,
            borderColor: 'rgba(255, 235, 59, .7)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Bad Quality',
                fontSize: 10,
            },
        },
        {
            id: '3',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 1.2,
            borderColor: 'rgba(76, 175, 80, .7)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Good Quality',
                fontSize: 10,
            },
        },
        {
            id: '4',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 1.5,
            borderColor: 'rgba(33, 150, 243, .7)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Better Quality',
                fontSize: 10,
            },
        },
        {
            id: '5',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 3.0,
            borderColor: 'rgba(103, 58, 183, .7)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Best Quality',
                fontSize: 10,
            },
        },

        {
            id: '6',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: 3.5,
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
        },
    ],
};

const advancedAnnotations = {
    drawTime: 'afterDatasetsDraw',
    events: ['click', 'mouseout', 'over', 'mouseenter'],
    annotations: [
        {
            id: '1',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 0.6,
            borderColor: 'rgba(244, 67, 54, .4)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Minimum',
                fontSize: 10,
            },
            // onMouseenter: function (e) {
            //     console.log(this.$refs.simpleLineChart);
            //     const item = this.$refs.simpleLineChart._data._chart.getElementAtEvent(e);
            //     console.log(item);
            //     console.log('hover');
            // }.bind(this),
            // onMouseout: function (e) {
            //     console.log('out');
            // },
        },
        {
            id: '2',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 0.8,
            borderColor: 'rgba(255, 235, 59, .4)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Bad Quality',
                fontSize: 10,
            },
        },
        {
            id: '3',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 1.2,
            borderColor: 'rgba(76, 175, 80, .4)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Good Quality',
                fontSize: 10,
            },
        },
        {
            id: '4',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 1.5,
            borderColor: 'rgba(33, 150, 243, .4)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Better Quality',
                fontSize: 10,
            },
        },
        {
            id: '5',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 3.0,
            borderColor: 'rgba(103, 58, 183, .4)',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Best Quality',
                fontSize: 10,
            },
        },
        {
            id: '6',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'left-y-axis',
            value: 3.5,
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
        },
    ],
};

export default {
    props: {
        event: {
            type: Object,
        },
        live: {
            type: Boolean,
        },
        accessToken: {
            type: String,
        },
        anonymous: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            testlist: [1, 2, 3, 4, 5],
            liveTestLimit: 0,
            advancedMetrics: false,
            token: '',
            healthRecords: [],
            selectedRecord: {},
            datacollection: null,
            simpleDataCollection: null,
            chartReady: false,
            loading: true,
            labels: [],
            inboundData: {
                label: 'Inbound Bitrate (Mbps)',
                borderColor: 'rgba(255,83,13,.9)',
                backgroundColor: 'rgba(0,0,0,0)',
                yAxisID: 'left-y-axis',
                data: [],
            },
            framerateData: {
                label: 'Frame Rate (FPS)',
                borderColor: 'rgba(25,200,13, .9)',
                backgroundColor: 'rgba(0,0,0,0)',
                yAxisID: 'right',
                data: [],
            },
            keyframeData: {
                label: 'Keyframe Interval(GOP)',
                borderColor: 'rgba(8,101,94, .9)',
                backgroundColor: 'rgba(0,0,0,0)',
                yAxisID: 'right',
                data: [],
            },
            simpleData: {
                label: 'Bitrate',
                borderColor: 'rgba(255,83,13, .9)',
                backgroundColor: 'rgba(0,0,0,0)',

                yAxisID: 'simple-y-axis',

                data: [],
            },

            simpleOptions: {
                plugins: {
                    chartAreaBorder: 'red',
                },
                scales: {
                    yAxes: [
                        {
                            id: 'simple-y-axis',
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value + ' Mbps';
                                },
                            },
                        },
                    ],
                },
                legend: {
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                    onLeave: function (e) {
                        e.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: simpleAnnotations,
            },

            advancedOptions: {
                scales: {
                    yAxes: [
                        {
                            id: 'left-y-axis',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value + ' Mbps';
                                },
                            },
                        },
                        {
                            id: 'right',
                            type: 'linear',
                            position: 'right',

                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        },
                    ],
                },
                legend: {
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                    onLeave: function (e) {
                        e.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    mode: 'label',
                },

                responsive: true,
                maintainAspectRatio: false,
                annotation: advancedAnnotations,
            },
            monitorInterval: null,
        };
    },
    watch: {
        selectedRecord(newVal) {
            const lastHealthRec = this.healthRecords[this.healthRecords.length - 1];

            if (newVal && lastHealthRec.id == newVal.id && this.live) {
                this.startLiveChartMonitor(newVal);
            } else {
                this.stopLiveChartMonitor();
                this.getHealthChart(newVal);
            }
        },
    },
    methods: {
        toggleAdvancedMetrics() {
            this.advancedMetrics = !this.advancedMetrics;
        },
        async getHealthRecords() {
            this.loading = true;

            try {
                let response;

                if (this.anonymous) {
                    response = await this.axios.get(
                        `${process.env.VUE_APP_API}/live/remote/get-health-records/${this.accessToken}`,
                    );
                } else {
                    response = await this.axiosInstance.get(`/live/get-health-records/${this.event.liveStreamId}`);
                }

                if (response.data) {
                    this.healthRecords = response.data.uptimes;
                    this.selectedRecord = this.healthRecords[this.healthRecords.length - 1];
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async getHealthChart(item) {
            if (!item) return;

            this.labels = [];
            this.inboundData.data = [];
            this.framerateData.data = [];
            this.keyframeData.data = [];
            this.simpleData.data = [];

            var start = item.started_at;
            var end = item.ended_at;
            if (item.ended_at == '0001-01-01T00:00:00') {
                end = this.$moment().utc().toISOString();
            }

            try {
                let response;

                if (this.anonymous) {
                    response = await this.axios.get(
                        `${process.env.VUE_APP_API}
                            /live/remote/get-health-chart/${this.accessToken}?from=${start}&to=${end}`,
                    );
                } else {
                    response = await this.axiosInstance.get(
                        `/live/get-health-chart/${this.event.liveStreamId}?from=${start}&to=${end}`,
                    );
                }

                if (response.data.live_stream) {
                    response.data.live_stream.trend.forEach(item => {
                        this.labels.push(this.$moment(item.created_at).format('LT'));

                        this.inboundData.data.push(Math.floor(item.bits_in_rate / 1000) / 1000);
                        this.simpleData.data.push(Math.floor(item.bits_in_rate / 1000) / 1000);
                        this.framerateData.data.push(item.frame_rate);
                        this.keyframeData.data.push(item.keyframe_interval);
                    });
                }

                this.datacollection = {
                    labels: this.labels,
                    datasets: [this.inboundData, this.framerateData, this.keyframeData],
                };

                this.simpleDataCollection = {
                    labels: this.labels,
                    datasets: [this.simpleData],
                };
            } catch (error) {
                console.log(error, 'error');
            } finally {
                this.loading = false;
                this.chartReady = true;
            }
        },
        async startLiveChartMonitor(item) {
            this.stopLiveChartMonitor();
            //Populates historical health data for live stream
            await this.getHealthChart(item);

            // //updates with new live health data
            // this.getLiveChart();

            this.monitorInterval = setInterval(() => {
                this.getLiveChart();
            }, 10000);
        },
        async getLiveChart() {
            try {
                let response;

                if (this.anonymous) {
                    response = await this.axios.get(
                        process.env.VUE_APP_API + `/live/remote/get-live-health-chart/${this.accessToken}`,
                    );
                } else {
                    response = await this.axiosInstance.get(`/live/get-live-health-chart/${this.event.liveStreamId}`);
                }

                if (!response.data) return;

                if (response.data.live_stream.connected.value !== 'Yes') {
                    this.stopLiveChartMonitor();
                }

                if (this.chartReady && response.data.live_stream) {
                    const bitRate = response.data.live_stream.bits_in_rate.value;
                    this.inboundData.data.push(Math.floor(bitRate / 1000));
                    this.simpleData.data.push(Math.floor(bitRate / 1000));

                    this.labels.push(this.$moment().format('LT'));

                    this.datacollection = {
                        labels: this.labels,
                        datasets: [this.inboundData, this.framerateData, this.keyframeData],
                    };
                    this.simpleDataCollection = {
                        labels: this.labels,
                        datasets: [this.simpleData],
                    };

                    // if (response.data.current.unique_views) {
                    //         this.$emit('viewerCountUpdate', response.data.current.unique_views.value);
                    //     }
                }
            } catch (error) {
                console.log(error, 'error');
            } finally {
                this.loading = false;
            }
        },
        stopLiveChartMonitor() {
            if (this.monitorInterval != null) {
                clearInterval(this.monitorInterval);
                this.monitorInterval = null;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        if (!this.anonymous) {
            await this.setAuthToken();
            this.createAxiosInstance();
        }

        await this.getHealthRecords();
    },
    beforeDestroy() {
        this.stopLiveChartMonitor();
    },
    components: {
        LineChart,
        Spinner,
    },
};
</script>

<style lang="scss" scoped></style>

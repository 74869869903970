<template>
    <div v-if="id">
        <custom-tooltip :tooltipProps="{ right: true }">
            <template v-slot:activator>
                <v-btn @click="syncPassareData" :disabled="isLoading" :loading="isLoading">
                    Sync with Passare <img src="@/assets/images/passare-square.png" class="passare-logo" />
                </v-btn>
            </template>

            <template v-slot:content>
                <span>MemoryShare syncs all services every night. Click here to get the latest services</span>
            </template>
        </custom-tooltip>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Services',
    },
    data() {
        return {
            token: '',
            id: null,
            passareToken: null,
            isSync: null,
            isLoading: false,
            status: '',
        };
    },
    async created() {
        await this.setAuthToken();
        this.getPassareData();
    },
    components: {
        CustomTooltip,
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getPassareData() {
            if (this.$auth.role.includes('FuneralHome')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Integration/Passare/settings/${this.$auth.funeralHomeId}`)
                    .then(({ data }) => {
                        this.id = data.passareOrganizationId;
                        this.passareToken = data.passareToken;
                        this.isSync = data.syncPassare;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        syncPassareData() {
            this.showSnackbar({ message: 'Sync in progress' });
            this.isLoading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Integration/Passare/${this.$auth.funeralHomeId}/sync`)
                .then(({ data }) => {
                    this.checkStatus(data.statusQueryGetUri);
                })
                .catch(error => {
                    console.error(error);
                    this.showSnackbar({
                        message:
                            'Error! Unable to sync Passare data. Please double check that your Passare credentials are correct.',
                    });
                });
        },
        checkStatus(url) {
            this.axios

                .get(url)
                .then(({ data }) => {
                    this.status = data.runtimeStatus;

                    if (this.status != 'Completed') {
                        setTimeout(() => {
                            this.checkStatus(url);
                        }, 3000);
                    } else {
                        this.$emit('sync-successful');
                        this.isLoading = false;
                        this.showSnackbar({ message: 'Sync Complete' });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
};
</script>

<style>
.passare-logo {
    height: 30px;
    margin-left: 15px;
}
</style>

<template>
    <div>
        <strong>Shipments</strong>
        <div v-if="totalShipments > 0">
            <div class="shipment" v-for="shipment in shipments">
                <v-row>
                    <v-col cols="12" md="6">
                        <div>
                            <div>
                                <v-menu attach offset-y v-if="shipment.status >= 0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            dark
                                            :color="shipmentStatuses[shipment.status].color"
                                            >{{ shipmentStatuses[shipment.status].label }}</v-chip
                                        >
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            @click="updateShipmentStatus(shipment.id, index)"
                                            v-for="(item, index) in shipmentStatuses"
                                            :key="index"
                                        >
                                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div>{{ $moment.utc(shipment.updatedDate).local().format('ll') }}</div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="">
                            <div>{{ carriers[shipment.carrier].label }}</div>
                            <div>{{ shipment.trackingNumber }}</div>
                        </div>
                    </v-col>
                </v-row>
                <div>
                    <v-data-table
                        hide-default-footer
                        :items="shipment.shipmentLineItems"
                        :headers="lineItemHeaders"
                    ></v-data-table>
                </div>
            </div>
        </div>
        <div v-else>No shipments have been created for this order.</div>
    </div>
</template>
<script>
import { carriers, shipmentStatuses } from '@/constants';
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
    data() {
        return {
            carriers,
            shipmentStatuses,
            token: '',
            shipments: [],
            totalShipments: 0,
            lineItemHeaders: [
                // { text: 'Line Item', value: 'id' },
                { text: 'Product', value: 'productName', sortable: false, width: '50%' },
                { text: 'Shipped Quantity', value: 'shipQuantity', sortable: false, width: '50%' },
            ],
        };
    },
    props: ['orderId'],
    methods: {
        ...mapActions(['showSnackbar']),
        updateShipmentStatus(id, status) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Shipping/update-shipment-status/${id}?status=${status}`)
                .then(response => {
                    this.showSnackbar({ message: 'Status Updated' });
                    this.getShipments();
                    this.$emit('refresh');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating status' });
                });
        },
        getShipments() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Shipping/get-shipments/${this.orderId}`)
                    .then(response => {
                        this.shipments = response.data.shipments;
                        this.totalShipments = response.data.total;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
    mounted() {
        this.getShipments();
    },
};
</script>
<style>
.shipment {
    margin-top: 20px;
}
</style>

<template>
    <section class="mt-10">
        <div class="p-3" style="display: flex; justify-content: space-between">
            <h5 :style="this.credits.length > 0 ? 'color: black' : 'color: white'">Credits</h5>
            <v-btn small outlined @click="openCreditTableModal" v-if="$auth.role.includes('SuperAdmin')"
                >Credit +</v-btn
            >
        </div>
        <!-- <div style="width: 300px" v-if="this.$auth.role.includes('SuperAdmin')">
            <v-text-field label="Search" v-model="search"></v-text-field>
        </div> -->

        <v-data-table
            :loading="loading"
            v-if="this.credits.length > 0"
            id="homeCreditTable"
            :headers="headers"
            :items="credits"
        >
            <template v-slot:item.creditTypeName="{ item }">
                <!-- <span>{{ item.creditTypeName }} + test</span> -->
                <span v-if="item.creditTypeId">
                    <v-chip dark :color="matchColor(item.creditTypeId)">
                        {{ item.creditTypeName }}
                    </v-chip>
                </span>
            </template>

            <template v-slot:item.amount="{ item }">
                <span>{{ item.amount | formatMoney }}</span>
            </template>
            <template v-slot:item.createDate="{ item }">
                <span>{{ item.createDate | dateFilter() }}</span>
            </template>

            <template v-slot:item.description="{ item }">
                <v-textarea v-if="item.isEditing" autofocus auto-grow rows="1" v-model="item.description"></v-textarea>
                <span v-else>{{ item.description }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <div v-if="item.isEditing" class="d-flex flex-column">
                    <v-btn class="mb-2" color="green" small dark @click="saveDescription(item.id)">Save</v-btn>
                    <v-btn @click="cancelEdit(item.id)" small>Cancel</v-btn>
                </div>
                <div v-else class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="editDescription(item.id)">
                                <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-pencil" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit Description</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span @click="showDeleteDialog(item)">
                                <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-trash-can" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete Item</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>

        <div v-if="totalCredit">
            <span>
                <p id="totalLabel">Credit Total: {{ totalCredit | formatMoney }}</p>
            </span>
        </div>

        <v-dialog max-width="400px" v-model="deleteDialog">
            <v-card justify="center">
                <v-card-title>Delete Credit?</v-card-title>
                <v-card-text class="text-center">
                    <p>Are you sure you want to delete this credit?</p>

                    {{ selectedCredit.description }}<br />
                    {{ selectedCredit.amount | formatMoney }}
                </v-card-text>
                <div class="p-3 text-center">
                    <v-btn @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn @click="deleteCredit" color="error" class="ml-3">Delete</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="applyCreditModal" max-width="600">
            <v-card>
                <v-card-title>Apply Credit</v-card-title>
                <v-card-text
                    >*Adding credits greater than their monthly subscription is not supported at this time.</v-card-text
                >

                <v-form class="p-3" ref="form" lazy-validation>
                    <v-textarea
                        :rules="[v => !!v || 'Description is required']"
                        v-model="creditDescription"
                        label="Description"
                    ></v-textarea>
                    <vuetify-money v-model="selectedCredit.suggestedAmount" :options="currencyOptions"></vuetify-money>
                    <div class="text-right">
                        <v-btn @click="applyCreditModal = false">Cancel</v-btn>
                        <v-btn @click="applyCredit" class="ml-3" color="primary">Apply Credit</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="creditTableModal" max-width="600">
            <v-card>
                <v-card-title>What type of credit would you like to apply?</v-card-title>
                <div class="ml-3 mr-3 pb-3">
                    <v-data-table @click:row="selectCredit" :headers="creditHeaders" :items="creditTypes">
                        <template v-slot:item.suggestedAmount="{ item }">
                            <span> {{ item.suggestedAmount | formatMoney }}</span>
                        </template>
                    </v-data-table>
                    <v-btn @click="creditTableModal = false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            credits: [],
            options: {},
            headers: [
                // { text: 'Date', value: 'createDate' },
                // { text: 'Issued By', value: 'createdBy' },
                // { text: 'Type', value: 'creditTypeName' },
                // { text: 'Amount', value: 'amount' },
                // { text: 'Description', value: 'description' },
                // { text: 'Actions', value: 'actions' },
            ],
            deleteDialog: false,
            selectedCredit: '',
            totalCredit: null,
            creditTableModal: false,
            applyCreditModal: false,
            selectedCredit: '',
            creditDescription: '',
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            creditTypes: [],
            creditHeaders: [
                { text: 'Name', value: 'name', align: 'center' },
                { text: 'Suggested Amount', value: 'suggestedAmount', align: 'center' },
            ],
            creditTableLength: 0,
            search: '',
            loading: true,
            creditColors: [
                'teal',
                'blue',
                'orange',
                'green',
                'cyan',
                'purple',
                '#D25216',
                '#975DBE',
                '#CB912F',
                'gray',
            ],
        };
    },
    components: { CustomTooltip },
    props: ['token', 'funeralHomeId'],
    filters: {
        dateFilter(value) {
            return moment(value).format('l');
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),

        matchColor(value) {
            const x = this.creditTypes.find(item => item.id === value);
            return x.color;
        },
        assignColor() {
            for (let i = 0; i < this.creditTypes.length; i++) {
                this.creditTypes[i].color = this.creditColors[i];
            }
        },
        applyCredit() {
            let data = {
                amount: parseInt(this.selectedCredit.suggestedAmount * 100),
                description: this.creditDescription,
                creditType: this.selectedCredit.id,
            };

            let valid = this.$refs.form.validate();

            if (valid)
                this.axiosInstance
                    .post(`/credit/apply/${this.funeralHomeId}`, data)
                    .then(response => {
                        this.showSnackbar({ message: 'Credit applied' });
                        this.applyCreditModal = false;
                        this.getHomeCredits();
                        this.creditDescription = '';
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error applying credit' });
                    });
        },
        selectCredit(item) {
            this.selectedCredit = { ...item, suggestedAmount: item.suggestedAmount / 100 };
            this.creditTableModal = false;
            this.applyCreditModal = true;
        },
        getCreditTypes() {
            this.axiosInstance.get(`/Credit/type`).then(response => {
                this.creditTypes = response.data.creditTypes.map(obj => ({
                    ...obj,
                }));
                this.assignColor();
                this.getHomeCredits();
                // this.test();
            });
        },
        openCreditTableModal() {
            this.creditTableModal = true;
            // this.getCreditTypes();
        },
        getCreditTotal() {
            let total = 0;
            for (let i = 0; i < this.credits.length; i++) {
                total += this.credits[i].amount;
            }
            this.totalCredit = total;
        },
        deleteCredit(id) {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + `/Credit/${this.selectedCredit.id}`)
                    .then(response => {
                        this.showSnackbar({ message: 'Credit deleted' });
                        this.deleteDialog = false;
                        this.getHomeCredits();
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error deleting credit' });
                    });
            }
        },
        showDeleteDialog(item) {
            this.selectedCredit = item;
            this.deleteDialog = true;
        },
        saveDescription(id) {
            const creditToUpdate = this.credits.find(item => item.id === id);
            if (creditToUpdate.description === creditToUpdate.prevDescription) {
                creditToUpdate.isEditing = false;
                return;
            }
            const payload = {
                ...creditToUpdate,
            };

            delete payload.isEditing;
            delete payload.prevDescription;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Credit/${id}`, payload)
                .then(response => {
                    this.showSnackbar({ message: 'Credit updated' });
                    creditToUpdate.isEditing = false;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating credit' });
                });
        },
        cancelEdit(id) {
            const creditToEdit = this.credits.find(item => item.id === id);
            creditToEdit.description = creditToEdit.prevDescription;
            creditToEdit.isEditing = false;
        },
        editDescription(id) {
            const creditToEdit = this.credits.find(item => item.id === id);
            creditToEdit.prevDescription = creditToEdit.description;
            creditToEdit.isEditing = true;
        },
        getHomeCredits() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(
                    // process.env.VUE_APP_API + `/credit/get-all/${this.funeralHomeId}`,
                    process.env.VUE_APP_API + `/credit/get-current/${this.funeralHomeId}`,
                    // ?pageSize=${itemsPerPage}&pageNumber=${
                    //     page ? page - 1 : 1
                    // }
                )
                .then(response => {
                    this.credits = response.data.credits.map(obj => ({
                        ...obj,
                        isEditing: false,
                    }));
                    this.creditTableLength = response.data.total;
                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.headers = [
                            { text: 'Date', value: 'createDate' },
                            { text: 'Issued By', value: 'createdBy' },
                            { text: 'Type', value: 'creditTypeName' },
                            { text: 'Amount', value: 'amount' },
                            { text: 'Description', value: 'description' },
                            { text: 'Actions', value: 'actions', align: 'center' },
                        ];
                    } else {
                        this.headers = [
                            { text: 'Date', value: 'createDate' },
                            { text: 'Issued By', value: 'createdBy' },
                            { text: 'Type', value: 'creditTypeName' },
                            { text: 'Amount', value: 'amount' },
                            { text: 'Description', value: 'description' },
                        ];
                    }
                    this.loading = false;

                    this.getCreditTotal();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    mounted() {
        this.createAxiosInstance();
        this.getCreditTypes();
        // this.getHomeCredits();
    },
};
</script>

<style lang="scss">
.current-invoice-title {
    display: flex;
    justify-content: space-between;
}
#homeCreditTable {
    min-height: 0px;

    .action-list {
        display: flex;
        gap: 10px;
        justify-content: center;
    }
}

#totalLabel {
    margin-top: -45px;
    border: 2px solid cornflowerblue;
    width: 25%;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
}
</style>

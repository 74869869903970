<template>
    <div class="onboard-upload-action-container">
        <div class="action-title text-left">
            <h4>Computer Upload</h4>
            <span>Upload photos from your computer to continue.</span>
        </div>

        <div class="action-inner">
            <photo-uploader-v2
                class="onboarding-uploader"
                v-if="event && service"
                :ref="uploaderId"
                :uploaderId="uploaderId"
                :eventId="event.id"
                :service="service"
                :customCta="true"
                handleFilesInParent
                @current-files="files => $emit('current-files', files)"
                @upload-start="files => $emit('upload-start', files)"
                @upload-success="remaining => this.$emit('upload-success', remaining)"
                @progress="progress => this.$emit('upload-progress', progress)"
                @upload-cancelled="handleUploadCancelled('onboardingUploader')"
                @all-uploads-complete="$emit('all-uploads-complete')"
            >
                <template v-slot>
                    <div class="uploader-inner">
                        <div class="action-inner-upper">
                            <div>
                                <font-awesome-icon
                                    style="font-size: 5rem"
                                    icon="fa-regular fa-cloud-arrow-up"
                                ></font-awesome-icon>
                            </div>
                            <h5 class="font-weight-bold">Drag and Drop Media</h5>
                        </div>
                        <div class="btn-wrap">
                            <v-btn depressed color="orange" dark @click="handleUppyOpen(uploaderId)">Upload</v-btn>
                        </div>
                    </div>
                </template>
            </photo-uploader-v2>
        </div>
    </div>
</template>
<script>
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
    data() {
        return {
            uploaderId: uuidv4(),
        };
    },
    components: {
        PhotoUploaderV2,
    },
    props: {
        event: {
            type: Object,
            default: () => {},
        },
        service: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        handleUppyOpen(ref) {
            const uploader = this.$refs[ref];
            if (uploader) {
                uploader.openFileSelection();
            }
        },
        handleUploadCancelled(ref) {
            const uploader = this.$refs[ref];
            if (uploader) {
                uploader.reset();
            }
        },
    },
};
</script>
<style lang="scss">
.onboard-upload-action-container {
    min-height: 550px;
    background-color: #f8f8f8;
    padding: 32px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    justify-content: space-between;
    height: 100%;

    .action-inner {
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 350px;
    }

    .action-inner-upper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .uploader-inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 16px;
    }

    .onboarding-uploader {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    #v2-uploader-container {
        display: flex;
        width: 100%;
    }

    .v2-uploader {
        flex-grow: 1;
        display: flex;
    }
}
</style>

<template lang="">
    <div>
        <branded-modal @close="modalVisible = false" v-model="modalVisible">
            <template v-slot:title>Invite Access</template>
            <template v-slot:body>
                <div class="text-center pb-3">
                    <v-btn-toggle
                        v-if="$route.name != 'TributeUploadPage'"
                        active-class="active-toggle-btn"
                        dense
                        v-model="linkType"
                        mandatory
                        rounded
                    >
                        <v-btn :disabled="$route.name == 'TributeUploadPage'" value="family" class="family-sel" small
                            >Family</v-btn
                        >
                        <v-btn value="contributor" class="general-sel" small>Contributor</v-btn>
                    </v-btn-toggle>
                </div>

                <v-alert
                    style="margin-top: 10px"
                    class="text-center"
                    dense
                    color="#ab0658"
                    text
                    outlined
                    v-if="linkType == 'family'"
                >
                    <p>
                        <strong>Allows uploading photos, deleting photos, choosing music and a theme</strong>
                    </p>
                    <div>*Recommended for next of kin</div>
                </v-alert>
                <v-alert style="margin-top: 10px" class="text-center" dense color="#0c3c60" text outlined v-else>
                    <p><strong>Only allows uploading photos</strong></p>
                    <div>*Recommended for extended family and friends</div>
                </v-alert>

                <div class="text-center">
                    <div class="btn-wrap">
                        <v-btn depressed :loading="loading" :disabled="loading" @click="downloadPdf">
                            Print
                            <font-awesome-icon class="ml-2" icon="fa-regular fa-print" />
                        </v-btn>
                    </div>
                </div>
            </template>
        </branded-modal>

        <!-- tribute qr code pdf printout  -->
        <qr-printout
            v-if="tributeVideo.deeplink && tributeVideo.familyLink"
            title="Tribute Video"
            :key="printoutRefreshKey"
            :service="service"
            :link="linkType == 'family' ? tributeVideo.familyLink : tributeVideo.deeplink"
            ref="qrPrint"
            @loading="val => (loading = val)"
        />
        <!-- tribute qr code pdf printout  -->
    </div>
</template>
<script>
import BrandedModal from '@/components/ui/BrandedModal.vue';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';

export default {
    data() {
        return {
            linkType: 'family',
            printoutRefreshKey: 0,
            loading: false,
        };
    },
    components: {
        BrandedModal,
        QrPrintout,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        service: {
            type: Object,
            default: () => {},
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        modalVisible: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        linkType(newVal) {
            this.printoutRefreshKey++;
        },
    },
    methods: {
        closeModal() {
            this.$emit('input', false);
        },
        downloadPdf() {
            const printout = this.$refs.qrPrint;

            if (!printout || typeof printout.generatePdf != 'function') return;

            printout.generatePdf();
        },
    },
};
</script>
<style lang=""></style>

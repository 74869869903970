<template>
    <div>
        <!-- PDF Layout -->
        <vue-html2pdf
            class="qr-pdf-wrapper"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :filename="`Tribute Upload intstructions for ${service.firstName} ${service.lastName}`"
            :pdf-quality="2"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="795px"
            :manual-pagination="true"
            ref="html2Pdf"
            id="vue-html-pdf"
        >
            <section id="pdf-wrapper-qr" slot="pdf-content">
                <div
                    class="py-5"
                    style="display: flex; flex-direction: column; justify-content: space-between; height: 1120px"
                >
                    <h1 class="px-5 text-center">{{ title }}</h1>
                    <div class="px-5 pdf-instructions">
                        <div class="instruction-group">
                            <h4 style="color: #ab0658">Overview</h4>
                            <p>
                                {{ service.funeralHomeName }} and MemoryShare have come together to create a lasting
                                tribute for {{ service.firstName }} - a montage depicting their life. The special video
                                will be shown during the service and available on the obituary page so that loved ones
                                can cherish these memories forever. All you need to do is upload or scan photos taken
                                throughout {{ service.firstName }}'s life with the Time Capsule app.
                            </p>
                        </div>
                        <div class="instruction-group">
                            <h4 style="color: #ab0658">How It Works</h4>
                            <p>
                                Share your memories using your iPhone or iPad - just scan the QR code printed below to
                                get started. Downloading and running this app will give you all the instructions needed
                                for scanning, uploading, and recording photos and videos.
                            </p>
                        </div>
                        <!-- <div class="instruction-group">
                            <h4 style="color: #ab0658">I Don't Have an iPhone or iPad</h4>
                            <p>
                                The scanning functionality is only available for iPhones and iPads. However, you can
                                still upload from an android device. Scanning the QR code above will bring you to a
                                webpage where you can upload photos. You can also scan photos on an android phone by
                                using the Google Photo Scanner app. If you'd like to upload photos from a computer you
                                can do so at the link below {{ tributeVideo.deeplink }}
                            </p>
                        </div> -->
                    </div>
                    <div class="p-5 qr-design-wrap">
                        <div
                            style="
                                border: 20px solid #ab0658;
                                background-color: #ab0658;
                                position: relative;
                                width: 80%;
                                border-radius: 20px;
                                height: 250px;
                            "
                        >
                            <v-avatar
                                style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto"
                                size="300"
                                color="#ab0658"
                            >
                                <v-avatar size="250" color="#FFFFFF">
                                    <div
                                        class="pt-3"
                                        style="
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                        "
                                    >
                                        <qrcode-vue
                                            v-if="link"
                                            style="width: 135px; height: 135px"
                                            :value="link"
                                            size="130"
                                            render-as="svg"
                                        />
                                        <p style="color: gray" class="text-center">Scan to Get Started</p>
                                    </div>
                                </v-avatar>
                            </v-avatar>
                        </div>
                    </div>

                    <div class="px-5 pdf-instructions">
                        <div class="instruction-group">
                            <h4 style="color: #ab0658">I Don't Have an iPhone or iPad</h4>
                            <p>
                                The scanning functionality is only available on iPhones and iPads, but you can still
                                upload photos to the tribute video from an android device. If you would like to scan a
                                photo through an Android, we recommend using the app Google Photo Scanner. If you have
                                an android device, you can upload photos through your web browser by scanning the above
                                QR code or by visiting <strong>{{ link }}</strong
                                >.
                            </p>
                        </div>
                    </div>

                    <div>
                        <div style="height: 40px; display: flex; justify-content: center; align-items: center">
                            <img :src="logoIconSrc" alt="MemoryShare Icon" />
                            <img class="ml-2 p-2" :src="logoTextSrc" alt="MemoryShare Text" />
                        </div>
                        <p style="color: gray" class="text-center mt-2">
                            Copyright MemoryShare 2023. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </section></vue-html2pdf
        >
        <!-- Share PDF Modal -->
    </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
import logoTextSrc from '@/assets/images/logo_text.png';
import logoIconSrc from '@/assets/images/logo.png';
import QrcodeVue from 'qrcode.vue';
export default {
    data() {
        return {
            logoTextSrc: logoTextSrc,
            logoIconSrc: logoIconSrc,
        };
    },
    components: {
        VueHtml2pdf,
        QrcodeVue,
    },
    props: {
        service: {
            type: Object,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    methods: {
        async generatePdf() {
            const generator = this.$refs.html2Pdf;
            if (!generator || typeof generator.generatePdf != 'function') return;

            this.$emit('loading', true);
            await generator.generatePdf();
            this.$emit('loading', false);
        },
    },
    mounted() {},
};
</script>
<style lang="scss">
.pdf-instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .instruction-group {
        width: 80%;
    }
}

.qr-design-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

// //hiding html2pdf float background
// #vue-html-pdf section.layout-container {
//     opacity: 0;
// }

// // overwriting preview location
// #vue-html-pdf section.pdf-preview {
//     position: fixed;
//     top: 0;
//     // bottom: 0;
//     // margin: auto;
// }
// #vue-html-pdf {
//     border: 2px solid pink;
// }
</style>

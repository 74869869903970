<template>
    <header class="ms-main-header">
        <font-awesome-icon icon="fa-solid fa-bars" @click="$emit('toggleMenu')" class="menu-toggle-button" size="lg" />
        <v-chip
            v-if="
                $auth.impersonating &&
                ($auth['user']['https://memoryshare.com/roles'].includes('SuperAdmin') ||
                    $auth['user']['https://memoryshare.com/roles'].includes('Owner'))
            "
            class="ml-4"
            color="primary"
            outlined
            ><font-awesome-icon class="p-1" icon="fa-solid fa-user" /> {{ $store.state.impersonatingName }}</v-chip
        >

        <span style="flex: 1"></span>
        <v-btn class="token-button" plain @click="copyTokenToClipboard()" v-if="shouldDisplayToken">TOKEN</v-btn>
        <div v-if="this.$store.state.streaming">
            <router-link :to="{ name: 'LiveDashboard' }" class="live-container">
                <div class="blob"></div>
                <span class="mr-3">LIVE</span>
            </router-link>
        </div>

        <div v-if="$auth.impersonating" @click="returnToAdmin" class="d-inline-block mr-8">
            <p class="pointer mb-0" style="font-size: 0.9rem">
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                <span>Back to {{ $auth.user.name }}</span>
            </p>
            <small
                style="position: absolute; margin-left: 1.9rem"
                v-if="$auth['user']['https://memoryshare.com/roles'].includes('SuperAdmin')"
                >Funeral Home Id: {{ $auth.funeralHomeId }}</small
            >
        </div>
        <span class="header-username" v-else>Hello, {{ $store.state.loggedInUser }}</span>
        <template>
            <div class="d-inline-block ml-4" style="z-index: 1001">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <font-awesome-icon icon="fa-regular fa-circle-user" size="2xl" />
                        </span>
                    </template>
                    <v-list class="dropdown">
                        <v-list-item
                            v-if="!($auth.role.includes('SuperAdmin') || $auth.role.includes('RegionalManager'))"
                            @click="goToSettings"
                        >
                            <font-awesome-icon icon="fa-regular fa-gear" class="mr-4" />
                            <span>Settings</span>
                        </v-list-item>
                        <v-list-item
                            v-if="$auth.role.includes('FuneralHome') || $auth.role.includes('Supplier')"
                            @click="getUsers"
                        >
                            <font-awesome-icon icon="fa-regular fa-circle-user" class="mr-4" />
                            <span>Manage Users</span>
                        </v-list-item>
                        <v-list-item
                            v-if="
                                !$auth.role.includes('SuperAdmin') &&
                                !$auth.role.includes('Supplier') &&
                                !this.$auth.impersonating
                            "
                            @click="getEditInfo"
                        >
                            <font-awesome-icon icon="fa-regular fa-circle-user" class="mr-4" />
                            <span>Edit Profile</span>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="mr-4" />
                            <span>Logout</span>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
        <div style="position: absolute">
            <UsersTable ref="usersTable"></UsersTable>
            <EditProfileTable ref="EditProfileTable"></EditProfileTable>
        </div>
    </header>
</template>
<script>
import UsersTable from '@/components/Tables/UsersTable.vue';
import EditProfileTable from '@/components/Tables/EditProfileTable.vue';
import { mapActions } from 'vuex';
export default {
    data: function () {
        return {};
    },
    components: {
        UsersTable,
        EditProfileTable,
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyTokenToClipboard() {
            this.$auth.getIdTokenClaims().then(result => {
                const el = document.createElement('textarea');
                el.value = 'Bearer ' + result.__raw;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.showSnackbar({ message: 'Token copied to clipboard' });
            });
        },
        goToSettings() {
            if (this.$auth.role.includes('Owner')) {
                let owner_id = this.$auth.user['https://memoryshare.com/owner_id'];
                this.$router.push(`/owner-settings/${owner_id}?tab=details`);
            } else if (this.$auth.role.includes('Supplier')) {
                this.$router.push(`/suppliers/edit/${this.$auth.session.supplier.id}`);
            } else {
                this.$router.push('/settings');
            }
        },
        getEditInfo() {
            this.$refs.EditProfileTable.openDialog = true;
            this.$refs.EditProfileTable.getUsers(true);
        },
        getUsers() {
            this.$refs.usersTable.openDialog = true;
            this.$refs.usersTable.getUsers(true);
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
        returnToAdmin() {
            this.$auth.getIdTokenClaims().then(result => {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${result.__raw}` } })
                    .post(process.env.VUE_APP_API + '/superadmin/impersonate', {
                        funeralHomeId: 0,
                        impersonate: false,
                    })
                    .then(response => {
                        this.$store.dispatch('updateImpersonatingName', '');
                        this.$router.push({ name: 'Services' });
                        this.$router.go(0);
                    });
            });
        },
        checkLive() {
            this.$auth.getIdTokenClaims().then(result => {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${result.__raw}` } })
                    .get(process.env.VUE_APP_API + '/live/get-live-count')
                    .then(response => {
                        if (response.data > 0) {
                            this.$store.dispatch('toggleStreaming', true);
                        } else {
                            this.$store.dispatch('toggleStreaming', false);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
    computed: {
        shouldDisplayToken() {
            return (
                this.$auth.role.includes('SuperAdmin') ||
                this.$auth.impersonating === true ||
                window.location.hostname === 'develop.memoryshare.com' ||
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            );
        },
    },
    mounted() {
        this.checkLive();
    },
};
</script>
<style lang="scss">
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .token-button,
    .header-username {
        display: none;
    }
}
</style>

<template>
    <div>
        <div class="connection-entry" @click="copyText(connection.primaryServer)" v-if="connection.primaryServer">
            <span class="entry-title">{{ streamingProvider === 5 ? 'RTSP' : 'RTMP' }} (Server)</span>
            <div class="content">
                {{ connection.primaryServer }}
                <v-icon slot="append" class="icon">mdi-clipboard</v-icon>
            </div>
        </div>

        <div class="connection-entry" @click="copyText(connection.streamName)" v-if="connection.streamName">
            <span class="entry-title">Stream Key</span>
            <div class="content">
                {{ connection.streamName }}
                <v-icon slot="append" class="icon">mdi-clipboard</v-icon>
            </div>
        </div>

        <div class="connection-entry" @click="copyText(connection.userName)" v-if="connection.userName">
            <span class="entry-title">Username</span>
            <div class="content">
                {{ connection.userName }}
                <v-icon slot="append" class="icon">mdi-clipboard</v-icon>
            </div>
        </div>

        <div class="connection-entry" @click="copyText(connection.password)" v-if="connection.password">
            <span class="entry-title">Password</span>
            <div class="content">
                {{ connection.password }}
                <v-icon slot="append" class="icon">mdi-clipboard</v-icon>
            </div>
        </div>

        <!-- when shane gives 'Atem' enum continue ticket -->

        <!-- UNKOWN COMPONENT NEED TO DO RESEARCH
        <Output>
            <LiveStream serviceName="YOUR -NAME-HERE" url="RTMP URL" key="STREAMKEY" lowLatency="False">
                <Credentials username="USERNAME" password="PASSWORD" />
                <bitrate low="6000000" high="9000000" />
                <audioBitrate low="128000" high="128000" />
            </LiveStream>
            <Record filename="Untitled" recordInAllCameras="False" />
        </Output>

        -->
        <!-- START: FACEBOOK WARNING DIALOG -->
        <v-dialog v-model="warningFacebookSettings" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">
                    {{ warningFacebookSettingsDialogTitle }}
                </v-card-title>
                <v-card-text>
                    <p>{{ wrongSettingsMessage }}</p>
                    <p class="mb-1">Facebook RTMPS</p>
                    <p>Facebook Stream Key</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="warningFacebookSettings = false"> Close </v-btn>
                    <v-btn :to="{ name: 'Settings' }" color="primary" text> Go to settings </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END: FACEBOOK WARNING DIALOG -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ConnectionFields',
    props: {
        connection: {
            type: Object,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        streamingProvider: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            facebookLiveStreamButtonText: 'Facebook Live Stream',
            facebookLiveStreamButtonType: 'primary',
            warningFacebookSettings: false,
            warningFacebookSettingsDialogTitle: null,
            facebookStreamStarted: false,
            wrongSettingsMessage: null,
            token: null,
        };
    },
    computed: {
        apiLink() {
            return process.env.VUE_APP_API;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        /**
         * Copies Text To Clipboard
         * @param text - text to be copied
         */
        copyText(text) {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.showSnackbar({ message: 'Copied to clipboard!' });
        },

        startFacebookLiveStream() {
            let facebookSettings = this.getUserSettings();

            facebookSettings.then(res => {
                if (!res.facebookRtmps || !res.facebookStreamKey) {
                    this.warningFacebookSettings = true;
                    this.warningFacebookSettingsDialogTitle = 'Missing General Settings';
                    this.wrongSettingsMessage =
                        'You must fill the following values from settings before you can go live on facebook.';
                    return;
                }
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/live/facebook?id=${this.event.liveStreamId}`)
                    .then(response => {
                        this.warningFacebookSettings = false;
                        this.facebookStreamStarted = true;
                        this.facebookLiveStreamButtonText = 'Facebook Live Stream Started';
                        this.facebookLiveStreamButtonType = 'success';
                    })
                    .catch(error => {
                        this.warningFacebookSettings = true;
                        this.warningFacebookSettingsDialogTitle = 'Wrong General Settings';
                        this.wrongSettingsMessage = error.response.data;
                    });
            });
        },
        getUserSettings() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/admin/${this.$auth.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                this.$emit('message', error.response.data);
                                reject(error);
                            });
                    } else {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/funeralhomes/settings/${this.$auth.funeralHomeId}`)
                            .then(response => {
                                if (response.data) {
                                    resolve(response.data);
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.connection-entry {
    width: 100%;
    position: relative;
    cursor: pointer;
    margin: 1rem 0;

    .entry-title {
        size: 0.75rem;
        color: gray;
    }

    .content {
        background: rgb(231, 231, 231);
        padding: 0.5rem;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 3rem;
    }
    .icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
    }
}
</style>

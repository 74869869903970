<template>
    <div>
        <v-card style="border: 2px solid cornflowerblue" class="mt-12 pb-3 pl-3 pr-3 m-3" elevation="2">
            <v-card
                v-if="this.account.owner"
                class="ml-5"
                style="max-width: 25%; margin-top: -30px; border: 2px solid cornflowerblue"
            >
                <v-card-title class="p-2 justify-center">{{ this.account.owner }}</v-card-title>
            </v-card>
            <v-row class="mt-3 text-center">
                <!-- <v-col>
                    <div>
                        <h3><u>Account Manager</u></h3>

                        <div>
                            <h5 v-if="account.accountManager">{{ account.accountManager }}</h5>
                            <h5 v-else>N/A</h5>
                        </div>
                    </div>
                </v-col> -->
                <v-col>
                    <div>
                        <h3><u>Last Service.</u></h3>

                        <div>
                            <h5 v-if="lastStream">{{ lastStream | moment('l') }}</h5>
                            <h5 v-else>N/A</h5>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div>
                        <h3><u>Calls Per Year</u></h3>
                        <div>
                            <h5>{{ account.callsPerYear }}</h5>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <h3><u>Subscribed On</u></h3>
                    <h5 v-if="account.subscribedOnDate">{{ account.subscribedOnDate | moment('l') }}</h5>
                </v-col>
            </v-row>
        </v-card>

        <div>
            <div v-if="chartMounted">
                <line-chart ref="usageChart" :chart-data="datacollection" :options="options"></line-chart>
            </div>

            <div v-else>
                <v-progress-linear indeterminate></v-progress-linear>
                <p class="text-center">Loading Chart..</p>
                <line-chart :chart-data="emptyData" :options="secondaryOptions"></line-chart>
            </div>
        </div>

        <v-dialog v-model="goalModal" max-width="400px">
            <v-card class="p-3">
                <h4>Edit Goal</h4>
                <v-text-field @keydown.enter="updateGoal" v-model="tempGoal" type="number"></v-text-field>
                <v-btn color="primary" @click="updateGoal">Update</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AccountHistoryTable from '@/components/Tables/Admin/AccountHistoryTable.vue';
import LineChart from '@/components/charts/LineChart';

var annotations = {
    drawTime: 'afterDatasetsDraw',
    events: ['click', 'mouseout', 'over', 'mouseenter'],
    annotations: [
        {
            id: '1',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: null,
            borderColor: '#61affe',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Goal',
                fontSize: 10,
            },
        },
    ],
};

export default {
    data() {
        return {
            lastStream: null,
            goalModal: false,
            tempGoal: null,
            account: {
                salesRep: '',
                salesRepId: '',
                salesRepEmail: '',
                accountManagerId: '',
                accountManagerEmail: '',
                createDate: '',
                goalStreams: '',
                callsPerYear: 0,
                owner: '',
            },
            tempValueCalls: '',
            tempValueManager: '',
            tempValueRep: '',
            editSalesRep: false,
            editManager: false,
            editCalls: false,
            admins: [],
            hubspot: {
                hubspotCompanyID: '',
            },
            datacollection: {
                labels: [],
                datasets: [
                    { label: 'Usage', data: [], backgroundColor: 'rgba(0,0,0,0)', borderColor: 'rgba(255,83,13,.7)' },
                ],
            },
            emptyData: {
                labels: [],
                datasets: [],
            },
            chartMounted: false,
            options: {
                scales: {
                    yAxes: [
                        {
                            id: 'simple-y-axis',
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            },
                        },
                    ],
                },
                legend: {
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                    onLeave: function (e) {
                        e.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: annotations,
            },
            secondaryOptions: {
                scales: {
                    yAxes: [
                        {
                            id: 'simple-y-axis',
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                                max: 100,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            memories: false,
            disableMem: true,
        };
    },
    components: {
        AccountHistoryTable,
        LineChart,
    },
    props: ['token', 'funeralHomeId', 'funeralHomeName'],
    watch: {
        memories() {
            if (!this.disableMem) {
                this.setTreasuredMem();
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        toggleDisabledMem() {
            this.disableMem = !this.disableMem;
        },
        setTreasuredMem() {
            this.disableMem = true;
            this.axiosInstance
                .put(`/funeralhomes/treasured-memories/${this.funeralHomeId}?treasuredMemories=${this.memories}`)
                .then(response => {
                    this.showSnackbar({ message: 'Updated treasured memories connection' });
                    this.disableMem = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating treasured memories connection' });
                    this.disableMem = false;
                });
        },
        getTreasuredMem() {
            this.axiosInstance
                .get(`/funeralhomes/treasured-memories/${this.funeralHomeId}`)
                .then(response => {
                    this.memories = response.data.treasuredMemories;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openGoalModal() {
            this.tempGoal = this.goalStreams;
            this.goalModal = true;
        },
        updateGoal() {
            this.goalModal = false;
            if (this.tempGoal != this.goalStreams) {
                this.chartMounted = false;
                this.axiosInstance
                    .put(`/account-managment/goal-streams/${this.funeralHomeId}?GoalStreams=${this.tempGoal}`)
                    .then(response => {
                        this.goalStreams = this.tempGoal;
                        this.options.annotation.annotations[0].value = this.goalStreams;
                        this.tempGoal = null;
                        this.getChartData();
                        this.showSnackbar({ message: 'Goal streams updated' });
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error updating goal streams' });
                    });
            } else {
                this.goalModal = false;
                return;
            }
        },
        getChartData() {
            this.datacollection.labels = [];
            this.datacollection.datasets[0].data = [];
            this.chartMounted = false;
            this.axiosInstance
                .get(`/account-managment/get-account-usage-chart/${this.funeralHomeId}`)
                .then(response => {
                    let eventCounts = [];
                    response.data.forEach(item => {
                        eventCounts.push(item.eventCounts);
                    });

                    this.datacollection.datasets[0].data = eventCounts;

                    response.data.forEach(item => {
                        this.datacollection.labels.push(item.month);
                    });
                    this.datacollection.labels.reverse();
                    this.datacollection.datasets[0].data.reverse();
                })
                .finally(response => {
                    this.chartMounted = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editingCalls() {
            this.editCalls = !this.editCalls;
            this.tempValueCalls = this.account.callsPerYear;
        },
        editingManager() {
            this.editManager = !this.editManager;
            this.tempValueManager = this.account.accountManagerId;
        },
        editingRep() {
            this.editSalesRep = !this.editSalesRep;
            this.tempValueRep = this.account.salesRepId;
        },
        updateCalls() {
            this.account.callsPerYear = this.tempValueCalls;
            this.axiosInstance
                .post(
                    `/account-managment/calls-per-year?funeralHomeId=${this.funeralHomeId}&calls=${this.account.callsPerYear}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated calls per year' });
                    this.editCalls = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating calls per year' });
                });
        },
        updateManager() {
            this.account.accountManagerId = this.tempValueManager;
            this.axiosInstance
                .post(
                    `/account-managment/account-manager?funeralHomeId=${this.funeralHomeId}&repId=${this.account.accountManagerId}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated account manager' });
                    this.editManager = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating account manager' });
                });
        },
        updateRep() {
            this.account.salesRepId = this.tempValueRep;
            this.axiosInstance
                .post(
                    `/account-managment/sales-rep?funeralHomeId=${this.funeralHomeId}&repId=${this.account.salesRepId}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated sales rep' });
                    this.editSalesRep = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating sales rep' });
                });
        },
        getAdmins() {
            this.axiosInstance
                .get('dropdown/superadmin')
                .then(response => {
                    this.admins = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error loading account managers' });
                });
        },
        updateAccount() {
            this.editingAccount = false;
            this.showSnackbar({ message: 'This feature is not ready yet' });
        },
        editAccount() {
            this.editingAccount = !this.editingAccount;
        },
        getGoalStreams() {
            this.axiosInstance
                .get(`account-managment/goal-streams/${this.funeralHomeId}`)
                .then(response => {
                    this.goalStreams = response.data.goalStreams;
                    this.options.annotation.annotations[0].value = this.goalStreams;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLastStream() {
            this.axiosInstance
                .get(`FuneralHomes/${this.funeralHomeId}`)
                .then(response => {
                    this.lastStream = response.data.funeralHome.lastEventTime;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error loading account data' });
                    console.log(error);
                });
        },
        getAcctManagementData() {
            this.axiosInstance
                .get(`account-managment/table/${this.funeralHomeId}`)
                .then(response => {
                    this.account = response.data;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error loading account data' });
                    console.log(error);
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        updateHubspot() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(this.hubspotEndpoint, this.hubspot)
                .then(({ data }) => {
                    this.showSnackbar({ message: 'Hubspot Company ID successfully updated!' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating Hubspot Company ID! Please try again.' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getIntegrations() {
            this.loading = true;
            const instance = this.axios.create({ headers: { Authorization: `Bearer ${this.token}` } });
            // const passareRequest = instance.get(this.passareEndpoint + `/${this.funeralHomeId}`);
            // const facebookRequest = instance.get(this.facebookEndpoint);
            // const zapierRequest = instance.get(this.zapierEndpoint + `/apikey`);
            const hubspotRequest = instance.get(this.hubspotEndpoint);
            // const tukiosRequest = instance.get(this.tukiosEndpoint + `/apikey`);
            const requests = [hubspotRequest];
            if (this.$auth.role.includes('SuperAdmin')) {
                // const hubspotRequest = instance.get(this.hubspotEndpoint);
                // requests.push(hubspotRequest);
            }

            Promise.allSettled(requests).then(([hubspotRes]) => {
                // Handle Passare response
                // if (passareRes.status === 'fulfilled') {
                //     const { passareOrganizationId, passareToken, syncPassare } = passareRes.value.data;
                //     this.passare.passareOrganizationId = passareOrganizationId;
                //     this.passare.passareToken = passareToken;
                //     this.passare.syncPassare = syncPassare;
                // } else {
                //     this.showSnackbar({ message: `There was an error retrieving your Passare settings.` });
                // }

                // Handle Facebook response
                // if (facebookRes.status === 'fulfilled') {
                //     const { facebookRtmps, facebookStreamKey } = facebookRes.value.data;
                //     this.facebook.facebookRtmps = facebookRtmps;
                //     this.facebook.facebookStreamKey = facebookStreamKey;
                // } else {
                //     this.showSnackbar({ message: 'There was an error retrieving your Facebook settings.' });
                // }

                // Handle Zapier response
                // if (zapierRes.status === 'fulfilled') {
                //     this.zapier.api_key = zapierRes.value.data.api_key;
                // } else {
                //     this.showSnackbar({ message: 'There was an error retrieving your Zapier settings.' });
                // }

                // Handle Hubspot response
                if (hubspotRes.status === 'fulfilled') {
                    this.hubspot.hubspotCompanyID = hubspotRes.value.data.hubspotCompanyID;
                } else {
                    this.showSnackbar({ message: 'There was an error retrieving your Hubspot settings.' });
                }

                // Handle Zapier response
                // if (tukiosRes.status === 'fulfilled') {
                //     this.tukios.api_key = tukiosRes.value.data.api_key;
                // } else {
                //     this.showSnackbar({ message: 'There was an error retrieving your Tukios settings.' });
                // }

                this.loading = false;
            });
        },
    },
    created() {
        // this.passareEndpoint = process.env.VUE_APP_API + `/Integration/Passare/settings`;
        // this.facebookEndpoint = process.env.VUE_APP_API + `/Integration/Facebook/settings/${this.funeralHomeId}`;
        // this.zapierEndpoint = process.env.VUE_APP_API + `/Integration/Zapier/settings/${this.funeralHomeId}`;
        this.hubspotEndpoint = process.env.VUE_APP_API + `/Integration/Hubspot/settings/${this.funeralHomeId}`;
        // this.tukiosEndpoint = process.env.VUE_APP_API + `/Integration/Tukios/settings/${this.funeralHomeId}`;
        this.getIntegrations();
    },
    mounted() {
        this.createAxiosInstance();
        this.getGoalStreams();
        this.getAcctManagementData();
        this.getChartData();
        if (this.$auth.role.includes('SuperAdmin')) {
            this.getAdmins();
            this.getTreasuredMem();
        }
        this.getLastStream();
        setTimeout(() => {
            this.disableMem = false;
        }, '2000');
    },
};
</script>

<style>
.background-hover:hover {
    cursor: pointer;
    background-color: #eee;
}
.pointer-hover:hover {
    cursor: pointer;
}
.centered-input input {
    text-align: center;
}
.large-input input {
    font-size: 1.5em;
}

.cls-1 {
    fill: none;
}
.cls-2 {
    clip-path: url(#clip-path);
}
.cls-3 {
    fill: #33475b;
}
.cls-4 {
    clip-path: url(#clip-path-3);
}
.cls-5 {
    fill: #ff7a59;
}
.hubspot-logo {
    width: 100px;
    margin-bottom: 1rem;
}

.connection-entry {
    width: 100%;
    position: relative;
    cursor: pointer;
    margin: 1rem 0;
}

.zone {
    background-color: #ecf3fb;
    border: 1px solid #61affe;
    border-radius: 5px;
}
</style>

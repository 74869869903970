<template>
    <section>
        <v-card class="p-4">
            <v-card-title>
                <font-awesome-icon icon="fa-regular fa-dollar-sign" style="margin-right: 0.5rem" />
                <h4 class="m-0">All Billing Events</h4>
            </v-card-title>

            <div class="d-flex px-3 align-items-center">
                <div class="me-5 text-muted">Quick Filters:</div>
                <v-btn @click="hotBtn(1)" :outlined="selectedHotBtn != 1" class="me-2" small dark color="blue"
                    >Overages</v-btn
                >
                <v-btn @click="hotBtn(2)" :outlined="selectedHotBtn != 2" class="me-2" small dark color="purple"
                    >Subscriptions</v-btn
                >
                <v-btn @click="hotBtn(3)" :outlined="selectedHotBtn != 3" :dark="selectedHotBtn == 3" small
                    >Other</v-btn
                >
                <!-- <v-btn @click="resetBillingParams" dark small>reset</v-btn> -->
            </div>

            <div style="display: flex; padding: 20px">
                <calendar v-model="startDate" label="From:" style="width: 200px" :output-local="true"></calendar>
                <!-- {{ getDateValue(startDate) }} -->
                <v-spacer></v-spacer>
                <calendar v-model="endDate" label="To:" style="width: 200px" :output-local="true"></calendar>
                <!-- {{ getDateValue(endDate) }} -->
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                    label="Search"
                    v-model="search"
                    append-icon="mdi-magnify"
                    @keyup.enter="getBillingEvents"
                    @input="updateSearch"
                    single-line
                    hide-details
                ></v-text-field>
            </div>
            <!-- START: DATA TABLE -->
            <!-- {{ options }}
            {{ options.sortBy }} -->
            <v-data-table
                :headers="billingHeaders"
                :items="totalBillingEvents"
                :options.sync="options"
                :server-items-length="billingResponseLength"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50],
                }"
                loading-text="Getting Billing Events..."
            >
                <template v-slot:header.eventType="{ header }">
                    <v-menu
                        @input="handleEventMenuToggle"
                        :disabled="loading"
                        :close-on-content-click="false"
                        :offset-y="true"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ selectedEvents.length }})
                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 200px">
                            <v-list-item v-for="(item, index) in eventTypes" :key="index" style="height: 20px">
                                <v-checkbox v-model="selectedEvents" :label="item.label" :value="index"></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:header.amount="{ header }">
                    <v-menu
                        :close-on-content-click="false"
                        :offset-y="true"
                        :disabled="loading"
                        @input="handleAmountMenuToggle"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }}
                                        <font-awesome-icon class="ml-1" icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 250px">
                            <v-select
                                dense
                                class="p-3"
                                v-model="selectedComparison"
                                :items="amountComparisons"
                            ></v-select>

                            <v-text-field
                                dense
                                v-if="selectedComparison != 6"
                                v-model="selectedAmount"
                                class="ml-4"
                                style="width: 100px"
                                label="Amount"
                                prepend-inner-icon="mdi-currency-usd"
                                type="number"
                            ></v-text-field>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:item.funeralHomeName="{ item }">
                    <a :href="`/homes/settings/${item.funeralHomeId}?tab=subscription`">{{ item.funeralHomeName }}</a>
                </template>

                <template v-slot:item.amount="{ item }">
                    {{ item.amount | formatMoney }}
                </template>

                <template v-slot:item.reportedDate="{ item }">
                    {{ item.reportedDate | dateFilter() }}<br />
                    <!-- {{ item.reportedDate | timeFilter() }} -->
                </template>

                <template v-slot:item.eventType="{ item }">
                    <span v-if="item.serviceSlug">
                        <a id="serviceEventSlugBtn" :href="`/services/${item.serviceSlug}`"
                            ><v-chip style="cursor: pointer" :color="eventTypes[item.eventType].color" dark>{{
                                eventTypes[item.eventType].label
                            }}</v-chip></a
                        ></span
                    >
                    <span v-else
                        ><v-chip :color="eventTypes[item.eventType].color" dark>{{
                            eventTypes[item.eventType].label
                        }}</v-chip></span
                    >
                </template>

                <template v-slot:item.description="{ item }">
                    <v-textarea
                        class="description-textarea"
                        v-if="item.isEditing"
                        autofocus
                        auto-grow
                        rows="1"
                        v-model="newDescription"
                    ></v-textarea>

                    <span v-else>{{ item.description }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div v-if="item.isEditing" class="d-flex flex-column">
                        <v-btn class="mb-2" color="green" small dark @click="saveDescription(item.id)">Save</v-btn>
                        <v-btn @click="cancelEdit(item.id)" small>Cancel</v-btn>
                    </div>
                    <div v-else class="action-list">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click="editDescription(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-pencil" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Edit Description</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click="showDeleteDialog(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-trash-can" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Delete Item</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>
            </v-data-table>
            <!-- End Data Table-->

            <!-- Start Summary  -->
            <SummaryCard>
                <v-row>
                    <v-col cols="4" v-for="(item, index) in eventTypes" :key="index">
                        <div
                            :style="{ 'background-color': item.color }"
                            style="border-radius: 5px; color: white"
                            class="elevation-2"
                        >
                            <div class="pt-1"><font-awesome-icon :icon="item.icon" class="mr-2" />{{ item.label }}</div>
                            <div class="pb-1">{{ billingTypeAmounts[index] | toCurrency() }}</div>
                        </div>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col>
                        <h3>Total: {{ billingResponseLength }}</h3>
                    </v-col>
                    <v-col></v-col>
                    <v-col>
                        <h3>
                            <strong>{{ amount | toCurrency() }}</strong>
                        </h3>
                    </v-col>
                </v-row>
            </SummaryCard>
            <!-- End Summary -->

            <!-- Delete Dialog -->
            <v-dialog v-model="deleteDialog.show" max-width="290">
                <v-card class="align-stretch">
                    <v-card-title class="headline">Delete Event?</v-card-title>
                    <v-card-text>Are you sure you want to delete this event?</v-card-text>
                    <v-card-actions class="justify-center">
                        <div>
                            <v-btn color="gdarken-1" text @click="deleteDialog.show = false">Cancel</v-btn>
                            <v-btn color="red darken-1" text @click="deleteEvent(deleteDialog.eventId)">Delete</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </section>
</template>

<script>
import { eventTypes } from '@/constants';
import Calendar from '@/components/ui/Calendar.vue';

import { mapActions } from 'vuex';
import moment from 'moment';
import SummaryCard from '../SummaryCard.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'AdminBillingTable',
    components: {
        Calendar,
        SummaryCard,
        CustomTooltip,
    },
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            mounted: false,
            eventTypes,
            selectedEvents: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            selectedComparison: '',
            selectedAmount: null,
            amountComparisons: [
                { text: '--', value: '' },
                { text: 'is greater than', value: 1 },
                { text: 'is less than', value: 2 },
                { text: 'is greater than or equal to', value: 3 },
                { text: 'is less than or equal to', value: 4 },
                { text: 'is equal to', value: 5 },
                { text: 'is not zero', value: 6 },
            ],
            selectedEventParamString: '',
            comparisonParamString: '',
            billingHeaders: [
                { text: 'Home', sortable: false, align: 'start', value: 'funeralHomeName', width: '16%' },
                { text: 'Date', sortable: true, value: 'reportedDate', width: '15%' },
                { text: 'Event', sortable: false, value: 'eventType', width: '15%' },
                { text: 'Amount', value: 'amount', width: '12%' },
                { text: 'Description', sortable: false, value: 'description', width: '25%' },
                { text: 'Actions', sortable: false, value: 'actions', align: 'center' },
            ],
            loading: false,
            billingResponseLength: 0,
            totalBillingEvents: [],
            deleteDialog: {
                eventId: '',
                show: false,
            },
            newDescription: 'blank',
            options: {},
            search: '',
            startDate: null,
            endDate: null,
            amount: null,
            currentOffset: '',
            filterMenuOpen: false,
            selectedHotBtn: null,
            billingTypeAmounts: [],
        };
    },

    methods: {
        ...mapActions(['showSnackbar']),
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        selectEventType(val) {
            this.selectedEvents = [val];
            this.getBillingEvents();
        },
        handleAmountMenuToggle() {
            if (this.filterMenuOpen == false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen == true) {
                this.getBillingEvents();
                this.filterMenuOpen = false;
            }
        },
        handleEventMenuToggle() {
            if (this.filterMenuOpen == false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen == true) {
                if (this.selectedEvents.length === 0) {
                    this.totalBillingEvents = [];
                    return;
                }
                this.getBillingEvents();
                this.filterMenuOpen = false;
            }
        },
        resetBillingParams() {
            this.selectedEvents = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            this.selectedComparison = '';
            this.selectedAmount = null;
            this.selectedHotBtn = null;
            this.getBillingEvents();
        },

        hotBtn(val) {
            this.options.page = 1;
            if (this.selectedHotBtn == val) {
                this.resetBillingParams();
            } else {
                this.selectedHotBtn = val;
                this.selectedComparison = 1;
                this.selectedAmount = 1;

                if (val == 1) this.selectedEvents = [0, 1];
                if (val == 2) this.selectedEvents = [3];
                if (val == 3) this.selectedEvents = [4, 5, 6, 7, 8, 9];

                this.getBillingEvents();
            }
        },
        getOffset() {
            this.currentOffset = Math.abs(moment(new Date()).utcOffset() / 60);
        },
        getDateValue(date) {
            return moment(date).unix();
        },
        updateSearch() {
            if (this.search.length === 0) {
                this.getBillingEvents();
            } else {
                this.getBillingEvents();
                this.options = {
                    ...this.options,
                    page: 1,
                };
            }
        },
        editDescription(id) {
            const totalBillingEvents = this.totalBillingEvents.map(event => {
                if (event.id === id) {
                    this.newDescription = event.description;
                    return { ...event, isEditing: true };
                } else return event;
            });
            this.totalBillingEvents = totalBillingEvents;
        },
        cancelEdit(id) {
            const totalBillingEvents = this.totalBillingEvents.map(event => {
                if (event.id === id) {
                    return {
                        ...event,
                        isEditing: false,
                    };
                } else return event;
            });
            this.totalBillingEvents = totalBillingEvents;
        },
        saveDescription(id) {
            const eventToUpdate = this.totalBillingEvents.find(event => event.id === id);
            eventToUpdate.description = this.newDescription;

            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${token}` }, baseURL: process.env.VUE_APP_API })
                    .put('billing/events', eventToUpdate)
                    .then(response => {
                        this.showSnackbar({ message: 'Event updated successfully' });
                        this.getBillingEvents();
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error updating events' });
                    });
            });
        },
        showDeleteDialog(id) {
            this.deleteDialog.eventId = id;
            this.deleteDialog.show = true;
        },
        deleteEvent(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({
                        headers: { Authorization: `Bearer ${token}` },
                        baseURL: process.env.VUE_APP_API,
                    })
                    .delete(`/billing/events/${id}`)
                    .then(response => {
                        this.getBillingEvents();
                        this.deleteDialog.show = false;
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error deleting events' });
                    });
            });
        },
        getEventColor(event) {
            switch (this.eventTypes[event]) {
                case 'Streaming Overage':
                    return 'blue';
                    break;
                case 'Viewer Overage':
                    return 'teal';
                    break;
                case 'Service Consumption':
                    return 'indigo';
                    break;
                case 'Subscription Charge':
                    return 'purple';
                    break;
                default:
                    return 'blue-grey';
                    break;
            }
        },
        getLocalDate(date) {
            const localDate = this.$moment(this.$moment.utc(date)).local();
            return localDate;
        },
        getSortByValue(value) {
            switch (value) {
                case 'reportedDate':
                    return 'ReportedDate';
                case 'amount':
                    return 'Amount';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getQueryParams() {
            let string = '';
            const { sortDesc, page, itemsPerPage } = this.options;

            var sortBy = this.getSortByValue(this.options.sortBy[0] ? this.options.sortBy[0] : 'reportedDate');

            string += `&offset=${this.currentOffset}`;
            string += `&startDate=${this.startDate}`;
            string += `&endDate=${this.endDate}`;
            string += `&pageNumber=${page ? page - 1 : 1}`;
            string += `&pageSize=${itemsPerPage}`;
            string += `&search=${this.search.toString().toLowerCase()}`;
            string += `&sortAsc=${sortDesc ? sortDesc : false}`;
            string += `&sortBy=${sortBy ? sortBy : 'ReportedDate'}`;

            this.selectedEvents.forEach(event => (string += `&eventType=${event}`));

            // string += this.selectedEventsParamString;
            // string += this.comparisonParamString;
            if (this.selectedComparison > 0) {
                if (this.selectedComparison === 6) {
                    string += `&symbol=1&amount=0`;
                } else {
                    string += `&symbol=${this.selectedComparison}&amount=${parseInt(this.selectedAmount * 100)}`;
                }
            }

            return string;
        },
        getBillingEvents() {
            this.loading = true;
            const params = this.getQueryParams();
            this.$auth.getIdTokenClaims().then(result => {
                const token = result.__raw;
                this.axios
                    .create({
                        headers: { Authorization: `Bearer ${token}` },
                        baseURL: process.env.VUE_APP_API,
                    })
                    .get(this.$props.apiUrl + `?${params}`)
                    .then(response => {
                        this.totalBillingEvents = response.data.data;
                        this.billingResponseLength = response.data.total;
                        this.amount = response.data.amount;
                        this.billingTypeAmounts = [
                            response.data.streamingAmount,
                            response.data.viewerAmount,
                            response.data.serviceAmount,
                            response.data.subscriptionAmount,
                            response.data.onboardingAmount,
                            response.data.equipmentAmount,
                            response.data.editAmount,
                            response.data.productAmount,
                            response.data.miscAmount,
                            response.data.tributeAmount,
                        ];
                    })
                    .finally((this.mounted = true))
                    .catch(error => {})
                    .then(() => (this.loading = false));
            });
        },
    },
    mounted() {
        // Update Start and End Date
        this.startDate = this.checkForLocal(moment(new Date()).startOf('month').toISOString());
        this.endDate = this.checkForLocal(moment(new Date()).toISOString());
        this.options.itemsPerPage = 25;

        this.getOffset();
        let string = '';
        this.selectedEvents.forEach(event => (string += `&eventType=${event}`));
        this.selectedEventsParamString = string;
        this.getBillingEvents();
    },
    filters: {
        toCurrency: function (value) {
            if (value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
                return formatter.format(value / 100);
            } else {
                return '$0';
            }
        },
        dateFilter(value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('l');
        },
        timeFilter(value) {
            const offset = moment(new Date()).utcOffset();
            return moment.utc(value).utcOffset(offset).format('LT');
        },
    },
    watch: {
        startDate() {
            if (this.mounted) {
                this.getBillingEvents();
            }
        },
        endDate() {
            if (this.mounted) {
                this.getBillingEvents();
            }
        },
        options: {
            handler() {
                if (this.mounted) {
                    this.getBillingEvents();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.hyperlink {
    color: #0dbaff !important;
    text-decoration: underline;
}
.action-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.action-list a {
    display: flex;
    justify-content: center;
    align-items: center;
}

#summaryBadge {
    width: 170px;
    margin-top: -25px;
    background-color: white;
    border: 2px solid cornflowerblue;
    border-radius: 15px;
    text-align: center;
}
#summary_card {
    border: 2px solid cornflowerblue;
}
</style>

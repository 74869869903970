<template>
    <div>
        <memoryshare-stream
            :slug="$route.params.slug"
            options='{"fluid": false, "height": 300, "width": 300, "playerOnly": true}'
        />
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'MemoryShare',
            titleTemplate: null,
        };
    },
    data() {
        return {
            ready: false,
            backgroundImage: `url(${require('@/assets/images/white-waves.png')})`,
            token: '',
        };
    },
    created() {
        this.$store.dispatch('toggleTopNav');

        // Inject JS
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', process.env.VUE_APP_ASSETS + '/app.v1.0.2.js');
        document.head.appendChild(externalScript);

        // Inject CSS
        let file = document.createElement('link');
        file.rel = 'stylesheet';
        file.href = process.env.VUE_APP_ASSETS + '/app.v1.0.2.css';
        document.head.appendChild(file);

        externalScript.addEventListener('load', () => {
            this.ready = true;
            if (this.$auth.role.includes('FuneralHome') || this.$auth.role.includes('SuperAdmin')) {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;
                });
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.stream-buffer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10001;
}
</style>

<template>
    <div>
        <v-dialog v-model="dialog" width="700">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="s" dark v-bind="attrs" v-on="on"> + Create Embed Code </v-btn>
            </template>
            <v-card style="padding: 2rem 3rem">
                <div class="">
                    <div class="container">
                        <h3 class="title">
                            <font-awesome-icon icon="fa-solid fa-code-simple" />

                            {{ editing ? 'Edit Embed Code' : 'Create New Embed Code' }}
                        </h3>
                    </div>

                    <div class="container">
                        <p>
                            The embed codes are exposed to our customers via a dropdown. Please ensure these names are
                            professional, and spelled correctly. Also refrain from naming embed codes after specific
                            customers.
                        </p>
                    </div>

                    <v-form class="container" ref="form" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="name"
                                    label="Name"
                                    :rules="[v => !!v.length || 'Name is required']"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="embedCodeType"
                                    :items="embedCodeData"
                                    item-text="description"
                                    item-value="id"
                                    label="Embed Code Type"
                                    :required="!embedCodeType"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <p class="text-caption">Supported Embed Code Fields</p>
                                <div class="d-flex flex-wrap" style="gap: 10px">
                                    <v-btn
                                        @click="insertEmbedCodeField(field)"
                                        depressed
                                        class="unset-btn"
                                        v-for="field in supportedFields"
                                        >{{ field }}</v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :class="htmlWrapper ? 'column-expanded' : 'column-collapsed'">
                                <textarea
                                    v-if="htmlWrapper"
                                    @scroll="syncScroll"
                                    id="editor-input"
                                    spellcheck="false"
                                    v-model="htmlWrapper"
                                    label="HTML Wrapper"
                                    @keydown.enter.stop
                                    @keydown="cancelCharacterDelete"
                                ></textarea>

                                <div :class="htmlWrapper ? 'visible-prism' : 'hidden-prism'">
                                    <prism id="editor-output" language="markup"></prism>
                                </div>
                            </v-col>
                        </v-row>

                        <v-alert v-if="errorMessage.length" type="error">
                            {{ errorMessage }}
                        </v-alert>

                        <div class="text-right mt-4">
                            <v-btn
                                v-if="htmlWrapper"
                                dark
                                color="#2d2d2d"
                                style="position: absolute; left: 59px"
                                @click="copyToClipboard"
                            >
                                <font-awesome-icon icon="fa-regular fa-clone" class="mr-2 justify-left" />
                                <span>Copy</span>
                            </v-btn>
                            <v-btn @click="handleCancel()" text class="mr-4">cancel</v-btn>
                            <v-btn @click.prevent="createEmbedCode()" color="primary">
                                {{ editing ? 'Update' : 'Create' }}</v-btn
                            >
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Prism from 'vue-prismjs';
import 'prismjs/themes/prism-okaidia.css';

export default {
    metaInfo: {
        title: 'Manage Admin Users',
    },
    data() {
        return {
            myCode: '',
            dialog: false,
            embedCodeData: [],
            name: '',
            embedCodeType: '',
            htmlWrapper: '',
            responsive: true,
            homes: [],
            funeralHome: '',
            token: '',
            errorMessage: '',
            editing: false,
            editingId: null,
            rules: {
                select: [v => !!v || 'Embed code type is required'],
            },
            embededCodeTemplates: [
                `<h1>code</h1>`,
                ``,
                `<a style="TO DO TO DO" href="{{src}}">Watch Live Stream</a>`,
                `<iframe class="embed-responsive-item" src="{{src}}" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen> </iframe>`,
            ],
            supportedFields: [
                'src',
                'seoScript',
                'storyViewLink',
                'storyContributeLink',
                'tributeViewLink',
                'tributeContributeLink',
            ],
        };
    },
    watch: {
        embedCodeType() {
            if (!this.editing) {
                this.htmlWrapper = this.embededCodeTemplates[this.embedCodeType];
                this.updatePrism(this.htmlWrapper);
            }
        },
        htmlWrapper() {
            this.updatePrism(this.htmlWrapper);
        },
    },

    async mounted() {
        window.Prism = window.Prism || {};
        Prism.manual = true;
        await this.setAuthToken();
        this.createAxiosInstance();

        this.getEmbedCodeType();
        if (this.$route.params.id) {
            this.editing = true;
            this.getEmbedCode();
        }
    },

    methods: {
        ...mapActions(['showSnackbar']),
        insertEmbedCodeField(field) {
            this.htmlWrapper = `{{${field}}}` + this.htmlWrapper;
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.htmlWrapper);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        cancelCharacterDelete(e) {
            let textarea = document.querySelector('#editor-input');
            let text = this.htmlWrapper;
            let position = textarea.selectionStart;

            if (e.keyCode === 8 && text[position - 2] === '}' && text[position - 1] === '}') {
                e.preventDefault();
            } else if (e.keyCode === 46 && text[position] === '{' && text[position + 1] === '{') {
                e.preventDefault();
            }
        },
        syncScroll() {
            let editorOutput = document.querySelector('#editor-output');
            let editorInput = document.querySelector('#editor-input');

            editorOutput.scrollTop = editorInput.scrollTop;
        },
        updatePrism(text) {
            window.Prism = window.Prism || {};
            Prism.manual = true;
            let x = document.querySelector('#editor-output');

            if (x && this.htmlWrapper) {
                x.innerHTML = text.replace(new RegExp('<', 'g'), '&lt').replace(new RegExp('>', 'g'), '&gt');

                window.Prism.highlightElement(x);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },

        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        handleCancel() {
            this.name = '';
            this.embedCodeType = '';
            this.editingId = null;
            this.htmlWrapper = '';
            this.dialog = false;
            this.editing = false;
        },
        getEmbedCodeType() {
            this.axiosInstance
                .get(`/dropdown/embedcodetype`)
                .then(response => {
                    if (response.data) {
                        this.embedCodeData = response.data;
                    }
                })
                .catch(error => {});
        },
        getEmbedCode(id) {
            this.editingId = id;
            this.axiosInstance
                .get(`/EmbedCode/${id}`)
                .then(response => {
                    this.name = response.data.name;
                    this.embedCodeType = response.data.embedCodeType;
                    this.responsive = response.data.responsive;
                    this.htmlWrapper = response.data.htmlWrapper;
                })
                .catch(error => {});
        },
        createEmbedCode() {
            this.errorMessage = '';

            if (this.editing) {
                let data = {
                    name: this.name,
                    width: 0,
                    height: 0,
                    embedCodeType: this.embedCodeType,
                    responsive: this.responsive,
                    id: this.editingId,
                    htmlWrapper: this.htmlWrapper,
                };

                this.axiosInstance
                    .put(`/EmbedCode/${this.editingId}`, data)
                    .then(response => {
                        this.message = 'Embed code Updated';
                        this.showSnackbar({ message: 'Embed code Updated' });
                        this.editing = false;
                        this.dialog = false;
                        this.name = '';
                        this.embedCodeType = '';
                        this.editingId = null;
                        this.htmlWrapper = '';
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        this.showSnackbar({ message: 'Error updating user' });
                    });
            } else {
                // new embed code
                let data = {
                    name: this.name,
                    width: 0,
                    height: 0,
                    embedCodeType: this.embedCodeType,
                    responsive: this.responsive,
                    htmlWrapper: this.htmlWrapper,
                };

                this.axiosInstance
                    .post('/EmbedCode', data)
                    .then(response => {
                        this.showSnackbar({ message: 'Embed Code created' });
                        this.editing = false;
                        this.dialog = false;
                        this.name = '';
                        this.embedCodeType = '';
                        this.editingId = null;
                        this.htmlWrapper = '';
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data;
                        this.showSnackbar({ message: 'Error creating user' });
                    });
            }
        },
    },
    components: {
        Prism,
    },
};
</script>

<style lang="scss" scoped>
.unset-btn {
    text-transform: unset !important;
}
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 3rem;
}

.row {
    margin-top: 1.5rem;
}

.custom-checkbox {
    margin-top: 1rem;
}

.save-btn {
    width: 40%;
}

.form-group {
    margin-top: 2rem;
}
.hidden-prism {
    opacity: 0;
}
.vissible-prism {
    opacity: 1;
}
.column-expanded {
    height: 230px;
}
.column-collapsed {
    height: 100px;
    overflow: hidden;
}

#editor-input,
#editor-output {
    // border: 2px solid orange;
    margin: 10px;
    padding: 10px;
    width: calc(100% - 32px);
    height: 200px;
}

#editor-input,
#editor-output,
#editor-output * {
    /* Also add text styles to highlighting tokens */
    font-size: 15pt;
    font-family: monospace;
    line-height: 20pt;
}

#editor-input,
#editor-output {
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    white-space: normal;
}
#editor-input {
    z-index: 1;
    color: transparent;
    background: transparent;
    caret-color: white;
}
#editor-output {
    z-index: 0;
    resize: vertical;
}

code[class*='language-'],
pre[class*='language-'] {
    white-space: pre-wrap !important;
    word-break: break-word !important;
    overflow-x: auto;
    overflow-y: auto;
}
</style>

<template>
    <v-progress-circular v-if="!token" indeterminate color="primary"></v-progress-circular>
    <div v-else>
        <h5>Super Admin Only</h5>
        <v-form>
            <v-text-field v-model="callsPerYear" label="Calls Per Year"></v-text-field>
            <div class="text-right mt-10 mb-6">
                <v-btn @click.prevent="setCallsPerYear" :loading="loading" :disabled="loading" color="primary"
                    >Save</v-btn
                >
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'SuperAdminDetails',
    props: ['token', 'funeralHomeId'],
    data() {
        return {
            loading: false,
            callsPerYear: '',
            currentSettings: {},
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        getCallsPerYear() {
            this.axiosInstance
                .get(`/funeralhomes/settings/admin/${this.funeralHomeId}`)
                .then(({ data }) => {
                    this.currentSettings = data;
                    this.callsPerYear = data.callsPerYear;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        setCallsPerYear() {
            const payload = {
                ...this.currentSettings,
                funeralHomeId: this.funeralHomeId,
                callsPerYear: Number(this.callsPerYear),
            };

            this.axiosInstance
                .put('/funeralhomes/settings/admin', payload)
                .then(response => {
                    this.showSnackbar({ message: 'Settings updated' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Problem updating settings. Please try again.' });
                });
        },
        setAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    mounted() {
        this.setAxiosInstance();
        this.getCallsPerYear();
    },
};
</script>

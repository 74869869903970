<template>
    <div id="donut-chart">
        <canvas ref="donutChart"></canvas>
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Chart } from 'chart.js';

export default {
    name: 'DonutChart',
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        chartOptions: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false,
            }),
        },
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const ctx = this.$refs.donutChart.getContext('2d');
            new Chart(ctx, {
                type: 'doughnut',
                data: this.chartData,
                options: this.chartOptions,
            });
        },
    },
};
</script>

<style scoped></style>

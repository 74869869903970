<template>
    <div class="mt-6 mb-6">
        <h3>Account History</h3>
        <div v-if="accountHistory.length > 0">
            <v-data-table
                @click:row="eventDetails"
                style="min-height: 0px"
                :headers="headers"
                :items="accountHistory"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
                :options.sync="options"
            >
                <template v-slot:item.changeType="{ item }">
                    <v-chip dark :color="accountChanges[item.changeType].color">{{
                        accountChanges[item.changeType].label
                    }}</v-chip>
                </template>

                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate | moment('l') }}
                </template>

                <template v-slot:item.newSubscsriptionCost="{ item }">
                    {{ item.newSubscsriptionCost | formatMoney }}
                </template>
                <template v-slot:item.oldSubscriptionCost="{ item }">
                    {{ item.oldSubscriptionCost | formatMoney }}
                </template>

                <template v-slot:item.notes="{ item }">
                    <custom-tooltip :tooltipProps="{ true: true }">
                        <template v-slot:activator>
                            <span @click.stop @click="editNotes(item)">
                                <font-awesome-icon icon="fa-regular fa-pencil" style="font-size: 1rem" />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Edit Notes</span>
                        </template>
                    </custom-tooltip>
                </template>
            </v-data-table>
        </div>

        <div style="color: gray" class="text-center" v-else>
            <p>There is no logged history for this account.</p>
        </div>

        <v-dialog v-model="notesDialog" max-width="400px">
            <v-card>
                <v-card-title>Notes:</v-card-title>
                <v-card-text>
                    <v-textarea v-model="tempNoteVal"></v-textarea>
                    <div class="text-right">
                        <v-btn color="primary" @click="updateNotes" :disabled="tempNoteVal === selectedChange.notes"
                            >Update</v-btn
                        >
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width=" 700px" v-model="detailsModal">
            <v-card class="p-3" v-if="updates">
                <div class="ml-3" style="display: flex; justify-content: space-between">
                    <p><strong>Date:</strong> {{ selectedDetails.createDate | moment('l') }}</p>
                    <span
                        ><v-chip class="p-3" dark :color="accountChanges[selectedDetails.changeType].color">{{
                            accountChanges[selectedDetails.changeType].label
                        }}</v-chip></span
                    >
                </div>
                <p class="ml-3"><strong>Changed By:</strong> {{ selectedDetails.changedBy }}</p>
                <p class="p-3" style="background-color: #eee"><strong>Notes: </strong> {{ selectedDetails.notes }}</p>

                <v-data-table :headers="detailsHeaders" :items="updates" hide-default-footer>
                    <template v-slot:item.old="{ item }">
                        <div id="highlightChange" v-if="item.old !== item.new">{{ item.old }}</div>
                        <div v-else>
                            {{ item.old }}
                        </div>
                    </template>

                    <template v-slot:item.new="{ item }">
                        <div id="highlightChange" v-if="item.old !== item.new">{{ item.new }}</div>
                        <div v-else>
                            {{ item.old }}
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { accountChanges } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    data() {
        return {
            options: {
                itemsPerPage: 25,
            },
            updates: '',
            accountChanges,
            accountHistory: [],
            headers: [
                { text: 'Change', value: 'changeType', sortable: false },
                { text: 'Date', value: 'createDate', sortable: false },
                { text: 'Admin', value: 'changedBy', sortable: false },
                { text: 'Old Price', value: 'oldSubscriptionCost', sortable: false },
                { text: 'New Price', value: 'newSubscsriptionCost', sortable: false },
                { text: 'Notes', value: 'notes', sortable: false },
            ],
            notesDialog: false,
            selectedChange: '',
            tempNoteVal: '',
            detailsModal: false,
            detailsHeaders: [
                { text: 'Changes', value: 'label', sortable: false },
                { text: 'Old', value: 'old', sortable: false, align: 'center', width: '20%' },
                { text: 'New', value: 'new', sortable: false, align: 'center', width: '20%' },
            ],
        };
    },
    components: { CustomTooltip },
    props: ['token', 'funeralHomeId'],

    methods: {
        ...mapActions(['showSnackbar']),
        formatMoneyString(cents) {
            return '$' + (cents / 100).toFixed(2);
        },
        eventDetails(item) {
            this.selectedDetails = {
                createDate: item.createDate,
                notes: item.notes,
                changeType: item.changeType,
                changedBy: item.changedBy,
            };

            this.updates = [
                {
                    label: 'Subscription Cost',
                    old: this.formatMoneyString(item.oldSubscriptionCost),
                    new: this.formatMoneyString(item.newSubscsriptionCost),
                },
                {
                    label: 'Streams',
                    old: item.oldStreamsPerMonth,
                    new: item.newStreamsPerMonth,
                },
                {
                    label: 'Max Viewers',
                    old: item.oldMaxViewers.toLocaleString('en-US'),
                    new: item.newMaxViewers.toLocaleString('en-US'),
                },
                {
                    label: 'Max Steam Length',
                    old: item.oldMaxStreamLength,
                    new: item.newMaxStreamLength,
                },
                {
                    label: 'Cost Per Additional Stream',
                    old: this.formatMoneyString(item.oldCostPerAdditionalStream),
                    new: this.formatMoneyString(item.newCostPerAdditionalStream),
                },
                {
                    label: 'Cost Per 100 Viewers',
                    old: this.formatMoneyString(item.oldCostPer100Viewers),
                    new: this.formatMoneyString(item.newCostPer100Viewers),
                },
                {
                    label: 'Cost Per Fifteen Minutes',
                    old: this.formatMoneyString(item.oldCostPerFifteenMinutes),
                    new: this.formatMoneyString(item.newCostPerFifteenMinutes),
                },
                {
                    label: 'Currency',
                    old: item.oldCurrency,
                    new: item.newCurrency,
                },
            ];
            this.detailsModal = true;
        },
        updateNotes() {
            this.axiosInstance
                .put(`account-managment/price-history/notes/${this.selectedChange.id}?notes=${this.tempNoteVal}`)
                .then(response => {
                    this.showSnackbar({ message: 'Notes updated' });
                    this.getAccountHistory();
                    this.selectedChange.notes = this.tempNoteVal;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating notes' });
                });
        },
        editNotes(item) {
            this.tempNoteVal = item.notes;
            this.selectedChange = item;
            this.notesDialog = true;
        },
        getAccountHistory() {
            this.axiosInstance
                .get(`account-managment/price-history/${this.funeralHomeId}`)
                .then(response => {
                    this.accountHistory = response.data;
                    this.accountHistory.reverse();
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error retrieving account history' });
                    console.log(error);
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    mounted() {
        this.createAxiosInstance();
        this.getAccountHistory();
    },
};
</script>

<style>
#highlightChange {
    background-color: #80cbc4;
}
</style>

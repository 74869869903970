<template>
    <div>
        <AppLayout :has-background="true" :contained="true">
            <v-card>
                <v-card-title class="p-4">
                    <font-awesome-icon icon="fa-solid fa-chart-line-up" class="mr-2"></font-awesome-icon>
                    <h3 class="m-0">Google Report</h3>
                </v-card-title>

                <keyword-report-panel :funeralHomeId="parseInt($route.params.funeralHomeId)" />
            </v-card>
        </AppLayout>
    </div>
</template>
<script>
import KeywordReportPanel from '@/components/Panels/KeywordReportPanel.vue';
export default {
    data() {
        return {};
    },
    components: {
        KeywordReportPanel,
    },
};
</script>
<style lang=""></style>

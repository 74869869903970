<template>
    <div img-dedupe-outer>
        <div class="img-dedupe-inner">
            <v-btn
                :disabled="loading"
                :loading="loading"
                @click="$emit('delete-photo')"
                class="delete-action-btn"
                x-small
                fab
                color="error"
            >
                <font-awesome-icon style="font-size: 0.8rem" icon="fa-regular fa-trash-can"></font-awesome-icon>
            </v-btn>
            <img
                class="dupe-img"
                style="border-radius: 5px; object-fit: contain"
                :style="{ maxHeight: maxHeight }"
                v-if="photo.url"
                :src="photo.url"
            />
            <div class="similarity-score" v-if="type == 'SIMILAR'">
                <v-chip v-if="isReference" color="black" dark small label>Ref</v-chip>
                <v-chip dark :color="getChipColor(Math.round(photo.similarity))" v-else small label
                    >{{ Math.round(photo.similarity) }}%</v-chip
                >
            </div>
            <div class="similarity-score" v-if="type == 'DUPLICATE'">
                <v-chip :color="isReference ? 'black' : '#1877f2'" dark small label>{{
                    isReference ? 'Ref' : 'Duplicate'
                }}</v-chip>
            </div>
        </div>
        <div class="pa-1 file-name-container">
            <p class="p-0 m-0 text-caption text-truncate">{{ photo.name }}</p>
        </div>
        <div class="text-caption" v-if="devMode && $auth.role.includes('SuperAdmin')">
            <p class="m-0 p-0">Hash: {{ photo.perceptualHash }}</p>
            <p class="m-0 p-0">Id: {{ photo.id }}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        photo: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ['DUPLICATE', 'SIMILAR'].includes(value);
            },
        },
        isReference: {
            type: Boolean,
            default: false,
        },
        devMode: {
            type: Boolean,
            defalut: false,
        },
        maxHeight: {
            type: String,
            default: '100%',
        },
    },
    methods: {
        getChipColor(value) {
            if (value > 90) {
                return '#4CAF50';
            } else if (value > 70) {
                return '#1877f2';
            } else {
                return '#ff5252';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.img-dedupe-inner {
    position: relative;
    max-height: 100%;
    max-width: 100%;
    width: max-content;
}
.img-dedupe-outer {
    max-width: 100%;
    max-height: 100%;
}

.delete-action-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 5;
    opacity: 0.4;
}

.dupe-img {
}
.file-name-container {
    width: 100%;
    max-width: 100%;
}
.similarity-score {
    position: absolute;
    bottom: 5px;
    left: 5px;
}
.delete-action-btn:hover {
    opacity: 1;
}
</style>

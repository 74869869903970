<template>
    <AppLayout :has-background="true" :contained="true">
        <div style="display: flex; justify-content: space-between">
            <v-btn class="mb-5" @click="$router.back()"
                ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Back
            </v-btn>
            <!-- <v-btn v-if="$auth.role.includes('SuperAdmin')" class="mb-5" @click="goToRouterName('EcomAdmin')"
                >Admin Panel</v-btn
            > -->
        </div>

        <div>
            <div v-if="order">
                <h3 style="color: white">Order Details</h3>
                <div class="elevation-3 p-4 bg-white rounded">
                    <div v-if="$auth.role.includes('SuperAdmin')">
                        <v-chip
                            v-if="order.paymentStatus >= 0"
                            class="mr-5"
                            :color="paymentStatuses[order.paymentStatus].color"
                            dark
                            >{{ paymentStatuses[order.paymentStatus].label }}</v-chip
                        >

                        <v-menu attach offset-y v-if="order.orderStatus >= 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" dark :color="orderStatuses[order.orderStatus].color">{{
                                    orderStatuses[order.orderStatus].label
                                }}</v-chip>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="updateStatus(order.id, index)"
                                    v-for="(item, index) in orderStatuses"
                                    :key="index"
                                >
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>

                    <div v-else-if="!$auth.role.includes('FuneralHome')">
                        <v-chip v-if="order.paymentStatus >= 0" dark :color="orderStatuses[order.orderStatus].color">{{
                            orderStatuses[order.orderStatus].label
                        }}</v-chip>
                    </div>
                    <div class="mt-5">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="details">
                                    <strong>Customer</strong>
                                    <v-divider></v-divider>
                                    <div>{{ order.customerName }}</div>
                                    <div>{{ order.customerEmail }}</div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <div id="shippingDetails" class="details">
                                    <strong>Shipping</strong>
                                    <v-btn
                                        v-if="$auth.role.includes('SuperAdmin')"
                                        class="hoverShow"
                                        style="position: absolute; right: 25px"
                                        x-small
                                        @click="openShippingUpdateModal"
                                        >Edit</v-btn
                                    >

                                    <v-divider></v-divider>
                                    <div v-if="order.city">
                                        <div>{{ order.shippingName }}</div>
                                        <div>{{ order.address1 }}</div>
                                        <div>{{ order.address2 }}</div>
                                        <div>{{ order.city }}, {{ order.state }} {{ order.zipCode }}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col v-if="$auth.role.includes('SuperAdmin')" cols="12" md="6">
                                <div class="details">
                                    <strong>Order</strong>
                                    <v-divider></v-divider>
                                    <div>{{ order.supplierName }}</div>
                                    <div>Order ID: {{ order.id }}</div>
                                    <div v-if="$auth.role.includes('SuperAdmin')">
                                        Purchase ID: {{ order.purchaseId }}
                                    </div>
                                    <div>{{ order.name }}</div>
                                    <div v-if="order.createdDate">
                                        <i style="color: gray">Created</i>
                                        {{ $moment.utc(order.createdDate).local().format('lll') }}
                                    </div>

                                    <div v-if="order.amount">{{ order.amount | formatMoney() }}</div>
                                </div>
                            </v-col>

                            <v-col v-if="$auth.role.includes('SuperAdmin')" cols="12" md="6">
                                <div class="details">
                                    <strong>Actions</strong>
                                    <v-divider></v-divider>
                                    <div style="display: flex; flex-direction: column">
                                        <OrderShipping
                                            :supplierSettings="supplierSettings"
                                            :order="order"
                                            @refresh="getOrderDetails"
                                        />
                                        <v-btn
                                            v-if="$auth.role.includes('SuperAdmin')"
                                            class="mt-2"
                                            color="#625afa"
                                            dark
                                            small
                                            @click="openStripeCustomer()"
                                            >Customer</v-btn
                                        >
                                        <v-btn
                                            v-if="$auth.role.includes('SuperAdmin')"
                                            class="mt-2"
                                            color="#625afa"
                                            dark
                                            small
                                            @click="openStripePayment()"
                                            >Payment</v-btn
                                        >
                                        <v-btn
                                            v-if="$auth.role.includes('SuperAdmin')"
                                            small
                                            dark
                                            class="mt-2"
                                            color="#ff530d"
                                            style="width: 100%"
                                            @click="$router.push({ path: `/services/${order.serviceSlug}` })"
                                            >Service</v-btn
                                        >
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <div v-if="order.lineItems" class="mt-5 elevation-3 p-4 bg-white rounded">
                    <div class="mb-5">
                        <strong>Line Items</strong>
                        <div v-if="order.lineItems.length == 0">
                            No shipment data is available for this purchase at this time.
                        </div>
                    </div>
                    <div v-for="(lineItem, index) in order.lineItems">
                        <LineItemStatusDetails :lineItem="lineItem" />
                        <v-divider v-if="index != order.lineItems.length - 1"></v-divider>
                    </div>
                    <div v-if="$auth.role.includes('FuneralHome') && order.amount">
                        <v-divider></v-divider>
                        <div class="d-flex justify-content-between">
                            <h4>Total</h4>
                            <h4>{{ order.amount | formatMoney() }}</h4>
                        </div>
                    </div>
                </div>

                <!-- <div class="mt-5 elevation-3 p-4 bg-white rounded">
                    <strong>Line Items</strong>
                    <v-data-table :items="order.lineItems" :headers="headers">
                        <template v-slot:item.unitAmount="{ item }">
                            {{ item.unitAmount | formatMoney() }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                            {{ item.totalAmount | formatMoney() }}
                        </template>
                    </v-data-table>
                </div> -->

                <div class="mt-5 elevation-3 p-4 bg-white rounded">
                    <!-- <OrderShipmentTable :orderId="$route.params.id" ref="shipmentTable" @refresh="getOrderDetails" /> -->
                    <div v-if="order.shipments">
                        <strong>Shipments</strong>

                        <div v-if="order.shipments.length == 0">
                            No shipment data is available for this purchase at this time.
                        </div>
                        <v-row class="text-center">
                            <v-col cols="12" md="6" v-for="(shipment, index) in order.shipments">
                                <div class="section">
                                    <div style="display: flex; justify-content: space-between">
                                        <div>
                                            <div class="text-left">
                                                <i style="color: gray; font-size: 0.8rem">Updated</i>
                                            </div>
                                            <div>
                                                <i style="color: gray; font-size: 0.8rem">{{
                                                    $moment.utc(shipment.updatedDate).local().format('ll')
                                                }}</i>
                                            </div>
                                        </div>

                                        <v-chip small dark :color="shipmentStatuses[shipment.status].color">{{
                                            shipmentStatuses[shipment.status].label
                                        }}</v-chip>
                                    </div>
                                    <div class="mt-2" style="display: flex; justify-content: center">
                                        <div v-for="(sli, index) in shipment.shipmentLineItems" :key="index">
                                            <ShipmentLineItemStatusDetails class="p-2" :shipmentLineItem="sli" />
                                        </div>
                                    </div>
                                    <div>
                                        <span class="mr-2">{{ carriers[shipment.carrier].label }}</span>
                                        <a :href="shipment.trackingUrl" target="_blank">{{
                                            shipment.trackingNumber
                                        }}</a>
                                    </div>
                                    <div style="display: flex; flex-direction: column">
                                        <v-btn
                                            class="mt-2"
                                            color="#005069"
                                            dark
                                            small
                                            v-if="shipment.trackingUrl"
                                            @click="openTab(shipment.trackingUrl)"
                                            >Tracking</v-btn
                                        >
                                        <v-btn
                                            color="#005069"
                                            dark
                                            small
                                            class="mt-2"
                                            v-if="shipment.labelUrl && $auth.role.includes('SuperAdmin')"
                                            @click="openTab(shipment.labelUrl)"
                                            >Label</v-btn
                                        >
                                        <v-btn
                                            dark
                                            small
                                            class="mt-2"
                                            v-if="$auth.role.includes('SuperAdmin')"
                                            @click="$router.push({ path: `/shipment/${shipment.id}` })"
                                            >Details</v-btn
                                        >
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div v-if="!order">
                <p>Order#: {{ $route.params.id }} was not found.</p>
            </div>
        </div>

        <!-- START: Edit Shipping Modal -->
        <v-dialog max-width="500" v-model="editShippingModal">
            <v-card class="p-3">
                <v-card-title>Edit Shipping</v-card-title>
                <div class="p-3">
                    <v-text-field v-model="shippingName" label="Name" />
                    <v-text-field v-model="line1" label="Address 1" />
                    <v-text-field v-model="line2" label="Address 2" />
                    <v-text-field v-model="city" label="City" />
                    <v-select
                        attach=""
                        v-model="state"
                        :items="usStates"
                        item-text="label"
                        item-value="value"
                        label="State"
                    ></v-select>
                    <v-text-field v-model="zipCode" label="Zip Code" />
                    <div class="text-right">
                        <v-btn color="#0c3c60" dark @click="updateShipping(order.id)">Submit</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- End: Edit Shipping Modal -->
    </AppLayout>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { orderStatuses, paymentStatuses, usStates, shipmentStatuses, carriers } from '@/constants';
import OrderShipping from '@/components/Shop/OrderShipping.vue';
import OrderShipmentTable from '@/components/Shop/OrderShipmentTable.vue';
import OrderStatusDetails from '@/components/Shop/OrderStatusDetails.vue';
import LineItemStatusDetails from '@/components/Shop/LineItemStatusDetails.vue';
import ShipmentLineItemStatusDetails from '@/components/Shop/ShipmentLineItemStatusDetails.vue';

export default {
    metaInfo: {
        title: 'Order Details',
    },
    data() {
        return {
            orderStatuses,
            paymentStatuses,
            usStates,
            shipmentStatuses,
            carriers,
            message: 'hi',
            token: '',
            overlay: true,
            order: {},
            trackingEditDialog: false,
            trackingNumber: '',
            headers: [],
            supplierSettings: {},
            editShippingModal: false,
            shippingName: '',
            line1: '',
            line2: '',
            city: '',
            state: null,
            zipCode: '',
        };
    },
    components: {
        OrderShipping,
        OrderShipmentTable,
        OrderStatusDetails,
        LineItemStatusDetails,
        ShipmentLineItemStatusDetails,
    },
    methods: {
        ...mapActions(['showSnackbar']),
        // refreshShipments() {
        //     this.$refs.shipmentTable.getShipments();
        // },
        openShippingUpdateModal() {
            this.editShippingModal = true;
            this.shippingName = this.order.shippingName;
            this.line1 = this.order.address1;
            this.line2 = this.order.address2;
            this.city = this.order.city;
            this.state = this.order.state;
            this.zipCode = this.order.zipCode;
        },
        resetShippingModal() {
            this.editShippingModal = false;
            this.shippingName = '';
            this.line1 = '';
            this.line2 = '';
            this.city = '';
            this.state = null;
            this.zipCode = '';
        },
        updateShipping(id) {
            let data = {
                shippingName: this.shippingName,
                address1: this.line1,
                address2: this.line2,
                city: this.city,
                state: this.state,
                zipCode: this.zipCode,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Order/update-shipping-address/${id}`, data)
                .then(res => {
                    this.resetShippingModal();
                    this.showSnackbar({ message: 'Shipping Updated' });
                    this.getOrderDetails();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        openTab(url) {
            window.open(url, '_blank');
        },
        openStripeCustomer() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/customers/${this.order.stripeCustomerId}`, '_blank');
        },
        openStripePayment() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/payments/${this.order.stripePaymentIntentId}`, '_blank');
        },
        updateStatus(id, status) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Order/update-order-status/${id}?status=${status}`)
                .then(response => {
                    this.showSnackbar({ message: 'Status Updated' });
                    this.getOrderDetails();
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        goToRouterName(name) {
            this.$router.push({ name: name });
        },
        consolidateShipments(shipments) {
            const consolidatedItems = {};

            shipments.forEach(shipment => {
                shipment.shipmentLineItems.forEach(item => {
                    const itemId = item.id;
                    const quantity = item.shipQuantity;

                    if (itemId in consolidatedItems) {
                        // Line item already exists, add the quantity
                        consolidatedItems[itemId].quantity += quantity;
                    } else {
                        // Line item doesn't exist, create a new entry
                        consolidatedItems[itemId] = { id: itemId, quantity: quantity };
                    }
                });
            });

            const consolidatedShipments = Object.values(consolidatedItems);

            return consolidatedShipments;
        },
        getOrderDetails() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Order/get-order/${this.$route.params.id}`)
                    .then(response => {
                        this.order = response.data;

                        let shippedItems = this.consolidateShipments(this.order.shipments);

                        if (this.order.lineItems) {
                            this.order.lineItems = this.order.lineItems.map(item => {
                                return {
                                    ...item,
                                    shipQuantity: item.quantity,
                                    splitShip: false,
                                    maxShipped: false,
                                    totalShipped: 0,
                                };
                            });

                            // Reduce available quantity to ship by what has already shipped
                            this.order.lineItems.forEach(item => {
                                const found = shippedItems.find(x => x.id == item.id);

                                if (found) {
                                    item.shipQuantity = item.quantity - found.quantity;
                                    item.totalShipped = found.quantity;

                                    if (item.shipQuantity <= 0) {
                                        item.maxShipped = true;
                                    }
                                }
                            });
                        }

                        this.getSupplierSettings(this.order.supplierId);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        getSupplierSettings(id) {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Suppliers/${id}`)
                    .then(response => {
                        this.supplierSettings = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
    mounted() {
        if (this.$auth.role.includes('SuperAdmin')) {
            this.headers = [
                { text: 'Name', value: 'productName' },
                { text: 'SupplierId', value: 'supplierId' },
                { text: 'Unit Price', value: 'unitAmount' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Total', value: 'totalAmount' },
                // { text: 'Quantity', value: 'quantity' },
            ];
        } else {
            this.headers = [
                { text: 'Name', value: 'productName' },
                // { text: 'SupplierId', value: 'supplierId' },
                { text: 'Unit Price', value: 'unitAmount' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Total', value: 'totalAmount' },
                // { text: 'Quantity', value: 'quantity' },
            ];
        }
        this.getOrderDetails();
    },
};
</script>
<style>
.flex-div {
    display: flex;
    justify-content: space-between;
}

.details {
    border: 2px solid #eee;
    border-radius: 10px;
    /* margin: 20px 0; */
    padding: 10px;
    text-align: center;

    /* max-width: 500px; */
}
/* .details-wrapper {
    border: 2px solid orange;
} */

.hoverShow {
    opacity: 0;
}

#shippingDetails:hover .hoverShow {
    opacity: 1;
}

@media (min-width: 900px) {
    /* .details-wrapper {
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    } */
    .details {
        max-width: 100%;
    }
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="elevation-3 p-4 bg-white rounded">
            <div class="tile form">
                <div class="header">
                    <h3 class="title">
                        <font-awesome-icon icon="fa-regular fa-house" />
                        {{ title }}
                    </h3>
                </div>

                <div>
                    <div class="tab-padding mt-10">
                        <v-form class="form-container" ref="form" lazy-validation>
                            <v-text-field
                                v-model="name"
                                :rules="[v => !!v || 'Name is required']"
                                label="Name"
                                required
                            ></v-text-field>
                            <v-text-field v-model="address1" label="Address 1" required></v-text-field>
                            <v-text-field v-model="address2" label="Address 2" required></v-text-field>

                            <v-row class="mt-0">
                                <v-col>
                                    <v-text-field v-model="city" label="City" required></v-text-field>
                                </v-col>

                                <v-col>
                                    <v-text-field v-model="state" label="State" required></v-text-field>
                                </v-col>

                                <v-col>
                                    <v-text-field v-model="zipCode" label="Zip" required></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field
                                v-model="email"
                                :rules="[v => !!v || 'Email is required']"
                                label="Email"
                                type="email"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="primaryContactEmail"
                                :rules="[v => !!v || 'Email is required']"
                                label="Primary Contact Email"
                                type="email"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="primaryContact"
                                :rules="[v => !!v || 'Primary Contact is required']"
                                label="Primary Contact Name"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="primaryContactPhoneNumber"
                                v-mask="'###.###.####'"
                                label="Primary Contact Phone"
                                type="phone"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="website"
                                label="Website Link"
                                :rules="[v => !!v || 'Valid link is required']"
                            ></v-text-field>

                            <embed-modal v-if="embedHtml" :slug="embedHtml" />
                            <div class="text-right mt-10 mb-6">
                                <button @click.prevent="saveOwner($data)" class="btn btn-primary ml-3 save-btn">
                                    Save Owner
                                </button>
                            </div>
                        </v-form>
                    </div>
                </div>
                <Spinner v-if="loading"></Spinner>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import OwnerSettings from '@/components/Settings/OwnerSettings';
import IntegrationSettings from '@/components/Settings/IntegrationSettings';
import Spinner from '@/components/ui/Spinner';
import { mapActions } from 'vuex';
import EmbedModal from '@/components/Modals/EmbedModal';
import LineChart from '@/components/charts/LineChart';
import OwnerSubscriptionPanel from '@/components/Panels/OwnerSubscriptionPanel';
import SuperAdminDetails from '@/components/Misc/SuperAdminDetails.vue';
import OwnerBillingPanel from '@/components/Panels/OwnerBillingPanel.vue';

export default {
    metaInfo: {
        title: 'ManageOwnersCreate ',
    },
    components: {
        OwnerSubscriptionPanel,
        SuperAdminDetails,
        OwnerBillingPanel,
        Spinner,
        EmbedModal,
        LineChart,
        OwnerSettings,
        IntegrationSettings,
    },
    data() {
        return {
            embedHtml: null,
            loading: true,
            editing: false,
            token: '',
            name: '',
            address1: '',
            address2: '',
            city: '',
            message: '',
            state: '',
            zipCode: '',
            email: '',
            ownerId: 0,
            owners: [],
            primaryContact: '',
            website: null,
            primaryContactPhoneNumber: '',
            primaryContactEmail: '',
            funeralHomeNumber: '',
            devHome: false,
            OwnerIdList: [
                { text: 'Shane', val: 0 },
                { text: 'Vertin', val: 1 },
                { text: 'Dev Home', val: 2 },
                { text: 'Dev Owner', val: 3 },
            ],
            requiredRules: [v => !!v || 'This field is required'],
            isDevEnv: process.env.VUE_APP_ENV,
            funeralHomeData: {},
            busy: false,
            loading: false,
            undoMigrationDialog: false,
            undoMigrationStatus: 0, // 0 = not started, 1 = in progress, 2 = completed
            token: '',
            snackbar: false,
            message: '',
            stripeId: '',
            isDevHome: false,
            detailsForm: {
                name: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipCode: '',
                email: '',
                ownerId: 0,
                primaryContact: '',
                primaryContactPhoneNumber: '',
                primaryContactEmail: '',
                funeralHomeNumber: '',
                devHome: true,
            },

            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            hasSubStartDate: false,
            date: new Date(),
            menu: false,
            modal: false,
            menu2: false,
            cancelDialog: false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            bandwidthData: {
                label: 'Bandwidth Data',
                borderColor: '#659fc3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [32, 45, 12, 45, 65, 43, 55],
            },
            streamingExpensesData: {
                label: 'Streaming Expenses',
                borderColor: '#65c3b8',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [99, 120, 44, 54, 65, 23, 43],
            },
            totalExpenseseData: {
                label: 'Total Expenses',
                borderColor: '#c38965',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [23, 45, 32, 87, 67, 66, 56],
            },
            profitData: {
                label: 'Profit',
                borderColor: '#6570c3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [76, 87, 67, 77, 88, 55, 66],
            },
            datacollection: null,
            chartReady: false,
            confirmCancel: false,
            subscriptionStatus: ['active', 'canceled'],
            OwnerIdList: [
                { text: 'Shane', val: 0 },
                { text: 'Vertin', val: 1 },
                { text: 'Dev Home', val: 2 },
                { text: 'Dev Owner', val: 3 },
            ],
            requiredRules: [v => !!v || 'This field is required'],
        };
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;
        });
        if (this.$route.params.id) {
            this.loading = true;
            this.editing = true;
            this.getOwners().then(() => {
                this.getOwner(this.$route.params.id);
            });
        } else {
            this.getOwners();
            this.loading = true;
        }
    },
    watch: {
        tab: {
            handler() {
                if (this.tab === 'settings') {
                    setTimeout(() => {
                        this.$refs.ownersettings.getSettings();
                    });
                }

                if (this.tab === 'profitability') {
                    this.setChart();
                }
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),

        getSettings() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${this.id}`)
                    .then(response => {
                        let data = response.data;
                        if (response.data) {
                            this.settingsForm = {
                                // azureVMSize: data.azureVMSize,
                                wowzaServerLocation: data.wowzaServerLocation,
                                websiteProvider: this.websiteProviders[data.websiteProvider],
                                streamingProvider: this.streamingProviders[data.streamingProvider],
                                // displayTutorial: data.displayTutorial,
                                // dvdForSale: data.dvdForSale,
                                // displayCTA: data.displayCTA,
                                transcoded: data.transcoded,
                                whiteLabel: data.whiteLabel,
                                support: data.support,
                                callToActionHeading: data.callToActionHeading,
                                callToActionButtonText: data.callToActionButtonText,
                                callToActionButtonLink: data.callToActionButtonLink,
                                // enablePreview: data.enablePreview,
                                embedCodeId: data.embedCodeId,
                            };
                        }
                    })
                    .catch(error => {
                        this.$emit('message', error.response.data);
                    });
            } else {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/owners/settings/${this.id}`)
                    .then(response => {
                        let data = response.data;
                        if (response.data) {
                            this.settingsForm = {
                                // azureVMSize: data.azureVMSize,
                                wowzaServerLocation: data.wowzaServerLocation,
                                websiteProvider: this.websiteProviders[data.websiteProvider],
                                streamingProvider: this.streamingProviders[data.streamingProvider],
                                // displayTutorial: data.displayTutorial,
                                // enablePreview: data.enablePreview,
                                // dvdForSale: data.dvdForSale,
                                // displayCTA: data.displayCTA,
                                transcoded: data.transcoded,
                                whiteLabel: data.whiteLabel,
                                support: data.support,
                                callToActionHeading: data.callToActionHeading,
                                callToActionButtonText: data.callToActionButtonText,
                                callToActionButtonLink: data.callToActionButtonLink,
                                embedCodeId: data.embedCodeId,
                            };
                        }
                    })
                    .catch(error => {});
            }
        },
        setSnackBar(message) {
            this.message = message;
            this.snackbar = true;
        },
        saveOwner(payload) {
            let valid = this.$refs.form.validate();

            if (valid) {
                let data = {
                    name: this.name,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    email: this.email,
                    primaryContact: this.primaryContact,
                    primaryContactEmail: this.primaryContactEmail,
                    primaryContactPhoneNumber: this.primaryContactPhoneNumber,
                    website: this.website,
                };

                // temp
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    let query = process.env.VUE_APP_API + '/Owners';

                    if (this.editing) {
                        query = `${query}/${this.$route.params.id}`;

                        this.axios
                            .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                            .put(query, data)
                            .then(response => {
                                this.showSnackbar({ message: `${this.name} successfully updated` });
                                this.$router.push({ name: 'Owners' });
                            })
                            .catch(error => {
                                this.showSnackbar({ message: 'Error! Please try again' });
                            });
                    } else {
                        this.axios
                            .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                            .post(query, data)
                            .then(response => {
                                this.showSnackbar({ message: `${this.name} successfully added` });
                                this.$router.push({ name: 'Owners' });
                            })
                            .catch(error => {
                                this.showSnackbar({ message: 'Error! Please try again' });
                            });
                    }
                });
            } else {
                this.showSnackbar({ message: 'Error! Please fix form errors' });
            }
        },
        getOwners() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/Owners`)
                        .then(response => {
                            this.OwnerIdList = response.data.owners.map(owner => {
                                return {
                                    text: owner.name,
                                    val: owner.id,
                                };
                            });

                            this.loading = false;
                            resolve();
                        })
                        .catch(error => {
                            this.loading = false;
                            this.showSnackbar({ message: 'Error! Please try again' });
                            reject();
                        });
                });
            });
        },
        getOwner(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/Owners/${id}`)
                    .then(response => {
                        this.loading = false;
                        let result = response.data;

                        (this.name = result.name),
                            (this.address1 = result.address1),
                            (this.address2 = result.address2),
                            (this.city = result.city),
                            (this.state = result.state),
                            (this.zipCode = result.zipCode),
                            (this.email = result.email),
                            (this.primaryContact = result.primaryContact),
                            (this.primaryContactEmail = result.primaryContactEmail),
                            (this.primaryContactPhoneNumber = result.primaryContactPhoneNumber),
                            (this.website = result.website);
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error! Please try again' });
                    });
            });
        },
        setChart() {
            let labels = [];
            for (let i = 0; i < 7; i++) {
                labels.push(this.$moment(this.startOfWeek).add(i, 'days').format('MM/DD/YYYY'));
            }
            this.datacollection = {
                labels: labels,
                datasets: [this.bandwidthData, this.streamingExpensesData, this.totalExpenseseData, this.profitData],
            };
        },
    },
    computed: {
        title() {
            return this.editing ? `Editing ${this.name}` : 'Create New Owner';
        },
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.row {
    margin-top: 1.5rem;
}

.custom-checkbox {
    margin-top: 1rem;
}

.save-btn {
    width: 40%;
}

.form-group {
    margin-top: 2rem;
}
</style>

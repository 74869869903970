/** @function log
 * @param {string} message Message to log
 * @param {string} color CSS color value
 */
export function log(message, color = null) {
    if (color) {
        return console.log(`%c${message}`, `color: ${color};`);
    }
    console.log(message);
}

export function logObjectWithTitle(object, title, color = 'black') {
    console.log(`%c==== ${title} ====`, `color: ${color}`);
    console.log(object);
}

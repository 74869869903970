<template>
    <div v-if="story" class="upload-page-wrapper stories-page">
        <animated-waves-background
            :startColor="customStartColor"
            :endColor="customEndColor"
            style="z-index: 0"
            class="animated-bg-comp d-none d-sm-block"
        />

        <div style="z-index: 2" class="logo-wrap d-none d-sm-block" v-if="logo">
            <img style="max-width: 100%; max-height: 100%" :src="logo" />
        </div>

        <div style="min-height: 100%" class="d-flex justify-content-center align-center">
            <div class="splash rounded-xl">
                <div v-if="videoCaptureStep != 2">
                    <div class="rounded-xl pa-2 mb-4" style="background-color: #eff0f3">
                        <div class="d-flex text-center flex-column align-center">
                            <v-avatar
                                class="mt-2 mb-4 desk-avatar"
                                style="outline: 4px solid #ccc"
                                v-if="story.photoUrl"
                                :size="videoCaptureStep == 0 ? 140 : 70"
                            >
                                <v-img :src="story.photoUrl" :lazy-src="story.photoUrl" alt="Avatar" />
                            </v-avatar>

                            <v-avatar
                                class="mt-2 mb-4 mobile-avatar"
                                style="outline: 4px solid #ccc"
                                v-if="story.photoUrl"
                                size="70"
                            >
                                <v-img :src="story.photoUrl" :lazy-src="story.photoUrl" alt="Avatar" />
                            </v-avatar>
                            <span>In Memory Of</span>
                            <h4 class="mb-0">{{ story.firstName }} {{ story.lastName }}</h4>
                            <!-- <div style="border: 2px solid red">{{ os }}</div>
                            <div style="border: 2px solid red">is mobile {{ isMobile }}</div> -->
                        </div>
                    </div>
                </div>
                <v-divider v-if="videoCaptureStep != 2" class="d-none d-sm-block"></v-divider>

                <div class="text-center" style="z-index: 1">
                    <div>
                        <div v-show="videoCaptureStep == 0">
                            <h1 class="mobile-title">Keep the Memory Alive</h1>
                            <p>
                                The family of {{ story.firstName }} would like you to share your favorite memory of
                                {{ story.firstName }}.
                            </p>

                            <div>
                                <div>
                                    <v-row>
                                        <v-col class="d-none d-sm-flex" cols="3"></v-col>
                                        <v-col>
                                            <!-- <v-img class="stories-svg" :src="storiesSvg"></v-img> -->
                                            <div class="envelope-container">
                                                <!-- style="margin-top: -60px" -->
                                                <animated-envelope
                                                    ref="animatedEnvelope"
                                                    @is-open="val => (envelopeOpen = val)"
                                                    v-if="story.firstName && videoCaptureStep == 0"
                                                >
                                                    <template v-slot:content>
                                                        <v-card-text
                                                            style="min-height: 100%; font-size: 1.2rem"
                                                            class="d-flex flex-column justify-content-center"
                                                        >
                                                            <v-window v-model="inviteIndex">
                                                                <v-window-item :value="0">
                                                                    <p>Dear friends and loved ones,</p>

                                                                    <p>
                                                                        <br />
                                                                        Remembering {{ story.firstName }}
                                                                        {{ story.lastName }}, we come together not just
                                                                        in grief, but in appreciation of the legacy and
                                                                        the life so beautifully lived. I'm calling on
                                                                        each of you to partake in creating a memorial
                                                                        video that will be a testament to the love, joy,
                                                                        and inspiration {{ story.firstName }} brought
                                                                        into our lives.
                                                                    </p>

                                                                    <p>
                                                                        Please contribute to this loving tribute by
                                                                        <strong
                                                                            >submitting a short video message of your
                                                                            favorite story of
                                                                            {{ story.firstName }}</strong
                                                                        >. We will weave these stories together and
                                                                        share them directly with the immediate family.
                                                                    </p>

                                                                    <p>
                                                                        <br />
                                                                        Sincerely, <br />
                                                                        {{ story.funeralHomeName }}
                                                                    </p>
                                                                    <p>
                                                                        On Behalf of :<br />
                                                                        {{ story.firstName }}'s Immediate Family
                                                                    </p>

                                                                    <div class="d-flex justify-content-center">
                                                                        <div
                                                                            class="flex-grow-1 d-flex justify-content-center"
                                                                            style="max-width: 350px"
                                                                        >
                                                                            <v-btn
                                                                                class="flex-sm-grow-0 flex-grow-1 mt-4"
                                                                                rounded
                                                                                depressed
                                                                                dark
                                                                                color="primary"
                                                                                @click="inviteIndex = 1"
                                                                                >next</v-btn
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </v-window-item>
                                                                <v-window-item :value="1">
                                                                    <h4 class="text-center mb-6">
                                                                        First, Tell Us About Yourself
                                                                    </h4>
                                                                    <div class="d-flex justify-content-center">
                                                                        <div
                                                                            style="max-width: 350px"
                                                                            class="d-flex flex-column flex-grow-1"
                                                                        >
                                                                            <v-text-field
                                                                                label="First Name"
                                                                                v-model="uploadUserFirstName"
                                                                            ></v-text-field>
                                                                            <v-text-field
                                                                                label="Last Name"
                                                                                v-model="uploadUserLastName"
                                                                            ></v-text-field>
                                                                            <v-text-field
                                                                                label="Email"
                                                                                v-model="uploadUserEmail"
                                                                            ></v-text-field>
                                                                            <div
                                                                                class="d-flex justify-content-center flex-wrap mt-4 pa-2"
                                                                                style="gap: 10px"
                                                                            >
                                                                                <!-- <v-btn
                                                                                    class="flex-grow-1"
                                                                                    rounded
                                                                                    depressed
                                                                                    @click="inviteIndex = 0"
                                                                                    >prev</v-btn
                                                                                > -->
                                                                                <v-btn
                                                                                    class="flex-grow-1 mt-6"
                                                                                    rounded
                                                                                    @click="initWebcamContribute"
                                                                                    color="primary"
                                                                                    depressed
                                                                                    dark
                                                                                    >Next</v-btn
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-window-item>
                                                            </v-window>
                                                        </v-card-text>
                                                    </template>
                                                </animated-envelope>
                                            </div>
                                        </v-col>
                                        <v-col class="d-none d-sm-flex" cols="3"></v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>

                        <div v-show="videoCaptureStep == 1">
                            <div style="height: 100%" class="d-flex justify-center">
                                <div
                                    style="overflow: hidden"
                                    class="d-flex flex-row justify-center align-center"
                                    :class="recordedPortrait ? 'portrait-player' : 'landscape-player'"
                                >
                                    <video
                                        id="recording-player"
                                        style="height: auto; width: 100%; max-width: 100%"
                                        class="flip-horizontal rounded-xl"
                                        muted
                                        autoplay
                                        loop
                                        controls
                                        playsinline
                                        src=""
                                    ></video>
                                </div>
                            </div>
                        </div>

                        <div class="mt-6" v-if="videoCaptureStep == 2">
                            <h2>Thank you for sharing your story</h2>
                            <p class="mt-4">*You can now close this page</p>
                        </div>
                    </div>
                </div>
                <div style="position: relative" class="splash-actions flex-grow-1 d-flex flex-column justify-center">
                    <div>
                        <div class="d-flex justify-content-center" style="gap: 10px" v-if="videoCaptureStep == 0">
                            <v-btn
                                v-show="!envelopeOpen"
                                rounded
                                @click="toggleEnvelopeOpen"
                                color="primary"
                                depressed
                                dark
                                >Open</v-btn
                            >
                        </div>
                        <div class="d-flex justify-content-center" style="gap: 10px" v-if="videoCaptureStep == 1">
                            <v-btn color="error" rounded depressed @click="resetWebcamCapture">Redo</v-btn>
                            <v-btn rounded color="primary" @click="submitRecording" depressed dark>Submit Video</v-btn>
                        </div>
                        <div
                            class="text-center d-flex flex-column align-center"
                            style="gap: 20px"
                            v-if="videoCaptureStep == 2"
                        >
                            <div class="d-flex justify-center">
                                <v-img class="notebook-svg" :src="notebookSvg"></v-img>
                            </div>
                            <h2 class="my-8" style="font-size: 1.3rem">Invite Others to Share Their Story</h2>
                            <v-btn class="mt-4" rounded depressed color="success" @click="inviteLinkModal = true">
                                <span>Share</span>
                                <font-awesome-icon class="ml-2" icon="fa-solid fa-share"></font-awesome-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="videoCaptureStep == 2" class="undo-submit-btn text-right">
                        <a class="text-decoration-underline" href="" @click.prevent="undoSubmit">Undo Submit</a>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="contributeModal" max-width="800px">
            <v-card>
                <v-card-title>
                    <h3>Contribute</h3>
                </v-card-title>
                <v-card-text>
                    <div style="gap: 12px" class="d-flex flex-column">
                        <v-btn @click="initWebcamContribute" depressed>Webcam</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog id="webcam-modal" v-model="webcamModal" fullscreen>
            <v-card>
                <div class="py-3" v-if="videoCaptureStep == 0 || videoCaptureStep == 1">
                    <div class="auto-start-timer">
                        <v-progress-circular
                            v-if="displayAutoStartCountdown && showInputs && !loading"
                            v-model="autoStartRemaining"
                            color="white"
                            size="150"
                        >
                            <countdown-timer
                                class="white--text"
                                :initial-time="autoStartInitialTime"
                                :output-format="'seconds'"
                                @complete="autoStartCountdownComplete"
                                @remaining-time="val => handleAutoStartRemaining(val)"
                            ></countdown-timer>
                        </v-progress-circular>
                    </div>
                    <video id="webcam-player" class="flip-horizontal" muted autoplay loop playsinline src=""></video>
                    <div class="webcam-overlay" :class="this.portrait ? 'mobile-fov' : 'desktop-fov'">
                        <v-overlay
                            :opacity="0.7"
                            style="outline: 2px solid black"
                            z-index="6"
                            color="black"
                            :absolute="true"
                            :value="displayTips"
                        >
                            <v-card class="text-center" width="300px" light>
                                <v-window v-model="tipIndex">
                                    <v-window-item :key="0">
                                        <v-card-title class="d-flex justify-center">Be Concise</v-card-title>
                                        <v-card-text>
                                            <v-divider class="mt-0"></v-divider>
                                            <div class="d-flex flex-column">
                                                <font-awesome-icon
                                                    class="my-4"
                                                    style="font-size: 1.5rem"
                                                    icon="fa-regular fa-clock"
                                                ></font-awesome-icon>

                                                <span class="mt-2">You will have 2 minutes to share your story</span>
                                            </div>
                                        </v-card-text>
                                    </v-window-item>
                                    <v-window-item :key="1">
                                        <v-card-title class="d-flex justify-center">Don't Compare</v-card-title>
                                        <v-card-text>
                                            <v-divider class="mt-0"></v-divider>
                                            <div class="d-flex flex-column">
                                                <font-awesome-icon
                                                    class="my-4"
                                                    style="font-size: 1.5rem"
                                                    icon="fa-regular fa-scale-balanced"
                                                ></font-awesome-icon>

                                                <span class="mt-2"
                                                    >Refrain from saying phrases like "I understand what you're going
                                                    through"</span
                                                >
                                            </div>
                                        </v-card-text>
                                    </v-window-item>
                                    <v-window-item :key="2">
                                        <v-card-title class="d-flex justify-center">Be Specific</v-card-title>
                                        <v-card-text>
                                            <v-divider class="mt-0"></v-divider>
                                            <div class="d-flex flex-column">
                                                <font-awesome-icon
                                                    class="my-4"
                                                    style="font-size: 1.5rem"
                                                    icon="fa-regular fa-bullseye-arrow"
                                                ></font-awesome-icon>

                                                <span class="mt-2"
                                                    >You can express general condolences, but try to include a specific
                                                    memory</span
                                                >
                                            </div>
                                        </v-card-text>
                                    </v-window-item>
                                </v-window>

                                <v-card-actions class="d-flex flex-column">
                                    <div class="py-3">
                                        <v-item-group v-model="tipIndex" class="text-center" mandatory>
                                            <v-item v-for="n in 3" :key="`btn-${n}`" v-slot="{ active, toggle }">
                                                <v-btn small :input-value="active" icon @click="toggle">
                                                    <v-icon style="font-size: 1rem">mdi-record</v-icon>
                                                </v-btn>
                                            </v-item>
                                        </v-item-group>
                                    </div>
                                    <v-btn depressed block v-if="tipIndex < 2" @click="tipIndex++">Next</v-btn>
                                    <v-btn depressed block v-else @click="dismissTips">I'm Ready</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-overlay>
                        <div class="actions-top d-flex justify-space-between" style="z-index: 8">
                            <v-btn fab dark small @click="cancelWebcam">
                                <font-awesome-icon style="font-size: 1rem" icon="fa-solid fa-x"></font-awesome-icon>
                            </v-btn>
                            <div class="d-flex">
                                <div
                                    v-if="videoCaptureStep == 0 && showInputs"
                                    class="d-flex"
                                    style="gap: 12px; z-index: 8"
                                >
                                    <v-btn
                                        @click="openUserInfoSettings"
                                        :disabled="loading || displayTips"
                                        fab
                                        dark
                                        small
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-gear"
                                            style="font-size: 1rem"
                                        ></font-awesome-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="toggleFacingMode"
                                        v-if="isMobile && videoInputs.length > 0"
                                        :disabled="recordInProgress || displayTips || videoInputs.length == 0"
                                        fab
                                        dark
                                        small
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-rotate"
                                            style="font-size: 1rem"
                                        ></font-awesome-icon>
                                    </v-btn>
                                    <!-- start: video input select -->
                                    <v-menu
                                        ref="vidInputMenu"
                                        v-if="!isMobile && videoInputs.length > 0"
                                        :key="'vid-inputs' + refreshKey"
                                        max-height="200px"
                                        nudge-bottom="12px"
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                :disabled="
                                                    recordInProgress ||
                                                    displayTips ||
                                                    loading ||
                                                    videoInputs.length == 0
                                                "
                                                fab
                                                dark
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-video"
                                                    style="font-size: 1rem"
                                                ></font-awesome-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                @click="selectVideoIn(item)"
                                                v-for="(item, index) in videoInputs"
                                                :key="'video-in-' + item.deviceId + refreshKey"
                                            >
                                                <font-awesome-icon
                                                    v-if="selectedVideoIn == item.deviceId"
                                                    class="mr-2"
                                                    style="font-size: 1rem; color: #61affe"
                                                    icon="fa-solid fa-circle-check"
                                                ></font-awesome-icon>
                                                <v-list-item-title>{{ item.label }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- end: video input select -->

                                    <!-- start: audio input select -->
                                    <v-menu
                                        v-if="audioInputs.length > 0 && showInputs"
                                        ref="audInputMenu"
                                        :key="'aud-inputs' + refreshKey"
                                        max-height="200px"
                                        offset-y
                                        nudge-bottom="12px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                :disabled="
                                                    recordInProgress ||
                                                    displayTips ||
                                                    loading ||
                                                    audioInputs.length == 0
                                                "
                                                fab
                                                dark
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-microphone"
                                                    style="font-size: 1rem"
                                                ></font-awesome-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                @click="selectAudioIn(item)"
                                                v-for="(item, index) in audioInputs"
                                                :key="'audio-in-' + item.deviceId + refreshKey"
                                            >
                                                <font-awesome-icon
                                                    v-if="selectedAudioIn == item.deviceId"
                                                    class="mr-2"
                                                    style="font-size: 1rem; color: #61affe"
                                                    icon="fa-solid fa-circle-check"
                                                ></font-awesome-icon>
                                                <v-list-item-title>{{ item.label }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <!-- end: audio input select -->
                                </div>
                            </div>
                        </div>

                        <div class="actions-bottom flex-column">
                            <div v-if="videoCaptureStep == 0">
                                <div v-if="recordInProgress" class="d-flex justify-center flex-column align-center">
                                    <div class="error--text" v-if="displayRemainingTimeWarning">
                                        <span>Warning: Recording will end soon</span>
                                    </div>
                                    <countdown-timer
                                        :class="displayRemainingTimeWarning ? 'error--text' : 'white--text'"
                                        style="font-size: 2rem"
                                        :initial-time="this.maxRecordingSeconds"
                                        :output-format="'timestamp'"
                                        @remaining-time="val => handleRemainingTime(val)"
                                    ></countdown-timer>
                                </div>
                                <div class="d-flex justify-center">
                                    <font-awesome-icon
                                        v-if="!recordInProgress"
                                        @click="initWebcamCapture"
                                        :class="loading ? 'disabled' : ''"
                                        icon="fa-circle"
                                        style="font-size: 3rem; color: #ff5252"
                                    ></font-awesome-icon>

                                    <font-awesome-icon
                                        v-else
                                        @click="stopRecording"
                                        icon="fa-stop"
                                        :class="loading ? 'disabled' : ''"
                                        style="font-size: 3rem; color: #ff5252"
                                    ></font-awesome-icon>
                                </div>
                            </div>
                        </div>

                        <div v-if="loading" class="d-flex justify-center align-items-center loading-overlay">
                            <v-progress-circular color="primary" size="80" indeterminate></v-progress-circular>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500px" v-model="showProgress">
            <v-card>
                <v-card-title>Progress</v-card-title>
                <v-card-text>
                    <v-progress-linear color="primary" v-model="totalProgress"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="unauthorizedError" max-width="600px">
            <v-card>
                <v-card-title>Error</v-card-title>
                <v-card-text>Unable to authorize access. Please visit the upload link to gain access.</v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500px" v-if="selectedStory" @click:outside="deleteModal = false" v-model="deleteModal">
            <v-card>
                <v-card-title>Delete Story?</v-card-title>
                <v-card-text>This cannot be undone, confirm to continue.</v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="deleteModal = false">Cancel</v-btn>
                    <v-btn :disabled="loading" depressed color="error" @click="deleteStory(selectedStory.id)"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <tribute-story-uploader
            v-if="token && story.eventId"
            ref="storyUploader"
            @refresh="refreshTributeStories(story.eventId)"
            @progress="val => handleUploadProgress(val)"
            @upload-success="result => handleUploadSuccess(result)"
            :eventId="story.eventId"
            :hide-button="true"
            :token="token"
            :uploadUserFirstName="uploadUserFirstName"
            :uploadUserLastName="uploadUserLastName"
            :uploadUserEmail="uploadUserEmail"
            :uploadUserRelationship="uploadUserRelationship"
        ></tribute-story-uploader>

        <v-dialog v-model="userInfoModal" max-width="600px">
            <v-card>
                <v-card-title>Who's story is this?</v-card-title>
                <v-card-text>
                    <v-text-field label="First Name" v-model="uploadUserFirstName"></v-text-field>
                    <v-text-field label="Last Name" v-model="uploadUserLastName"></v-text-field>
                    <v-text-field label="Email" v-model="uploadUserEmail"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <div class="d-flex flex-column flex-sm-row justify-space-between" style="width: 100%; gap: 12px">
                        <v-btn @click="userInfoModal = false" depressed>Cancel</v-btn>
                        <v-btn depressed color="primary" @click="saveUserInfo">Continue</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <branded-modal max-width="500px" @close="inviteLinkModal = false" v-model="inviteLinkModal">
            <template v-slot:title>
                <h2>Invite Others</h2>
            </template>

            <template v-slot:body>
                <v-text-field :value="story.contributeLink" readonly label="Url">
                    <template v-slot:append-outer>
                        <v-btn @click="copyContributeLink" dark>
                            <font-awesome-icon icon="fa-regular fa-clone"></font-awesome-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </template>
        </branded-modal>
    </div>
</template>
<script>
import logoIconSrc from '@/assets/images/logo_title.png';
import msScannerIconSrc from '@/assets/images/msScannerIcon.png';
import googleScannerIconSrc from '@/assets/images/GoogleScannerIcon.png';
import moment from 'moment';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import TributeStories from '@/components/ManageService/Tribute/TributeStories.vue';
import AnimatedWavesBackground from '@/components/ui/AnimatedWavesBackground.vue';
import { getLighterShade, getDarkerShade } from '@/utilities/color.js';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import CountdownTimer from '@/components/ui/CountdownTimer.vue';
import TributeStoryUploader from '@/components/ManageService/Tribute/TributeStoryUploader.vue';
import { v4 as uuidv4 } from 'uuid';
import { debounceV2 } from '@/utilities/debounce';
import storiesSvg from '@/assets/svg/stories.svg';
import notebookSvg from '@/assets/svg/notebook.svg';
import AnimatedEnvelope from '@/components/ui/AnimatedEnvelope.vue';

export default {
    metaInfo: {
        title: 'Upload Story',
        meta: [
            {
                name: 'robots',
                content: 'noindex',
            },
        ],
    },
    components: {
        BrandedModal,
        TributeStories,
        AnimatedWavesBackground,
        CustomTooltip,
        CountdownTimer,
        TributeStoryUploader,
        AnimatedEnvelope,
    },
    data() {
        return {
            storiesSvg,
            notebookSvg,
            contributeModal: false,
            webcamModal: false,
            recordInProgress: false,
            eventId: 0,
            token: '',
            logoIconSrc,
            loading: false,
            story: {},
            os: null,
            browser: '',
            isMobile: false,
            unauthorizedError: false,
            formattedDate: null,
            msScannerIconSrc,
            googleScannerIconSrc,
            appId: '1665247224',
            event: null,
            service: null,
            downloadProgress: {},
            overallProgress: 0,
            customStartColor: null,
            customEndColor: null,
            logo: null,
            render: {},
            guidStorageName: '',
            videoStream: null,
            videoCaptureStep: 0,
            maxRecordingSeconds: 120,
            recorder: null,
            loading: true,
            recordingFile: null,
            showProgress: false,
            totalProgress: 0,
            orientation: '',
            resizeObserver: null,
            audioInputs: [],
            videoInputs: [],
            selectedAudioIn: '',
            selectedVideoIn: '',
            facingMode: 'user',
            userUploads: {},
            stories: [],
            selectedStory: {},
            deleteModal: false,
            refreshKey: 0,
            showInputs: false,
            displayRecording: false,
            recordedPortrait: false,
            lastUpload: {},
            displayTips: false,
            tipIndex: 0,
            displayRemainingTimeWarning: false,
            timeToDisplayWarning: 10,
            abortController: null,
            destroyPendingStream: false,
            uploadUserFirstName: '',
            uploadUserLastName: '',
            uploadUserEmail: '',
            uploadUserRelationship: '',
            userInfoModal: false,
            inviteIndex: 0,
            displayAutoStartCountdown: false,
            autoStartRemaining: 100,
            autoStartInitialTime: 5,
            inviteLinkModal: false,
            envelopeOpen: false,
        };
    },
    watch: {
        webcamModal(val) {
            this.refreshKey++;

            if (!val) {
                this.stopTracks(this.videoStream);
                this.displayRemainingTimeWarning = false;
                this.displayAutoStartCountdown = false;
                this.autoStartRemaining = 100;
            }
        },
        videoStream(newVal, oldVal) {
            if (oldVal) {
                this.stopTracks(oldVal);
            }

            if (this.destroyPendingStream) {
                this.destroyPendingStream = false;
                this.videoStream = null;
            }
        },
        envelopeOpen(newVal) {
            if (!newVal) {
                this.inviteIndex = 0;
            }
        },
    },
    computed: {
        portrait() {
            return this.orientation === 'portrait';
        },
        disableContribute() {
            return this.render || this.checkDatePassed(this.story.deadline);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        toggleEnvelopeOpen() {
            if (this.$refs.animatedEnvelope) {
                this.$refs.animatedEnvelope.toggleCard(!this.envelopeOpen);
            }
        },
        copyContributeLink() {
            if (!this.story.contributeLink) {
                this.showSnackbar({ message: 'Error copying link', color: 'error' });
                return;
            }
            navigator.clipboard.writeText(this.story.contributeLink);
            this.showSnackbar({ message: 'Link Copied' });
        },
        handleAutoStartRemaining(val) {
            const rawProgress = (val / this.autoStartInitialTime) * 100;
            const progress = Math.min(Math.max(Math.round(rawProgress), 0), 100);
            this.autoStartRemaining = progress;
        },
        autoStartCountdownComplete() {
            this.displayAutoStartCountdown = false;
            this.autoStartRemaining = 100;
            this.initWebcamCapture();
        },
        resetUserInfo() {
            this.uploadUserFirstName = '';
            this.uploadUserLastName = '';
            this.uploadUserEmail = '';
            this.uploadUserRelationship = '';
        },
        saveUserInfo() {
            this.userInfoModal = false;

            if (!this.webcamModal) {
                this.initWebcamContribute();
            }
        },
        dismissTips() {
            this.displayTips = false;
            this.tipIndex = 0;
            setTimeout(() => {
                this.displayAutoStartCountdown = true;
            }, 500);
        },
        handleRemainingTime(val) {
            if (val <= this.timeToDisplayWarning) {
                this.displayRemainingTimeWarning = true;
            }
        },
        undoSubmit() {
            if (!this.lastUpload.file) {
                this.showSnackbar({ message: 'Error: Last upload file not found', color: 'error' });
                return;
            }

            if (this.lastUpload.file) {
                this.displayRecordingResult(this.lastUpload.file);
                this.videoCaptureStep = 1;

                this.deleteStory(this.lastUpload.id, this.lastUpload.guid);
            }
        },
        openUserInfoSettings() {
            // this.cancelWebcam();
            this.userInfoModal = true;
        },
        cancelWebcam() {
            this.stopTracks(this.videoStream);
            this.destroyPendingStream = true;
            if (this.abortController) {
                this.abortController.abort();
            }
            this.closeWebcam();
            this.videoCaptureStep = 0;
            this.videoStream = null;
        },
        checkDatePassed(datetime) {
            // Check if specifiedDatetime is null or empty
            if (!datetime) {
                return false; // Exit the method early
            }

            // Convert the specified datetime string to a Date object
            const specifiedDate = new Date(datetime);

            // Get the current date and time
            const currentDate = new Date();

            // Compare the dates
            return currentDate > specifiedDate;
        },

        async deleteStory(id, guid) {
            if (!id) {
                this.showSnackbar({ message: 'Error deleting story', color: 'error' });
                return;
            }

            if (!guid) {
                this.showSnackbar({ message: 'Error deleting story' });
            }

            this.loading = true;
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/TributeStory/unique-delete/${id}/${guid}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Delete Success' });
                    let found = this.stories.find(x => x.id == id);
                    if (found) {
                        let index = this.stories.indexOf(found);

                        this.stories.splice(index, 1);
                    }
                    this.selectedStory = {};
                    this.deleteModal = false;
                })
                .catch(error => {
                    console.log(error, 'error deleting story');

                    this.showSnackbar({ message: 'Error deleting story', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        blurMenu(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].save();
            }
        },
        toggleFacingMode() {
            if (this.facingMode == 'user') {
                this.facingMode = { exact: 'environment' };
            } else {
                this.facingMode = 'user';
            }
            this.resetWebcamCapture();
        },
        selectAudioIn(item) {
            this.selectedAudioIn = item.deviceId;
            this.resetWebcamCapture();
        },
        selectVideoIn(item) {
            this.selectedAudioIn = item.deviceId;
            this.resetWebcamCapture();
        },
        handleUploadProgress(val) {
            if (val > 0) this.totalProgress = val;
        },
        handleUploadSuccess(result) {
            if (result.guid) {
                this.lastUpload = {
                    id: result.id,
                    guid: result.guid,
                    file: this.recordingFile,
                };
            }

            this.showSnackbar({ message: 'Upload success' });
            this.closeWebcam();
            this.videoCaptureStep = 2;
            this.contributeModal = false;
            this.totalProgress = 100;
            this.showProgress = false;

            setTimeout(() => {
                this.totalProgress = 0;
                this.loading = false;
            }, 500);
        },
        submitRecording() {
            if (!this.recordingFile) {
                this.showSnackbar({ message: 'Recording file not found', color: 'error' });
                return;
            }

            if (!this.$refs.storyUploader) {
                this.showSnackbar({ message: 'Error accessing uploader', color: 'error' });
                return;
            }

            this.pauseVideoPlayer('recording-player');

            this.showProgress = true;
            this.$refs.storyUploader.addAndUploadFile(this.recordingFile);
        },
        pauseVideoPlayer(id) {
            const el = document.getElementById(id);

            if (el) {
                el.pause();
            }
        },
        resetWebcamCapture() {
            this.loading = true;
            this.videoStream = null;

            const videoElementId = 'webcam-player';
            this.flipPlayerHorizontal(videoElementId, true);
            this.resetVideoPlayer('webcam-player');
            this.resetVideoPlayer('recording-player');
            this.initWebcamContribute();
            this.videoCaptureStep = 0;
            this.loading = false;
        },
        resetVideoPlayer(elementId) {
            const videoElementId = elementId;
            const player = document.getElementById(videoElementId);
            if (player) {
                player.src = null;
                player.srcObject = null;
                player.muted = true;
            }
        },
        initWebcamCapture() {
            if (!this.videoStream) {
                this.showSnackbar({ message: 'Error occured starting recording', color: 'error' });
                return;
            }
            this.displayAutoStartCountdown = false;
            this.autoStartRemaining = 100;
            this.recordInProgress = true;
            this.startRecording(this.videoStream, this.maxRecordingSeconds)
                .then(recordedChunks => {
                    let recordedBlob = new Blob(recordedChunks, {
                        // type: this.os == 'iOS' ? 'video/mp4' : 'video/webm',
                        type: 'video/mp4',
                    });
                    this.videoStream = null;

                    this.displayRecordingResult(recordedBlob);
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.recordInProgress = false;
                });
        },
        flipPlayerHorizontal(videoElementId, bool) {
            var player = document.getElementById(videoElementId);
            if (!player) return;

            if (bool) {
                player.classList.add('flip-horizontal');
            } else {
                player.classList.remove('flip-horizontal');
            }
        },
        setVideoMuted(val) {
            const videoEl = document.getElementById('webcam-player');

            if (videoEl) {
                videoEl.muted = val;
            }
        },
        async displayRecordingResult(blob) {
            const videoElementId = 'recording-player';

            var player = document.getElementById(videoElementId);

            this.videoCaptureStep = 1;
            this.webcamModal = false;
            if (!player) return;

            player.srcObject = null;
            player.muted = false;
            player.classList.remove('flip-horizontal');
            player.muted = false;
            player.setAttribute('playsinline', '');
            player.addEventListener('loadedmetadata', () => {
                player.play();
            });
            player.src = URL.createObjectURL(blob);
            // player.load();

            let tempFileName = uuidv4() + '.mp4';
            // let fileType = this.os == 'iOS' ? 'video/mp4' : 'video/webm';
            let fileType = 'video/mp4';
            this.recordingFile = new File([blob], tempFileName, { type: fileType });
        },
        async startRecording(stream, lengthInSeconds) {
            this.abortController = new AbortController();
            const { signal } = this.abortController;

            let recorder = new MediaRecorder(stream);
            let data = [];

            recorder.ondataavailable = event => data.push(event.data);
            recorder.start();

            this.recorder = recorder;

            let timeWithGracePeriod = lengthInSeconds + 5;

            this.recordingTimeout = setTimeout(() => this.stopRecording(), timeWithGracePeriod * 1000);

            return new Promise((resolve, reject) => {
                signal.addEventListener('abort', () => {
                    this.videoCaptureStep = 0;
                    reject();
                });

                recorder.onstop = () => {
                    clearTimeout(this.recordingTimeout); // Clear the timeout
                    resolve(data);
                };
                recorder.onerror = event => reject(event.name);
            });
        },
        stopRecording() {
            if (this.loading) return;
            if (this.recorder && this.recorder.state === 'recording') {
                this.recorder.stop();
            }
        },
        closeWebcam() {
            this.webcamModal = false;
            this.videoStream = null;
            this.resetVideoPlayer('webcam-player');
            this.resetVideoPlayer('recording-player');
        },
        initWebcamContribute() {
            this.userInfoModal = false;
            this.destroyPendingStream = false;
            this.loading = true;
            this.webcamModal = true;
            this.displayTips = true;

            this.pollForVideoContainer();
        },
        stopTracks(stream) {
            if (!stream) return;

            const tracks = stream.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
        },
        async accessAvInputs(videoEl) {
            if (this.destroyPendingStream) {
                console.log('Cancellation requested, aborting accessAvInputs.');
                return;
            }

            this.checkOrientation();

            const mediaDevices = navigator.mediaDevices;

            try {
                let aspectRatio = this.portrait ? 9 / 16 : 16 / 9;

                if (this.isMobile) {
                    aspectRatio = 16 / 9;
                }
                this.recordedPortrait = this.portrait;

                const constraints = {
                    video: {
                        aspectRatio: { exact: aspectRatio },
                        resizeMode: 'none',
                        deviceId: this.selectedVideoIn ? this.selectedVideoIn : undefined,
                        facingMode: this.facingMode,
                    },
                    audio: {
                        deviceId: this.selectedAudioIn ? this.selectedAudioIn : undefined,
                    },
                };

                if (this.destroyPendingStream) {
                    console.log('Cancellation requested, aborting accessAvInputs.');
                    return;
                }
                const stream = await mediaDevices.getUserMedia(constraints);

                if (this.destroyPendingStream) {
                    console.log('Cancellation requested, aborting after getUserMedia.');
                    // Make sure to stop the stream if it has started
                    this.stopTracks(stream);
                    return;
                }

                videoEl.addEventListener('loadedmetadata', () => {
                    if (this.destroyPendingStream) {
                        console.log('Cancellation requested, aborting accessAvInputs.');
                        this.stopTracks(stream);
                        return;
                    }
                    videoEl.play();

                    setTimeout(() => {
                        if (this.destroyPendingStream) {
                            console.log('Cancellation requested, aborting accessAvInputs.');
                            this.stopTracks(stream);
                            return;
                        }
                        this.loading = false;
                        this.refreshKey++;
                    }, 1000);
                });
                videoEl.srcObject = stream;
                this.videoStream = stream;

                await this.refreshDeviceList();

                if (this.destroyPendingStream) {
                    console.log('Cancellation requested, aborting accessAvInputs.');
                    this.stopTracks(stream);
                    return;
                }

                this.checkActiveInputs(stream);
                this.showInputs = true;
            } catch (err) {
                console.error(err, 'error starting webcam');
            }
        },

        checkActiveInputs(stream) {
            const tracks = stream.getTracks();

            tracks.forEach(track => {
                if (track.kind === 'audio' && !this.selectedAudioIn) {
                    this.selectedAudioIn = track.getSettings().deviceId;
                }

                if (track.kind === 'video' && !this.selectedVideoIn) {
                    this.selectedVideoIn = track.getSettings().deviceId;
                }
            });
        },

        pollForVideoContainer() {
            const videoElementId = 'webcam-player';
            const checkInterval = setInterval(() => {
                if (this.destroyPendingStream) {
                    clearInterval(checkInterval);
                    return;
                }

                const videoContainer = document.getElementById(videoElementId);
                if (videoContainer) {
                    clearInterval(checkInterval);
                    this.accessAvInputs(videoContainer);
                }
            }, 500);
        },
        async refreshDeviceList() {
            const devices = await navigator.mediaDevices.enumerateDevices();

            const videoInputs = devices.filter(d => d.kind === 'videoinput');
            const audioInputs = devices.filter(d => d.kind === 'audioinput');

            this.videoInputs = videoInputs;
            this.audioInputs = audioInputs;
        },
        getStory(slug) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/story-upload-info/${slug}`)
                .then(resp => {
                    let storyData = {
                        ...resp.data,
                        mainPhotoUrl: resp.data.photoUrl,
                        id: resp.data.tributeVideoId,
                    };
                    this.storyData = storyData;

                    if (resp.data.funeralHomeBrandColor) {
                        this.setAnimatedBackgroundColors(resp.data.funeralHomeBrandColor);
                    }

                    if (resp.data.funeralHomeLogo) {
                        this.logo = resp.data.funeralHomeLogo;
                    }

                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status && err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },
        setAnimatedBackgroundColors(baseColor) {
            const darkerShade = getDarkerShade(baseColor, 0.2);

            this.customStartColor = baseColor;
            this.customEndColor = darkerShade;
        },
        getService(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Services/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },
        getEvent(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Events/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'err');
                    if (err.response.status == 401) {
                        this.unauthorizedError = true;
                    }
                    // return err;
                });
        },
        getRender(id) {
            this.loading = true;
            this.axiosInstance
                .get(`/TributeStory/get-render/${id}`)
                .then(resp => {
                    this.render = resp.data;
                })
                .catch(error => {
                    this.render = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createAxiosInstance() {
            return (this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            }));
        },
        async getStoryToken() {
            let params = {
                slug: this.$route.params.slug,
                apiKey: process.env.VUE_APP_STORY_API_KEY,
            };

            return this.axios
                .create({
                    baseURL: process.env.VUE_APP_API,
                })
                .get(`/Story/get-story-token?slug=${params.slug}&apiKey=${params.apiKey}`)
                .then(resp => {
                    return resp.data.token;
                });
        },

        checkOrientation() {
            if (window.innerWidth > window.innerHeight) {
                this.orientation = 'landscape';
            } else {
                this.orientation = 'portrait';
            }
        },
        getOs() {
            var platform = navigator.platform,
                os = null;

            // Define platform groups
            var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
            var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
            var iosPlatforms = ['iPhone', 'iPad', 'iPod'];

            // Use navigator.platform directly for MacOS and iOS detection to increase reliability
            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'MacOS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(navigator.userAgent)) {
                os = 'Android';
            } else if (/Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        },
        isMobileDevice() {
            var os = this.getOs();
            return os == 'iOS' || os == 'Android';
        },
        debouncedResizeHandler: debounceV2(function () {
            this.checkOrientation();
            if (!this.recordInProgress) {
                if (this.webcamModal) {
                    this.resetWebcamCapture();
                }
            }
        }, 100),
        initResizeObserver() {
            this.resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    this.loading = true;
                    this.blurMenu('vidInputMenu');
                    this.blurMenu('audInputMenu');
                    this.debouncedResizeHandler();
                }
            });

            this.resizeObserver.observe(document.body);
        },
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener('deviceorientation', this.checkOrientation, true);
    },
    async mounted() {
        this.initResizeObserver();
        window.addEventListener('deviceorientation', this.checkOrientation, true);

        if (!this.os) {
            this.os = this.getOs();
        }

        this.isMobile = this.isMobileDevice();
        this.checkOrientation();

        this.token = await this.getStoryToken();

        if (this.token) {
            this.createAxiosInstance();

            this.story = await this.getStory(this.$route.params.slug);

            if (this.story) {
                this.eventId = this.story.eventId;

                if (this.eventId) {
                    this.getRender(this.eventId);
                }

                this.event = await this.getEvent(this.eventId);

                this.service = await this.getService(this.story.serviceId);

                if (this.story.deadline && this.story.deadline != '0001-01-01T00:00:00') {
                    let fDate = moment(this.story.deadline).format('MMMM Do YYYY');
                    let fTime = moment(this.story.deadline).format('LT');
                    this.formattedDate = `${fDate} ${fTime}`;
                }
            }
        }

        this.loading = false;
    },
};
</script>
<style lang="scss" scoped>
.upload-page-wrapper.stories-page {
    // border: 2px solid purple;
    // overflow: auto;
    // min-height: 100vh;

    .video-container {
        position: relative;
        width: 100%;
        aspect-ratio: 9/16;
        background-color: black;
        border-radius: 5px;
        // border: 2px solid lime;
    }

    .video-container video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: contain;
    }

    .error-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 18px;
        text-align: center;
    }

    .del-action-btn {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        opacity: 0;
        height: 20px !important;
        width: 20px !important;
    }

    .video-container:hover .del-action-btn {
        opacity: 0.4;
    }

    .del-action-btn:hover {
        opacity: 1 !important;
    }

    .notebook-svg {
        max-width: 400px;
        width: 100%;
    }
}
.disabled {
    color: #e5e5e5 !important;
}
#webcam-player {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background-color: black;
    object-fit: contain;
}

.flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mobile-fov {
    aspect-ratio: 9 / 16;
}
.desktop-fov {
    aspect-ratio: 16 / 9;
}

.auto-start-timer {
    font-size: 6rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
    height: fit-content;
    text-align: center;
}

.webcam-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    max-height: 100%;

    background-color: transparent;
    z-index: 5;
    object-fit: contain;

    .actions-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        width: 100%;
        padding: 10px;
        z-index: 5;
    }

    .actions-bottom {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        width: 100%;
        padding: 10px;
        z-index: 5;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        max-height: 100%;
        background-color: black;
        z-index: 4;
    }
}
.upload-page-wrapper {
    background-color: transparent;
    position: relative;
    // max-width: 100vw;
    // height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    // border: 2px solid orange;

    .action-list-item {
        cursor: pointer;
    }

    .logo-wrap {
        border: none;
        max-width: 100px;
        max-height: 100px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1;
        object-fit: contain;
        padding: 10px;
    }

    .action-list-item:hover {
        background-color: #f8f8f8;
    }

    .dashboard-uploader-wrap {
        background-color: #f8f8f8;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: center;
    }

    .gallery-section {
        width: 100%;
    }

    .gallery-video {
        aspect-ratio: 1;
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
    }

    .animated-bg-comp {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        min-width: 100%;
        max-width: 100%;
        overflow: hidden;
    }
    .envelope-container {
        max-width: 800px;
    }
}

// .splash-container {
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;

//     border: 2px solid cyan !important;
//     overflow: auto;
//     position: relative;
//     padding-bottom: 50px;
// }

.splash {
    background-color: white;
    z-index: 1;
    // margin: 80px;
    padding: 24px;
    width: 80%;
    max-width: 900px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

    // .stories-svg {
    //     // max-width: 200px;
    //     width: 100%;
    // }

    .portrait-player {
        max-height: 450px;
        overflow: hidden;
        aspect-ratio: 9/16;
    }

    .landscape-player {
        max-width: 450px;
        overflow: hidden;
        aspect-ratio: 16/9;
    }

    .mobile-avatar {
        display: none;
    }

    .splash-actions {
        margin-top: 36px;
        padding: 12px 0;
        // border: 2px solid red;
    }

    .undo-submit-btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
@media (max-width: 1250px) {
}

@media (max-width: 1024px) {
}

@media only screen and (max-width: 600px) {
    // .stories-page {
    //     border: 2px solid violet;
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //     height: 100vh;
    //     // overflow: hidden;
    //     // padding: 50px;
    // }
    // .splash-container {
    //     padding: 10px;
    // }

    .splash {
        display: flex;
        flex-direction: column;
        box-shadow: none;
        position: absolute;
        top: 0;
        margin: auto;
        width: 100vw;
        height: 100svh;
        padding: 12px;
        padding-bottom: 0;

        .stories-svg {
            max-width: 50%;
        }

        .mobile-avatar {
            display: block;
        }
        .desk-avatar {
            display: none;
        }

        .mobile-title {
            font-size: 1.8rem;
        }

        .splash-actions {
            margin-top: 0px;
        }

        .envelope-container {
            margin: 24px 0;
        }

        .undo-submit-btn {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .envelope-container {
            padding: 12px;
        }
    }
}
</style>

<template>
    <div>
        <div>
            <!-- Display warning for no graveside contact user support -->
            <div style="font-size: 0.8rem" v-if="event.eventType == 3">
                <p class="mt-2">
                    <i>MemoryShare will not provide proactive support for graveside services.</i>
                </p>
            </div>

            <!-- Display user drop down -->
            <div v-else>
                <div ref="dropDownContainer" class="text-left d-flex justify-space-between align-center">
                    <custom-tooltip :tooltipProps="{ left: true }">
                        <template v-slot:activator>
                            <attached-menu ref="userDropDown" min-width="300px" max-height="400px" offset-y>
                                <template #activator>
                                    <div class="pt-2 pb-2 mt-2" style="display: flex; position: relative">
                                        <v-avatar
                                            @click.stop="copyPhoneToClipboard(tempUser.phoneNo)"
                                            class="elevation-4"
                                            size="32"
                                            color="#0d3d60"
                                        >
                                            <span v-if="tempUser.firstName && tempUser.lastName">
                                                <span class="white--text">{{
                                                    tempUser.firstName[0].toUpperCase() +
                                                    tempUser.lastName[0].toUpperCase()
                                                }}</span></span
                                            >
                                            <span v-else class="white--text">N/A</span>
                                        </v-avatar>

                                        <div class="ml-2 btn-group" style="display: flex; align-items: center">
                                            <div class="pr-2 pointer">
                                                <font-awesome-icon
                                                    class="mr-2 ml-2"
                                                    style="font-size: 0.8rem"
                                                    icon="fa-regular fa-list-dropdown"
                                                />
                                                <span style="font-size: 0.8rem" v-if="tempUser.phoneNo">{{
                                                    tempUser.phoneNo
                                                }}</span>
                                                <span style="font-size: 0.8rem" v-else>{{
                                                    tempUser.id > 0 ? 'XXX.XXX.XXXX' : 'No user selected'
                                                }}</span>
                                            </div>
                                            <v-divider id="btn-divider" vertical></v-divider>
                                            <div
                                                class="pl-2 pr-2 pointer"
                                                :class="tempUser.id > 0 ? '' : 'disabled'"
                                                id="phone-btn"
                                                @click.prevent="getUser()"
                                                @click.stop
                                            >
                                                <font-awesome-icon style="font-size: 0.8rem" icon="fa-solid fa-phone" />
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #content>
                                    <v-list v-if="users.length > 0">
                                        <div v-for="(item, index) in users" :key="index">
                                            <v-list-item @click="updateStreamOwner(item)">
                                                <v-list-item-title
                                                    style="display: flex; justify-content: space-between"
                                                >
                                                    <span v-if="item.firstName || item.lastName">{{
                                                        `${item.firstName} ${item.lastName}`
                                                    }}</span>
                                                    <span v-else>N/A</span>

                                                    <div>
                                                        <span v-if="item.phoneNo">{{ item.phoneNo }}</span>
                                                        <span v-if="!item.phoneNo">xxx-xxx-xxxx</span>
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </div>
                                        <div class="text-center">
                                            <v-btn width="90%" @click="addUser">Add User</v-btn>
                                        </div>
                                    </v-list>

                                    <div v-if="users.length === 0" style="background: white" class="text-center p-3">
                                        <span v-if="loading">Loading users..</span>
                                        <span v-else>No users found..</span>
                                    </div>
                                </template>
                            </attached-menu>
                        </template>

                        <template v-slot:title v-if="tempUser.firstName || tempUser.lastName">
                            <span v-if="tempUser.firstName || tempUser.lastName">{{
                                tempUser.firstName + ' ' + tempUser.lastName
                            }}</span>
                            <span v-else>N/A</span>
                        </template>
                        <template v-slot:content v-if="tempUser.email">
                            <span>{{ tempUser.email }}</span>
                        </template>
                    </custom-tooltip>

                    <div v-if="$auth.role.includes('SuperAdmin')">
                        <v-chip color="#0d3d60" dark @click="phoneTypeModal = true">{{
                            phoneTypes[tempUser.phoneType].label
                        }}</v-chip>
                    </div>
                </div>
                <div style="font-size: 0.8rem">
                    <div v-if="tempUser.phoneNo">
                        <div v-if="tempUser.phoneType == 2 || tempUser.phoneType == 3">
                            <v-alert class="text-center mt-2" dense outlined type="info">
                                <template v-slot:prepend>
                                    <font-awesome-icon
                                        icon="fa-regular fa-utility-pole-double"
                                        style="font-size: 1.1rem"
                                        class="mr-3"
                                    />
                                </template>
                                {{ tempUser.phoneNo }} is a landline phone number. MemoryShare will not contact this
                                number for stream support. Please enter a mobile number MemoryShare may text if any
                                stream issues are detected. Thank you.
                            </v-alert>
                        </div>
                        <p class="mt-2" v-else>
                            <i>MemoryShare may call/text this number if there is an issue with the stream.</i>
                        </p>
                    </div>
                    <div v-else>
                        <v-alert class="text-center mt-2" dense outlined type="warning">
                            {{
                                tempUser.firstName
                                    ? tempUser.firstName[0].toUpperCase() + tempUser.firstName.slice(1)
                                    : 'This user'
                            }}
                            does not have a cell number. We won't be able to contact you if we notice an issue. Please

                            <u @click="getUser" class="pointer">add one now</u>
                        </v-alert>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="phoneTypeModal" max-width="400px">
            <v-card>
                <v-card-title>User Phone Type</v-card-title>
                <v-card-text>
                    <v-text-field disabled readonly :value="computedFullName"></v-text-field>
                    <v-text-field
                        disabled
                        readonly
                        :value="tempUser.phoneNo ? tempUser.phoneNo : 'XXX.XXX.XXXX'"
                    ></v-text-field>
                    <v-select
                        v-model="tempPhoneType"
                        label="Phone Type"
                        :items="filteredPhoneTypes"
                        item-text="label"
                        item-value="value"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="updateUserPhoneType" color="#0c3c60" dark depressed block>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import AttachedMenu from '@/components/ui/AttachedMenu.vue';
import { phoneTypes } from '@/constants';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            phoneTypes,
            tempPhoneType: null,
            phoneTypeModal: false,
        };
    },

    components: { CustomTooltip, AttachedMenu },
    computed: {
        filteredPhoneTypes() {
            return this.phoneTypes.filter(t => t.value != 0 && t.value != 1);
        },
        computedFullName() {
            const { firstName, lastName } = this.tempUser;

            if (!firstName && !lastName) {
                return 'N/A';
            }

            return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
        },
    },
    watch: {
        phoneTypeModal() {
            this.tempPhoneType = this.tempUser.phoneType;
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        getUser() {
            this.$emit('getUser');
        },
        copyPhoneToClipboard(val) {
            if (!this.$auth.role.includes('SuperAdmin')) return;

            navigator.clipboard.writeText(val.replaceAll('.', ''));

            this.showSnackbar({ message: 'Copied phone number to clipboard' });
        },
        updateStreamOwner(user) {
            if (this.$refs.userDropDown) {
                this.$refs.userDropDown.blur();
            }

            this.$emit('updateStreamOwner', user);
        },
        addUser() {
            console.log(this.$refs.userDropDown);

            if (this.$refs.userDropDown) {
                this.$refs.userDropDown.blur();
            }
            this.$emit('addUser');
        },
        updateUserPhoneType() {
            let data = {
                id: this.tempUser.id,
                phoneType: this.tempPhoneType,
            };

            this.$emit('update-phone-type', data);
        },
    },
    props: ['users', 'event', 'tempUser', 'loading'],
};
</script>
<style lang="scss" scoped>
.btn-group {
    border: 2px solid #eee;
    border-radius: 5px;
    transition: 0.3s;
}
.btn-group:hover {
    border: 2px solid white;
    transition: 0.3s;
}
.btn-group:hover #btn-divider {
    opacity: 0;
}
.pointer {
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    transition: 0.3s;
}
.pointer:hover {
    // border: 2px solid #309cf493;
    border: 2px solid #9ecaed;
    box-shadow: 0 0 5px #9ecaed;
    transition: 0.3s;
}
</style>

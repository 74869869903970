<template>
    <AppLayout :has-background="true" :contained="true">
        <div v-if="!$auth.role.includes('SuperAdmin')" class="text-right mb-4">
            <v-btn class="new-service-btn" v-if="$auth.role.includes('FuneralHome')" :to="{ name: 'CreateService' }"
                >+ Create New Service</v-btn
            >
        </div>

        <services-table></services-table>
    </AppLayout>
</template>

<script>
import ServicesTable from '@/components/Tables/Services/ServicesTable.vue';

export default {
    name: 'Services',
    metaInfo: {
        title: 'Services',
    },
    components: {
        ServicesTable,
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat fixed 0 -18rem;
}

.new-service-btn:hover,
.new-service-btn:focus {
    text-decoration: none;
}

.v-data-table-header th {
    white-space: nowrap;
}
</style>

<template>
    <AppLayout :has-background="true" :contained="true">
        <admin-billing-table apiUrl="/admin-tools/get-billing-events"></admin-billing-table>
    </AppLayout>
</template>

<script>
import AdminBillingTable from '@/components/Tables/AdminBillingTable.vue';
export default {
    components: { AdminBillingTable },
    name: 'AdminBilling',
    metaInfo: {
        title: 'Billing',
    },
    data() {
        return {
            message: 'Hello',
            token: '',
        };
    },
    methods: {},
};
</script>

<style>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg')
        no-repeat fixed 0 -18rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableItems,"loading":_vm.tableLoading,"single-expand":true,"server-items-length":_vm.tableTotalCount,"expanded":_vm.expanded,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [5, 25, 50],
            showFirstLastPage: true,
        },"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dateTimeUTC",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.dateTimeUTC).format('MM/DD/YYYY - h:mm a')))])]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"half-expanded"},[_c('section',[_c('h3',[_vm._v("Old Values")]),_c('div',[_vm._v(_vm._s(item.oldValuse || 'N/A'))])]),_c('section',[_c('h3',[_vm._v("New Values")]),_c('div',[_vm._v(_vm._s(item.newValues || 'N/A'))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
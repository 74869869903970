<template>
    <v-dialog v-model="dialog" persistent max-width="320">
        <v-card>
            <v-card-title class="headline"> Reset Password</v-card-title>
            <v-card-text>
                <h6>User: {{ user.email }}</h6>
                <v-form v-model="valid" class="mt-3" ref="form">
                    <v-text-field
                        v-model="password"
                        :rules="[rules.required, rules.password]"
                        type="password"
                        label="Password"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="confirmPassword"
                        :rules="[rules.required, rules.passwordsMatch]"
                        type="password"
                        label="Confirm Password"
                    ></v-text-field>
                    <v-btn
                        @click.prevent="submit($data)"
                        :disabled="!valid"
                        color="primary"
                        block
                        class="mt-3"
                        :loading="isSubmitting"
                    >
                        Reset Password
                    </v-btn>
                    <v-btn color="gdarken-1" class="mt-2" block text @click="$emit('close-password-reset-modal')">
                        Cancel
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ResetPassword',
    props: ['user', 'token'],
    data() {
        return {
            valid: false,
            dialog: true,
            password: '',
            confirmPassword: '',
            isSubmitting: false,
            rules: {
                required: value => !!value || 'Required.',
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
                    return pattern.test(value) || 'Min. 8 characters with at least one capital letter and a number.';
                },
                passwordsMatch: value => {
                    return value === this.password || `Passwords don't match`;
                },
            },
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        submit(data) {
            let payload = {
                password: data.password,
            };

            this.isSubmitting = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Users/change-password/${this.user.id}`, payload)
                .then(response => {
                    this.showSnackbar({ message: `Password reset for ${this.user.firstName} ${this.user.lastName}` });
                    this.$refs.form.reset();
                    this.$emit('close-password-reset-modal');
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({
                        message: `Error resetting password for ${this.user.firstName} ${this.user.lastName}`,
                    });
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
};
</script>

<style></style>

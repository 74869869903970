<template>
    <fieldset :class="$attrs.class">
        <label class="date-label" :class="(error || hasError) && 'validation-error'">{{ formLabel }}</label>
        <datetime
            :minute-step="15"
            placeholder="-- --"
            :type="$props.type"
            use12-hour
            class="theme-orange"
            :class="error && 'validation-error'"
            :zone="$props.zone"
            @close="validateField"
            v-model="internalValue"
        />
        <p v-if="(error || hasError) && required" class="date-error-message">{{ formLabel }} is required!</p>
    </fieldset>
</template>

<script>
export default {
    props: {
        hasError: {
            type: Boolean,
        },
        required: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        formLabel: {
            type: String,
        },
        type: {
            type: String,
            default: 'datetime',
        },
        zone: {
            type: String,
            default: 'local',
        },
    },
    name: 'DatePicker',
    data() {
        return {
            internalValue: null,
            error: false,
        };
    },
    methods: {
        validateField: function () {
            if (this.required && this.internalValue === null) {
                this.error = true;
            } else {
                this.$emit('input', this.internalValue);
                this.error = false;
            }
        },
    },

    mounted() {
        this.internalValue = this.value;
    },
};
</script>

<style lang="scss" scoped>
.date-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px !important;
}

.date-label.validation-error {
    color: #ff5252;
}

.date-error-message {
    font-size: 12px;
    color: #ff5252;
    margin-top: 2px;
}

.date-label.validation-error .vdatetime-input::placeholder {
    color: #ff5252;
}
</style>

<template>
    <div
        :key="event.id"
        class="service-editor-section-entry"
        :class="activeTab === index ? 'active' : ''"
        @click="$emit('click')"
    >
        <span v-if="!event.hidden" class="status-dot" :class="checkCompletedStatus() ? 'active' : ''"></span>
        <span v-else class="status-dot" style="background-color: #fb8c00"></span>
        <span class="entry-title"
            >{{ cleanTitle(event.title) }}
            <div v-if="$auth.role.includes('SuperAdmin')">
                <v-chip dark label :color="eventStates[event.eventStatus].color" x-small>{{
                    eventStates[event.eventStatus].label
                }}</v-chip>
            </div>
        </span>

        <!-- Live Icon -->
        <div v-if="event.eventStatus === 2" class="live-container">
            <div class="blob" />
            <span>LIVE</span>
        </div>

        <v-btn
            v-if="!checkCompletedStatus()"
            @click="$emit('deleteEvent', event)"
            class="delete-btn"
            :class="{ 'delete-btn--light-bg': activeTab === index }"
            color="#F28686"
            small
            fab
            text
            dark
        >
            <font-awesome-icon icon="fa-regular fa-trash-can" />
        </v-btn>
    </div>
</template>

<script>
import { eventStates } from '@/constants';

export default {
    name: 'EventEntry',
    props: {
        event: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        activeTab: {
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            eventStates: [...eventStates],
        };
    },
    methods: {
        /**
         * Used to check if an event is completed, if so it will turn the dot green.
         */
        checkCompletedStatus: function () {
            if (this.event?.time === '0001-01-01T00:00:00') {
                return false;
            }

            return true;
        },
        /**
         * Currently, the title of an event comes back from the api as
         * 'firstName lastName's Funeral'. We need to remove the first
         * and last name for visual purposes.
         */
        cleanTitle: function (title) {
            let cleaned = title.replace(`${this.service.firstName} ${this.service.lastName}'s `, '');
            return cleaned;
        },
    },
};
</script>

<style lang="scss" scoped>
.delete-btn {
    &:hover,
    &:focus {
        background-color: rgba(233, 232, 232, 0.5) !important;
    }
}
.hidden {
    background: #fb8c00;
}
</style>

<template>
    <div>
        <div class="settings-container d-flex">
            <div style="display: flex; flex-direction: column; justify-content: center; max-width: 200px">
                <div v-if="tributeVideo.selectedTemplates[0]">
                    <v-img
                        :src="handleThumbnail(tributeVideo.selectedTemplates[0].url)"
                        style="max-width: 100%; max-height: 100%; border-radius: 5px"
                    ></v-img>
                </div>
                <div v-else style="width: 100%">
                    <!-- <span style>No intro selected</span> -->
                    <v-alert
                        @click="$emit('changeTab', 'intro')"
                        outlined
                        text
                        color="warning"
                        class="d-flex align-items-center justify-content-center text-center"
                        style="min-height: 100%; width: 100%; cursor: pointer"
                    >
                        <div>No theme selected!</div>

                        <div>
                            <font-awesome-icon
                                class="mt-2"
                                style="font-size: 1.2rem"
                                icon="fa-regular fa-circle-plus"
                            ></font-awesome-icon>
                        </div>
                    </v-alert>
                </div>
                <strong v-if="tributeVideo.selectedTemplates[0]" class="text-center mt-2">{{
                    tributeVideo.selectedTemplates[0].title
                }}</strong>
                <v-btn
                    v-if="tributeVideo.selectedTemplates[0]"
                    x-small
                    class="mt-1"
                    text
                    @click="$emit('changeTab', 'intro')"
                >
                    {{ tributeVideo.selectedTemplates[0] ? 'Choose Another' : 'Choose Intro' }}</v-btn
                >
            </div>

            <v-divider class="mx-5" vertical></v-divider>

            <div style="display: flex; flex-direction: column; flex-grow: 1">
                <strong>Options</strong>
                <div class="flex-column mt-2">
                    <strong style="font-size: 0.8rem">Slide Duration</strong>
                    <!-- <v-text-field
                        background-color="white"
                        dense
                        solo
                        flat
                        label="Slide Duration"
                        v-model.number="slideDuration"
                        min="0"
                        max="20"
                        type="number"
                    >
                        <template v-slot:append>
                            <div>Seconds</div>
                        </template>
                    </v-text-field> -->
                    <div class="p-3" style="background-color: white; border-radius: 5px">
                        <v-slider
                            :disabled="loading"
                            class="pointer-slider"
                            @start="handleSliderStart"
                            @end="handleSliderEnd"
                            background-color="white"
                            color="#fb8c00"
                            track-color="#eee"
                            dense
                            hide-details
                            solo
                            flat
                            v-model.number="slideDuration"
                            min="5"
                            max="12"
                            :step="sliderInUse ? '0.5' : '0.000001'"
                        ></v-slider>
                        <div class="d-flex justify-space-between align-center flex-wrap">
                            <span v-if="slideDuration"> {{ slideDuration.toFixed(2) }} Seconds </span>
                            <div>
                                <v-btn
                                    :disabled="loading"
                                    :loading="loading"
                                    small
                                    color="#fb8c00"
                                    @click="autoSlideDuration = !autoSlideDuration"
                                    :dark="autoSlideDuration"
                                    depressed
                                    :outlined="!autoSlideDuration"
                                    >Auto: {{ autoSlideDuration ? 'ON' : 'OFF' }}</v-btn
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-column mt-2">
                    <strong style="font-size: 0.8rem">Transitions</strong>
                    <v-select
                        :disabled="loading"
                        @change="handleSelectChange"
                        attach=""
                        background-color="white"
                        dense
                        solo
                        flat
                        label="Transitions"
                        :items="transitionTypes"
                        item-text="label"
                        item-value="value"
                        v-model="selectedTransition"
                    ></v-select>
                </div>

                <div class="flex-column mt-2">
                    <strong style="font-size: 0.8rem">Animations</strong>
                    <v-select
                        :disabled="loading"
                        @change="handleSelectChange"
                        attach=""
                        background-color="white"
                        dense
                        solo
                        flat
                        label="Animations"
                        :items="animationTypes"
                        item-text="label"
                        item-value="value"
                        v-model="selectedAnimation"
                    ></v-select>
                </div>

                <div v-if="$auth.role.includes('SuperAdmin')" class="flex-column mt-2">
                    <strong style="font-size: 0.8rem">Floating Images</strong>

                    <v-select
                        @change="handleSelectChange"
                        attach=""
                        :disabled="tributeVideo.totalPhotos < 15 || loading"
                        background-color="white"
                        dense
                        solo
                        flat
                        :items="boolValues"
                        v-model="floatingImages"
                    ></v-select>
                    <span style="color: #999; font-size: 0.8rem" v-if="tributeVideo.totalPhotos < 15"
                        >Must include at least 16 photos to use this option.</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { transitionTypes } from '@/constants';
import { debounceV2 } from '@/utilities/debounce.js';

export default {
    data() {
        return {
            transitionTypes,
            selectedAnimation: 1,
            selectedTransition: 0,
            slideDuration: 0,
            autoSlideDuration: true,
            floatingImages: false,
            boolValues: [true, false],
            mounted: false,
            sliderInUse: false,
            animationTypes: [
                {
                    label: 'Random',
                    value: 0,
                },
                {
                    label: 'Zoom In',
                    value: 1,
                },
                {
                    label: 'Zoom Out',
                    value: 2,
                },
                {
                    label: 'None',
                    value: null,
                },
            ],
        };
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    props: {
        // calculatedSlideDuration: {
        //     type: Number,
        //     default: 0,
        // },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        autoSlideDuration(val) {
            // if (!val) {
            //     console.log('updating render settings by watcher', this.slideDuration);
            //     this.slideDuration = 6;
            //     this.updateTributeVideo({ slideDuration: this.slideDuration });
            // }

            if (!this.sliderInUse) {
                this.debouncedSettingsUpdate();
            }
        },
    },
    methods: {
        ...mapActions('tributeVideo', ['updateTributeVideo']),
        handleSelectChange() {
            this.debouncedSettingsUpdate();
        },
        handleSliderEnd() {
            this.sliderInUse = false;
            this.updateTributeVideo({ slideDuration: this.slideDuration });
            this.debouncedSettingsUpdate();
        },
        handleSliderStart() {
            this.sliderInUse = true;
            this.autoSlideDuration = false;
        },
        handleThumbnail(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //https://ik.imagekit.io/demo/sample-video.mp4/ik-thumbnail.jpg
            //https://ik.imagekit.io/memoryshare/templates/fa6da4d5-b142-40ac-a67f-efdc1acd0523.mp4/ik-thumbnail.jpg?tr=so-5
            if (url) {
                const srcPath = url.split('/tribute-template-renders/')[1];
                const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
                const poster = `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${thumbNailMod}`;
                return poster;
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        debouncedSettingsUpdate: debounceV2(function () {
            let data = {
                slideDuration: this.slideDuration,
                autoSlideDuration: this.autoSlideDuration,
                transition: this.selectedTransition,
                animation: this.selectedAnimation,
                floatingImages: this.floatingImages,
            };

            this.$emit('update-render-settings', data);
        }, 500),
    },
    sockets: {
        NotifyTributeRenderSettings(data) {
            if (data != null && data.tributeId == this.tributeVideo?.id) {
                this.updateSettings(data);
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
    },
};
</script>
<style lang="scss">
.settings-container {
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 40px 20px;

    .pointer-slider .v-slider__thumb-container:hover {
        cursor: pointer;
    }
}
</style>

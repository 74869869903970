<template>
    <AppLayout :has-background="true" :contained="true">
        <div>
            <div class="mb-5">
                <v-btn v-if="$auth.role.includes('SuperAdmin')" @click="$router.push({ name: 'EcomAdmin' })"
                    ><font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left" />Suppliers</v-btn
                >
                <h3 class="mt-3" style="color: white">
                    {{ settings.name }}
                </h3>
            </div>

            <v-card class="elevation-3 p-4 bg-white rounded">
                <v-tabs v-model="tab" color="#0d5259" style="overflow: visible">
                    <v-tab href="#products">Products</v-tab>
                    <v-tab href="#orders">Orders</v-tab>
                    <v-tab href="#shipments">Shipments</v-tab>
                </v-tabs>

                <v-tabs-items :value="tab">
                    <v-tab-item value="products">
                        <div class="mt-3">
                            <products-table></products-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item value="orders">
                        <OrderTable :supplierId="$route.params.supplier_id" />
                    </v-tab-item>
                    <v-tab-item value="shipments">
                        <SupplierShipmentTable :supplierId="$route.params.supplier_id" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </div>
    </AppLayout>
</template>

<script>
import ProductsTable from '@/components/Tables/ProductsTable.vue';
import OrderTable from '@/components/Tables/OrderTable.vue';
import SupplierShipmentTable from '@/components/Tables/SupplierShipmentTable';
export default {
    metaInfo: {
        title: 'Products',
    },
    data() {
        return {
            token: '',
            settings: '',
        };
    },
    components: {
        ProductsTable,
        OrderTable,
        SupplierShipmentTable,
    },
    methods: {
        getSettings() {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/Suppliers/${this.$route.params.supplier_id}`)
                    .then(response => {
                        this.settings = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
    mounted() {
        this.getSettings();
    },
    computed: {
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
};
</script>

<style></style>

<template>
    <v-card>
        <v-card-title> Render History </v-card-title>

        <v-card-text>
            <h3>{{ event.title }}</h3>
            <br />
            <!--START: User Records -->
            <v-data-table
                :headers="headers"
                :items="renders"
                :options.sync="options"
                :loading="loading"
                :server-items-length="total"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50],
                }"
                loading-text="Getting Renders..."
            >
                <template v-slot:item.blobs="{ item }">
                    <div @click="displayBlobsList(item)" class="d-flex flex-column align-center pa-2">
                        <span>{{ item.blobs.split(',').length }}</span>
                        <v-btn x-small depressed>view</v-btn>
                    </div>
                </template>

                <template v-slot:item.createDate="{ item }">
                    <div style="min-width: 65px" class="d-flex flex-column">
                        <span>
                            {{ $moment.utc(item.createDate).local().format('l') }}
                        </span>
                        <span style="color: gray" class="text-caption">
                            {{ $moment.utc(item.createDate).local().format('LTS') }}
                        </span>
                    </div>
                </template>

                <template v-slot:item.encodeStartTime="{ item }">
                    <div v-if="item.encodeStartTime" style="min-width: 65px" class="d-flex flex-column">
                        <span>
                            {{ $moment.utc(item.encodeStartTime).local().format('l') }}
                        </span>
                        <span style="color: gray" class="text-caption">
                            {{ $moment.utc(item.encodeStartTime).local().format('LTS') }}
                        </span>
                    </div>
                </template>

                <template v-slot:item.encodeEndTime="{ item }">
                    <div v-if="item.encodeEndTime" style="min-width: 65px" class="d-flex flex-column">
                        <span>
                            {{ $moment.utc(item.encodeEndTime).local().format('l') }}
                        </span>
                        <span style="color: gray" class="text-caption">
                            {{ $moment.utc(item.encodeEndTime).local().format('LTS') }}
                        </span>
                    </div>
                </template>

                <template v-slot:item.encodeDuration="{ item }">
                    <div v-if="item.encodeStartTime && item.encodeEndTime">
                        {{ getDuration(item.encodeStartTime, item.encodeEndTime) }}
                    </div>
                </template>

                <template v-slot:item.renderSpeed="{ item }">
                    <div>
                        <v-chip small dark :color="renderSpeeds[item.renderSpeed].color">{{
                            renderSpeeds[item.renderSpeed].label
                        }}</v-chip>
                    </div>
                </template>

                <template v-slot:item.videoQueType="{ item }">
                    <div>
                        <v-chip small dark :color="renderTypes[item.videoQueType].color">{{
                            renderTypes[item.videoQueType].label
                        }}</v-chip>
                    </div>
                </template>

                <template v-slot:item.engineType="{ item }">
                    <div>
                        <v-chip small dark :color="renderEngineTypes[item.engineType].color">{{
                            renderEngineTypes[item.engineType].label
                        }}</v-chip>
                    </div>
                </template>
                <template v-slot:item.originalFileSize="{ item }">
                    <div v-if="item.originalFileSize" style="min-width: 70px">
                        {{ bytesToReadableFormat(item.originalFileSize) }}
                    </div>
                </template>
                <template v-slot:item.convertedFileSize="{ item }">
                    <div v-if="item.convertedFileSize" style="min-width: 70px">
                        {{ bytesToReadableFormat(item.convertedFileSize) }}
                    </div>
                </template>

                <template v-slot:item.completed="{ item }">
                    <div>
                        <font-awesome-icon
                            v-if="item.completed"
                            style="font-size: 1.5rem; color: #4caf50"
                            icon="fa-solid fa-circle-check"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            style="font-size: 1.5rem; color: #ff5252"
                            icon="fa-solid fa-circle-xmark"
                        ></font-awesome-icon>
                    </div>
                </template>

                <template v-slot:item.hevc="{ item }">
                    <div>
                        <font-awesome-icon
                            v-if="item.hevc"
                            style="font-size: 1.5rem; color: #4caf50"
                            icon="fa-solid fa-circle-check"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            style="font-size: 1.5rem; color: #ff5252"
                            icon="fa-solid fa-circle-xmark"
                        ></font-awesome-icon>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-if="selectedRender" v-model="blobsModal" max-width="700px">
            <v-card>
                <v-card-title>
                    <div style="width: 100%" class="d-flex flex-row justify-space-between">
                        <h3>Blobs</h3>

                        <p class="text-caption mb-0">Render: {{ selectedRender.id }}</p>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-list>
                        <div v-for="blob in blobsList">
                            <v-list-item>
                                <a :href="blob" target="_blank">{{ blob }}</a>
                            </v-list-item>
                            <v-divider></v-divider>
                        </div>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="blobsModal = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { renderSpeeds, renderTypes, renderEngineTypes } from '@/constants';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    components: {
        CustomTooltip,
    },
    watch: {
        blobsModal(newVal) {
            if (!newVal) {
                this.selectedRender = {};
            }
        },
        options: {
            handler() {
                if (this.mounted) {
                    this.getRenderHistory();
                }
            },
        },
    },
    data() {
        return {
            renderSpeeds,
            renderTypes,
            renderEngineTypes,
            token: null,
            renders: [],
            total: 0,
            selectedRender: {},
            blobsModal: false,
            blobsList: [],
            options: {},
            loading: true,
            mounted: false,
            headers: [
                { text: 'Id', value: 'id', align: 'center' },
                { text: 'Create Date', value: 'createDate', align: 'center' },
                { text: 'Vm Name', value: 'vmName', align: 'center' },
                { text: 'Speed', value: 'renderSpeed', align: 'center' },
                { text: 'Type', value: 'videoQueType', align: 'center' },
                { text: 'Engine', value: 'engineType', align: 'center' },
                { text: 'Blobs', value: 'blobs', align: 'center' },
                { text: 'Encode Start', value: 'encodeStartTime', align: 'center' },
                { text: 'Encode End', value: 'encodeEndTime', align: 'center' },
                { text: 'Encode Duration', value: 'encodeDuration', align: 'center' },
                { text: 'Original', value: 'originalFileSize', align: 'center' },
                { text: 'Converted', value: 'convertedFileSize', align: 'center' },
                { text: 'Completed', value: 'completed', align: 'center' },
                { text: 'HEVC', value: 'hevc', align: 'center' },
                { text: 'Previous Render', value: 'previousRenderId', align: 'center' },
            ],
        };
    },
    methods: {
        bytesToReadableFormat(bytes) {
            if (bytes == null) return;

            const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            let readable = bytes;
            let unitIndex = 0;

            while (readable >= 1024 && unitIndex < units.length - 1) {
                readable /= 1024;
                unitIndex++;
            }

            return `${readable.toFixed(2)} ${units[unitIndex]}`;
        },
        displayBlobsList(render) {
            this.selectedRender = render;

            this.blobsList = this.selectedRender.blobs.split(',');
            this.blobsModal = true;
        },
        getDuration(startTime, endTime) {
            var mStartTime = this.$moment(startTime);
            var mEndTime = this.$moment(endTime);

            var duration = this.$moment.utc(mEndTime.diff(mStartTime));

            var formatted = duration.format('HH:mm:ss');
            return formatted;
        },
        getSortByValue(value) {
            switch (value) {
                case 'id':
                    return 'Id';
                case 'createDate':
                    return 'CreateDate';
                case 'originalFileSize':
                    return 'OriginalFileSize';
                case 'convertedFileSize':
                    return 'ConvertedFileSize';
                case 'encodeStartTime':
                    return 'EncodeStartTime';
                case 'encodeEndTime':
                    return 'EncodeEndTime';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        async getRenderHistory() {
            // const paramString = this.getParamString();
            var sortBy = this.getSortByValue(this.options.sortBy[0] ? this.options.sortBy[0] : 'createDate');
            const { sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortAsc: sortDesc ? sortDesc : false,
                sortBy: sortBy,
            };

            this.$auth.getIdTokenClaims().then(async result => {
                this.token = result.__raw;

                this.loading = true;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token} ` } })
                    .get(`${process.env.VUE_APP_API}/Events/render-history/${this.event.id}`, { params: params })
                    .then(response => {
                        this.renders = response.data.renders;
                        this.total = response.data.total;
                        this.loading = false;
                        this.mounted = true;
                    })
                    .catch(error => {})
                    .finally(() => {});
            });
        },
    },
    async mounted() {
        await this.getRenderHistory();
    },
};
</script>

<style>
.date-time-stamp {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>

<template>
    <SummaryCard>
        <v-row>
            <v-col cols="6" v-for="(item, index) in categoryTotals" :key="index">
                <div
                    :style="{ 'background-color': item.color }"
                    style="border-radius: 5px; color: white"
                    class="elevation-2"
                >
                    <div class="pt-1"><font-awesome-icon :icon="item.icon" class="mr-2" />{{ item.label }}</div>
                    <div class="d-flex justify-content-center">
                        <div v-if="item.label == 'USB' || item.label == 'DVD'">{{ item.unitCount }} units</div>
                        <span v-if="item.label == 'USB' || item.label == 'DVD'" class="mx-2">|</span>
                        <div class="pb-1">{{ item.total | formatMoney() }}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <h3>Total: {{ total }}</h3>
            </v-col>
            <v-col></v-col>
            <v-col>
                <h3>
                    <strong>{{ totalAmount | formatMoney() }}</strong>
                </h3>
            </v-col>
        </v-row>
    </SummaryCard>
</template>

<script>
import SummaryCard from '../SummaryCard.vue';
import { customerTypes } from '@/constants.js';

export default {
    data() {
        return {
            customerTypes,
        };
    },
    props: ['total', 'totalAmount', 'purchases', 'categoryTotals'],
    components: {
        SummaryCard,
    },
};
</script>

<style></style>

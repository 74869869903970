<template>
    <div>
        <div>
            <div>
                <div
                    v-if="mapId == 'modalMap'"
                    style="
                        position: absolute;
                        top: 25px;
                        left: 25px;
                        z-index: 5;

                        display: flex;
                        flex-direction: column;
                    "
                >
                    <v-btn id="target-toggle" dark small class="mb-2" @click="$emit('toggleScope')">{{
                        locationScoped ? 'Target Everywhere' : 'Target Location'
                    }}</v-btn>

                    <v-btn id="set-btn" v-if="showAreaSelection" @click="emitSelected" dark small>Set Location</v-btn>
                </div>
                <MglMap
                    :id="mapId"
                    :accessToken="mapboxSettings.accessToken"
                    :mapStyle="mapboxSettings.mapStyle"
                    :center="mapboxSettings.centerCoords"
                    class="map-container"
                    :zoom="mapboxSettings.zoomLevel"
                >
                </MglMap>
            </div>
            <div v-if="showAreaSelection">
                <div class="pt-2" style="display: flex; align-items: center">
                    <div>
                        Radius: <strong>{{ radius }}</strong> {{ radius === 1 ? 'mile' : 'miles' }}
                    </div>
                    <v-slider style="margin-bottom: -20px; z-index: 15" dense v-model="radius"></v-slider>
                </div>
            </div>
            <div v-else style="height: 38px"></div>
        </div>
    </div>
</template>
<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox';
import * as turf from '@turf/helpers';
import * as turfCircle from '@turf/circle';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { trackingCodeTypes } from '@/constants';
import homeIcon from '@/assets/icons/droplet.png';

export default {
    data() {
        return {
            loaded: false,
            showMap: false,
            lat: null,
            long: null,
            // window: 0,
            // pages: 3,
            tempDate: null,
            message: 'hi',
            marketModal1: false,
            mapInitialLoading: true,
            loadingMap: true,
            companyOptions: [
                { text: 'Google', logo: '' },
                { text: 'Facebook', logo: '' },
                { text: 'LinkedIn', logo: '' },
            ],
            selectingLocation: false,
            selectedCompany: {},
            mapboxSettings: {
                zoomLevel: 3.4,
                centerCoords: [-74.5, 40],
                mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            },
            geojson: {
                type: 'FeatureCollection',
                crs: {
                    type: 'name',
                    properties: {
                        name: '',
                    },
                },
                features: [],
            },
            trackingCode: {
                funeralHomeId: null,
                locationScoped: false,
                latitude: null,
                longitude: null,
                radius: 0,
                ageScoped: false,
                minAge: 0,
                maxAge: 0,
                serviceDateScoped: false,
                minServiceDate: 0,
                maxServiceDate: 0,
                estimated30DayHits: 0,
                trackingCodeType: 7,
            },
            trackingCodeTypes,
            radius: 25,
            showAreaSelection: false,
            analytics: [],
        };
    },
    props: {
        mapId: {
            type: String,
            required: true,
        },
        location: {
            type: Object,
            required: true,
        },
        lockArea: {
            type: Boolean,
            required: true,
        },
        interactive: {
            type: Boolean,
            required: true,
        },
        locationScoped: {
            type: Boolean,
            required: true,
        },
        showAnalytics: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        MglMap,
        MglMarker,
        MglNavigationControl,
        turf,
        turfCircle,
        homeIcon,
    },
    computed: {
        menuShowing() {
            return this.$store.state.menuShowing;
        },
    },

    mounted() {
        this.mapbox = Mapbox;
        this.loadMap();
    },
    watch: {
        radius() {
            if (this.loaded && this.lockArea == false) {
                var mapCenter = this.map.getCenter().toArray();
                this.updateLayers(mapCenter);
            }
        },
        menuShowing() {
            setTimeout(() => {
                this.resizeMap();
            }, 100);
        },
        locationScoped() {
            if (this.locationScoped == false) {
                this.toggleMarker('none');
            } else {
                this.toggleMarker('visible');
            }
        },
    },
    methods: {
        addAnalyticsToMap(data) {
            var geoData = {
                type: 'FeatureCollection',
                crs: {
                    type: 'name',
                    properties: {
                        name: '',
                    },
                },
                features: [],
            };

            const mappedCoords = data.map(function (viewer, index) {
                // commented clean code for now
                // const lat = this.isFloat(viewer?.latitude) ? viewer.latitude.toFixed(4) : 0
                // const long = this.isFloat(viewer?.longitude) ? viewer.longitude.toFixed(4) : 0
                const lat =
                    viewer?.latitude === viewer?.latitude && viewer?.latitude % 1 !== 0
                        ? viewer.latitude.toFixed(4)
                        : 0;
                const long =
                    viewer?.longitude === viewer?.longitude && viewer?.longitude % 1 !== 0
                        ? viewer.longitude.toFixed(4)
                        : 0;
                return {
                    type: 'Feature',
                    properties: { id: viewer?.id, city: viewer?.city },
                    geometry: {
                        type: 'Point',
                        coordinates: [parseFloat(long), parseFloat(lat), 0.0],
                    },
                };
            });
            geoData.features = mappedCoords;

            this.map.addSource('viewers', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ viewers
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: geoData,
                buffer: 0,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            });

            this.map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'viewers',
                minZoom: '3',
                maxZoom: '9',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
                    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                },
            });
            this.map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'viewers',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                },
            });

            this.map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'viewers',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#ff530d',
                    'circle-radius': 6,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#fff',
                },
            });

            this.map.on('click', 'clusters', e => {
                const features = this.map.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                });

                if (!features.length) {
                    return;
                }
                const clusterId = features[0].properties.cluster_id;
                this.map.getSource('viewers').getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;

                    this.map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom,
                    });
                });
            });

            const popup = new Mapbox.Popup({ closeButton: false, closeOnClick: false });

            this.map.on('mouseover', 'unclustered-point', e => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const city = e.features[0].properties.city;

                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popup.setLngLat(coordinates).setHTML(`${city}`).addTo(this.map);
            });

            this.map.on('mouseleave', 'unclustered-point', e => {
                popup.remove();
            });

            this.map.on('mouseenter', 'clusters', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });
            this.map.on('mouseleave', 'clusters', () => {
                this.map.getCanvas().style.cursor = '';
            });
        },
        loadAnalytics() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/dogs/tracking-code-map/${this.$route.params.id}/${this.mapId}`)
                    .then(response => {
                        if (response.data.length > 0) {
                            this.addAnalyticsToMap(response.data);
                        }
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
        setNoLocation() {
            this.map.zoomTo(0, { duration: 2000 });
            this.radius = 0;
            this.showAreaSelection = false;
            this.toggleMarker('none');
        },
        refreshMap(obj) {
            if (obj.locationScoped) {
                this.map.zoomTo(8, {
                    center: [obj.longitude, obj.latitude],
                    duration: 2000,
                });
                this.radius = obj.radius;
                this.showAreaSelection = true;
                this.toggleMarker('visible');
            } else {
                this.setNoLocation();
            }
        },
        emitSelected() {
            var tempCenter = this.map.getCenter();
            const data = {
                long: tempCenter.lng,
                lat: tempCenter.lat,
                radius: this.radius,
            };
            this.$emit('locationSelected', data);
        },
        resizeMap() {
            this.map.resize();
        },
        toggleMarker(val) {
            const layer = this.map.getLayer('homeLayer');

            if (layer) {
                this.map.setLayoutProperty('homeLayer', 'visibility', val);
            }
        },
        updateLayers(mapCenter) {
            //Reset data with new center
            var center = turf.point(mapCenter);
            var radius = this.radius;
            var options = {
                units: 'miles',
            };
            var circle = turfCircle.default(center, radius, options);

            var circleSource = this.map.getSource('circle');
            if (circleSource) {
                circleSource.setData(circle);
            }
            // this.map.getSource('homeSource').setData(center);
        },

        loadMap() {
            Mapbox.accessToken = process.env.VUE_APP_MAPBOX_KEY;
            const map = new Mapbox.Map({
                container: this.mapId,
                style: 'mapbox://styles/mapbox/streets-v9',

                // center: [-99.203512, 37.462758],
                center: [this.location.long, this.location.lat],
                zoom: this.locationScoped ? 8 : 0,
                interactive: this.interactive,
            });

            this.map = map;

            map.on('load', () => {
                const geocoder = new MapboxGeocoder({
                    accessToken: process.env.VUE_APP_MAPBOX_KEY,
                    mapboxgl: this.map,
                    marker: false,
                });
                if (this.interactive) {
                    map.addControl(geocoder);
                    map.addControl(new Mapbox.NavigationControl());
                    map.addControl(new Mapbox.GeolocateControl());
                }

                // var center = turf.point([-99.203512, 37.462758]);
                var center = turf.point([this.location.long, this.location.lat]);
                var radius = this.locationScoped ? this.radius : 0;
                var options = {
                    units: 'miles',
                };

                var circle = turfCircle.default(center, radius, options);

                map.addSource('circle', {
                    type: 'geojson',
                    data: circle,
                });

                map.addLayer({
                    id: 'circle-fill',
                    type: 'fill',
                    source: 'circle',
                    paint: {
                        'fill-color': 'pink',
                        'fill-opacity': 0.3,
                    },
                });
                map.addLayer({
                    id: 'circle-outline',
                    type: 'line',
                    source: 'circle',
                    paint: {
                        'line-color': 'blue',
                        'line-opacity': 0.5,
                        'line-width': 10,
                        'line-offset': 5,
                    },
                    layout: {},
                });

                this.map.scrollZoom._aroundCenter = true;

                if (this.mapId == 'modalMap') {
                    // const marker = new this.mapbox.Marker()
                    //     .setLngLat([this.location.long, this.location.lat])
                    //     .addTo(map);

                    this.showAreaSelection = true;

                    map.loadImage(homeIcon, (error, image) => {
                        if (error) throw error;

                        map.addImage('home', image);

                        map.addSource('homeSource', {
                            type: 'geojson',
                            data: center,
                        });

                        map.addLayer({
                            id: 'homeLayer',
                            type: 'symbol',
                            source: 'homeSource',
                            layout: {
                                'icon-image': 'home',
                                'icon-size': 0.1,
                            },
                            visibility: this.locationScoped ? 'visible' : 'none',
                        });
                    });
                }

                if (this.showAnalytics) {
                    this.loadAnalytics();
                }

                this.loaded = true;
                this.$emit('loaded');
            });

            map.on('move', () => {
                if (!this.lockArea) {
                    var mapCenter = this.map.getCenter().toArray();
                    this.updateLayers(mapCenter);

                    // this.emitSelected();
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
#map {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    // border: 2px solid green;
}
.map-container {
    height: 500px;
}
// #target-toggle {
//     opactity: 0.5;
// }
</style>

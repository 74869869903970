var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Render History ")]),_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.event.title))]),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.renders,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
                itemsPerPageOptions: [10, 25, 50],
            },"loading-text":"Getting Renders..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.blobs",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-center pa-2",on:{"click":function($event){return _vm.displayBlobsList(item)}}},[_c('span',[_vm._v(_vm._s(item.blobs.split(',').length))]),_c('v-btn',{attrs:{"x-small":"","depressed":""}},[_vm._v("view")])],1)]}},{key:"item.createDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"65px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(item.createDate).local().format('l'))+" ")]),_c('span',{staticClass:"text-caption",staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.createDate).local().format('LTS'))+" ")])])]}},{key:"item.encodeStartTime",fn:function(ref){
            var item = ref.item;
return [(item.encodeStartTime)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"65px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(item.encodeStartTime).local().format('l'))+" ")]),_c('span',{staticClass:"text-caption",staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.encodeStartTime).local().format('LTS'))+" ")])]):_vm._e()]}},{key:"item.encodeEndTime",fn:function(ref){
            var item = ref.item;
return [(item.encodeEndTime)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"65px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(item.encodeEndTime).local().format('l'))+" ")]),_c('span',{staticClass:"text-caption",staticStyle:{"color":"gray"}},[_vm._v(" "+_vm._s(_vm.$moment.utc(item.encodeEndTime).local().format('LTS'))+" ")])]):_vm._e()]}},{key:"item.encodeDuration",fn:function(ref){
            var item = ref.item;
return [(item.encodeStartTime && item.encodeEndTime)?_c('div',[_vm._v(" "+_vm._s(_vm.getDuration(item.encodeStartTime, item.encodeEndTime))+" ")]):_vm._e()]}},{key:"item.renderSpeed",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.renderSpeeds[item.renderSpeed].color}},[_vm._v(_vm._s(_vm.renderSpeeds[item.renderSpeed].label))])],1)]}},{key:"item.videoQueType",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.renderTypes[item.videoQueType].color}},[_vm._v(_vm._s(_vm.renderTypes[item.videoQueType].label))])],1)]}},{key:"item.engineType",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.renderEngineTypes[item.engineType].color}},[_vm._v(_vm._s(_vm.renderEngineTypes[item.engineType].label))])],1)]}},{key:"item.originalFileSize",fn:function(ref){
            var item = ref.item;
return [(item.originalFileSize)?_c('div',{staticStyle:{"min-width":"70px"}},[_vm._v(" "+_vm._s(_vm.bytesToReadableFormat(item.originalFileSize))+" ")]):_vm._e()]}},{key:"item.convertedFileSize",fn:function(ref){
            var item = ref.item;
return [(item.convertedFileSize)?_c('div',{staticStyle:{"min-width":"70px"}},[_vm._v(" "+_vm._s(_vm.bytesToReadableFormat(item.convertedFileSize))+" ")]):_vm._e()]}},{key:"item.completed",fn:function(ref){
            var item = ref.item;
return [_c('div',[(item.completed)?_c('font-awesome-icon',{staticStyle:{"font-size":"1.5rem","color":"#4caf50"},attrs:{"icon":"fa-solid fa-circle-check"}}):_c('font-awesome-icon',{staticStyle:{"font-size":"1.5rem","color":"#ff5252"},attrs:{"icon":"fa-solid fa-circle-xmark"}})],1)]}},{key:"item.hevc",fn:function(ref){
            var item = ref.item;
return [_c('div',[(item.hevc)?_c('font-awesome-icon',{staticStyle:{"font-size":"1.5rem","color":"#4caf50"},attrs:{"icon":"fa-solid fa-circle-check"}}):_c('font-awesome-icon',{staticStyle:{"font-size":"1.5rem","color":"#ff5252"},attrs:{"icon":"fa-solid fa-circle-xmark"}})],1)]}}])})],1),(_vm.selectedRender)?_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.blobsModal),callback:function ($$v) {_vm.blobsModal=$$v},expression:"blobsModal"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex flex-row justify-space-between",staticStyle:{"width":"100%"}},[_c('h3',[_vm._v("Blobs")]),_c('p',{staticClass:"text-caption mb-0"},[_vm._v("Render: "+_vm._s(_vm.selectedRender.id))])])]),_c('v-card-text',[_c('v-divider'),_c('v-list',_vm._l((_vm.blobsList),function(blob){return _c('div',[_c('v-list-item',[_c('a',{attrs:{"href":blob,"target":"_blank"}},[_vm._v(_vm._s(blob))])]),_c('v-divider')],1)}),0)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.blobsModal = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
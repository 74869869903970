<template>
    <v-card>
        <v-card-title> Super Admin Usage Records </v-card-title>

        <v-card-text v-if="loaded">
            <h3>{{ event.title }}</h3>
            <br />
            <!--START: User Records -->
            <v-data-table :headers="headers" :items="liveStreamData">
                <template v-slot:header.usageType="{ header }">
                    <v-menu
                        :close-on-content-click="false"
                        :offset-y="true"
                        :disabled="!loaded"
                        @input="handleMenuToggle"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ selectedUsageTypes.length }})

                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 200px; overflow: hidden">
                            <v-list-item v-for="(item, index) in usageRecordTypes" :key="index" style="height: 20px">
                                <v-checkbox
                                    v-model="selectedUsageTypes"
                                    :label="item.label"
                                    :value="index"
                                ></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:item.startedTime="{ item }">
                    <div class="date-time-stamp">
                        <span>{{ item.startedTime }}</span>
                        <span style="color: gray">{{ item.startedTimeHours }}</span>
                    </div>
                </template>
                <template v-slot:item.endedTime="{ item }">
                    <div v-if="item.endedTime != 'Invalid date'" class="date-time-stamp">
                        <span>{{ item.endedTime }}</span>
                        <span style="color: gray">{{ item.endedTimeHours }}</span>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div>
                        <span>
                            <font-awesome-icon icon="fa-regular fa-trash-can" @click="deleteRecord(item)" />
                        </span>
                    </div>
                </template>
                <template v-slot:item.usageType="{ item }">
                    <v-chip label dark small :color="usageRecordTypes[item.usageType].color">
                        {{ usageRecordTypes[item.usageType].label }}
                    </v-chip>
                </template>
                <template v-slot:item.completed="{ item }">
                    <v-chip :color="item.completed ? '#975DBE' : 'orange'" dark small label>{{
                        item.completed ? 'Closed' : 'Open'
                    }}</v-chip>
                </template>
            </v-data-table>
        </v-card-text>
        <div class="p-3" v-if="!loaded">
            <v-progress-linear indeterminate color="primary "></v-progress-linear>
        </div>
    </v-card>
</template>

<script>
import moment from 'moment';
import { usageRecordTypes } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    components: {
        CustomTooltip,
    },
    data() {
        return {
            usageRecordTypes,
            token: null,
            liveStreamData: [],
            loaded: false,
            selectedUsageTypes: [0, 1, 2],
            usageTypeParamString: '',
            filterMenuOpen: false,
            headers: [
                { text: 'Started Time', align: 'start', sortable: true, value: 'startedTime' },
                { text: 'Ended Time', value: 'endedTime' },
                { text: 'Type', value: 'usageType' },
                { text: 'Status', value: 'completed' },
                { text: 'Starting User', value: 'startingUser' },
                { text: 'Ending User', value: 'endingUser' },
                { text: 'Area Start', value: 'areaStart' },
                { text: 'Area End', value: 'areaEnd' },
                { text: 'Time Consumed', value: 'secondsConsumed' },
                { text: 'Actions', value: 'actions' },
            ],
        };
    },
    methods: {
        handleMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.selectedUsageTypes.length === 0) {
                    this.liveStreamData = [];
                    return;
                }

                this.getUsageRecords();
                this.filterMenuOpen = false;
            }
        },
        deleteRecord(item) {
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(`${process.env.VUE_APP_API}/live/usage-records/${item.id}${this.usageTypeParamString}`)
                    .then(response => {
                        this.getUsageRecords();
                    });
            });
        },
        getParamString() {
            var string = '';
            this.selectedUsageTypes.forEach(type => (string += `&usageTypes=${type}`));
            return string;
        },
        getUsageRecords() {
            this.loaded = false;
            const paramString = this.getParamString();

            this.$auth.getIdTokenClaims().then(async result => {
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token} ` } })
                    .get(`${process.env.VUE_APP_API}/live/usage-records/${this.event.liveStreamId}?${paramString}`)
                    .then(response => {
                        this.liveStreamData = response.data.map(stream => {
                            return {
                                id: stream.id,
                                startedTime: moment.parseZone(stream.startedTime).local().format('L'),
                                endedTime: moment.parseZone(stream.endedTime).local().format('L'),
                                secondsConsumed: moment
                                    .utc(moment.duration(stream.secondsConsumed, 'seconds').asMilliseconds())
                                    .format('HH:mm:ss'),
                                completed: stream.completed,
                                startingUser: stream.startingUser,
                                endingUser: stream.endingUser,
                                areaStart: stream.areaStart,
                                areaEnd: stream.areaEnd,
                                startedTimeHours: moment.parseZone(stream.startedTime).local().format('hh:mm:ss A'),
                                endedTimeHours: moment.parseZone(stream.endedTime).local().format('hh:mm:ss A'),
                                usageType: stream.usageType,
                            };
                        });

                        // this.userRecords = this.liveStreamData.filter(s => s.usageType == 2 || s.usageType == 0);
                        // this.billableRecords = this.liveStreamData.filter(s => s.usageType == 1 || s.usageType == 0);
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.loaded = true;
                    });
            });
        },
    },
    mounted() {
        this.getUsageRecords();
    },
};
</script>

<style>
.date-time-stamp {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>

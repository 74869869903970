<template>
    <div>
        <v-menu  max-height="400" @input="handleMenuToggle"  :close-on-content-click="false" :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
                <custom-tooltip  :tooltipProps="{ top: true }">
                    <template v-slot:activator="{ on: tooltip }">
                        <span v-bind="attrs" v-on="{ ...menu, ...tooltip }">
                            <slot name="label"> </slot>
                        </span>
                    </template>

                    <template v-slot:content>
                        <span>Select Filters</span>
                    </template>
                </custom-tooltip>
            </template>

            <slot name="content"> </slot>
        </v-menu>

        </v-menu>
    </div>
</template>
<script>
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { debounceV2 } from '@/utilities/debounce';

export default {
    data() {
        return {
            menuOpen: false,
        };
    },
    components: {
        CustomTooltip,
    },
    methods: {
        handleMenuToggle() {

            if (!this.menuOpen) {
                this.menuOpen = true;
            } else {
                this.menuOpen = false;
               
                this.$emit('menu-closed');
            }
        },
    },
};
</script>
<style lang=""></style>

<template>
    <div>
        <div class="ms-notification-bar" :class="subscriptionStatus === 1 ? 'notification-bar-red-bg' : ''">
            <div class="ms-notification-bar-content-left">
                <font-awesome-icon
                    v-if="subscriptionStatus === 1"
                    icon="fa-regular fa-triangle-exclamation"
                    style="font-size: 1.4rem; color: red"
                    class="mr-4"
                />
                <font-awesome-icon
                    v-else
                    icon="fa-regular fa-triangle-exclamation"
                    style="font-size: 1.4rem; color: orange"
                    class="mr-4"
                />
                <p v-if="subscriptionStatus === 7">
                    Your account has no payment method attached. Please add a payment method to begin using MemoryShare.
                </p>
                <p v-else-if="subscriptionStatus === 1">
                    Your account has been canceled. Please contact support to begin using MemoryShare again.
                </p>
                <p v-else>
                    You have an outstanding invoice. Please
                    <span @click="openBillingPortal" style="text-decoration: underline; cursor: pointer">
                        update your credit card details
                    </span>
                    as soon as possible to ensure there is no interruption with your service. After you've updated your
                    card information please ensure all invoices are paid.
                </p>
            </div>

            <div>
                <v-btn color="primary" @click="openBillingPortal" v-if="subscriptionStatus === 7">Add Card</v-btn>
                <router-link
                    v-if="displayPayInvoice"
                    :to="{ name: 'Settings', query: { tab: 'subscription' } }"
                    style="text-decoration: none; color: cyan"
                >
                    <v-btn color="primary" basic style="text-decoration: none">Pay Invoice</v-btn>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        subscriptionStatus: {
            type: Number,
            required: true,
        },
    },
    computed: {
        displayPayInvoice() {
            return this.subscriptionStatus !== 7 && this.subscriptionStatus !== 1;
        },
    },
    methods: {
        openBillingPortal() {
            const funeralHomeId = this.$auth.session.home.id;
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/billing/funeralhome/portal/${funeralHomeId}`)
                    .then(response => {
                        window.open(response.data, '_blank');
                    })
                    .catch(error => {});
            });
        },
    },
};
</script>
<style lang="scss">
.ms-main-content-container {
    .notification-bar-red-bg {
        background-color: rgb(255, 199, 199) !important;
    }
}
</style>
